import { isPlainObject } from '@core/helpers/objectHelper';
import { createLogger } from '@core/services/logger.service';
import { EntityContext } from '@core/services/orm/orm';
import { CURRENT_PATCH } from '@archipad/backend/archiweb/synchroDriver';
import { PatchesDescriptor, PatchDescriptor, PatchAttachmentDescriptor } from '@archipad/services/file/patchFile';
import { FileDescriptor } from '@core/services/file/file';
import { parseDate } from './patchHelper';
import { IllegalArgumentError, NotImplementedError } from '@core/errors/errors-core';
import { PatchError } from '@archipad/errors/errors-archipad';
import { ExportKind, ORMError } from '@archipad-js/core/orm';
import z from 'zod/lib';


export function newPatchId(synchronizedMicroTime:number): string {
	let str = ('000000000000000' + synchronizedMicroTime.toString(16)).substr(-16, 16);
	str += '.';
	str += ('000' + Math.floor(Math.random() * 0xFFFF).toString(16)).substr(-4, 4);
	str += ('000' + Math.floor(Math.random() * 0xFFFF).toString(16)).substr(-4, 4);
	return str;
}

const log = createLogger('patch');

/*----------------------------------------------------------------------------*/
export interface UserInfo {
    company_id: number;
    email: string;
    id: number;
}

export type PatchId = typeof CURRENT_PATCH | string;

/**
 *  A patch is a list of operations on orm entities
 */
export interface Patch {
    /** patch header */
    readonly header: PatchHeader;
    /** entity operations by entity type name */
    readonly operationsByType: ReadonlyMap<string, EntityOperations>;
}

export interface PatchHeader {
    /** minimum model version required to read this patch */
    readonly modelMinVersion:number;
    /** model version of this patch */
    readonly modelVersion:number;
    /** client name */
    readonly clientName?:string;
    /* user info */
    readonly user?: Readonly<UserInfo>;
    /* temp patch */ 
    readonly temp?:boolean;
    /* if true, represent an empty patch from a regular patch point of view but is not as a temp patch */
    readonly emptyLike?:boolean;
    /* if the patch is a dump of the project or not */
    readonly isDump?: boolean;
}

/**
 * A patch in the network format (a JSON serializable structure)
 */
export interface NetPatch {
    patch:NetPatchData,
    attachmentManifest:NetPatchAttachmentManifest
}

export type NetPatchData = [NetPatchHeader, ...Array<NetPatchOperations>];

export interface NetPatchAttachmentManifest {
    [entityId: string]: {
        [attachmentKey: string]: {
            url: string;
            deleted: boolean;
        }
    }
}

/**
 * A patch header in the network format
 */
export interface NetPatchHeader {
    diff : boolean;
    minVersion : number;
    version : number;
    client ? : string;
    user ? : UserInfo;
    temp ? : boolean;
    tempData ? : {
        emptyLike : boolean;
    };
    templateIdStack?: string;
}

export interface NetPatchOperation { 
    id: string;
    _metadata?: { op: "create"|"update"|"delete" }
    [k:string]: unknown;
}

export type NetPatchOperations = [ string, ...Array<NetPatchOperation> ];

export interface EntityOperations {
    /** entity operations by entity id */
    readonly operationById: ReadonlyMap<string, EntityOperation>;
}

export interface AttachmentUpdate {
    readonly file:FileDescriptor;
    readonly deleted:false;
}

export interface AttachmentDelete {
    readonly deleted:true;
}

export type AttachmentOperation = AttachmentUpdate | AttachmentDelete;

export interface EntityUpdate {
    readonly type: 'update';
    readonly propertyUpdates: ReadonlyMap<string, any>;
    readonly attachmentUpdates: ReadonlyMap<string, AttachmentOperation>;
}

export interface EntityCreate {
    readonly type: 'create';
    readonly propertyUpdates: ReadonlyMap<string, any>;
    readonly attachmentUpdates: ReadonlyMap<string, AttachmentOperation>;
}

export interface EntityDelete {
    readonly type: 'delete';
}

export type EntityOperation = EntityUpdate | EntityCreate | EntityDelete;

export enum PatchNature {
    PatchData = 'patchData',
    DumpPatch = 'dumpPatch',
    LinkedEmptyPatch = 'linkedEmptyPatch',
    TempPatch  = 'tempPatch',
}

/*----------------------------------------------------------------------------*/
class DummyAttachmentSerializer {
    private _dummyAttachment = { url:"" };

    serializeAttachment(file: FileDescriptor, entityId:string, attachmentKey:string): SerializedJSONAttachment {
        return this._dummyAttachment;
    }
    deserializeAttachment(json:SerializedJSONAttachment, entityId:string, attachmentKey:string): FileDescriptor {
        throw new NotImplementedError('deserializeAttachment', 'DummyAttachmentSerializer');
    }
}

export class MutablePatch implements Patch {
    /** patch header */
    header: PatchHeader;
    /** entity operations by entity type name */
    operationsByType: Map<string, MutableEntityOperations>;

    constructor(header: PatchHeader) {
        this.header = header;
        this.operationsByType = new Map<string, MutableEntityOperations>();
    }

    isEmpty(entityContext:EntityContext): boolean {
        let empty = true;
        this.operationsByType.forEach(function(operations, entityName) {
            if(operations.operationById.size !== 0)
                empty = false;
        });
        // TODO remove me when this operation works safely
        // sanity check
        if (!empty && (exportPatch(entityContext, new DummyAttachmentSerializer(), this) == null)) {
            empty = true;
            log.error("fix errorneous check 'isEmpty'");
        }

        return empty;
    }
}

export class MutablePatchHeader {
    /** minimum model version required to read this patch */
    modelMinVersion:number;
    /** model version of this patch */
    modelVersion:number;
    /** client name */
    clientName?:string;
    /* user info */
    user?: UserInfo;
    /* temp patch */
    temp:boolean;
    /* if true, represent an empty patch from a regular patch point of view but is not as a temp patch */
    emptyLike:boolean;

    constructor() {
        this.modelMinVersion = null;
        this.modelVersion = null;
        this.clientName = null;
        this.user = null;
        this.temp = false;
    }
}

export class MutableEntityOperations {
    constructor() {
        this.operationById = new Map<string, MutableEntityOperation>();
    }

    /** entity operations by entity id */
    readonly operationById: Map<string, MutableEntityOperation>;

}

export class MutableAttachmentUpdate implements AttachmentUpdate {
    file:FileDescriptor;
    readonly deleted:false;

    constructor(file:FileDescriptor) {
        this.file = file;
        this.deleted = false;
    }
}

export class MutableAttachmentDelete implements AttachmentDelete {
    readonly deleted:true;

    constructor() {
        this.deleted = true;
    }
}

export type MutableAttachmentOperation = MutableAttachmentUpdate | MutableAttachmentDelete;


export class MutableEntityUpdate implements EntityUpdate {
    readonly type: 'update';
    readonly propertyUpdates: Map<string, any>;
    readonly attachmentUpdates: Map<string, MutableAttachmentOperation>;

    constructor() {
        this.type = 'update';
        this.propertyUpdates = new Map<string, any>();
        this.attachmentUpdates = new Map<string,MutableAttachmentOperation>();
    }
}

export class MutableEntityCreate implements EntityCreate {
    readonly type: 'create';
    readonly propertyUpdates: Map<string, any>;
    readonly attachmentUpdates: Map<string, MutableAttachmentOperation>;

    constructor() {
        this.type = 'create';
        this.propertyUpdates = new Map<string, any>();
        this.attachmentUpdates = new Map<string,MutableAttachmentOperation>();
    }
}

export class MutableEntityDelete implements EntityDelete {
    readonly type: 'delete';

    constructor() {
        this.type = 'delete';
    }
}

export type MutableEntityOperation = MutableEntityUpdate | MutableEntityCreate | MutableEntityDelete;

/*----------------------------------------------------------------------------*/

export const PatchHandlerContext = {
    UNKNOWN: 'UNKNOWN',
    SYNCHRONIZE: 'SYNCHRONIZE',
    TEMP: 'TEMP',
    UPGRADE: 'UPGRADE',
    DUMP_PATCH: 'DUMP_PATCH',
    PROJECT_SYNC: 'PROJECT_SYNC',
} as const;

const PatchHandlerContextEnum = z.nativeEnum(PatchHandlerContext);
export type PatchHandlerContext = z.infer<typeof PatchHandlerContextEnum>;

/**
 * Each patch handler module must export a 'handler' property that implements the
 * following interface.
 */
export interface PatchHandler {
    /** called before applying a list of patches on the orm */
    willImportPatches?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void>;
    /** called after applying a list of patches on the orm */
    endImportPatches?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void>;
    /** called before creating a patch */
    willCreatePatch?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void>;

    /** called before exporting a patch */
    willExportPatch?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch:MutablePatch, patchId?: PatchId): Promise<void>;
    /** called before applying a patch on the orm */
    willImportPatch?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch:MutablePatch, patchId?: PatchId): Promise<void>;
    /** called after applying the patch on the orm */
    didImportPatch?(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch:MutablePatch, patchId?: PatchId): Promise<void>;
    
}

type PatchHandlerEventType = keyof PatchHandler;

export type PatchHandlerEventRelativeToPatch = Extract<PatchHandlerEventType, 'willImportPatch' | 'didImportPatch' | 'willExportPatch' >;
function isPatchHandlerEventRelativeToPatch(event: PatchHandlerEventType): event is PatchHandlerEventRelativeToPatch {
    return event === 'willImportPatch' || event === 'didImportPatch' || event === 'willExportPatch';
}

export type PatchHandlerEvent= Extract<PatchHandlerEventType, 'willImportPatches' | 'endImportPatches' | 'willCreatePatch' >;
function isPatchHandlerEvent(event: PatchHandlerEventType): event is PatchHandlerEvent {
    return event === 'willImportPatches' || event === 'endImportPatches' || event === 'willCreatePatch';
}

export class PatchHandlerService {
    constructor() {
        this.patchHandlers = [];
    }

    /** patch handlers */
    readonly patchHandlers: PatchHandler[];

    /**
     * Dispatch the given event on the patch handlers.
     * PatchHandlerEventRelativeToPatch --> This event is applied on each patch.
     * PatchHandlerEventRelativeToPatches --> This event is applied once per list of patches.
     *
     * The patch handlers are dynamically loaded modules which can alter patches
     * before or after they are imported or exported from the orm.
     *
     * @param event the event to dispatch
     * @param entityContext the current entity context
     * @param projectId the root entity entity in the context
     * @param patch the patch which is the object of the event
     */
          executePatchHandlers(event:PatchHandlerEvent,                  entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void>;
          executePatchHandlers(event:PatchHandlerEventRelativeToPatch,   entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch:MutablePatch,  patchId?: PatchId): Promise<void>;
    async executePatchHandlers(event:PatchHandlerEventType,              entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch?:MutablePatch, patchId?: PatchId): Promise<void> {
        // dispatch the event in turn to each handler that implements the method
        for (const patchHandler of this.patchHandlers) {
            if (!patchHandler[event]) {
                continue;
            }
            if (isPatchHandlerEventRelativeToPatch(event)) {
                const fn = patchHandler[event].bind(patchHandler);
                await fn(entityContext, projectId, patchHandlerContext, patch, patchId);
            } else if (isPatchHandlerEvent(event)) {
                const fn = patchHandler[event].bind(patchHandler);
                await fn(entityContext, projectId, patchHandlerContext);
            }
        }
    }

    registerPatchHandler(patchHandler:PatchHandler) {
        this.patchHandlers.push(patchHandler);
    }
}

/*----------------------------------------------------------------------------*/

/**
 * Export a patch to network format (a JSON serializable structure)
 *
 * If the attachmentSerializer then 
 * 
 * @param entityContext the orm context
 * @param attachmentSerializer attachment serializer to use for attachments
 * @param patch the patch to export
 * @return the patch and the attachment manifest in network representation
 */
export function exportPatch(entityContext:EntityContext, attachmentSerializer:AttachmentSerializer, patch:MutablePatch) : NetPatch | null {
    if(!patch)
        return null;

    const header : NetPatchHeader = {
        diff : true,
        minVersion : patch.header.modelMinVersion,
        version : patch.header.modelVersion,
    };

    if (patch.header.isDump) {
        header.diff = false;
    }

    if(patch.header.clientName)
        header.client = patch.header.clientName;
    if(patch.header.user)
        header.user = patch.header.user;

    if(patch.header.temp) {
        header.temp = true;
        header.tempData = {
            emptyLike : patch.header.emptyLike,
        };
    }

    const patchData:[NetPatchHeader, ...Array<NetPatchOperations>] = [ header ];
    const attachmentManifest = {};

    const entityTypes = entityContext.entityTypes;
    entityTypes.byIndex.forEach(function(entityType) {
        if(!entityType.export)
            return; // non exportable entities

        const entityName = entityType.entityName;
        const metadata = entityType.metadata;
        const patchOperations:NetPatchOperations = [ entityName ];

        const operations = patch.operationsByType.get(entityName);
        if(!operations || operations.operationById.size === 0)
            return;

        const markedOperations: Set<string> = new Set();

        function processOperation(operation: EntityOperation, entityId: string) {
            if (entityId === null || markedOperations.has(entityId)) {
                return;
            }

            switch (operation.type) {
                case 'create':
                case 'update': {
                    const patchOperation = {
                        id: entityId
                    };

                    if (operation.type === 'update') {
                        patchOperation['_metadata'] = {op: 'update'};
                    }

                    // for each property to update
                    operation.propertyUpdates.forEach((propertyValue, propertyName) => {
                        const property = metadata[propertyName];

                         // the temp patch ignores and forces import and export flags
                        if( ! (property.exportable || (patch.header.temp && property.export!=ExportKind.cache) ) )
                            return;   // skip property

                        // the order of EntityOperations is not guaranteed so this code is required to make sure parent Remarks are created before they are referenced by child remarks.
                        if (propertyValue && property.relation && property.single && property.target.entityName === entityName) {
                            // - if the updated property is a relation that points to the same entity type (e.g: a Remark that references another Remark through parentRemark)
                            // - if there is an operation on the target entity in the same patch
                            // then put the operation on the target entity first in the patch.
                            const operation = operations.operationById.get(propertyValue);
                            if(operation)
                                processOperation(operation, propertyValue);
                        }
                        
                        patchOperation[propertyName] = propertyValue;
                    });

                    // add attachment updates
                    if (operation.attachmentUpdates.size !== 0) {
                        attachmentManifest[entityId] = {};
                    }

                    operation.attachmentUpdates.forEach((update, key) => {
                        if (update.deleted === true) {
                            attachmentManifest[entityId][key] = {deleted: true};
                        } else {
                            attachmentManifest[entityId][key] = attachmentSerializer.serializeAttachment(update.file, entityId, key);
                        }
                    });

                    if (operation.propertyUpdates.size !== 0 || operation.attachmentUpdates.size !== 0) {
                        patchOperations.push(patchOperation);
                    }
                } break;
                case 'delete': {
                    patchOperations.push({
                        id: entityId,
                        _metadata: {op: 'delete'}
                    });
                } break;
            }

            markedOperations.add(entityId);
        }
        operations.operationById.forEach(processOperation);

        if(patchOperations.length > 1)
            patchData.push(patchOperations);
    });

    if(patchData.length === 1)
        return null;
    return { patch:patchData, attachmentManifest:attachmentManifest };
}

/**
 * Import a patch from network format
 *
 * @param entityContext the entity context
 * @param attachmentSerializer attachment serializer to use for attachments
 * @param patchData the patch data in network format
 * @param attachmentManifest the attachment manifest in network format
 * @return the patch
 */
export function importPatch(entityContext:EntityContext, attachmentSerializer:AttachmentSerializer, patchData:NetPatchData, attachmentManifest?:NetPatchAttachmentManifest):MutablePatch {
    if(!isNetPatchData(patchData)) {
        throw new IllegalArgumentError("Bad patch data");
    }

    const patchHeader = patchData[0];
    if(patchHeader['diff'] !== true || !patchHeader['minVersion'] || !patchHeader['version']) {
        log.warn("Bad patch header, autofixing", patchHeader);
        if(patchHeader['diff'] !== true)
            patchHeader['diff'] = true;
        if(!patchHeader['minVersion'])
            patchHeader['minVersion'] = entityContext.modelMinVersion;
        if(!patchHeader['version'])
            patchHeader['version'] = entityContext.modelVersion;
    }

    const header = new MutablePatchHeader();
    header.modelMinVersion = patchHeader['minVersion'];
    header.modelVersion = patchHeader['version'];
    header.clientName = patchHeader['clientName'];
    header.user = Object.assign({}, patchHeader['user']);
    header.temp = !! patchHeader['temp'];
    header.emptyLike = false;

    const patch = new MutablePatch(header);

    for(let i=1;i<patchData.length;i++) {
        const patchOperations = patchData[i];
        if(!Array.isArray(patchOperations) || patchOperations.length === 0 || typeof patchOperations[0] !== 'string')
            throw new PatchError('Bad patch operation list');

        const entityName = patchOperations[0];

        let operations = patch.operationsByType.get(entityName);
        if(!operations) {
            operations = new MutableEntityOperations();
            patch.operationsByType.set(entityName, operations);
        }

        for(let j=1;j<patchOperations.length;j++) {
            const patchOperation = patchOperations[j];
            if(typeof patchOperation !== 'object' || typeof patchOperation['id'] !== 'string')
                throw new PatchError('Bad patch operation');

            const entityId = patchOperation['id'];
            let isCreate = true;

            if(patchOperation._metadata) {
                if(patchOperation._metadata['op'] === 'delete') {
                    operations.operationById.set(entityId, new MutableEntityDelete());
                    continue;
                } else if(patchOperation._metadata['op'] === 'update') {
                    isCreate = false;
                }
            }

            const operation = isCreate ? new MutableEntityCreate() : new MutableEntityUpdate();
            for(const propertyName in patchOperation) {
                if(propertyName === 'id' || propertyName === '_metadata')
                    continue;
                operation.propertyUpdates.set(propertyName, patchOperation[propertyName]);
            }

            if(attachmentManifest) {
                const entityAttachments = attachmentManifest[entityId];
                if(entityAttachments) {
                    for(const attachmentKey in entityAttachments) {
                        const attachment = entityAttachments[attachmentKey];

                        if(attachment.deleted === true) {
                            operation.attachmentUpdates.set(attachmentKey.normalize('NFD'), new MutableAttachmentDelete());
                        } else {
                            // normalize the key and the attachment url to UTF-8 form D in patches because
                            // archipad sometimes writes the manifest in form C and stores files
                            // in form D on S3. (maps created from a photo).
                            if(attachment.url)
                                attachment.url = attachment.url.normalize('NFD');
                            
                            const attachmentFile = attachmentSerializer.deserializeAttachment(attachment, entityId, attachmentKey);
                            operation.attachmentUpdates.set(attachmentKey.normalize('NFD'), new MutableAttachmentUpdate(attachmentFile));
                        }
                    }
                }
            }

            operations.operationById.set(entityId, operation);
        }
    }

    return patch;
}

/**
 * Shallow checks the given {@link NetPatchData} to establish if its a valid one
 * or not.
 */
export function isNetPatchData(netPatchData: unknown): netPatchData is NetPatchData {
    if (!Array.isArray(netPatchData)) {
        return false;
    }

    if (netPatchData.length === 0) {
        return false;
    }

    const patchHeader = netPatchData[0] as unknown;
    if (!isPlainObject(patchHeader)) {
        return false;
    }

    const firstNetPatchOperations = netPatchData[1] as unknown;
    if (!Array.isArray(firstNetPatchOperations)) {
        return false;
    }

    const firstEntityName = firstNetPatchOperations[0];
    if (typeof firstEntityName !== 'string') {
        return false;
    }

    const firstEntityOperations = firstNetPatchOperations[1];
    if (!isPlainObject(firstEntityOperations) || !('id' in firstEntityOperations)) {
        return false;
    }
    
    return true;
}

/*----------------------------------------------------------------------------*/

/**
 * Apply the changes in a patch in an entity context
 *
 * @param entityContext the entity context
 * @param patch the patch to apply
 */
export function applyPatch(entityContext:EntityContext, patch:Patch): void {
    const entityTypes = entityContext.entityTypes;

    entityContext.ensureModelMinVersion(patch.header.modelMinVersion);

    for(const entityType of entityTypes.byIndex) {
        if(!entityType.import)
            continue;   // non importable entities

        const entityName = entityType.entityName;
        const operations = patch.operationsByType.get(entityName);
        if(!operations)
            continue;
        operations.operationById.forEach(function(operation, entityId) {
            switch(operation.type) {
                case 'delete': {
                    // delete the entity
                    const object = entityContext.getEntity(entityName, entityId);
                    if(object)
                        entityContext.deleteEntity(object);
                } break;

                case 'create':
                case 'update': {
                    let object = entityContext.getEntity(entityName, entityId);
                    if(!object) {
                        if(operation.type === 'update')
                            return; // ignore updates on non existing entities

                        object = entityContext.createEntity(entityName, entityId);
                        if(!object)
                            throw new ORMError('Cannot create entity of type "' + entityName + '"');
                    } else {
                        // reset transient properties to their default values (used for properties that are reset after a push)
                        for(const k in entityType.metadata) {
                            const property = entityType.metadata[k];
                            if(property.relation===false && property.transient)
                                object[k] = object.getDefaultValueFor(property);
                        }
                    }

                    // apply patch operation
                    operation.propertyUpdates.forEach(function(val, k) {
                        const property = entityType.metadata[k];
                        if(!property)
                            return; // no such property, ignore

                        if (property.calculated) {
                            return;
                        }

                        // the temp patch ignores and forces import and export flags
                        if( ! (property.importable || patch.header.temp) )
                            return; // ignore property

                        if(property.relation===true) {
                            const relation = property;
                            if(!entityTypes.byName[relation.target.entityName])
                                return;

                            if(val === null) {
                                object[k] = null;
                            } else {
                                const target = entityContext.getEntity(relation.target.entityName,val);
                                if(!target) {
                                    log.warn(`missing entity reference "${object.entityType.entityName}.${k}" to "${relation.target.entityName}(${val})"`);
                                } else {
                                    object[k] = target;
                                }
                            }
                        } else {
                            if (property.primaryKey)
                                return;

                            if(val === null) {
                                object[k] = null;
                                return;
                            }

                            // ensure that type of the value is the expected one
                            switch(property.type) {
                                case 'number': {
                                    const v = Number(val);
                                    if(!isNaN(v)) {
                                        object[k] = v;
                                    } else {
                                        log.warn('** Bad number value in patch', val);
                                        object[k] = null;
                                    }
                                } break;
                                case 'date': {
                                    object[k] = parseDate(val);
                                } break;
                                case 'string': {
                                    object[k] = String(val);
                                } break;
                                case 'boolean': {
                                    object[k] = val ? true : false;
                                } break;
                                default: {
                                    object[k] = val;
                                } break;
                            }
                        }
                    });

                    // apply attachment operations
                    operation.attachmentUpdates.forEach(function(attachmentUpdate, attachmentId) {
                        if(attachmentUpdate.deleted === true) {
                            object.setAttachmentForKey(attachmentId, null);
                        } else {
                            object.setAttachmentForKey(attachmentId, attachmentUpdate.file);
                        }
                    });
                } break;
            }
        });
    }
}


/**
 * Substract from a patch another compatible patch
 * 
 * @param entityContext the entity context
 * @param patch the patch to modify
 * @param patch2 the patch to substract
 */
export function minusPatch(entityContext:EntityContext, patch:MutablePatch, patch2:Patch):void {
    patch.operationsByType.forEach((unused:MutableEntityOperations, entityName:string) => {
        const operations2:EntityOperations = patch2.operationsByType.get(entityName);
        if (operations2 == null || operations2.operationById.size == 0)
            return;
        const operations:MutableEntityOperations = patch.operationsByType.get(entityName);

        operations.operationById.forEach((unused:MutableEntityOperation, entityId:string) => {
            const operation:MutableEntityOperation = operations.operationById.get(entityId);
            const operation2:EntityOperation = operations2.operationById.get(entityId);

            if (operation2 == null)
                return;

            if (operation.type != operation2.type)
                throw new PatchError("incompatible operands");

            if (operation.type === 'delete') {
                operations.operationById.delete(entityId);
            }
            else if (operation.type === 'create') {
                // TODO check each property value
                operations.operationById.delete(entityId);
            }
            else if (operation.type === 'update') {
                // TODO check each property value
                const operation2U:EntityUpdate = operation2 as EntityUpdate;
                operation.propertyUpdates.forEach((unused:any, propertyName:string)=> {
                    if ( ! operation2U.propertyUpdates.has(propertyName) )
                        return;
                    operation.propertyUpdates.delete(propertyName);
                });
                operation.attachmentUpdates.forEach((unused:MutableAttachmentUpdate, attachmentKey:string) => {
                    if ( ! operation2U.attachmentUpdates.has(attachmentKey) )
                        return;
                    operation.attachmentUpdates.delete(attachmentKey);
                });
                if (operation.propertyUpdates.size == 0 && operation.attachmentUpdates.size == 0) {
                    operations.operationById.delete(entityId);
                }
            }
        });
    });
}


/**
 * Reset the ORM commit of a patch
 * 
 * @param entityContext the entity context
 * @param patch the patch
 */
export function resetCommitPatch(entityContext:EntityContext, patch:Patch):void {
    patch.operationsByType.forEach((operations:EntityOperations, entityName:string)=> {
        operations.operationById.forEach((operation:EntityOperation, entityId:string) => {
            if (operation.type === 'delete') {
                const entity = entityContext.getEntity(entityName, entityId);
                if (entity != null)
                    throw new PatchError("cannot reset commit entity delete : entity exists");
                entityContext['_deletedEntities'][entityName][entityId] = true;
            }
            else if (operation.type === 'create') {
                const entity = entityContext.getEntity(entityName, entityId);
                if (entity == null)
                    throw new PatchError("cannot reset commit entity create : entity does not exist");
                if (operation.attachmentUpdates.size != 0)
                    throw new PatchError("cannot reset commit entity create : entity has attachments : not supported");
                entityContext['_createdEntities'][entityName][entityId] = entity;
            }
            else if (operation.type === 'update') {
                throw new PatchError("cannot reset commit entity update : not supported");
            }
        });
    });
}


/**
 * Extract the changes from the entity context to a patch
 *
 * @param entityContext the entity context
 * @param patchHeader the patch header
 * @return the new patch
 */
export function extractPatch(entityContext:EntityContext, patchHeader:PatchHeader):MutablePatch {
    const patch = new MutablePatch(patchHeader);

    const entityTypes = entityContext.entityTypes;
    for(const entityType of entityTypes.byIndex) {
        const entityName = entityType.entityName;

        let operations = patch.operationsByType.get(entityName);
        if(!operations) {
            operations = new MutableEntityOperations();
            patch.operationsByType.set(entityName, operations);
        }

        const metadata = entityType.metadata;

        // deletions
        const deletedEntitites = entityContext.deletedEntities[entityName];
        for(const entityId in deletedEntitites)
            operations.operationById.set(entityId, new MutableEntityDelete());

        // creations
        const createdEntities = entityContext.createdEntities[entityName];
        for(const entityId in createdEntities) {
            const operation = new MutableEntityCreate();
            const entity = createdEntities[entityId];

            for(const name in metadata) {
                const property = metadata[name];
                if (property.calculated) {
                    continue;
                }

                const val = entity[name];

                if (val === undefined) {
                    throw new ORMError(`Illegal attempt to create an entity "${entityName}" with a property "${property.name}" set to 'undefined'.`);
                }

                if(property.relation) {
                    const relation = property;
                    if(!relation.single)
                        continue;   // only single relationship are supported
                    operation.propertyUpdates.set(name, val ? val.id : null);
                } else if(!property.relation) {
                    operation.propertyUpdates.set(name, val);
                }
            }

            operations.operationById.set(entityId, operation);
        }

        // modifications
        const modifiedEntitites = entityContext.modifiedEntities[entityName];
        for(const entityId in modifiedEntitites) {
            const originalValues = modifiedEntitites[entityId];
            const currentEntity = entityContext.getEntity(entityName, entityId);

            const operation = new MutableEntityUpdate();
            for(const name in metadata) {
                const property = metadata[name];
                if (property.calculated) {
                    continue;
                }
                if( ! (property.exportable || (patchHeader.temp && property.export!=ExportKind.cache && !property.relation)) )
                    continue;
                if(!(name in originalValues))
                    continue;

                const newValue = currentEntity[name];
                const oldValue = originalValues[name];

                if (newValue === undefined) {
                    throw new ORMError(`Illegal attempt to update an entity "${entityName}" with a property "${property.name}" set to 'undefined'.`);
                }

                if(property.relation===true) {
                    const relation = property;
                    if(!relation.single)
                        continue;   // only single relationship are supported
                    if(oldValue !== newValue)
                        operation.propertyUpdates.set(name, newValue ? newValue.id : null);
                } else {
                    if (oldValue !== newValue) {
                        // in case of date we when to compare timestamp
                        if ((property.type !== 'date' || !oldValue || !newValue || (oldValue as Date).getTime() !== (newValue as Date).getTime())) {
                            operation.propertyUpdates.set(name, currentEntity[name]);
                        }
                    }
                }
            }

            operations.operationById.set(entityId, operation);
        }

        // attachments
        const createdAttachments = entityContext.createdAttachments[entityName];
        for(const entityId in createdAttachments) {
            const operation = operations.operationById.get(entityId);
            if(!operation || operation.type === 'delete')
                continue;
            const attachments = createdAttachments[entityId];
            for(const attachmentKey in attachments) {
                const attachment = attachments[attachmentKey];
                operation.attachmentUpdates.set(attachmentKey, new MutableAttachmentUpdate(attachment));
            }
        }
        const updatedAttachments = entityContext.updatedAttachments[entityName];
        for(const entityId in updatedAttachments) {
            const operation = operations.operationById.get(entityId);
            if(!operation || operation.type === 'delete')
                continue;
            const attachments = updatedAttachments[entityId];
            for(const attachmentKey in attachments) {
                const attachment = attachments[attachmentKey];
                if(!attachment)
                    operation.attachmentUpdates.set(attachmentKey, new MutableAttachmentDelete());
                else
                    operation.attachmentUpdates.set(attachmentKey, new MutableAttachmentUpdate(attachment));
            }
        }

        // sanity check on modifications
        operations.operationById.forEach(function(operation:MutableEntityOperation, entityId:string) {
            if (operation.type === 'update' && operation.propertyUpdates.size == 0 && operation.attachmentUpdates.size == 0) {
                operations.operationById.delete(entityId);
            }
        });

    }

    return patch;
}

/**
 * Extract all entities and attachments from the entity context into a patch
 *
 * @param entityContext - the entity context
 * @param patchHeader - the patch header
 * @return the dump patch
 */
export function extractDumpPatch(entityContext: EntityContext, patchHeader: PatchHeader): MutablePatch {
    const patch = new MutablePatch(patchHeader);

    for (const entityType of entityContext.entityTypes.byIndex) {
        const metadata = entityType.metadata;
        const entityName = entityType.entityName;

        let operations = patch.operationsByType.get(entityName);
        if (!operations) {
            operations = new MutableEntityOperations();
            patch.operationsByType.set(entityName, operations);
        }

        const entities = entityContext.entitiesByType[entityName];

        for (const entityId in entities) {
            const entity = entities[entityId];

            const operation = new MutableEntityCreate();

            for (const name in metadata) {
                const property = metadata[name];
                if (property.calculated) {
                    continue;
                }

                const val = entity[name];

                if (val === undefined) {
                    throw new ORMError(`Illegal attempt to create an entity "${entityName}" with a property "${property.name}" set to 'undefined'.`);
                }

                // ignore non exportable properties
                if (!property.exportable) {
                    continue;
                }

                if (property.relation) {
                    const relation = property;

                    if (!relation.single) {
                        continue;   // only single relationship are supported
                    }

                    operation.propertyUpdates.set(name, val ? val.id : null);
                } else {
                    operation.propertyUpdates.set(name, val);
                }
            }

            const attachments = entity.getAttachments();

            for (const attachmentKey in attachments) {
                const attachment = attachments[attachmentKey];
                operation.attachmentUpdates.set(attachmentKey, new MutableAttachmentUpdate(attachment));
            }

            operations.operationById.set(entityId, operation);
        }
    }

    return patch;
}

/**
 * Extract all entities attachments from the entity context into a AttachmentManifest
 */
export function extractDumpManifest(entityContext: EntityContext, attachmentSerializer:AttachmentSerializer): NetPatchAttachmentManifest {
    const attachmentManifest = {};
    const entityTypes = entityContext.entityTypes;
    for(const entityType of entityTypes.byIndex) {
        const entityName = entityType.entityName as keyof EntityTypeMap;

        for (const entityId in entityContext.entitiesByType[entityName]) {
            const entity = entityContext.getEntity(entityName, entityId);
            const attachments = entity.getAttachments();
            if (Object.keys(attachments).length){
                attachmentManifest[entityId] = {};
            }
            for (const attachmentKey in attachments) {
                const attachment = attachments[attachmentKey];
                attachmentManifest[entityId][attachmentKey] = attachmentSerializer.serializeAttachment(attachment, entityId, attachmentKey);
            }
        }
    }
    return attachmentManifest;
}

/*----------------------------------------------------------------------------*/
export interface SerializedJSONAttachment {
    url: string;
    mimeType?: string;
    hash?: string;
    size?: number;
}

/**
 * Attachment serializer interface
 * 
 * An attachment serializer specifies the format of a path attachment manifest. 
 * Whether you store `temp:`, `patch:` url or paths relative to a patch directory.
 */
export interface AttachmentSerializer {
    /**
     * Transform a file descriptor into a SerializedJSONAttachment
     */
    serializeAttachment(file: FileDescriptor, entityId:string, attachmentKey:string): SerializedJSONAttachment;
    /**
     * Transform a SerializedJSONAttachment back into a file descriptor
     */
    deserializeAttachment(json:SerializedJSONAttachment, entityId:string, attachmentKey:string): FileDescriptor;
}

/**
 * Temp patch attachment serializer
 * 
 * transforms "temp:" and "patch:" urls into PatchFileDescriptor 
 */
export class TempPatchAttachmentSerializer implements AttachmentSerializer {
    private _tempPatch: PatchDescriptor;
    private _patchesDir: PatchesDescriptor;

    private _patchesById: Map<string, PatchDescriptor>;

    constructor(projectId:string) {
        const tempDir = new PatchesDescriptor(projectId, "temp");
        const patchesDir = new PatchesDescriptor(projectId, "patch");
        
        this._tempPatch = new PatchDescriptor(tempDir, "current"); // temp patch attachments are all located in patch named "current"
        this._patchesDir = patchesDir;
        this._patchesById = new Map();
    }

    private _getPatch(patchId:string) {
        let patch = this._patchesById.get(patchId);
        if(!patch) {
            patch = new PatchDescriptor(this._patchesDir, patchId);
            this._patchesById.set(patchId, patch)
        }

        return patch;
    }

    public get directory(): PatchDescriptor {
        return this._tempPatch;
    }

    /**
     * Compatibility with old attachment format: attempt to guess the file descriptor type from the url
     * 
     * @param url 
     */
    private _oldUrlCompatibility(url:string): PatchAttachmentDescriptor {
        let ret = url.match(/^[^\/]+\/projects\/[0-9a-f]{16}\/([^\/]+)\/([0-9]+)\/(.*)$/);
        if(ret) {
            // url is in the form: 215-4f86e3394bc76-bigsool/projects/61fa643498ea1f57/00000154aa262c48.0ab59388/7060065542923231063/reports/levée_des_réserves__b80__r1_ouest--1462260445-0/report.xlsx
            // this is a patch file
            const patch = this._getPatch(ret[1]);
            return new PatchAttachmentDescriptor(patch, ret[2], ret[3]);
        }

        ret = url.match(/^[^\/]+\/assets\/projects\/[0-9a-f]{32}\/current\/([0-9]+)\/(.*)$/);
        if(ret) {
            // url is in the form: 215-4f86e3394bc76-bigsool/assets/projects/000000000000000047b1f203d06c9dc1/current/907175279255307749/qwe.pdf
            // this is a temp patch file
            return new PatchAttachmentDescriptor(this._tempPatch, ret[1], ret[2]);
        }

        ret = url.match(/^[^\/]+\/assets\/projects\/[0-9a-f]{32}\/users\/[0-9]+\/current\/([0-9]+)\/(.*)$/);
        if(ret) {
            // url is in the form: 215-4f86e3394bc76-bigsool/assets/projects/000000000000000047b1f203d06c9dc1/users/20476/current/907175279255307749/qwe.pdf
            // this is a temp patch file
            return new PatchAttachmentDescriptor(this._tempPatch, ret[1], ret[2]);
        }

        throw new PatchError(`Invalid temp patch url: "${url}"`);

    }

    serializeAttachment(file: PatchAttachmentDescriptor, entityId:string, attachmentKey:string): SerializedJSONAttachment {
        if(!(file instanceof PatchAttachmentDescriptor))
            throw new IllegalArgumentError(`Invalid attachment file`);
        
        const json:SerializedJSONAttachment = { url:null };
        if(file.type === "temp") {
            json.url = `temp:${file.entityId}/${file.attachmentPath}`
        } else if(file.type === "patch") {
            json.url = `patch:${file.patch.patchId}/${file.entityId}/${file.attachmentPath}`
        } else {
            throw new PatchError('Invalid file type in temp patch')
        }

        if(file.mimeType)
            json.mimeType = file.mimeType;
        if(file.fileSize)
            json.size = file.fileSize;
        if(file.hash)
            json.hash = file.hash;

        return json;
    }

    deserializeAttachment(json:SerializedJSONAttachment, entityId:string, attachmentKey:string): PatchAttachmentDescriptor {
        let ret: RegExpMatchArray;
        let file:PatchAttachmentDescriptor;

        ret = json.url.match(/^temp:([0-9]+)\/(.*)$/);
        if(ret) {
            // temp url
            file = new PatchAttachmentDescriptor(this._tempPatch, ret[1], ret[2]);
        } else {
            ret = json.url.match(/^patch:([^\/]+)\/([0-9]+)\/(.*)$/);
            if(ret) {
                // patch url
                const patch = this._getPatch(ret[1]);
                file = new PatchAttachmentDescriptor(patch, ret[2], ret[3]);
            } else {
                // compatibility with current temp patches
                return this._oldUrlCompatibility(json.url);
            }
        }

        if(json.mimeType)
            file.mimeType = json.mimeType;
        if(json.size)
            file.fileSize = json.size;
        if(json.hash)
            file.hash = json.hash;

        return file;
    }
}

// TODO @deprecated, use DI instead
export default new PatchHandlerService();