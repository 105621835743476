import { LoggerManager } from "@archipad-js/core/logger";
import {
    AttributeTypeConf,
    ExportKind,
    ValuePropertyType,
} from "./types";

export const logger = LoggerManager.getLogger("ORM");

export function convertType(type: AttributeTypeConf): ValuePropertyType | null {
    switch (type) {
        case "int16":
            return "number";
        case "int32":
            return "number";
        case "int64":
            return "string";
        case "decimal":
            return "number";
        case "double":
            return "number";
        case "float":
            return "number";
        case "string":
            return "string";
        case "bool":
            return "boolean";
        case "date":
            return "date";
        case "binary":
            return "string";
        case "transformable":
            return "object";
        case "undefined":
            return "object";
        case "uuid":
            return "string";
        default:
            return null;
    }
}

export function convertBoolean(value: unknown, defaultValue: boolean): boolean {
    return value == null ? defaultValue : Boolean(value);
}

export const kExportKindValueByName: Map<string, ExportKind> = new Map([
    ["no", ExportKind.no],
    ["yes", ExportKind.yes],
    ["clearOnExport", ExportKind.clearOnExport],
    ["cache", ExportKind.cache],
]);

export const kExportKindExportableByValue: Record<ExportKind, boolean> = {
    [ExportKind.no]: false,
    [ExportKind.yes]: true,
    [ExportKind.clearOnExport]: true,
    [ExportKind.cache]: false,
};

export function convertExportKind(
    value: string | boolean | number | null | undefined,
    defaultValue: ExportKind,
): ExportKind {
    let result: ExportKind;

    if (value == null) {
        result = defaultValue;
    } else if (typeof value === "boolean" || typeof value === "number") {
        result = value ? ExportKind.yes : ExportKind.no;
    } else if (typeof value === "string") {
        const numValue = kExportKindValueByName.get(value);
        if (numValue) {
            result = numValue;
        } else {
            logger.error("unknown ExportKind | value:", value);
            result = ExportKind.no;
        }
    } else {
        logger.error("unknown ExportKind | value:", value);
        result = ExportKind.no;
    }

    return result;
}
