import * as IdGenerator from '@core/services/orm/idGenerator';

import { BugEntity } from '@archipad-models/models/BugEntity';
import { BugNoteEntity } from '@archipad-models/models/BugNoteEntity';
import { RemarkEntity } from '@archipad-models/models/RemarkEntity';
import { RemarkAnnotationEntity } from '@archipad-models/models/RemarkAnnotationEntity';
import { BugAnnotationEntity } from '@archipad-models/models/BugAnnotationEntity';
import { BugNoteAnnotationEntity } from '@archipad-models/models/BugNoteAnnotationEntity';
import { EntityType } from '@archipad-js/core/orm';
import { BaseEntity, EntityContext, predicateDelegateFor } from '@core/services/orm/orm';
import { OrderByPredicate } from '@archipad-js/core/query';

export type AnnotatedEntity = BugEntity | BugNoteEntity | RemarkEntity;
export type AnnotationEntity = BugAnnotationEntity | BugNoteAnnotationEntity | RemarkAnnotationEntity;


/**
 * Responsible of all CRUD operations on {@link AnnotationEntity annotations entities}.
 */
export class AnnotationService {
    makeAnnotationForAnnotated(parent: AnnotatedEntity): AnnotationEntity {
        const entityContext: EntityContext = parent.getContext();
        
        const annotationEntity: AnnotationEntity = entityContext.createEntity(`${parent.entityType.entityName}Annotation`);
        annotationEntity.parentAnnotatedEntity = parent;

        return annotationEntity;
    }

    makeLegacyAnnotationForAnnotated(parent: AnnotatedEntity): AnnotationEntity {
        const entityContext: EntityContext = parent.getContext();
        const legacyStableId = this._getAnnotationId(parent);
        
        /** 
         *  @WORKAROUND
         *  Currently getOrCreate but it must be create only
         *  Fix https://bigsool-archipad.atlassian.net/browse/AP-8061 before
         */
        let annotationEntity: AnnotationEntity = entityContext.getEntity(`${parent.entityType.entityName}Annotation`, legacyStableId);
        if(!annotationEntity) {
            annotationEntity = entityContext.createEntity(`${parent.entityType.entityName}Annotation`, legacyStableId);
            annotationEntity.temp = true;
            annotationEntity.hasMetadata = false;
            annotationEntity.parentAnnotatedEntity = parent;
        }
        
        return annotationEntity;
    }

    getLegacyAnnotation(annotatedEntity: AnnotatedEntity): AnnotationEntity | null {
        const annotations = this.getAnnotations(annotatedEntity);
        if(annotations.length < 1) {
            return null;
        }

        const legacyAnnotationIndex = annotations.findIndex((annotation) => this.isLegacyAnnotationEntity(annotation));
        return legacyAnnotationIndex >= 0 
            ? annotations[legacyAnnotationIndex]
            : null;
    }

    /**
     * Check if the given entity is an AnnotationEntity
     */
    isAnnotationEntity(entity: BaseEntity): entity is AnnotationEntity {
        return entity instanceof BugAnnotationEntity
            || entity instanceof BugNoteAnnotationEntity
            || entity instanceof RemarkAnnotationEntity;
    }

    /**
     * Check if annotation entity is the legacy annotation entity
     */
    isLegacyAnnotationEntity(annotationEntity: AnnotationEntity) {
        const isTemp = annotationEntity.temp === true;

        /**
         * @WORKAROUND
         * Check consistency
         * https://bigsool-archipad.atlassian.net/browse/AP-8061
         * Reactivate this sanity check when the bug has been fixed
         */
        /*const annotatedEntity = annotationEntity.parentAnnotatedEntity;
        const legacyAnnotationId = this._getAnnotationId(annotatedEntity);
        const hasLegacyStableId = annotationEntity.id === legacyAnnotationId
        if(isTemp !== hasLegacyStableId) {
            throw new IllegalStateError('Annotation entity illegal state');
        }*/

        return isTemp;
    }

    private _getAnnotationId(entity: AnnotatedEntity): AnnotationEntity['id'] {
        return IdGenerator.generateDeterministicId(entity.id, 'C4EAC4A905844BABA0145A3C01B018EC', 'uuid');
    }

    /**
     * Returns the entity name of an annotation for a given annotated entity.
     * 
     * @example Bug => BugAnnotation
     * @example Remark => RemarkAnnotation
     */
    getAnnotationEntityTypeForAnnotated(annotatedEntity: AnnotatedEntity): EntityType {
        const annotationsProperty = annotatedEntity.entityType.propertyForKeyPath('annotations');
        if (!annotationsProperty) {
            throw new Error(`Unable to find annotations property in "${annotatedEntity.entityType.entityName}"`);
        }

        if (!annotationsProperty.relation) {
            throw new Error(`Property "${annotationsProperty.name}" is not a relation`);
        }

        return annotationsProperty.target;
    }


    /**
     * Returns the annotations of an annotatedEntity sorted from newest first.
     * 
     * @example AnnotatedEntity => Array<AnnotationEntity>
     * @example Bug => Array<BugAnnotationEntity>
     * @example Remark => Array<RemarkAnnotationEntity>
     */
    getAnnotations(annotatedEntity: AnnotatedEntity): Array<AnnotationEntity> {

        if(!annotatedEntity.annotations.length) {
            return []
        }

        const entityContext = annotatedEntity.getContext();

        const annotationEntityType = this.getAnnotationEntityTypeForAnnotated(annotatedEntity);
        const annotationEntityName = annotationEntityType.entityName as keyof EntityTypeMap;

        const predicate = new OrderByPredicate(`creationDate desc, id asc`);
        const comparator = predicate.build(predicateDelegateFor(entityContext, annotationEntityName));

        const annotations: readonly AnnotationEntity[] = annotatedEntity.annotations
        
        return annotations
            .filter(annotation => annotation.hasAttachments())
            .sort(comparator);
    }
}