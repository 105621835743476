import { LoggerManager } from '@archipad-js/core/logger';

const logger = LoggerManager.getLogger("JSON");

const ARCHIPAD_TEMPORARY_DATE_MARKER = "__ARCHIPAD_TEMPORARY_DATE_MARKER__";
const ARCHIPAD_TEMPORARY_DATE_MARKER_REGEXP = new RegExp(`{"${ARCHIPAD_TEMPORARY_DATE_MARKER}":([0-9-]+)}`, 'g');

/**
 * Converts a JavaScript value to a JavaScript Object Notation (JSON) string.
 *
 * Also transforms JavaScript Date objects to Microsoft serialized date format.
 *
 * @example { creationDate: new Date(2021, 10, 30, 12, 15) } become {"creationDate":"\/Date(1638270900000)\/"}
 *
 * @see [Microsoft serialized Date format](https://www.newtonsoft.com/json/help/html/datesinjson.htm)
 */
export function JSONEncode(o: unknown): string | undefined {
    let str = JSON.stringify(o, function (key, value) {
        const v: unknown = this[key];

        if (v instanceof Map || v instanceof Set) {
            throw new Error("Unable to serialize Set or Map since there is no way to deserialize them from JSON");
        }

        if (v instanceof Date) {
            return { [ARCHIPAD_TEMPORARY_DATE_MARKER]: v.getTime() };
        }

        return value;
    }) as string | undefined;

    str = str?.replace(ARCHIPAD_TEMPORARY_DATE_MARKER_REGEXP, '"\\/Date($1)\\/"');

    if (str?.match(/"\/Date\([0-9-]+\)\/"/)) {
        logger.warn("Error invalid date encoding detected in JSON output");
    }

    return str;
}

/**
 * Converts a JavaScript Object Notation (JSON) string into an object.
 *
 * Also transforms Dates serialized in Microsoft format to JavaScript Date objects.
 * 
 * @example {"creationDate":"\/Date(1638270900000)\/"} become { creationDate: new Date(2021, 10, 30, 12, 15) }
 *
 * @see [Microsoft serialized Date format](https://www.newtonsoft.com/json/help/html/datesinjson.htm)
 */
export function JSONDecode(str: string): unknown {
    str = str.replace(/"\\\/Date\(([0-9-]+)\)\\\/"/g, `{"${ARCHIPAD_TEMPORARY_DATE_MARKER}":$1}`);

    return JSON.parse(str, function (_key, value) {
        if (value && typeof value === "object") {
            if (!(ARCHIPAD_TEMPORARY_DATE_MARKER in value)) {
                return value;
            }

            const timestamp = Number(value[ARCHIPAD_TEMPORARY_DATE_MARKER]);
            return new Date(timestamp);
        }

        return value;
    });
}
