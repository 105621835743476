import { ConsoleLogFnKeyType, LogFunction, LoggerConfig, LogLevel, SingleLoggerConfig } from './logger.interface';


export function deserializeLoggerConfig(config?: SingleLoggerConfig): LoggerConfig {
    if (!config) {
        return {
            DEBUG: true,
            INFO: true,
            WARN: true,
            ERROR: true,
            classes: {},
        };
    }

    const mutableConfig: Partial<LoggerConfig> = config;
    if (!mutableConfig.classes) {
        mutableConfig.classes = {};
    }
    return mutableConfig as LoggerConfig;
}

export function logLevelToConsoleKey(level: LogLevel): ConsoleLogFnKeyType {
    switch (level) {
        case LogLevel.DEBUG:
            return "debug";
        case LogLevel.INFO:
            return "info";
        case LogLevel.WARN:
            return "warn";
        case LogLevel.ERROR:
            return "error";
    }
}

export function logFnFor(status: LogLevel): LogFunction {
    return function (name: string) {
        return console[logLevelToConsoleKey(status)].bind(console, "%s", `[${name}]`);
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyFn = () => {};