export interface Resource {
    file:string;
    name:string;
    aspect?:string;

    [name:string]: any;
}

/**
 * 'cloud-app|./main/menu.json' => 
 *      match[1] = null
 *      match[2] = null
 *      match[3] = 'main/menu'
 * 
 * 'app|./main/menu.default_beta.json' => 
 *      match[1] = 'main/menu'
 *      match[2] = 'default_beta'
 *      match[3] = null
 */
const FILE_REGEX = /^[@a-z-]+\|\.\/(?:([^\.]+)\.([^\.]+)|(.+))\.json$/;

const configContext = import('@config') as Promise<any>;

/**
 * List available config resources
 * 
 * Returns a a list of objects with the following structure
 * - file:   `'config/main/menu.default_beta.json'`
 * - name:   `'main/menu'`
 * - aspect: `'default_beta'`
 * 
 * @return the availlable resource list
 */
export function list(): Promise<Resource[]> {
    return configContext.then(function(config) {
        const ret = [];
        
        for(const file of config.keys()) {
            const match = FILE_REGEX.exec(file);
            if(!match)
                continue;
            if(match[3]) {
                // found configuration file
                ret.push({ file:file, name:match[3] });
            } else {
                // found configuration aspect
                ret.push({ file:file, name:match[1], aspect:match[2] });
            }
        }

        return ret;
    });
} 

/**
 * Load the specified configuration files
 * 
 * @param files configuration files to load.
 * @returns configuration files contents.
 */
export function load(resources:{ file:string }[]): Promise<any[]>  {
    return configContext.then(function(config) {
        const data = [];
        for(const resource of resources)
            data.push(config.get(resource.file));
        return Promise.resolve(data);
    });
} 
