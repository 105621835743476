import { Type } from "class-transformer";
import { IsInstance, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";

export class AuthToken {
    @IsNumber()
    end!: number;

    @IsOptional()
    @IsString()
    login?: string;

    /**
     * Returned root value is a `"true"` / `"false"` string
     */
    @IsOptional()
    @IsString()
    root?: string;

    @IsOptional()
    @IsNumber()
    credId?: number;

    @IsOptional()
    @IsString()
    hash?: string;

    @IsOptional()
    @IsString()
    type?: string;
}

export class CredentialLoginResponse {
    @IsNumber()
    userId!: number;

    /**
     * {@link id } represents the `credentialId`, __NOT__ the `userId`.
     *
     * Use {@link userId} instead.
     *
     * @deprecated
     */
    @IsNumber()
    id!: number;

    @IsString()
    uniqueUserId!: string;

    @IsNotEmpty()
    @IsString()
    login!: string;

    @ValidateNested()
    @IsInstance(AuthToken)
    @Type(() => AuthToken)
    authToken!: AuthToken;
}

export class CredentialRenewResponse extends CredentialLoginResponse {}
