import { CancelledError, IllegalStateError } from '@core/errors/errors-core';
import { createLogger } from '@core/services/logger.service';

const log = createLogger('task');

export type MakePromiseFn = (task:Task) => Promise<unknown>;

/*---------------------------------------------------------------------------*/
export class Task {
    label:string;
    errorLabel:string;
    base: Task;

    private _progressStack:any[];

    isRunning:boolean;
    isFinished:boolean;
    isCancelled:boolean;
    result:any;
    error:Error;

    tracking:any;

    private _listeners:((state:string)=>void)[];
    private _taskResolve:(v)=>void;
    private _taskReject:(err)=>void;

    private _cancelPromiseResolve:(v)=>void;
    private _cancelPromise:Promise<any>;

    constructor(label:string, makePromiseFn:MakePromiseFn) {
        this.label = label;
        this.errorLabel = null;

        this._progressStack = [ { weight:1, progress:0, total:0, completed:0 } ];

        this.isRunning = false;
        this.isFinished = false;
        this.isCancelled = false;
        this.result = null;
        this.error = null;

        this.tracking = null;

        this._listeners = [];

        this._taskResolve = null;
        this._taskReject = null;

        this._cancelPromise = null; 
        this._cancelPromiseResolve = null;


        let promise;
        
        this._cancelPromise = new Promise((resolve,reject) => { this._cancelPromiseResolve = resolve; });
        try {
            promise = makePromiseFn.call(this, this);
        } catch(err) {
            promise = Promise.reject(err);
        }
        if(!promise)
            throw new Error('TaskComponent promise constructor did not return a promise');

        this.isRunning = true;
        this.notify('running');

        Object.seal(this);

        this._taskResolve = (ret) => {
            if(!this.isRunning)
                return;

            this.result = ret;
            this.isRunning = false;
            this.isFinished = true;

            this.notify('finished');

            this._listeners = null;
        };
        this._taskReject = (err) => {
            if(!this.isRunning)
                return;

            this.error = err;
            this.isRunning = false;
            this.isFinished = true;

            if(err instanceof RangeError || err instanceof ReferenceError || err instanceof TypeError  || err instanceof URIError || err instanceof SyntaxError)
                log.error('Exception during task execution', err, err.stack);

            this.notify('finished');

            this._listeners = null;
        }
        promise.then(this._taskResolve, this._taskReject);

    }

    addListener(fn: (state:string) => void) {
        if(!this.isFinished)
            this._listeners.push(fn);
    }
    notify(state:string) {
        for(let i=0;i<this._listeners.length;i++)
            this._listeners[i].call(this, state);
    }

    cancel() {
    	if(this.isFinished || this.isCancelled)
    		return;
        this.isCancelled = true;
        this._taskReject(new CancelledError());
        this._cancelPromiseResolve(true);
    }
    get cancelPromise() {
    	return this._cancelPromise;
    }
    checkCancel() {
        if(this.isCancelled)
            throw new CancelledError();
    }

    async subTask<T>(units:number, makePromiseFn:() => Promise<T>) {
        const last = this._progressStack[this._progressStack.length-1];

        // get the current progress
        const p = this.progress;
        const w = (last.total) ? units/last.total : 0;

        if(last.completed + units > last.total)
            log.warn("The number of completed units (" +  last.completed + ") plus the units of this subtTask (" +  units + ") is greater than the total " + last.total + ")");

        this._progressStack.push({ weight:w, progress:p, total:0, completed:0 });
        const ret = await makePromiseFn();
        
        this._progressStack.pop();
        this.setCompletedUnits(last.completed + units);
        return ret;
    }

    setTotalUnits(t:number) {
        const last = this._progressStack[this._progressStack.length-1];
        if(last.total && last.total != t)
            log.warn("The total number of units is already set. This part of the process should be in its own subTask");
        last.total = t;
        if(!this.isFinished)
            this.notify('progressing');
    }
    setCompletedUnits(c:number) {
        const last = this._progressStack[this._progressStack.length-1];
        if(c > last.total)
            log.warn("The number of completed units (" + c + ") is greater than the total (" + last.total + ")");

        last.completed = c;
        if(!this.isFinished)
            this.notify('progressing');
    }
    get progress():number | null {
        const last = this._progressStack[this._progressStack.length-1];
        if(!last.total || last.progress === null) {
            return null;
        } else {
            return last.progress + last.weight*(last.completed/last.total);
        }
    }
    set progress(p) {} // To avoid an error when notifyPath try to set the progress value
    
    throwCancelledError(code?: string) {
        throwCancelledError(code);
    }
}

export function throwCancelledError(code?: string) {
    const error = new CancelledError();

    if (code) {
        error.reasonCode = code;
    }

    throw error;
}
export function checkTask(t) {
    if ( t && ! (t instanceof Task) ) {
        throw new IllegalStateError("Given task is not a task instance but "+ t);
    }
}

export function make(label:string, makePromiseFn:MakePromiseFn) {
    return new Task(label, makePromiseFn);
}
