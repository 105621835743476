import { throwError } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotImplementedError } from '@core/errors/errors-core';
import { getOrCreateFromMap } from '@core/helpers/collection.helper';
import { ProgressObserver } from '@core/tasks/progress';
import { PathDescriptor, FileService } from '@core/services/file/file';
import { FileResolverDriver } from '@core/services/file/fileResolver';
import {
    S3DumpPatchFileType,
    S3DumpPatchPathDescriptor,
    S3FileDescriptor,
    S3ProjectPathDescriptor,
} from '@core/services/file/s3file';

import {
    PatchAttachmentDescriptor,
    PatchDescriptor,
    PatchesDescriptor,
    PatchFileDescriptor,
    PatchManifestDescriptor,
} from '@archipad/services/file/patchFile';

export const concretePatchFileResolver = new FileResolverDriver<PatchFileDescriptor>({
    /** transform PatchFileDescriptors into S3FileDescriptors */
    mapper: function (file: PatchFileDescriptor) {
        const s3Path = new S3ProjectPathDescriptor(file.patch.patches.projectId, 'patch');

        if (file instanceof PatchManifestDescriptor) {
            return new S3FileDescriptor(s3Path, `${file.patch.patchId}/${file.path}`);
        }

        return new S3FileDescriptor(s3Path, `${file.patch.patchId}/${file.entityId}/${file.attachmentPath}`);
    },
    /** list all the patches for a project */
    list: function (progress: ProgressObserver, location: PathDescriptor) {
        if (!(location instanceof PatchesDescriptor) || location.type !== 'patch') {
            return throwError(new NotImplementedError('list', 'patchFileResolver'));
        }

        const s3Path = new S3ProjectPathDescriptor(location.projectId, 'patch');
        const patchDir = new PatchesDescriptor(location.projectId, 'patch');
        const patchById = new Map<string, PatchDescriptor>();
        return FileService.list(progress, s3Path).pipe(
            map((s3File: S3FileDescriptor) => {
                const patchId = getPatchId(s3File);
                if (!patchId) {
                    return null;
                }

                const patch = getOrCreateFromMap(patchById, patchId, new PatchDescriptor(patchDir, patchId));

                const patchManifestDescriptor = makePatchManifestDescriptor(patch, s3File);
                if (patchManifestDescriptor) {
                    return patchManifestDescriptor;
                }

                const patchAttachmentDescriptor = makePatchAttachmentDescriptor(patch, s3File);
                if (patchAttachmentDescriptor) {
                    return patchAttachmentDescriptor;
                }

                return null;
            }),
            filter((file) => Boolean(file)),
        );
    },
});

export const tempPatchFileResolver = new FileResolverDriver<PatchFileDescriptor>({
    /** transform PatchFileDescriptors into S3FileDescriptors */
    mapper: function (file: PatchFileDescriptor) {
        const s3Path = new S3ProjectPathDescriptor(file.patch.patches.projectId, 'temp');

        if (file instanceof PatchManifestDescriptor) {
            return new S3FileDescriptor(s3Path, `current/${file.patch.patchId}/${file.path}`);
        }

        return new S3FileDescriptor(s3Path, `${file.patch.patchId}/${file.entityId}/${file.attachmentPath}`);
    },
    /** list all the patches for a project */
    list: function (progress: ProgressObserver, location: PathDescriptor) {
        if (!(location instanceof PatchesDescriptor) || location.type !== 'temp') {
            return throwError(new NotImplementedError('list', 'tempFileResolver'));
        }

        const s3Path = new S3ProjectPathDescriptor(location.projectId, 'temp');
        const patchDir = new PatchesDescriptor(location.projectId, 'temp');
        const patchById = new Map<string, PatchDescriptor>();

        return FileService.list(progress, s3Path).pipe(
            map((s3File: S3FileDescriptor) => {
                const tempPatchId = getPatchId(s3File) ?? 'current';

                const tempPatchDescriptor = getOrCreateFromMap(
                    patchById,
                    tempPatchId,
                    new PatchDescriptor(patchDir, tempPatchId),
                );

                const tempPatchManifestDescriptor = makePatchManifestDescriptor(tempPatchDescriptor, s3File);
                if (tempPatchManifestDescriptor) {
                    return tempPatchManifestDescriptor;
                }

                const tempPatchAttachmentDescriptor = makePatchAttachmentDescriptor(tempPatchDescriptor, s3File);
                if (tempPatchAttachmentDescriptor) {
                    return tempPatchAttachmentDescriptor;
                }

                return null;
            }),
            filter((file) => Boolean(file)),
        );
    },
});

export const dumpPatchFileResolver = new FileResolverDriver<PatchFileDescriptor>({
    /** transform PatchFileDescriptors into S3FileDescriptors */
    mapper: function (file: PatchFileDescriptor) {
        const s3Path = new S3ProjectPathDescriptor(file.patch.patches.projectId, S3DumpPatchFileType.VALUE);

        if (file instanceof PatchAttachmentDescriptor) {
            throw new NotImplementedError('mapper', 'dumpPatchFileResolver/attachments');
        }

        return new S3FileDescriptor(s3Path, `dump/${file.patch.patchId}/${file.path}`);
    },

    /** list all the dump patches for a project */
    list: function (progress: ProgressObserver, location: PathDescriptor) {
        if (!(location instanceof PatchesDescriptor) || location.type !== 'dumpPatch') {
            return throwError(new NotImplementedError('list', 'dumpPatchFileResolver'));
        }

        const S3PathDescriptor = new S3DumpPatchPathDescriptor(location.projectId, S3DumpPatchFileType.VALUE);
        const patchDir = new PatchesDescriptor(location.projectId, 'dumpPatch');
        const patchById = new Map<string, PatchDescriptor>();

        return FileService.list(progress, S3PathDescriptor).pipe(
            map((s3File: S3FileDescriptor) => {
                const dumpPatchId = getPatchId(s3File);
                if (!dumpPatchId) {
                    return null;
                }

                const dumpPatchDescriptor = getOrCreateFromMap(
                    patchById,
                    dumpPatchId,
                    new PatchDescriptor(patchDir, dumpPatchId),
                );

                const dumpPatchManifestDescriptor = makePatchManifestDescriptor(dumpPatchDescriptor, s3File);
                if (dumpPatchManifestDescriptor) {
                    return dumpPatchManifestDescriptor;
                }

                return null;
            }),
            filter((file) => Boolean(file)),
        );
    },
});

FileService.registerDriver('patch', concretePatchFileResolver);
FileService.registerDriver('temp', tempPatchFileResolver);
FileService.registerDriver(S3DumpPatchFileType.VALUE, dumpPatchFileResolver);

/**
 * @example 0000017c11c579c6.20a2cf53
 */
const S3_PATCH_ID_REGEX_PART = '[0-9a-f]{16}.[a-f0-9]{8}';

/**
 * @example 0000017c11c579c6.20a2cf53-7a1eeac2c168a051c55f5b6f484a87a10a73c8d0-1632469276
 */
const S3_TEMP_PATCH_ID_REGEX_PART = `${S3_PATCH_ID_REGEX_PART}\-[0-9a-f]+\-[0-9]{10,}`;

/**
 * @example 2956707323106743235
 */
const S3_ENTITY_ID_REGEX_PART = '[0-9]+';

/**
 * @example annotations/0.jpg
 */
const S3_ATTACHMENT_KEY_REGEX_PART = '.+';

/**
 * Returns the patchId extracted from the path of the given s3File.
 *
 * @returns The patchId of the s3File path or null otherwise.
 */
function getPatchId(s3File: S3FileDescriptor): string | null {
    let found: ReturnType<RegExp['exec']> = null;
    switch (s3File.location.s3Type) {
        case 'patch': {
            const patchPathRegex = new RegExp(`^(${S3_PATCH_ID_REGEX_PART})\/`);
            found = patchPathRegex.exec(s3File.path);
            break;
        }
        case 'temp': {
            const tempPatchPathRegex = new RegExp(`^current\/(${S3_TEMP_PATCH_ID_REGEX_PART})\/`);
            found = tempPatchPathRegex.exec(s3File.path);
            break;
        }
        case 'dumpPatch': {
            const dumpPatchPathRegex = new RegExp(`^dump\/(${S3_PATCH_ID_REGEX_PART})\/`);
            found = dumpPatchPathRegex.exec(s3File.path);
            break;
        }
    }

    if (!found) {
        return null;
    }

    const [, patchId] = found;
    return patchId;
}

/**
 * Returns a {@link PatchManifestDescriptor} for a given s3File if compatible.
 *
 * @returns The {@link PatchManifestDescriptor} or null otherwise.
 */
function makePatchManifestDescriptor(patch: PatchDescriptor, s3File: S3FileDescriptor): PatchManifestDescriptor | null {
    let found: ReturnType<RegExp['exec']> = null;
    switch (s3File.location.s3Type) {
        case 'patch': {
            const patchDataOrManifestPathRegex = new RegExp(`^${S3_PATCH_ID_REGEX_PART}\/((?:data|_manifest)\.json)$`);
            found = patchDataOrManifestPathRegex.exec(s3File.path);
            break;
        }
        case 'temp': {
            const patchDataOrManifestPathRegex = new RegExp(
                `^current\/${S3_TEMP_PATCH_ID_REGEX_PART}\/((?:data|_manifest)\.json)$`,
            );
            found = patchDataOrManifestPathRegex.exec(s3File.path);
            break;
        }
        case 'dumpPatch': {
            const patchDataOrManifestPathRegex = new RegExp(
                `^dump\/${S3_PATCH_ID_REGEX_PART}\/((?:_data|_manifest|_patchSet)\.json)$`,
            );
            found = patchDataOrManifestPathRegex.exec(s3File.path);
            break;
        }
    }

    if (!found) {
        return null;
    }

    const [, filename] = found;
    return new PatchManifestDescriptor(patch, filename);
}

/**
 * Returns a {@link PatchAttachmentDescriptor} for a given s3File if compatible.
 *
 * @returns The {@link PatchAttachmentDescriptor} or null otherwise.
 */
function makePatchAttachmentDescriptor(
    patch: PatchDescriptor,
    s3File: S3FileDescriptor,
): PatchAttachmentDescriptor | null {
    let found: ReturnType<RegExp['exec']> = null;
    switch (s3File.location.s3Type) {
        case 'patch': {
            const patchAttachmentPathRegex = new RegExp(
                `^${S3_PATCH_ID_REGEX_PART}\/(${S3_ENTITY_ID_REGEX_PART})\/(${S3_ATTACHMENT_KEY_REGEX_PART})$`,
            );
            found = patchAttachmentPathRegex.exec(s3File.path);
            break;
        }
        case 'temp': {
            const patchAttachmentPathRegex = new RegExp(
                `^current\/(${S3_ENTITY_ID_REGEX_PART})\/(${S3_ATTACHMENT_KEY_REGEX_PART})$`,
            );
            found = patchAttachmentPathRegex.exec(s3File.path);
            break;
        }
    }

    if (!found) {
        return null;
    }

    const [, entityId, attachmentKey] = found;
    return new PatchAttachmentDescriptor(patch, entityId, attachmentKey);
}
