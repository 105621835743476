/**
* VisitEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionEntity } from "./RegionEntity";
import { PhaseEntity } from "./PhaseEntity";
import { VisitTypeEntity } from "./VisitTypeEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";
import { DeliveryVisitEntity } from "./DeliveryVisitEntity";
import { VisitMemberEntity } from "./VisitMemberEntity";
import { FormEntity } from "./FormEntity";
import { BugEntity } from "./BugEntity";
import { BugNoteEntity } from "./BugNoteEntity";
import { RemarkEntity } from "./RemarkEntity";
import { ReportEntity } from "./ReportEntity";
import { promoimmoEntity } from "./promoimmoEntity";


declare global {
    interface EntityTypeMap {
        "Visit": VisitEntity
    }
}

export abstract class VisitEntity extends BaseEntity {

	 abstract visitId: string;
	 abstract date: Date;
	 abstract creationDate: Date;
	 abstract temp: boolean;
	 abstract isVisitToday: boolean;
	 abstract project: ProjectEntity;
	 abstract forms: ReadonlyArray<FormEntity>;
	 abstract templateId?: string;
	 abstract notes?: string;
	 abstract object?: string;
	 abstract complete?: boolean;
	 abstract nextVisitDate?: Date;
	 abstract region?: RegionEntity;
	 abstract phase?: PhaseEntity;
	 abstract visitType?: VisitTypeEntity;
	 abstract creator?: ProjectUserEntity;
	 abstract deliveryVisit?: DeliveryVisitEntity;
	 abstract members?: ReadonlyArray<VisitMemberEntity>;
	 abstract bugs?: ReadonlyArray<BugEntity>;
	 abstract closingBugs?: ReadonlyArray<BugEntity>;
	 abstract bugNotes?: ReadonlyArray<BugNoteEntity>;
	 abstract remarks?: ReadonlyArray<RemarkEntity>;
	 abstract closingRemarks?: ReadonlyArray<RemarkEntity>;
	 abstract reports?: ReadonlyArray<ReportEntity>;
	 abstract promoimmo?: promoimmoEntity;
	
}
