/**
* BugNoteAnnotationEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugNoteEntity } from "./BugNoteEntity";


declare global {
    interface EntityTypeMap {
        "BugNoteAnnotation": BugNoteAnnotationEntity
    }
}

export abstract class BugNoteAnnotationEntity extends BaseEntity {

	 abstract creationDate: Date;
	 abstract hasMetadata: boolean;
	 abstract drawingHint: number;
	 abstract temp: boolean;
	 abstract bugNoteAnnotationId: string;
	 abstract bugNote: BugNoteEntity;
	 abstract parentAnnotatedEntity: BugNoteEntity;
	 abstract templateId?: string;
	
}
