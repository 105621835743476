import { EntityContext, Notification, NotificationType, Validator } from "@core/services/orm/orm";

import _PatchHandlerService, { MutablePatch, PatchHandler, PatchHandlerContext, PatchId } from "@archipad/backend/project/patch";
import { BugWorkflowDeleteError } from "@archipad/errors/errors-archipad";

class BugWorkflowValidatorAndPatchHandler implements Validator, PatchHandler {
    private readonly patches: Set<{ patch: MutablePatch, patchId?: PatchId }> = new Set();

    validateNotification(notification: Notification): void {
        if (notification[0] !== NotificationType.willDeleteEntity || !(notification[1].entityType.entityName === "BugWorkflow")) {
            return;
        }
        
        throw new BugWorkflowDeleteError();
    }

    async willImportPatches(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void> {
        this.patches.clear();
    }

    async didImportPatch(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch: MutablePatch, patchId?: PatchId): Promise<void> {
        this.patches.add({ patch, patchId });
    }

    async endImportPatches(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext): Promise<void> {
        const patchIdsWithBugWorkflowDeleteByEntityId: Map<string, PatchId> = new Map();

        for (const { patch, patchId } of this.patches) {
            const bugWorkflowOperations = patch.operationsByType.get('BugWorkflow');

            if (bugWorkflowOperations === undefined) {
                continue;
            }

            for (const [entityId, operation] of bugWorkflowOperations.operationById) {
                if (operation.type === 'create') {
                    patchIdsWithBugWorkflowDeleteByEntityId.delete(entityId);
                }

                if (operation.type === 'delete') {
                    patchIdsWithBugWorkflowDeleteByEntityId.set(entityId, patchId);
                }
            }
        }

        this.patches.clear();

        if (patchIdsWithBugWorkflowDeleteByEntityId.size > 0) {
            const error = new BugWorkflowDeleteError();
            const patchIds = new Set(patchIdsWithBugWorkflowDeleteByEntityId.values());
            error.addContext('Archipad', { 'patchIds': Array.from(patchIds) });
            throw error;
        }
    }

    async willExportPatch(entityContext: EntityContext, projectId: string, patchHandlerContext: PatchHandlerContext, patch:MutablePatch, patchId?: PatchId): Promise<void> {
        const bugWorkflowOperations = patch.operationsByType.get('BugWorkflow');

        if (bugWorkflowOperations === undefined) {
            return;
        }

        for (const [_entityId, operation] of bugWorkflowOperations.operationById) {
            if (operation.type === 'delete') {
                throw new BugWorkflowDeleteError();
            }
        }
    }
}

const handler = new BugWorkflowValidatorAndPatchHandler();

_PatchHandlerService.registerPatchHandler(handler);

export default handler as Validator;
