/**
* PlanningEventEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PlanningEventCategoryEntity } from "./PlanningEventCategoryEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { PlanningEventLogEntity } from "./PlanningEventLogEntity";


declare global {
    interface EntityTypeMap {
        "PlanningEvent": PlanningEventEntity
    }
}

export abstract class PlanningEventEntity extends BaseEntity {

	 abstract planningEventId: string;
	 abstract text: string;
	 abstract creationDate: Date;
	 abstract startDate: Date;
	 abstract progress: number;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract endDate?: Date;
	 abstract category?: PlanningEventCategoryEntity;
	 abstract workPackage?: WorkPackageEntity;
	 abstract planningEventLogs?: ReadonlyArray<PlanningEventLogEntity>;
	
}
