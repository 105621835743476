import { Observable, Subject, EMPTY, throwError, isObservable } from "rxjs";
import { catchError, exhaustMap } from "rxjs/operators";
import { createLogger } from '@core/services/logger.service';
import { getSourceError, findInAllChainedErrors, IndexedDBVersionError, UnexpectedError } from '@core/errors/errors-core';
import { isAbortOrCancelledError } from "@core/helpers/errorHelper";

const log = createLogger('ErrorHandlerService');

export const enum ErrorHandlerPriority {
    CatchAll = -1,
    Low = 0,
    Medium = 1,
    High = 2,
}

/**
 * Error handler interface
 */
export interface ErrorHandler {
    /**
     * Does this handler accepts this error?
     * 
     * @param err error to check
     * @return true if the handler accepts the error
     */
    acceptsError(err: Error): boolean;

    /**
     * Handle an error
     * 
     * @param err - error to handle
     * @return a cold observable that hanles the error (presents a dialog to the user, reloads the page ...)
     */
    handle(err: Error): Observable<void> | null;
}

export interface ErrorLogger {
    log(err: Error): void;
}

const ConsoleErrorLogger: ErrorLogger = {
    log(error: Error): void {
        
        const err = findInAllChainedErrors(error, (err): err is Error => isAbortOrCancelledError(err));
        if (err) {
            return;
        }

        const isIndexedDBVersionError = Boolean(error?.name === 'VersionError');
        if (isIndexedDBVersionError){
            const indexDBErrorVersion = new IndexedDBVersionError('The cached indexDB version has a higher version than the requested version');
            indexDBErrorVersion.underlyingError = error;
            console.error(indexDBErrorVersion);
            return;
        }

        const sourceError = getSourceError(error);
        console.error(sourceError);
    },
}

/**
 * Error Handler Service
 * 
 * Register error handlers and make possible to trigger error handling from anywhere.
 */
class ErrorHandlerService {
    private static _instance: ErrorHandlerService;

    private readonly _handledErrors = new WeakSet<Error>();
    private readonly _loggedErrors = new WeakSet<Error>();

    private _errorLoggers: Set<ErrorLogger> = new Set([ConsoleErrorLogger]);
    private _errorHandlersByPriority: Map<ErrorHandlerPriority, ErrorHandler[]> = new Map<ErrorHandlerPriority, ErrorHandler[]>();
    private _errorTaskQueue: Subject<Observable<void>> = new Subject<Observable<void>>();

    /**
     * Get a single ErrorHandlerService instance, this is the only way to
     * get one.
     */
    public static get instance(): ErrorHandlerService {
        if (!ErrorHandlerService._instance) {
            ErrorHandlerService._instance = new ErrorHandlerService();
        }

        return ErrorHandlerService._instance;
    }

    public registerHandler(errorHandler: ErrorHandler, priority: ErrorHandlerPriority = ErrorHandlerPriority.Low): void {
        if (!this._errorHandlersByPriority.has(priority)) {
            this._errorHandlersByPriority.set(priority, [errorHandler]);
        } else {
            const errorHandlers = this._errorHandlersByPriority.get(priority);
            errorHandlers.push(errorHandler);
        }
    }

    public registerLogger(errorLogger: ErrorLogger): void {
        this._errorLoggers.add(errorLogger);
    }

    /**
     * Handle an error by getting through all registered error handlers,
     * find one that match the given error and trigger the error handling on it.
     * 
     * @param err - The error to handle
     */
    public handleError(err: Error): void {
        const abortError = findInAllChainedErrors(err, (err): err is Error => isAbortOrCancelledError(err));
        if (abortError) {
            return;
        }

        if (this._handledErrors.has(err)) {
            return;
        }

        const compatibleErrorHandlers: Array<{handler: ErrorHandler, priority: ErrorHandlerPriority}> = [];

        const sortedPriorities = Array.from(this._errorHandlersByPriority.keys()).sort((a, b) => b - a);
        for (const priority of sortedPriorities) {
            const shouldIgnoreCatchAll = priority === ErrorHandlerPriority.CatchAll && compatibleErrorHandlers.length > 0;
            if (shouldIgnoreCatchAll) {
                continue;
            }

            const errorHandlers = this._errorHandlersByPriority.get(priority)
                .filter(errorHandler => errorHandler.acceptsError(err))
                .map(errorHandler => ({ handler: errorHandler, priority }));

            compatibleErrorHandlers.push(...errorHandlers);
        }

        if (compatibleErrorHandlers.length === 0) {
            return;
        }

        let errorToLog = err;

        const errorHandler = compatibleErrorHandlers[0];
        if (errorHandler?.priority === ErrorHandlerPriority.CatchAll) {
            const unexpectedError = new UnexpectedError();
            unexpectedError.underlyingError = err;
            errorToLog = unexpectedError;
        }

        this.logError(errorToLog);

        const task = errorHandler?.handler.handle(err);
        if (isObservable(task)) {
            this._errorTaskQueue.next(task);
        }

        try {
            this._handledErrors.add(err);
        } catch (handledError) {
            if (handledError instanceof TypeError) {
                log.error('Error while trying to handling error', err);
            } else {
                throw handledError;
            }
        }
    }

    public logError(error: Error): void {
        if (this._loggedErrors.has(error)) {
            return;
        }

        for (const errorLogger of this._errorLoggers) {
            errorLogger.log(error);
        }
        try {
            this._loggedErrors.add(error);
        } catch (loggedError) {
            if (loggedError instanceof TypeError) {
                log.error('Error while trying to handling error', error);
            } else {
                throw loggedError;
            }
        }
    }

    public isHandled(error: Error): boolean {
        return this._handledErrors.has(error);
    }

    public wrap$<T = unknown>(source: Observable<T>): Observable<T> {
        return source.pipe(
            catchError(err => {
                this.handleError(err);

                return throwError(err);
            })
        );
    }

    private constructor() {
        this._errorTaskQueue.pipe(
            exhaustMap((task) => task),
            catchError(err => {
                log.error('Exception while handling error', err);
                return EMPTY;
            })
        ).subscribe({
            error: this.logError,
        });
    }
}

const instance = ErrorHandlerService.instance;

export { instance as ErrorHandlerService };
