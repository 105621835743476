import { isLeft } from 'fp-ts/lib/Either';

import { PhaseEntity } from "@archipad-models/models/PhaseEntity";

import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";
import { Phase_BugGroupsEntity } from "@archipad-models/models/Phase_BugGroupsEntity";
import { BugEntity } from '@archipad-models/models/BugEntity';
import { BugNoteEntity } from '@archipad-models/models/BugNoteEntity';
import { MapEntity } from '@archipad-models/models/MapEntity';

import { UnsupportedOperationError, IllegalArgumentError, IllegalStateError } from "@core/errors/errors-core";
import { PhaseIdentifierCodec } from '@core/iots/codecs';
import { EntityContext } from "@core/services/orm/orm";
import * as IdGenerator from '@core/services/orm/idGenerator';
import * as QueryService from '@core/services/orm/query';
import { getConfigService } from '@core/services/config';
import * as queryService from '@core/services/orm/query';
import { getLocalizationService } from '@core/services/translate';

import { VisitTypeTemplateName } from '@archipad/backend/project/workflowManagerConstant';
import _BugNoteFacade from '@archipad/services/bugNote.facade';
import { VisitTypeService } from '@archipad/services/visitType.service';
import { BugService } from '@archipad/services/bug.service';
import _TodaysVisitFacade, { TodaysVisitFacade } from '@archipad/services/todays-visit.facade';

import '@archipad-models/models/extensions/PhaseEntity-ext';
import { PhaseService } from '@archipad/services/phase.service';
import { BugNoteFacade } from '@archipad/services/bugNote.facade';


enum BugGroupTemplate {
    defaultGroup = 'defaultGroup',
    deliveryGroup = 'deliveryGroup',
}

interface IPhaseDescription {
    "_originBugGroupTemplateName_": BugGroupTemplate;
    "nameSystem": string;
    "templateId": string;
}

export interface IPhaseManager {
    migrateIssuesToPhaseCount(visit: VisitEntity, unclassifiedRegionOnly?: boolean): number;

    migrateIssuesToPhase(visit: VisitEntity, unclassifiedRegionOnly?: boolean): void;

    addSystemPhases(entityContext: EntityContext, project: ProjectEntity): Promise<void>;

    findPhaseByNameIdentifier(project: ProjectEntity, phaseNameIdentifier: string): PhaseEntity | null;
    getPhaseNameIdentifier(phase: PhaseEntity): string;
}

export class PhaseManager implements IPhaseManager {
    constructor(
        private readonly visitTypeService: VisitTypeService,
        private readonly bugService: BugService,
        private readonly phaseService: PhaseService,
        private readonly bugNoteFacade: BugNoteFacade,
        private readonly todaysVisitFacade: TodaysVisitFacade,
    ) {
    }
    
    migrateIssuesToPhaseCount(visit: VisitEntity, unclassifiedRegionOnly: boolean = false): number {
        throw new UnsupportedOperationError('Method not implemented.');
    }

    migrateIssuesToPhase(visit: VisitEntity, unclassifiedRegionOnly: boolean = false): void {
        throw new UnsupportedOperationError("Method not implemented.");
    }

    public async getPhasesDescription(): Promise<{[lang: string]: IPhaseDescription[]}> {
        const phases = await getConfigService().get('phases');
        return phases as any;
    }


    async addSystemPhases(entityContext: EntityContext, project: ProjectEntity): Promise<void> {
        const phasesDescription = await this.getPhasesDescription();
        if(!phasesDescription){
            // No config loaded with this app
            // NOTE : This avoid ipad-app / report-app to try upgrading phases.
            return;
        }

        // Already have phases, skip adding phase part.
        if(!project.phases.length) {
            const ConfigEntities_phases = phasesDescription[project.language] as IPhaseDescription[];

            // NOTE: corresponding orm object is NULL
            const ConfigEntity_bugGroup_default = {
                "_templateName_": "defaultGroup",
                "templateId": "6485251F3220412089A38D3E28701777",
            }
            const ConfigEntity_bugGroup_delivery = {
                "_templateName_": "deliveryGroup",
                "templateId": "22976AADBE084EA69650626F98671EFD",
            };

            const localizer = getLocalizationService();

            let phaseIndex = 0;
            for(const systemPhase of ConfigEntities_phases){
                const templateId = systemPhase.templateId;
                const entityId = IdGenerator.generateDeterministicId(project.projectId, templateId, 'uuid');
                const bugGroup = systemPhase._originBugGroupTemplateName_;
                
                const phaseEntity = entityContext.createEntity('Phase', entityId);
                phaseEntity.index = phaseIndex;
                phaseEntity.templateId = templateId;
                phaseEntity.project = project;
                phaseEntity.name = localizer.get(systemPhase.nameSystem).toString();
                phaseEntity.nameSystem = systemPhase.nameSystem;

                let relationEntity: Phase_BugGroupsEntity = null;
                if(bugGroup === ConfigEntity_bugGroup_default._templateName_){
                    const relationTemplateId = IdGenerator.generateDeterministicTemplateIdFromTemplateIdAndTemplateId(phaseEntity.templateId, ConfigEntity_bugGroup_default.templateId);
                    const relationEntityId = IdGenerator.generateDeterministicId(project.projectId, relationTemplateId, 'uuid');
                    relationEntity = entityContext.createEntity('Phase_BugGroups', relationEntityId);
                    relationEntity.templateId = relationTemplateId;
                    relationEntity.phase = phaseEntity;
                    
                } else if(bugGroup === ConfigEntity_bugGroup_delivery._templateName_){
                    const relationTemplateId = IdGenerator.generateDeterministicTemplateIdFromTemplateIdAndTemplateId(phaseEntity.templateId, ConfigEntity_bugGroup_delivery.templateId);
                    const relationEntityId = IdGenerator.generateDeterministicId(project.projectId, relationTemplateId, 'uuid');

                    const query = QueryService.query(entityContext, 'BugGroup');
                    query.predicateWithFormat('templateId == {templateId}', {templateId: ConfigEntity_bugGroup_delivery.templateId});
                    const resultSet = query.execute();
                    const bugGroupEntity = resultSet.firstEntity();

                    relationEntity = entityContext.createEntity('Phase_BugGroups', relationEntityId);
                    relationEntity.templateId = relationTemplateId;
                    relationEntity.phase = phaseEntity;

                    if(bugGroupEntity){
                        relationEntity.bugGroup = bugGroupEntity;
                    }
                }

                phaseIndex++;
            }
        }

        this.maybeUpdateAfterSalesServiceVisitTypeDefaultPhase(project);

        for (const visitType of project.visitTypes) {
            if (!visitType.phase) {
                visitType.phase = this.phaseService.getDefaultPhaseForBugGroup(project, visitType.bugGroup);
            }
        }

        for(const visit of project.visits){
            if(!visit.phase){
                visit.phase = visit.visitType.phase;
            }
        }
    }

    /**
     * Returns the default phase to use for a new bug.
     */
    private getDefaultPhaseForBug(options: {
        referenceBug?: BugEntity;
        visit: VisitEntity;
    }): PhaseEntity | null {
        const { referenceBug, visit } = options;

        const isStandardVisit = visit.visitType.eventPriority >= 0;
        if (isStandardVisit) {
            return null;
        }

        const defaultPhase = referenceBug?.phase ?? visit.visitType.phase;
        if (defaultPhase === null) {
            throw new IllegalStateError("Cannot find a default phase for the bug");
        }
        return defaultPhase;
    }

    phaseForBug(visit: VisitEntity, map: MapEntity): PhaseEntity | null {
        const referenceBug = this.bugService.findReferenceBug({ map: map, bugGroup: visit.visitType?.bugGroup ?? null });
        return this.getDefaultPhaseForBug({ referenceBug, visit });
    }

    isStandardVisit(visit: VisitEntity): boolean {
        const isStandardVisit = visit.visitType.eventPriority >= 0;
        return isStandardVisit;
    }

    /**
     * Find a project phase by name with a given phase identifier.
     * 
     * @param phaseUri - The phase name identifier (example: "nameSystem:Phase 1" or "name:Phase 1").
     */
    findPhaseByNameIdentifier(project: ProjectEntity, phaseNameIdentifier: string): PhaseEntity | null {
        if (isLeft(PhaseIdentifierCodec.decode(phaseNameIdentifier))) {
            throw new IllegalArgumentError('Invalid phase identifier', 'phaseNameIdentifier', 'nameSystem:{phase.nameSystem} | name:{phase.name}', phaseNameIdentifier);
        }

        const indexOfColon = phaseNameIdentifier.indexOf(':');
        const propertyName = phaseNameIdentifier.slice(0, indexOfColon) as 'nameSystem' | 'name';
        const propertyValue = phaseNameIdentifier.slice(indexOfColon + 1) as PhaseEntity['nameSystem'] | PhaseEntity['name'];
        const phase = project.phases.find((phase) => phase[propertyName] === propertyValue);
        return phase ?? null;
    }

    findPhaseByTemplateId(entityContext: EntityContext, templateId: string): PhaseEntity | null {
        const query = QueryService.query(entityContext, 'Phase');
        query.predicateWithFormat('templateId == {templateId}', { templateId });
        const resultSet = query.execute();
        const phase = resultSet.firstEntity();
        return phase;
    }

    getPhaseNameIdentifier(phase: PhaseEntity): string {
        return phase.nameSystem ? `nameSystem:${phase.nameSystem}` : `name:${phase.name}`;
    }

    _notCalledFunctionButNecessaryForTranslationToolExtract() {
        l('[phase]:suivi_de_chantier');
        l('[phase]:pre-cloisons');
        l('[phase]:cloisons');
        l('[phase]:pre-livraison');
        l('[phase]:opr');
        l('[phase]:reception');
        l('[phase]:livraison');
        l('[phase]:30_jours');
        l('[phase]:gpa');
        l('[phase]:biennale');
        l('[phase]:decennale');
        l('[phase]:pre-construction');
        l('[phase]:execution');
    }

    listBugsWithDefinedPhase(project: ProjectEntity): BugEntity[] {
		const entityContext = project.getContext();
        const query = queryService.query(entityContext, "Bug");
        query.predicateWithFormat("phase != null");
        query.orderBy('index');
        const bugs: QueryService.ResultSet<BugEntity> = query.execute();
        return bugs.sortedEntities();
    }

    autoCreateBugNoteForChangedPhase(project: ProjectEntity): void {
        const bugsWithDefinedPhase = this.listBugsWithDefinedPhase(project);
        for(const bug of bugsWithDefinedPhase){
            const lastBugNoteConcerningPhase = this.bugService.findLastBugNoteConcerningBugPhase(bug);
            const isConcerningLastPhase = lastBugNoteConcerningPhase && lastBugNoteConcerningPhase.phase === bug.phase;
            if(isConcerningLastPhase){
                // Ignore if the last changing phase was already noted
                continue;
            }
            this.createBugNoteForChangedPhase(bug);
        }
    }

    createBugNoteForChangedPhase(bug: BugEntity): BugNoteEntity {
        const project = bug.visit.project;
        const todaysVisit = this.todaysVisitFacade.getOrCreateTodaysVisit(project, VisitTypeTemplateName.Automation);
        const bugNote = this.bugNoteFacade.createBugNote(bug, todaysVisit);
        bugNote.phase = bug.phase;
        bugNote.creationDate = new Date();
        bugNote.text = l("Phase changed to {{phase}}", { phase: bug.phase.nameComputed });
        return bugNote;
    }

    /**
     * @see {@link https://bigsool-archipad.atlassian.net/browse/AP-9218 | AP-9218}
     */
    updateBugsWithUndesiredMissingPhase(project: ProjectEntity): void {
        const bugs = this.bugService.listBugs({ where: { project, phases: [null] } });
        const bugsLinkWithReference = this.bugService.findAllReferenceBugs(bugs);
        for (const [ bug, referenceBug ] of bugsLinkWithReference) {
            const defaultPhase: PhaseEntity | null = this.getDefaultPhaseForBug({referenceBug, visit: bug.visit});
            bug.phase = defaultPhase;
        }
    }

    private maybeUpdateAfterSalesServiceVisitTypeDefaultPhase(project: ProjectEntity): void {
        const afterSalesServiceVisitType = this.visitTypeService.findVisitType({
            where: {
                project,
                templateNames: [VisitTypeTemplateName.AfterSalesService],
            },
        });

        if (!afterSalesServiceVisitType) {
            return;
        }

        if (afterSalesServiceVisitType.phase) {
            return;
        }

        const phase30days = this.find30DaysPhase(project);
        afterSalesServiceVisitType.phase = phase30days;
    }

    private find30DaysPhase(project: ProjectEntity): PhaseEntity | null {
        const phase30days = this.findPhaseByNameIdentifier(project, 'nameSystem:[phase]:30_jours');
        return phase30days;
    }
}

export default new PhaseManager(
    new VisitTypeService(),
    new BugService(),
    new PhaseService(),
    _BugNoteFacade,
    _TodaysVisitFacade,
);
