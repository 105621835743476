import { Observable, forkJoin, Subject, of, throwError, BehaviorSubject, merge, EMPTY, defer, from, timer } from 'rxjs';
import { map, switchMap, catchError, ignoreElements, tap, reduce } from 'rxjs/operators';
import _ from 'lodash';

import { CancelledError, IllegalStateError, chainError, findInAllChainedErrors, ResponseAPIError } from '@core/errors/errors-core';

import _LegacyServiceManager from '@core/services/legacy-service-manager';
import { createLogger } from '@core/services/logger.service';
import { EntityContext } from '@core/services/orm/orm';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { GeneratorType, task, call } from '@core/tasks/generator';
import { callAsGenerator, callAsAsync, callAsAsyncFromTask } from '@core/tasks/asyncCaller';
import { TaskQueue, QueuedTask, DefaultTaskContext } from '@core/tasks/queue';
import { IllegalArgumentError } from '@core/errors/errors-core';
import { ErrorHandlerService } from '@core/services/errorHandler';
import { FileService } from '@core/services/file/file';
import { CoreError, TechnicalErrorIdentifier } from '@core/errors/errors-core';

import { PatchesDescriptor, PatchAttachmentDescriptor } from '@archipad/services/file/patchFile';
import workflowUserManager, { UserDataJson } from '@archipad/backend/project/workflowUserManager';
import bugNotePhase from '@archipad/services/bugNote.facade';
import * as Patch from '@archipad/backend/project/patch';
import _PatchHandlerService from '@archipad/backend/project/patch';
import { ProjectContext } from '@archipad/backend/project/projectContext';
import { MinimalProjectInfo, SimpleProjectInfo } from '@archipad/backend/project/projectInfo';
import { getCurrentProject, ProjectContextLifecycleManager, ProjectContextDataManager, BaseProjectContext } from '@archipad/backend/project/baseProjectContext';
import * as dependenciesManager from '@archipad/backend/project/dependenciesManager';
import {UpdateRemoteProjectInfo} from '@archipad/backend/archiweb/synchroBackend';
import { RemoteListProjectInfo, RemoteProjectInfo } from '@archipad/backend/archiweb/synchroBackend';
import * as synchroBackend from '@archipad/backend/archiweb/synchroBackend';
import * as synchroDriver from '@archipad/backend/archiweb/synchroDriver';
import * as sharedProjects from '@archipad/backend/archiweb/sharedProjects';
import * as projectTemplateManager from '@archipad/backend/project/projectTemplateManager';
import { isBugWorkflowEnabled } from '@archipad/backend/project/workflowManagerHelper';
import "@archipad/backend/archiweb/cloudFileResolver";
import "@archipad/services/file/file";
import { ProjectNotFound, WriteEmptyPatchSetError, ReadEmptyPatchSetError } from '@archipad/errors/errors-archipad';
import { comparePatchId } from "@archipad/backend/project/patchHelper";
import { TechnicalErrorModule } from '@archipad/errors/errors-archipad';

import { ProjectEntity } from '@archipad-models/models/ProjectEntity';

import * as projectLocalStore from '@ui-archipad/backend/project/localStore';
import { LocalProjectInfo } from '@ui-archipad/helpers/indexedDB';
import * as FormTemplate from '@ui-archipad/drivers/formTemplate';
import { findConfigBundleByName } from '@core/services/config';
import { getAccountService } from '@archipad/services/account.service';


const log = createLogger('project');

/*---------------------------------------------------------------------------*/

const TEMP_PATCH_VERSION = 1;

// @TOOL prevent usage of dump patch mechanism
const USE_DUMP_PATCH_MECHANISM = true;


/*---------------------------------------------------------------------------*/

export class ProjectError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT,
        'PROJECT_ERROR',
    );

    public state?: ProjectSynchroState;
}


/*---------------------------------------------------------------------------*/

enum UpdateReason {
    Unknown = 0,
    Read,
    Write,
}

export interface CloudProjectInfo extends SimpleProjectInfo {
    dependencies: string[];
    lastPatchId:string;
    created: number; // server: creationDate
    lastModified: number; // server: lastModificationDate
    isOwner: boolean; // server: owned
    hasWorkflow: boolean; // Boolean(server: bugWorkflow)
    photoUrl?: string;
}

export interface CloudExtendedProjectInfo extends CloudProjectInfo {
    registered: boolean;
    approximateChangesCount: number;
    aspects: string[];
    reports: unknown; // TODO Remove?

    photo: string; // @deprecated alias to `photoUrl`

    isBusy: boolean;
    state: ProjectSynchroState;
}

export interface ProjectSynchroState {
    hasUnflushedChanges: boolean;
    hasUnpublishedChanges: boolean;
    hasUnfetchedChanges: boolean;
    tempPatchStatus: synchroDriver.TempPatchStatus;

    isDirty(): boolean;
}


/*---------------------------------------------------------------------------*/

export function sanitizeRemoteProjectInfo(remoteProjectInfo: RemoteListProjectInfo | RemoteProjectInfo) : CloudProjectInfo {
    const value : CloudProjectInfo = {
        id: remoteProjectInfo.id,
        name: remoteProjectInfo.name,
        lastPatchId: remoteProjectInfo.lastPatchId,
        created: remoteProjectInfo.creationDate,
        lastModified: remoteProjectInfo.lastModificationDate,
        isOwner: remoteProjectInfo.owned,
        hasWorkflow: !!remoteProjectInfo.bugWorkflow,
        dependencies: remoteProjectInfo.dependencies as string[],
    };
    if ('photoUrl' in remoteProjectInfo) {
        value.photoUrl = remoteProjectInfo.photoUrl;
    }
    return value;
}


/*---------------------------------------------------------------------------*/

type CloudProjectContext = ProjectContext & ProjectContextLifecycleManager;


export interface ProjectManager {

    //--------------------------------------------------------------------------
    // Project Info

    getId() : string;

    getInfo(): Readonly<CloudExtendedProjectInfo>;

    refreshProjectInfo(progress: ProgressObserver): Observable<void>;

    notification$: Observable<unknown>;


    //--------------------------------------------------------------------------
    // Lifecycle

    getContext(progress: ProgressObserver): Observable<CloudProjectContext>;
    
    closeContext(progress: ProgressObserver): Observable<void>;

    hasContext(): boolean;


    //--------------------------------------------------------------------------
    // Project synchronization operations with S3

    publishChanges(progress: ProgressObserver): Observable<unknown>;

    refreshSynchroState(progress: ProgressObserver): Observable<ProjectSynchroState>;

    remove(progress:ProgressObserver): Observable<void>;

    rebase(progress: ProgressObserver): Observable<unknown>;

    saveProjectAsTemplate(progress: ProgressObserver, name:string):Observable<string>;


    //--------------------------------------------------------------------------
    // Project synchronization operations with temp patch

    flushTempChanges(progress: ProgressObserver): Observable<ProjectSynchroState | synchroDriver.TempPatch>;

    tempPatchIsEmptyLike(): Observable<boolean>;

    fetchLatestTempChanges(progress: ProgressObserver): Observable<ProjectSynchroState>;

    discardTempLocalChanges(progress: ProgressObserver): Observable<ProjectSynchroState>;

    discardTempCloudChanges(progress: ProgressObserver): Observable<ProjectSynchroState>;
    

    //--------------------------------------------------------------------------
    // Project synchronization operations with API server

    synchronizeUsers(progress: ProgressObserver): Observable<void>;

    // update existing project with remote info
    update(progress:ProgressObserver, remoteInfo: CloudProjectInfo, lastPatchId:string|null): Observable<boolean>;


    //--------------------------------------------------------------------------
    // Debug and Commands

    logTempChanges(): Observable<void>;

    clear(progress: ProgressObserver): Observable<void>;

    upgradeProjectUsingContext(progress: ProgressObserver, context: ProjectContext): Observable<boolean>;

}


/*---------------------------------------------------------------------------*/

/**
 * project manager task queue (instance is shared because only one project can be opened at a time because the config service is shared)                                             
 */
 const taskQueue = new TaskQueue();

 interface TaskContext extends DefaultTaskContext {
     projectId: string;
 }
 
 function addTaskPolicy(queue:QueuedTask[], args:{ key:string, context:TaskContext, taskFn:(progress:ProgressObserver) => Observable<unknown> }): QueuedTask {
     if(!args.context){
         throw new IllegalArgumentError('Task context is missing')
     }
 
     if(args.key === 'closeContext') {
         // closeContext cancels every other task, except blocking tasks
         const tasks = queue.slice();
         for(const task of tasks) {
             if(task.key === 'closeContext'){
                 throw new IllegalStateError('Project context is already closing !')
             }
             if(task.context.blocking){
                 continue;
             }
 
             task.cancel();
         }
     } else {
         const task = queue.find((task) => (task.key === args.key));
         if(task){
             return task;    // if a task already exist, share it
         }
     }
 
     // append new task at end
     const task = new QueuedTask(args.key, args.taskFn, args.context);
     queue.push(task);
     return task;
 }
 
 taskQueue.addTaskPolicy = addTaskPolicy;
 
 /*---------------------------------------------------------------------------*/
 
 const onSavePatch : projectTemplateManager.OnSavePatch = async (signal: AbortSignal, progress:ProgressObserver, userInfo:Patch.UserInfo, projectInfo : UpdateRemoteProjectInfo) => {
     const p = makeProgressHandler(progress);
     p.total(1);
 
     const projectInfoForLocalStore : LocalProjectInfo = {
         id: projectInfo.id,
         name: projectInfo.name,
         dependencies: [ ... projectInfo.dependencies ],
 
         hasWorkflow: projectInfo.dependencies.indexOf('workflow') !== 1,
         isOwner: true,
         created: new Date().getTime(),  // not really true but not really important
     };
 
     await callAsAsync(signal, p, 1, (po) => projectLocalStore.setProjectInfo(userInfo.id, projectInfo.id, projectInfoForLocalStore));
 };
 

 export async function createProjectFromTemplate(signal: AbortSignal, progress:ProgressObserver, userInfo: Patch.UserInfo,
    projectName:string, templateIds:string[], serverSideInstanciation:boolean): Promise<[ MinimalProjectInfo, ProjectManager ]> 
{
    const p = makeProgressHandler(progress);
    p.total(100);

    const projectInfo : MinimalProjectInfo = await callAsAsync(signal, p, 40, (po) => 
        projectTemplateManager.createFromTemplate(progress, projectName, templateIds, serverSideInstanciation, onSavePatch));

    const localProjectInfo = await callAsAsyncFromTask(signal, p, 5, (task) => 
        projectLocalStore.getProject(task, userInfo.id, projectInfo.id));

    const projectManager = MakeProjectManager(userInfo, localProjectInfo.info);

    const unsanitizedRemoteProjectInfo = await callAsAsync(signal, p, 10, (po) => 
        synchroDriver.getProjectInfo(po, projectInfo.id));
    const remoteProjectInfo = sanitizeRemoteProjectInfo(unsanitizedRemoteProjectInfo);

    // mark project as registered
    await callAsAsync(signal, p, 5, (po) => 
        projectManager.update(po, remoteProjectInfo, remoteProjectInfo.lastPatchId));

    await callAsAsync(signal, p, 10, (po) =>
        projectManager.synchronizeUsers(po));

    await callAsAsync(signal, p, 30, (po) =>
        projectManager.publishChanges(po));

    return [ projectInfo , projectManager ];
 }
 
 
 
//------------------------------------------------------------------------------
// Cloud project context
//------------------------------------------------------------------------------

interface CloudProjectContextDataManager extends ProjectContextDataManager {
    addBlockingTask<T = unknown>(progress: ProgressObserver, key: string, taskFn: (progress: ProgressObserver) => Observable<T>): Observable<T>;
}


class CloudProjectContextImpl extends BaseProjectContext {
    private _projectContextDataManager: CloudProjectContextDataManager;

    protected get _dataManager(): CloudProjectContextDataManager {
        return this._projectContextDataManager;
    }


    public constructor(projectContextDataManager: CloudProjectContextDataManager) {
        super();
        this._projectContextDataManager = projectContextDataManager;
    }

    public addBlockingTask<T = unknown>(progress: ProgressObserver, key: string, taskFn: (progress: ProgressObserver) => Observable<T>): Observable<T> {
        if(!this._dataManager){
            console.warn('Project has been closed before blocking task added');
            return of(null);
        }

        return this._dataManager.addBlockingTask<T>(progress, key, taskFn);
    }

}



const CONST_WAIT_FOR_GET_CONTEXT_BETWEEN_ATTEMPTS = 500;
const CONST_MAX_ATTEMPTS_GET_CONTEXT = 4;
/*---------------------------------------------------------------------------*/

class ProjectManagerImpl implements ProjectManager, CloudProjectContextDataManager {

    private _projectContext$: BehaviorSubject<CloudProjectContext>;

    private _notification$: Observable<unknown>;
    private _notificationSubject$: Subject<void>;

    private _userInfo: Patch.UserInfo;
    private _projectInfo: LocalProjectInfo;

    // Synchro state related
    private _latestTempPatchStatus = null;
    private _hasUnflushedChanges = false;
    private _hasUnpublishedChanges = false;

    private _isDirty = false;

    private _attachmentSerializer:Patch.TempPatchAttachmentSerializer;

    constructor(theUserInfo: Patch.UserInfo, theProjectInfo: CloudProjectInfo | LocalProjectInfo){
        const projectContext$ = new BehaviorSubject(null);
        const notificationSubject$ = new Subject<void>();

        const ready$ = taskQueue.ready$.pipe(
            tap(ready => ready === true ? this._notificationSubject$.next() : null),
        );

        const projectContextProcesses$ = projectContext$.pipe(
            switchMap((projectContext) => {
                if(!projectContext){
                    return EMPTY;
                }

                return projectContext.processes$
            }),
            catchError((err) => {
                ErrorHandlerService.handleError(err);
                return throwError(err);
            }),
            ignoreElements(),
        );

        const notification$ = merge(
            ready$,
            notificationSubject$,
            // subscribe to the projectContext processes for as long as the
            // project notifications are observed
            projectContextProcesses$,
        );


        this._projectContext$ = projectContext$;

        this._notificationSubject$ = notificationSubject$;
        this._notification$ = notification$;
        
        this._userInfo = theUserInfo;

        this._projectInfo = {
            id: null,
            name: null,
            isOwner: false,
            created: null,
            lastModified: null,
            photoUrl: null,
            reports: null,
            lastPatchId: null,
    
            // default values
            registered: false,
            hasWorkflow: false,
            dependencies: [],
            // not copied from the project info parameter
            approximateChangesCount: null,
            hasUnfetchedChanges: false,
    
            // cache
            aspects: [],
        };

        const mandatoryFields : Array<keyof LocalProjectInfo> = [
            'id',
            'name',
            'isOwner',
            'created',
        ];
        mandatoryFields.forEach((k:string) => {
            if (theProjectInfo[k] === undefined) {
                throw new ProjectError("Project instance cannot be created without " + k);
            }
    
            this._projectInfo[k] = theProjectInfo[k];
        });
    
        const otherFields : Array<keyof LocalProjectInfo> = [
            'lastPatchId',
            'lastModified',
            'photoUrl',
            'reports',
            'registered',
            'hasWorkflow',
            'dependencies',
            'aspects',
        ];
        // TODO:check if we need to pull aspects out from cache since it's recomputed by loadAspects
        otherFields.forEach((k:string) => {
            if (theProjectInfo[k] !== undefined) {
                this._projectInfo[k] = theProjectInfo[k];
            }
        });

        this._attachmentSerializer = new Patch.TempPatchAttachmentSerializer(this.getId())
    }

    public getId() { return this._projectInfo.id; }

    public get notification$(): Observable<unknown> { return this._notification$; }

    /** returns a readonly object */
    private _getProjectInfo(): Readonly<CloudExtendedProjectInfo> {
        // nested inner class
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const parent = this;
        class ProjectInfoProxy implements CloudExtendedProjectInfo {
            get id() { return parent._projectInfo.id; }
            get name() { return parent._projectInfo.name; }
            get isOwner() { return parent._projectInfo.isOwner; }
            get created() { return parent._projectInfo.created; }
            get lastModified() { return parent._projectInfo.lastModified; }
            get hasWorkflow() { return parent._projectInfo.hasWorkflow; }
            get dependencies() { return parent._projectInfo.dependencies; }
            
            get lastPatchId() { return parent._projectInfo.lastPatchId; }
            get photoUrl() { return parent._projectInfo.photoUrl; }

            // @deprecated alias
            get photo() { return this.photoUrl; }

            get registered() { return parent._projectInfo.registered; }
            get reports() { return parent._projectInfo.reports; }
            get aspects() { return parent._projectInfo.aspects; }
            get approximateChangesCount() { return parent._projectInfo.approximateChangesCount; }

            get state() { return parent._getState(); }
            get isBusy() { return taskQueue.isRunning(); }
        }
        return new ProjectInfoProxy();
    }

    private get projectContext(): CloudProjectContext { return this._projectContext$.getValue(); }
    private set projectContext(projectContext: CloudProjectContext) { this._projectContext$.next(projectContext); }

    private _setTempPatchStatus(status) {
        this._latestTempPatchStatus = status;
        this._isDirty = false;
        this._notificationSubject$.next();
    }
    
    // TODO: remove reference and synchroTime from state
    private  _setStatusSynced() {
        this._latestTempPatchStatus = {
            state: synchroDriver.TempPatchState.IN_SYNC,
            rebase: false,
        };
        this._isDirty = false;

        this._notificationSubject$.next();
    }

    private _contextModified() {
        this._isDirty = true;

        if (this._latestTempPatchStatus &&
            (this._latestTempPatchStatus.state == synchroDriver.TempPatchState.CONFLICT ||
                this._latestTempPatchStatus.state == synchroDriver.TempPatchState.PUSH)) {
            // already in conflict or push state, nothing to do
        } else if (this._latestTempPatchStatus && this._latestTempPatchStatus.state == synchroDriver.TempPatchState.FETCH && this._hasUnflushedChanges) {
            // transform to conflict
            this._latestTempPatchStatus = {
                state: synchroDriver.TempPatchState.CONFLICT,
                rebase: false,
            };
        } else if (this._hasUnflushedChanges) {
            this._latestTempPatchStatus = {
                state: synchroDriver.TempPatchState.PUSH,
                rebase: false,
            };
        } else {
            this._latestTempPatchStatus = {
                state: synchroDriver.TempPatchState.IN_SYNC,
                rebase: false,
            };
        }

        this._notificationSubject$.next();
    }

    private _getState(): ProjectSynchroState {
        return {
            hasUnflushedChanges: this._hasUnflushedChanges,
            hasUnpublishedChanges: this._hasUnpublishedChanges,
            hasUnfetchedChanges: this._projectInfo.hasUnfetchedChanges,
            tempPatchStatus: this._latestTempPatchStatus,

            isDirty: () => this._isDirty,
        };
    }

    private *_refresh(progress: ProgressObserver): GeneratorType<ProjectSynchroState> {
        const p = makeProgressHandler(progress);
        p.total(2);
        
        const status = yield* this._getTempPatchStatus(p.task(1))

        yield* this._updateHasUnfetchedChanges(p.task(1));

        this._setTempPatchStatus(status);
        
        return this._getState();
    }

    private  _updateTempPatchInfo(tempPatch:synchroDriver.TempPatch, updateReason:UpdateReason) {
        let unpublishedChanges;
        let unflushedChanges;
        
        if (tempPatch) {
            if (tempPatch.patch) {
                const header : Patch.NetPatchHeader = tempPatch.patch.patch[0];
                if (header.tempData && ! header.tempData.emptyLike) {
                    unpublishedChanges = true;
                    unflushedChanges = tempPatch.hasUnflushedChanges;
                }
                else {
                    unpublishedChanges = false;
                    unflushedChanges = tempPatch.hasUnflushedChanges;
                }    
            }
            else {
                unpublishedChanges = false;
                unflushedChanges = tempPatch.hasUnflushedChanges;
            }
        }
        else {
            unpublishedChanges = false;
            unflushedChanges = (updateReason == UpdateReason.Write);
        }

        const changed = unpublishedChanges != this._hasUnpublishedChanges || unflushedChanges != this._hasUnflushedChanges;

        this._hasUnpublishedChanges = unpublishedChanges;
        this._hasUnflushedChanges = unflushedChanges;

        // // TODO testing purpose
        // console.log(">>>" , "updateReason",updateReason,  "hasUnpublishedChanges",hasUnpublishedChanges, "hasUnflushedChanges",hasUnflushedChanges);

        if (changed) {
            this._notificationSubject$.next();
        }
    }

    private _updateApproxChangesCount(tempPatch) {
        const changes = tempPatch ? tempPatch.patch : null;
    
        if (!changes) {
            this._projectInfo.approximateChangesCount = null;
            return;
        }
    
        let count = 0;
    
        const patch = changes.patch;
    
        // TODO: check performances !!
        const idMatcher = /[0-9]{19}/;
        for (let i = 1; i < patch.length; i++) {
            const entityChanges = patch[i];
            for (let j = 1; j < entityChanges.length; j++) {
                const change = entityChanges[j];
                for (const k in change) {
                    if (!("string" == typeof change[k] && idMatcher.test(change[k]))) {
                        count++;
                    }
                }
            }
        }
    
        this._projectInfo.approximateChangesCount = count;
    }

    private *_getAvailablePatches() : GeneratorType<ReadonlySet<string>> {
        const p = makeProgressHandler(null);
        p.total(1);

        const _projectPatches = yield* call(p.oldTask(1, null, (task) => projectLocalStore.listProjectPatches(task, this._userInfo.id, this._projectInfo.id)));
        const projectPatches = new Set(_projectPatches);

        projectPatches.delete(synchroDriver.CURRENT_PATCH);

        return projectPatches;
    }
    
    private *_hasAllPatches(requiredPatches: string[]) : GeneratorType<boolean> {
        const projectPatches = yield* this._getAvailablePatches();
    
        for (let i = 0; i < requiredPatches.length; i++) {
            if( !projectPatches.has(requiredPatches[i]) ){
                return false;
            }
        }
    
        return true;
    }

    private *_getPatch(progress: ProgressObserver, id: string) : GeneratorType<{patch: Patch.NetPatch, nature: Patch.PatchNature}> {
        const p = makeProgressHandler(progress);
        p.total(1);

        return yield* call(p.oldTask(1, null, () => projectLocalStore.getPatchData(this._userInfo.id, this._projectInfo.id, id)));
    }

    private _makePatchHeader(entityContext:EntityContext, clientName:string, temp:boolean) : Patch.MutablePatchHeader {
        const header : Patch.MutablePatchHeader = {
            modelMinVersion: entityContext.modelMinVersion,
            modelVersion: entityContext.modelVersion,
            clientName: clientName,
            user: this._userInfo,
            temp: temp,
            emptyLike: false,
        };
        return header;
    }

    private _checkTempPatchVersion(tempPatch:synchroDriver.TempPatch) {
        if (tempPatch && tempPatch.version != TEMP_PATCH_VERSION) {
            const status = {
                state: synchroDriver.TempPatchState.CONFLICT,
                rebase: true,
            };
            this._setTempPatchStatus(status);
    
            const error = new ProjectError("Wrong temp patch version");
            error.state = this._getState();
            throw error;
        }
    }

    private _checkTempPatchIntegrity(tempPatch: synchroDriver.TempPatch | null): void {
        if (tempPatch === null) {
            return;
        }

        if (!tempPatch.patchSet?.length) {
            throw new ReadEmptyPatchSetError();
        }
    }

    private *_checkTempPatchAttachments(progress: ProgressObserver, tempPatch: synchroDriver.TempPatch): GeneratorType<boolean> {
        if(!tempPatch.patch?.attachmentManifest){
            return true;
        }

        const tempPatchFolder = this._attachmentSerializer.directory;
        const remoteAttachments = yield * call<PatchAttachmentDescriptor[]>(FileService.list(progress, tempPatchFolder.patches).pipe(
            reduce((attachments: PatchAttachmentDescriptor[], attachment: PatchAttachmentDescriptor) => {
                attachments.push(attachment);
                
                return attachments;
            }, []),
        ));

        const manifest = tempPatch.patch.attachmentManifest;
        for (const entityId in manifest) {
            const entityAttachments = manifest[entityId];
            for (const attachmentKey in entityAttachments) {
                const attachment = entityAttachments[attachmentKey];
                if (attachment.deleted) {
                    // delete
                    continue;
                } else {
                    // check if the attachment is in place
                    const sourceFile = this._attachmentSerializer.deserializeAttachment(attachment, entityId, attachmentKey);

                    /**
                     * Avoid to check if patch attachments exists in the temp patch folder.
                     * 
                     * Upgraded report entities might point to a real patch folder.
                     */
                    if (sourceFile.type !== 'temp') {
                        continue;
                    }

                    const isInListing = !!remoteAttachments.find(file => file.attachmentPath === sourceFile.attachmentPath);
                    if(!isInListing){
                        const synchroState = this._getState();
                        synchroState.tempPatchStatus.rebase = true;
                        synchroState.tempPatchStatus.state = synchroDriver.TempPatchState.CONFLICT;
                        this._setTempPatchStatus(synchroState);
                        return false;
                    }
                }
            }
        }

        return true;
    }

    // TODO: more check
    private *_checkTempPatchForUpload(progress: ProgressObserver, tempPatch:synchroDriver.TempPatch): GeneratorType<void> { 
        if (!tempPatch) {
            throw new IllegalArgumentError('checkTempPatchForUpload(): no temp patch at all');
        }
    
        if (tempPatch.patch && tempPatch.patch.patch.length < 2) {
            throw new IllegalStateError('checkTempPatchForUpload(): patch has no changes');
        }

        if (!tempPatch.patchSet?.length) {
            throw new WriteEmptyPatchSetError();
        }

        const canCopyAttachments = yield * this._checkTempPatchAttachments(progress, tempPatch);
        if(!canCopyAttachments){
            throw new IllegalStateError('checkTempPatchForUpload(): patch has invalid changes on attachments');
        }
    }

    private *_getTempPatch(progress: ProgressObserver) : GeneratorType<synchroDriver.TempPatch> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const patchInfo = yield* call(p.oldTask(1, null, (task) => projectLocalStore.getPatchData(this._userInfo.id, this._projectInfo.id, synchroDriver.CURRENT_PATCH)));
        const tempPatch = patchInfo ? patchInfo.patch : null;

        this._checkTempPatchIntegrity(tempPatch);
        this._checkTempPatchVersion(tempPatch);
        this._updateTempPatchInfo(tempPatch, UpdateReason.Read);
        return tempPatch;
    }

    private *_getEmptyTempPatch() : GeneratorType<synchroDriver.TempPatch> {
        const availablePatches = yield* this._getAvailablePatches();
        const patch:synchroDriver.TempPatch = {
            patchSet: Array.from(availablePatches).sort(comparePatchId),
            patch: null,
            hasUnflushedChanges: false,
            synchroTime: null,
            version: TEMP_PATCH_VERSION,
        };
        return patch;
    }

    private *_persistTempPatch(tempPatch:synchroDriver.TempPatch): GeneratorType<synchroDriver.TempPatch> {
        log.warn("Persisting temp patch", tempPatch);
        this._updateApproxChangesCount(tempPatch);
    
        if (tempPatch.patchSet === undefined || tempPatch.synchroTime === undefined || tempPatch.patch === undefined || tempPatch.hasUnflushedChanges === undefined) {
            log.debug("incomplete temp patch", tempPatch);
            throw new IllegalStateError("Incomplete temp patch");
        }
        tempPatch.version = TEMP_PATCH_VERSION;
    
        if (tempPatch.patch && tempPatch.patch.patch.length < 2) {
            throw new IllegalStateError("got patch with no changes");
        }

        if (!tempPatch.patchSet?.length) {
            throw new WriteEmptyPatchSetError();
        }

        log.debug('saving local patch with synchroTime ' + tempPatch.synchroTime + " ref:" + tempPatch.patchSet.slice(-1).pop());
        yield* call(from(projectLocalStore.setPatchData(this._userInfo.id, this._projectInfo.id, synchroDriver.CURRENT_PATCH, tempPatch,Patch.PatchNature.TempPatch)));
        this._updateTempPatchInfo(tempPatch, UpdateReason.Write);
    
        return tempPatch;
    }

    private *_removeTempPatch(): GeneratorType<void> {
        yield* call(from(projectLocalStore.removePatchData(this._userInfo.id, this._projectInfo.id, synchroDriver.CURRENT_PATCH)));
        this._updateTempPatchInfo(null, UpdateReason.Write);
    }

    // Project status
    private *_updateHasUnfetchedChanges(progress: ProgressObserver): GeneratorType<boolean> {
        const p = makeProgressHandler(progress);
        p.total(4);
        
        // no new changes, project is not registered
        if (!this._projectInfo.registered){
            return false;
        }

        // project is on cloud
        const tempPatch = yield* this._getTempPatch(p.task(1));
        let hasMorePatches: boolean = false;
        if (!tempPatch) {
            // we don't have any temp patch, project never downloaded
            hasMorePatches = true;
        } else if (tempPatch && tempPatch.patchSet.length === 0) {
            // we are not based on any patch, new project
            hasMorePatches = false;
        } else {
            const patchesDir = new PatchesDescriptor(this.getId(), "patch"); 
            
            const patches = yield* call(synchroDriver.listProjectPatches(p.task(1), patchesDir));
            yield* callAsGenerator(p,1,(si, po) => this._discardOutdatedPatchesCached(si, po, patches));
            hasMorePatches = patches.findIndex(patch => (tempPatch.patchSet.indexOf(patch) === -1)) !== -1;
        }

        this._projectInfo.hasUnfetchedChanges = hasMorePatches;

        yield* call(p.oldTask(1, null, () => projectLocalStore.setProjectInfo(this._userInfo.id, this._projectInfo.id, this._projectInfo)));
        
        return hasMorePatches;
    }

    // Project sync
    private *_getTempPatchStatus(progress: ProgressObserver): GeneratorType<synchroDriver.TempPatchStatus> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const tempPatch = yield* this._getTempPatch(p.task(1));

        const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
        const status = yield* call(synchroDriver.getTempPatchStatus(p.task(1), tempDirectory, tempPatch));
        
        log.info("Temp patch status: " + status.state);
        
        return status;
    }


    // flush changes
    private *_persistTempPatchRemotely(progress: ProgressObserver, resolvingConflict?: boolean): GeneratorType<ProjectSynchroState>{
        const p = makeProgressHandler(progress);
        p.total(2);

        const tempPatch = yield* this._getTempPatch(p.task(1));
        
        try {
            yield * this._checkTempPatchForUpload(p.task(0), tempPatch);

            if (tempPatch && tempPatch.patch == null && !resolvingConflict) {
                const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
                const status = yield* call(synchroDriver.clearTempPatch(p.task(1), tempDirectory, tempPatch));
                yield* this._persistTempPatch(status.tempPatch);
            }
            else {
                const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
                const status = yield* call(synchroDriver.saveTempPatch(p.task(1), tempDirectory, tempPatch, resolvingConflict));
                yield* this._persistTempPatch(status.tempPatch);
            }
            this._setStatusSynced();

            return this._getState();
        } catch(error) {
            if (error.status) {
                log.debug("Trying to persist temp patch remotely but got status", error.status);
                this._setTempPatchStatus(error.status);

                const stateError = new ProjectError("Unable to flush temp changes");
                stateError.state = this._getState();
                throw stateError;
            }

            throw error;
        }
    }

    private *_getContext(progress: ProgressObserver) : GeneratorType<CloudProjectContext> {
        const p = makeProgressHandler(progress);
        p.total(100);

        if(this.projectContext){
            return this.projectContext;
        }

        const aspects = yield* this._getAspects(p.task(2));

        const projectInfo = this._getProjectInfo()
        const participants = yield* call(sharedProjects.listParticipants(p.task(1), projectInfo.id));

        const isOwner = projectInfo.isOwner;
        const isAdmin = participants.find(participant => participant.id === this._userInfo.id)?.role === "administrator"

        let configAspects = isOwner ? [ 'project_owner' ] : [];
        if (isAdmin) {
            configAspects.push('admin')
        }

        configAspects = configAspects.concat(aspects);

        const projectConfigBundle = findConfigBundleByName("project");
        projectConfigBundle.setEnabledAspects(configAspects);

        const shouldRebase = yield* this._isRebaseNeeded(p.task(2));
        if (shouldRebase) {
            // call public API to have call notifications
            yield* this._rebase(p.task(88), false);
            log.debug("downloaded project " + this._projectInfo.name);
        } else {
            p.units(92);
        }

        // THIS is where the link between ProjectContext and ProjectManager starts...
        const projectContext : CloudProjectContext = new CloudProjectContextImpl(this);
        yield* projectContext.openProjectContext(p.task(2));
    
        const projectEntity = projectContext.getProjectEntity();

        yield* call(FormTemplate.driver.updateTemplateCacheForProject(null, projectEntity));
        yield* this._loadAspects(p.task(2), projectEntity);

        this.projectContext = projectContext;

        yield* this._synchronizeUsers(p.task(2), projectContext);

        projectContext.notifyContextReady();        
		return projectContext;
    }

    // Refreshes up to a latest version or temp patch's one
    private *_rebase(progress: ProgressObserver, discardTempChanges?: boolean, exceptLastTempPatch?: boolean): GeneratorType<ProjectSynchroState> {
        const p = makeProgressHandler(progress);
        p.total(100);

        const projectId = this._projectInfo.id;

        try {
            // Handle temp patch (clean it or get reference/status)
            if (discardTempChanges) {
                const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
                
                yield* call(synchroDriver.clearAllTempPatches(p.task(0), tempDirectory, exceptLastTempPatch));
                yield* this._removeTempPatch();
            }

            const availablePatches = yield* this._getAvailablePatches();

            let tempPatch = null;
            {
                const localTempPatch = yield* this._getTempPatch(p.task(5));
                
                const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
                
                const status = yield* call(synchroDriver.getLatestTempPatch(p.task(5), tempDirectory, localTempPatch));

                this._checkTempPatchIntegrity(status.tempPatch);
                this._checkTempPatchVersion(status.tempPatch);

                if (status.tempPatch) {
                    tempPatch = status.tempPatch;
                    const patchesNotInPatchSet = Array.from(availablePatches).filter(patchId => (
                        tempPatch.patchSet.indexOf(patchId) === -1
                    ));
                    if (patchesNotInPatchSet.length) {
                        for(const patchId of patchesNotInPatchSet) {
                            // remove patch - not sure why ?
                            yield* call(p.oldTask(0, null, () => projectLocalStore.removePatchData(this._userInfo.id, projectId, patchId)));
                            // update available patch list
                            delete availablePatches[patchId];
                        }
                    }
                }
            }

            // Are we using an outdated dumpPatch in cache ?
            const isOutdatedDumpPatchInCache = yield* callAsGenerator( p, 30, ( si, po ) => this._isOutdatedDumpPatchInCache( si, po ) );
            if ( isOutdatedDumpPatchInCache ){
                log.warn('The project entity of this project is based on an erroneous dumpPatch. We will delete cached patches.');
                // remove All dumpPatches save on caches and delete them from availablePatches
                const dumpPatchesId = yield* callAsGenerator(p, 0, () => projectLocalStore.removeDumpPatch(this._userInfo.id, projectId));
                for (const patchId of dumpPatchesId) {
                    delete availablePatches[patchId];
                }
                // Recursive call on itself to restart the rebase process after cleaning the corrupted patches
                return yield* this._rebase(progress,discardTempChanges,exceptLastTempPatch);
            }

            // if the project is registered (on cloud, load patches)
            let hasMorePatchesToLoad = false;
            if (this._projectInfo.registered) {
                const manifests : {[key:string]:unknown} = {};

                const patchSet = tempPatch ? tempPatch.patchSet : null;
                const patchesDir = new PatchesDescriptor(this.getId(), "patch");
                // we have the reference and tempPatch, download missing patches
                const loadProjectPatchesCall = synchroDriver.loadProjectPatches(p.task(40), this.getId(), patchesDir, availablePatches, patchSet, (progress,patchesData) => {
                    // store the patch
                    return defer(async () => {
                        await projectLocalStore.setPatchesData(this._userInfo.id, projectId, p.task(20),patchesData);
                        for (const patchData of patchesData){
                            manifests[patchData.patchId] = patchData.attachmentManifest;
                            availablePatches[patchData.patchId] = true;
                        }
                    })
                }, USE_DUMP_PATCH_MECHANISM);
                const ignoredPatches = yield* call(loadProjectPatchesCall);
                hasMorePatchesToLoad = !!ignoredPatches.length;
            }

            // save project info
            {
                // NOTE: seems useless now that we keep the patches on S3 after deleting projects 
                // if (!Object.keys(availablePatches).length && !tempPatch) {
                //     // no temp patch and no patches, project was removed from Cloud and we don't know it
                //     const error = new ProjectNotFound("Inexistant project " + projectId);
                //     throw error;
                // }

                this._projectInfo.hasUnfetchedChanges = hasMorePatchesToLoad;
                yield* call(p.oldTask(5, null, () => projectLocalStore.setProjectInfo(this._userInfo.id, projectId, this._projectInfo)));
            }

            {
                let patch = tempPatch;
                if(!patch) {
                    patch = yield* this._getEmptyTempPatch();
                }
                yield* this._persistTempPatch(patch);
                this._setStatusSynced();
            }

            // reload orm
            if(this.projectContext){
                yield* call(this.projectContext.reload());
            }
            return this._getState();
        }
        catch(e) {
            if (e instanceof CancelledError) {
                throw e;
            }

            log.error("Unable to rebase", e, e.stack);

            const apiError = findInAllChainedErrors(e, ResponseAPIError.is);
            if (apiError?.response.code === 41) {
                throw chainError(e, new ProjectNotFound());
            }

            throw e;
        }            
    }

    private async _isOutdatedDumpPatchInCache(signal: AbortSignal, progress: ProgressObserver): Promise<boolean> {
        const p = makeProgressHandler(progress);
        p.total(100);
        const linkedEmptyPatches = await callAsAsync( signal, p, 25, ( _po ) => projectLocalStore.getPatchesIdByNature( this._userInfo.id, this._projectInfo.id, Patch.PatchNature.LinkedEmptyPatch ) );
        if (!linkedEmptyPatches.size){
            return false;
        }
        const dumpPatchIDs = await callAsAsync( signal, p, 25, ( _po ) => projectLocalStore.getPatchesIdByNature( this._userInfo.id, this._projectInfo.id, Patch.PatchNature.DumpPatch ) );
        const dumpPatchID = [...dumpPatchIDs][0];
        
        const patchesDir = new PatchesDescriptor(this.getId(), "patch");

        const allPatches = await callAsAsync( signal, p, 45, (po) => synchroDriver.listProjectPatches( po, patchesDir ));
        
        await callAsAsync(signal, p, 5,(po) => this._discardOutdatedPatchesCached(signal, po, allPatches));

        const lastPatchInPatchSet = [...linkedEmptyPatches].pop();

        const checkThereIsInterlacedPatch = Boolean(allPatches.filter(value => {
            const isNotTheDumPatch = value !== dumpPatchID;
            const isNotIncludeInPatchSet = linkedEmptyPatches.has(value) === false;
            const isInterlacedPatch = comparePatchId(value,lastPatchInPatchSet) === -1;
            return isNotTheDumPatch && isNotIncludeInPatchSet && isInterlacedPatch;
        }).length);
        const checkThereIsNotExcessPatchInPatchSet = Boolean(Array.from(linkedEmptyPatches).filter((localPatchId) => allPatches.includes(localPatchId) === false).length);

        return checkThereIsInterlacedPatch || checkThereIsNotExcessPatchInPatchSet;
    }

    // Publishes current changes
    private *_publishChanges(progress: ProgressObserver, ctx?: CloudProjectContext): GeneratorType<ProjectSynchroState> {
        const p = makeProgressHandler(progress);
        p.total(100);

        let projectContext : CloudProjectContext = ctx;
        if(!ctx){
            // WORKAROUND: During a project creation (in the middle of),
            // this method was called without context while the project was not in consistent state.
            // The call must be silently ignored.
            if (!this._hasUnpublishedChanges){
                log.error("project manager is not properly initialized. 'publishChanges' call aborted and ignored");
                return this._getState();
            }

            projectContext = yield* this._getContext(p.task(20));
        }

        // flush ORM
        yield* call(projectContext.flushORMChanges());       

        if (!this._hasUnpublishedChanges){
            return this._getState();
        }

        // if we're not in sync, don't even try
        const currentState = this._getState();
        if ( ! (!currentState.tempPatchStatus ||
            currentState.tempPatchStatus.state == synchroDriver.TempPatchState.IN_SYNC ||
            currentState.tempPatchStatus.state == synchroDriver.TempPatchState.PUSH) )
        {
            const error = new ProjectError("Trying to push but not correct state");
            error.state = currentState;
            throw error;
        }

        // if not yet registered, call backend
        if (!this._projectInfo.registered) {
            yield* call(p.oldTask(5, null, (task) => synchroDriver.registerProject(task, this._projectInfo)));
            this._projectInfo.registered = true;
            yield* call(p.oldTask(0, null, (task) => projectLocalStore.setProjectInfo(this._userInfo.id, this._projectInfo.id, this._projectInfo)));
        } else {
            p.units(5);
        }

        const status = yield* this._getTempPatchStatus(p.task(0));

        const currentTempPatch = yield * this._getTempPatch(p.task(0))

        const isAttachmentsInTempPatch = yield * this._checkTempPatchAttachments(p.task(0), currentTempPatch);
        // NOTE : Attachments moved by someone else. Suicide current tempPatch (avoid no such key error.).
        if(!isAttachmentsInTempPatch){
            yield * this._removeTempPatch();
            return this._getState();
        }

        // if we're not in sync, stop

        if ( ! (status.state == synchroDriver.TempPatchState.IN_SYNC ||
                status.state == synchroDriver.TempPatchState.PUSH) )
        {
            log.warn("Trying to push but not correct state");

            this._setTempPatchStatus(status);

            const error = new ProjectError("Trying to push but not correct state");
            error.state = this._getState();
            throw error;
        }

        // all good
        {
            log.info('saving project ' + this._projectInfo.id);

            const entityContext = projectContext.getEntityContext();

            // flush ORM changes on local db

            yield* callAsGenerator(p, 0, () => _PatchHandlerService.executePatchHandlers('willCreatePatch', entityContext, this._projectInfo.id, Patch.PatchHandlerContext.SYNCHRONIZE));
            // regular patch header
            const header = this._makePatchHeader(entityContext, projectContext.getClientName(), false);

            // get patch data
            let patch : Patch.MutablePatch = Patch.extractPatch(entityContext, header);

            // patch data without temp entities
            yield* call(p.oldTask(0, null, () => _PatchHandlerService.executePatchHandlers('willExportPatch', entityContext, this._projectInfo.id, Patch.PatchHandlerContext.SYNCHRONIZE, patch, synchroDriver.CURRENT_PATCH)));

            const netPatch = Patch.exportPatch(entityContext, this._attachmentSerializer, patch);
            if(!netPatch){
                throw new IllegalStateError('Publishing an empty patch: this error is due to a mismatch between the state of the temp patch and the actual exported patch');
            }

            const projectInfo = this._projectInfo;
            if(!projectInfo.id || !projectInfo.dependencies || !projectInfo.name){
                throw new IllegalArgumentError(`Invalid project info`);
            }

            yield* call(synchroDriver.saveProjectPatch(p.task(70), { id:projectInfo.id, dependencies:projectInfo.dependencies, name:projectInfo.name }, this._attachmentSerializer, netPatch));

            // rebase by cleaning user changes
            const state = yield* this._rebase(p.task(5), true);

            // await projectContext.flushORMChanges();

            log.info('project ' + this._projectInfo.id + ' published');
            return state;
        }
    }

    private *_isRebaseNeeded(progress: ProgressObserver): GeneratorType<boolean> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const tempPatch = yield* this._getTempPatch(p.task(1));
        
        // only rebase if no temp patch or not a new project
        // 1. no temp patch: reference: null :
        //      never touched the project : rebase
        //      hasPatches : no need to rebase
        // 2. initial reference : new project, no need to rebase
        // 3. other reference : need to check we're at correct revision
        let shouldRebase = true;
        if (tempPatch) {
            if (tempPatch.patchSet.length === 0){
                shouldRebase = false; // new project
            }
            else if (yield * this._hasAllPatches(tempPatch.patchSet)){
                shouldRebase = false; // we have all patches, no need to rebase
            }
        }

        return shouldRebase;
    }

    private *_getAspects(progress: ProgressObserver): GeneratorType<string[]> {
        const p = makeProgressHandler(progress);
        p.total(1);

        return yield* call(p.oldTask(1, null, (task) => dependenciesManager.getAspectsFromDependency(task, this._projectInfo.dependencies, this._projectInfo.id)));
    }

    private *_loadAspects(progress:ProgressObserver, project: ProjectEntity): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const accountService = getAccountService();
        const userInfo = accountService.currentUser;

        const getAspectsCall = forkJoin([
            p.oldTask(1, null, (task) => dependenciesManager.getProjectAspects(task, this._projectInfo.dependencies, this._projectInfo.id)),
            p.oldTask(1, null, (task) => dependenciesManager.getParticipantAspects(task, project, userInfo)),
        ]);
        const [ projectAspects, participantAspects ] = yield* call(getAspectsCall);

        const projectParticipantConfigBundle = findConfigBundleByName("project.participant");
        projectParticipantConfigBundle.setEnabledAspects(participantAspects);

        this._projectInfo.aspects = [].concat(projectAspects, participantAspects);
    }

    private *_synchronizeUsers(progress:ProgressObserver, projectContext:ProjectContext):GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.total(1);
        
        if (!isBugWorkflowEnabled(projectContext.getProjectEntity())) {
            p.units(1);
            return;
        }

        sharedProjects.clearParticipantsCache();

        const participants = yield* call(sharedProjects.listParticipants(p.task(1), this._projectInfo.id));

        const users = participants.map((participant) => {
            const x : UserDataJson = {
                cloudUserId: participant.id,
                email: participant.email,
                primaryRole: participant.role,
                roleData: participant.roleData,
                isOwner: participant.isOwner,
                name: participant.name,
                company: participant.company,
            };
            return x;
        });

        yield * call(from(workflowUserManager.synchronizeUsers(projectContext.getProjectEntity(), users)));
    }

    private *_doUpgrade(progress: ProgressObserver, projectContext: ProjectContext): GeneratorType<boolean> {
        const p = makeProgressHandler(progress);
        p.total(100);

        // update operation: create upgrade patches directly on server
        const anyUpgradeApplied = yield* call(projectTemplateManager.applyTemplateUpgrade(p.task(50), projectContext.getProjectEntity(), onSavePatch));

        if ( anyUpgradeApplied ) {
            // fetch latests patches - created by upgrade
            yield* this._rebase(p.task(40), true);

            bugNotePhase.upgradeBugNotes(projectContext.getProjectEntity());
            
            yield* this._synchronizeUsers(p.task(10), projectContext);
            
            return true;
        } else {
            p.units(50);
            return false;   // no need to refresh dependencies
        }
    }

    private *_upgradeProjectUsingContext(progress: ProgressObserver, context: CloudProjectContext):GeneratorType<boolean> {
        const p = makeProgressHandler(progress);
        p.total(100);
       
        const ret = yield* this._doUpgrade(p.task(75), context);

        if(!ret){
            return false;
        }

        // make sure operation changes are pushed
        yield* this._publishChanges(p.task(22), context);
        yield* call(context.reload());
        
        const projectEntity = context.getProjectEntity();

        // upgrade dependencies
        this._upgradeProjectDependencies(projectEntity);

        // update dependencies
        this._projectInfo.hasWorkflow = this._projectInfo.dependencies.indexOf('workflow') !== -1;
        this._projectInfo.dependencies = projectEntity.dependencies ? projectEntity.dependencies.split(',') : [];

        yield* call(p.oldTask(1, null, (task) => projectLocalStore.setProjectInfo(this._userInfo.id, this._projectInfo.id, this._projectInfo)));
        yield* this._setDependencies(p.task(1), this._projectInfo.dependencies);

        // make sure operation changes are pushed
        yield* this._publishChanges(p.task(1), context);

        return true;

    }

    private _upgradeProjectDependencies(projectEntity: ProjectEntity): void {
        if (!projectEntity) {
            return;
        }

        // update formAspects cache if needed
        // WARNING : formAspects field used to store the project dependencies and NOT the project aspects
        // WE MUST HAVE A 1:1 mapping between project dependencies and project aspects : a project dependency can ONLY have ONE project aspect with the SAME name.

        // - if projectEntity.dependencies is set, it always wins
        // - if dataManager.getDependencies() is an array then this is second best guess, comes from server
        // - if projectEntity.formAspects is a string then third best guess, historical

        if ( !projectEntity.dependencies ) {
            // model doesn't have dependencies.

            if ( this.getDependencies().length ) {
                // server has dependencies, update model from server
                projectEntity.dependencies = this.getDependencies().filter(x => !x.endsWith(dependenciesManager.REPORT_TEMPLATE_DEPENDENCY_MARKER)).join(',');
            }    

            if( !projectEntity.dependencies && projectEntity.formAspects) {
                // project entity has form aspects, update dependencies using formAspects
                projectEntity.dependencies = projectEntity.formAspects;
            }
        }
    }

    private *_update(progress:ProgressObserver, remoteInfo: CloudProjectInfo, lastPatchId:string|null): GeneratorType<boolean> {
        const p = makeProgressHandler(progress);
        p.total(1);

        this._projectInfo.registered = true;

        // TODO Add logic to compare `photoUrl`.
        // Since it is a sign url it always changes, which defeats browser image caching.
        // TODO Use the expiry parameter in the url, and add a policy to update `photoUrl` or not (example: use half life).
        let modified = false;
        const merge = (key) => {
            if (this._projectInfo[key] != remoteInfo[key] && remoteInfo[key] !== undefined) {
                this._projectInfo[key] = remoteInfo[key];
                modified = true;
            }
        }

        const keys : Array<keyof CloudProjectInfo> = ['created', 'lastModified', 'isOwner', 'photoUrl', 'hasWorkflow'];
        keys.map(merge);

        if (!this._hasUnpublishedChanges) {
            merge('name');
        }

        if(lastPatchId) {
            const availablePatches = yield* this._getAvailablePatches();
            if (availablePatches.size > 0) {
                const lastAvailablePatchId = Array.from(availablePatches).sort(comparePatchId).pop();
                this._projectInfo.hasUnfetchedChanges = (lastAvailablePatchId !== lastPatchId);
            }
        }

        yield* call(p.oldTask(1, null, (task) => projectLocalStore.setProjectInfo(this._userInfo.id, this._projectInfo.id, this._projectInfo)));

        return modified;
    }

    private *_remove(progress:ProgressObserver): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.total(100);

        yield* call(p.oldTask(10, null, (task) => projectLocalStore.removeProject(task, this._userInfo.id, this._projectInfo.id)));

        const tempDirectory = new PatchesDescriptor(this.getId(), "temp");
        yield* call(synchroDriver.clearAllTempPatches(p.task(10), tempDirectory));

        yield* call(p.oldTask(80, null, (task) => synchroDriver.deleteProjects(task, [this._projectInfo.id])));
    }

    private *_updateChangeCount(progress: ProgressObserver): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const tempPatch = yield* this._getTempPatch(p.task(1));
        
        // init changes count
        this._updateApproxChangesCount(tempPatch);
    }

    private *_setDependencies(progress: ProgressObserver, deps: string[]): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.total(1);

        this._projectInfo.dependencies = deps;
        const projectInfo = this._projectInfo;
        yield* callAsGenerator(p, 1, (signal, po) => synchroBackend.updateProject(signal, po, projectInfo, null));
    }

    private *_closeContext(progress: ProgressObserver): GeneratorType<void> {
        const projectContext = this.projectContext;
        if(projectContext) {    // make sure the context is not already closed or did not had the time to open
            yield* call(projectContext.close());
            this.projectContext = null;
        }

        const projectConfigBundle = findConfigBundleByName("project");
        const projectParticipantConfigBundle = findConfigBundleByName("project.participant");

        // reset the config aspects
        projectConfigBundle.setEnabledAspects([]);
        projectParticipantConfigBundle.setEnabledAspects([]);
    }


    //---------------------------------------------------------------------------
    // ProjectContextDataManager implementation

    public getAttachmentSerializer(): Patch.AttachmentSerializer {
        return this._attachmentSerializer;
    }

    private getDependencies() : string[] { return this._projectInfo.dependencies; }

    public getPatches(): Observable<string[]>{
        return task(() => this._getAvailablePatches()).pipe(
            map(function(availablePatches) { return Array.from(availablePatches).sort(comparePatchId); }),
        );
    }

    public makePatchHeader(entityContext:EntityContext, clientName:string, temp:boolean) : Patch.MutablePatchHeader {
        return this._makePatchHeader(entityContext, clientName, temp);
    }

    public getPatchData(patchId:string): Observable<{patch: Patch.NetPatch, nature: Patch.PatchNature}> {
        return task(() => this._getPatch(null, patchId));
    }

    private getTempPatchData(): Observable<Patch.NetPatch> {
        return task(() => this._getTempPatch(null)).pipe(
            map((tempPatch) => {
                return tempPatch ? tempPatch.patch : null;
            }),
        )
    }

    public *applyPatchesDidCommit(referenceContext:EntityContext): GeneratorType<void> {
        // apply temp patch
        const changeData = yield* call(this.getTempPatchData());
        if (changeData) {
            const header : Patch.NetPatchHeader = changeData.patch[0];
            if (!header.tempData) {
                header.tempData = { emptyLike:false };
            }
            const patch = Patch.importPatch(referenceContext, this.getAttachmentSerializer(), changeData.patch, changeData.attachmentManifest);
            Patch.applyPatch(referenceContext, patch);
        }
    }

    private *_onContextModified(projectName:string, patch:synchroDriver.PatchData, onUserInput:boolean) : GeneratorType<void> {
        const p = makeProgressHandler(null);
        
        let oldTempPatch = yield* this._getTempPatch(null);
        if (!oldTempPatch) {
            oldTempPatch = yield* this._getEmptyTempPatch();
        }

        const newTempPatch : synchroDriver.TempPatch = {
            patchSet: oldTempPatch.patchSet,
            patch: patch,
            synchroTime: oldTempPatch.synchroTime,
            hasUnflushedChanges: onUserInput,
            version: 0,
        };
        yield* this._persistTempPatch(newTempPatch);

        if (projectName != this._projectInfo.name) {
            this._projectInfo.name = projectName;

            yield* call(p.oldTask(0, null, () => projectLocalStore.setProjectInfo(this._userInfo.id, this._projectInfo.id, this._projectInfo)));
        }

        this._contextModified();
    }

    /**
     * Checks that the patchesData stored locally are also present remotely. If not, restores the integrity of the project.
     * @param remotePatches List of patches store remotely (Like S3) 
     */
    private async _discardOutdatedPatchesCached(signal: AbortSignal, progress:ProgressObserver, remotePatches: string[]) : Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(4);

        const localPatchesSet = await callAsAsync(signal, p, 1, (_po) => this.getPatches());
        const localPatches = Array.from(localPatchesSet);

        const localPatchesOutdated = localPatches.filter(patch => !remotePatches.includes(patch));
        if (localPatchesOutdated.length > 0) {
            log.warn("Discarding outdated patches", localPatchesOutdated);
            const outdatedPatchesData = await Promise.all(localPatchesOutdated.map(
                async patch => {
                    return {
                        patchId: patch,
                        ...(await projectLocalStore.getPatchData(this._userInfo.id, this._projectInfo.id, patch)),
                    }
                }));
            for (const outdatedPatch of outdatedPatchesData.filter(p => p.nature === Patch.PatchNature.PatchData)) {
                await callAsAsync(signal, p, 0, (_po) => projectLocalStore.removePatchData(this._userInfo.id, this._projectInfo.id, outdatedPatch.patchId));
            }
            if (outdatedPatchesData.some(p => p.nature === Patch.PatchNature.DumpPatch || p.nature === Patch.PatchNature.LinkedEmptyPatch)) {
                await callAsAsync(signal, p, 0, (_po) => projectLocalStore.removeDumpPatch(this._userInfo.id, this._projectInfo.id));
            }
            const tempPatch = await callAsAsync(signal, p, 1, (po) => task(() => this._getTempPatch(po)));
            if (tempPatch && tempPatch.patchSet){
                tempPatch.patchSet = tempPatch.patchSet.filter(patch => localPatchesOutdated.indexOf(patch) === -1);
                await callAsAsync(signal, p, 1, (_po) => task(() => this._persistTempPatch(tempPatch)));
            }
            await callAsAsync(signal, p, 2, (po) => task(() => this._rebase(po, false)));
        }
    }

    public contextModified(projectName:string, patch:synchroDriver.PatchData, onUserInput:boolean): Observable<void> {
        return task(() => this._onContextModified(projectName, patch, onUserInput));
    }

    public upgradeProjectUsingContext(progress: ProgressObserver, context: ProjectContext): Observable<boolean> {
        return task(() => this._upgradeProjectUsingContext(progress, context as CloudProjectContext));
    }


    //---------------------------------------------------------------------------

    public refreshProjectInfo(progress: ProgressObserver): Observable<void> {
        return task(() => this._updateChangeCount(progress));
    }

    public getInfo(): Readonly<CloudExtendedProjectInfo> {
        return this._getProjectInfo();
    }

    /* Changes stuff */

    // publish temp changes as a real patch
    public publishChanges(progress: ProgressObserver): Observable<unknown> {
        return taskQueue.addTask<unknown, TaskContext>(progress, {
            key: 'publishChanges', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._publishChanges(progress))
            },
        });
    }

    // discards local changes and get latest version
    public rebase(progress: ProgressObserver): Observable<unknown> {
        return taskQueue.addTask<unknown, TaskContext>(progress, {
            key: 'rebase', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._rebase(progress, true, false))
            },
        });
    }

    public hasContext(): boolean {
        return Boolean(this.projectContext);
    }

    // flushes temp changes to remote temp patch
    // if not registered, publish it
    public flushTempChanges(progress: ProgressObserver): Observable<ProjectSynchroState | synchroDriver.TempPatch>  {
        return taskQueue.addTask<synchroDriver.TempPatch | ProjectSynchroState, TaskContext>(progress, {
            key: 'flushTempChanges', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                if (!this._getState().hasUnflushedChanges){
                    return of(null);
                }

                let result: Observable<synchroDriver.TempPatch | ProjectSynchroState> = null;
                if (this._getProjectInfo().registered) {
                    result = task(() => this._persistTempPatchRemotely(progress));
                }
                else {
                    result = task(() => this._publishChanges(progress));
                }

                return result.pipe(
                    catchError((error) => {
                        if (error.state && error.state.tempPatchStatus && error.state.tempPatchStatus.state == synchroDriver.TempPatchState.IN_SYNC) {
                            return throwError(error.state);
                        } else {
                            return throwError(error);
                        }
                    }),
                )
            },
        });
    }

    // updates local changes by using remote changes
    public fetchLatestTempChanges(progress: ProgressObserver): Observable<ProjectSynchroState> {
        return taskQueue.addTask<ProjectSynchroState, TaskContext>(progress, {
            key: 'fetchLatestTempChanges', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._rebase(progress));
            },
        });
    }

    // resolve conflict using local changes
    public discardTempCloudChanges(progress: ProgressObserver): Observable<ProjectSynchroState>  {
        return taskQueue.addTask<ProjectSynchroState, TaskContext>(progress, {
            key: 'discardTempCloudChanges', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._persistTempPatchRemotely(progress, true));
            },
        });
    }

    // resolve conflict using cloud changes
    public discardTempLocalChanges(progress: ProgressObserver): Observable<ProjectSynchroState>  {
        return taskQueue.addTask<ProjectSynchroState, TaskContext>(progress, {
            key: 'discardTempLocalChanges', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._rebase(progress, true, true));
            },
        });
    }

    public logTempChanges(): Observable<void> {
        return task(() => this._getTempPatch(null)).pipe(
            map((tempPatch) => {
                if (tempPatch.patch) {
                    const header: Patch.NetPatchHeader = tempPatch.patch.patch[0];
                    if (header.tempData && header.tempData.emptyLike) {
                        log.debug("No local changes, only temporary data", tempPatch.patch);
                    } else {
                        log.debug("Local changes", tempPatch.patch);
                    }
                } else {
                    log.debug("No local changes");
                }
            }),
        );
    }

    public tempPatchIsEmptyLike(): Observable<boolean> {
        return task(() => this._getTempPatch(null)).pipe(
            map((tempPatch) => {
                if (tempPatch.patch) {
                    const header : Patch.NetPatchHeader = tempPatch.patch.patch[0];
                    if (header.tempData && header.tempData.emptyLike) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }),
        )
    }

    public refreshSynchroState(progress: ProgressObserver): Observable<ProjectSynchroState> {
        return taskQueue.addTask<ProjectSynchroState, TaskContext>(progress, {
            key: 'refreshSynchroState', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._refresh(progress));
            },
        });
    }

    public clear(progress: ProgressObserver): Observable<void> {
        return taskQueue.addTask<void, TaskContext>(progress, {
            key: 'clear', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                const p = makeProgressHandler(progress);
                p.total(1);
                return p.oldTask(1, null, (task) => projectLocalStore.removeProject(task, this._userInfo.id, this._projectInfo.id));
            },
        });
    }

    /* Context creation */

    public getContext(progress: ProgressObserver): Observable<CloudProjectContext> {
        return this._resilientGetContext(progress);
    }

    /**
     * We cannot open a new context if the old one is still being closed.
     * This method therefore includes a retry process to "wait" for a potential context closure.
     */
    private _resilientGetContext(progress: ProgressObserver, retryCount: number = 0): Observable<CloudProjectContext> {
        const currentProject = getCurrentProject();
        if (currentProject && currentProject.id !== this.getId()) {
            if ( retryCount > CONST_MAX_ATTEMPTS_GET_CONTEXT ) {
                throw new Error('Illegal attempt to open the context before closing the existing one');
            }
            return timer(CONST_WAIT_FOR_GET_CONTEXT_BETWEEN_ATTEMPTS).pipe(
                switchMap(() => this._resilientGetContext(progress, retryCount + 1)),
            );
        }
        return taskQueue.addTask<CloudProjectContext, TaskContext>(progress, {
            key: 'getContext',
            context: { projectId: this.getId() },
            taskFn: (progress) => task(() => this._getContext(progress)),
        });
    }

    public closeContext(progress: ProgressObserver): Observable<void> {
        // close context
        return taskQueue.addTask<void, TaskContext>(progress, {
            key: 'closeContext', 
            context: { projectId: this.getId() },
            taskFn: (progress) => task(() => this._closeContext(progress)),
        });
    }

    public update(progress:ProgressObserver, remoteInfo: CloudProjectInfo, lastPatchId:string|null): Observable<boolean> {
        return task(() => this._update(progress, remoteInfo, lastPatchId));
    }

    public remove(progress:ProgressObserver): Observable<void> {
        // close context
        return taskQueue.addTask<void, TaskContext>(progress, {
            key: 'remove', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                return task(() => this._remove(progress));
            },
        });
    }

    public saveProjectAsTemplate(progress: ProgressObserver, name:string):Observable<string> {
        return task(() => this._saveProjectAsTemplate(progress, name));
    }

    private * _saveProjectAsTemplate(progress: ProgressObserver, name:string):GeneratorType<string> {
        const p = makeProgressHandler(progress);
        p.task(100);
    
        const projectContext = yield* call(this.getContext(p.task(5)));
        const projectId = this.getId();

        yield* this._upgradeProjectUsingContext(p.task(5), projectContext as CloudProjectContext);

        const result = yield* projectTemplateManager.saveProjectAsTemplate(p.task(90), name, projectContext, projectId);
        return result;
    }
    
    public synchronizeUsers(progress: ProgressObserver): Observable<void> {
        return taskQueue.addTask<void, TaskContext>(progress, {
            key: 'synchronizeUsers', 
            context: { projectId: this.getId() },
            taskFn: (progress) => {
                const p = makeProgressHandler(progress);
                p.total(100);

                const getContext$ = task(() => this._getContext(p.task(90)));
                return getContext$.pipe(
                    switchMap((projectContext) => {
                        return task(() => this._synchronizeUsers(progress, projectContext))
                    }),
                );
            },
        });
    }

    public addBlockingTask<T = unknown>(progress: ProgressObserver, key: string, taskFn: (progress: ProgressObserver) => Observable<T>): Observable<T>{
        return taskQueue.addTask(progress, {
            key: key, 
            context: { projectId: this.getId(), blocking:true },
            taskFn: (progress) => {
                return taskFn(progress);
            },
        })
    }
}


export function MakeProjectManager(userInfo: Patch.UserInfo, projectInfo: CloudProjectInfo | LocalProjectInfo): ProjectManager {
    return new ProjectManagerImpl(userInfo, projectInfo);
}
