import "reflect-metadata";
import { injectable } from "inversify";
import fetch, { Response, RequestInit } from "node-fetch";

import { HttpClientInterface } from "./http-client.interface";

export const ALLOWED_HTTP_METHODS = ["DELETE", "GET", "HEAD", "OPTIONS", "PATCH", "POST", "PUT"] as const;

type HttpMethod = typeof ALLOWED_HTTP_METHODS[number];

@injectable()
export class NodeFetchHttpClient implements HttpClientInterface {
    /**
     * Shortcut to request("GET")
     */
    public async get(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("GET", url, options);
    }

    /**
     * Shortcut to request("HEAD")
     */
    public head(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("HEAD", url, options);
    }

    /**
     * Shortcut to request("DELETE")
     */
    public delete(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("DELETE", url, options);
    }

    /**
     * Shortcut to request("DELETE")
     */
    public options(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("OPTIONS", url, options);
    }

    /**
     * Shortcut to request("PATCH")
     */
    public patch(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("PATCH", url, options);
    }

    /**
     * Shortcut to request("POST")
     */
    public post(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("POST", url, options);
    }

    /**
     * Shortcut to request("PUT")
     */
    public put(url: string | URL, options?: RequestInit): Promise<Response> {
        return this.request("PUT", url, options);
    }

    /**
     * Returns a fetch Promise<Response>
     */
    public request(method: HttpMethod, url: string | URL, options?: RequestInit): Promise<Response> {
        if (!ALLOWED_HTTP_METHODS.includes(method)) {
            throw new Error("Method not allowed.");
        }

        if (!options) {
            options = {};
        }

        options.method = method;

        if (url instanceof URL) {
            url = url.toJSON();
        }

        return fetch(url.toString(), options);
    }
}
