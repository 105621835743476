/*

Dependencies are a way to bundle a set of resources (form aspects and model aspects) required for the appropriate user experience.

There are 3 types of dependencies :
- USER dependencies : not dependent of a project, only for a user : allows to (re)define the default project template, show a new menu item at top level/project list
- PROJECT dependency : for a specific project, but not for a specific user/role within the project. Typically a model aspect (custom model fields) + custom fields/views for ALL user/roles on that project
- PARTICIPANT dependency : for a specific role in a specific project : typically used to show/restrict UI to a specific role in a project.

The dependencies bundle and fetch/download system is based on legacy Archipad App's report template bundle and listResourceTemplate API.

Bundles contains multiple dependencies, in order to group related dependencies (project dependency that contains model aspect + participant dependencies that contain UI tweaks based on role)

A dependency can contain multiple aspects, in order to re-use aspects (the "guest" dependency activates project-data-readonly and visit-data-readonly aspects whereas the "Collaborator" dependency only activates the project-data-readonly aspect).

The server knows which dependency is contained in which bundle. When the client asks for dependencies, it's served a bundle URL to download that will contain the requested dependencies.
It's then up to the client to unzip the bundle, extract dependencies and activate appropriate aspects.

TO SUM UP :
 - 3 levels of dependencies : USER > Project > Participant
 - A ZIP bundle that contains dependencies definitions and aspects JSON files.
 - Client requests dependencies, Server gives bundle (that could also contain irrelevant dependencies/aspects), Client extracts aspects and apply appropriate aspects based on User/Project/Participant dependencies.

*/

import env from '@env';

import { AsyncContainerModule } from 'inversify';

import { createLogger } from '@core/services/logger.service';

import * as ConfigResources from '@core/services/config/resources';
import { ConfigBundle, Config_Bundle_DIID } from '@archipad-js/core/config';

import * as Dependency from '@archipad/drivers/dependency';
import { ProjectEntity } from '@archipad-models/models/ProjectEntity';
import { Task } from '@core/services/task';
import { DependenciesManagerError, MissingDependenciesError } from '@archipad/errors/errors-archipad';
import { type LegacyUserInformation } from '@archipad/services/account.service';
import _LegacyServiceManager from "@core/services/legacy-service-manager";
import { type AccountInfo } from '@archipad/services/account.service.types';

const log = createLogger('dependencyManager');

/*----------------------------------------------------------------------------*/
/*
{
    minVersion: "1.0",
    presets: [  // list of dependencies definitions in that bundle
        {
            name: "Guest",
            identifier: "archipad_guest",   // dependency name
            aspects: [
                "project-data-readonly",    // list of aspects to apply. Relates to a JSON aspect file contained in the bundle (or in another bundle)
                "visit-data-readonly",
                "report-readonly"
            ]
        },
        {
            name: "Collaborator",
            identifier: "archipad_collaborator",
            aspects: [
                "project-data-readonly"
            ]
        }
    ]
}
*/

export interface TemplateInfo {
    name: string;
    identifier: string;
    aspects: string[];
}

export interface Manifest {
    minVersion: string;
    platform: string;
    presets: TemplateInfo[];
}

export interface Driver {
    /**
     * Load remote dependencies
     *
     * @param platform current platform (mobi, cloud, container ...)
     * @param dependencies list of dependencies to load
     * @param bundle configuration bundle where to load the dependencies into
     * @param project current project id
     * @return a promise that resolves to a list of template info
     */
    installRemoteBundles(task, platform:string, dependencies:string[], bundle:ConfigBundle, projectId?:string):Promise<TemplateInfo[]>;

    /**
     * Get dependencies for the specified account info
     *
     * @param accountInfo the account info
     * @return a promise that resolves to the dependency list
     */
    getUserDependencies(task, accountInfo:AccountInfo):Promise<string[]>;

    /**
     * Get dependencies for a project participant
     *
     * @param project the project
     * @param user the project participant
     * @return a promise that resolves to the dependency list
     */
    getParticipantDependencies(task, project, user:LegacyUserInformation):Promise<string[]>;
}

/*----------------------------------------------------------------------------*/
export const REPORT_TEMPLATE_DEPENDENCY_MARKER = "_report_template";

//const SIGNED_URL_EXPIRATION_TIME = 15*60;
const BUILTIN_DEPENDENCIES = [
    'workflow',
];

const moduleConfig = Object.assign({}, env["@archipad/backend/project/dependenciesManager"]);
if ( ! moduleConfig.platform ){
    throw new DependenciesManagerError("No platform defined in dependenciesManager config");
}

const platform = moduleConfig.platform;
const ignoreMissingDependencies = moduleConfig.ignoreMissingDependencies || false;


const definedTemplates = new Map<string,TemplateInfo>();

const localConfigBundle = new ConfigBundle("dependency.local", 100);
const remoteConfigBundle = new ConfigBundle("dependency.remote", 101);
const userBundle = new ConfigBundle("user", 200);
const debugUserBundle = new ConfigBundle("user.debug", 201);
const projectConfigBundle = new ConfigBundle("project", 300);
const projectParticipantConfigBundle = new ConfigBundle("project.participant", 400);
const userProductConfigBundle = new ConfigBundle("user.product", 500);

export const DependenciesModule = new AsyncContainerModule(async (bind) => {
    const resources = await ConfigResources.list();

    // put all local dependencies in the same configuration bundle

    // collect all resources in config/bundles/*
    const bundleFiles: ConfigResources.Resource[] = [];
    const bundleNames = new Set<string>();

    for (const resource of resources) {
        if (!resource.name.startsWith("bundles/")) {
            continue;
        }

        resource.name = resource.name.substr("bundles/".length);

        const idx = resource.name.indexOf("/");
        if (idx === -1) {
            continue;
        }

        const bundleName = resource.name.substr(0, idx);
        resource.name = resource.name.substr(idx + 1);

        if (!resource.aspect && resource.name === "template") {
            bundleNames.add(bundleName); // template.json is the bundle marker
        }

        bundleFiles.push({ bundle: bundleName, file: resource.file, name: resource.name, aspect: resource.aspect });
    }

    // load them
    const resourcesData = await ConfigResources.load(bundleFiles);
    for (let i = 0; i < bundleFiles.length; i++) {
        const bundleFile = bundleFiles[i];
        const resourceData = resourcesData[i];

        if (!bundleNames.has(bundleFile.bundle)) {
            throw new DependenciesManagerError(`template.json is missing for local dependency '${bundleFile.bundle}'`);
        }

        if (!bundleFile.aspect) {
            if (bundleFile.name === "template") {
                // register template
                const templateInfos = getTemplateInfos(resourceData);
                for (const templateInfo of templateInfos) {
                    definedTemplates.set(templateInfo.identifier, templateInfo);
                }
            }
        } else {
            // add aspect
            localConfigBundle.addAspect(bundleFile.name, bundleFile.aspect, resourceData);
        }
    }

    bind(Config_Bundle_DIID.symbol).toConstantValue(localConfigBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(remoteConfigBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(userBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(debugUserBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(projectConfigBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(projectParticipantConfigBundle);
    bind(Config_Bundle_DIID.symbol).toConstantValue(userProductConfigBundle);
});




/** DEPENDENCIES / ASPECTS PART **/


function ensureDependenciesInstalled(task, dependencies:readonly string[], projectId?):Promise<void> {
    const missingDependencies = getMissingDependencies(dependencies);
    // if all installed, do nothing
    if ( missingDependencies.length == 0 ){
        return Promise.resolve();
    }

    return Dependency.driver.installRemoteBundles(task, platform, missingDependencies, remoteConfigBundle, projectId).then(function(templateInfos) {
        // register template info
        for(const templateInfo of templateInfos){
            definedTemplates.set(templateInfo.identifier, templateInfo);
        }

        // recheck for missing dependencies
        const missingDependencies = getMissingDependencies(dependencies);
        if ( missingDependencies.length ) {
            if ( ignoreMissingDependencies ) {
                log.warn("Ignoring missing dependencies " + missingDependencies.join(', '))
            } else {
                throw new MissingDependenciesError(
                    missingDependencies,
                );
            }
        }
    });
}

function hasDependency(name:string):boolean {
    // builtin dep
    if ( BUILTIN_DEPENDENCIES.indexOf(name) !== -1 ){
        return true;
    }
    
    // report template
    if ( name.endsWith(REPORT_TEMPLATE_DEPENDENCY_MARKER) ){
        return true;
    }

    return definedTemplates.has(name);
}

function getMissingDependencies(requiredDependencies: readonly string[]): string[] {
    const missingDependencies = [];

    for(const requiredDependency of requiredDependencies) {
        if ( !hasDependency(requiredDependency) ){
            missingDependencies.push(requiredDependency);
        }
    }

    return missingDependencies;
}

function hasWorkflowRights(project:ProjectEntity) : boolean {
    return project && project.userRights.length > 0;
}


export function getTemplateInfos(manifest:Manifest):TemplateInfo[] {
    const templates:TemplateInfo[] = [];
    if ( manifest.presets ) {
        manifest.presets.forEach(function(preset) {
            templates.push(preset);
        });
    }

    return templates;
}

export function getUserAspects(task:Task, accountInfo:AccountInfo): Promise<string[]> {
    return Dependency.driver.getUserDependencies(task, accountInfo).then((userDependencies) => {
        return getAspectsFromDependency(task, userDependencies);
    });
}

export function getProjectAspects(task: Task | null, dependencies: readonly string[], projectId: string): Promise<string[]> {
    if ( dependencies.length ) {
        return getAspectsFromDependency(task, dependencies, projectId);
    } else {
        return Promise.resolve([]);
    }
}

export function getParticipantAspects(task:Task, project:ProjectEntity, userInfo:LegacyUserInformation): Promise<string[]> {
    if (!hasWorkflowRights(project) ) {
        return Promise.resolve([]);
    }

    return Dependency.driver.getParticipantDependencies(task, project, userInfo).then(function(dependencies) {
        return getAspectsFromDependency(task, dependencies, project.id);
    });
}

export function getAspectsFromDependency(task: Task | null, dependencies:readonly string[], projectId?: string): Promise<string[]> {
    return ensureDependenciesInstalled(task, dependencies, projectId).then(function() {
        let aspects = [];
        dependencies.forEach(function(dependencyName) {
            if( !hasDependency(dependencyName) ) {
                if ( ignoreMissingDependencies ) {
                    // do nothing
                } else {
                    throw new DependenciesManagerError("Unable to find dependency "+ dependencyName);
                }
            }

            const dependency = definedTemplates.get(dependencyName);
            if ( dependency && dependency.aspects ){
                aspects = aspects.concat(dependency.aspects)
            }
        });

        return aspects;
    });
}
