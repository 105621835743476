globalThis.dataLayer = globalThis.dataLayer || [];

/**
 * Restrict Google Tag Manager to not be able to do anything.
 * 
 * @see https://developers.google.com/tag-platform/tag-manager/web/restrict
 */
globalThis.dataLayer.push({
    "gtm.allowlist": [],
});

globalThis.gtag = function gtag() {
    // eslint-disable-next-line prefer-rest-params
    globalThis.dataLayer.push(arguments);
};

export function installGtag(tagId: string): void {
    gtag("js", new Date());
    gtag("config", tagId, {
        send_page_view: false,
    });

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;

    document.head.appendChild(script);
}
