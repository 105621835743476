import { formatTextList } from "@core/helpers/textHelper";
import { Query, query } from "@core/services/orm/query";

import { VisitTypeEntity } from "@archipad-models/models/VisitTypeEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { BugGroupEntity } from "@archipad-models/models/BugGroupEntity";


export class VisitTypeService {
    
    constructor() {}

    public listVisitTypes(options: { where: VisitTypeWhereInput; orderBy?: string }): readonly VisitTypeEntity[] {
        const defaultOrderBy = "phase.index, name(natural)";
        const { where, orderBy } = options;
        const q = this.makeVisitTypeQuery({ where, orderBy: orderBy ?? defaultOrderBy });

        const resultSet = q.execute();
        const visitTypes = resultSet.sortedEntities();

        return visitTypes;
    }

    public listVisibleVisitTypes(options: { where: VisitTypeWhereInput; orderBy?: string }): readonly VisitTypeEntity[] {
        const defaultOrderBy = "phase.index, name(natural)";
        const { where, orderBy } = options;
        const q = this.makeVisitTypeQuery({ where, orderBy: orderBy ?? defaultOrderBy });
        q.predicateWithFormat("eventPriority >= 0");
        const resultSet = q.execute();
        const visitTypes = resultSet.sortedEntities();
        return visitTypes;
    }

    public findVisitType(options: { where: VisitTypeWhereInput; orderBy?: string }): VisitTypeEntity | undefined {
        const defaultOrderBy = "phase.index, name(natural)";
        const { where, orderBy } = options;
        const q = this.makeVisitTypeQuery({ where, orderBy: orderBy ?? defaultOrderBy });
        const resultSet = q.execute();
        const visitType = resultSet.firstEntity();
        return visitType ?? undefined;
    }

    private makeVisitTypeQuery(options: { where: VisitTypeWhereInput; orderBy?: string }): Query<VisitTypeEntity> {
        const { where, orderBy } = options;
        const { project, bugGroups, eventPriorities, isDefault, templateNames } = where;
        const entityContext = project.getContext();

        const q = query(entityContext, "VisitType")
            .predicateWithFormat("project == {project}", { project })
            .orderBy(formatTextList([orderBy, "id asc"], ", "));

        if (bugGroups) {
            q.predicateWithFormat("bugGroup IN {bugGroups}", { bugGroups: bugGroups });
        }

        if (eventPriorities) {
            q.predicateWithFormat("eventPriority IN {eventPriorities}", { eventPriorities });
        }

        if (isDefault) {
            q.predicateWithFormat("isDefault == {isDefault}", { isDefault });
        }

        if (templateNames) {
            q.predicateWithFormat("templateName IN {templateNames}", { templateNames });
        }

        return q;
    }
}

export interface VisitTypeWhereInput {
    project: ProjectEntity;
    bugGroups?: ReadonlyArray<BugGroupEntity | null>;
    eventPriorities?: ReadonlyArray<VisitTypeEntity["eventPriority"]>;
    isDefault?: VisitTypeEntity["isDefault"];
    templateNames?: ReadonlyArray<VisitTypeEntity["templateName"]>;
}
