/**
 * Format text by concatenating a list of string.
 * Skip null or empty strings.
 * @param textList the strings to concatenate
 * @param separator the separator to insert between 2 strings.
 */
export function formatTextList(textList:Array<unknown>, separator:string): string {
    const result: string = textList
        .filter(x => typeof x === 'string' && x.trim().length > 0)
        .join(separator);

    return result;
}

/**
 * Get unique text for a given list.
 */
export function getUniqueTextInList(text: string, list: string[]): string {
    if (!list.includes(text)) {
        return text;
    }

    const reducedList = list.filter((t) => t.includes(text));

    let nth = 2;
    for (const _text of reducedList) {
        const nextText = `${text} ${nth}`;

        if (!list.includes(nextText)) {
            return nextText;
        }

        nth++;
    }
}
