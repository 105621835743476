import { defer, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { formatTextList } from '@core/helpers/textHelper';
import * as Cache from '@core/services/cache';
import _LegacyServiceManager from "@core/services/legacy-service-manager";
import { adaptOldTask, makeProgressHandler, ProgressObserver } from '@core/tasks/progress';

import * as rpc from '@archipad/backend/rpc/authenticated';
import { getAccountService, LegacyUserInformation } from '@archipad/services/account.service';

/*---------------------------------------------------------------------------*/
const PARTICIPANTS_CACHE_EXPIRY = 60 * 60 * 1000;    // warning, cache is also cleared by archipad-app's refreshSynchroState

export interface Participant {
    id: number,
    email: string,
    name: string,
    company: string,
    isOwner: boolean,
    hasAccount: boolean,
    hasSharingCapability: boolean,
    participantStatus: string,
    role: string,
    roleData: ParticipantRoleData | null,
    displayName: string,
};

export interface ParticipantRoleData {
    workpackages? : Array<string>;
    dependencies? : Array<string>;
    // deprecated
    workflowRole? : string;
}

/*---------------------------------------------------------------------------*/

const participantsCache = new Map<string, Cache.Request<Participant[]>>();

_LegacyServiceManager.waitForReady().then(() => {
    getAccountService().currentUserInformation$.subscribe(() => {
        participantsCache.clear();
    });
}).catch(() => {});

export function clearParticipantsCache() {
    participantsCache.clear();
}

export function listParticipants(progress:ProgressObserver, projectId:string): Observable<Participant[]> {
    let entry = participantsCache.get(projectId);
    if(!entry) {
        entry = new Cache.Request<Participant[]>(getAccountService().currentUserInformation$, async function(currentUser:LegacyUserInformation) {
            if(!currentUser) {
                return null;
            }
            
            const rightsResult = await rpc.makeAuthenticatedRequest(null, 'project', 'getParticipants', {
                "id": projectId,
            });
            
            const participants:Participant[] = [];
            for (let i = 0; i < rightsResult.length; i++) {
                const rights = rightsResult[i];
                if (!rights.hasAccess) {
                    continue;
                }

                const hasAccount = rights.user.company.name != 'default';
                const participant = {
                    id: rights.user.id,
                    email: rights.user.credential.login,
                    name: formatTextList([rights.user.firstName, rights.user.lastName], ' '),
                    company: hasAccount ? rights.user.company.name : "",
                    isOwner: rights.isOwner,
                    hasAccount: hasAccount,
                    hasSharingCapability: rights.user.hasProjectSharingFeature,
                    participantStatus: rights.participantStatus,
                    role: rights.role,
                    roleData: rights.roleData,
                    workpackage: null,
                    workpackages: null,
                    displayName: rights.user.displayName,
                };

                participants.push(participant);
            }

            return participants;
        }, PARTICIPANTS_CACHE_EXPIRY);
        participantsCache.set(projectId, entry);
    }

    return defer(() => {
        const p = makeProgressHandler(progress);
        p.total(1);
        return entry.fetch().pipe(
            tap(() => p.units(1)),
        );
    });
}

// export function getAspects(task, project, type, refresh?) {
//     if (!project) {
//         throw new Error("Unable to get aspects without project");
//     }

//     // doesn't handle workflow rights
//     if (!WorkflowManager.hasWorkflowRights(project)) {
//         return Promise.resolve([]);
//     }

//     if (refresh) {
//         clearParticipantsCache();
//     }

//     return listParticipants(null, project.id).then(function (participants) {
//         var userId = User.getCurrentUserId();
//         var rightName = null;

//         for (var i = 0; i < participants.length; i++) {
//             if (participants[i].id == userId) {
//                 rightName = participants[i].role;
//                 break;
//             }
//         }

//         // TODO: move me to dependencies manager, once it exists
//         var DEPENDENCIES_TO_ASPECTS = {
//             archipad_guest: ["project-data-readonly", "bug-readonly", "report-readonly"],
//             archipad_collaborator: ["project-data-readonly"]
//         };

//         var userRight = WorkflowManager.findRight(project, rightName);
//         var aspects = [];
//         if (userRight.dependencies) {
//             var dependencies = userRight.dependencies.split(',');
//             dependencies.forEach(function (dependency) {
//                 if (DEPENDENCIES_TO_ASPECTS[dependency]) {
//                     aspects = aspects.concat(DEPENDENCIES_TO_ASPECTS[dependency]);
//                 }
//                 else {
//                     console.warn("user right dependency not found. fallback to GUEST |", dependency);
//                     aspects = aspects.concat(DEPENDENCIES_TO_ASPECTS["archipad_guest"]);
//                 }
//             });
//         }

//         return aspects;
//     });

// }

export function getParticipant(task, project, userInfo: LegacyUserInformation | number): Promise<Participant> {
    return adaptOldTask(task, (progress) => listParticipants(progress, project.id)).then(function (participants) {
        const userId = typeof( userInfo ) === "number" ? userInfo : userInfo.userId;

        for (let i = 0; i < participants.length; i++) {
            if (participants[i].id == userId) {
                return participants[i];
            }
        }

        return null;
    });
}


/**
 *
 * @param task
 * @param projectId
 * @param participants array of {login, role, roleData, customMessage}
 * @returns {Promise}
 */
export function batchShare(task, projectId, participants) {
    return rpc.makeAuthenticatedRequest(task, 'project', 'batchShare', {
        "projectId": projectId,
        "participants": participants,
    });
}

export function share(task, projectId, participantLogin, role, roleData, customMessage, participantParams?) {
    const methodParams = {
        "projectId": projectId,
        "participantLogin": participantLogin,
        "role": role,
        "roleData": roleData
    };
    if (participantParams) {
        methodParams['participant'] = participantParams;
    }
    return rpc.makeAuthenticatedRequest(task, 'project', 'share', methodParams);
}

export function unshare(task, projectId, participantLogin) {
    return rpc.makeAuthenticatedRequest(task, 'project', 'unshare', {
        "projectId": projectId,
        "participantLogin": participantLogin
    });
}
