import "reflect-metadata";

import Env from "@env";

import { inject, injectable } from "inversify";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";



import {
    ArchiwebServicesClient,
    Archiweb_Services_Client_DIID,
    AuthToken,
} from "@archipad-js/archipad/archiweb-services-client";
import { OAuthClientService, OAuth_Client_Service_DIID } from "@archipad-js/archipad/oauth-client-service";
import { DIID } from "@archipad-js/dependency-injection";
import { getUserAspects } from "@archipad/backend/project/dependenciesManager";
import _TrackingService from "@core/drivers/tracking";
import { TrackingService } from "@core/drivers/tracking/tracking.types";
import { findConfigBundleByName } from "@core/services/config";
import _LegacyServiceManager from "@core/services/legacy-service-manager";
import { createLogger } from "@core/services/logger.service";
import { callAsAsync } from "@core/tasks/asyncCaller";
import { ProgressObserver, makeProgressHandler } from "@core/tasks/progress";

import {
    AccountInfo,
    AccountPlan,
    ContactMePayload,
    CreateAccountPayload,
    FormCredentials,
    LegacyUserInformationInterface,
    UpdateAccountPayload,
    UpdateCredentialsPayload,
} from "./account.service.types";

import { Legacy_Account_Service_DIID } from "./account.service.types";


const logger = createLogger("LegacyAccountService");

export const Account_Service_DIID = DIID<AccountService>("AccountService");

/**
* [PLUS-158] Remove this check 
*/
const isFeatureFlagsPlusAuthenticationServerEnable = Env["featureFlags"]?.['plusAuthenticationServer'];
@injectable()
export class AccountService {
    /**
     * [PLUS-158] Marked as public and deprecated only to handle the transition
     * @deprecated Marked as private to avoid usage
     */
    public readonly currentAccountSub: BehaviorSubject<AccountInfo | null> = new BehaviorSubject(null);
    /**
     * [PLUS-158] Marked as public and deprecated only to handle the transition
     * @deprecated Marked as private to avoid usage
     */
    public readonly currentUserInformationSub: BehaviorSubject<LegacyUserInformation | null> = new BehaviorSubject(
        null,
    );
    /**
     * [PLUS-158] Marked as public and deprecated only to handle the transition
     * @deprecated Marked as private to avoid usage
     */
    public readonly currentPlanSub: BehaviorSubject<AccountPlan | null> = new BehaviorSubject(null);

    public readonly currentAccount$ = this.currentAccountSub.asObservable();
    public readonly accountUpdated$: Observable<void> = this.currentAccount$.pipe(map(() => { }));
    public readonly currentUserInformation$ = this.currentUserInformationSub.asObservable();
    public readonly currentPlan$ = this.currentPlanSub.asObservable();

    public readonly isAuthenticated$ = this.currentAccount$.pipe(
        map((account) => {
            return Boolean(account);
        }),
    );

    constructor(
        @inject(OAuth_Client_Service_DIID.symbol) private readonly oAuthClientService: OAuthClientService,
        @inject(Archiweb_Services_Client_DIID.symbol) private readonly archiwebServicesClient: ArchiwebServicesClient,
        protected readonly trackingService: TrackingService = _TrackingService,
    ) { }

    public get isAuthenticated(): boolean {
        return Boolean(this.currentAccountSub.getValue());
    }

    /**
     * Return true if user not connected or connected with limited rights (e.g. SSOToken)
     * Else return false
     */
    public get isRestricted(): boolean {
        const explicitlyRestricted = this.currentUser && this.currentUser.isRestricted;
        return explicitlyRestricted || !this.isAuthenticated;
    }

    public get isRoot(): boolean {
        return Boolean(this.currentUserInformationSub.getValue()?.isRoot);
    }

    public get currentUser(): LegacyUserInformation | null {
        return this.currentUserInformationSub.getValue();
    }

    public get currentAccount(): AccountInfo | null {
        const currentAccount = this.currentAccountSub.getValue();
        return currentAccount;
    }

    /**
     * Forces a specific `LegacyUserInformation`, used by ipad-app
     */
    public forceUserInformation(id: number, login: string): void {
        const forgedLoginResponse = {
            userId: id,
            login: login,
        };
        const userInformation = new LegacyUserInformation(forgedLoginResponse, false);
        this.currentUserInformationSub.next(userInformation);
    }

    public async logout(): Promise<void> {
        if (!isFeatureFlagsPlusAuthenticationServerEnable) {
            return _getLegacyAccountService().logout();
        }
        await this.oAuthClientService.logout();

        this.currentAccountSub.next(null);
        this.currentUserInformationSub.next(null);
        this.currentPlanSub.next(null);

        this.trackingService.setUser(null);
    }

    /**
     * `sso` allows an user to login with credentials provided as `GET` parameters.
     *
     * It simply by-passes the login form and tries to log the user in with the provided {@link userLogin} and {@link hashedPassword}
     * It is primarily used by the admin panel to `logAs` someone.
     *
     * @deprecated This is a pretty bad pattern, it should not be used extensively, and should at least be renamed to less confusing name
     * @param signal
     * @param progress
     * @param userLogin
     * @param hashedPassword
     * @returns
     */
    public async sso(
        signal: AbortSignal,
        progress: ProgressObserver,
        userLogin: string,
        hashedPassword: string,
    ): Promise<void> {
        throw new Error("Not implemented with new login system [sso]");
    }

    /**
     * `ssoToken` allows an user to authenticate with the provided {@link tokenInfo} and {@link userLogin} `GET` parameters.
     *
     * It by-passes the __whole login process__ and registers the extracted {@link authToken} to the underlying services.
     *
     * Since this method bypasses {@link loginClient()}, most {@link loginClient()} business logic must be reproduced here if we want the application to behave correctly.
     *
     * @deprecated Just like {@link sso()} this is a pretty bad pattern.
     * @param signal
     * @param progress
     * @param userLogin
     * @param tokenInfo
     * @param userId
     * @returns
     */
    public async ssoToken(
        signal: AbortSignal,
        progress: ProgressObserver,
        userLogin: string,
        tokenInfo: string,
        userId: number,
    ): Promise<void> {
        throw new Error("Not implemented with new login system [ssoToken]");
    }

    public async getCurrentAccount(signal: AbortSignal, progress: ProgressObserver): Promise<AccountInfo> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const accountInfo = await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "account", "find"),
        );

        // TODO COPY?
        return accountInfo[0];
    }

    public async updateAccount(
        signal: AbortSignal,
        progress: ProgressObserver,
        account: UpdateAccountPayload,
    ): Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(2);

        await callAsAsync(signal, p, 1, () =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "account", "update", account),
        );

        /**
         * Because account/update returns partial account data, we have to
         * call `account/find` manually to retrieve up-to-date data
         */
        const newAccountInfo = await callAsAsync(signal, p, 1, (po) => this.getCurrentAccount(signal, po));

        this.currentAccountSub.next(newAccountInfo);
    }

    public async isAlreadyAuthenticated(): Promise<boolean> {
        return this.oAuthClientService.canBeSilentlyAuthenticated();
    }

    public async createAccount(
        signal: AbortSignal,
        progress: ProgressObserver,
        accountData: CreateAccountPayload,
    ): Promise<AccountInfo> {
        throw new Error("Not implemented with new login system [createAccount]");
    }

    public async getCurrentAccountPlan(signal: AbortSignal, progress: ProgressObserver): Promise<AccountPlan> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const accountPlan = await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "account", "getCurrentPlan"),
        );

        return accountPlan as AccountPlan;
    }

    public async refreshCurrentPlan(signal: AbortSignal, progress: ProgressObserver): Promise<void> {
        const currentPlan = await this.getCurrentAccountPlan(signal, progress);
        this.currentPlanSub.next(currentPlan);
    }

    public async stopCurrentPlanRecurring(signal: AbortSignal, progress: ProgressObserver): Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const params = {
            accountId: this.currentAccount.id,
        };

        await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "product", "stopCurrentPlanRecurring", params),
        );

        await callAsAsync(signal, p, 1, (po) => this.refreshCurrentPlan(signal, po));
    }

    public async reactivateCurrentPlanRecurring(signal: AbortSignal, progress: ProgressObserver): Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const params = {
            accountId: this.currentAccount.id,
        };

        await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(
                signal,
                "product",
                "reactivateCurrentPlanRecurring",
                params,
            ),
        );

        await callAsAsync(signal, p, 1, (po) => this.refreshCurrentPlan(signal, po));
    }

    public async activateFreeTrial(signal: AbortSignal, progress: ProgressObserver): Promise<unknown> {
        const p = makeProgressHandler(progress);
        p.total(2);

        const response = await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "product", "activateFreeTrial"),
        );

        await callAsAsync(signal, p, 1, (po) => this.refreshCurrentPlan(signal, po));

        return response;
    }

    public async contactMe(
        signal: AbortSignal,
        progress: ProgressObserver,
        params: ContactMePayload,
    ): Promise<boolean> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const response = await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "account", "contactMe", params),
        );
        return response as boolean;
    }

    /**
     * Logs in using {@link LoginService.completeLoginFlow()} to validate supplied {@link credentials}
     *
     * @param signal
     * @param progress
     * @param credentials
     */
    public async validateCredentials(
        signal: AbortSignal,
        progress: ProgressObserver,
        credentials: FormCredentials,
    ): Promise<void> {
        if (!isFeatureFlagsPlusAuthenticationServerEnable) {
            return _getLegacyAccountService().validateCredentials(signal, progress, credentials);
        }
        const p = makeProgressHandler(progress);
        p.total(1);

        await callAsAsync(signal, p, 1, (po) =>
            this.oAuthClientService.completeLoginFlow(),
        );
    }

    /**
     * Updates the remote password and stores the new password using {@link storeCredentials()}
     *
     * @param signal
     * @param progress
     * @param credentials
     */
    public async changePassword(
        signal: AbortSignal,
        progress: ProgressObserver,
        credentials: UpdateCredentialsPayload,
    ): Promise<void> {
        throw new Error("Not implemented with new login system [changePassword]");
    }

    public async deleteAccount(signal: AbortSignal, progress: ProgressObserver): Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(1);

        await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "credential", "delete"),
        );
    }

    public async checkVAT(signal: AbortSignal, progress: ProgressObserver, vat: string): Promise<boolean> {
        const p = makeProgressHandler(progress);
        p.total(1);
        if (!vat) {
            return Promise.resolve(false);
        }

        if (vat.length < 7 || vat.length > 20) {
            return Promise.resolve(false);
        }

        const res = await callAsAsync(signal, p, 1, (po) =>
            this.archiwebServicesClient.makeAuthenticatedRequest(signal, "account", "checkVATv2", { vatNumber: vat }),
        );
        return Boolean(res);
    }

    /**
     * Calls `account/find` using a lightweight version of `LegacyArchipadAuthService` to retrieve
     * {@link AccountInfo} during the login process.
     *
     * It then enable corresponding {@link userBundle} aspects.
     *
     * @param signal
     * @param progress
     * @param authToken
     */
    private async retrieveAndEnableUserAspects(
        signal: AbortSignal,
        progress: ProgressObserver,
        accountInfo: AccountInfo,
    ): Promise<void> {
        /**
         * Handle old `loginTasks`, essentially retrieving user dependencies with `account/find`
         * and then updating {@link ConfigServiceBundle} `userBundle`
         */
        const p = makeProgressHandler(progress);
        p.total(1);

        logger.debug("Executing loginTask");
        const userAspect = await callAsAsync(signal, p, 1, (po) => {
            const p = makeProgressHandler(po);
            p.total(1);
            return p.oldTask(1, null, (task) => getUserAspects(task, accountInfo));
        });

        const userBundle = findConfigBundleByName("user");
        userBundle.setEnabledAspects(userAspect);
    }

    /**
     * 
     * @returns The url to redirect to, or null if the user is already authenticated
     */
    public async completeLogin(signal: AbortSignal, progress: ProgressObserver): Promise<string | undefined>{
        try {
            /** Reset {@link userBundle} enabled aspects */
            const userBundle = findConfigBundleByName("user");
            userBundle.setEnabledAspects([]);

            const p = makeProgressHandler(progress);
            p.total(2);

            const urlState = await callAsAsync(signal, p, 1, (po) =>
                this.oAuthClientService.completeLoginFlow(),
            );
            await callAsAsync(signal, p, 1, (po) =>
                this._postLoginProcess(signal, po),
            );
            return urlState;
        } catch (error) {
            this.clearAccountData();
            throw error;
        }

    }

    /**
    * Starts the login process by calling {@link OAuthClientService.startLoginFlow()}
    */
    public async loginClient(signal: AbortSignal, progress: ProgressObserver, redirectionURL: string): Promise<void> {
        try {
            /** Reset {@link userBundle} enabled aspects */
            const userBundle = findConfigBundleByName("user");
            userBundle.setEnabledAspects([]);

            const p = makeProgressHandler(progress);
            p.total(2);

            await callAsAsync(signal, p, 1, (po) =>
                this.oAuthClientService.startLoginFlow(redirectionURL),
            );

            await callAsAsync(signal, p, 1, (po) =>
                this._postLoginProcess(signal, po),
            );
        } catch (error) {
            this.clearAccountData();
            throw error;
        }
    }

    private async _postLoginProcess(signal: AbortSignal, progress: ProgressObserver): Promise<void> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const currentAccount = await callAsAsync<AccountInfo>(signal, p, 1, (po) =>
            this.getCurrentAccount(signal, po),
        );

        const userInformation = new LegacyUserInformation({
            userId: currentAccount.id,
            login: currentAccount.credential.login,
            root: "false", // TODO Get from API
        }, false);

        /** Login process is complete, update observables and services */
        this.currentUserInformationSub.next(userInformation);


        const currentPlan = await callAsAsync<AccountPlan>(signal, p, 1, (po) =>
            this.getCurrentAccountPlan(signal, po),
        );

        
        this.currentAccountSub.next(currentAccount);
        this.currentPlanSub.next(currentPlan);

        await callAsAsync(signal, p, 1, (po) => this.retrieveAndEnableUserAspects(signal, po, currentAccount));

        this.trackingService.setUser({
            userId: userInformation.id,
            login: userInformation.credentialLogin,
            isRoot: userInformation.isRoot,
        });

    }

    private clearAccountData(): void {
        const userBundle = findConfigBundleByName("user");
        userBundle.setEnabledAspects([]);
        this.currentAccountSub.next(null);
        this.currentUserInformationSub.next(null);
        this.trackingService.setUser(null);
    }
}

/**
 * __legacy__ {@file /legacy/modules/archipad/src/backend/archiweb/account.ts} used to expose two observables:
 *
 * - `currentAuthInfo` which contained derived/extended `credential/login` API data.
 * - `currentUser` which contained reduced `currentAuthInfo` data.
 *
 * __Note:__ When `loggedAs`, `currentUser.login` and `currentAuthInfo.login` were different:
 *
 * - `currentUser.login` contained `user@bigsool.com#admin@bigsool.com`
 * - `currentAuthInfo.login` contained `user@bigsool.com`
 */
export class LegacyUserInformation implements LegacyUserInformationInterface {
    /**
     * Either `user@bigsool.com#admin@bigsool.com` or `user@bigsool.com`
     * @todo Rename?
     * */
    public readonly credentialLogin: string;
    public readonly id: number;
    public readonly userId: number;
    public readonly isRoot: boolean;
    public readonly isRestricted: boolean;
    /** When `user_1` is __logged as__ `user_2`, `user_2` will be returned. Else, returns `user_1.` */
    public readonly login: string;
    /** Returns the admin login when `logged as`. */
    public readonly adminLogin: string | null;

    constructor(loginResponse: {
        userId: number;
        login: string;
        root?: string;
    }, isRestricted: boolean) {
        const credentialLogin = loginResponse.login as string;

        /**
         * Ideally, we should not rely on what is inside the token, except {@link AuthToken.end}
         *
         * Usage of `root` sounds like a bad practice. If anywhere, it should be outside the token but it involves API changes.
         */
        this.userId = this.id = loginResponse.userId;
        this.isRestricted = isRestricted;
        this.credentialLogin = credentialLogin;

        if (LegacyUserInformation.isUserLoggedAs(credentialLogin)) {
            this.isRoot = true;
            this.login = loginResponse.login;
            this.adminLogin = this.credentialLogin.substring(this.credentialLogin.indexOf("#"));
        } else {
            this.isRoot = Boolean(loginResponse.root === "true");
            this.login = loginResponse.login;
            this.adminLogin = null;
        }
    }

    public get isLoggedAs(): boolean {
        return Boolean(this.adminLogin);
    }

    public get logAs(): string | null {
        if (this.adminLogin) {
            return this.login;
        } else {
            return null;
        }
    }

    public static isUserLoggedAs(login: string): boolean {
        const loginContainsLogAs = login.indexOf("#") != -1;
        return loginContainsLogAs;
    }
}


export function getAccountService(): AccountService {
    return _LegacyServiceManager.container.get(Account_Service_DIID.symbol);
}


function _getLegacyAccountService(): AccountService {
    /**
     * [PLUS-158] Remove this check 
     */
    const isFeatureFlagsPlusAuthenticationServerEnable = Env["featureFlags"]?.['plusAuthenticationServer'];
    if (isFeatureFlagsPlusAuthenticationServerEnable) {
        throw new Error('Cannot use legacy account service with new login system');
    }
    return _LegacyServiceManager.container.get(Legacy_Account_Service_DIID.symbol) as unknown as AccountService;
}
