import * as S3 from "aws-sdk/clients/s3";
import * as Util from 'aws-sdk/lib/util';
import { IllegalArgumentError, CancelledError, chainError, ConnectionFailedNetworkError } from "@core/errors/errors-core";
import { S3Error } from "@archipad/errors/errors-archipad";
import { createLogger } from "@core/services/logger.service";

const log = createLogger('s3');

declare global { 
    interface Navigator { userAgent:any; }
    interface Window { readonly navigator:Navigator; }
}
declare const window: Window;

(function(Util) {
    if(typeof window !== 'object' || !window['TextDecoder'])
        return;
    
    // patch Buffer.toString() on browsers that support TextDecoder 
    const TextDecoder = window['TextDecoder'];
    const currentToString = Util.Buffer.prototype.toString;

    Util.Buffer.prototype.toString = function(encoding?:string) {
        if(encoding === 'base64' || encoding === 'hex')
            return currentToString.call(this, encoding);
        return new TextDecoder(encoding).decode(this);
    }
})(Util);

/*----------------------------------------------------------------------------*/
export class Creds {
    readonly AccessKeyId:string;
    readonly SecretAccessKey:string;
    readonly SessionToken:string;
    readonly Expiration:Date;

    constructor(params:any) {
        if (typeof params.AccessKeyId !== 'string' || typeof params.SecretAccessKey !== 'string' || typeof params.SessionToken !== 'string' || typeof params.Expiration !== 'string') {
            throw new IllegalArgumentError("Invalid S3 credentials");
        }

        this.AccessKeyId = params.AccessKeyId;
        this.SecretAccessKey = params.SecretAccessKey;
        this.SessionToken = params.SessionToken;
        this.Expiration = new Date(params.Expiration);
    }
}


export class Path {
    readonly region: string;
    readonly bucket: string;
    readonly key: string;

    constructor(region: string, bucket: string, key: string) {
        this.region = region;
        this.bucket = bucket;

        if (key && key.endsWith('/')) {
            this.key = key.substr(0, key.length - 1);
        } else {
            this.key = key;
        }
    }

    get directory(): Path {
        const index = this.key.lastIndexOf('/');
        const path = this.key.substr(0, index === -1 ? 0 : index);

        return new Path(this.region, this.bucket, path);
    }

    appendPathComponent(path: string): Path {
        return new Path(this.region, this.bucket, this.key + '/' + path);
    }

    newPath(path: string): Path {
        return new Path(this.region, this.bucket, path);
    }
}

export class File {
    readonly key:string;
    readonly lastModified:Date;
    readonly size:number;

    constructor(key:string, lastModified:Date, size:number) {
        this.key = key;
        this.lastModified = lastModified;
        this.size = size;
    }
}

export class Context {
    readonly creds: Creds;

    private _endpointsByRegion:Map<string,S3>;

    constructor(creds: Creds) {
        if (!creds || !(creds instanceof Creds)) {
            throw new IllegalArgumentError("invalid creds");
        }

        this.creds = creds;
        this._endpointsByRegion = new Map<string,any>();
    }

    getEndpoint(region:string):S3 {
        let endpoint = this._endpointsByRegion.get(region);
        if(!endpoint) {
            endpoint = new S3({
                accessKeyId : this.creds.AccessKeyId,
                secretAccessKey : this.creds.SecretAccessKey,
                sessionToken : this.creds.SessionToken,
                region : region
            });
            this._endpointsByRegion.set(region, endpoint);
        }

        return endpoint;
    }
}

/*----------------------------------------------------------------------------*/
function wrapS3Error(error: Error): ConnectionFailedNetworkError | Error {
    if (error['retryable']) {
        if ( error['code'] == "NetworkingError" || error['code'] == "TimeoutError" ) {
            throw chainError(error, new ConnectionFailedNetworkError());
        }
    }

    return error;
}

function callS3(task, s3:S3, name:string, params:{ [name:string]:any }):Promise<any> {
    return new Promise(function(resolve, reject) {
        if(task)
            task.checkCancel();
        s3[name](params, function(error,data) {
            try {
                if(task)
                    task.checkCancel();
                if(error) {
                    reject(error);
                } else {
                    resolve(data);
                }
            } catch(err) {
                reject(err);
            }
        });
    });
}

function processNextItem<T>(task, context:Context, itemsToProcess:T[], totalItems:number, operationCb:(task,context:Context,item:T,count:number)=>Promise<boolean>) {
    try {
        if(!itemsToProcess.length)
            return;

        const item = itemsToProcess.pop();
        const count = totalItems-itemsToProcess.length;

        const promise = operationCb(task, context, item, count);
        if(!promise)
            return processNextItem(task, context, itemsToProcess, totalItems, operationCb);
        else
            return promise.then(function() { return processNextItem(task, context, itemsToProcess, totalItems, operationCb); });
    } catch(err) {
        return Promise.reject(err);
    }
}
async function parallelS3Call<T>(task, context:Context, items:T[], maxOperations:number, operationCb:(task,context:Context,item:T,count:number)=>Promise<boolean>):Promise<void> {
    const itemsToProcess = items.slice();

    // process files in parallel
    const processors = [];
    for(let i=0;i<maxOperations;i++)
        processors.push(processNextItem(task, context, itemsToProcess, items.length, operationCb));

    await Promise.all(processors);
}

/*----------------------------------------------------------------------------*/
let count = 0;

export function listFilesAtPath(task, context:Context, path:Path, onPage:(files:File[])=>void):Promise<boolean> {
    const s3 = context.getEndpoint(path.region);

    let maxKeys = null;
    
    if(typeof window === 'object' && window.navigator.userAgent.indexOf('Trident/') !== -1) {
        // IE11 only hack: pass a different value of MaxKey on each call to avoid 
        // random XHR caching
        maxKeys = 1000000 + (count++);
    }

    function onError(err:Error) {
        if (err instanceof CancelledError) {
            throw err;
        }

        log.error('unable to listFilesAtPath ' + path, err);
        const error = new S3Error('unable to listFilesAtPath');
        error.addContext('Archipad', { 'path': path });
        throw chainError(wrapS3Error(err), error);
    }

    function handleNextPage(data) {
        const files:File[] = [];
        for(const entry of data.Contents)
            files.push(new File(entry.Key, entry.LastModified, entry.Size));
        onPage(files);
        if(!data.IsTruncated) {
            // request complete
            return;
        } else {
            let nextMarker = data.NextMarker;
            if(!nextMarker)
                nextMarker = data.Contents[data.Contents.length-1].Key;

            // more data available
            return callS3(task, s3, 'listObjects', { Bucket: path.bucket, Prefix:path.key + '/', Marker:nextMarker, MaxKeys:maxKeys }).then(handleNextPage).catch(onError);
        }
    }
    return callS3(task, s3, 'listObjects', { Bucket: path.bucket, Prefix:path.key + '/', MaxKeys:maxKeys }).then(handleNextPage).catch(onError);
}

export function listDirectoriesAtPath(task, context:Context, path:Path, onPage:(files:File[])=>void):Promise<boolean> {
    const s3 = context.getEndpoint(path.region);

    let maxKeys = null;
    
    if(typeof window === 'object' && window.navigator.userAgent.indexOf('Trident/') !== -1) {
        // IE11 only hack: pass a different value of MaxKey on each call to avoid 
        // random XHR caching
        maxKeys = 1000000 + (count++);
    }

    function onError(err) {
        log.error('unable to listDirectoriesAtPath ' + path, err);
        const error = new S3Error('unable to listDirectoriesAtPath');
        error.addContext('Archipad', { 'path': path });
        throw chainError(wrapS3Error(err), error);
    }

    function handleNextPage(data) {
        const files:File[] = [];
        for(const entry of data.CommonPrefixes)
            files.push(new File(entry.Prefix, null, null));
        onPage(files);
        if(!data.IsTruncated) {
            // request complete
            return;
        } else {
            const nextMarker = data.NextMarker;

            // more data available
            return callS3(task, s3, 'listObjects', {Bucket: path.bucket, Prefix:path.key + '/', Delimiter:'/', Marker:nextMarker, MaxKeys:maxKeys }).then(handleNextPage, onError);
        }
    }
    return callS3(task, s3, 'listObjects', { Bucket: path.bucket, Prefix:path.key + '/', Delimiter:'/', MaxKeys:maxKeys }).then(handleNextPage).catch(onError);
}

export function fetchFilesAtPath(task, context:Context, files:Path[], onFetch:(file:Path,data)=>Promise<void>, maxOperations = 16):Promise<void> {
    if(task) {
        task.setTotalUnits(files.length);
        task.setCompletedUnits(0);
    }
    return parallelS3Call(task, context, files, maxOperations, function(task, context, file, count) {
        log.info('loading', file);

        const s3 = context.getEndpoint(file.region);
        return callS3(task, s3, 'getObject', {
            Bucket: file.bucket,
            Key: file.key,

            /**
             * It actually tells the browser to cached the response body only.
             * 
             * So if the ETag is unchanged, it will not download it, but if it does, so it will.
             * @link https://developer.mozilla.org/fr/docs/Web/HTTP/Headers/Cache-Control#no-cache_2
             */
            ResponseCacheControl: 'no-cache',
        }).then(function(data) {
            return onFetch(file, data.Body);
        }).then(function() {
            if(task)
                task.setCompletedUnits(count);
            log.info('loaded', file);
            return true;
        }).catch(function(err) {
            log.error('unable to load ', file.bucket + '/' + file.key, err);
            const error = new S3Error('unable to load file');
            error.addContext('Archipad', { 'file': file });
            throw chainError(wrapS3Error(err), error);
        });
    });
}

export function copyFiles(task, context:Context, items:{ source:Path, dest:Path }[], acl?:string, maxOperations = 4):Promise<void> {
    return parallelS3Call(task, context, items, maxOperations, function(task, context:Context, item) {
        log.info('copying', item);
        const s3 = context.getEndpoint(item.dest.region);
        return callS3(task, s3, 'copyObject', {
            Bucket: item.dest.bucket,
            Key: item.dest.key,
            // Do not remove the encodeURIComponent below
            // The path encoding MUST be consistent with the rest of the API
            // or uploading files with '+' char will fail
            CopySource: encodeURIComponent(item.source.bucket + '/' + item.source.key),
            ACL: (acl || 'private')
        }).then(function() {
            log.info('copied', item);
            return true;
        }).catch(function(err) {
            log.error('unable to copy ', item.source.bucket + '/' + item.source.key, item.dest.bucket + '/' + item.dest.key, err);
            const error = new S3Error('unable to copy');
            error.addContext('Archipad', { 'source': item.source });
            error.addContext('Archipad', { 'destination': item.dest });
            throw chainError(wrapS3Error(err), error);
        });
    });
}

export function deleteFilesUnderPath(task, context:Context, path:Path):Promise<void> {
    const files = [];
    return listFilesAtPath(task, context, path, function(page) {
        page.forEach(function(file) {
            files.push(new Path(path.region,path.bucket,file.key));
        });
    }).then(function() {
    	if (files.length !== 0)
    		return deleteFiles(task, context, files);
    	return;
    });
}

export function deleteFiles(task, context:Context, files:Path[], maxOperations = 4):Promise<void> {
    return parallelS3Call(task, context, files, maxOperations, function(task, context, file) {
        log.info('deleting', file);
        const s3 = context.getEndpoint(file.region)
        return callS3(task, s3, 'deleteObject', {
            Bucket: file.bucket,
            Key: file.key
        }).then(function() {
            log.info('deleted', file.bucket + '/' + file.key);
            return null;
        }).catch(function(err) {
            log.error('unable to delete', file.bucket + '/' + file.key, err);
            const error = new S3Error('unable to delete');
            error.addContext('Archipad', { 'file': file });
            throw chainError(wrapS3Error(err), error);
        });
    });
}

export function uploadFileToPath(task, context:Context, file:Path, data:any):Promise<void> {
    log.info('sending', file);
    const s3 = context.getEndpoint(file.region)
    return callS3(task ,s3, 'upload', {
        Bucket: file.bucket,
        Key: file.key,
        ACL: 'private',
        Body: data
    }).then(function() {
        log.info('sent', file);
    }).catch(function(err) {
        log.error('unable to send ' + file.bucket + '/' + file.key, err);
        const error = new S3Error('unable to send');
        error.addContext('Archipad', { 'file': file });
        throw chainError(wrapS3Error(err), error);
    });
}

export function getSignedUrlFor(context:Context, path:Path, expires):string {
    const s3 = context.getEndpoint(path.region);
    const url = s3['getSignedUrl']('getObject', {Bucket: path.bucket, Key: path.key, Expires: expires});
    return url;
}
