import { Logger, LoggerManager } from "@archipad-js/core/logger";

export function createLogger(name: string): Logger {
    
    // @TOOL : Avoid having too much messages as Chrome dev tools becomes really buggy after
    /* const emptyFn = () => {};
    const skippedKeys = name === 'orm' || name === 'queue' || name === 'tracking' || name === 'index';
    if (skippedKeys) {
        return {
            debug: emptyFn,
            info: emptyFn,
            warn: emptyFn,
            error: emptyFn,
            time: emptyFn,
            timeEnd: emptyFn,
            setEnabled: emptyFn,
            isEnabled: () => true,
        };
    } */

    const logger = LoggerManager.getLogger(name);
    return logger;
}
