/**
* FormTypeViewEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { FormTypeVersionEntity } from "./FormTypeVersionEntity";


declare global {
    interface EntityTypeMap {
        "FormTypeView": FormTypeViewEntity
    }
}

export abstract class FormTypeViewEntity extends BaseEntity {

	 abstract formTypeViewId: string;
	 abstract type: string;
	 abstract name: string;
	 abstract engineVersion: number;
	 abstract engineMinVersion: number;
	 abstract version: FormTypeVersionEntity;
	 abstract templateId?: string;
	 abstract data?: string;
	
}
