
import { BehaviorSubject, Observable, of, from, throwError } from 'rxjs';
import { switchMap, pairwise, startWith, catchError } from 'rxjs/operators';

import { createLogger } from '@core/services/logger.service';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { GeneratorType, task, call } from '@core/tasks/generator';
import { TaskQueue } from '@core/tasks/queue';

import * as synchroDriver from '@archipad/backend/archiweb/synchroDriver';
import { RemoteListProjectInfo, RemoteProjectInfo } from '@archipad/backend/archiweb/synchroBackend';

import * as projectLocalStore from '@ui-archipad/backend/project/localStore';
import * as interactions from '@ui-archipad/backend/project/interactions';
import { MakeProjectManager, createProjectFromTemplate, ProjectManager, sanitizeRemoteProjectInfo } from '@ui-archipad/backend/project/project';
import { IllegalArgumentError, IllegalStateError, UnexpectedError } from '@core/errors/errors-core';
import { ProjectNotFound, ReadEmptyPatchSetError } from '@archipad/errors/errors-archipad';
import { ErrorHandlerService } from '@core/services/errorHandler';
import * as Patch from '@archipad/backend/project/patch';
import { callAsAsync, callAsGenerator } from '@core/tasks/asyncCaller';
import { MinimalProjectInfo } from '@archipad/backend/project/projectInfo';
import { getAccountService } from '@archipad/services/account.service';
import { AccountInfo } from '@archipad/services/account.service.types';

const log = createLogger('projectList');

type ProjectsById = { [projectId:string]:ProjectManager };

export class ProjectListNotification {
    constructor() {
        this.added = [];
        this.removed = [];
        this.updated = [];
    }

    readonly added: string[];
    readonly removed: string[];
    readonly updated: string[];
}

/*---------------------------------------------------------------------------*/
export class ProjectList {
    private _taskQueue: TaskQueue;
    private _projectInstances$: BehaviorSubject<ProjectsById>;

    public readonly notifications$: Observable<ProjectListNotification>;

    constructor() {
        const projectInstances$ = new BehaviorSubject<ProjectsById>({});
        
        const accountService = getAccountService();
        const currentUser$ = accountService.currentUserInformation$.pipe(
            startWith(null),
            pairwise(),
            switchMap(([previousUser, _currentUser]) => {
                if(previousUser)
                    return interactions.clearCachedInteractionsForUser(previousUser.userId);
                else
                    return of(null);
            })
        );

        const notifications$ = currentUser$.pipe(	
            startWith(null),	
            switchMap(() => projectInstances$),	
            pairwise(),	
            switchMap(([ previousInstances, currentInstances ]) => {	
                const notification = new ProjectListNotification();	

                // removed instances	
                for(const projectId in previousInstances) {	
                    if(!(projectId in currentInstances)){
                        notification.removed.push(projectId);
                    }
                }	

                // added instances	
                for(const projectId in currentInstances) {	
                    if(!(projectId in previousInstances)){
                        notification.added.push(projectId);
                    }
                }


                return of(notification)
        }));

        this._taskQueue = new TaskQueue();
        this._projectInstances$ = projectInstances$;
        this.notifications$ = notifications$;
    }


    private async _getUserInfo(signal: AbortSignal, progress: ProgressObserver): Promise<Patch.UserInfo> {
        const p = makeProgressHandler(progress);
        p.total(1);
        const accountService = getAccountService();
        let accountInfo: AccountInfo;
        if (accountService.isAuthenticated){
            accountInfo = accountService.currentAccount;
        } else {
            accountInfo = await callAsAsync(signal, p, 1, (po) => accountService.getCurrentAccount(signal, po));
        }
        if (!accountInfo) {
            throw new IllegalStateError("No account information available");
        }

        return {
            id: accountInfo.id,
            email: accountInfo?.credential?.login,
            company_id: accountInfo?.company?.id,
        };
    }

    getProjectsIds(): ReadonlyArray<string> {
        const instances = this._projectInstances$.getValue();
        const ids = [];

        for(const id in instances){
            ids.push(id);
        }

        return ids;
    }

    getProjectInstances(): ReadonlyArray<ProjectManager> {
        const instances = this._projectInstances$.getValue();

        const projects = [];
        for(const projectId in instances) {
            projects.push(instances[projectId]);
        }

        return projects;
    }

    getProjectInstanceById(projectId: string): ProjectManager {
        const instances = this._projectInstances$.getValue();
        return instances[projectId];
    }

    // refresh project list
    private *_refresh(progress: ProgressObserver, projectId?: string): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.label(l('Loading project list ...'));
        p.total(100);

        const userInfo = yield* callAsGenerator(p, 5, (signal, po) => 
            this._getUserInfo(signal, po),
        );

        // make sure the project list is up to date with local storage
        yield* this._loadLocalProjects(p.task(5));
        
        const currentLocalProjects = this._projectInstances$.getValue();
        const newLocalProjects:{ [projectId:string]:ProjectManager } = {};
        
        for(const projectId in currentLocalProjects) {
            const projectInstance = currentLocalProjects[projectId];
            yield* call(
                projectInstance.refreshProjectInfo(p.task(0)).pipe(
                    catchError((err) => {
                        if ((err instanceof ReadEmptyPatchSetError)) {
                            // ignore EMPTY_PATCHSET_ERROR when it occurs in the project list
                            // because it would completly stuck the user.
                            return of(undefined);
                        }

                        return throwError(err);
                    })
                )
            );
            if (!projectInstance.getInfo().registered) {
                // project is not registered, publish it
                // TODO : this should happen only in case of error. remove it instead ?
                yield* call(projectInstance.publishChanges(progress));
            }
        }

        const remoteProjects: Array<RemoteListProjectInfo | RemoteProjectInfo> = [];

        if (projectId) {
            const unsanitizedRemoteInfo = yield * call(synchroDriver.getProjectInfo(p.task(80), projectId));
            remoteProjects.push(unsanitizedRemoteInfo);
        } else {
            const unsanitizedRemoteInfos = yield* call(p.oldTask(95, null, (task) => synchroDriver.getProjects(task)));
            remoteProjects.push(...unsanitizedRemoteInfos);
        }

        // create new projects or update existing projects
        for(const unsanitizedRemoteInfo of remoteProjects) {
            const remoteInfo = sanitizeRemoteProjectInfo(unsanitizedRemoteInfo);
            const lastPatchId = remoteInfo.lastPatchId;

            let project = currentLocalProjects[remoteInfo.id];
            if (project) {
                // update existing project with remote info
                const modified = yield* call(project.update(p.task(0), remoteInfo, lastPatchId));
                if (modified) {
                    log.info("updated project " + remoteInfo.name);
                }
            } else {
                // else simply create it
                project = MakeProjectManager(userInfo, remoteInfo);
                yield* call(project.update(p.task(0), remoteInfo, lastPatchId));
                log.info("created project " + remoteInfo.name);
            }

            newLocalProjects[remoteInfo.id] = project;
        }
        /**
         * If the project id is filled in then the list of remote projects is not retrieved entirely
         */
        const isRemoteProjectsIsComplete = !projectId;
        if (isRemoteProjectsIsComplete){
            // remove projects that are no longer present remotely
            for(const projectId in currentLocalProjects) {
                if(projectId in newLocalProjects) {
                    continue;
                }

                const project = currentLocalProjects[projectId];
                const projectInfo = project.getInfo();
                if(projectInfo.registered) {
                    yield* call(p.oldTask(0, null, (task) => projectLocalStore.removeProject(task, userInfo.id, projectId)));
                    log.info("deleted project " + projectId + " " + projectInfo.name + " since it was removed from cloud");
                } else {
                    log.debug("Ignoring unregisted project " + projectId + " " + projectInfo.name);
                }
            }
        }

        // update project instances
        this._projectInstances$.next(newLocalProjects);
    }

    private *_loadLocalProjects(progress:ProgressObserver): GeneratorType<void> {
        const p = makeProgressHandler(progress);
        p.label(l('Loading project list ...'));
        p.total(100);
        
        const userInfo = yield* callAsGenerator(p, 10, (signal, po) => 
            this._getUserInfo(signal, po),
        );

        const localProjects = yield* call(p.oldTask(90, null, (task) => projectLocalStore.listProjects(task, userInfo.id)));

        const currentInstances = this._projectInstances$.getValue();
        const newInstances: { [projectId:string]: ProjectManager } = {};

        // get or create the project instances
        for (const localProject of localProjects) {
            const projectId = localProject.info.id;
            if(projectId in currentInstances) {
                newInstances[projectId] = currentInstances[projectId];
            } else {
                newInstances[projectId] = MakeProjectManager(userInfo, localProject.info);
            }
        }

        this._projectInstances$.next(newInstances);
    }

    private *_addFromTemplates(progress: ProgressObserver, projectName:string, templateIds:string[]): GeneratorType<MinimalProjectInfo> {
        const p = makeProgressHandler(progress);
        p.label(l('Adding project...'));
        p.total(100);

        log.debug("creating a new project from templates " + JSON.stringify(templateIds));

        const userInfo = yield* callAsGenerator(p, 10, (signal, po) => 
            this._getUserInfo(signal, po),
        );

        const [ projectInfo , projectManager ] = yield* callAsGenerator(p, 90, (signal, po) => 
            createProjectFromTemplate(signal, po, userInfo, projectName, templateIds, false) );
        
        // update cache
        const currentLocalProjects = this._projectInstances$.getValue();
        currentLocalProjects[projectInfo.id] = projectManager;
        this._projectInstances$.next(currentLocalProjects);

        return projectInfo;
    }

    private *_remove(progress: ProgressObserver, projectId: string): GeneratorType<void> {
        log.debug("removing project " + projectId);

        const project = this.getProjectInstanceById(projectId);
        if (!project) {
            throw new IllegalArgumentError("No such project to remove " + projectId);
        }

        if (!project.getInfo().isOwner) {
            return;
        }

        yield* call(project.remove(progress));

        const currentLocalProjects = this._projectInstances$.getValue();
        
        const newLocalProjects = Object.assign({}, currentLocalProjects);
        delete newLocalProjects[projectId];
        this._projectInstances$.next(newLocalProjects);

        log.debug("removed project " + projectId);
    }

    private *_get(progress: ProgressObserver, projectId: string): GeneratorType<ProjectManager> {
        const p = makeProgressHandler(progress);
        p.total(1);
        
        let project = this.getProjectInstanceById(projectId);
        if (project) {
            // short path: return project directly from instance cache
            return project;
        }

        // load the project list from IndexedDB
        yield* call(this.loadLocalProjects(p.task(0)));
        
        project = this.getProjectInstanceById(projectId);
        if (!project) {
            // if we don't have the project locally, try to fetch it remotely
            yield* call(this.refresh(p.task(1), projectId));

            project = this.getProjectInstanceById(projectId);
            if (!project) {
                // still not here? it does not (or no longer) exists
                const error = new ProjectNotFound();
                error.addTag('archipad.project.id', projectId);
                throw error;
            }
        }
        return project;
    }


    private *_wipeCache(progress: ProgressObserver): GeneratorType<void> {
        // TODO: make sure that all contexts are closed ?

        // not using getProjectCache but local store directly to avoid
        // errors while Project instance creation

        const p = makeProgressHandler(progress);
        p.total(100);
        const userInfo = yield* callAsGenerator(p, 10, (signal, po) => 
            this._getUserInfo(signal, po),
        );

        // remove interactions for user
        yield* call(from(interactions.clearCachedInteractionsForUser(userInfo.id)));

        yield* this._loadLocalProjects(p.task(80));

        const currentLocalProjects = this._projectInstances$.getValue();
        const projectIds = Object.keys(currentLocalProjects);
        
        p.total(projectIds.length);

        for(const projectId of projectIds) {
            yield* call(p.oldTask(10, null, (task) => projectLocalStore.removeProject(task, userInfo.id, projectId)));
        }

        this._projectInstances$.next({})
    }

    private *_flush(progress: ProgressObserver): GeneratorType<void> {
        const p = makeProgressHandler(progress);

        yield* this._loadLocalProjects(p.task(0));
        const projects = this.getProjectInstances();
        
        const failedProjectNames = [];

        let count = 0;
        for(const _projectId in projects) {
            count++;
        }
        p.total(count+1);

        for(const project of projects) {
            // context wasn't opened before, there is no changes, don't try to flush.
            if(!project.hasContext()){
                // @tool : comment to enforce an error while flush on logout on each user project
                continue;
            }

            try {
                yield* call(project.flushTempChanges(p.task(1)));
            } catch(err) {
                const projectInfo = project.getInfo();
                if(!projectInfo || !projectInfo.name) {
                    return;
                }
                log.error("Unable to flush project", projectInfo.name, err);
                failedProjectNames.push(projectInfo.name);
            }
        }

        yield* this._refresh(p.task(1));

        if(failedProjectNames.length) {
            const error =  new UnexpectedError('pushFailed');
            error['failedProjectNames'] = failedProjectNames;
            throw error;
        }
    }

    loadLocalProjects(progress: ProgressObserver): Observable<void> {
        return this._taskQueue.addTask(progress, {
            key: 'loadLocalProjects',
            taskFn: (progress) => task(() => this._loadLocalProjects(progress))
        });
    }

    refresh(progress: ProgressObserver, projectId?: string): Observable<void> {
        return this._taskQueue.addTask(progress, {
            key: 'refresh',
            context: {
                projectId,
            },
            taskFn: (progress) => task(() => this._refresh(progress, projectId)),
        });
    }

    addFromTemplates(progress: ProgressObserver, projectName:string, templateIds:string[]): Observable<MinimalProjectInfo> {
        return task(() => this._addFromTemplates(progress, projectName, templateIds));
    }

    remove(progress: ProgressObserver, projectId: string): Observable<void> {
        return task(() => this._remove(progress, projectId));
    }

    get(progress: ProgressObserver, projectId: string): Observable<ProjectManager> {
        return task(() => this._get(progress, projectId)).pipe(
            catchError((err) => {
                ErrorHandlerService.handleError(err);
                
                return of(null);
            })
        );
    }

    wipeCache(progress: ProgressObserver): Observable<void> {
        return this._taskQueue.addTask(progress, {
            key: 'wipeCache',
            taskFn: (progress) => task(() => this._wipeCache(progress))
        });
    }
    
    flush(progress: ProgressObserver): Observable<void> {
        return this._taskQueue.addTask(progress, {
            key: 'flush',
            taskFn: (progress) => task(() => this._flush(progress))
        });
    }

    hasUnflushedChanges() {
        const projectInstances = this._projectInstances$.getValue();

        const projects = Object.keys(projectInstances).map(function(key) { return projectInstances[key]; });
        for (let i = 0; i < projects.length; i++) {
            if (projects[i].getInfo().state.hasUnflushedChanges) {
                return true;
            }
        }

        return false;
    }
}

/*---------------------------------------------------------------------------*/
let _sharedProjectList:ProjectList = null;

// Service methods
export function sharedProjectList(): ProjectList {
    if( !_sharedProjectList ) {
        _sharedProjectList = new ProjectList();
    }
    return _sharedProjectList;
}
