import { Observable, throwError, defer, combineLatest, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as S3 from "aws-sdk/clients/s3";
import * as Util from 'aws-sdk/lib/util';

import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { FileDescriptor, FileDriver, Data, FileService, PathDescriptor, mimeTypeForPath, extensionForPath, extensionToMimeType, FileMetadata } from "@core/services/file/file";
import { FileNotFoundError, FileError, IllegalArgumentError, chainError } from '@core/errors/errors-core';

import "@core/drivers/fileData";

/*----------------------------------------------------------------------------*/
declare global { 
    interface Navigator { userAgent:any; }
    interface Window { readonly navigator:Navigator; }
}
declare const window: Window;

(function(Util) {
    if(typeof window !== 'object' || !window['TextDecoder'])
        return;
    
    // patch Buffer.toString() on browsers that support TextDecoder 
    const TextDecoder = window['TextDecoder'];
    const currentToString = Util.Buffer.prototype.toString;

    Util.Buffer.prototype.toString = function(encoding?:string) {
        if(encoding === 'base64' || encoding === 'hex')
            return currentToString.call(this, encoding);
        return new TextDecoder(encoding).decode(this);
    }
})(Util);


/** s3 file is an attachment in a dump patch */
export enum S3DumpPatchFileType { VALUE = 'dumpPatch' }

export class S3DumpPatchPathDescriptor extends PathDescriptor {
    get type(): 's3' { return 's3' as const }

    /** project id for this path */
    readonly projectId:string;
    /** type of location for this path */
    readonly s3Type: S3DumpPatchFileType
    /** acl to use to create files */
    readonly acl:string | null;

    constructor(projectId:string, s3Type: S3DumpPatchFileType, acl:string = null) {
        super();
        this.projectId = projectId;
        this.s3Type = s3Type;
        this.acl = acl;
    }

    toString(): string {
        return `S3DumpPatchPath(${this.projectId}, "${this.s3Type}"${ this.acl ? `, "${this.acl}"` : "" })`;
    }
}

/*---------------------------------------------------------------------------*/
export type S3ProjectFileTypes =
    /** s3 file is a temp attachment */
    'temp' |
    /** s3 file is an attachment in a patch */
    'patch' |
    /** s3 file is an attachment in a dump patch */
    S3DumpPatchFileType.VALUE |
    /** s3 file is in the filestack s3 bucket */
    'upload';

/**
 * Represents a path relative to a project in S3
 */
export class S3ProjectPathDescriptor extends PathDescriptor {
    get type(): 's3' { return 's3' as const }

    /** project id for this path */
    readonly projectId:string;
    /** type of location for this path */
    readonly s3Type:S3ProjectFileTypes;
    /** acl to use to create files */
    readonly acl:string | null;

    constructor(projectId:string, s3Type:S3ProjectFileTypes, acl:string = null) {
        super();
        this.projectId = projectId;
        this.s3Type = s3Type;
        this.acl = acl;
    }

    toString(): string {
        return `S3ProjectPath(${this.projectId}, "${this.s3Type}"${ this.acl ? `, "${this.acl}"` : "" })`;
    }
}

export type S3ReportFileTypes =
    /** s3 file belongs to a report export (in order to prepare a report generation) */
    'reportExport' |
    /** s3 file belongs to a report custom resource, such as the pdf background (in order to prepare a report generation) */
    'reportCustomResource' |
    /** s3 file belongs to a report output (a generated file, the result of report generation) */
    'reportOutput';


export class S3ReportPathDescriptor extends PathDescriptor {
    get type(): 's3' { return 's3' as const }

    /** project id for this path */
    readonly projectId:string;
    /** report id for this path */
    readonly reportId: string;
    /** type of location for this path */
    readonly s3Type:S3ReportFileTypes;
    /** acl to use to create files */
    readonly acl?:string;

    constructor(projectId:string, reportId: string, s3Type:S3ReportFileTypes, acl?:string) {
        super();
        this.projectId = projectId;
        this.reportId = reportId;
        this.s3Type = s3Type;
        this.acl = acl;
    }

    toString(): string {
        return `S3ReportPath(${this.projectId}, "${this.s3Type}"${ this.acl ? `, "${this.acl}"` : "" })`;
    }
}

export const enum S3ReportTemplateFileTypes {
    /** s3 file is a template attachment */
    REPORT_TEMPLATE_UPLOAD = 'reportTemplateUpload',
    /** s3 file is a template attachment */
    REPORT_TEMPLATE_IMAGES_PREFIX = 'reportTemplate',
    /** s3 file is a template in a transient folder */
    REPORT_TEMPLATE_TRANSIENT = 'reportTemplateTransient',
}

export const NEW_REPORT_TEMPLATE_ID = Symbol('NEW_REPORT_TEMPLATE_ID');

/**
 * Represents a path relative to a report template in S3
 */
export class S3ReportTemplatePathDescriptor extends PathDescriptor {
    get type(): 's3' { return 's3' as const }

    /** report template id for this path */
    readonly reportTemplateId: string | typeof NEW_REPORT_TEMPLATE_ID | null;

    /** project id is needed to call report template backend */
    readonly projectId: string | null;

    /** type of storage info for this location */
    readonly s3Type:S3ReportTemplateFileTypes;
    /** acl to use to create files */
    readonly acl:string|null;

    public static MakeForReportTemplate(reportTemplateId: string | typeof NEW_REPORT_TEMPLATE_ID, projectId: string, acl?: string): S3ReportTemplatePathDescriptor {
        return new S3ReportTemplatePathDescriptor(
            S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX,
            reportTemplateId,
            projectId,
            acl,
        );
    }

    public static MakeForUploadReportTemplate(acl?: string): S3ReportTemplatePathDescriptor {
        return new S3ReportTemplatePathDescriptor(
            S3ReportTemplateFileTypes.REPORT_TEMPLATE_UPLOAD,
            null,
            null,
            acl,
        );
    }

    public static MakeForTransientReportTemplate(acl?: string): S3ReportTemplatePathDescriptor {
        return new S3ReportTemplatePathDescriptor(
            S3ReportTemplateFileTypes.REPORT_TEMPLATE_TRANSIENT,
            null,
            null,
            acl,
        );
    }

    private constructor(s3Type: S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX, reportTemplateId: string | typeof NEW_REPORT_TEMPLATE_ID, projectId: string, acl?: string)
    private constructor(s3Type: Exclude<S3ReportTemplateFileTypes, S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX>, reportTemplateId?: null, projectId?: null, acl?: string)
    private constructor(s3Type: S3ReportTemplateFileTypes, reportTemplateId: string | typeof NEW_REPORT_TEMPLATE_ID = null, projectId: string = null, acl: string = null) {
        super();
        if (s3Type === S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX) {
            if (reportTemplateId !== NEW_REPORT_TEMPLATE_ID && Number.isInteger(Number(reportTemplateId))) {
                console.warn(`Invalid report template id`, 'reportTemplateId', '^[0-9]+$', reportTemplateId);
            }
            if (Number.isInteger(Number(projectId))) {
                console.warn(`Invalid project id`, 'projectId', '^[0-9]+$', projectId);
            }
        }
        this.reportTemplateId = reportTemplateId;
        this.projectId = projectId;
        this.s3Type = s3Type;
        this.acl = acl;
    }

    toString(): string {
        const reportTemplateId = this.reportTemplateId === NEW_REPORT_TEMPLATE_ID ? 'NEW_REPORT_TEMPLATE_ID' : this.reportTemplateId;
        return `S3ReportTemplatePath("${this.s3Type}"${ this.acl ? `, "${this.acl}"` : "" }, ${reportTemplateId}, ${this.projectId})`;
    }
}


/**
 * Represents a path associated to a s3 context
 */
export class S3ContextPathDescriptor extends PathDescriptor {
    get type(): 's3' { return 's3' as const }

    /** project template id for this location */
    readonly context:S3Context;
    /** type of storage info for this location */
    readonly s3Type:"context";

    constructor(context: S3Context) {
        super();
        this.context = context;
        this.s3Type = "context";
    }

    toString(): string {
        return `S3ContextPathDescriptor("${this.context.bucket}/${this.context.keyPrefix}")`;
    }
}

/*---------------------------------------------------------------------------*/

export type S3PathDescriptor = S3ProjectPathDescriptor | S3ReportPathDescriptor | S3ReportTemplatePathDescriptor | S3ContextPathDescriptor | S3DumpPatchPathDescriptor;
export type S3FileTypes = S3ProjectFileTypes | S3ReportFileTypes | S3ReportTemplateFileTypes | "context" | S3DumpPatchFileType;

/**
 * Represents a file on S3
 */
export class S3FileDescriptor extends FileDescriptor {
    get type(): 's3' { return 's3' as const }
    /** location for this file */
    readonly location: S3PathDescriptor;
    /** path relative to the location */
    readonly path: string;

    constructor(location: S3PathDescriptor, path: string) {
        super();
        this.location = location;
        this.path = PathDescriptor.normalizePath(path);

        const ext = extensionForPath(this.path);
        if(ext)
            this.mimeType = extensionToMimeType(ext);
    }
    toString(): string {
        return `S3File(${this.location},"${this.path}")`;
    }
}

declare global {
    interface FileTypeMap {
        's3': S3FileDescriptor
    }
}

/*---------------------------------------------------------------------------*/

/**
 * An s3 context with an endpoint ready to use by the AWS.S3 API
 * 
 * Example:
 * ```ts
 * const s3Context:S3Context = ...;
 * s3Context.endpoint.getObject({ Bucket:s3Context.bucket, Key:s3Context.keyPrefix+key }, callback)
 * ```
 */
export interface S3Context {
    /** s3 endpoint */
    endpoint: S3;
    /** s3 bucket */
    region: string;
    /** s3 bucket */
    bucket: string;
    /** s3 key prefix */
    keyPrefix: string|null;
    /** s3 acl to use to create files */
    acl: string|null;

    /** s3 upload policy */
    policy?: string;
    /** s3 upload signature */
    signature?: string;
}

export function wrapS3Error(err: Error, method: string, source?: FileDescriptor, destination?: FileDescriptor): Error {
    const isRecoverable = ('retryable' in err && err['retryable']);

    if (err.name === "NoSuchKey") {
        const notFoundError = FileNotFoundError.makeError(method, source, destination);
        notFoundError.recoverable = Boolean(isRecoverable);
        return chainError(err, notFoundError);
    }

    const fileError = FileError.makeError(method, source, destination);
    fileError.recoverable = Boolean(isRecoverable);
    return chainError(err, fileError);
}

/**
 * Resolves an S3PathDescriptor into an S3Context ready to use by the AWS.S3 API
 */
export type S3LocationResolver = (location:S3PathDescriptor) => Observable<S3Context>;

// /!\ S3 works in seconds (while object should be used)

// const PRESIGNED_URL_EXPIRATION = 15 * 60;   // 15min
const PRESIGNED_URL_EXPIRATION = 24 * 60 * 60; // 24h

class S3FileDriverImpl implements FileDriver<S3FileDescriptor> {
    private _presignedUrlCache: WeakMap<S3, Map<string,{ urlPromise:Promise<string>, expiration:number }>>;
    private _locationResolverByType: Map<S3FileTypes, S3LocationResolver>;

    constructor() {
        this._presignedUrlCache = new  WeakMap();
        this._locationResolverByType = new Map();
    }

    private _makeKey(context: S3Context, file: S3FileDescriptor): string {
        if(context.keyPrefix)
            return `${context.keyPrefix}/${file.path}`;
        else
            return file.path;
    }

    /**
     * Remove a presigned url from cache
     * Should be used before any upload / copy operation
     * 
     * @param context
     * @param file
     */
    private _removePresignedCacheFor(context: S3Context, file: S3FileDescriptor){
        const urlsForEndpoint = this._presignedUrlCache.get(context.endpoint);

        const s3CacheKey = `${context.bucket}/${context.keyPrefix}/${file.path}`;
        if(urlsForEndpoint){
            const cacheEntry = urlsForEndpoint.get(s3CacheKey);
            // Enforce drop cache, we are overwriting a file who exists
            if(cacheEntry){
                urlsForEndpoint.delete(s3CacheKey);
            }
        }
    }

    /**
     * 
     */
    resolveLocation(path:S3PathDescriptor): Observable<S3Context> {
        if(path.s3Type === "context")
            return of(path.context);
        
        const pathResolver = this._locationResolverByType.get(path.s3Type);
        if(!pathResolver)
            return throwError(new Error(`No path resolver for "${path.s3Type}"`));
        return pathResolver(path).pipe(
            map(function(context) {
                context.keyPrefix = PathDescriptor.normalizePath(context.keyPrefix);
                return context;
            })
        );
    }

    setLocationResolver(s3Type:S3FileTypes, locationResolver: S3LocationResolver) {
        this._locationResolverByType.set(s3Type, locationResolver);
    }

    download(file: S3FileDescriptor): Data {
        const requestCallback = () => {
            return this.resolveLocation(file.location).pipe(
                map((context) => {
                    return context.endpoint.getObject({ Bucket:context.bucket, Key: this._makeKey(context, file) });
                }),
                catchError((err) => {
                    return throwError(wrapS3Error(err, "download", file));
                })
            );
        }
        return Data.fromS3FetchRequest(requestCallback, file.mimeType);
    }
    
    upload(progress: ProgressObserver, file: S3FileDescriptor, data: Data): Observable<unknown> {
        return defer(() => {
            if(!file.mimeType)
                file.mimeType = mimeTypeForPath(file.path);

            return this.resolveLocation(file.location).pipe(
                switchMap((context) => {
                    this._removePresignedCacheFor(context, file);

                    return data.pipeToS3Upload(progress, context, this._makeKey(context, file));
                }),
                catchError((err) => {
                    return throwError(wrapS3Error(err, "upload", file));
                })
            );
        });
    }
    
    delete(progress: ProgressObserver, source: S3FileDescriptor): Observable<unknown> {
        return defer(() => {
            const p = makeProgressHandler(progress);
            p.total(1);
            return this.resolveLocation(source.location).pipe(
                switchMap((context) => {
                    console.log(`DELETE ${context.bucket}/${this._makeKey(context, source)}`);
                    const request = context.endpoint.deleteObject({ Bucket:context.bucket, Key: this._makeKey(context, source) });
                    return request.promise();
                }),
                map(() => {
                    p.units(1);
                }),
                catchError((err) => {
                    return throwError(wrapS3Error(err, "delete", source));
                })
            );
        });
    }
    
    copy(progress: ProgressObserver, source: S3FileDescriptor, destination: S3FileDescriptor): Observable<unknown> {
        return combineLatest([ this.resolveLocation(source.location), this.resolveLocation(destination.location) ]).pipe(
            switchMap(async ([ sourceContext, destinationContext ]) => {
                this._removePresignedCacheFor(destinationContext, destination);

                // NOTE: S3 supports direct copy from any location with compatible credentials.
                // If the direct copy is not possible for some reason, this is where you would 
                // implement the fallback to download / upload
                // 
                // if(sourceContext.endpoint !== destinationContext.endpoint) {
                //     // copy by download / upload
                //     return this.upload(progress, destination, this.download(source));
                // } else {
                    // use direct S3 to S3 copy
                    const p = makeProgressHandler(progress);
                    p.total(1);

                    console.log(`COPY ${sourceContext.bucket}/${this._makeKey(sourceContext, source)} to ${destinationContext.bucket}/${this._makeKey(destinationContext, destination)}`);
                    const request = destinationContext.endpoint.copyObject({
                        Bucket:destinationContext.bucket,
                        CopySource:encodeURIComponent(`/${sourceContext.bucket}/${this._makeKey(sourceContext, source)}`),
                        Key: this._makeKey(destinationContext, destination),
                        ACL: destinationContext.acl,
                        MetadataDirective: 'REPLACE',
                    });

                    await request.promise();

                    p.units(1);
                // }
            }),
            catchError((err) => {
                return throwError(wrapS3Error(err, "copy", source, destination));
            })
        )
    }

    list(progress: ProgressObserver, location:S3PathDescriptor): Observable<FileDescriptor> {
        return this.resolveLocation(location).pipe(
            // list objects in the bucket
            switchMap((context) => {
                const listObjects$ = new Observable<S3.Object>(function(observer) {
                    const listRequest$ = context.endpoint.listObjectsV2({ Bucket:context.bucket, Prefix:`${context.keyPrefix}/`, MaxKeys:10000 });
                    listRequest$.eachPage((err, data) => {
                        if(!observer)
                            return false;

                        if(err) {
                            observer.error(err);
                            observer = null;
                        } else {
                            for(const o of data.Contents) {
                                observer.next(o);
                                if(!observer)
                                    return false;
                            }

                            if(observer && !data.IsTruncated) {
                                observer.complete();
                                observer = null;
                            }
                        }

                        return !!observer;
                    });

                    return function() {
                        if(observer) {
                            observer = null;
                            listRequest$.abort();
                        }
                    }
                });

                return listObjects$.pipe(
                    // map the s3 objects to FileDescriptors
                    map((s3Object) => {
                        // remove keyPrefix from the path
                        const path = s3Object.Key.substr(context.keyPrefix.length);
                        
                        const s3File = new S3FileDescriptor(location, path);
                        s3File.fileSize = s3Object.Size;

                        return s3File;
                    })
        
                );
            }),
            catchError((err) => {
                return throwError(wrapS3Error(err, "list", location));
            })
        )
    }
    
    url(file: S3FileDescriptor): Observable<string> {
        return this.resolveLocation(file.location).pipe(
            switchMap((context) => {
                // get or create the url map for this endpoint
                let urlsForEndpoint = this._presignedUrlCache.get(context.endpoint);
                if(!urlsForEndpoint) {
                    urlsForEndpoint = new Map();
                    this._presignedUrlCache.set(context.endpoint, urlsForEndpoint);
                }

                // see if this resource is already in cache
                const s3CacheKey = `${context.bucket}/${context.keyPrefix}/${file.path}`;
                let cacheEntry = urlsForEndpoint.get(s3CacheKey);
                if(cacheEntry && cacheEntry.expiration > new Date().getTime() ){
                    return cacheEntry.urlPromise;   // in cache and not expired 
                }
    
                // get the presigned url for this resource
                let urlPromise = new Promise<string>((resolve,reject) => {
                    context.endpoint.getSignedUrl('getObject', { Bucket:context.bucket, Key: this._makeKey(context, file), Expires:PRESIGNED_URL_EXPIRATION }, (err, url) => {
                        if(err)
                            reject(err);
                        else
                            resolve(url);
                    });
                });
                urlPromise = urlPromise.catch((err) => {
                    urlsForEndpoint.delete(s3CacheKey); // remove from cache on error
                    throw wrapS3Error(err, "url", file);
                });
    
                // keep in cache
                cacheEntry = {
                    urlPromise: urlPromise,
                    expiration: new Date().getTime()+(PRESIGNED_URL_EXPIRATION*1000)
                }
                urlsForEndpoint.set(s3CacheKey, cacheEntry);
    
                return cacheEntry.urlPromise;
            })
        );
    }

    metadata(file: S3FileDescriptor): Observable<FileMetadata> {
        return this.resolveLocation(file.location).pipe(
            switchMap((context) => {
                const headObjectPromise = new Promise<FileMetadata>((resolve,reject) => {
                    context.endpoint.headObject({ Bucket:context.bucket, Key: this._makeKey(context, file) }, (err, headObject) => {
                        if(err)
                            reject(err);
                        else
                            resolve( makeFileMetada(headObject));
                    });
                });
                return headObjectPromise;
            }),
            catchError((err) => {
                return throwError(wrapS3Error(err, "metadata", file));
            })
        );
    }
}

function makeFileMetada(s3HeadObject:S3.HeadObjectOutput): FileMetadata {
    return {
        contentLength: s3HeadObject.ContentLength,
        etag: s3HeadObject.ETag,
    };
}

/*---------------------------------------------------------------------------*/
export const S3FileDriver = new S3FileDriverImpl();

FileService.registerDriver('s3', S3FileDriver);


export function makeEndpoint({creds, region}: {creds?: S3Credentials, region?: string} = {}): S3 {

    // Should not happen but we prefer to be sure
    if (creds === null) {
        throw new IllegalArgumentError(`Unexpected credentials`, `creds`, `S3Credentials | undefined`, typeof creds);
    }
    if (region === null) {
        throw new IllegalArgumentError(`Unexpected region`, `region`, `string | undefined`, typeof region);
    }

    return new S3({
        accessKeyId: creds && creds.AccessKeyId,
        secretAccessKey: creds && creds.SecretAccessKey,
        sessionToken: creds && creds.SessionToken,
        region: region
    })
}

export interface S3PathDescription {
    bucket: string
    region: string
    path: string
    policy?: string
    signature?: string
}

export interface S3Credentials {
    AccessKeyId: string
    SecretAccessKey: string
    SessionToken?: string
}
export interface IndependentS3Description extends S3PathDescription{
    credentials: S3Credentials,
}

/**
 * File data of patch ids from which the dump path is formed.
 */
export interface DumpPatchSet {
    patchSet: string[],
}