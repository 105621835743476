import { inject, injectable } from "inversify";
import { 
    Locale_DIID,
    TranslationMap,
    TranslationMap_DIID 
} from "./translate.types";

@injectable()
export class LocalizationService {
    constructor(
        @inject(Locale_DIID.symbol)
        public readonly locale: Intl.Locale,

        @inject(TranslationMap_DIID.symbol)
        private readonly translationMap: TranslationMap,
    ) {}

    /**
     * Returns the value of the location based on the key
     * @param concatKey can contain a nested key
     * Ex : "Dashboard.meetings.title"
     * Which points to a translation that has the key : "Dashboard.meetings.title"
     * Or : "Dashboard": { "meetings": { "title": "Titre" } }
     */
    get(concatKey: string): string | TranslationMap {
        if (!this.translationMap || !concatKey) {
            return this._middlewareLocalization(concatKey, null);
        }
        if (concatKey in this.translationMap) {
            return this._middlewareLocalization(concatKey, this.translationMap[concatKey]);
        }
        if (concatKey.includes('.')) {
            const keys = concatKey.split('.');
            let rawTranslation: TranslationMap | string = this.translationMap;
            for (const key of keys) {
                if (typeof rawTranslation !== 'string' && !(key in rawTranslation)) {
                    return this._middlewareLocalization(concatKey, null);
                }
                if (typeof rawTranslation === 'string') {
                    return this._middlewareLocalization(concatKey, null);
                }
                rawTranslation = rawTranslation[key];
            }
            return this._middlewareLocalization(concatKey, rawTranslation);
        }
        return this._middlewareLocalization(concatKey, null);
    }

    /**
     * Return true is given key has been localize aka present in .json file
     */
    isLocalized(str: string): boolean {
        const localizedStrings = this.translationMap;
        if (typeof localizedStrings[str] === 'string') {
            return true;
        }
        return false;
    }

    /**
     *  Returns the localized string composed with the arguments
     * @param key can contain a nested key
     *  Ex : "Dashboard.meetings.title"
     */
    getFormatted(key: string, params: Record<string, string> = {}): string | TranslationMap {
        /**
         * Online regex
         * @link https://regex101.com/r/wNNbCP/2
         */
        const regex = `({{.*?}})`;
        const rawTranslation = this.get(key);
        return formatTranslationObject(rawTranslation);

        function formatTranslationObject(translation: TranslationMap | string): TranslationMap | string {
            if (typeof translation === "string") {
                return translation.replace(new RegExp(regex, "ig"), (match) => {
                    const key = match.substring(2, match.length - 2);
                    if (params[key] === undefined) {
                        return `[NOARG]${key}`;
                    }
                    return params[key];
                });
            }
            const formatObject: TranslationMap = {};
            for (const [translationKey, obj] of Object.entries(translation)) {
                formatObject[translationKey] = formatTranslationObject(obj);
            }
            return formatObject;
        }
    }

    /**
     * Interceptor called on every location request 
     */
    private _middlewareLocalization(key: string, loc: string | null | TranslationMap): string | TranslationMap {
        if (!loc) {
            // console.warn(`[Localizer] Missing translation for key: ${key}`);
            return `[NOLOC]${key}`;
        }
        return loc;
    }
}
