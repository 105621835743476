import { Type } from "class-transformer";
import {
    IsArray,
    IsInstance,
    IsNotEmpty,
    IsNumber,
    IsObject,
    IsOptional,
    IsString,
    NotEquals,
    ValidateNested,
} from "class-validator";

export interface ArchipadHttpClientConfiguration {
    webServiceBaseUrl: string;
    clientVersion: string;
    clientName: string;
    clientProtocol: string;
    preflightOptimization: boolean;
}

export class APIResponseError {
    @IsNumber()
    code!: number;

    @IsString()
    message!: string;

    @IsOptional()
    @IsString()
    localizedMessage?: string;

    @IsOptional()
    @IsString()
    field?: string;

    @IsOptional()
    @IsObject()
    params?: Record<string, unknown>;

    @IsOptional()
    @IsArray()
    errors?: ReadonlyArray<APIResponseError>;
}

abstract class APIResponse {
    @IsString()
    @IsNotEmpty()
    jsonrpc!: string;

    @NotEquals(undefined)
    id!: unknown | null;
}

export class APIResponseSuccess extends APIResponse {
    @NotEquals(undefined)
    result!: unknown;
}

export class APIResponseFail extends APIResponse {
    @ValidateNested()
    @IsInstance(APIResponseError)
    @Type(() => APIResponseError)
    error!: APIResponseError;
}
