import * as t from 'io-ts';
import { isLeft } from 'fp-ts/lib/Either';
import { getLocalizationService } from '@core/services/translate';

// Check if string is the representation of a number
export const NumericStringCodec = new t.Type<string, string, unknown>(
    'NumericString',
    t.string.is,
    (s: unknown, c) => {
        const argType: string = typeof s;
        if(argType !== 'string' && argType !== 'number') {
            return t.failure(s, c);
        }
        if(isNaN(parseInt(`${s}`, 10))) {
            return t.failure(s, c);
        }
        return t.success(s as string)
    },
    String
);

// Check if the string given is the representation of an EntityId
// - Only decimal numbers
// - don't start by a 0
export const EntityIdCodec = new t.Type<string, string, string>(
    'EntityIdString',
    t.string.is,
    (s: string, c) => {
        if (RegExp(/^[^0][0-9]*$/i).test(s)) {
            return t.success(s)
        }
        return t.failure(s, c);
    },
    String
);

// Check if given parameter is an array of type: ['qwe', true, 'qweqwe', false]
export const StringNameBoolValueArray = StringNameValueArray(t.boolean);

// Check if given parameter is an array of type: ['qwe', <T>, 'qweqwe', <T>]
export function StringNameValueArray<C extends t.Mixed>(valueCodec: C, name?: string) {
    if (name === undefined) { name = "StringNameValueArray<" + valueCodec.name + ">"; }

    const codec = new t.Type<Array<string | t.TypeOf<C>>, Array<string | t.TypeOf<C>>, unknown[]>(
        name,
        t.array(t.union([t.string, valueCodec])).is,
        (a: unknown[], c: t.Context) => {
            if(!Array.isArray(a)) {
              return t.failure(a, c);
            }
            if (a.length % 2 !== 0) {
                return t.failure(a, c);
            }
            for(let i=0; i<a.length; i+=2) {
                const name = a[i];
                const value = a[i+1];
                
                
                const nameRes = t.string.validate(name, t.appendContext(c, String(i), t.string, name));
                if (isLeft(nameRes)) {
                    return t.failures(nameRes.left);
                }

                const valueRes = valueCodec.validate(value, t.appendContext(c, String(i+1), valueCodec, value));
                if (isLeft(valueRes)) {
                    return t.failures(valueRes.left);
                }
            }

            return t.success(a);
        },
        Array
    );

    return t.UnknownArray.pipe(codec);
}

// Check if it correct dateFormat
export const DateFormatCodec = new t.Type<string, string, string>(
    'DateFormatString',
    t.string.is,
    (s: string, c) => {
        if(RegExp(/^(d|M){2}[/|\.](d|M){2}[/|\.](y{4}|y{1})$/).test(s)) {
            return t.success(s);
        }
        return t.failure(s, c);
    },
    String
);

// CHeck if it correct timeFormat
export const TimeFormatCodec = new t.Type<string, string, string>(
    'TimeFormatString',
    t.string.is,
    (s: string, c) => {
        const currentLocale: string = getLocalizationService().locale.language;
        if(currentLocale === 'en' && RegExp(/^h{1}[:]m{2} a$/).test(s)) {
            return t.success(s);
        } else if(RegExp(/^H{2}[:]m{2}$/).test(s)) {
            return t.success(s);
        }
        return t.failure(s, c);
    },
    String
);

// Check if given string have one of the following format : 
// - name:qweqqwe 
// - nameSystem:qweqwe
export const PhaseIdentifierCodec = new t.Type<string, string, string>(
    'PhaseIdentifierString',
    t.string.is,
    (s: string, c) => {
        if(s.startsWith('name:') || s.startsWith('nameSystem:')) {
            return t.success(s);
        }
        return t.failure(s, c);
    },
    String,
);
