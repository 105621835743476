import * as JSZip from 'jszip';
import { Observable, defer, from, of, combineLatest, throwError, BehaviorSubject } from 'rxjs';
import { switchMap, concatMap, map, mergeAll, reduce, take, finalize, tap, catchError } from 'rxjs/operators';

import * as IdGenerator from '@core/services/orm/idGenerator';
import * as Orm from '@core/services/orm/orm';

import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';

import { FormTypeEntity } from "@archipad-models/models/FormTypeEntity";
import { FormTypeVersionEntity } from '@archipad-models/models/FormTypeVersionEntity';
import { FormTypeViewEntity } from '@archipad-models/models/FormTypeViewEntity';
import { ProjectEntity } from '@archipad-models/models/ProjectEntity';

import * as Model from '@archipad/services/entities/forms/model';
import { updateFormTypeContainsConformityWithObservation } from '@archipad/services/entities/formObsMap';

import * as FormTemplate from '../baseFormTemplate';
import { FileService, Data } from '@core/services/file/file';
import { PatchesDescriptor, PatchDescriptor, PatchAttachmentDescriptor } from '@archipad/services/file/patchFile';
import { makeEndpoint, S3ContextPathDescriptor, S3FileDescriptor } from '@core/services/file/s3file';
import { JSONDecode, JSONEncode } from '@archipad-js/archipad/utils';
import { IllegalStateError, IllegalArgumentError } from '@core/errors/errors-core';
import { DigitformError, MissingFormResourceError } from '@archipad/errors/errors-archipad';
import { getAccountService } from '@archipad/services/account.service';



//-----------------------------------------------------------------------------
function _zipFormTypeView(progress:ProgressObserver, zip:JSZip, formTypeView:FormTypeViewEntity): Observable<void>[] {
    // collect the attachments to download
    const tasks: Observable<void>[] = [];
    const attachments = formTypeView.getAttachments();
    for(const attachmentKey in attachments) {
        const attachment = attachments[attachmentKey];

        const data = FileService.download(attachment);
        const task = data.asBlob(progress).pipe(
            map(function(data) {
                // add to zip
                const d = data as (Blob | Promise<Blob>);   // jslib type definitions require this 
                zip.file(attachmentKey, d, { binary:true });
            })
        );
        tasks.push(task);
    }

    return tasks;
}

/**
 * Put all the layouts in a form type version into a zip, automatically downloads each layout attachment.
 *
 * @param projectId current project id
 * @param formTypeVersion form type version
 * @param isSystem is this a system template ?
 * @param creator the current user account info
 * @return an observable that resolves to a zip blob
 */
function _zipFormTypeVersion(progress:ProgressObserver, projectId:string, formTypeVersion:FormTypeVersionEntity, formDescriptor:FormTemplate.FormDescriptor): Observable<Blob> {
    return defer(function() {
        const tasks: Observable<unknown>[] = [];

        const formData: FormTemplate.FormData = {
            views:[]
        };

        const zip = new JSZip();

        // write the views
        for(const formTypeView of formTypeVersion.views) {
            if(!formTypeView.templateId)
                formTypeView.templateId = IdGenerator.generateDeterministicTemplateId(projectId, formTypeView.id);

                formData.views.push({
                templateId:formTypeView.templateId,
                name:formTypeView.name,
                type:formTypeView.type,
                data:formTypeView.data ? JSONDecode(formTypeView.data) : null
            });
            tasks.push(... _zipFormTypeView(progress, zip.folder(formTypeView.templateId), formTypeView));
        }

        // write the descriptor
        zip.file('descriptor.json', JSONEncode(formDescriptor));

        // write the data.json
        zip.file('data.json', JSONEncode(formData));

        // execute fetch tasks then generate the zip
        return from(tasks).pipe(
            mergeAll(4),
            reduce(function(zip) { return zip; }, zip),
            concatMap(function(zip) { return zip.generateAsync({ type: 'blob' }); })
        );
    });
}

function _uploadZip(progress:ProgressObserver, resource, zipData:Blob) {
    const resourceDir = new S3ContextPathDescriptor({
        endpoint: makeEndpoint({creds: resource.credentials, region: resource.resource.region}),
        region: resource.resource.region,
        bucket: resource.resource.bucket,
        keyPrefix: resource.resource.path,
        acl: null
    });
    const file = new S3FileDescriptor(resourceDir, `${resource.resourceId}.zip`);
    const data = Data.fromBlob(zipData);
    return FileService.upload(progress, file, data);
}

//-----------------------------------------------------------------------------
function _uploadAttachment(progress:ProgressObserver, projectId:string, entity:Orm.BaseEntity, attachmentKey:string, data:Blob) {
    const p = makeProgressHandler(progress);
    p.total(1);

    const tempDir = new PatchesDescriptor(projectId, "temp");
    const tempPatch = new PatchDescriptor(tempDir, "current");
    const attachment = new PatchAttachmentDescriptor(tempPatch, entity.id, attachmentKey);
    return FileService.upload(p.task(1), attachment, Data.fromBlob(data)).pipe(
        tap(() => {
            // upload to temp patch complete, assign to entity
            entity.setAttachmentForKey(attachmentKey, attachment);
        })
    );
}

function _unzipFormTypeViewAttachments(progress:ProgressObserver, projectId:string, zip:JSZip, formTypeView:FormTypeViewEntity) {
    const p = makeProgressHandler(progress);
    
    const tasks: Observable<unknown>[] = [];
    zip.forEach((relativePath, file) => {
        // add the attachments
        const task = defer(function() { return file.async('blob'); }).pipe(
            switchMap(function(data) {
                return _uploadAttachment(p.task(1), projectId, formTypeView, relativePath, data);
            })
        );
        tasks.push(task);
    });

    p.total(tasks.length);
    return FileService.execute(tasks);
}

/**
 * Creates a form type version from a zip
 *
 * @param progress
 * @param formType form type in which to create the version
 * @param project current project entity
 * @param asACopy
 * @param formType form type on which to add the version
 * @param zipData a zip data blob
 * @return an observable that resolves to the new form type version
 */
function _unzipFormTypeVersion(progress:ProgressObserver, project:ProjectEntity, formType:FormTypeEntity, asACopy:boolean, zipData:Blob):Observable<{ name:string, entity:FormTypeVersionEntity }> {
    return defer(() => {
            // load the zip
            const zip = new JSZip();
            return zip.loadAsync(zipData, { createFolders:true });
        }).pipe(
            concatMap((zip) => {
                // extract the data and the descriptor
                const data = zip.file('data.json');
                if(!data)
                    return Promise.reject(new IllegalStateError('data.json is missing from zip'));
                const descriptor = zip.file('descriptor.json');
                if(!descriptor)
                    return Promise.reject(new IllegalStateError('descriptor.json is missing from zip'));
    
                return Promise.all([descriptor.async("text"), data.async("text")])
                    .then(function(ret) {
                        return {
                            zip:zip,
                            descriptor:JSONDecode(ret[0]) as FormTemplate.FormDescriptor,
                            data: ret[1] ? JSONDecode(ret[1]) as FormTemplate.FormData : null
                        };
                    });
            }),
            concatMap((ret) => {
                return FormTemplate.createFormTypeVersion(project, formType, ret.descriptor, asACopy, ret.data, function(templateId, formTypeView) {
                    return _unzipFormTypeViewAttachments(progress, project.id, ret.zip.folder(templateId), formTypeView);
                });
            })
        );
}

function _findResource(resources, resourceId:string, versionId:string) {
    for(const resource of resources.data) {
        for(const resourceData of resource.data) {
            if(resourceData.resourceId === resourceId && resourceData.versionId === versionId)
                return { resource:resource, resourceData:resourceData };
        }
    }

    return null;
}

function _downloadZip(progress:ProgressObserver, project:Orm.BaseEntity, resourceId:string, versionId:string): Observable<Blob> {
    return FormTemplate.listResourceTemplate(project.id).pipe(
        concatMap(function(resources) {
            const ret = _findResource(resources, resourceId, versionId);
            if(!ret)
                throw new IllegalArgumentError(`No such resource template: resourceId=${resourceId} versionId=${versionId}`);

            const resourceDir = new S3ContextPathDescriptor({
                endpoint: makeEndpoint({creds: ret.resource.credentials, region: ret.resource.region}),
                region: ret.resource.region,
                bucket: ret.resource.bucket,
                keyPrefix: ret.resourceData.baseURI,
                acl: null
            });
            const file = new S3FileDescriptor(resourceDir, ret.resourceData.path);
            
            const data = FileService.download(file);
            return data.asBlob(progress) as Observable<Blob>;
        })
    );
}

//-----------------------------------------------------------------------------
interface CacheEntry {
    formTemplates:BehaviorSubject<Map<string, FormTemplate.TemplateDescriptor>>;   
}

class CloudFormTemplateDriver implements FormTemplate.Driver {
    private _cache = new WeakMap<Orm.BaseEntity, CacheEntry>();
    private _cacheSub = new BehaviorSubject<Map<string, FormTemplate.TemplateDescriptor>>(null);

    updateTemplateCacheForProject(progress:ProgressObserver, project:Orm.BaseEntity): Observable<void> {
        return of(null);
    }

    getTemplatesForProject(progress: ProgressObserver, project: Orm.BaseEntity, language: string): Observable<FormTemplate.TemplateDescriptor[]> {     
        const cacheEntry = this._cache.get(project); 

        if(cacheEntry) {
            return this._cacheSub.pipe(
                map((formTemplates: Map<string, FormTemplate.TemplateDescriptor>) => {
                    const templates: FormTemplate.TemplateDescriptor[] = [];
                    if(formTemplates) {
                        formTemplates.forEach(formTemplate => {
                            templates.push(formTemplate);
                        });
                    }
                    return templates;
                })
            );
        }

        return FormTemplate.resourceList(project.id, 'form_definition', language).pipe(
            map((ret) => {
                const formTemplates = new Map<string, FormTemplate.TemplateDescriptor>();

                for(const resourceId in ret) {
                    const resource = ret[resourceId];
                    const activeVersion = resource.versions[resource.activeVersion];

                    formTemplates.set(resourceId, {
                        resourceId: resourceId,
                        versionId: resource.activeVersion,
                        isSystem: ret[resourceId].isSystem,
                        isDeleted: resource.isDeleted,
                        rights: ret[resourceId].rights,
                        metadata: JSONDecode(activeVersion.metadata) as FormTemplate.FormTemplate,
                        engineVersion: activeVersion.engineVersion,
                        engineMinVersion: activeVersion.engineMinVersion,
                    });
                }

                this._cacheSub.next(formTemplates);
                this._cache.set(project, { formTemplates: this._cacheSub });

                const templates = [];
                formTemplates.forEach(formTemplate => {
                    templates.push(formTemplate);
                });
                return templates;
            })
        );
    }

    importTemplate(progress:ProgressObserver, project: ProjectEntity, resourceId:string, asACopy:boolean): Observable<FormTypeEntity> {
        const p = makeProgressHandler(progress);
        p.total(100);
        p.label(l("Importing form definition ..."));

        const cacheEntry = this._cache.get(project); 
        const templateDescriptor = cacheEntry.formTemplates.getValue().get(resourceId);

        return FormTemplate.getOrCreateFormType(project, templateDescriptor.metadata, asACopy, (formType) => {
            return _downloadZip(p.task(60), project, resourceId, templateDescriptor.versionId).pipe(
                concatMap((zipData) => {
                    return _unzipFormTypeVersion(p.task(40), project, formType, asACopy, zipData)
                }),
                map((ret) => {
                    formType['name'] = ret.name;
                }),
                catchError((err) => {
                    const error = new MissingFormResourceError(templateDescriptor.metadata.name);
                    error.underlyingError = err;
                    return throwError(error);
                }),
            );
        });
    }

    publishTemplate(progress:ProgressObserver, project:Orm.BaseEntity, formType_:Orm.BaseEntity): Observable<void> {
        // TODO type interface
        const formType : FormTypeEntity = formType_ as FormTypeEntity;

        const p = makeProgressHandler(progress);
        p.total(100);
        p.label(l("Exporting form definition ..."));

        const formTypeVersion = Model.getLatestFormTypeVersion(formType);
        if(formTypeVersion === formType.activeVersion) {
            return throwError(new DigitformError('Cannot publish, latest version is already active'));
        }

        // get the templates (refreshes the cache if needed)
        const templates = this.getTemplatesForProject(progress, project, null);

        // get account info
        const accountInfo = getAccountService().currentAccount;

        return templates.pipe(
            map(( templateDescriptors ) => {
                // get existing template
                const existingTemplate = FormTemplate.findPersonalTemplateDescriptor(templateDescriptors, formType);
                const resourceType = existingTemplate ? null : 'form_definition';
                const resourceId = existingTemplate ? existingTemplate.resourceId : null;
                const isSystem = false;
    
                const formDescriptor = FormTemplate.makeFormDescriptor(project.id, formTypeVersion, isSystem, accountInfo);
    
                // zip the form type version
                return _zipFormTypeVersion(p.task(40), project.id, formTypeVersion, formDescriptor).pipe(
                    concatMap((zipData:Blob) => {
                        // prepare the resource
                        return FormTemplate.resourcePrepare(resourceId, resourceType, formType.name, isSystem, JSONEncode(formDescriptor.description), formDescriptor.engineVersion, formDescriptor.engineMinVersion).pipe(
                            map(function(resource) { return { zipData:zipData, resource:resource } })
                        )
                    })
                );
            }),
            take(1),
            mergeAll(1),
            concatMap((ret) => {
                const resource = ret.resource;
                const zipData:Blob = ret.zipData;

                return _uploadZip(p.task(60), resource, zipData).pipe(
                    map(function() {
                        return resource;
                    })
                );
            }),
            concatMap((resource) => {
                return FormTemplate.resourcePublish(resource.resourceId, resource.versionId);
            }),
            map(() => {
                // activate the lastest version and synchronize project
                formType.activeVersion = formTypeVersion;
                updateFormTypeContainsConformityWithObservation(formType);
            }),
            finalize(() => {
                // finally clear cache even if an error have been thrown.
                this.clearCache();
            })
        );


        // .map(function(zipData:Blob) {
        //     let reader = new FileReader();
        //     reader.readAsDataURL(zipData);
        //     reader.onloadend = function() {
        //         return SaveAs.saveLocalFile(reader.result, 'form', 'application/zip');
        //     };
        // })
    }

    clearCache() {
        this._cache = new WeakMap<Orm.BaseEntity, CacheEntry>();
    }
}

export function deleteTemplate(templateId:string): Observable<void> {
    return FormTemplate.resourceDelete(templateId).pipe(
        map(() => {
            driver.clearCache();
        })
    );   
}

export const driver = new CloudFormTemplateDriver();
