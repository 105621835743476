import * as queryService from '@core/services/orm/query';
import { JSONDecode } from '@archipad-js/archipad/utils';
import {
    generateDeterministicId,
    generateDeterministicTemplateIdFromTemplateIdAndEntityId,
    generateDeterministicTemplateIdFromTemplateIdAndTemplateId,
} from "@core/services/orm/idGenerator";

import { formatTextList } from "@core/helpers/textHelper";

import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { MapEntity } from "@archipad-models/models/MapEntity";
import { MAP_FILE_TYPES, MAP_TYPES } from "@archipad-models/models/extensions/MapEntity-ext";
import { RegionEntity } from "@archipad-models/models/RegionEntity";
import { FormTypeEntity } from '@archipad-models/models/FormTypeEntity';
import { FormTypeVersionEntity } from '@archipad-models/models/FormTypeVersionEntity';
import { VisitEntity } from '@archipad-models/models/VisitEntity';

import {
    FormView,
    walkElementChildren,
    WalkResult
} from "@archipad/services/entities/forms/model";

const generalityFormsObsMapTemplateId = "2B99B9FC3DB6483EBD307784923244BD";
const regionFormsObsMapTemplateId = "ED41FEC331234EEBB18BB30B664A5F38";

export function enforceFormBugsIntegrityOn(visit: VisitEntity): void {
    // find the formObs map for this visit
    const allMaps = visit.project.maps;
    const formObsMap = allMaps.find((map) => map.region === visit.region && map.type === MAP_TYPES.FORMS);

    // for each form added by that visit
    for(const form of visit.forms) {
        // for each bug added by this form
        for(const bug of form.bugs) {
            // assign formObs map
            bug.map = formObsMap;
        }
    }
}

export function updateFormObsMapFilename(map: MapEntity): void {
    const name = map.region ? map.region.name : l('Generalities');
    map.filename = formatTextList(['FormObs', name], ' - ');
}

export function updateFormsObsMap(project: ProjectEntity, region: RegionEntity): MapEntity {
    const entityContext = project.getContext();

    let formsObsMapTemplateId: string;
    let formsObsMapId: string;
    const query = queryService.query<MapEntity>(entityContext, "Map");
    let predicateParams;
    if (region) {
        if (region.templateId) {
            formsObsMapTemplateId = generateDeterministicTemplateIdFromTemplateIdAndTemplateId(regionFormsObsMapTemplateId, region.templateId);
        } else {
            formsObsMapTemplateId = generateDeterministicTemplateIdFromTemplateIdAndEntityId(regionFormsObsMapTemplateId, region.regionId);
        }
        formsObsMapId = generateDeterministicId(project.projectId, formsObsMapTemplateId, "uuid");
        query.predicateWithFormat("(region={region} AND type={type}) OR id={mapId}");
        predicateParams = { 'region':region, 'type':MAP_TYPES.FORMS, 'mapId':formsObsMapId };
    } else {
        formsObsMapTemplateId = generalityFormsObsMapTemplateId;
        formsObsMapId = generateDeterministicId(project.projectId, formsObsMapTemplateId, "uuid");
        query.predicateWithFormat("templateId={templateId} OR id={mapId}");
        predicateParams = { 'templateId':formsObsMapTemplateId, 'mapId':formsObsMapId };
    }

    const resultSet = query.execute(predicateParams);
    let formsObsMap : MapEntity = resultSet.firstEntity();

    if (!formsObsMap) {
        formsObsMap = entityContext.createEntity("Map", formsObsMapId) as MapEntity;
        formsObsMap.templateId = formsObsMapTemplateId;
        formsObsMap.type = MAP_TYPES.FORMS;
        formsObsMap.fileType = MAP_FILE_TYPES.PDF;
        formsObsMap.region = region;
        formsObsMap.project = project;
    }

    if (formsObsMap) {
        // WORKAROUND : the region may have been resurrected (created then deleted then re-created with the same id)
        formsObsMap.region = region;

        // The name must be updated even if the map exists in case the region was renamed
        updateFormObsMapFilename(formsObsMap);
    }

    return formsObsMap;
}

export function addFormObsMap(project: ProjectEntity) : void {
    updateFormsObsMap(project, null);
    
    for (const region of project.regions) {
        updateFormsObsMap(project, region);
    }
}

export function hasConformityWithObservation(formTypeVersion: FormTypeVersionEntity | null): boolean {
    if (formTypeVersion === null) {
        return false;
    }

    const formTypeViewScreenEntity = formTypeVersion.views.find(formView => formView.type === 'SCREEN');
    if (formTypeViewScreenEntity.data == null){
        return false;
    }

    const fields = JSONDecode(formTypeViewScreenEntity.data) as FormView;
    const conformityWithObs = walkElementChildren(fields, (child: FormView.Element): WalkResult => {
        if (child.type === "conformity_observation") {
            return WalkResult.FOUND;
        }
        return WalkResult.CONTINUE;
    });

    return conformityWithObs !== null;
}

export function updateFormTypeContainsConformityWithObservation(formType: FormTypeEntity) : void {
    formType.containsConformityWithObservation = hasConformityWithObservation(formType.activeVersion);
}

export function updateFormsContainsConformityWithObservation(project: ProjectEntity) : void {
    for (const formType of project.formTypes) {
        updateFormTypeContainsConformityWithObservation(formType);
    }
}
