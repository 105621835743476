import { vec2 } from "gl-matrix";

/**
 * Indicates that the given type can contain additional properties.
 */
export type WithAdditionalProperties<T> = T & Record<string, unknown>;

//=================================================================================================
// 2D types
//=================================================================================================

export type Point = {
    x: number;
    y: number;
};

export type Size = {
    width: number;
    height: number;
};

export type Rect = {
    origin: Point;
    size: Size;
};

//=================================================================================================
// 2D types constants
//=================================================================================================

export function PointZero(): Point {
    return { x: 0, y: 0 };
}

export function SizeZero(): Size {
    return { width: 0, height: 0 };
}

export function RectZero(): Rect {
    return { origin: { x: 0, y: 0 }, size: { width: 0, height: 0 } };
}

export function RectIsEmpty(rect: Rect): boolean {
    return rect.size.width === 0 && rect.size.height === 0;
}

export function RectFromString(str: string): Rect {
    const regex =
        /{{(([0-9]+)|([0-9]*\.[0-9]+)),(([0-9]+)|([0-9]*\.[0-9]+))},{(([0-9]+)|([0-9]*\.[0-9]+)),(([0-9]+)|([0-9]*\.[0-9]+))}}/;
    const matches = str.replace(/ /g, "").match(regex);
    return matches
        ? {
              origin: {
                  x: parseFloat(matches[1]),
                  y: parseFloat(matches[4]),
              },
              size: {
                  width: parseFloat(matches[7]),
                  height: parseFloat(matches[10]),
              },
          }
        : RectZero();
}

//=================================================================================================
// 2D types convert
//=================================================================================================

/**
 * Convert a tuple to a point
 * @param tuple [x, y]
 */
export function pointFromTuple(tuple: [number, number]): Point {
    return {
        x: tuple[0],
        y: tuple[1],
    };
}

/**
 * Convert a point to a tuple
 * @returns tuple [x, y]
 */
export function tupleFromPoint(model: Point): [number, number] {
    return [model.x, model.y];
}

/**
 * Convert a vector to a point
 */
export function pointFromVector(vector: vec2): Point {
    return {
        x: vector[0],
        y: vector[1],
    };
}

/**
 * Convert a point to a vector
 */
export function vectorFromPoint(model: Point): vec2 {
    return vec2.fromValues(model.x, model.y);
}

/**
 * Convert a tuple to a size
 * @param tuple [w, h]
 */
export function sizeFromTuple(tuple: [number, number]): Size {
    return {
        width: tuple[0],
        height: tuple[1],
    };
}

/**
 * Convert a size to a tuple
 * @returns tuple [w, h]
 */
export function tupleFromSize(model: Size): [number, number] {
    return [model.width, model.height];
}

/**
 * Convert a tuple to a rect
 * @param tuple [p1.x, p1.y, p2.x, p2.y] where p1 is (left,top) and p2 is (right,bottom)
 */
export function rectFromTuple(tuple: [number, number, number, number]): Rect {
    const p1_x = tuple[0];
    const p1_y = tuple[1];
    const p2_x = tuple[2];
    const p2_y = tuple[3];
    return {
        origin: {
            x: p1_x,
            y: p1_y,
        },
        size: {
            width: p2_x - p1_x,
            height: p2_y - p1_y,
        },
    };
}

/**
 * Convert a rect to a tuple
 * @returns tuple [p1.x, p1.y, p2.x, p2.y] where p1 is (left,top) and p2 is (right,bottom)
 */
export function tupleFromRect(model: Rect): [number, number, number, number] {
    return [model.origin.x, model.origin.y, model.size.width + model.origin.x, model.size.height + model.origin.y];
}

export enum BackgroundOrientation {
    Up = 0,
    Right = 90,
    Left = 180,
    Down = 270,
}

export function normalizeRect(rect: Rect): Rect {
    const minX = rect.origin.x;
    const minY = rect.origin.y;
    const maxX = rect.origin.x + rect.size.width;
    const maxY = rect.origin.y + rect.size.height;

    const normalizedRect: Rect = {
        origin: {
            x: Math.min(minX, maxX),
            y: Math.min(minY, maxY),
        },
        size: {
            width: Math.abs(maxX - minX),
            height: Math.abs(maxY - minY),
        },
    };

    return normalizedRect;
}
