/**
* ReportEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";
import { VisitEntity } from "./VisitEntity";


declare global {
    interface EntityTypeMap {
        "Report": ReportEntity
    }
}

export abstract class ReportEntity extends BaseEntity {

	 abstract reportId: string;
	 abstract temp: boolean;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract filename?: string;
	 abstract date?: Date;
	 abstract visitName?: string;
	 abstract visitDate?: Date;
	 abstract prefix?: string;
	 abstract provider?: string;
	 abstract permaLink?: string;
	 abstract isShared?: number;
	 abstract isSigned?: number;
	 abstract mapsIncluded?: number;
	 abstract reportTemplate?: string;
	 abstract isDemo?: number;
	 abstract password?: string;
	 abstract generationStatus?: number;
	 abstract generationErrorCode?: string;
	 abstract creator?: ProjectUserEntity;
	 abstract visit?: VisitEntity;
	
}
