import { Observable, from, of, defer, throwError } from 'rxjs';
import { mergeAll, reduce, map, catchError } from 'rxjs/operators';

import { env as ConfigEnv } from '@core/services/config';
import * as IdGenerator from '@core/services/orm/idGenerator';
import * as Orm from '@core/services/orm/orm';

import { ProgressObserver } from '@core/tasks/progress';

import { FormTypeEntity } from "@archipad-models/models/FormTypeEntity";
import { FormTypeVersionEntity } from "@archipad-models/models/FormTypeVersionEntity";
import { FormTypeViewEntity } from "@archipad-models/models/FormTypeViewEntity";
import { ProjectEntity } from '@archipad-models/models/ProjectEntity';

import * as Rpc from '@archipad/backend/rpc/authenticated';
import { updateFormTypeContainsConformityWithObservation, hasConformityWithObservation } from '@archipad/services/entities/formObsMap';
import { convertIdToHex32 } from '@core/helpers/entityId';
import { JSONEncode } from '@archipad-js/archipad/utils';
import { IllegalArgumentError } from '@core/errors/errors-core';
import { AccountInfo } from '@archipad/services/account.service.types';

const env = ConfigEnv();

export const enum ResourceType {
    FORM_DEFINITION = 'form_definition'
}

/** 
 * Contents of descriptor.json
 */
export interface BaseResourceDescriptor {
  /** the type of resource */
  type: ResourceType;

  /** which client produced the resource */
  client: string;

  /** which client version produced the resource */
  clientVersion: string;

  /** resource creator */
  user: {
    id: number;
    company_id: number;
  };

  /** is this a system resource */
  isSystem: boolean;

  /** the description of the resource */
  description: unknown;

  /** the version of the engine that published the resource */
  engineVersion: number;

  /** the minimal required version of the engine to deal with the resource */
  engineMinVersion: number;
}

export interface FormDescriptor extends BaseResourceDescriptor {
    type: ResourceType.FORM_DEFINITION;
    description: FormTemplate;
}

export type ResourceDescriptor = FormDescriptor /** | ReportDescriptor */;

/**
 * The description of a form_definition resource.
 */
export interface FormTemplate {
  /** FormType templateId */
  templateId: string;

  /** FormTypeVersion templateId */
  versionId: string;

  /** FormType name */
  name : string;

  /** FormTypeVersion date */
  date : number;

  /** does this version contain at least one conformity_with_observation field */
  containsConformityWithObservation: boolean;
}

/**
 * The template descriptor used on the client-side.
 */
export interface TemplateDescriptor {
  /** resource Id */
  resourceId: string;

  /** resource version Id */
  versionId: string;

  /** is this a system template ? */
  isSystem: boolean;

  /** has been deleted by the user ? */
  isDeleted: boolean;

  /** is this a system template ? */
  rights: {
    read: boolean;
    write: boolean;
  };

  /** opaque metadata (resource specific) */
  metadata: FormTemplate/* | ReportTemplate*/;

  /** the version of the engine that published the resource */
  engineVersion: number;

  /** the minimal required version of the engine to deal with the resource */
  engineMinVersion: number;
}

/**
 * Contents of data.json
 */
export interface FormData {
    views: {
        /** FormTypeView templateId */
        templateId: string;
        /** 'Saisie', 'Rapport' */
        name: string;
        /** SCREEN, PRINT */
        type: string;
        /** json representation of the form layout */
        data: any;
    }[];
}

//-----------------------------------------------------------------------------

/**
 * Set attachment callback
 * 
 * @param templateId form type view templateId, path prefix for all the entity attachments
 * @param formTypeView form type view on which to set the attachments
 * @return an array of tasks
 */
export type SetAttachmentFn = (templateId:string, formTypeView:FormTypeViewEntity)=> Observable<unknown>

/**
 * Create a form type version from a template manifest
 * 
 * @param project current project entity
 * @param formType form type on which to add the new version
 * @param asACopy
 * @param descriptor template descriptor
 * @param data template data
 * @param fn set attachment callback
 * @return an observable that resolves to then new form type version when all tasks are complete
 */
export function createFormTypeVersion(project: Orm.BaseEntity, formType: FormTypeEntity, descriptor: FormDescriptor, asACopy: boolean, data: FormData, fn: SetAttachmentFn): Observable<{ name: string, entity: FormTypeVersionEntity }> {

    return defer(function () {

        const entityContext = project.getContext();
        const projectId = project.id;

        const formTypeVersionId = asACopy ? null : IdGenerator.generateDeterministicId(projectId, descriptor.description.versionId, "uuid");
        const formTypeVersion = entityContext.createEntity('FormTypeVersion', formTypeVersionId) as FormTypeVersionEntity;
        formTypeVersion.templateId = asACopy ? null : descriptor.description.versionId;
        formTypeVersion.date = new Date(descriptor.description.date);
        formTypeVersion.formType = formType;

        formType.name = descriptor.description.name;
        formType.activeVersion = asACopy ? null : formTypeVersion; // activate version unless it is a copy

        const tasks:Observable<unknown>[] = [];
        for(const view of data.views) {
            // load the form type views
            const formTypeViewId = asACopy ? null : IdGenerator.generateDeterministicId(projectId, view.templateId, "uuid");
            const formTypeView = entityContext.createEntity('FormTypeView', formTypeViewId) as FormTypeViewEntity;
            formTypeView.templateId = asACopy ? null : view.templateId;
            formTypeView.name = view.name;
            formTypeView.type = view.type;
            formTypeView.data = JSONEncode(view.data);
            formTypeView.version = formTypeVersion;

            if(descriptor.engineVersion){
                formTypeView.engineVersion = descriptor.engineVersion;
            }
            if(descriptor.engineMinVersion){
                formTypeView.engineMinVersion = descriptor.engineMinVersion;
            }

            // set the attachments
            tasks.push(fn(view.templateId, formTypeView));
        }

        updateFormTypeContainsConformityWithObservation(formType);
        if(!tasks.length)
            tasks.push(of(null));

        return from(tasks).pipe( // execute the attachment tasks
            mergeAll(4),   // 4 simultaneously
            reduce(function() {}),
            map(function() {                                   // then return the form type version
                return { name:descriptor.description.name, entity:formTypeVersion };
            })
        );
    });
}

export function makeFormDescriptor(projectId:string, formTypeVersion:FormTypeVersionEntity, isSystem: boolean, creator:AccountInfo): FormDescriptor {
    const formType = formTypeVersion.formType;

    if(!formType.templateId)
        formType.templateId = IdGenerator.generateDeterministicTemplateId(projectId, formType.id)
    if(!formTypeVersion.templateId)
        formTypeVersion.templateId = IdGenerator.generateDeterministicTemplateId(projectId, formTypeVersion.id);

    const formTypeViewScreenEntity = formTypeVersion.views.filter(formView => formView.type === 'SCREEN')[0];

    const containsConformityWithObservation = hasConformityWithObservation(formTypeVersion);

    return {
        type: ResourceType.FORM_DEFINITION,
        client: env.clientName,
        clientVersion: env.appVersion,
        user: {
            id: creator.id,
            company_id: creator.company.id
        },
        isSystem: false,
        description: {
            templateId: formType.templateId,
            versionId: formTypeVersion.templateId,
            name: formType.name,
            date: formTypeVersion.date.getTime(),
            containsConformityWithObservation
        },
        engineVersion: formTypeViewScreenEntity.engineVersion,
        engineMinVersion: formTypeViewScreenEntity.engineMinVersion
    };
}

/**
 * Find the personal template descriptor that matches the formType if there is one
 * 
 * @param templateDescriptors template descriptor array
 * @param formType form type entity
 * @return the template descriptor or null
 */
export function findPersonalTemplateDescriptor(templateDescriptors:TemplateDescriptor[], formType:Orm.BaseEntity): TemplateDescriptor {
    return templateDescriptors.find((templateDescriptor) => {
        if (templateDescriptor.isSystem) {
            return false;
        }
        const formDescriptor = templateDescriptor.metadata as FormTemplate;
        return formDescriptor.templateId === formType['templateId'];
    });
}

/**
 * Import a form type version from a template into a form type
 * 
 * @param formType form type on which to create the form type version.
 * @return an observable that resolves when the form type version is imported.
 */
export type ImportFormVersionFn = (formType:FormTypeEntity)=>Observable<void>

/**
 * Get a FormType from a template descriptor. Import, update or get it if needed
 * 
 * @param project current project entity
 * @param templateDescriptor 
 */
export function getOrCreateFormType(project:ProjectEntity, formDescriptor:FormTemplate, asACopy:boolean, fn:ImportFormVersionFn): Observable<FormTypeEntity> {
    const entityContext = project.getContext();
    const projectId = project.id;

    let formType = null;
    let formTypeVersion = null;
    let newFormType: FormTypeEntity = null;

    if(asACopy) {
        // create the form type
        newFormType = entityContext.createEntity('FormType') as FormTypeEntity;
        newFormType.project = project;
        newFormType.hidden = false;  // show imported copies by default
        formType = newFormType;
    } else {
        const templateId = formDescriptor.templateId;
        const entityId = IdGenerator.generateDeterministicId(projectId, templateId, 'uuid');

        // try find the form type
        formType = entityContext.getEntity("FormType", entityId) as FormTypeEntity;
        if(formType) {
            formType.templateId = templateId; // should already have this value but better be safe
            const versionEntityId = IdGenerator.generateDeterministicId(projectId, formDescriptor.versionId, 'uuid');

            // try find the form type version
            formTypeVersion = entityContext.getEntity("FormTypeVersion", versionEntityId);
            if(formTypeVersion)
                formTypeVersion.templateId = formDescriptor.versionId;  // should already have this value but better be safe
        } else {
            // create the form type
            newFormType = entityContext.createEntity('FormType', entityId) as FormTypeEntity;
            newFormType.templateId = templateId;
            newFormType.project = project;
            newFormType.hidden = true;  // hide imported form types by default
            formType = newFormType;
        }
        
        if(formTypeVersion)
            return of(formType);    // all good, the FormType and the FormTypeVersion are up to date
    }

    return fn(formType).pipe(
        catchError(function(err) {
            // cleanup 
            if(newFormType)
                entityContext.deleteEntity(newFormType);
            return throwError(err);
        }),
        map(function() {
            return formType;
        })
    );
}

/*---------------------------------------------------------------------------*/
type ResourceId = string;

interface ResourceListResponse {
    [index: /** ResourceId */ string]: Resource;
};

type Resource = FormResource;

interface FormResource extends BaseResource {
    type: ResourceType.FORM_DEFINITION;
}

export interface BaseResource {
    type: ResourceType
    isSystem: boolean;
    isDeleted: boolean;
    lang: string;
    rights: {
        read: boolean;
        write: boolean;
    };
    activeVersion: ResourceId;
    versions: ResourceVersionList;
}

interface ResourceVersionList {
    [index: /** ResourceId */ string]: ResourceVersion;
}

interface ResourceVersion {
    date: number;
    label: string;
    metadata: string;
    engineVersion: number;
    engineMinVersion: number;
}

export function resourcePrepare(
    resourceId: string,
    resourceType: string,
    label: string,
    isSystem: boolean,
    metadata: string,
    engineVersion: number,
    engineMinVersion: number
): Observable<any> {
    const params = {
        label: label,
        isSystem: isSystem,
        metadata: metadata,
        engineVersion: engineVersion,
        engineMinVersion: engineMinVersion
    };
    if (resourceId)
        params['resourceId'] = resourceId;
    if (resourceType)
        params['resourceType'] = resourceType;

    return defer(function () {
        if (!!resourceId === !!resourceType) {
            throw new IllegalArgumentError('should either set resourceId or resourceType');
        }
        return Rpc.makeAuthenticatedRequest(null, 'resource', 'prepare', params);
    });
}

export function resourcePublish(resourceId:string, versionId:string) {
    const params = {
        resourceId: resourceId,
        versionId: versionId
    };
    
    return defer(function() {
        return Rpc.makeAuthenticatedRequest(null, 'resource', 'publish', params);
    });
}

export function resourceDelete(resourceId:string) {
    const params = {
        resourceId: resourceId
    };
    
    return defer(function() {
        return Rpc.makeAuthenticatedRequest(null, 'resource', 'delete', params);
    });
}

export function resourceList(projectId:string, resourceType:string, lang?:string): Observable<ResourceListResponse> {
    const params = {};
    if(projectId) {
        const hexProjectId = convertIdToHex32(projectId); 
        params['projectId'] = hexProjectId;
    }
    if(resourceType)
        params['resourceType'] = resourceType;
    if(lang)
        params['lang'] = lang;

    return defer(function() {
        return Rpc.makeAuthenticatedRequest(null, 'resource', 'list', params);
    });
}

export function listResourceTemplate(projectId: string): Observable<any> {
    const params = {
        type: "resource",
        projectId: projectId
    }

    return defer(function() {
        return Rpc.makeAuthenticatedRequest(null, "storage", "listResourceTemplate", params);
    });
}

//-----------------------------------------------------------------------------
export interface Driver {
    /**
     *  Update the template list for a project, usually on project open.
     */
    updateTemplateCacheForProject(progress:ProgressObserver, project:ProjectEntity): Observable<void>;

    /**
     * Get the available templates for the specified project.
     *
     * @param progress  The progress observer
     * @param project   The project entity
     * @param language  The language of the templates to get, get all templates of all languages if null.
     */
    getTemplatesForProject(progress: ProgressObserver, project: ProjectEntity, language?: string): Observable<TemplateDescriptor[]>;

    /**
     *  Import a form template into a project
     */
    importTemplate(progress:ProgressObserver, project:ProjectEntity, resourceId:string, asACopy:boolean): Observable<FormTypeEntity>;
    
    /**
     *  Publish a new template or update an existing template from a form type.
     */
    publishTemplate(progress:ProgressObserver, project:ProjectEntity, formType:FormTypeEntity): Observable<void>;
}
