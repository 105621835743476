import { Observable, from, of } from 'rxjs';
import { mergeAll, takeLast, reduce } from 'rxjs/operators';

import { ProgressObserver } from '@core/tasks/progress';

import { createLogger } from '@core/services/logger.service';
import { NotImplementedError } from '@core/errors/errors-core';

const log = createLogger('file');

/*---------------------------------------------------------------------------*/

/**
 * Represents the contents of a file 
 */
export abstract class Data {
    constructor(mimeType:string) {
        this.mimeType = mimeType;
    }

    /** create a Data object from a string */
    static fromString(str: string, mimeType:string = "text/plain", encoding:string = "utf-8"): Data { return new StringDataImpl(str, mimeType, encoding); }

    /** data mimetype */
    readonly mimeType:string;

	/** Convert the data contents as string */
    abstract asString(progress:ProgressObserver | null, encoding?:string): Observable<string>;
}

export abstract class StringData extends Data {
    constructor(mimeType:string, encoding:string) {
        super(mimeType);
        this.encoding = encoding;
    }

    /** prefered encoding if this data must be interpreted as binary */
    readonly encoding:string;

    abstract asString(progress:ProgressObserver | null, encoding?:string): Observable<string>;
}

class StringDataImpl extends StringData {
    private _str:string;
    
    constructor(str: string, mimeType:string, encoding:string) {
        super(mimeType, encoding);
        this._str = str;
    }

    asString(progress:ProgressObserver | null): Observable<string> {
        return of(this._str);
    }
}


/*---------------------------------------------------------------------------*/

/** file type registry, augmented by each FileDescriptor class */
declare global {
    // tslint:disable-next-line: no-empty-interface
    interface FileTypeMap {
    }
}

export type FileType = keyof FileTypeMap;

/*---------------------------------------------------------------------------*/

export function isTextMimeType(mimeType:string):boolean {
    if (mimeType.startsWith("text/"))
        return true;
    switch(mimeType) {
        case 'application/json': return true;
        default: return false;
    }
}

const mimeTypesByExtension: ReadonlyMap<string, string> = new Map([
    ['pdf', 'application/pdf'],
    ['xls', 'application/vnd.ms-excel'],
    ['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    ['zip', 'application/zip'],
    ['json', 'application/json'],
    ['gif', 'image/gif'],
    ['jpeg', 'image/jpeg'],
    ['jpg', 'image/jpeg'],
    ['png', 'image/png'],
    ['dwf', 'application/dwf'],
    ['css', 'text/css'],
    ['ds_store', 'application/octet-stream'],
]);

export function extensionToMimeType(extension: string): string {
    const mimeType = mimeTypesByExtension.get(extension.toLowerCase());
    if (!mimeType) {
        log.warn(`Cannot find mimetype for "${extension}", assuming "application/octet-stream"`);
        return "application/octet-stream";
    }
    return mimeType;
}

function mimeTypeToExtension(mimeType: string): string | undefined {
    const [extension] = Array.from(mimeTypesByExtension.entries()).find(([ext, mt]) => mt === mimeType);
    return extension ?? undefined;
}

/**
 * @see https://regex101.com/r/XE3FyB/1
 */
const extensionRegex = /\.([^.]+)$/;

export function ensureHasExtension(filename: string, mimeType: string): string {
    const alreadyHasExtenstion = extensionRegex.test(filename);
    if (alreadyHasExtenstion) {
        return filename;
    }

    const extension = mimeTypeToExtension(mimeType);
    if (!extension) {
        return filename;
    }

    return `${filename}.${extension}`;
}

export function extensionForPath(path:string) {
    let pos = path.lastIndexOf('/');
    if(pos !== -1)
        path = path.substr(pos+1);
    pos = path.lastIndexOf('.');
    if(pos === -1)
        return null;

    let ext = path.substr(pos+1);
    pos = ext.indexOf('?');
    if(pos !== -1)
        ext = ext.substr(0,pos);
    return ext;
}

export function mimeTypeForPath(path:string) {
    const ext = extensionForPath(path);
    if(!ext)
        return null;
    return extensionToMimeType(ext);
}

/**
 * Concatenate the FileDescriptors sequence and wait to completes before continuing
 * @param listObservable is return by the List function as a sequence of FileDescriptors then completes
 */
export function listAsArray<T extends FileDescriptor> (listObservable: ReturnType<ReadFileDriver<T>[ 'list' ]> ): Observable<T[]> {
    const concatenatedFileDescriptors: T[] = [];
    return listObservable?.pipe(
        reduce( ( concatenatedFileDescriptors, file: T ) => {
            concatenatedFileDescriptors.push( file );
            return concatenatedFileDescriptors;
        }, concatenatedFileDescriptors ),
    );
}

/*---------------------------------------------------------------------------*/

/**
 * Path descriptor base class
 */
export abstract class PathDescriptor {
    /** Normalize a path string: "/foo/bar/" -> "foo/bar" */
    static normalizePath(str:string|null):string|null {
        if(!str)
            return null;
        if(str.startsWith('/'))
            str = str.substring(1);
        if(str.endsWith('/'))
            str = str.substring(0, str.length-1);
        return str;
    }

    /** type of driver for this path */
    abstract get type(): FileType;
}

/**
 * File descriptor base class
 */
export abstract class FileDescriptor {
    /** type of driver for this file */
    abstract get type(): FileType;
    
    fileSize?: number;
    mimeType?: string;
    /** 
     * hash of the file contents, the hash function (now sha1) must be the same 
     * for all project attachments.
     */
    hash?: string;
}
/**
 * Metadata interface
 */
export interface FileMetadata {
    contentLength: number;
    etag: string;
}

/*---------------------------------------------------------------------------*/

/**
 * FileDriver interfaces
 * 
 * Note that you don't need to implement each operation on every driver. 
 * 
 * For example if you don't implement copy(), the FileService will default to:
 * ```ts
 * function copy(progress: ProgressObserver, source: FileDescriptor, destination: FileDescriptor): Observable<unknown> {
 *     const p = makeProgressHandler(progress);
 *     p.total(100)
 *     return this.upload(p.task(50), dest, this.download(p.task(50), source));
 * }
 * ```
 * 
 * If an operation is not available and cannot be reimplemented using other operations (like `list()`), the operation will
 * throw FileOperationNotImplementedError.
 */

/**
 * A readonly file driver
 */
export interface ReadFileDriver<T extends FileDescriptor> {
    /** download a file - note that the operation is synchronous since all Data accessors are asynchronous */
    download(file:T): Data;
    
    /**
     * list the files in a path
     * 
     * Note: the returned observable sends a sequence of FileDescriptors then completes
     */
    list?(progress:ProgressObserver|null, path:PathDescriptor): Observable<FileDescriptor>

    /** 
     * get a presigned url to the file content - can be used in <img src="">, XMLHttpRequest ... 
     */
    url?(file:T|null): Observable<string|null>;


    /** 
     * get metadata of a file
     */
    metadata?(file:T): Observable<FileMetadata>;
}

/**
 * A write only file driver
 */
export interface WriteFileDriver<T extends FileDescriptor> {
    upload(progress: ProgressObserver|null, file: T, data:Data): Observable<unknown>;
    
    delete?(progress: ProgressObserver|null, source: T): Observable<unknown>;
}

export type ReadWriteFileDriver<T extends FileDescriptor> = ReadFileDriver<T> & WriteFileDriver<T>;

/**
 * A file driver that can read and write
 */
export interface FileDriver<T extends FileDescriptor> extends ReadWriteFileDriver<T> {
    copy?(progress: ProgressObserver|null, source: T, destination: T): Observable<unknown>;
}

/**
 * A file driver that resolves file descriptors into other file descriptors
 */
export interface ResolverFileDriver<T extends FileDescriptor> {
    /**
     * resolve a file descriptor
     */
    resolveFile(file:T): FileDescriptor;
}

/*---------------------------------------------------------------------------*/

type AnyFileDriver<T extends FileDescriptor> = ReadFileDriver<T> | WriteFileDriver<T> | FileDriver<T> | ResolverFileDriver<T>;

/**
 * FileService interface, implements all FileOperations
 */
export class FileServiceImpl implements FileDriver<FileDescriptor> {
    static MAX_FILE_TASKS = 8;

    private _driverbyType:Map<string, AnyFileDriver<FileDescriptor>>;

    constructor() {
        this._driverbyType = new Map();
    }
    
    /**
     * Register a file driver in the file service
     * 
     * @param fileType type of file this driver accepts
     * @param driver file driver to register
     */
    registerDriver<T extends FileDescriptor>(fileType:string, driver: AnyFileDriver<T>) {
        this._driverbyType.set(fileType, driver);
    }

    /**
     * Execute a list of tasks 
     * 
     * `execute()` behaves like `forkJoin()` except that it limits the number of file tasks that can run
     *  simultaneously in the application.
     * 
     *  Note: the execution order of the tasks is unknown and depends on the execution time.
     * 
     * @param tasks 
     * @return an observable that return a value and completes when all tasks are complete or an error if any task fails.
     */
    execute(tasks:Observable<unknown>[]): Observable<unknown> {
        if(!tasks.length)
            return of(null);
        // trivial implementation for now, just limit the
        // number of simultaneous executions.
        return from(tasks).pipe(
            mergeAll(FileServiceImpl.MAX_FILE_TASKS),
            takeLast(1)
        );
    }

    resolveFile(file: FileDescriptor): FileDescriptor {
        const driver = this._driverbyType.get(file.type) as ResolverFileDriver<FileDescriptor>;
        if(!driver)
            throw new NotImplementedError("resolveFile", file.type);
        if(!driver.resolveFile)
            return file;
        return this.resolveFile(driver.resolveFile(file));
    }

    download(file: FileDescriptor): Data {
        const driver = this._driverbyType.get(file.type) as ReadFileDriver<FileDescriptor>
        if(!driver || !driver.download)
            throw new NotImplementedError("download", file.type);
        return driver.download(file);
    }
    upload(progress: ProgressObserver, file: FileDescriptor, data:Data): Observable<unknown> {
        const driver = this._driverbyType.get(file.type) as FileDriver<FileDescriptor>;
        if(!driver || !driver.upload)
            throw new NotImplementedError("upload", file.type);
        return driver.upload(progress, file, data);
    }
    delete(progress: ProgressObserver, source: FileDescriptor): Observable<unknown> {
        const driver = this._driverbyType.get(source.type) as FileDriver<FileDescriptor>;
        if(!driver || !driver.delete)
            throw new NotImplementedError("delete", source.type);
        return driver.delete(progress, source);
    }

    copy(progress: ProgressObserver, source: FileDescriptor, destination: FileDescriptor): Observable<unknown> {
        source = this.resolveFile(source);
        destination = this.resolveFile(destination);
        
        const sourceDriver = this._driverbyType.get(source.type) as FileDriver<FileDescriptor>;
        if(!sourceDriver)
            throw new NotImplementedError("copy", source.type);
        
        if(sourceDriver.copy && source.type === destination.type) {
            // direct driver copy on source driver
            return sourceDriver.copy(progress, source, destination);
        }

        const destinationDriver = this._driverbyType.get(destination.type) as FileDriver<FileDescriptor>;
        if(!sourceDriver.download)
            throw new NotImplementedError("download", source.type);
        if(!destinationDriver || !destinationDriver.upload)
            throw new NotImplementedError("upload", destination.type);
    
        // fallback to download / upload
        return destinationDriver.upload(progress, destination, sourceDriver.download(source));
    }

    list(progress:ProgressObserver, path:PathDescriptor): Observable<FileDescriptor> {
        const driver = this._driverbyType.get(path.type) as ReadFileDriver<FileDescriptor>
        if(!driver || !driver.list)
            throw new NotImplementedError("list", path.type);
        return driver.list(progress, path);
    }

    url(file: FileDescriptor): Observable<string> {
        if(!file)
            return of(null);
        const driver = this._driverbyType.get(file.type) as ReadFileDriver<FileDescriptor>
        if(!driver || !driver.url)
            throw new NotImplementedError("url", file.type);
        return driver.url(file);
    }
    
    metadata(file: FileDescriptor): Observable<FileMetadata> {
        const driver = this._driverbyType.get(file.type) as ReadFileDriver<FileDescriptor>
        if(!driver || !driver.metadata)
            throw new NotImplementedError("metadata", file.type);
        return driver.metadata(file);
    }
}

export const FileService = new FileServiceImpl();
