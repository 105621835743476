import { IllegalArgumentError } from "@archipad-js/core/error";

import { ConfigOperator } from "../config-operator";
import { isCustomOperator } from "../config.helpers";
import { CustomOperator } from "../config.types";

interface RGBObject extends CustomOperator {
    red: number;
    green: number;
    blue: number;
}

interface RGBAObject extends RGBObject {
    alpha: number;
}

function isRGBObject(object: CustomOperator): object is RGBObject {
    return (
        typeof object["red"] === "number" && typeof object["green"] === "number" && typeof object["blue"] === "number"
    );
}

function isRGBAObject(object: CustomOperator): object is RGBAObject {
    return isRGBObject(object) && typeof object["alpha"] === "number";
}

export const ColorRGB256 = new ConfigOperator("ColorRGB256", (args) => {
    if (!isCustomOperator(args) || !isRGBObject(args)) {
        throw new IllegalArgumentError("ColorRGB256 expects red, green and blue arguments");
    }

    const { red, green, blue } = args;
    return ((red & 0xff) << 16) | ((green & 0xff) << 8) | (blue & 0xff);
});

export const ColorRGBA256 = new ConfigOperator("ColorRGBA256", (args) => {
    if (!isCustomOperator(args) || !isRGBAObject(args)) {
        throw new IllegalArgumentError("ColorRGBA256 expects red, green, blue and alpha arguments");
    }

    const { red, green, blue, alpha } = args;
    return ((alpha & 0xff) << 24) | ((red & 0xff) << 16) | ((green & 0xff) << 8) | (blue & 0xff);
});
