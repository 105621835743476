import * as JSBN from 'jsbn';
import { SHA1 } from "crypto-js";
import { IllegalArgumentError } from '@core/errors/errors-core';

const BIGINT_SIZE = 20;

function randHex(max) {
    return ('000' + Math.floor(Math.random() * max).toString(16)).substr(-4,4);
}

function makeBigInt() {
    const ret = [];
    for(let i=BIGINT_SIZE-1;i>=0;i--)
        ret.push(0);
    return ret;
}

function pow2(n) {
    let v;
    let c = 0;
    for(let i=BIGINT_SIZE-1;i>=0;i--) {
        v = n[i];
        v = v*2 + c;
        c = v >= 10 ? 1 : 0;
        n[i] = v % 10;
    }
}

function add(a,b) {
    let v;
    let c = 0;
    for(let i=BIGINT_SIZE-1;i>=0;i--) {
        v = a[i] + b[i] + c;
        c = v >= 10 ? 1 : 0;
        a[i] = v % 10;
    }
}

export function hexStrToDec(hexStr) {
    const ret = makeBigInt();

    const n = makeBigInt();
    n[BIGINT_SIZE-1] = 1;

    for(let i=hexStr.length-1;i>=0;i--) {
        const c = parseInt(hexStr.charAt(i), 16);
        if(c & 1) add(ret, n);
        pow2(n);
        if(c & 2) add(ret, n);
        pow2(n);
        if(c & 4) add(ret, n);
        pow2(n);
        if(c & 8) add(ret, n);
        pow2(n);
    }
    while(ret[0] == 0 && ret.length != 1)
        ret.shift();

    return ret.join('');
}

function generateStableHash63(deterministicKey: string): JSBN.BigInteger {
    const key = SHA1(deterministicKey);
    const hash1= (key.words[key.words.length-2] >>> 0) & 0x7FFFFFFF;
    const hash2 = key.words[key.words.length-1] >>> 0;

    const hash = [hash1, hash2]
        .map((hash) => new JSBN.BigInteger(hash.toString(), 10))
        .reduce((previous, current) => previous.shiftLeft(32).add(current));

    return hash;
}

function generateStableHash128(deterministicKey: string): JSBN.BigInteger {
    const key = SHA1(deterministicKey);
    const hash1 = key.words[key.words.length-4] >>> 0;
    const hash2 = key.words[key.words.length-3] >>> 0;
    const hash3 = key.words[key.words.length-2] >>> 0;
    const hash4 = key.words[key.words.length-1] >>> 0;

    const hash = [hash1, hash2, hash3, hash4]
        .map((hash) => new JSBN.BigInteger(hash.toString(), 10))
        .reduce((previous, current) => previous.shiftLeft(32).add(current));

    return hash;
}

function generateDeterministicIdWithStringKey(baseDeterministicId:string, deterministicKey:string) {
    const baseId = new JSBN.BigInteger(baseDeterministicId, 10);
    const hash = generateStableHash63(deterministicKey);

    return baseId.xor(hash).toString();
}

function generateDeterministicIdWithNumericKey(baseDeterministicId:string, deterministicKey:string) {
    const baseId = new JSBN.BigInteger(baseDeterministicId, 10);
	const keyId = new JSBN.BigInteger(deterministicKey, 10);
    return baseId.xor(keyId).toString();
}

function generateDeterministicIdWithUUIDKey(baseDeterministicId:string, deterministicKey:string) {
    if(!deterministicKey.match(/^[A-Z0-9]{32}$/))
        throw new IllegalArgumentError('Invalid UUID key', 'deterministicKey', 'UUID', deterministicKey);
    const keyStrLow = deterministicKey.substring(16);
    let keyId = new JSBN.BigInteger(keyStrLow, 16);
    keyId = keyId.and(new JSBN.BigInteger("7FFFFFFFFFFFFFFF", 16)); // force sign bit to 0

    const baseId = new JSBN.BigInteger(baseDeterministicId, 10);
    return baseId.xor(keyId).toString();
}

//------------------------------------------------------------------------------
export function generateId() {
    // generate an int64 random id (in 16 bits chunk to workaround rand generator issues on some browsers)
    const str = randHex(0x7FFF) + randHex(0xFFFF) + randHex(0xFFFF) + randHex(0xFFFF);

    // convert to decimal and return
    return hexStrToDec(str);
};

export type DeterministicIdKey = "string" | "number" | "uuid";

//
export function generateDeterministicId(baseDeterministicId:string, deterministicKey:string, keyType:DeterministicIdKey = "string") {
    if(typeof baseDeterministicId !== 'string' || !baseDeterministicId.match(/^[0-9]+$/))
        throw new IllegalArgumentError('Invalid baseDeterministicId key', 'baseDeterministicId', 'DeterministicId', baseDeterministicId);
    if(typeof deterministicKey !== 'string')
    throw new IllegalArgumentError('Invalid deterministicKey key', 'deterministicKey', 'DeterministicKey', deterministicKey);

    if (keyType == "uuid") {
		return generateDeterministicIdWithUUIDKey(baseDeterministicId, deterministicKey);
	} else if (keyType === "number") {
		return generateDeterministicIdWithNumericKey(baseDeterministicId, deterministicKey);
	} else {
		return generateDeterministicIdWithStringKey(baseDeterministicId, deterministicKey);
	}
}

export function generateDeterministicTemplateIdFromTemplateIdAndEntityId(baseTemplateId: string, rootEntityId: string): string {
    const hexId = new JSBN.BigInteger(baseTemplateId, 16).xor(new JSBN.BigInteger(rootEntityId, 10)).toString(16).toUpperCase();
    return ('00000000000000000000000000000000' + hexId).slice(-32);
}

export function generateDeterministicTemplateIdFromTemplateIdAndTemplateId(templateId1: string, templateId2: string): string {
    const hexId = new JSBN.BigInteger(templateId1, 16).xor(new JSBN.BigInteger(templateId2, 16)).toString(16).toUpperCase();
    return ('00000000000000000000000000000000' + hexId).slice(-32);
}

export function generateDeterministicTemplateIdFromTemplateIdAndStringKey(baseTemplateId: string, stringKey: string): string {
    const hash = generateStableHash128(stringKey);

    const hexId = new JSBN.BigInteger(baseTemplateId, 16).xor(new JSBN.BigInteger(hash.toString(), 10)).toString(16).toUpperCase();
    return ('00000000000000000000000000000000' + hexId).slice(-32);
}

//------------------------------------------------------------------------------
/**
 * If you want to create a new entity with a templateId do:
 *  * ```
 * let templateId = makeUUID();
 * let entityId = generateDeterministicId(projectId, templateId, "uuid")
 * let entity = entityContext.createEntity("FormType", entityId)
 * ```
 * 
 * If you want to add a templateId to an existing entity do:
 * ```
 * existingEntity.templateId = generateDeterministicTemplateId(projectId, entity.id);
 * ```
 * 
 * NEVER USE: 
 * ```
 * existingEntity.templateId = makeUUID();
 * ```
 */

/**
 * Generate a new UUID
 */
export function makeUUID() {
    const uuid = randHex(0xFFFF) + randHex(0xFFFF) + randHex(0xFFFF) + randHex(0xFFFF) +
        randHex(0xFFFF) + randHex(0xFFFF) + randHex(0xFFFF) + randHex(0xFFFF);
    return uuid.toUpperCase();
}

/**
 * Generate a templateId from a baseDeterministicId and an existing entityId.
 */
export function generateDeterministicTemplateId(baseDeterministicId:string, entityId:string): string  {
    if(typeof baseDeterministicId !== 'string' || !baseDeterministicId.match(/^[0-9]+$/))
        throw new IllegalArgumentError(`Invalid baseDeterministicId key: ${baseDeterministicId}`);
    if(typeof entityId !== 'string' || !entityId.match(/^[0-9]+$/))
        throw new IllegalArgumentError('Invalid entityId key', 'entityId');

    const baseId = new JSBN.BigInteger(baseDeterministicId,10);
    const keyId = new JSBN.BigInteger(entityId,10);
    
    let hexId = baseId.xor(keyId).toString(16).toUpperCase();
    hexId = ('00000000000000000000000000000000' + hexId).slice(-32);
    return hexId;
}
