import { startOfToday, endOfToday } from "date-fns";
import { VisitEntity } from '@archipad-models/models/VisitEntity';

Object.defineProperty(VisitEntity.prototype, 'isVisitToday', {
    get: function(this:VisitEntity) : boolean {
        if (this.isDeleted()) {
            return false;
        }
    
        return (this.date >= startOfToday() && this.date < endOfToday());
    },
    enumerable: true
});
