import { ContainerModule } from "inversify";

import { ArchiwebServicesClient, Archiweb_Services_Client_DIID } from "./archiweb-services-client";
import {
    LegacyArchipadAuthService,
    Legacy_Archipad_Auth_Service_DIID,
} from "./authentication/legacy-archipad-authentication.service";
import { ArchipadRPCService, Archipad_RPC_Service_DIID } from "./rpc/archipad-rpc.service";
import { TrustedTimeService, Trusted_Time_Service_DIID } from "./trusted-time/trusted-time.service";
import { ArchipadAuthService, Archipad_Auth_Service_DIID } from "./authentication/archipad-authentication.service";

export const ArchiwebServicesClientModule = new ContainerModule((bind) => {
    bind<ArchiwebServicesClient>(Archiweb_Services_Client_DIID.symbol).to(ArchiwebServicesClient).inSingletonScope();
    bind<ArchipadRPCService>(Archipad_RPC_Service_DIID.symbol).to(ArchipadRPCService);
    bind<LegacyArchipadAuthService>(Legacy_Archipad_Auth_Service_DIID.symbol)
        .to(LegacyArchipadAuthService)
        .inSingletonScope();
    bind<TrustedTimeService>(Trusted_Time_Service_DIID.symbol).to(TrustedTimeService).inSingletonScope();
    bind<ArchipadAuthService>(Archipad_Auth_Service_DIID.symbol).to(ArchipadAuthService).inSingletonScope();
});
