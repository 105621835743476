import { ProjectEntity } from "../ProjectEntity";
import { resolveCostPerUnitSurface, resolveOverallBudget } from "@archipad/helpers/projectCostHelper";

Object.defineProperty(ProjectEntity.prototype, 'estimatedOverallBudget', {
    get: function(this:ProjectEntity) : number {
        return resolveOverallBudget(this.estimatedDemolitionBudget, this.estimatedConstructionBudget);
    },
    enumerable: true,
});

Object.defineProperty(ProjectEntity.prototype, 'realOverallBudget', {
    get: function(this:ProjectEntity) : number {
        return resolveOverallBudget(this.realDemolitionBudget, this.realConstructionBudget);
    },
    enumerable: true,
});

Object.defineProperty(ProjectEntity.prototype, 'estimatedCostSurface', {
    get: function(this:ProjectEntity) : number {
        const overallBudget = resolveOverallBudget(this.estimatedDemolitionBudget, this.estimatedConstructionBudget)
        return resolveCostPerUnitSurface(overallBudget, this.floorSurface);
    },
    enumerable: true,
});

Object.defineProperty(ProjectEntity.prototype, 'realCostSurface', {
    get: function(this:ProjectEntity) : number {
        const overallBudget = resolveOverallBudget(this.realDemolitionBudget, this.realConstructionBudget);
        return resolveCostPerUnitSurface(overallBudget, this.floorSurface);
    },
    enumerable: true,
});