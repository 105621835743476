import { ContainerModule } from "inversify";
import { queueScheduler } from "rxjs";

import { ConfigService } from "./config.service";
import { Config_Service_DIID, Config_Operator_DIID, Config_Service_Scheduler_DIID } from "./config.types";
import { ColorRGB256, ColorRGBA256 } from "./operators/color";

import { NewObject, Update } from "./operators/compatibility";

export const ConfigModule = new ContainerModule((bind) => {
    bind(Config_Service_DIID.symbol).to(ConfigService).inSingletonScope();

    bind(Config_Operator_DIID.symbol).toConstantValue(NewObject);
    bind(Config_Operator_DIID.symbol).toConstantValue(Update);

    bind(Config_Operator_DIID.symbol).toConstantValue(ColorRGB256);
    bind(Config_Operator_DIID.symbol).toConstantValue(ColorRGBA256);

    bind(Config_Service_Scheduler_DIID.symbol).toConstantValue(queueScheduler);
});
