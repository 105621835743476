import { isEqual } from "lodash";
import { Observable, Subject } from "rxjs";

import { IllegalArgumentError, UnsupportedOperationError } from "@archipad-js/core/error";
import { Comparator, Expression, UnaryOperator } from "@archipad-js/core/query";
import { isRecord } from "@archipad-js/core/utils";

import { applyOperators, isBuiltInOperator, parsePath } from "./config.helpers";
import { AspectPath, ConfigError } from "./config.types";

export type BundleUpdate =
    | {
          type: "bundle_list";
      }
    | {
          type: "object";
          bundle: ConfigBundle;
      }
    | {
          type: "aspect";
          bundle: ConfigBundle;
      }
    | {
          type: "enabled_aspects";
          bundle: ConfigBundle;
      };

interface BundleObject {
    path: string;
    aspect?: string;
    data: unknown;
}

interface BundleAspect {
    path: string;
    aspect: string;
    data: Array<Record<string, unknown>>;
}

/**
 * Configuration bundle
 *
 * Gather a list of configuration objects and configuration aspects in an object.
 *
 * The objects and the active aspects are added to the configuration as soon as the
 * bundle is activated.
 *
 * If the bundle is changed while active, the changes are immediate
 */
export class ConfigBundle {
    public readonly name: string;
    public readonly priority: number;
    public readonly updates$: Observable<BundleUpdate>;
    public currentConfig: unknown | null;

    private readonly _updates: Subject<BundleUpdate>;
    private _objects: BundleObject[];
    private _aspects: BundleAspect[];
    private _enabledAspects: readonly string[];

    /**
     * @param priority bundle priority, defines the order of application of objects and aspects from the bundle compared to the others
     */
    constructor(name: string, priority: number) {
        this.name = name;
        this.priority = priority;
        this._updates = new Subject();
        this.updates$ = this._updates.asObservable();
        this.currentConfig = null;

        this._objects = [];
        this._aspects = [];
        this._enabledAspects = [];
    }

    /**
     * A sort comparator of {@link ConfigBundle} by priority.
     */
    static get comparator(): Comparator<ConfigBundle> {
        return (a, b) => {
            if (a.priority === b.priority) {
                throw new ConfigError(`A bundle named ${a.name} already exists with the same priority as ${b.name}`);
            }
            return a.priority - b.priority;
        };
    }

    /**
     * Add an object to the bundle
     *
     * @param path object path
     * @param data an object serializable in JSON
     */
    set(path: BundleObject["path"], data: BundleObject["data"]): void {
        const object = { path: path, data: data };
        for (let i = this._objects.length - 1; i >= 0; i--) {
            if (this._objects[i].path === path) {
                this._objects.splice(i, 1);
                break;
            }
        }

        this._objects.push(object);
        this._updates.next({ bundle: this, type: "object" });
    }

    /**
     * Add an object dedicated to an aspect to the bundle.
     *
     * The object is disabled unless it has been enabled in this bundle or
     * in another active bundle.
     *
     * @param path object path
     * @param aspect aspect name
     * @param data an object serializable in JSON
     */
    setForAspect(path: BundleObject["path"], name: BundleObject["aspect"], data: BundleObject["data"]): void {
        const object = { path: path, aspect: name, data: data };
        for (let i = this._objects.length - 1; i >= 0; i--) {
            if (this._objects[i].path === path && this._objects[i].aspect === name) {
                this._objects.splice(i, 1);
                break;
            }
        }

        this._objects.push(object);
        this._updates.next({ bundle: this, type: "object" });
    }

    /**
     * Add an aspect to the bundle
     *
     * The aspect is disabled unless it has been enabled in this bundle or
     * in another active bundle.
     *
     * @param path config path to apply the aspect on.
     * @param name aspect name.
     * @param aspectData aspect instruction to apply on the config.
     */
    addAspect(path: BundleAspect["path"], name: BundleAspect["aspect"], aspectData: BundleAspect["data"]): void {
        const aspect = { path: path, aspect: name, data: aspectData };
        for (let i = this._aspects.length - 1; i >= 0; i--) {
            if (this._aspects[i].path === path && this._aspects[i].aspect === name) {
                this._aspects.splice(i, 1);
                break;
            }
        }

        this._aspects.push(aspect);
        this._updates.next({ bundle: this, type: "aspect" });
    }

    /**
     * Tests if the bundle contains at least one aspect in set
     *
     * @param aspects a set of aspect names
     * @return true if the bundle contains one of the aspects in the set
     */
    hasAnAspectIn(aspects: Set<string>): boolean {
        for (let i = this._aspects.length - 1; i >= 0; i--) {
            if (aspects.has(this._aspects[i].aspect)) return true;
        }
        return false;
    }

    /**
     * Set the aspects enabled by this bundle
     *
     * @param aspects aspect list to enable
     */
    setEnabledAspects(aspects: string[]): void {
        // Defensive CODE to avoid infinite loop
        if (isEqual(this._enabledAspects, aspects)) {
            return;
        }
        this._enabledAspects = [...aspects];
        this._updates.next({ bundle: this, type: "enabled_aspects" });
    }

    /**
     * Add aspect to the list of enabled aspects
     *
     * @param aspect aspect to add
     */
    addEnabledAspects(aspect: string): void {
        if (this._enabledAspects.includes(aspect)) {
            return;
        }

        const newAspects = [...this._enabledAspects, aspect];
        this.setEnabledAspects(newAspects);
    }

    /**
     * Remove aspect from the list of enabled aspects
     *
     * @param aspect aspect to remove
     */
    removeEnabledAspects(aspect: string): void {
        if (!this._enabledAspects.includes(aspect)) {
            return;
        }

        const newAspects = this._enabledAspects.filter((a) => a !== aspect);
        this.setEnabledAspects(newAspects);
    }

    /**
     * Get the aspects enabled by this bundle
     *
     * @return an aspect list
     */
    getEnabledAspects(): readonly string[] {
        return this._enabledAspects;
    }

    /**
     * Apply the bundle on configData
     *
     * The input configuration is not modified
     *
     * @param configData input configuration
     * @param enabledAspects aspects to apply
     * @return a clone of configData with the bundle modification applied
     */
    applyOnConfig(configData: unknown, enabledAspects: readonly string[]): unknown {
        // set values
        for (const object of this._objects) {
            const isObjectEnabledForAspect = object.aspect === undefined || enabledAspects.includes(object.aspect);
            if (!isObjectEnabledForAspect) {
                continue;
            }

            configData = setValueAtPath(configData, object.path, object.data);
        }

        // NOTE: apply includes after setting the configuration objects but before applying aspects.
        // this is done this way because aspects are usually applied on the data after include.
        // I.e: if view.json includes panel.json, the aspects are on view.json rather than on panel.json
        //
        // NOTE: if aspects are applied on an object that is included, the result are non deterministic
        configData = applyOperators(configData, function (name, args) {
            if (!isBuiltInOperator(args)) {
                return args;
            }
            if (name !== "inc") {
                return args;
            }
            const path = args.str.split(/:|\./);
            let o = configData;
            for (const p of path) {
                if (!isRecord(o)) {
                    break;
                }
                o = o[p];
                if (!o) break;
            }
            return o;
        });

        const sortedAspects: BundleAspect[] = [];
        for (const enabledAspect of enabledAspects) {
            const matchedAspects = this._aspects.filter((x) => x.aspect === enabledAspect);
            sortedAspects.splice(sortedAspects.length, 0, ...matchedAspects);
        }
        // apply aspects
        for (const aspect of sortedAspects) {
            try {
                const path = parsePath(aspect.path);
                configData = applyUpdateOnPath(configData, aspect.path, 0, path.length, path, function (o) {
                    return applyConfigurationAspect(o, aspect.data);
                });
            } catch (err) {
                const error = new ConfigError(`${err} in ${aspect.aspect} applied on ${aspect.path}`);
                if (err instanceof Error) {
                    error.underlyingError = err;
                }
                throw error;
            }
        }

        return configData;
    }
}

function applyUpdateOnPath(
    object: unknown,
    pathStr: string,
    pathStart: number,
    pathEnd: number,
    path: AspectPath[],
    fn: UnaryOperator,
): unknown {
    if (pathStart === pathEnd) return fn(object);

    if (object == null) return object;

    const pathComponent = path[pathStart];
    switch (pathComponent.type) {
        case "index": {
            const index = pathComponent.value;

            // index, expect an array
            if (!Array.isArray(object)) {
                throw new IllegalArgumentError(
                    "Unable to resolve path",
                    `${pathStr} at index ${index}`,
                    "array",
                    JSON.stringify(object),
                );
            }

            const o = applyUpdateOnPath(object[index], pathStr, pathStart + 1, pathEnd, path, fn);
            if (object[index] === o) return object; // no change

            // object changed, clone if needed then assign
            const clone = [...object];
            clone[index] = o;
            return clone;
        }

        case "star": {
            // index, expect an array
            if (!Array.isArray(object))
                throw new IllegalArgumentError("Unable to resolve path", pathStr, "array", JSON.stringify(object));

            // apply the update once for each
            // element of the target object array
            for (let i = 0; i < object.length; i++) {
                const o = applyUpdateOnPath(object[i], pathStr, pathStart + 1, pathEnd, path, fn);
                if (object[i] !== o) {
                    // object changed, clone if needed then continue assignment
                    const clone = [...object];
                    clone[i] = o;
                    for (let j = i + 1; j < object.length; j++)
                        clone[j] = applyUpdateOnPath(object[j], pathStr, pathStart + 1, pathEnd, path, fn);
                    return clone;
                }
            }
            return object; // no change
        }

        case "expr": {
            const expressionString = pathComponent.value;
            // predicate expression, expect an array
            if (!Array.isArray(object))
                throw new IllegalArgumentError(
                    "Unable to resolve path",
                    `${pathStr} for ${expressionString}`,
                    "array",
                    JSON.stringify(object),
                );

            let expression;
            try {
                expression = new Expression(expressionString).build();
            } catch (e) {
                throw new IllegalArgumentError(
                    "Unable to resolve path '" +
                        pathStr +
                        "': got an expression error '" +
                        expressionString +
                        "': " +
                        e,
                );
            }

            // apply the set value for each object matching the expression
            for (let i = 0; i < object.length; i++) {
                if (!expression(object[i])) continue;

                const o = applyUpdateOnPath(object[i], pathStr, pathStart + 1, pathEnd, path, fn);
                if (object[i] !== o) {
                    const clone = [...object];
                    clone[i] = o;
                    for (let j = i + 1; j < object.length; j++) {
                        if (!expression(object[j])) continue;
                        clone[j] = applyUpdateOnPath(object[j], pathStr, pathStart + 1, pathEnd, path, fn);
                    }
                    return clone;
                }
            }
            return object; // no change
        }

        case "ident": {
            const key = pathComponent.value;

            // key, expect a dictionary
            if (!isRecord(object))
                throw new IllegalArgumentError(
                    "Unable to resolve path",
                    `${pathStr} for key ${key}`,
                    "dictionary",
                    JSON.stringify(object),
                );

            const o = applyUpdateOnPath(object[key], pathStr, pathStart + 1, pathEnd, path, fn);
            if (object[key] === o) return object; // no change

            // object changed, clone if needed then assign
            const clone = { ...object };
            clone[key] = o;
            return clone;
        }
    }
}

function setValueAtPath(object: unknown, pathStr: string, value: unknown): unknown {
    const path = parsePath(pathStr);

    return applyUpdateOnPath(object, pathStr, 0, path.length - 1, path, function (o) {
        if (o == null) return o;

        const lastPathComponent = path[path.length - 1];
        switch (lastPathComponent.type) {
            case "index": {
                const index = lastPathComponent.value;

                // index, expect an array
                if (!Array.isArray(o))
                    throw new IllegalArgumentError(
                        "Unable to resolve path",
                        `${pathStr} at index ${index}`,
                        "array",
                        JSON.stringify(o),
                    );

                const clone = [...o];
                if (value === null) clone.splice(index, 1);
                else clone[index] = value;

                return clone;
            }

            case "star": {
                // index, expect an array
                if (!Array.isArray(o))
                    throw new IllegalArgumentError(
                        "Unable to resolve path",
                        `${pathStr} for *`,
                        "array",
                        JSON.stringify(o),
                    );

                const clone = [...o];
                for (let i = 0; i < o.length; i++) clone[i] = value;

                return clone;
            }

            case "expr": {
                const expressionString = lastPathComponent.value;
                // predicate expression, expect an array
                if (!Array.isArray(o)) {
                    throw new IllegalArgumentError(
                        "Unable to resolve path",
                        `${pathStr} for ${expressionString}`,
                        "array",
                        JSON.stringify(o),
                    );
                }

                const clone = [...o];

                if (expressionString == "end") {
                    // add value at the end
                    clone.push(value);
                } else if (expressionString == "start") {
                    // add value at the beginning
                    clone.unshift(value);
                } else if (expressionString == "endForeach") {
                    // add values at the end
                    if (!Array.isArray(value)) {
                        throw new IllegalArgumentError(
                            "value is not an array",
                            `${pathStr} for ${expressionString}`,
                            "array",
                            JSON.stringify(value),
                        );
                    }
                    value.forEach(function (iValue) {
                        clone.push(iValue);
                    });
                } else if (expressionString == "startForeach") {
                    // add values at the beginning
                    if (!Array.isArray(value)) {
                        throw new IllegalArgumentError(
                            "value is not an array",
                            `${pathStr} for ${expressionString}`,
                            "array",
                            JSON.stringify(value),
                        );
                    }
                    (value as unknown[])
                        .slice()
                        .reverse()
                        .forEach(function (iValue) {
                            clone.unshift(iValue);
                        });
                } else {
                    let expression;
                    try {
                        expression = new Expression(expressionString).build();
                    } catch (e) {
                        throw new IllegalArgumentError(
                            "Unable to resolve path '" +
                                pathStr +
                                "': got an expression error '" +
                                expressionString +
                                "': " +
                                e,
                        );
                    }

                    // assign the value on each object matching the expression
                    for (let i = o.length - 1; i >= 0; i--) {
                        if (!expression(o[i])) continue;
                        if (value === null) clone.splice(i, 1);
                        else clone[i] = value;
                    }
                }
                return clone;
            }

            case "ident": {
                const key = lastPathComponent.value;

                // key, expect an dictionary
                if (!isRecord(o)) {
                    throw new IllegalArgumentError(
                        "Unable to resolve path",
                        `${pathStr} for key ${key}`,
                        "dictionary",
                        JSON.stringify(o),
                    );
                }

                const clone = { ...o };
                if (value === null) delete clone[key];
                else clone[key] = value;

                return clone;
            }

            // istanbul ignore next (Not reachable. All possible values have been considered)
            default: {
                throw new IllegalArgumentError(`Unable to resolve path "${pathStr}": no such token`);
            }
        }
    });
}

function applyConfigurationUpdate(configData: unknown, operation: Record<string, unknown>): unknown {
    for (const k in operation) {
        if (k == "func:") continue;
        const value = operation[k];
        configData = setValueAtPath(configData, k, value);
    }
    return configData;
}

function applyConfigurationAspect(configData: unknown, aspectData: Array<Record<string, unknown>>): unknown {
    for (let i = 0; i < aspectData.length; i++) {
        const operation = aspectData[i];
        if (operation["func:"] == "Update") {
            configData = applyConfigurationUpdate(configData, operation);
        } else {
            throw new UnsupportedOperationError("Unsupported configuration aspect operation: " + operation["func:"]);
        }
    }
    return configData;
}
