import { of, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { JSONDecode, JSONEncode } from "@archipad-js/archipad/utils";

import { Data, StringData } from "@core/services/file/file";
import { ProgressObserver } from "@core/tasks/progress";

declare module "@core/services/file/file" {
    namespace Data {
        /** create a Data object from a string */
        function fromJSON(json: unknown): Data;
    }
    interface Data {
    	/** Fetch the file contents as a JSON object (json with comments and dates - same as for patches) */
        asJSON(progress:ProgressObserver): Observable<unknown>;
    }
}

Data.fromJSON = function(json: unknown) {
    return new JSONData(json);
}
Data.prototype.asJSON = function(progress:ProgressObserver, encoding:string = "utf-8") {
    return (this as Data).asString(progress, encoding).pipe(
        map(function(str) { return JSONDecode(str); })
    );
}

class JSONData extends StringData {
    private _json: unknown;

    constructor(json: unknown, encoding:string = "utf-8") {
        super('application/json', encoding);
        this._json = json;
    }

    asString(progress:ProgressObserver) { return of(JSONEncode(this._json)); }
    asJSON(progress:ProgressObserver) { return of(this._json); }
}
