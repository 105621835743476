/**
* RemarkStyleEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RemarkEntity } from "./RemarkEntity";


declare global {
    interface EntityTypeMap {
        "RemarkStyle": RemarkStyleEntity
    }
}

export abstract class RemarkStyleEntity extends BaseEntity {

	 abstract remarkStyleId: string;
	 abstract color: number;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract style?: number;
	 abstract isDefault?: boolean;
	 abstract styleOfRemark?: ReadonlyArray<RemarkEntity>;
	
}
