"use strict";

import * as JSBN from 'jsbn';

import { createLogger } from '@core/services/logger.service';
import * as queryService from '@core/services/orm/query';
import * as idGenerator from '@core/services/orm/idGenerator';
import { ParticipantRoleData } from "@archipad/backend/archiweb/sharedProjects";

// use generated *Entity
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { ProjectUserEntity } from '@archipad-models/models/ProjectUserEntity';
import { ProjectUserRoleEntity } from '@archipad-models/models/ProjectUserRoleEntity';
import { ProjectUserRightEntity } from '@archipad-models/models/ProjectUserRightEntity';

import { WorkflowManagerError } from '@archipad/errors/errors-archipad';
import { getAccountService } from '@archipad/services/account.service';
import { Logger } from '@archipad-js/core/logger';
import { Expression } from '@archipad-js/core/query';
import { fetchEntityByTemplateId, fetchEntityByTemplateName } from '@core/services/orm/orm.helper';


//=================================================================================================

export const WORKFLOW_COMPLEX_ROBOT_USER_TEMPLATE_ID = '51A2468869C342F1B0251B026326B8A3';
//=================================================================================================

export interface UserDataJson {
    cloudUserId : number;
    email : string;
    primaryRole : string;
    roleData : ParticipantRoleData;
    isOwner: boolean;
    name: string;
    company: string;
}

interface UserData {
    cloudUserId: string;
    email : string;
    right: ProjectUserRightEntity;
    role: ProjectUserRoleEntity;
    isOwner: boolean;
    name: string;
    company: string;

}


//=================================================================================================

export class WorkflowUserManager {

    constructor(
        private readonly logger: Logger,
    ) {}

	//=================================================================================================
	// Related to : User

	isCurrentUser(user:ProjectUserEntity) : boolean {
        if (user == null) {
            return false;
        }
        const currentUser = this.getCurrentUser(user.project);
        if (currentUser == null) {
            return false;
        }
        const res = (currentUser.id == user.id);
        return res;
	}

    isCurrentUserProjectOwner(project: ProjectEntity): boolean {
        const currentUser = this.getCurrentUser(project);
        if (currentUser.id === project.owner?.id) {
            return true;
        }
        return false;
    }

    getCurrentUser(project: ProjectEntity): ProjectUserEntity {
        const cloudUserId = this._getLoggedUserId();
        if (cloudUserId == null) {
            this.logger.warn("[WorkflowUserManager] cannot get current cloud user id");
            return null;
        }

        const entityContext = project.getContext();
		const query = queryService.query<ProjectUserEntity>(entityContext, "ProjectUser");
        query.predicateWithFormat("cloudUserId == {cloudUserId}");
        const predicateParams = { 'cloudUserId' : cloudUserId.toString() };

        const resultSet = query.execute(predicateParams);
        const count = resultSet.count();

        let currentUser: ProjectUserEntity;
        if (count == 0) {
            this.logger.warn("[WorkflowUserManager] user not found");
            return null;
        }
		else if (count == 1) {
			currentUser = resultSet.firstEntity();
		}
		else {
            this.logger.warn("[WorkflowUserManager] multiple users found, processing best match");

			const entityId = idGenerator.generateDeterministicId(project.id, cloudUserId.toString(), 'number');
			let foundUser = null;
			let isExactMatch = false;
			resultSet.forEach(function(obj) {
				if (isExactMatch) return;

				if (obj.id == entityId) {
					foundUser = obj;
					isExactMatch = true;
				}
				else if (foundUser == null) {
					foundUser = obj;
				}
				else if ( (new JSBN.BigInteger(obj.id)).compareTo(new JSBN.BigInteger(foundUser.id)) < 0 ) {
					foundUser = obj;
				}
			}, this);

			currentUser = foundUser;
        }

        if (currentUser.role == null) {
            this.logger.warn("[WorkflowUserManager] user role not defined");
        }

        return currentUser;
	}

    listUsers(project: ProjectEntity): readonly ProjectUserEntity[] {
        const entityContext = project.getContext();

        let q = queryService.query(entityContext, "ProjectUser").orderBy("email, id");

        const currentUser = this.getCurrentUser(project);
        if (currentUser) {
            q = q.predicateWithFormat("id != {currentUserId}", { currentUserId: currentUser.id })
        }

        const resultSet = q.execute();
        const users = resultSet.sortedEntities();

        if (!currentUser) {
            return [...users];
        }

        return [currentUser, ...users];
    }

    findRight(project:ProjectEntity, rightName:string) : ProjectUserRightEntity {
		const entityContext = project.getContext();
		const query = queryService.query(entityContext, "ProjectUserRight");
        query.predicate(new Expression("project == {project} AND templateName == {name}"));
		const predicateParams = { 'project' : project, name: rightName };

		const resultSet = query.execute(predicateParams);

		const projectRight = resultSet.firstEntity();

        if ( ! projectRight ) {
			throw new WorkflowManagerError("Unable to find right "+ rightName);
        }

		return projectRight;
	}

    fetchProjectUserByTemplateId(project: ProjectEntity, projectUserTemplateId: string): ProjectUserEntity | null {
        const entityContext = project.getContext();
        const projectUser = fetchEntityByTemplateId(entityContext, 'ProjectUser', projectUserTemplateId);
        return projectUser;
    }

    private _getLoggedUserId():number | null {
        return getAccountService().currentUser?.id;
	}

    //=================================================================================================
    // Related to : Backend API

    public async synchronizeUsers(project:ProjectEntity, users:Array<UserDataJson>): Promise<void> {
        const usersByCloudId : { [key:string]:UserData } = {};

        users.forEach((user) => {
            if (user.cloudUserId == null) { throw new WorkflowManagerError("[WorkflowManager] Assert not null"); }
            if (user.primaryRole == null) { throw new WorkflowManagerError("[WorkflowManager] Assert not null"); }


            const right = this._rightFromUserData(project, user);
            const role = this._roleFromUserData(project, user);

            const cloudUserId = user.cloudUserId.toString();
            const userContainer : UserData = {
                cloudUserId: cloudUserId,
                email: user.email,
                right: right,
                role: role,
                isOwner: user.isOwner,
                name: user.name,
                company: user.company,
            };

            usersByCloudId[cloudUserId] = userContainer;
        });

        // handle remove and update user
        const foundUsers : Array<string> = [];
        project.users.forEach((userEntity) => {
            const userContainer = usersByCloudId[userEntity.cloudUserId];
            if (userContainer) {

                if (userContainer.isOwner) {
                    project.owner = userEntity;

                    if (!project.planningMaster) {
                        project.planningMaster = userEntity;
                    }
                }

                // Synchronize ProjectUser email
                if(!(userContainer.email === userEntity.email)) {
                    userEntity.email = userContainer.email;
                }

                // Synchronize ProjectUser name
                if(!(userContainer.name === userEntity.name)) {
                    userEntity.name = userContainer.name;
                }

                // Synchronize ProjectUser company
                if(!(userContainer.company === userEntity.company)) {
                    userEntity.company = userContainer.company;
                }

                foundUsers.push(userEntity.cloudUserId);
                // if project has been upgraded to workflow rights, set them
                if (userContainer.right) {
                    if (userEntity.right == null || (userEntity.right.id != userContainer.right.id)) {
                        this.logger.debug("[WorkflowManager] user - right updated | cloudUserId:", userEntity.cloudUserId, "email:", userEntity.email);
                        userEntity.right = userContainer.right;
                    }
                }
                if (userEntity.role == null || (userEntity.role.id != userContainer.role.id)) {
                    this.logger.debug("[WorkflowManager] user - role updated | cloudUserId:", userEntity.cloudUserId, "email:", userEntity.email);
                    userEntity.role = userContainer.role;
                }
            } else {
                this.logger.debug("[WorkflowManager] user - removed | cloudUserId:", userEntity.cloudUserId, "email:", userEntity.email);
                userEntity.right = null;
                userEntity.role = null;
            }
        });
        foundUsers.forEach((key) => {
            delete usersByCloudId[key];
        });

        // handle add user
        for (const key in usersByCloudId) {
            const userContainer = usersByCloudId[key];

            this.logger.debug("[WorkflowManager] user - added | cloudUserId:", userContainer.cloudUserId, "email:", userContainer.email);
            const projectUser = this._createProjectUser(project, userContainer.cloudUserId);
            projectUser.email = userContainer.email;
            projectUser.right = userContainer.right;
            projectUser.role = userContainer.role;
            projectUser.name = userContainer.name;
            projectUser.company = userContainer.company;
            
            if (userContainer.isOwner) {
                project.owner = projectUser;
            }
        }
    }


    private _createProjectUser(project:ProjectEntity, cloudUserId:string) : ProjectUserEntity {
        const entityContext = project.getContext();
        // TODO : move and convert cloudUserId into templateId
        const projectUser = entityContext.createEntity("ProjectUser", idGenerator.generateDeterministicId(project.id, cloudUserId, 'number'));
        projectUser.project = project;
        projectUser.cloudUserId = cloudUserId;
        return projectUser;
    }


    private _primaryToWorkflowRoleName(primaryRole:string) : string {
        // old way, should use UserProjectRight instead
        const ROLES : { [key:string]:string } = {
            "administrator": "ownerAssistant",
            "workpackage": "workpackage",
        };
        let workflowRole = ROLES[primaryRole];
        if (workflowRole == null) {
            this.logger.warn("[WorkflowManager] no role mapping defined | role:", primaryRole);
            workflowRole = primaryRole;
        }
        return workflowRole;
    }

    private _roleFromUserData(project:ProjectEntity, userData:UserDataJson) : ProjectUserRoleEntity {

        // if project has been upgraded with Workflow rights, use that
        const right = this._rightFromUserData(project, userData);
        if (right && right.workflowRole) {
            return right.workflowRole;
        }

        // else, fallback on old implementation
        let roleName : string = userData.roleData && userData.roleData.workflowRole;
        if (roleName == null) {
            roleName = this._primaryToWorkflowRoleName(userData.primaryRole);
        }

        const role : ProjectUserRoleEntity = fetchEntityByTemplateName(project.getContext(), "ProjectUserRole", roleName) as ProjectUserRoleEntity;
        if (role == null) { throw new WorkflowManagerError("Role not found | role:" + roleName); }

        if (right) {
            this.logger.warn("[WorkflowManager] autofixing user right | right:", right, "role:", role);
            right.workflowRole = role;
        }

        return role;
    }


    private _rightFromUserData(project:ProjectEntity, userData:UserDataJson) : ProjectUserRightEntity {
        if (!project.userRights.length) { // project not upgraded
            return null;
        }

        const right : ProjectUserRightEntity = fetchEntityByTemplateName(project.getContext(), "ProjectUserRight", userData.primaryRole) as ProjectUserRightEntity;
        if (right == null) { throw new WorkflowManagerError("Right not found | right:" + userData.primaryRole); }

        return right;
    }

}


// TODO @deprecated, use DI instead

export default new WorkflowUserManager(createLogger('WorkflowUserManager'));

