import { Observable } from 'rxjs';
import * as Task from '@core/services/task'; // deprecated Task type
import { adaptOldTask, Handler, ProgressObserver } from '@core/tasks/progress';
import { call, GeneratorType } from '@core/tasks/generator';

import { UnsupportedOperationError } from '@core/errors/errors-core';

import { 
    createAbortError,
    isAbortError,
    voidProgress,
    voidAbortSignal,
    observableToPromise,
    promiseToObservable,
    callAsAsync,
 } from '@archipad-js/core/async';

export {
    createAbortError, 
    isAbortError,
    voidProgress,
    voidAbortSignal,
    promiseToObservable,
    observableToPromise,
    callAsAsync,
}


/**
 * Adapter to call a task like function
 * 
 * @returns (outer function) abortable `Promise`
 * @param func (inner function) deprecated `Task`
 */
export async function callAsAsyncFromTask<T>(signal: AbortSignal, p: Handler, units: number, func: (task:Task.Task)=>Promise<T>) : Promise<T> {
    const result$ = p.oldTask(units, null, func);
    const resultValue = await observableToPromise(result$, signal);
    return resultValue;
}


/**
 * Adapter to call a task like function
 * 
 * @param task (outer function) deprecated `Task`
 * @param func (inner function) abortable `Promise`
 */
export async function callAsTask<T>(task: Task.Task|null, func: (signal: AbortSignal, po:ProgressObserver)=>Promise<T>): Promise<T> {
    Task.checkTask(task);

    let result : T;
    if (task) {
        const abortController = new AbortController();
        const { signal } = abortController;
    
        task.cancelPromise.then(function() {
            abortController.abort();
        }).catch(() => {});
        result = await adaptOldTask(task, (po:ProgressObserver) => 
            func(signal, po));
    }
    else {
        // NOTE: not abortable, no progress
        result = await func(voidAbortSignal(), voidProgress());
    }
    return result;
}

/**
 * Adapter to call a task like function
 * 
 * @returns (outer function) generator (`GeneratorType`)
 * @param func (inner function) abortable `Promise`
 */
export function* callAsGenerator<T>(p: Handler, units: number, func: (signal: AbortSignal, po:ProgressObserver)=>Promise<T>) : GeneratorType<T> {
    const result = yield* call( callAsObservable(p, units, func) );
    return result;
}


/**
 * Adapter to call a generator like function
 * 
 * @returns (outer function) abortable `Promise`
 * @param func (inner function) generator (`GeneratorType`)
 * 
 * @deprecated
 * Instead use `callAsAsync(...)` and wrap the function into `task(...)`,
 * which converts a generator function into an observable. 
 * 
 * ```
 *   const foo = await callAsAsync(signal, p, 1, (po) => task(function*() {
 *       const p = makeProgressHandler(po);
 *       p.total(1);
 *       return yield* bar(p.task(1));
 *   }));
 * ```
 */
 export function callAsAsyncFromGenerator<T>(signal: AbortSignal, p: Handler, units: number, func: (po:ProgressObserver)=>GeneratorType<T>) : Promise<T> {
    throw new UnsupportedOperationError("deprecated");
}

/**
 * Adapter to call a task like function
 * 
 * @returns (outer function) `Observable`
 * @param func (inner function) abortable `Promise`
 */
export function callAsObservable<T>(p: Handler, units: number, func: (signal: AbortSignal, po:ProgressObserver)=>Promise<T>) : Observable<T> {
    return promiseToObservable((signal: AbortSignal) => 
        callAsAsync(signal, p, units, (po:ProgressObserver) => 
            func(signal, po) ));
}