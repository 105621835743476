import { validate, validateSync, ValidatorOptions } from "class-validator";

export { validate, validateSync };

import { ValidationError } from "./core-validation.error";

const VALIDATION_ERROR_MESSAGE = "An error occurred during object validation";

export async function validateOrReject(object: object, validatorOptions?: ValidatorOptions): Promise<void> {
    const errors = await validate(object, validatorOptions);
    if (errors.length > 0) {
        const validationError = new ValidationError(errors, VALIDATION_ERROR_MESSAGE);
        throw validationError;
    }
}

/**
 * Custom implementation of a synchronous {@link validateOrReject}
 * @param object
 * @param validatorOptions
 */
export function validateOrRejectSync(object: object, validatorOptions?: ValidatorOptions): void {
    const errors = validateSync(object, validatorOptions);
    if (errors.length > 0) {
        const validationError = new ValidationError(errors, VALIDATION_ERROR_MESSAGE);
        throw validationError;
    }
}
