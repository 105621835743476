import { IllegalArgumentError } from '@core/errors/errors-core';
import { Task } from '@core/services/task';

import { PatchId, PatchNature } from '@archipad/backend/project/patch';

import {
    AppDBValue,
    getDB,
    LocalPatchData,
    LocalProjectInfo,
    startsWithIDBKeyRange,
} from '@ui-archipad/helpers/indexedDB';
import {PatchData} from "@archipad/backend/archiweb/synchroDriver";
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';

/*---------------------------------------------------------------------------*/
export async function listProjects(_task: Task | null, userId: number): Promise<ReadonlyArray<AppDBValue<'Projects'>>> {
    if (!userId) {
        throw new IllegalArgumentError("No user Id provided");
    }

    const db = await getDB();

    const userKeyPrefix = `${userId}|`;
    const userKeyPrefixKeyRange = startsWithIDBKeyRange(userKeyPrefix);

    const projects = await db.getAll('Projects', userKeyPrefixKeyRange);
    return projects;
}

export async function removeProject(_task: Task | null, userId: number, projectId: string, patchesOnly?: boolean): Promise<void> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId provided");
    }

    const db = await getDB();
    
    const projectIdPrefix = `${userId}|${projectId}`;

    const patchIdPrefixKeyRange = startsWithIDBKeyRange(projectIdPrefix);
    await db.delete('ProjectPatches', patchIdPrefixKeyRange);

    if (patchesOnly) {
        return;
    }

    const projectIdPrefixKeyRange = IDBKeyRange.only(projectIdPrefix);
    await db.delete('Projects', projectIdPrefixKeyRange);
}

export async function getProject(_task: Task | null, userId: number, projectId: string): Promise<AppDBValue<'Projects'> | null> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId provided");
    }

    const db = await getDB();

    const projectIdPrefix = `${userId}|${projectId}`;

    const project = db.get('Projects', projectIdPrefix);
    return project ?? null;
}

export async function setProjectInfo(userId: number, projectId: string, projectInfo: LocalProjectInfo): Promise<void> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId provided");
    }

    const db = await getDB();

    await db.put('Projects', {
        projectId: `${userId}|${projectId}`,
        info: projectInfo,
    });
}

export async function listProjectPatches(_task: Task | null, userId: number, projectId: string): Promise<ReadonlySet<string>> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId provided");
    }

    const db = await getDB();

    const patchIdPrefix = `${userId}|${projectId}|`;
    const patchIdPrefixKeyRange = startsWithIDBKeyRange(patchIdPrefix);

    const patchKeys = await db.getAllKeys('ProjectPatches', patchIdPrefixKeyRange);
    const patchIds = patchKeys.map((patchKeys) => patchKeys.substr(patchIdPrefix.length));

    return new Set(patchIds);
}

/**
 * Remove a patch from the project cache
 * 
 * ***Is forbidden to delete a dumpPatch*** via this API instead use {@link removeDumpPatch}
 */
export async function removePatchData(userId: number, projectId: string, patchId: string): Promise<void> {
    if (!userId || !projectId || !patchId) {
        throw new IllegalArgumentError("No userId or projectId or patchId provided");
    }

    const db = await getDB();

    const patchKey = `${userId}|${projectId}|${patchId}`;
    const patchData = await db.get('ProjectPatches', patchKey);
    if (!patchData) {
        return;
    }
    if (patchData.nature === PatchNature.DumpPatch || patchData.nature === PatchNature.LinkedEmptyPatch) {
        throw new IllegalArgumentError("Illegal attempt to delete a dumpPatch via removePatchData()");
    }
    return _removePatchData(patchKey);
}
async function _removePatchData(patchKey: string): Promise<void> {
    const db = await getDB();
    await db.delete('ProjectPatches', patchKey);
}
async function _removePatchesData(patchesKeys: string[]): Promise<void> {
    const db = await getDB();
    const tx = db.transaction('ProjectPatches', 'readwrite');

    const deleteActions = patchesKeys.map((patchKey) => tx.store.delete(patchKey));
    await Promise.all([
        ...deleteActions,
        tx.done,
      ]);
}
/**
 * @returns Return all deleted patches ID of the dumpPatch
 */
export async function removeDumpPatch(userId: number, projectId: string): Promise<string[]> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId or patchId provided");
    }

    const linkedDumpPatchesId = await getPatchesIdByNature(userId, projectId, PatchNature.LinkedEmptyPatch);
    const dumpPatchesId = await getPatchesIdByNature(userId, projectId, PatchNature.DumpPatch);
    const allPatchesId = Array.from([...linkedDumpPatchesId, ...dumpPatchesId]);
    const patchesKeys = allPatchesId.map((patchId) => `${userId}|${projectId}|${patchId}`);
    await _removePatchesData(patchesKeys);
    return allPatchesId;
}

export async function setPatchData<T extends PatchId>(userId: number, projectId: string, patchId: T, patchData: LocalPatchData<T>, patchNature: PatchNature): Promise<void> {
    if (!userId || !projectId || !patchId) {
        throw new IllegalArgumentError("No userId or projectId or patchId provided");
    }

    const db = await getDB();

    const patchKey = `${userId}|${projectId}|${patchId}`;
    await db.put('ProjectPatches', {
        patchId: patchKey,
        data: patchData,
        nature: patchNature,
    });
}

export async function setPatchesData(userId: number, projectId: string, progress: ProgressObserver,patchesData: PatchData[]): Promise<void> {
    if (!userId || !projectId) {
        throw new IllegalArgumentError("No userId or projectId provided");
    }
    const patchProgress = makeProgressHandler(progress);
    patchProgress.total(patchesData.length);

    const db = await getDB();
    const transaction = db.transaction('ProjectPatches','readwrite');
    const projectPatchesStore = transaction.store;
    const insertPromises: Promise<any>[] = patchesData.map(async (patchData, index) =>{
        const patchKey = `${userId}|${projectId}|${patchData.patchId}`;
        try {
            return projectPatchesStore.put({
                patchId: patchKey,
                data: { patch: patchData.patch, attachmentManifest: patchData.attachmentManifest },
                nature: patchData.patch[0].nature ? patchData.patch[0].nature : 'patchData',
            });
        }finally {
            patchProgress.units(index + 1);
        }
    });

    await Promise.all([...insertPromises,transaction.done]);
}


export async function getPatchData<T extends PatchId>(userId: number, projectId: string, patchId: T): Promise<{patch: LocalPatchData<T>, nature: PatchNature} | null> {
    if (!userId || !projectId || !patchId) {
        throw new IllegalArgumentError("No userId or projectId or patchId provided");
    }

    const db = await getDB();

    const patchKey = `${userId}|${projectId}|${patchId}`;
    const patch = await db.get('ProjectPatches', patchKey);

    if (!patch) {
        return null;
    }

    return {
        patch: patch.data as LocalPatchData<T>,
        nature: patch.nature,
    };
}

export async function getPatchesIdByNature( userId: number, projectId: string, nature: PatchNature ): Promise<Set<string>> {
    if (!userId || !projectId || !nature) {
        throw new IllegalArgumentError("No userId or projectId or nature provided");
    }
    const db = await getDB();

    const patchIdPrefix = `${userId}|${projectId}|`;

    const patchIdPrefixKeyRange = startsWithIDBKeyRange(patchIdPrefix);

    let patches = await db.getAll('ProjectPatches', patchIdPrefixKeyRange);

    patches = patches.filter((patch) => patch.nature === nature);

    //Extract patchId
    const patchIds = patches
        .map((patch) => patch.patchId)
        .map((patchKeys) => patchKeys.substr(patchIdPrefix.length));

    return new Set(patchIds);
}