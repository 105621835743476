/**
* MapEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionEntity } from "./RegionEntity";
import { MapVersionEntity } from "./MapVersionEntity";
import { MapLocationEntity } from "./MapLocationEntity";
import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "Map": MapEntity
    }
}

export abstract class MapEntity extends BaseEntity {

	 abstract mapId: string;
	 abstract filename: string;
	 abstract type: number;
	 abstract fileType: number;
	 abstract orientation: number;
	 abstract project: ProjectEntity;
	 abstract versions: ReadonlyArray<MapVersionEntity>;
	 abstract locations: ReadonlyArray<MapLocationEntity>;
	 abstract bugs: ReadonlyArray<BugEntity>;
	 abstract templateId?: string;
	 abstract typeOrderHint?: number;
	 abstract region?: RegionEntity;
	 abstract activeVersion?: MapVersionEntity;
	
}
