import "reflect-metadata";

import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";

import { callAsAsync, promiseToObservable, voidProgressHandler } from "@archipad-js/core/async";
import { DIID } from "@archipad-js/dependency-injection";

import { ArchipadRPCService, Archipad_RPC_Service_DIID } from "../rpc/archipad-rpc.service";
import { TrustedTimeService, Trusted_Time_Service_DIID } from "../trusted-time/trusted-time.service";
import { AuthenticationAPIError, TimeSkewAPIError } from "./archipad-authentication.service.error";
import { OAuthClientService, OAuth_Client_Service_DIID } from "@archipad-js/archipad/oauth-client-service";

export const Archipad_Auth_Service_DIID = DIID<ArchipadAuthService>("ArchipadAuthService");

/**
 * Service in charge of making authenticated requests to Archipad APIs.
 * It exposes a `requireLogin$` observable which is used to trigger an (auto)Login when needed.
 *
 * Optionally, a `forcedAuthToken` can be injected to force the use of a specific `AuthToken`.
 * It is needed to make requests during authentication process, when the `AuthToken`
 * is not yet propagated through the whole application.
 */
@injectable()
export class ArchipadAuthService {
    private readonly requireAccessTokenIfNeeded$: Observable<string | null>;

    constructor(
        @inject(Archipad_RPC_Service_DIID.symbol) private readonly archipadRpcService: ArchipadRPCService,
        @inject(Trusted_Time_Service_DIID.symbol) private readonly trustedTimeService: TrustedTimeService,
        @inject(OAuth_Client_Service_DIID.symbol) private readonly oAuthClientService: OAuthClientService,
    ) {
        // NOTE : This check is here to avoid S3 SDK RequestTimeTooSkewed which will lead to forbidden download on any S3 resource.
        if (Math.abs(this.trustedTimeService.serverDeltaTimeSeconds) > 15 * 60) {
            throw new TimeSkewAPIError();
        }

        this.requireAccessTokenIfNeeded$ = promiseToObservable(async () => {
            const canBeSilentlyAuthenticated = await this.oAuthClientService.canBeSilentlyAuthenticated();
            if (canBeSilentlyAuthenticated) {
                const accessToken = await this.oAuthClientService.getAccessToken();
                return accessToken;
            }
            throw new AuthenticationAPIError("accessToken could not be renewed.");
        }).pipe(share());
    }

    public async makeRequest(
        signal: AbortSignal,
        serviceName: string,
        methodName: string,
        methodParams?: Record<string, unknown>,
    ): Promise<unknown> {
        const p = voidProgressHandler();
        const accessToken = await callAsAsync(signal, p, 1, () => this.requireAccessTokenIfNeeded$);

        if (!accessToken) {
            throw new AuthenticationAPIError("Could not obtain an accessToken.");
        }

        const httpHeaders = {
            Authorization: `Bearer ${accessToken}`,
        };

        const result = await this.archipadRpcService.makeRequest(
            signal,
            serviceName,
            methodName,
            methodParams,
            httpHeaders,
        );
        return result;
    }
}
