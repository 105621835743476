export enum LogLevel {
    DEBUG = "DEBUG",
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
}

export interface Logger {
    /**
     * Prints a message to the Web console at the "debug" log level or is just
     * an alias for {@link console.log()} on Node.
     */
    debug(message?: any, ...optionalParams: any[]): void;

    /**
     * Prints a message to the Web console or is just an alias for
     * {@link console.log()} on Node.
     */
    info(message?: any, ...optionalParams: any[]): void;

    /**
     * Prints an error message to the Web console or to `stderr` with newline.
     */
    error(message?: any, ...optionalParams: any[]): void;

    /**
     * Prints a message to the Web console at the "warn" log level or is just
     * an alias for {@link console.error()} on Node.
     */
    warn(message?: any, ...optionalParams: any[]): void;

    /**
     * Checks if the given LogLevel is enabled.
     */
    isEnabled(level: LogLevel): boolean;

    /**
     * Enable or disable the given LogLevel
     */
    setEnabled(level: LogLevel, enabled: boolean): void;
}

export interface LoggerConfig extends SingleLoggerConfig {
    classes: Record<string, SingleLoggerConfig>;
}

export interface SingleLoggerConfig {
    DEBUG: boolean;
    INFO: boolean;
    WARN: boolean;
    ERROR: boolean;
}

type ConsoleLogFnType = Pick<Console, "debug" | "info" | "warn" | "error">;
export type ConsoleLogFnKeyType = keyof ConsoleLogFnType;

export type LogFunction = (name: string) => () => void;
