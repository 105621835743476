import { CoreError, AlertError, TechnicalErrorIdentifier, AlertErrorAction, IllegalStateError, LocalizedError } from '@core/errors/errors-core';
import { ParseError } from 'papaparse';

export const enum TechnicalErrorModule {
    // AWS
    AWS = 'network.AWS',
    AWS_S3 = 'network.AWS.S3',
    AWS_CHIME = 'network.AWS.CHIME',

    // Archipad Cloud
    BUG_CONTROLLER = 'BugController',
    CSV_REGION_COMPILER = 'CSVRegion.Compiler',
    CSV_REGION_IMPORTER = 'CSVRegion.Import',
    DEPENDENCIES_MANAGER = 'DependenciesManager',
    DIGIT_FORM = 'DigitForm',
    FILE_UPLOAD = 'FileUpload',
    INDEXED_DB = 'IndexedDB',
    INTERACTION = 'Interaction',
    ORM = 'ORM',
    MULTIPLE_FILE_UPLOAD = 'MultipleFileUpload',
    PATCHES = 'Patches',
    PLANNING = 'Planning',
    PROJECT = 'Project',
    PROJECT_CONTEXT = 'ProjectContext',
    PROJECT_TEMPLATE = 'ProjectTemplate',
    REMARK = 'Remark',
    REPORT = 'Report',
    REPORT_GENERATOR = 'ReportGenerator',
    REPORT_TEMPLATE = 'ReportTemplate',
    SYNCHRO_DRIVER = 'SynchroDriver',
    STRIPE = 'Stripe',
    UFFICIOWEB = 'Ufficioweb',
    WORKFLOW = 'Workflow',
    
    // Node
    REPORT_DOWNLOADER = 'ReportDownloader',
    REPORT_GENERATOR_RESOURCE_MANAGER = 'ReportGenerator.ResourceManager',
    GRIMMO_SQL_CLIENT = 'GrImmoConnector.SQLClient',
    ARCHI_SWAP = 'ArchiSwap.Fatal',
}

// Workflow
export class BugWorkflowDeleteError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.ORM,
        'BUG_WORKFLOW_DELETE',
    );

    constructor() {
        super(
            l("Unexpected error"),
            [l("If the problem persists, please contact support team with the following information:"), 'BUG_WORKFLOW_DELETE'],
            AlertErrorAction.BACK_TO_PROJECT_LIST,
        );
    }
}

export class WorkflowCreationError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.WORKFLOW,
        'WORKFLOW_CREATION_ERROR',
    );
}

export class WorkflowManagerError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.WORKFLOW,
        'WORKFLOW_MANAGER_ERROR',
    );
}

// Report
export class ReportTemplateNotFound extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_TEMPLATE,
        'REPORT_TEMPLATE_NOT_FOUND',
    );

    constructor() {
        super(
            l("Report template not found"),
            'REPORT_TEMPLATE_NOT_FOUND',
        );
    }
}

export class ReportTemplateProcessError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_TEMPLATE,
        'REPORT_TEMPLATE_PROCESS_ERROR',
    );

    constructor() {
        super(
            l("Error while loading report template"),
            [
                l("If the problem persists, please contact support team with the following information:"),
                'REPORT_TEMPLATE_PROCESS_ERROR',
            ]
        );
    }
}

export abstract class ReportTemplatePermissionDeniedError extends AlertError {
    constructor(reportTemplateName: string, message: string) {
        super(
            l('Unable to save "%1"', { '%1' : reportTemplateName}),
            message,
        );
    }
}

export class ReportTemplateDeletionDeniedError extends ReportTemplatePermissionDeniedError {
    public static readonly API_ERROR_CODE = 16331;

    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_TEMPLATE,
        'DELETION_DENIED',
    );
}

export class ReportTemplateUpdateDeniedError extends ReportTemplatePermissionDeniedError {
    public static readonly API_ERROR_CODE = 16332;

    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_TEMPLATE,
        'UPDATE_DENIED',
    );
}

// MUST cmd + MAJ + F
export class ReportBeingGeneratedError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_GENERATOR,
        'REPORT_BEING_GENERATED_ERROR',
    );
}

// Synchro / patches / entity
export class SynchroDriverError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.SYNCHRO_DRIVER,
        'SYNCHRO_DRIVER_ERROR',
    );
}

export class PatchError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PATCHES,
        'PATCH_ERROR',
    );
}

export class PatchTooOldError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PATCHES,
        'PATCH_TOO_OLD',
    );
}

export class ReadEmptyPatchSetError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PATCHES,
        'READ_EMPTY_PATCHSET_ERROR',
    );

    constructor() {
        super(
            l("Could not open this project"),
            [
                l("If the problem persists, please contact support team with the following information:"),
                'EMPTY_PATCHSET_ERROR'
            ],
            AlertErrorAction.BACK_TO_PROJECT_LIST,
        );
    }
}

export class WriteEmptyPatchSetError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PATCHES,
        'WRITE_EMPTY_PATCHSET_ERROR',
    );

    constructor() {
        super(
            l("Internal Error"),
            l("The current page must be reloaded to solve an internal error. Please click the Reload button to allow Archipad to recover from this problem."),
            AlertErrorAction.RELOAD,
        );
    }
}

export class TempPatchConflictError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PATCHES,
        'TEMP_PATCH_CONFLICT_ERROR',
    );
}



export class ProjectContextError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT_CONTEXT,
        'PROJECT_CONTEXT_ERROR',
    );
}

// Project template
export class ProjectTemplateError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT_TEMPLATE,
        'PROJECT_CONTEXT_ERROR',
    );
}

// Project template
export class EmptyProjectTemplateError extends ProjectTemplateError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT_TEMPLATE,
        'EMPTY_PROJECT_TEMPLATE_ERROR',
    );
}

export class VisitTypeSystemMissingError extends AlertError {
    public static identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT,
        'VISIT_TYPE_SYSTEM_MISSING',
    );

    constructor() {
        super(
            l("Unexpected error"),
            [
                l("If the problem persists, please contact support team with the following information:"),
                VisitTypeSystemMissingError.identifier.toString(),
            ],
            AlertErrorAction.BACK_TO_PROJECT_LIST,
        );
    }
}

export class BugError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.BUG_CONTROLLER,
        'BUG_ERROR',
    );
}

export class DependenciesManagerError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.DEPENDENCIES_MANAGER,
        'DEPENDENCIES_ERROR',
    );
}

export class MissingDependenciesError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.DEPENDENCIES_MANAGER,
        'DEPENDENCIES_ERROR',
    );

    constructor(missingDependencies: string[]) {
        super(
            l("Missing components"),
            l("Unable to install all required components. Please contact support to install %1", {'%1': missingDependencies.join(', ')}),
            AlertErrorAction.BACK_TO_PROJECT_LIST,
        );

        this.addContext('Archipad', { 'missingDependencies': missingDependencies });
    }
}

export class SQLClientError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.GRIMMO_SQL_CLIENT,
        'SQL_CLIENT_ERROR',
    );
}

export class ReportDownloaderError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_DOWNLOADER,
        'REPORT_DOWNLOADER_ERROR',
    );
}

// Resource Manager
export class ResourceManagerError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT_GENERATOR_RESOURCE_MANAGER,
        'RESOURCE_MANAGER_ERROR',
    );
}

/**
 * @deprecated
 */export class TimeoutError extends CoreError {}

/**
 * @deprecated
 */
export class EnsureTimeoutError extends CoreError {}

/**
 * @deprecated
 */
export class NoSuchElementError extends CoreError {}

export class FileUploadError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.FILE_UPLOAD,
        'FILE_UPLOAD_ERROR',
    );
}

export class MultipleFileUploadError extends CoreError {

    files: {filename: string, error: string}[]

    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.MULTIPLE_FILE_UPLOAD,
        'MULTIPLE_FILE_UPLOAD_ERROR',
    );

    constructor (files: {filename: string, error: string}[]) {
        super()
        this.files = files
    }
}

export class InteractionError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.INTERACTION,
        'INTERACTION_ERROR',
    );

    public fromCache = false;
}

export class NotAuthorizedInteractionError extends InteractionError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.INTERACTION,
        'NOT_AUTHORIZED',
    );
}

export class GoPremiumInteractionError extends InteractionError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.INTERACTION,
        'GO_PREMIUM',
    );
}


/**
 * Transfer Error
 * - In admin panel
 */
export class TransferError extends AlertError {
    constructor(message: string){
        super(
            'Impossible de transférer le projet',
            message,
        );
    }
}

export class DigitformError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.DIGIT_FORM,
        'DIGIT_FORM_ERROR',
    );
}

export class MissingFormResourceError extends AlertError {

    constructor(resourceName: string) {
        super(
            l("Cannot create form"),
            [
                l('Cannot find the form definition : %1', { '%1': resourceName } ),
                'MISSING_FORM_RESOURCE_ERROR',
            ],
        );

        this.identifier = new TechnicalErrorIdentifier(
            TechnicalErrorModule.DIGIT_FORM,
            'MISSING_FORM_RESOURCE_ERROR',
        );
    }
}

export class RemarkError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REMARK,
        'REMARK_ERROR',
    );
}

export class S3Error extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.AWS_S3,
        'S3_ERROR',
    );
}

export class ProjectNotFound extends AlertError {
    public static readonly API_ERROR_CODE = 16308;

    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PROJECT,
        'PROJECT_NOT_FOUND',
    );

    constructor() {
        super(
            l("Project not found"),
            l("The requested project could not be found"),
            AlertErrorAction.BACK_TO_PROJECT_LIST,
        );
    }
}

export class StripeRejectionError extends AlertError {
	constructor(message: string, code: string) {
        super(
            l("Error during payment"),
            [
                message,
                l("If the problem persists, please contact support team with the following information:"),
                code,
            ],
        );

        this.identifier = new TechnicalErrorIdentifier(
            TechnicalErrorModule.STRIPE,
            code,
        );
	}
}

export class BlockingUpgradeIndexedDBError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.INDEXED_DB,
        'CONNECTION_BLOCKING_UPGRADE',
    );

    constructor() {
        super(
            l("Database changed in another page"),
            [
                l("Running database version is out of date."),
                l("Please reload this tab"),
            ],
            AlertErrorAction.RELOAD,
        );
    }
}

export class UpgradeBlockedIndexedDBError extends AlertError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.INDEXED_DB,
        'CONNECTION_BLOCKED_UPGRADE',
    );

    constructor() {
        super(
            l("Database blocked by another tab"),
            [
                l("Archipad Cloud wants to perform an upgrade but is blocked by another page."),
                l("Please close other tabs running Archipad Cloud"),
            ],
        );
    }
}

export class CorruptedReportError extends CoreError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.REPORT,
        'CORRUPTED_REPORT',
    );
}

/**
 * CSV Region Importer
 */
export abstract class CSVRegionImporterError extends AlertError {
    constructor() {
        super(
            `Impossible d'importer le fichier`,
            `Les données du fichier CSV que vous avez sélectionné ne sont pas dans un format qu'Archipad peut importer. Merci de vérifier le contenu de votre fichier ou de contacter le support Archipad pour obtenir le bon format.`,
            AlertErrorAction.CANCEL,
        );
    }
}

export abstract class CSVRegionCompilerError extends CSVRegionImporterError {}

export class ProjectCodeOperationMismatchCSVRegionImporterError extends CSVRegionImporterError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_IMPORTER,
        'PROJECT_CODE_OPERATION_MISMATCH',
    );
}

export class MultipleExistingRegionsCSVRegionImporterError extends CSVRegionImporterError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_IMPORTER,
        'MULTIPLE_EXISTING_REGIONS',
    );
}

export class CSVRegionCompilerParseError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'PARSE_FAIL',
    );

    constructor(...csvParseErrors: ParseError[]) {
        super();
        this.message = csvParseErrors.map(parseError => parseError.message).join('\n');
    }
}

export class ProjectCodeOperationMismatchCSVRegionCompilerError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'PROJECT_CODE_OPERATION_MISMATCH',
    );
}

export class DuplicateColumnsCSVRegionCompilerError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'DUPLICATE_COLUMNS',
    );
}

export class MissingColumnsCSVRegionCompilerError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'MISSING_COLUMNS',
    );
}

export class UnknownColumnsCSVRegionCompilerError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'UNKNOWN_COLUMNS',
    );
}

export class DuplicateRowsCSVRegionCompilerError extends CSVRegionCompilerError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.CSV_REGION_COMPILER,
        'DUPLICATE_ROWS',
    );
}

/**
 * Planning
 */

export abstract class PlanningError extends CoreError {
    public planningMasterEmail: string | null;

    constructor(message: string, planningMasterEmail: string | null) {
        super(message);
        this.planningMasterEmail = planningMasterEmail;
    }
}

export class PlanningForbiddenEventCreationError extends PlanningError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PLANNING,
        'FORBIDDEN_EVENT_CREATION',
    );
}

export class PlanningForbiddenEventUpdateError extends PlanningError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PLANNING,
        'FORBIDDEN_EVENT_UPDATE',
    );
}

export class PlanningEventDeletionForbiddenError extends PlanningError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PLANNING,
        'FORBIDDEN_EVENT_DELETION',
    );
}

export class PlanningMasterForbiddenUpdateError extends PlanningError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModule.PLANNING,
        'FORBIDDEN_PLANNING_MASTER_UPDATE',
    );
}

/**
 * This error appears when the map we use in the current context is deleted
 */
 export class CurrentMapDeleted extends IllegalStateError { }

 export class CurrentMapAttachmentDeleted extends LocalizedError {
    constructor() {
        super(
            l("\"File error\": Please import the plan again."),
        );
    }
}