import { isPlainObject, isRecord } from "@archipad-js/core/utils";
import { IllegalArgumentError } from "@archipad-js/core/error";

import * as AspectPathParser from "./parsers/aspectPath";

import { AspectPath, BuiltInOperator, CustomOperator, IdentifierAspectPath } from "./config.types";

export function parsePath(pathStr: string): AspectPath[] {
    return AspectPathParser.parse(pathStr);
}

export function isIdentifierAspectPath(aspectPath: AspectPath): aspectPath is IdentifierAspectPath {
    return aspectPath.type === "ident";
}

export function isBuiltInOperator(object: unknown): object is BuiltInOperator {
    return isRecord(object) && typeof object["str"] === "string";
}

export function isCustomOperator(object: unknown): object is CustomOperator {
    return isRecord(object) && typeof object["func:"] === "string";
}

const OperatorRegExp = /^([a-zA-Z0-9_-]*):([\s\S]+)$/;
export function applyOperators(
    object: unknown,
    fn: (name: string, args: BuiltInOperator | CustomOperator) => unknown,
): unknown {
    if (Array.isArray(object)) {
        const clone = [...object];
        for (let i = 0; i < object.length; i++) {
            const o = applyOperators(object[i], fn);
            if (object[i] !== o) {
                clone[i] = o;
            }
        }
        return clone;
    }

    if (!isRecord(object)) {
        return object;
    }

    if (isCustomOperator(object)) {
        const name = object["func:"];
        return fn(name, object);
    } else if (isPlainObject(object)) {
        const clone = { ...object };
        for (const k in object) {
            let value = object[k];
            if (typeof value === "string") {
                const ret = value.match(OperatorRegExp);
                if (ret) {
                    const name = ret[1];
                    const args = { str: ret[2] };
                    value = fn(name, args);
                    if (value !== args) {
                        clone[k] = value;
                    }
                }
            } else if (typeof value === "object") {
                const o = applyOperators(object[k], fn);
                if (o !== object[k]) {
                    clone[k] = o;
                }
            }
        }
        return clone;
    }

    throw new IllegalArgumentError(
        "Given object is not an array or plain object",
        "object",
        "array or plain object",
        JSON.stringify(object),
    );
}
