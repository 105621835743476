/**
* FormTypeEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { FormTypeVersionEntity } from "./FormTypeVersionEntity";


declare global {
    interface EntityTypeMap {
        "FormType": FormTypeEntity
    }
}

export abstract class FormTypeEntity extends BaseEntity {

	 abstract formTypeId: string;
	 abstract name: string;
	 abstract project: ProjectEntity;
	 abstract versions: ReadonlyArray<FormTypeVersionEntity>;
	 abstract templateId?: string;
	 abstract containsConformityWithObservation?: boolean;
	 abstract hidden?: boolean;
	 abstract activeVersion?: FormTypeVersionEntity;
	
}
