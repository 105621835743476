import * as rpc from '@archipad/backend/rpc/authenticated';
import * as AuthenticatedRpc from '@archipad/backend/rpc/abortable/authenticated';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { Task } from '@core/services/task';
import { callAsAsync } from '@core/tasks/asyncCaller';
import {IndependentS3Description, S3PathDescription} from "@core/services/file/s3file";
import * as t from "io-ts";
import * as Authenticated from '@archipad/backend/rpc/abortable/authenticated';

/**
 * project info returned by project/find API
 */
export interface RemoteListProjectInfo {
    id: string;
    lastPatchId:string;
    name: string
    creationDate: number;
    lastModificationDate: number;
    owned: boolean;
    photoUrl: string;
    bugWorkflow: string;
    dependencies: ReadonlyArray<string>;
}

/**
 * a project info update, used by updateProject()
 */
export interface UpdateRemoteProjectInfo {
    id:string;
    dependencies: readonly string[];
    name:string;
}

/**
 * project info returned by project/get API
 */
export interface RemoteProjectInfo {
    id: string;
    lastPatchId:string;
    name: string
    creationDate: number;
    creatorEmail: string;
    creatorId: number;
    deletionDate: any;
    lastModificationDate: number;
    owned: boolean;
    bugWorkflow: boolean;
    photoPath: string;
    templateUpdateAvailable?: boolean;
    dependencies: ReadonlyArray<string>;

    credentials: S3Creds;

    patches: S3PathDescription;
    temporary: S3PathDescription;
    template?: S3PathDescription;
    reportGeneration: S3PathDescription;
    upload: S3PathDescription & {
		policy: string;
		signature: string;
	}
    projectCaches: IndependentS3Description;
}

/**
 * Information related to the storage of a project model
 * Returned by projectTemplate/get API
 * @example
 * {
 *      bucket: "dev-archipad-services-us",
 *      region: "us-east-1",
 *      baseURI: "0-archipad-assets/dependencies/oteis_user",
 *      path: "oteis_user.zip",
 *      type: "DEPENDENCY",
 *      "credentials": {[...]}
 * }
 */
export interface ProjectTemplateStorageInfo {
    
    bucket: string;
	region: string;
    baseURI: string;
    path: string;
    /**
     * `USER` refers to project templates created by users from an existing project
     * 
     * `DEPENDENCY` designates the project models contained in the dependency packages - ex: tokheim_user
     */
    type: "DEPENDENCY" | "USER";
    credentials: S3Creds;
}

export interface ProjectTemplateRelation {
    id: string;
    bundleId: string;
    lastVersionId: string;
    originTemplateIds: string;
}

export const S3Creds = t.type({
    AccessKeyId: t.string,
    SecretAccessKey: t.string,
    SessionToken: t.string,
    Expiration: t.string,
});

export type S3Creds = t.TypeOf<typeof S3Creds>;

export interface StorageInfo {
	id: string;
	getSynchronizedTime: any;
	credentials: S3Creds;
	creds: S3Creds;
	reportTemplatesImagesPrefix?: string;
	bucket: string;
	region: string;
	path: string;
    projectCaches: IndependentS3Description;
	upload: {
		policy: string;
		signature: string;
		path: string;
		region: string;
		bucket: string;
	}
}

/*---------------------------------------------------------------------------*/
export function addProject(task:Task, projectId:string, projectName:string): Promise<unknown> {
	// TODO: check call
	return rpc.makeAuthenticatedRequest(task, 'synchro', 'addProject', { id:projectId, name:projectName });
}

export function createProject(task:Task, projectId: string, projectName: string, templates?: string[]): Promise<unknown> {
    // TODO: check call
    const params = {id:projectId, name:projectName};
    if (templates) {
        params['templates'] = templates;
    }
    return rpc.makeAuthenticatedRequest(task, 'project', 'create', params);
}

export async function updateProject(signal: AbortSignal, progress: ProgressObserver, projectInfo:UpdateRemoteProjectInfo, patchId:string|null) : Promise<unknown> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const params: any = {
        id: projectInfo.id
    };

    if (projectInfo.dependencies !== undefined) {
        params.bugWorkflow = projectInfo.dependencies.indexOf('workflow') !== 1;
        params.dependencies = projectInfo.dependencies;
    }

    if ( projectInfo.name )
        params.name = projectInfo.name;

    if ( patchId )
        params.patchId = patchId;

    const result = await callAsAsync(signal, p, 1, (po) => AuthenticatedRpc.makeAuthenticatedRequest(signal, po, 'project', 'update', params));
    return result;
}

export async function synchronizeProject(signal: AbortSignal, progress: ProgressObserver, projectInfo:UpdateRemoteProjectInfo, patchId:string|null) : Promise<unknown> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const params: any = {
        id: projectInfo.id
    };

    if (projectInfo.dependencies !== undefined) {
        params.bugWorkflow = projectInfo.dependencies.indexOf('workflow') !== 1;
        params.dependencies = projectInfo.dependencies;
    }

    if ( projectInfo.name )
        params.name = projectInfo.name;

    if ( patchId )
        params.patchId = patchId;

    const result = await callAsAsync(signal, p, 1, (po) => AuthenticatedRpc.makeAuthenticatedRequest(signal, po, 'project', 'synchronize', params));
    return result;
}

export function isProjectSyncAllowed(task:Task, projectId:string) {
	console.warn("DEPRECATED API isProjectSyncAllowed, use getProjectInfo() instead");
    return rpc.makeAuthenticatedRequest(task, 'synchro', 'isProjectSyncAllowed', { id:projectId });
}

export async function getProjectInfo(task:Task, params: {id: string, templateId?: string, ignoreRestrictions?: boolean}): Promise<RemoteProjectInfo> {
    const result = await rpc.makeAuthenticatedRequest(task, 'project', 'get', params);
    return {
        ...result,
        id: params.id,
        dependencies: result.dependencies.map(d => d.bundleId),
    };
}

export async function listProjects(task:Task, params?): Promise<ReadonlyArray<RemoteListProjectInfo>> {
    const projectInfos = await rpc.makeAuthenticatedRequest(task, 'project', 'find', params);
    return projectInfos.map(projectInfo => ({
        ...projectInfo,
        dependencies: projectInfo.dependencies.map(d => d.bundleId),
    }));
}

export function deleteProjects(task:Task, projectIds:string[]): Promise<unknown> {
    const params = [];
    for ( let i = 0; i < projectIds.length; i++ )
        params.push({
            id: projectIds[i]
        });
    return rpc.makeAuthenticatedRequest(task, 'project', 'delete', { projects: params });
}

export function getStorageInfo(task:Task, params): Promise<StorageInfo> {
    return rpc.makeAuthenticatedRequest(task, 'storage', 'getInfo', params).then(function(result) {
        result.creds = result.credentials;
        
        return result;
    });
}

export function restoreProjects(task:Task, projectIds:string[]): Promise<unknown> {
    const params = projectIds.map( id => ({id:id}));
    return rpc.makeAuthenticatedRequest(task, 'project', 'restore', { projects: params });
}

export async function getProjectTemplateStorageInfo(signal: AbortSignal, progress:  ProgressObserver, params: {id: string}): Promise<ProjectTemplateStorageInfo> {
    const result = await Authenticated.makeAuthenticatedRequest<ProjectTemplateStorageInfo>(signal, progress, 'projectTemplate', 'get', params);
    return result;
}

export async function resolveDeeplyProjectTemplateRelation(signal: AbortSignal, progress:  ProgressObserver, params: {id: string}): Promise<ProjectTemplateRelation[]> {
    const result = await Authenticated.makeAuthenticatedRequest<{relations: Record<string, ProjectTemplateRelation>}>(signal, progress, 'projectTemplate', 'resolveDeeplyProjectTemplateRelation', params);

    const relations = result.relations;
    const projectTemplateIds = Object.keys(relations);

    const formatedRelations = [];
    for ( const id of projectTemplateIds ) {
        const relation = relations[id];
        const formatedRelation = {
            id: id,
            bundleId: relation.bundleId,
            lastVersionId: relation.lastVersionId,
            originTemplateIds: relation.originTemplateIds,
        };
        formatedRelations.push(formatedRelation);
    }
    return formatedRelations;
}