import { Observer as RxObserver, Observable } from "rxjs";

import { CoreError, IllegalStateError } from "@archipad-js/core/error";
import { LoggerManager } from "@archipad-js/core/logger";

const log = LoggerManager.getLogger("Progress");

export class ProgressError extends CoreError {}

interface TaskProgress {
    units: number;
    progress: number | null;
}

/*---------------------------------------------------------------------------*/

/** Task has a label  */
export class LabelProgressMessage {
    type: "label";
    message: string;

    constructor(message: string) {
        this.type = "label";
        this.message = message;
    }
}
/** Task is progressing  */
export class ProgressProgressMessage {
    type: "progress";
    progress: number | null; /** null for undetermined or any value between 0 and 1 */

    constructor(progress: number | null) {
        this.type = "progress";
        this.progress = progress;
    }
}
/** Task failed  */
export class ErrorProgressMessage {
    type: "error";
    error: Error;

    constructor(error: Error) {
        this.type = "error";
        this.error = error;
    }
}
/** Task started */
export class StartProgressMessage {
    type: "start";

    constructor() {
        this.type = "start";
    }
}
/** Task completed */
export class ShowTimeProgressMessage {
    type: "showTime";

    constructor() {
        this.type = "showTime";
    }
}

export class PauseProgressMessage {
    type: "pause";

    constructor() {
        this.type = "pause";
    }
}

export class ContinueProgressMessage {
    type: "continue";

    constructor() {
        this.type = "continue";
    }
}

export type ProgressMessage =
    | LabelProgressMessage
    | ProgressProgressMessage
    | ErrorProgressMessage
    | StartProgressMessage
    | ShowTimeProgressMessage
    | PauseProgressMessage
    | ContinueProgressMessage;

/*---------------------------------------------------------------------------*/

/**
 * Task progress: DO and DON'T
 *
 * Don't pass a progress Handler to another function, don't use a progress Handler outside a function scope:
 * ```js
 * // DONT DO THIS:
 * class {
 *     myTask(progress:ProgressObserver) {
 *         const p = makeProgressHandler(progress);
 *         p.total(2);
 *
 *         this._mySubTask1(p); // Error: passing a Handler
 *         this._mySubTask2(p);
 *     }
 *
 *     private _mySubTask1(progress:Progress.Handler) {
 *         ...
 *         progress.units(1);
 *     }
 *
 *     private _mySubTask2(progress:Progress.Handler) {
 *         ...
 *         progress.units(2);
 *     }
 *
 *     ...
 * }
 *
 * // DO THIS:
 * class {
 *     myTask(progress:ProgressObserver) {
 *         const p = makeProgressHandler(progress);
 *         p.total(2)
 *
 *         this._mySubTask1(p.task(1));
 *         this._mySubTask2(p.task(1));
 *     }
 *
 *     private _mySubTask1(progress:Progress.Observable) {
 *         const p = makeProgressHandler(progress);
 *         p.total(1);  // I can define my own sub-total now
 *         ...
 *         p.units(1);
 *     }
 *
 *     private _mySubTask2(progress:Progress.Observable) {
 *         const p = makeProgressHandler(progress);
 *         p.total(1);
 *         ...
 *         p.units(1);   // I don't depend on the other's tasks progress
 *     }
 *
 *     ...
 * }
 * ```
 *
 * The progress works with multiple sub tasks executed in parallel:
 * ```js
 * function myTask(progress:ProgressObserver) {
 *     const p = makeProgressHandler(progress);
 *     p.total(4);
 *
 *     return forkJoin([
 *         this._mySubTask1(p.task(1)),
 *         this._mySubTask2(p.task(1)),
 *         this._mySubTask2(p.task(1)),
 *         this._mySubTask2(p.task(1))
 *     ]);
 * }
 * ```
 *
 * You can handle the progress with a undetermined number of sub tasks to execute like this:
 * ```js
 * function myTask(progress:ProgressObserver, list:Items) {
 *     const p = makeProgressHandler(progress);
 *
 *     const tasks:Observable<unknown>[] = [];
 *     for(item in items) {
 *         // Add the task to the list. Calling p.task() before p.total() is ok here
 *         // because the task is not running yet.
 *         tasks.push(processItem(p.task(1), item));
 *     }
 *
 *     // set the total now
 *     p.total(tasks.length);
 *
 *     // run the tasks, in sequence or in parallel
 *     return doExecute(tasks);
 * }
 * ```
 */

/**
 * Task progress handler, used to report progress on observable tasks.
 * ```js
 * function* doTask(progress:ProgressObserver) {
 *     const p = makeProgressHandler(progress);
 *     p.label(l('Loading'));
 *
 *     // you must assign the total before you start progressing
 *     p.total(125);
 *
 *     // Progressing here. Note that you can interleave between your progress and the sub tasks progress.
 *     // p.units() must be between 0 and p.total() - (the sum of p.task())
 *     // So in this case: between 0 and 125 - (25 + 25 + 25) = 50
 *     p.units(0);
 *     ...
 *     p.units(15);
 *     ...
 *
 *     // execute a sub task
 *     yield* call(mySubTask(p.task(25), ...))
 *     ...
 *
 *     // I've finished my progress but I stil need to run some
 *     // sub tasks
 *     p.units(50);
 *
 *     // executing another sub task
 *     yield* call(myOtherSubTask(p.task(25), ...));
 *
 *     // compatibility with promises and oldTasks
 *     yield* call(p.oldTask(25, "My task", async (task) => {
 *         await myAsyncFunctionHere(task, ...);
 *     }));
 * }
 * ```
 */
export class ProgressHandler {
    protected _progress: RxObserver<ProgressMessage> | null;

    /* completed units for sub tasks */
    protected _subTasks: TaskProgress[];

    /* current progress */
    private _current: number | null;

    private _label: string | null;

    /* total units for current task */
    private _total: number;
    /* completed for current task */
    private _units: number;

    constructor(progress: RxObserver<ProgressMessage> | null) {
        this._progress = progress;
        this._current = null;
        this._label = null;
        this._total = 0;
        this._units = 0;
        this._subTasks = [];
    }

    private _getProgress() {
        if (!this._total) {
            return null; // undetermined
        }
        let units = this._units;
        if (this._subTasks.length > 0) {
            for (const subTask of this._subTasks) {
                units += (subTask.progress ?? 0) * subTask.units;
            }
        }
        return Math.round((units * 100.0) / this._total);
    }

    protected _progressChanged() {
        if (!this._progress) {
            return;
        }

        let p = this._getProgress();
        if (p !== this._current) {
            this._current = p;
            if (p !== null) {
                p /= 100.0;
                if (p > this._total) {
                    log.warn("task progress goes beyond task total !", p, this._total);
                }
            }
            this._progress.next(new ProgressProgressMessage(p));
        }
    }

    /**
     * Set the task label
     * @param label current task label
     */
    label(label: string): void {
        if (!this._progress) {
            return;
        }
        this._label = label;
        this._progress.next(new LabelProgressMessage(label));
    }
    /**
     * Set the task progress total units including the sub tasks
     * @param totalUnits total number of units for this task
     */
    total(totalUnits: number): void {
        if (totalUnits < 0) {
            throw new ProgressError("Total cannot be negative");
        }
        this._total = totalUnits;
        this._units = 0;
        this._subTasks = [];
        this._progressChanged();
    }

    /**
     * Pause progress reporting, useful for hiding the progress ui
     */
    pause(): void {
        if (!this._progress) {
            return;
        }
        this._progress.next(new PauseProgressMessage());
    }

    /**
     * Resume progress reporting
     */
    continue(): void {
        if (!this._progress) {
            return;
        }
        this._progress.next(new ContinueProgressMessage());
    }

    /**
     * Set the number of completed units
     * @param units number of units completed for this task
     */
    units(units: number): void {
        this._units = units;
        this._progressChanged();
    }

    /**
     * Create a progress observer for a sub task
     * @param units number of units completed in this progress handler when the sub task completes
     */
    task(units: number): RxObserver<ProgressMessage> | null {
        if (!this._progress) {
            return null;
        }

        const subTask: TaskProgress = { units: units, progress: 0 };
        this._subTasks.push(subTask);

        return {
            next: (message) => {
                if (this._progress === null) {
                    throw new IllegalStateError("Unreacheable code");
                }
                switch (message.type) {
                    case "label":
                        {
                            if (!this._label) {
                                this._progress.next(message);
                            }
                        }
                        break;

                    case "pause":
                    case "continue":
                        {
                            this._progress.next(message);
                        }
                        break;

                    case "progress":
                        {
                            // TODO Maybe the wrong way, we probably only want subTask.progress to be null
                            subTask.progress = message.progress;
                            this._progressChanged();
                        }
                        break;
                }
            },
            error: (err) => log.error("something wrong occurred: " + err),
            complete: () => log.info("done"),
        };
    }

    /**
     * Executes an async task and completes a given number or progress units.
     *
     * @example
     * ```typescript
     *  await p.executeTask( 40, async ( _po ) => {
     *      await asynCall();
     *  } );
     * ```
     */
    async executeTask<T>(unites: number, func: (po: ProgressObserver | null) => Promise<T>): Promise<T> {
        const progress = this.task(unites);
        const resultValue = await func(progress);

        if (progress) {
            progress.next(new ProgressProgressMessage(1.0));
        }

        return resultValue;
    }
}

export function makeProgressHandler(progress: RxObserver<ProgressMessage> | null): ProgressHandler {
    return new ProgressHandler(progress) as unknown as ProgressHandler;
}

/**
 * @Example
 * ```typescript
 *  const p = makeProgressHandler(progress);
 *  p.total(1);
 * ```
 */
export type ProgressObserver = RxObserver<ProgressMessage>;
/*---------------------------------------------------------------------------*/

/**
 * A mediator class between a task that reports the progress on an observer and
 * the ui that watches the progress
 */
export class ProgressSubject {
    private _observers: RxObserver<ProgressMessage>[];
    private _isRunning: boolean;
    private _nbPaused: number;
    private _isShowTime: boolean;
    private _label: string | null;
    private _progress: number | null;
    private _error: Error | null;

    readonly observer: RxObserver<ProgressMessage>;
    readonly observable: Observable<ProgressMessage>;

    constructor() {
        this._observers = [];
        this._isRunning = false;
        this._nbPaused = 0;
        this._isShowTime = true;
        this._label = null;
        this._progress = null;
        this._error = null;

        this.observer = {
            next: (message) => {
                switch (message.type) {
                    case "label":
                        {
                            this._label = message.message;
                        }
                        break;
                    case "progress":
                        {
                            this._progress = message.progress;
                        }
                        break;
                    case "error":
                        {
                            this._isRunning = false;
                            this._isShowTime = false;
                            this._progress = null;
                            this._error = message.error;
                        }
                        break;
                    case "start":
                        {
                            if (this._isRunning) {
                                throw new ProgressError("Task is already running");
                            }
                            this._isRunning = true;
                            this._isShowTime = false;
                            this._progress = null;
                            this._error = null;
                        }
                        break;
                    case "showTime":
                        {
                            if (!this._isRunning) {
                                throw new ProgressError("Task is not running");
                            }
                            this._isRunning = false;
                            this._isShowTime = true;
                            this._progress = null;
                            this._error = null;
                        }
                        break;
                    case "pause":
                        {
                            if (!this._isRunning) {
                                throw new ProgressError("Task is not running");
                            }
                            this._nbPaused++;
                        }
                        break;
                    case "continue":
                        {
                            if (this._nbPaused === 0) {
                                throw new ProgressError("Calling continue on a task that is not paused");
                            }
                            this._nbPaused--;
                        }
                        break;
                }

                const observers = this._observers.slice();
                for (const observer of observers) {
                    if (message.type === "continue") {
                        if (this._nbPaused === 0) {
                            observer.next(message);
                        }
                    } else if (message.type === "pause") {
                        if (this._nbPaused > 0) {
                            observer.next(message);
                        }
                    } else {
                        observer.next(message);
                    }
                }
            },
            error: (err) => {
                const observers = this._observers.slice();
                for (const observer of observers) {
                    observer.error(err);
                }
            },
            complete: () => {
                const observers = this._observers.slice();
                for (const observer of observers) {
                    observer.complete();
                }
            },
        };

        const observers = this._observers;
        this.observable = new Observable((observer) => {
            observers.push(observer);

            if (this._isRunning) {
                observer.next(new StartProgressMessage());
            }
            if (this._nbPaused > 0) {
                observer.next(new PauseProgressMessage());
            }
            if (this._isShowTime) {
                observer.next(new ShowTimeProgressMessage());
            }
            if (this._label !== null) {
                observer.next(new LabelProgressMessage(this._label));
            }
            if (this._progress !== null) {
                observer.next(new ProgressProgressMessage(this._progress));
            }
            if (this._error !== null) {
                observer.next(new ErrorProgressMessage(this._error));
            }

            return function () {
                const idx = observers.indexOf(observer);
                observers.splice(idx, 1);
            };
        });
    }

    get isRunning() {
        return this._isRunning;
    }
}
