import { IsNumber, IsObject, IsString } from "class-validator";

export class BootstrapGetResponse {
    @IsString()
    archipadVersion!: string;

    @IsObject()
    capabilities!: Record<string, unknown>;

    @IsString()
    clientIP!: string;

    @IsObject()
    extra!: Record<string, unknown>;

    @IsString()
    secureAPIURL!: string;

    @IsNumber()
    serverTimestamp!: number;

    @IsString()
    serverVersion!: string;
}
