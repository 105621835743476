import { CancelledError } from "@core/errors/errors-core";
import { isAbortError } from "@core/tasks/asyncCaller";


export function isAbortOrCancelledError<T extends Error | CancelledError>( err: unknown ): err is T{
    if (err instanceof CancelledError){
        return true;
    }
    if (isAbortError(err)){
        return true;
    }
    return false;
}
