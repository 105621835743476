import * as JSZip from 'jszip';

import { ConfigBundle } from '@archipad-js/core/config';
import { createLogger } from '@core/services/logger.service';

import * as JSONParser from '@core/parsers/json.pegjs';

import * as SharedProjects from '@archipad/backend/archiweb/sharedProjects';
import * as DependencyManager from '@archipad/backend/project/dependenciesManager';
import _WorkflowUserManager from '@archipad/backend/project/workflowUserManager';
import * as AuthenticatedService from '@archipad/backend/rpc/authenticated';
import * as s3 from '@archipad/backend/s3';
import { LegacyUserInformation } from '@archipad/services/account.service';
import { AccountInfo } from '@archipad/services/account.service.types';

const log = createLogger('dependency');

interface RemoteBundleDefinition {
    bucket: string,
    region: string,
    credentials: {[key:string]:any},
    packaging: string,
    uri: string
};

function listBundlesInRemoteFolder(task, bundleDefinition:RemoteBundleDefinition):Promise<RemoteBundleDefinition[]> {
    const folderBundles:RemoteBundleDefinition[] = [];
    const creds = new s3.Creds(bundleDefinition.credentials);
    const context = new s3.Context(creds);
    const path = new s3.Path(bundleDefinition.region, bundleDefinition.bucket, bundleDefinition.uri);
    return s3.listFilesAtPath(task, context, path, function(page) {
        page.forEach(function(file) {
            if ( file.key.endsWith('.zip') ) {
                const bundle = {
                    bucket: bundleDefinition.bucket,
                    region: bundleDefinition.region,
                    credentials: bundleDefinition.credentials,
                    packaging: 'file',
                    uri: file.key
                };
                folderBundles.push(bundle);
            }
        });
    }).then(function() {
        return folderBundles;
    });
}

function listResourceTemplate(task, dependencies:string[], projectId?: string): Promise<any[]> {
    const params = {
        type: "form",
        dependencies: dependencies
    } as any;

    if (projectId)
        params.projectId = projectId;

    return AuthenticatedService.makeAuthenticatedRequest(task, "storage", "listResourceTemplate", params);
}

async function extractZipBundle(task:any, platform:string, configBundle:ConfigBundle, bundleDefinition:RemoteBundleDefinition, loadedTemplateInfos:DependencyManager.TemplateInfo[]):Promise<void> {
    const CLOUD_PREFIX = 'cloud/';
    const DATASOURCE_PREFIX = 'ref-';

    const creds = new s3.Creds(bundleDefinition.credentials);
    const context = new s3.Context(creds);
    const path = new s3.Path(bundleDefinition.region, bundleDefinition.bucket, bundleDefinition.uri);

    let zipContent;

    await s3.fetchFilesAtPath(task, context, [ path ], function(path, file) {
        zipContent = file;
        return null;
    });

    const zip = await JSZip.loadAsync(zipContent);

    const template = zip.file(platform + '/' + 'template.json');
    if ( ! template ) {
        log.warn("Unable to find template.json in bundle, ignoring");
        return;
    }

    const templateContent = await template.async("string");
    const jsonTemplateContent = JSON.parse(templateContent);
    const templateInfos = DependencyManager.getTemplateInfos(jsonTemplateContent);
    const forms = zip.file(/\.json$/);

    for( const file of forms ) {
        if ( file.name.indexOf(CLOUD_PREFIX) === -1 )
            continue;

        if ( file.name == CLOUD_PREFIX + 'template.json' )
            continue;

        const str = file.name.substr(file.name.indexOf(CLOUD_PREFIX) + CLOUD_PREFIX.length);
        // NOTE: project aspects cannot contain '.' otherwise 
        // there is an ambiguity in the zip file name
        const match = /^(.*)\.([a-z0-9_-]+)\.json$/i.exec(str);
        if(!match)
            continue;

        let path = match[1];
        const aspectName = match[2];

        const content = await file.async('string');
        const jsonContent = JSONParser.parse(content);

        // TODO: remove this
        if(path === 'project.entity') {
            log.debug('**** AUTOFIXING ASPECTS ON project.entity TO project_entity ****')
            path = 'project_entity';
        }

        /**
         * AP-6925
         * Files starting with `ref-` are treated as datasources for suggest/option inputs
         * File content is parsed and directly stored in the configuration object
         * Content can then be accessed with a simple ConfigService.get(path)
         * With `path` being the filename without the `ref-` prefix
         */
        if(path.startsWith(DATASOURCE_PREFIX)) {
            path = path.substring(DATASOURCE_PREFIX.length); // Remove "ref-" prefix
            configBundle.setForAspect(path, aspectName, jsonContent);
        }
        else {
            configBundle.addAspect(path,aspectName,jsonContent)
        }
    }

    for(const templateInfo of templateInfos) {
        loadedTemplateInfos.push(templateInfo)
    }
}

function installRemoteBundle(task:any, platform:string, configBundle:ConfigBundle, bundleDefinition:RemoteBundleDefinition, loadedTemplateInfos:DependencyManager.TemplateInfo[]):Promise<any> {
    const bundlesPromise:Promise<RemoteBundleDefinition[]> = bundleDefinition.packaging == 'folder' ? listBundlesInRemoteFolder(task, bundleDefinition) : Promise.resolve([bundleDefinition]);

    return bundlesPromise.then(function(bundleDefinitions) {
        const extractPromises = [];
        bundleDefinitions.forEach(function(definition) {
            extractPromises.push(extractZipBundle(task, platform, configBundle, definition, loadedTemplateInfos));
        });

        return Promise.all(extractPromises);
    });
}

export const driver:DependencyManager.Driver = {
    installRemoteBundles(task, platform, dependencies, bundle, projectId):Promise<DependencyManager.TemplateInfo[]> {
        // if still not found, check remote bundles
        return listResourceTemplate(task, dependencies, projectId).then(function(results:any) {
            const promises = [];
            const resources = results.data;

            const loadedTemplateInfos:DependencyManager.TemplateInfo[] = [];
            resources.forEach(function(resource) {
                resource.data.forEach(function(info) {
                    const remoteBundleDefinition = {
                        bucket: resource.bucket,
                        region: resource.region,
                        credentials: resource.credentials,
                        packaging: info.packaging,
                        uri: info.packaging == 'file' ? info.baseURI + '/' + info.path : info.uri // Thierry, wtf ?
                    };

                    const installPromise = installRemoteBundle(task, platform, bundle, remoteBundleDefinition, loadedTemplateInfos);
                    promises.push(installPromise);
                });
            });

            return Promise.all(promises).then(function() {
                return loadedTemplateInfos;
            });
        });
    },


    getUserDependencies(task, accountInfo:AccountInfo):Promise<string[]> {
        return Promise.resolve()
            .then(function() {
                if ( accountInfo.dependencies ) {
                    /**
                    * Convert the collection of objects returned by the API to an array of string.
                    *
                    * @example [{"bundleId": "example1"}, {"bundledId": "example2"}] => ["example1", "example2"]
                    */
                    const dependencies = [];
                    for (const obj of accountInfo.dependencies) {
                        if (obj.hasOwnProperty('bundleId') && dependencies.indexOf(obj.bundleId) === -1) { // dedup
                            dependencies.push(obj.bundleId);
                        }
                    }

                    return dependencies;
                } else {
                    return [];
                }
            });
    },

    getParticipantDependencies(task, project, user:LegacyUserInformation):Promise<string[]> {
        return SharedProjects.getParticipant(task, project, user).then(function(participant) {
            if ( !participant ) {
                // This can happen if we try to enter a project as a deleted user (logAs)
                // TODO - We could try to detect if `user` is deleted or not, and throw if not
                return [];
            }

            const rightName = participant.role;
            const userRight = _WorkflowUserManager.findRight(project, rightName);

            if ( userRight.dependencies ) {
                const dependencies = userRight.dependencies.split(',');
                return dependencies;
            } else {
                return [];
            }
        });
    }
}
