/**
* ProjectUserRightEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { ProjectUserRoleEntity } from "./ProjectUserRoleEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";


declare global {
    interface EntityTypeMap {
        "ProjectUserRight": ProjectUserRightEntity
    }
}

export abstract class ProjectUserRightEntity extends BaseEntity {

	 abstract projectUserRightId: string;
	 abstract project: ProjectEntity;
	 abstract templateName?: string;
	 abstract dependencies?: string;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract detail?: string;
	 abstract index?: number;
	 abstract workflowRole?: ProjectUserRoleEntity;
	 abstract users?: ReadonlyArray<ProjectUserEntity>;
	
}
