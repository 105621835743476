import { Range } from "@archipad-js/core/range";

import { Expression } from "./predicate";

/**
 * Returns an {@link Expression expression} which evaluates to `true` if the
 * given field value is within the given range.
 *
 * @example
 * ```
 * const expression = makeRangeExpression({
 *      fieldName: "value",
 *      range: {
 *          lower: { bound: 1, open: true },
 *          upper: { bound: 2, open: true },
 *      },
 * });
 * ```
 */
export function makeRangeExpression(options: { fieldName: string; range: Range<unknown> }): Expression {
    const { fieldName, range } = options;
    let expression = new Expression();

    if (range.lower) {
        if (range.lower.open) {
            expression = expression.and(
                new Expression(`${fieldName} > {lowerValue}`, { lowerValue: range.lower.bound }),
            );
        } else {
            expression = expression.and(
                new Expression(`${fieldName} >= {lowerValue}`, { lowerValue: range.lower.bound }),
            );
        }
    }

    if (range.upper) {
        if (range.upper.open) {
            expression = expression.and(
                new Expression(`${fieldName} < {upperValue}`, { upperValue: range.upper.bound }),
            );
        } else {
            expression = expression.and(
                new Expression(`${fieldName} <= {upperValue}`, { upperValue: range.upper.bound }),
            );
        }
    }

    return expression;
}
