import * as Sentry from '@sentry/browser';

import { TrackingService, TrackingInfo, User } from '../tracking.types';


class TrackingServiceImpl implements TrackingService {
    /**
      * Model,  Add / Remove / RemoveMulti / Close, Bug/Map/WP/PlanningEvent/..., selectionCount?
      * Account, Signup/Login/Logout
      * Upgrade, ShowFromMenu/ShowFromInteraction/DismissedInfo/ShowPayment/ShowContact/DismissedPayment/DismissedContact/PaymentDone/ContactDone
      * View, ShowLeftPanel/ShowList/ShowMap,
      * ShareProject, SetRight/InviteConfirm/InviteCancel/InvitePay/PaymentCancel, admin/none/...
      * Sync, Publish/PublishMulti/FetchBackground/TempPatchPushBackground/TempPatchConflict/TempPatchResolveUsingMine/TempPatchResolveUsingTheirs/Rebase/RebaseClean
     */
    trackAction(trackingInfo: TrackingInfo): void {
        const { category, name: actionName, label } = trackingInfo;


        if ( category == "Account" && actionName == 'Register' ) {
            gtag("event", "sign_up", {
                method: "email",
            });
        }
        if ( category == "Account" && actionName == 'Delete' && label == 'DeletionSuccess' ) {
            gtag("event", "unsubscribe");
        }
    }

    setUser(user: User): void {
        try {
            if (user !== null) {
                gtag('set', {'user_id': user.userId.toString()});
                Sentry.setUser({
                    id: user.userId.toString(),
                    username: user.login,
                    email: user.login,
                    isRoot: user.isRoot,
                });
            } else {
                gtag('set', {'user_id': null});
                Sentry.setUser(null);
            }
        } catch {
            //Ignore all errors
        }

    }

    /**
     * Tracking used in loadTask
     */
    trackTask(trackingInfo: TrackingInfo, state: "begin" | "end" | "error", error: Error): void {
        const { category, name: actionName } = trackingInfo;

        if (state !== "end") {
            return;
        }

        if (category === "Account" && actionName === "Login") {
            gtag("event", "login", {
                method: "email",
            })
        }
        if (category === "Account" && actionName === "Register") {
            gtag("event", "sign_up", {
                method: "email",
            })
        }
    }
}
export default new TrackingServiceImpl();
