(function(DocumentFragment, Element, Node) {
    "use strict";

    const df = document.createDocumentFragment();
    df.appendChild(document.createElement('div'));
    if(!df.firstElementChild) {
        if(!DocumentFragment.prototype.firstElementChild) {
            Object.defineProperty(DocumentFragment.prototype, 'firstElementChild', {
                get: function() {
                    let e = this.firstChild;
                    while(e && e.nodeType !== Node.ELEMENT_NODE)
                        e = e.nextSibling;
                    return e;
                }
            });
            Object.defineProperty(DocumentFragment.prototype, 'lastElementChild', {
                get: function() {
                    let e = this.lastChild;
                    while(e && e.nodeType !== Node.ELEMENT_NODE)
                        e = e.previousSibling;
                    return e;
                }
            });
        }
    }
})(DocumentFragment, Element, Node);

import Modernizr from '@ui-archipad/device.modernizrrc';
import Env from '@env';

import { createLogger } from '@core/services/logger.service';

const log = createLogger('index');

// link automation module with the page
import * as Automation from '@ui-core/automation';
Automation.addLookInsideElement('ui-page, ' +
    'ui-login-view, ' +
    'ui-page-header, ' +
    'ui-alert-default, ' +
    'ui-logged-user, ' +
    'ui-billing-form, ' +
    'ui-stripe-form, ' +
    'rx-ui-task, ' +
    'zoom-layer, ' +
    'scroll-layer, ' +
    'rx-ui-form, ' +
    'ui-form, ' +
    'ui-form-section-default,' +
    ' ui-empty-content,' +
    ' ui-dropdown, ' +
    'ui-visit-cell, ' +
    'user-interaction-controller, ' +
    'ui-confirmation-popup,' +
    ' ui-dot-color, ' +
    'ui-role-selector-default,' +
    'ui-action-overlay,' +
    'ui-issue-attachment,' +
    'ui-toggle-default,' +
    'ui-bug-note-add,' +
    'ui-shared-report-info,' +
    'ui-form-attachment-input,' +
    'ui-bug-note-editor,' +
    'ui-remark-dropdown-editable,' +
    'rx-ui-form-type-popup,' +
    'rx-ui-form-page-create-popup,' +
    'ui-form-field-row,' +
    'ui-font-display,' +
    'ui-date-display,' +
    'rx-ui-table,' +
    'ui-password-confirmation-popup,' +
    'ui-project-list-cell,' +
    'ui-bug-list-cell,' +
    'ui-bug-form-history,' +
    'ui-header-back-button,' +
    'ui-popup-close-button,' +
    'orm-main-context'
    );

window['automation'] = Automation;

import * as Sentry from "@sentry/browser";

const excludedIntegrations = [
    Sentry.Integrations.GlobalHandlers.name,
    Sentry.Integrations.TryCatch.name,
];

Sentry.init({
    dsn: Env.sentry?.dsn,
    environment: Env.sentry?.environment,
    release: Env.sentry?.release,
    /** @see https://docs.sentry.io/platforms/javascript/integrations/default/ */
    integrations(defaultIntegrations) {
        const integrations = defaultIntegrations.filter((integration) => {
            const isExcluded = excludedIntegrations.includes(integration.name);
            return !isExcluded;
        });

        console.log(integrations);
        return integrations;
    },
});

import '@ui-archipad/helpers/commands';

import { installGtag } from './gtag';

if (Env.GTAG_ID) {
    installGtag(Env.GTAG_ID);
}

window.Stripe = null;
window.filestack = null;


const _supportedLocales = ['en', 'fr', 'de', 'es'];

function getLocale () {
    function isLocaleSupported(loc) {
        if(_supportedLocales.indexOf(loc) != -1)
            return true;

        loc = loc.split('-')[0];	// try en-GB - > en
        if(_supportedLocales.indexOf(loc) != -1)
            return true;

        return false;
    }

    function validateLocale(loc) {
        if(_supportedLocales.indexOf(loc) != -1)
            return loc;	// ok

        loc = loc.split('-')[0];	// try en-GB - > en
        if(_supportedLocales.indexOf(loc) != -1)
            return loc;	// ok

        // return default locale
        return _supportedLocales[0];
    }

    //var loc = null;

    // parse query string
    let query = window.location.search;
    if(query) {
        query = query.substring(1);
        const args = query.split('&');
        for(let i=0;i<args.length;i++) {
            if(!args[i].indexOf('lang='))
                return validateLocale(args[i].substring(5));
        }
    }


    let preferedLocales = window.navigator.languages;	// modern browsers
    if(!preferedLocales) {
        if(navigator['browserLanguage'])					// IE
            preferedLocales = [ navigator['browserLanguage'] ];
        if(window.navigator['userLanguage'])
            preferedLocales = [ window.navigator['userLanguage'] ];
        if(window.navigator.language)
            preferedLocales = [ window.navigator.language ];
    }

    for(let i=0;i<preferedLocales.length;i++) {
        if(isLocaleSupported(preferedLocales[i]))
            return validateLocale(preferedLocales[i]);
    }

    return _supportedLocales[0];
}

async function modernizrInit(){
    // Here we assume that all "top level" features of this Modernizr build are critical for Cloud
    // Chrome (latest) don't support cssgridlegacy (we don't use it) but the loader bundle it when cssgrid is defined, thus filtering is needed.
    let hasMissingFeatures:boolean = Object.entries(Modernizr).filter( feature => ['cookies', 'cssgridlegacy'].indexOf(feature[0]) === -1).some( feature => {
        // Modernizr might expose objects as values for sub-features (ex: supported video format, input types, ...)
        // We will ignore these for now as we do not know which features are critical for Cloud
        if((typeof(feature[1]) === 'boolean') && (feature[1] === false)) {
            return true;
        }
    });

    // Early return if we already have missing features
    if(hasMissingFeatures) {
        return false;
    }

    // Some features are added asynchronously and we have no way to know when they are made available on Modernizr object (ex: indexeddb)
    // My guess is that if the IndexedDB API is not available, Moderniz.indexeddb is false right away.
    // If the API is available, Modernizr tries to open a connection to test "sub-features" making the test result asynchronous.
    // We have to manually test these async features.
    // TODO : Find out if other async features are critical for Cloud
    const asyncFeatures:Array<string> = ['indexeddb'];

    for( const asyncFeature of asyncFeatures ) {
         // We might already have the result for this feature
        if(!Modernizr.hasOwnProperty(asyncFeature)) {
            hasMissingFeatures = !await executeFeatureTest(asyncFeature);
        }
        if(hasMissingFeatures) {
            break;
        }
    }

    // all checks complete, will show page
    return !hasMissingFeatures;
}

function executeFeatureTest(feature: string): Promise<boolean>{
    return new Promise((resolve, reject) => {
        Modernizr.on(feature, function(gotFeature) {
            if(!gotFeature) {
                resolve(false);
            }

            resolve(true);
        });
    })
}

async function init(){
    if ( window.location.search.indexOf('debug-notsupported') !== -1 ) {
        console.log("not supported debug");
        return showPage(false);
    } else {
        Modernizr.addTest('openkeycursor', function() {
            return typeof IDBObjectStore.prototype['openKeyCursor'] === 'function';
        });

        return showPage(await modernizrInit());
    }
}

init().catch((err) => {
    throw err;
});

function showPage(hasFeatures) {
    const notSupportedDiv = document.getElementById('browserNotSupported');
    const locale = getLocale();

    const ua = window.navigator.userAgent;
    const IS_MSIE = ua.indexOf("Trident/") !== -1;

    if ( ! hasFeatures || IS_MSIE ) {
        notSupportedDiv.setAttribute('class', 'notsupported '+ locale);

        // TODO XXX : What is this beast ? (SSL cert broken, not found at the end.)
        const search = '#/shared-report/';
        if ( window.location.hash.indexOf(search) === 0 ) {
            const reportId = window.location.hash.substr(search.length);

            const to = locale == 'fr' ? "https://v1.archipad.com/fr/rapport-partage.html?report=" : "https://v1.archipad.com/en/shared-report.html?report=";

            window.location.href = to + reportId;
        }

    } else {
        const loadingDiv = document.getElementById('app-loading');
        loadingDiv.setAttribute('class', 'loading '+ locale);

        document.body.addEventListener('app-ready', function() {
            loadingDiv.classList.add("loaded");
            setTimeout(function() {
                loadingDiv.style.display = 'none';
            }, 1000);
        });

        import("./bootstrap.html")
            .then(function() {
                const appBootstrap = document.createElement('app-bootstrap');
                appBootstrap.innerHTML = `
                    <template slot="template">
                        <archipad-app id="app"></archipad-app>
                    </template>`;
                notSupportedDiv.parentNode.appendChild(appBootstrap);
            })
            .catch(function(err) {
                console.log('Error while loading bootstrap', err);
                const archipadCloudAppNode = document.createElement('div');
                archipadCloudAppNode.innerText = err;
                notSupportedDiv.parentNode.appendChild(archipadCloudAppNode);
                loadingDiv.classList.add("loaded");
            });

        loadingDiv.style.display = 'block';
    }
}
