import "reflect-metadata";

import { plainToInstance } from "class-transformer";
import { inject, injectable, optional } from "inversify";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { distinctUntilChanged, first, map, share, skip } from "rxjs/operators";

import { callAsAsync, observableToPromise, promiseToObservable, voidProgressHandler } from "@archipad-js/core/async";
import { UnsupportedOperationError } from "@archipad-js/core/error";
import { validateOrRejectSync } from "@archipad-js/core/validation";
import { DIID } from "@archipad-js/dependency-injection";

import { AuthToken, CredentialRenewResponse } from "../dto/credential.types";
import { ArchipadRPCService, Archipad_RPC_Service_DIID } from "../rpc/archipad-rpc.service";
import { API_ERROR_CODE, ResponseAPIError } from "../rpc/archipad-rpc.service.error";
import { TrustedTimeService, Trusted_Time_Service_DIID } from "../trusted-time/trusted-time.service";
import { AuthenticationAPIError, TimeSkewAPIError } from "./archipad-authentication.service.error";

export const Legacy_Archipad_Auth_Service_DIID = DIID<LegacyArchipadAuthService>("LegacyArchipadAuthService");
export const Auth_Token_DIID = DIID<AuthToken>("AuthToken");

/**
 * [PLUS-158] Delete this service and all is usages
 * Service in charge of making authenticated requests to Archipad APIs.
 * It exposes a `requireLogin$` observable which is used to trigger an (auto)Login when needed.
 *
 * Optionally, a `forcedAuthToken` can be injected to force the use of a specific `AuthToken`.
 * It is needed to make requests during authentication process, when the `AuthToken`
 * is not yet propagated through the whole application.
 */
@injectable()
export class LegacyArchipadAuthService {
    private readonly requireLoginSub = new Subject<void>();
    public readonly requireLogin$ = this.requireLoginSub.asObservable();

    private readonly requireAuthToken$: Observable<AuthToken | null>;
    private readonly requireAuthTokenIfNeeded$: Observable<AuthToken | null>;
    private authTokenLifetime = 0;

    private readonly authTokenSub = new BehaviorSubject<AuthToken | null>(null);
    private readonly loginValueSub = new BehaviorSubject<string | null>(null);

    public readonly isConnected$ = this.authTokenSub.asObservable().pipe(
        map((value) => Boolean(value)),
        distinctUntilChanged(),
    );

    constructor(
        @inject(Archipad_RPC_Service_DIID.symbol) private readonly archipadRpcService: ArchipadRPCService,
        @inject(Trusted_Time_Service_DIID.symbol) private readonly trustedTimeService: TrustedTimeService,
        @inject(Auth_Token_DIID.symbol) @optional() private readonly forcedAuthToken: AuthToken,
    ) {
        // NOTE : This check is here to avoid S3 SDK RequestTimeTooSkewed which will lead to forbidden download on any S3 resource.
        if (Math.abs(this.trustedTimeService.serverDeltaTimeSeconds) > 15 * 60) {
            throw new TimeSkewAPIError();
        }

        this.requireAuthToken$ = promiseToObservable(async (signal) => {
            /**
             * We want to halt the task and (a)wait for a new `authToken` value.
             * In order to do so, we create a new `nextAuthToken$` observable from `authTokenSub` that
             * will skip the current value using `skip` and complete on the first value received
             * using `first`.
             */
            const nextAuthToken$ = this.authTokenSub.pipe(skip(1), first());
            const nextAuthTokenPromise = observableToPromise(nextAuthToken$, signal);
            this.requireLoginSub.next();
            const authToken = await nextAuthTokenPromise;
            return authToken;
        }).pipe(share());

        this.requireAuthTokenIfNeeded$ = promiseToObservable(async (signal) => {
            if (this.forcedAuthToken) {
                return this.forcedAuthToken;
            }

            if (!this.authToken) {
                const authToken = await this.requireAuthToken(signal);
                this.setAuthToken(authToken);
                return authToken;
            }

            const remainingAuthTokenTime = this.getAuthTokenRemainingSeconds(this.authToken);
            const authTokenHalfLife = Math.floor(this.authTokenLifetime / 2);

            if (remainingAuthTokenTime <= 0) {
                const authToken = await this.requireAuthToken(signal);
                this.setAuthToken(authToken);
                return authToken;
            } else if (remainingAuthTokenTime < authTokenHalfLife) {
                if (!this.currentLogin) {
                    throw new AuthenticationAPIError("authToken could not be renewed, missing credentials.");
                }

                const authToken = await this.renewAuthToken(this.authToken, this.currentLogin, signal);
                this.setAuthToken(authToken);
                return authToken;
            }
            return this.authToken;
        }).pipe(share());
    }

    private get authToken() {
        return this.authTokenSub.getValue();
    }

    public get currentLogin() {
        return this.loginValueSub.getValue();
    }

    public async makeRequest(
        signal: AbortSignal,
        serviceName: string,
        methodName: string,
        methodParams?: Record<string, unknown>,
    ): Promise<unknown> {
        const p = voidProgressHandler();
        const authToken = await callAsAsync(signal, p, 1, () => this.requireAuthTokenIfNeeded$);

        if (!authToken) {
            throw new AuthenticationAPIError("Could not obtain an authToken.");
        }

        methodParams = methodParams || {};
        methodParams["authToken"] = JSON.stringify(authToken);

        const result = await this.archipadRpcService.makeRequest(signal, serviceName, methodName, methodParams);
        return result;
    }

    public setAuthToken(authToken: AuthToken | null): void {
        if (this.forcedAuthToken) {
            throw new UnsupportedOperationError("Cannot replace supplied forcedAuthToken.");
        }

        if (!authToken) {
            this.authTokenSub.next(null);
            this.authTokenLifetime = 0;
            return;
        }

        const authTokenLifetime = this.computeAuthTokenLifetime(authToken);
        this.authTokenLifetime = authTokenLifetime;
        this.authTokenSub.next(authToken);
    }

    /**
     * Computes the `authToken` lifetime based on `authToken.end` assuming
     * that the `authToken` has just been created. ( end - now = lifetime )
     *
     * @param authToken
     */
    private computeAuthTokenLifetime(authToken: AuthToken): number {
        const authTokenLifetime = authToken.end - this.trustedTimeService.trustedTime;
        return authTokenLifetime;
    }

    /**
     * @deprecated
     * @param login
     */
    public setCurrentLogin(login: string | null): void {
        this.loginValueSub.next(login);
    }

    /**
     * Subscribe to `requireAuthToken$` observable through `callAsAsync`,
     * emitting an empty value on `requireLoginSub` subject and then
     * waits for a new value on `authTokenSub`.
     *
     * `callAsAsync` was lazily used to handle `AbortSignal` in this promise
     *
     * @param signal
     * @returns
     */
    private async requireAuthToken(signal: AbortSignal): Promise<AuthToken | null> {
        const p = voidProgressHandler();
        const authToken = await callAsAsync(signal, p, 1, () => this.requireAuthToken$);
        return authToken;
    }

    /**
     * Returns the seconds remaining until `authToken` expires.
     *
     * @param signal
     * @returns Promise<number>
     */
    private getAuthTokenRemainingSeconds(authToken: AuthToken): number {
        const trustedMicroTime = this.trustedTimeService.trustedMicroTime;
        const authTokenExpiration = new Date(authToken.end * 1000);

        return Math.round((authTokenExpiration.getTime() - trustedMicroTime) / 1000);
    }

    /**
     * Calls `credential/renew` and tries to renew the current (valid) authToken
     * For now, we need the current `authToken` and `login` in order to renew the `authToken`
     * In future API versions, we might only need the current `authToken`.
     *
     * @param authToken
     * @param login
     * @param signal
     * @returns
     */
    private async renewAuthToken(authToken: AuthToken, login: string, signal: AbortSignal): Promise<AuthToken> {
        try {
            const params = {
                currentAuthToken: JSON.stringify(this.authToken),
                login: this.currentLogin,
            };

            const response = await this.archipadRpcService.makeRequest(signal, "credential", "renew", params);
            const authResponse = plainToInstance(CredentialRenewResponse, response);
            validateOrRejectSync(authResponse);
            return authResponse.authToken;
        } catch (error) {
            /**
             * If `credential/renew` returns an `access denied` error, it means that current `authToken` was rejected.
             * At this point, re-logging should be the best solution.
             */
            if (error instanceof ResponseAPIError && error.response.code == API_ERROR_CODE.ACCESS_DENIED) {
                this.setAuthToken(null);
                throw new AuthenticationAPIError(error.response.message);
            }
            throw error;
        }
    }
}
