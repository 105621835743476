import { DIID } from "@archipad-js/dependency-injection";
import { InternationalizationService } from "./internationalization.service";
import { LocalizationService } from "./localization.service";

export const Localization_Service_DIID = DIID<LocalizationService>("Localization.Service");
export const Internationalization_Service_DIID = DIID<InternationalizationService>("Internationalization.Service");

export const Locale_DIID = DIID<Intl.Locale>("Localization.Service.Locale");
export const TranslationMap_DIID = DIID<TranslationMap>("Localization.Service.TranslationMap");

/**
 * Object containing all the keys / translated values for a given locale
 */
export type TranslationMap = {
    [key: string]: string | TranslationMap;
};
/**
 * Common interface with the translation tools.
 * (https://github.com/ArchipadDev/translation-tools)
 */
export interface JsonTranslationFile {
    header: {
        original: string;
        "source-language": string;
        "target-language": string;
    };
    data: TranslationMap;
}
