import {ProjectEntity} from "@archipad-models/models/ProjectEntity";
import {MapEntity} from "@archipad-models/models/MapEntity";
import {MAP_TYPES} from "@archipad-models/models/extensions/MapEntity-ext";
import {getMapFileType} from '@archipad/helpers/mapHelper';
import { EntityContext } from "@core/services/orm/orm";
import { RegionEntity } from "@archipad-models/models/RegionEntity";
import { FileDescriptor } from "@core/services/file/file";
import * as IdGenerator from '@core/services/orm/idGenerator';
import { createLogger } from '@core/services/logger.service';

export function updateMapTypeOrderHint(map: MapEntity): void {
    const orderHint = typeOrderHint(map);

    if (orderHint === map.typeOrderHint) {
        return;
    }

    map.typeOrderHint = orderHint;
}

export function updateProjectMapsTypeOrderHint(project: ProjectEntity): void {
    for(const map of project.maps) {
        updateMapTypeOrderHint(map);
    }
}

export function typeOrderHint(map: MapEntity): number {
    /**
     * On affiche toujours `Généralités` puis  `FormObs - Généralités ` ,
     * puis les plans `FormObs - Nom de la zone ` dans l'ordre alphabétiques,
     * puis les autres plans du projet dans le même ordre qu'aujourd'hui.
     */
    if (map.type === MAP_TYPES.GENERALITIES) {
        return -200;
    }
    if (map.type === MAP_TYPES.FORMS) {
        if (!map.region) {
            return -101;
        } else {
            return -100;
        }
    }
    return 0;
}

/**
 * Check if the map can be moved to the null region when deleting its region.
 * 
 * @param map - The map entity to check
 */
export function canMoveMap(map: MapEntity): boolean {
    if (map.region === null) {
        return false;
    }

    const isFormObsMap = map.type === MAP_TYPES.FORMS;
    const hasObservations = map.bugs && map.bugs.length > 0;
    const isRegionPersisted = map.region.isPersisted();

    return !isFormObsMap || hasObservations || isRegionPersisted;
}

/**
 * Normalize filename
 * Copied from file-upload.ts
 * 
 * @param filename 
 */
export function normalizeFilename(filename: string): string {
	return filename.replace('\/', '-').normalize('NFD');
}

/**
 * Find map in provided map list by filename (exact match) or baseFilename (ignoring suffixes)
 * 
 * @param maps 
 * @param filename 
 * @param exactMatch 
 */
export function findMapByFilename( maps: readonly MapEntity[], filename:string, exactMatch: boolean = true ):MapEntity | null {
    const normalizedFilename = exactMatch?normalizeFilename( filename ):getNormalizedBaseFilename( filename );
    const method = exactMatch?normalizeFilename:getNormalizedBaseFilename;

    for(const map of maps) {
        if (method(map.filename) === normalizedFilename) {
            return map;
        }
    }
    return null;
}

/**
 * Returns normalized filename without the suffix ( IND-XXX )
 * 
 * @param filename 
 */
export function getNormalizedBaseFilename( filename:string ):string {
    const regExp = /(?:(.+)-IND-[a-zA-Z0-9]{1,3})\.pdf/;
    const filenameMatchResult = normalizeFilename(filename).match( regExp );

    if( filenameMatchResult ) {
        return filenameMatchResult[1] + '.pdf';
    }
    return normalizeFilename(filename);
}

/**
 * Create a new MapEntity if the provided filename does not match a map (ignoring suffixes)
 * Create a new MapVersionEntity and update MapEntity if the filename matches an existing map
 * 
 * @param entityContext 
 * @param project 
 * @param region 
 * @param attachment 
 * @param newEntityId 
 * @param filename 
 */

export function createOrUpdateMap({ entityContext, project, region, attachment, newEntityId, filename}:{
    entityContext:EntityContext, 
    project:ProjectEntity, 
    region:RegionEntity, 
    attachment:FileDescriptor, 
    newEntityId:string, 
    filename:string
}):boolean {
    const log = createLogger('map-helper');
    const fileType = getMapFileType(filename);
    const map = findMapByFilename( project.maps, filename, false );

    if( map ) {
        // Map was found, update MapEntity and create a new MapVersionEntity
        // Copied from map-controller.js

        const mapVersion = entityContext.createEntity('MapVersion', newEntityId );
        mapVersion.date = new Date();

        let index = 0;
        map.versions.forEach(function(v) {
            index = Math.max(index, v.index);
        });
        index++;

        mapVersion.index = index;
        mapVersion.filename = filename;
        mapVersion.setAttachmentForKey(filename, attachment);
        mapVersion.map = map;

        // Copied from map-version-controller.ts

        const previousFilename = map.filename;
        map.setAttachmentForKey(previousFilename, null);
        map.fileType = fileType;
        map.activeVersion = mapVersion;
        map.filename = filename;
        map.setAttachmentForKey( filename, attachment );
    }
    else {
        // Map not found, create a new MapEntity

        const mapVersionId = IdGenerator.generateId();

        log.debug("creating map with Id ", newEntityId, " and version ", mapVersionId);

        const newMap = entityContext.createEntity('Map', newEntityId );
        newMap.filename = filename;
        newMap.type = MAP_TYPES.DEFAULT;
        newMap.fileType = fileType;
        newMap.project = project;
        newMap.region = region ? region : null;
        newMap.setAttachmentForKey(filename, attachment);

        const mapVersion = entityContext.createEntity('MapVersion', mapVersionId);
        mapVersion.date = new Date();
        mapVersion.filename = filename;
        mapVersion.index = 1;
        mapVersion.setAttachmentForKey(filename, attachment);
        mapVersion.map = newMap;

        newMap.activeVersion = mapVersion;
    }
    return true;
}