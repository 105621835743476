/**
* RegionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionTypeEntity } from "./RegionTypeEntity";
import { MapEntity } from "./MapEntity";
import { RegionMemberEntity } from "./RegionMemberEntity";
import { VisitEntity } from "./VisitEntity";
import { DocumentEntity } from "./DocumentEntity";


declare global {
    interface EntityTypeMap {
        "Region": RegionEntity
    }
}

export abstract class RegionEntity extends BaseEntity {

	 abstract regionId: string;
	 abstract isHandedOver: boolean;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract text?: string;
	 abstract type?: RegionTypeEntity;
	 abstract maps?: ReadonlyArray<MapEntity>;
	 abstract members?: ReadonlyArray<RegionMemberEntity>;
	 abstract visits?: ReadonlyArray<VisitEntity>;
	 abstract documents?: ReadonlyArray<DocumentEntity>;
	
}
