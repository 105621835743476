import { endOfToday, startOfToday } from "date-fns";

import { IllegalStateError } from "@archipad-js/core/error";

import { VisitTypeTemplateName } from "@archipad/backend/project/workflowManagerConstant";
import _WorkflowUserManager, { WorkflowUserManager } from "@archipad/backend/project/workflowUserManager";
import { VisitService, VisitWhereInput } from "@archipad/services/visit.service";
import { VisitTypeService, VisitTypeWhereInput } from "@archipad/services/visitType.service";

// use generated *Entity
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";

import "@archipad-models/models/extensions/VisitEntity-ext";
import { PhaseService } from "./phase.service";

export class TodaysVisitFacade {
    constructor(
        private readonly visitService: VisitService,
        private readonly visitTypeService: VisitTypeService,
        private readonly workflowUserManager: WorkflowUserManager,
    ) {}

    isVisitToday(visit: VisitEntity | null): boolean {
        return Boolean(visit?.isVisitToday);
    }

    /**
     * Returns the today's visit for the given visit type template name, if it exists.
     *
     * If no visit type template name is provided, returns an execution today's visit if it exists.
     */
    public findTodaysVisit(
        project: ProjectEntity,
        visitTypeTemplateName?: VisitTypeTemplateName,
    ): VisitEntity | undefined {
        const visitTypeWhere: VisitTypeWhereInput = {
            project,
        };

        if (visitTypeTemplateName) {
            visitTypeWhere.templateNames = [visitTypeTemplateName];
        } else {
            visitTypeWhere.bugGroups = [null];
            visitTypeWhere.eventPriorities = [0];
        }

        const visitTypes = this.visitTypeService.listVisitTypes({
            where: visitTypeWhere,
        });

        const visitWhere: VisitWhereInput = {
            project,
            /** { date | startOfToday() <= date < endOfToday() } */
            dateRange: {
                lower: { bound: startOfToday(), open: false },
                upper: { bound: endOfToday(), open: true },
            },
            visitTypes,
        };

        if (visitTypeTemplateName === VisitTypeTemplateName.WorkPackage) {
            const currentUser = this.workflowUserManager.getCurrentUser(project);
            visitWhere.creators = [currentUser];
        }

        return this.visitService.findVisit({
            where: visitWhere,
            orderBy: "date desc,object(natural)",
        });
    }

    /**
     * Returns the last **past** execution visit, if it exists.
     *
     * A past visit is a visit that has a date today or before today.
     */
    public findLastPastExecutionVisit(project: ProjectEntity): VisitEntity | undefined {
        const visitTypes = this.visitTypeService.listVisitTypes({
            where: { project, bugGroups: [null], eventPriorities: [0] },
        });

        return this.visitService.findVisit({
            where: {
                project,
                /** { date | date < endOfToday() } */
                dateRange: {
                    upper: { bound: endOfToday(), open: true },
                },
                visitTypes,
            },
            orderBy: "date desc,object(natural)",
        });
    }

    /**
     * Create a new today's visit for the given visit type template name.
     *
     * If no visit type template name is provided, create a new execution today's visit.
     */
    public createTodaysVisit(project: ProjectEntity, visitTypeTemplateName?: string): VisitEntity {
        const visitTypeWhere: VisitTypeWhereInput = {
            project,
        };

        if (visitTypeTemplateName) {
            visitTypeWhere.templateNames = [visitTypeTemplateName];
        } else {
            visitTypeWhere.isDefault = true;
        }

        const visitType = this.visitTypeService.findVisitType({
            where: visitTypeWhere,
        });

        if (!visitType) {
            throw new IllegalStateError("Unable to find visit type");
        }

        const now = new Date();

        const visit = this.visitService.createVisit({
            project,
            visitType,
            date: new Date(now),
            creationDate: new Date(now),
        });

        return visit;
    }

    /**
     * Return an existing today's visit or create a new one for the given visit type template name.
     *
     * If no visit type template name is provided, return an existing execution today's visit or create a new one.
     */
    public getOrCreateTodaysVisit(project: ProjectEntity, visitTypeTemplateName?: VisitTypeTemplateName): VisitEntity {
        let visit = this.findTodaysVisit(project, visitTypeTemplateName);

        if (!visit) {
            visit = this.createTodaysVisit(project, visitTypeTemplateName);
        }

        return visit;
    }
}

// TODO @deprecated, use DI instead
const instance = new TodaysVisitFacade(new VisitService(_WorkflowUserManager, new PhaseService(), new VisitTypeService()), new VisitTypeService(), _WorkflowUserManager);

export default instance;
