export function resolveOverallBudget(demolitionBudget: number, constructionBudget: number): number | undefined {
    if (isNaN(demolitionBudget) || demolitionBudget === undefined) {
        demolitionBudget = null;
    }
    if (isNaN(constructionBudget) || constructionBudget === undefined) {
        constructionBudget = null;
    }
    if (demolitionBudget == null && constructionBudget == null) {
        return undefined;
    }
    return (demolitionBudget + constructionBudget);
}

export function resolveCostPerUnitSurface(overallBudget: number, floorSurface: number): number | undefined {
    if (!floorSurface || overallBudget == null) {
        return undefined;
    }
    return (overallBudget / floorSurface);
}
