import { Archiweb_Services_Client_DIID } from '@archipad-js/archipad/archiweb-services-client';
import _LegacyServiceManager from "@core/services/legacy-service-manager";
import { callAsAsync } from '@core/tasks/asyncCaller';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';

/*----------------------------------------------------------------------------*/

export async function makeAuthenticatedRequest<T>(signal: AbortSignal, progress: ProgressObserver, serviceName:string, methodName:string, methodParams?:Record<string,any>) : Promise<T> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const client = _LegacyServiceManager.container.get(Archiweb_Services_Client_DIID.symbol);
    const result = await callAsAsync(signal, p, 1, (po) => client.makeAuthenticatedRequest(signal, serviceName, methodName, methodParams) );
    return result as T;
}


export async function makeUnauthenticatedRequest<T>(signal: AbortSignal, progress: ProgressObserver, serviceName:string, methodName:string, methodParams?:Record<string,any>) : Promise<T> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const client = _LegacyServiceManager.container.get(Archiweb_Services_Client_DIID.symbol);
    const result = await callAsAsync(signal, p, 1, (po) => client.makeUnauthenticatedRequest(signal, serviceName, methodName, methodParams) );
    return result as T;
}
