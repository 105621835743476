/*

Interactions are a way to present dialog/alerts to the user, driven by the API.
Interactions can interupt the user action, for instance to abort the action if the user
hasn't Premium or if he hasn't the right to access a project.

An interaction definition has several parameters :
 - type : AlertInteraction | ConfirmInteraction | ErrorInteraction | PlanChangeInteraction | HTMLInteraction | ...
 - topic : basically the "title" of the dialog that will be presented
 - message : the message shown to the user


There are several types of interactions :
 - AlertInteraction : Simply show a message in an Alert-style view. User can continue the actions
 - ErrorInteraction : Simply show a message in an Alert-style view. User cannot continue the actions
 - ConfirmInteraction : Show a confirm Dialog. User can continue or not.
 - PlanChangeInteraction : Shows a go Premium dialog. User can continue once subscribed to Premium
 - HTMLInteraction : Shows a HTML chunk. The HTML page can decide whether or not the user can continue. --Not sure this one is implemented on Cloud.

 Interactions are hooked to several places in Archipad App/Cloud. There are plenty of hooks :
 - Access : Acces to a project, typically when the user tries to open a project
 - Sync : when the user tries to synchronize a project
 - Export : when the user tries to export a project (to Itunes, on App)
 - Delte : when the user tries to delete a project
 - ReportSharing : whent he user tries to share a report (by email, via Cloud, etc)
 - ...

 See ProjectInteractionManager.h on App to the list of App interactions

Hooks are a set a key points in the app/cloud and mainly to handle the Go Premium stuff.
If there is no interaction returned by the server for a specific hook, do nothing and don't interrupt user action

The flow is as follows :

On App :
 - upon connection/login we ask the server for the interactions of the projects on the iPad
 - cache the interactions for each project
 - upon hook, get the interaction and react accordingly

 On cloud, we used to get interactions for all projects so we don't delay entering the project by getting interactions for that project but implementation is changing for performance reasons.


*/

import { IllegalArgumentError } from '@archipad-js/core/error';
import * as rpcService from '@archipad/backend/rpc/authenticated';
import { getAccountService } from '@archipad/services/account.service';
import * as Task from '@core/services/task';
import { adaptOldTask } from '@core/tasks/progress';
import * as Interactions from '@ui-archipad/drivers/interactions/baseInteractions';
import { InteractionConstraint, PROJECT } from '@ui-archipad/drivers/interactions/baseInteractions';
import { AppDBValue, getDB, startsWithIDBKeyRange } from '@ui-archipad/helpers/indexedDB';

/*---------------------------------------------------------------------------*/

async function setInteraction(userId: number, interactionId: string, interactions: AppDBValue<"Interactions">['data']): Promise<void> {
    const db = await getDB();

    const key = `${userId}|${interactionId}`;

    await db.put('Interactions', {
        interactionId: key,
        data: interactions,
    });
}

async function getInteraction(userId: number, interactionId: string): Promise<unknown | null> {
    const db = await getDB();

    const key = `${userId}|${interactionId}`;

    const interaction = await db.get('Interactions', key);
    return interaction?.data ?? null;
}

export async function clearCachedInteractionsForUser(userId: number): Promise<void> {
    const db = await getDB();

    const userKeyPrefix = `${userId}|`;
    const keyRange = startsWithIDBKeyRange(userKeyPrefix);
    await db.delete('Interactions', keyRange);
}

async function doLoadInteractionsForProjects(task: Task.Task | null, projectId: string): Promise<void> {
    const accountInfo = getAccountService().currentAccount;

    // fetch the interactions
    const params = {
        projects: [],
    };

    params.projects.push({
        id: projectId,
        driverId: 'cloud',
        driverType: 'cloud',
    });

    const interactions = await rpcService.makeAuthenticatedRequest(task, 'project', 'getInteractions', params);

    const userId = accountInfo.id;

    await clearCachedInteractionsForUser(userId);

    for (const interaction of interactions) {
        await setInteraction(userId, interaction.id, interaction);
    }
}

/**
 * We avoid multiple project interactions loads because API doesn't like it..
 */
const pendingProjectInteractionsLoads: Map<string, Promise<void>> = new Map();
async function loadInteractionsForProjects(task: Task.Task, projectId: string): Promise<void> {
    const pendingPromise = pendingProjectInteractionsLoads.get(projectId);
    if (pendingPromise !== undefined) {
        return pendingPromise;
    }

    const promise = doLoadInteractionsForProjects(task, projectId);

    pendingProjectInteractionsLoads.set(projectId, promise);

    return promise.finally(() => {
        pendingProjectInteractionsLoads.delete(projectId);
    });
}

export function getAccountInteraction(task, interactionsFilter?: Interactions.InteractionType[] | Interactions.InteractionType) {
    return adaptOldTask(task, (progress) => {
        const accountInfo = getAccountService().currentAccount;
        if( !accountInfo) {
            throw new IllegalArgumentError(`Could not retrieve account information before getAccountInteraction.`);
        }

        const methodParams: Record<string, any> = {};
        if(interactionsFilter) {
            methodParams.filter = Array.isArray(interactionsFilter) ? interactionsFilter : [interactionsFilter];
        }

		return rpcService.makeAuthenticatedRequest(task, 'account', 'getInteractions', methodParams).then(function (interactions) {
            return interactions;
        });
    });
}

export function getProjectInteraction(task: Task.Task, projectId: string, reload?: boolean): Promise<{
    fromCache?: boolean;
    id?: string;
    interactions?: Record<PROJECT | string, InteractionConstraint>;
} | null> {
    return adaptOldTask(task, (progress) => {
        const accountInfo = getAccountService().currentAccount;
        if( !accountInfo) {
            throw new IllegalArgumentError(`Could not retrieve account information before getProjectInteraction.`);
        }
   
        // attempt to fetch the project from the cache
        let fromCache = true;
        const userId = accountInfo.id;
        return getInteraction(userId, projectId).then(function(interaction) {
            if(interaction && !reload) {
                return interaction; // found it
            }

            fromCache = false;

            return loadInteractionsForProjects(task, projectId).catch((err) => {
                if (interaction) {
                    // log the error and return the interaction from cache.
                    console.warn('Error while fetching interactions', err);

                    fromCache = true;
                    return interaction;
                }

                throw err; // no cached interaction for the project so we re-throw the error.
            });
        }).then(function () {
            // fetch again
            return getInteraction(userId, projectId).then(function(interaction) {
                if ( interaction ) {
                    (interaction as any).fromCache = fromCache;
                }
                return interaction;
            });
        });
    });
}