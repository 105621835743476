import * as t from 'io-ts';
import { isEqual, cloneDeep } from 'lodash';

import {
    SectionType,
    ReportTemplateSectionConfigModel,
    ReportTemplateSectionContainerConfigModel,
} from "@archipad/services/report/models/Definition";
import { SectionDefinition, ContainerSectionDefinition } from '@archipad/services/report/models/ArchipadReportTemplateConfig';


import { IllegalStateError, IllegalArgumentError } from "@core/errors/errors-core";

/**
 * This files contains the base interfaces of the sections configuration that will be serialized and contained in the SerializableReportTemplate objects
 */


export enum ReportTemplateConfigDiffType  {
    /** returns all user changes, user changes having precedence */
    ALL_CHANGES = 0,

    /** create a copy by returning all data */
    COPY = 1,
}



// represents the configuration key of a given section.
// Today, we have a one to one mapping between sectionId and sectionType because we can have only one
// type of section per template
export type sectionId = string;





/**
 * Represents with ColumnsConfig a columns configuration. We keep the order of each columns with their include state because we want
 * to save the fact that a columns is before another one, even if not "activated"
 */
export const ColumnConfig = t.type({
    value: t.string,
    selected: t.boolean,
});
export type ColumnConfig = t.TypeOf<typeof ColumnConfig>;

export const ColumnsConfig = t.array(ColumnConfig);
export type ColumnsConfig = t.TypeOf<typeof ColumnsConfig>;


/**
 * This is the base interface for ReportTemplate section configurations
 * Contains the minimum vital information for a section to display : it's section id, it's section type and if it's visible or not
 * Specific section configuration interfaces/classes should extends this one to add the configuration parameters
 */
export interface ReportTemplateSectionConfig extends ReportTemplateSectionConfigModel {
    /**
     * Initializes ReportTemplateSectionConfig from base/user configuration
     * @param baseConfiguration 
     */
    init(baseConfiguration:unknown)

    /**
     * Validates configuration object. NEVER CHANGE VALUES IN HERE
     * Throws an error if internal consistency is not met.
     */
    ensureConsistency();

    /**
     * Returns the Serializable diff based on internal model and provided base configuration
     * @param baseConfiguration
     */
    getDiff(baseConfiguration:ReportTemplateSectionConfigModel|null, strategy:ReportTemplateConfigDiffType):unknown;
}


/**
 * A specialisation of a base SectionConfig able to handle sub-sections
 */
export interface ReportTemplateSectionContainerConfig extends ReportTemplateSectionContainerConfigModel, ReportTemplateSectionConfig {
    
    /**
     * Adds a section into the user's configuration
     * @param sectionId sectionId to add
     * @param sectionType sectionType to add
     * @param beforeSectionIdOrNullIfAtTheEnd 
     */
    addSection(sectionId: string, sectionType:SectionType, beforeSectionIdOrNullIfAtTheEnd?:string);

    /**
     * Removes a section from the user's configuration.
     * @param sectionIdToRemove 
     */
    removeSection(sectionIdToRemove:string);

    /**
     * Moves a section in the user's configuration
     * @param sectionIdToMove 
     * @param beforeSectionIdOrNullIfAtTheEnd 
     */
    moveSection(sectionIdToMove:string, beforeSectionIdOrNullIfAtTheEnd:string, enforceAfter: boolean):void;

}




/**
 * Base SectionConfig that contains fields common to (almost) all SectionConfigs
 */
export class BaseSectionConfig implements ReportTemplateSectionConfig {
    
    readonly sectionType: string;
    
    protected _definition:SectionDefinition;

    sectionId:string;

    constructor(sectionId:string, definition:SectionDefinition, sectionType: SectionType) {
        this.sectionId = sectionId;
        this._definition = definition;
        this.sectionType = sectionType
    }

    init(baseConfiguration:ReportTemplateSectionConfigModel) {
        // construct object from definition and base configuration
        for ( const key of Object.keys(this)) {
            if ( key.startsWith('_' ) )
                continue;

            if ( key == 'sectionId' ) {
                if ( baseConfiguration && this.sectionId != baseConfiguration.sectionId ) {
                    throw new IllegalStateError("Unable to create configuration for type "+ this.sectionType + " : base configuration has another sectionId :" + baseConfiguration.sectionId);
                }
            } else if ( key == 'sectionType' ) {
                if ( baseConfiguration && this.sectionType != baseConfiguration.sectionType ) {
                    throw new IllegalStateError("Unable to create configuration for type "+ this.sectionType + " : base configuration has another sectionType :" + baseConfiguration.sectionType);
                }
            } else {
                this._setInitValue(baseConfiguration, key);
            }
        }
        
        this.ensureConsistency();
    }

    ensureConsistency() {
        // perform object/config-wide validation in subclasses
    }

    getDiff(baseConfiguration:ReportTemplateSectionConfigModel|null, strategy:ReportTemplateConfigDiffType):unknown {
        const result : Partial<ReportTemplateSectionConfigModel> = {};

        let hasDiff = false;
        for ( const key of Object.keys(this)) {
            if ( this._isDiffIgnoredKey(key) ) {
                continue;
            }

            const diffValue = this._getDiffValue(baseConfiguration, key, strategy);
            if ( diffValue !== undefined ) {
                result[key] = diffValue;
                hasDiff = true;
            }
        }

        if ( strategy ===  ReportTemplateConfigDiffType.COPY || hasDiff  ) {
            // inject sectionType and sectionId if we have anything
            result.sectionType = this.sectionType;
            result.sectionId = this.sectionId;

            return result;
        } else {
            return undefined;
        }
    }

    protected _isDiffIgnoredKey(key:string): boolean {
        return key.startsWith('_') || key === 'sectionId' || key === 'sectionType';
    }

    private _valueIsEqual(x:unknown, y:unknown): boolean {
        return isEqual(x,y);
    }

    // returns diff value. undefined if no diff
    protected _getDiffValue(baseConfiguration:ReportTemplateSectionConfigModel|null, key:string, strategy:ReportTemplateConfigDiffType):unknown {
        const value = this[key];
        if ( strategy ===  ReportTemplateConfigDiffType.COPY ) {
            return value;
        }
        else if (baseConfiguration && (key in baseConfiguration)) {
            // base configuration has the key.
            if ( ! this._valueIsEqual(baseConfiguration[key], value ) ) {
                // our value is different, set it
                return value;
            }
        } 
        else {
            // base configuration doesn't have the key
            const defaultValue = this._definition.fields.find(field => field.id == key).defaultValue;
            if (  ! this._valueIsEqual(defaultValue, value) ) {
                // it is not default value, set it
                return value;
            }
        }

        return undefined;
    }

    protected _setInitValue(baseConfiguration:ReportTemplateSectionConfigModel, key:string) {
        if ( baseConfiguration && baseConfiguration[key] !== undefined ) { // TODO XXX CLEANUP : null ?
            this[key] = cloneDeep(baseConfiguration[key]);
        }
        else {
            if ( this[key] !== undefined ) {
                // we already have a value, no need to fetch from field
            } else {
                const field = this._definition.fields.find(field => field.id == key);
                if ( ! field )
                    throw new IllegalArgumentError("No field "+key+" to fetch default value from. You should either define a field or provide a value in base configuration");
    
                this[key] = field.defaultValue;
            }
        }
    }
}







export abstract class BaseSectionContainerConfig extends BaseSectionConfig implements ReportTemplateSectionContainerConfig {

    protected _definition:ContainerSectionDefinition;


    // TODO XXX CLEANUP : make them getter only
    sections: {[sectionId:string]:ReportTemplateSectionConfig} = {};
    sectionsOrder:string[] = [];

    constructor(sectionId:string, definition:ContainerSectionDefinition, sectionType: SectionType) {
        super(sectionId, definition, sectionType);
    }

    protected _setInitValue(baseConfiguration:ReportTemplateSectionContainerConfigModel, key:string) {
        if ( key == 'sections' ) {
            if ( baseConfiguration ) {
                for ( const sectionId in baseConfiguration.sections ) {
                    const sectionConfig = baseConfiguration.sections[sectionId];
                    if (typeof sectionConfig['sectionType'] !== 'string' )
                        throw new IllegalArgumentError("Unable to create sub-section configuration object "+ sectionId + ": missing sectionType");

                    // If Section exist that mean we load a config
                    if ( this.sections[sectionId]) {
                        const existingSection = this.sections[sectionId];
                        existingSection.init(sectionConfig);
                    // Else create default
                    } else {
                        this._addSectionConfig(sectionId, sectionConfig['sectionType'], sectionConfig);
                    }
                }
            }
        } else {
            super._setInitValue(baseConfiguration, key);
        }
    }

    protected _isDiffIgnoredKey(key:string): boolean {
        return super._isDiffIgnoredKey(key) || key === "identifier";
    }

    protected _getDiffValue(baseConfiguration:ReportTemplateSectionContainerConfigModel|null, key:string, strategy:ReportTemplateConfigDiffType):unknown {
        if ( key == 'sections' ) {
            let hasDiff = false;
            const sectionsDiff = {};
            for ( const sectionId in this.sections ) {
                const sectionBaseConfiguration : ReportTemplateSectionConfigModel|null = baseConfiguration && baseConfiguration.sections && baseConfiguration.sections[sectionId];
                const sectionDiff = this.sections[sectionId].getDiff(sectionBaseConfiguration, strategy);
                if ( sectionDiff !== undefined ) {
                    hasDiff = true;
                    sectionsDiff[sectionId] = sectionDiff
                }
            }

            if ( baseConfiguration && ! Array.isArray(baseConfiguration.sectionsOrder) ) {
                throw new IllegalStateError("must be an array");
            }
            
            const addedSections = !baseConfiguration ? this.sectionsOrder : this.sectionsOrder.filter( x => ! baseConfiguration.sectionsOrder.includes(x) );
            for ( const addedSection of addedSections ) {
                if ( ! sectionsDiff[addedSection] ) {
                    sectionsDiff[addedSection] = {
                        sectionId: addedSection,
                        sectionType: this.sections[addedSection].sectionType
                    };
                    hasDiff = true;
                }
            }

            if ( hasDiff ) 
                return sectionsDiff;
            else
                return undefined;
        } else {
            return super._getDiffValue(baseConfiguration, key, strategy);
        }
    }

    ensureConsistency() {
        super.ensureConsistency();

        for ( const sectionId of this.sectionsOrder ) {
            if ( ! this.sections[sectionId] )
                throw new IllegalStateError("All sections defined in sectionsOrder are present in sections. missing : "+ sectionId);

            this.sections[sectionId].ensureConsistency();
        }
    }



    addSection(sectionId: string, sectionType:string, beforeSectionIdOrNullIfAtTheEnd?:string) {
        if ( this.sectionsOrder.indexOf(sectionId) !== -1 )
            throw new IllegalArgumentError("Unable to add section "+ sectionId + ", section ID already exists");

        if ( ! this.sections[sectionId] ) {
            this._addSectionConfig(sectionId, sectionType, null); // add section with section defaults
        }

        this.sectionsOrder = [...this.sectionsOrder, sectionId];
        if ( beforeSectionIdOrNullIfAtTheEnd )
            this.moveSection(sectionId, beforeSectionIdOrNullIfAtTheEnd, true);
    }

    /**
     * Removes a section from the user's configuration.
     * @param sectionIdToRemove 
     */
    removeSection(sectionIdToRemove:string) {
        this.sectionsOrder = this.sectionsOrder.filter((sectionId) => sectionId !== sectionIdToRemove);
    }

    /**
     * Moves a section in the user's configuration
     * @param sectionIdToMove 
     * @param beforeSectionIdOrNullIfAtTheEnd 
     */
    moveSection(sectionIdToMove:string, beforeSectionIdOrNullIfAtTheEnd:string, enforceAfter: boolean):void {
        if ( sectionIdToMove === beforeSectionIdOrNullIfAtTheEnd )
            return;

        const newSectionsOrder = [...this.sectionsOrder];

        const fromPosition = newSectionsOrder.indexOf(sectionIdToMove);
        if ( fromPosition == -1 )
            throw new IllegalArgumentError("Unable to find sectionId "+ sectionIdToMove +" to move");

        // remove the moved element
        newSectionsOrder.splice(fromPosition, 1);

        let toPosition = newSectionsOrder.indexOf(beforeSectionIdOrNullIfAtTheEnd);
        if (toPosition === -1) {
            toPosition = newSectionsOrder.length;
        }

        if (enforceAfter) {
            toPosition++;
        }

        // add the move element at the targeted index
        newSectionsOrder.splice(toPosition, 0, sectionIdToMove);

        this.sectionsOrder = newSectionsOrder;
    }

    protected _addSectionConfig(sectionId: string, sectionType: string, baseConfig:ReportTemplateSectionConfigModel): void {
        if ( this.sections[sectionId] ) {
            throw new IllegalArgumentError("Unable to create section "+ sectionId + ", section ID already exists");
        }

        const sectionDefinition = this._definition.availableChildSectionDefinitions.find(sectionDefinition => sectionDefinition.sectionType == sectionType);
        const sectionConfiguration:ReportTemplateSectionConfig = this._makeSectionConfig(sectionId, sectionDefinition);
        sectionConfiguration.init(baseConfig);

        this.sections[sectionId] = sectionConfiguration;
    }

    protected abstract _makeSectionConfig(sectionId: string, definition: SectionDefinition):ReportTemplateSectionConfig;
}
