import { IllegalArgumentError, IllegalStateError } from "@core/errors/errors-core";
import { generateDeterministicId, generateDeterministicTemplateIdFromTemplateIdAndTemplateId, makeUUID } from '@core/services/orm/idGenerator';
import { EntityContext } from "@core/services/orm/orm";

import { getProjectInfo, newPatchId } from "@archipad/backend/archiweb/synchroDriver";

import { BugGroupEntity } from '@archipad-models/models/BugGroupEntity';
import { MAP_FILE_TYPES, MAP_TYPES } from '@archipad-models/models/extensions/MapEntity-ext';
import { MapEntity } from '@archipad-models/models/MapEntity';
import { PhaseEntity } from '@archipad-models/models/PhaseEntity';
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";

import { ProjectManager } from "@ui-archipad/backend/project/project";
import { sharedProjectList } from "@ui-archipad/backend/project/projectList";
import { voidAbortSignal, voidProgress } from "@core/tasks/asyncCaller";

/**
 * To use the follow commands you have to open a project on [Archipad Cloud](https://cloud.archipad.com).
 * Open the developer console. All commands are available under `Archipad.Commands`.
 * 
 * @private
 */
class Commands {
    static async getAllUserProjects(): Promise<ReadonlyArray<string>> {
        const projectList = sharedProjectList();
        
        return projectList.getProjectsIds();
    }

    /**
     * @internal
     * Retrieve the current entity context.
     */
    static async getEntityContext(): Promise<EntityContext> {
        const archipadApp = document.querySelector('archipad-app') as any;
        const projectManager = archipadApp.project as ProjectManager | null;
        if (projectManager === null) {
            throw new IllegalStateError('Cannot retrieve the project prefix outside of a project');
        }
        const projectContext = await projectManager.getContext(null).toPromise();
        return projectContext.getEntityContext();
    }

    /**
     * @internal
     * Will delete the Map entity of a given id.
     * 
     * @param mapId - The id of the Map entity to delete
     */
    static async deleteMap(mapId: string): Promise<void> {
        if (typeof mapId !== 'string') {
            throw new IllegalArgumentError(`The mapId must be a string`);
        }
    
        const entityContext = await Commands.getEntityContext();
    
        const map = entityContext.getEntity('Map', mapId);
    
        if (map === null) {
            throw new IllegalArgumentError(`Unable to find the Map<${mapId}>`);
        }
    
        entityContext.deleteEntity(map);
    
        console.log(`The Map ${mapId} has been deleted`);
    }

    /**
     * Will delete all empty FormObs maps.
     *
     * Example:
     * ```
     * > Archipad.Commands.deleteAllEmptyFormObsMaps()
     * The following map contains 1 observations and will not be deleted.
     * entityType {_entityContext: EntityContext, _attachments: {…}, _values: {…}, _updating: false}
     * Promise {<fulfilled>: "3 maps have been deleted"}
     * ```
     * @return `"<<number of deleted map>> maps have been deleted"`
     */
    static async deleteAllEmptyFormObsMaps(): Promise<string> {
        const entityContext = await Commands.getEntityContext();
        const allMaps = Object.values(entityContext.entitiesByType['Map']) as MapEntity[];
        const emptyFormObsMaps = allMaps
            .filter((map) => map.fileType === MAP_FILE_TYPES.PDF && map.type === MAP_TYPES.FORMS)
            .filter((map) => {
                if (map.bugs.length === 0) {
                    return true;
                }

                console.warn(`The following map contains ${map.bugs.length} observations and will not be deleted.`, map);
                return false;
            });

        let deleteCount = 0;

        for (const map of emptyFormObsMaps) {
            if (map.bugs.length === 0) {
                entityContext.deleteEntity(map);
                deleteCount++;
            }
        }

        return `${deleteCount} maps have been deleted`;
    }

    /**
     * @internal
     * Will output in the browser console the current project AWS S3 prefix.
     */
    static async getProjectPrefix(): Promise<void> {
        const archipadApp = document.querySelector('archipad-app') as any;
        const projectManager = archipadApp.project as ProjectManager | null;

        if (projectManager === null) {
            throw new IllegalStateError('Cannot retrieve the project prefix outside of a project');
        }

        const projectInfo = await getProjectInfo(null, projectManager.getId()).toPromise();
        const projectPrefix = `${projectInfo.patches.bucket}/${projectInfo.patches.path}`;

        console.log(`Project prefix: %c${projectPrefix}`, 'color: blue;');
        console.table({
            'Region': projectInfo.patches.region,
            'Bucket': projectInfo.patches.bucket,
            'Path': projectInfo.patches.path,
        });
    }

    static async qweqwe(): Promise<void> {
    
        const project = await Commands.getProject();
        function resolveEquivBugState(equivNb) {
            switch (equivNb) {
                case 0:
                    return 'EquivStateNone';
                case 1:
                    return 'EquivStateOpen';
                case 2:
                    return 'EquivStateClosed';
                case 3:
                    return 'EquivStateOpenAlt';
                case 4:
                    return 'EquivStateClosedAlt';
                case 5:
                    return 'EquivStateClosedRejected';
                case 6:
                    return 'EquivStateClosing';
            }
        }

        function resolveEquivBugTransition(equivNb){
            switch (equivNb) {
                case 0:
                    return 'EquivTransitionNone';
                case 1:
                    return 'EquivTransitionAccept';
                case 2:
                    return 'EquivTransitionReject';
                case 3:
                    return 'EquivTransitionEnter';
        }
        }

        function resolveBugTransitionActivation(equivNb){
            switch (equivNb) {
                case 0:
                    return 'TransitionActivationNone';
                case 1:
                    return 'TransitionActivationIfEveryLinkClosed';
                case 2:
                    return 'TransitionActivationIfNotEveryLinkClosed';
                case 3:
                    return 'TransitionActivationIfAutoAcceptanceOfBug';
        }
        }
        
        const bugStates = project.bugWorkflow?.bugStates ?? [];
        const bugStatesDeliveryGroup = bugStates
        .filter((bugState) => bugState.bugGroup?.templateName === 'deliveryGroup')
        .map((bugState) => {
            return {
                templateName: bugState.templateName,
                name: bugState.name,
                equivBugState: resolveEquivBugState(bugState.equivBugState),
            };
        });
        const bugStatesDefaultGroup = bugStates
        .filter((bugState) => bugState.bugGroup?.templateName !== 'deliveryGroup')
        .map((bugState) => {
            return {
                templateName: bugState.templateName,
                name: bugState.name,
                equivBugState: resolveEquivBugState(bugState.equivBugState),
            };
        });
        console.log('Bug states for the delivery group:');
        console.table(bugStatesDeliveryGroup);
        console.log('Bug states for the default group:');
        console.table(bugStatesDefaultGroup);

        const transitionsForDeliveryGroup = project.bugWorkflow?.bugTransitions
        .filter((transition) => transition.endState.bugGroup?.templateName === 'deliveryGroup')
        .map((transition) => {
            return {
                name: transition.name,
                nameDone: transition.nameDone,
                startState: transition.startState?.templateName,
                endState: transition.endState.templateName,
                equivTransition: resolveEquivBugTransition(transition.equivBugTransition),
                autoActivation: resolveBugTransitionActivation(transition.autoActivation),
            };
        });

        console.log('Transitions for the delivery group:');
        console.table(transitionsForDeliveryGroup);

        const transitionsForDefaultGroup = project.bugWorkflow?.bugTransitions
        .filter((transition) => transition.endState.bugGroup?.templateName !== 'deliveryGroup')
        .map((transition) => {
            return {
                name: transition.name,
                nameDone: transition.nameDone,
                startState: transition.startState?.templateName,
                endState: transition.endState.templateName,
                equivTransition: resolveEquivBugTransition(transition.equivBugTransition),
                autoActivation: resolveBugTransitionActivation(transition.autoActivation),
            };
        });

        console.log('Transitions for the default group:');
        console.table(transitionsForDefaultGroup);
        
    }

    /**
     * Create a Phase_BugGroups entity to associate a Phase to a BugGroup.
     * 
     * @param bugGroup - The BugGroup to associate to the Phase
     * @param phase - The Phase to associate to the BugGroup
     * @internal
     */
     static async createPhaseRelation(bugGroup: BugGroupEntity | null, phase: PhaseEntity): Promise<void> {
        const project = await Commands.getProject();
        const entityContext = project.getContext();
        const relationTemplateId = generateDeterministicTemplateIdFromTemplateIdAndTemplateId(phase.templateId, bugGroup?.templateId ?? '6485251F3220412089A38D3E28701777');
        const relationEntityId = generateDeterministicId(project.projectId, relationTemplateId, 'uuid');
        const relation = entityContext.getEntity('Phase_BugGroups', relationEntityId) ?? entityContext.createEntity('Phase_BugGroups', relationEntityId);
        relation.templateId = relationTemplateId;
        relation.phase = phase;
        relation.bugGroup = bugGroup;
    }

    /**
     * @internal
     * Return the current project entity
     */
    static async getProject(): Promise<ProjectEntity> {
        const entityContext = await Commands.getEntityContext();
        const projects = Object.values(entityContext.entitiesByType.Project) as ProjectEntity[];
        return projects[0];
    }

    /**
     * Set a list of templates which the project must depend on.
     * The content of each template will be added to the project.
     *
     * To apply changes to the project we must reload the page.
     *
     * Example:
     * ```
     * > Archipad.Commands.updateOriginTemplates(['831DA8A6A0F9AA28E3F9C0CAD530ABAD'])
     * Promise {<fulfilled>: undefined}
     * ```
     * @param {string[]} originTemplateIds List of templateId to apply to the project
     */
    static async updateOriginTemplates(originTemplateIds: string[]): Promise<void> {
        const re = /^[0-9A-F]{32}$/i;
        const isValid = originTemplateIds.every((templateId) => {
            if (!re.test(templateId)) {
                console.error(`Invalid template id: ${templateId}`);
                return false;
            }
            return true;
        })
        if (!isValid) {
            return;
        }

        const project = await Commands.getProject();
        project.originTemplateIds = originTemplateIds.join(',');

        const archipadApp = document.querySelector('archipad-app');
        const projectManager = (archipadApp as unknown as any).project as ProjectManager | null;
        const projectContext = await projectManager.getContext(null).toPromise();
        await projectManager.upgradeProjectUsingContext(null, projectContext).toPromise();
    }

    /**
     * Utility to make a new patch id.
     * 
     * @internal
     */
    static async makeNewPatchId(): Promise<{patchId: string}> {
        const patchId = await newPatchId(voidAbortSignal(), voidProgress());

        return { patchId };
    }

    /**
     * Utility to make a new template id.
     * 
     * @internal
     */
    static async makeNewTemplateId(): Promise<{templateId: string}> {
        const templateId = makeUUID();

        return { templateId };
    }

}

/**
 * This global injection is intentionally opaque because those
 * commands should be used from the browser console only.
 */
(window as any).Archipad = {...(window as any).Archipad, Commands};
