import { CoreError } from "@archipad-js/core/error";

export class ORMError extends CoreError {
    constructor(message: string, entityId?: string, entityName?: string) {
        super(message);

        if (entityName) {
            this.addTag("archipad.orm.entity.name", entityName);
        }

        if (entityId) {
            this.addContext("Archipad", { "archipad.orm.entity.id": entityId });
        }
    }
}
