/**
* BugWorkflowEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { BugGroupEntity } from "./BugGroupEntity";
import { BugStateEntity } from "./BugStateEntity";
import { BugTransitionEntity } from "./BugTransitionEntity";


declare global {
    interface EntityTypeMap {
        "BugWorkflow": BugWorkflowEntity
    }
}

export abstract class BugWorkflowEntity extends BaseEntity {

	 abstract bugWorkflowId: string;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract bugGroups?: ReadonlyArray<BugGroupEntity>;
	 abstract bugStates?: ReadonlyArray<BugStateEntity>;
	 abstract bugTransitions?: ReadonlyArray<BugTransitionEntity>;
	
}
