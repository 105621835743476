import { SchedulerLike } from "rxjs";

import { DIID } from "@archipad-js/dependency-injection";

import { CoreError } from "@archipad-js/core/error";

import { ConfigBundle } from "./config-bundle";
import { ConfigService } from "./config.service";
import { ConfigOperator } from "./config-operator";
import { ConfigConstant } from "./config-contant";

export const Config_Bundle_DIID = DIID<ConfigBundle>("Config.Bundle");
export const Config_Constant_DIID = DIID<ConfigConstant>("Config.Constant");
export const Config_Operator_DIID = DIID<ConfigOperator>("Config.Operator");
export const Config_Service_DIID = DIID<ConfigService>("Config.Service");
export const Config_Service_Scheduler_DIID = DIID<SchedulerLike>("Config.Service.Scheduler");

export interface BuiltInOperator {
    str: string;
}

export interface CustomOperator extends Record<string, unknown> {
    "func:": string;
}

export interface ExpressionAspectPath {
    type: "expr";
    value: string;
}

export interface IdentifierAspectPath {
    type: "ident";
    value: string;
}

export interface IndexAspectPath {
    type: "index";
    value: number;
}

export interface StarAspectPath {
    type: "star";
}

export type AspectPath = ExpressionAspectPath | IdentifierAspectPath | IndexAspectPath | StarAspectPath;

export class ConfigError extends CoreError {}
