/**
* PersonEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionMemberEntity } from "./RegionMemberEntity";
import { ProjectMemberEntity } from "./ProjectMemberEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { VisitMemberEntity } from "./VisitMemberEntity";
import { RemarkCategoryEntity } from "./RemarkCategoryEntity";
import { RemarkEntity } from "./RemarkEntity";


declare global {
    interface EntityTypeMap {
        "Person": PersonEntity
    }
}

export abstract class PersonEntity extends BaseEntity {

	 abstract personId: string;
	 abstract temp: boolean;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract contactID?: string;
	 abstract name?: string;
	 abstract email?: string;
	 abstract abbreviation?: string;
	 abstract address?: string;
	 abstract company?: string;
	 abstract displayName?: string;
	 abstract phone?: string;
	 abstract memberOfRegions?: ReadonlyArray<RegionMemberEntity>;
	 abstract memberOfProject?: ReadonlyArray<ProjectMemberEntity>;
	 abstract memberOfWorkPackage?: ReadonlyArray<WorkPackageEntity>;
	 abstract memberOfVisit?: ReadonlyArray<VisitMemberEntity>;
	 abstract visitorInRemarkCategory?: RemarkCategoryEntity;
	 abstract contactOfRemark?: ReadonlyArray<RemarkEntity>;
	
}
