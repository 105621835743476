import WorkflowUserManager from '@archipad/backend/project/workflowUserManager';

import _PlanningService from '@archipad/services/planning.service';

import { ProjectEntity } from "@archipad-models/models/ProjectEntity";

/**
 * Ensure the project name integrity.
 * 
 * Project name cannot be null or empty, otherwise the project name is set to "Untitled".
 * 
 * @param project - The project
 */
export function ensureProjectNameIntegrity(project: ProjectEntity): void {
    const isProjectNameEmpty = project.name === null || project.name === '';
    
    if (isProjectNameEmpty) {
        project.name = l('Untitled');
    }
}

/**
 * Determines whether the current user is a project administrator of a given project or not.
 * 
 * @param project - The project entity
 */
export function isCurrentUserProjectAdministrator(project: ProjectEntity): boolean {
    const currentProjectUser = WorkflowUserManager.getCurrentUser(project);
    const isProjectAdministrator = currentProjectUser?.right?.templateName === 'administrator';
    return isProjectAdministrator;
}

/**
 * Ensure the planningMaster integrity
 *
 */
export function ensurePlanningMasterIntegrity(project: ProjectEntity): void {
    if (!project.planningMaster){
        project.planningMaster = project.owner;
        return;
    }
    const eligibleUsersForPlanningMaster = _PlanningService.getAllUsersEligibleToBePlanningMaster(project);
    const isPlanningMasterWasNotEligible = ! eligibleUsersForPlanningMaster.includes(project.planningMaster);
    if (isPlanningMasterWasNotEligible) {
        project.planningMaster = project.owner;
        return;
    }
}
export const COGEDIM_CODE_OPERATION_REGEX = /^[0-9]{5}$/;
export function ensureCogedimCodeOperationIntegrity(project: ProjectEntity): void {
    const haveCogedimCodeOperationProperty = 'cogedim_code_operation' in project;
    if (!haveCogedimCodeOperationProperty) {
        return;
    }
    if (typeof project.cogedim_code_operation !== 'string') {
        return;
    }
    const isValidCogedimCodeOperation = COGEDIM_CODE_OPERATION_REGEX.test(project.cogedim_code_operation);
    if (isValidCogedimCodeOperation) {
        return;
    }
    project.cogedim_code_operation = '';
}