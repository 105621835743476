import { createLogger } from '@core/services/logger.service';
import * as Orm from '@core/services/orm/orm';
import * as IdGenerator from '@core/services/orm/idGenerator';
import * as queryService from '@core/services/orm/query';
import { FileDescriptor } from '@core/services/file/file';

import WorkflowManager from '@archipad/backend/project/workflowManager';
import { PatchAttachmentDescriptor } from '@archipad/services/file/patchFile';

import { isBugWorkflowEnabled } from './workflowManagerHelper';
import { UserInfo, NetPatchData, NetPatchOperation, NetPatchOperations } from './patch';
import { ProjectTemplateError } from '@archipad/errors/errors-archipad';
import { ProjectEntity } from '@archipad-models/models/ProjectEntity';
import { getLocalizationService } from '@core/services/translate';

const log = createLogger('projectTemplate');

/*----------------------------------------------------------------------------*/


/**
 *
 * -----------------------------------------------------------------
 * /!\ /!\ /!\ /!\ /!\ /!\ /!\ IMPORTANT /!\ /!\ /!\ /!\ /!\ /!\ /!\
 * -----------------------------------------------------------------
 * Some parts of this file are ported in the ProjectTemplate API
 * If you do modifications in this file, you ought to port it in PHP
 * -----------------------------------------------------------------
 *
 */


/*----------------------------------------------------------------------------*/
export interface TemplateHeader {
    diff: true,
    templateId: string,
    originTemplateIds: string[],
    minVersion: number,
    version: number,
    creationDate: number
}

export interface TemplateVersion {
    version: string;
    patch: TemplatePatch;
    manifest: { [entityId:string]: any };
}

export interface TemplatePatchHeaderRelations {
    [entityName:string]: {
        [relationName:string]: string;
    }
}

export interface TemplatePatchHeader {
    diff: true,
    minVersion: number,
    version: number,
    relations: TemplatePatchHeaderRelations
}

export interface TemplatePatchOperation {
    templateId?:string;
    _metadata?: { op: "create"|"update"|"delete" }
    [k:string]: unknown;
}

export type TemplatePatchOperations = [ string, ...Array<TemplatePatchOperation> ];

export type TemplatePatch = [ TemplatePatchHeader, ...Array<TemplatePatchOperations> ];

export type Template = [ TemplateHeader, ...Array<TemplateVersion>];

export interface TemplateEntity {
    entityName: string;
    template: { [field:string]:unknown };
}

export interface TemplateEntityAttachments {
    [attachmentKey:string]: string;
}

/**
 * Flatten the state of all the entities in a template
 *
 * @param templateVersion the input template version
 * @param entitiesById output entity state by id
 * @param attachmentsById output attachment state by id
 */
function flattenTemplateVersion(templateVersion:TemplateVersion, entitiesById:Map<string,TemplateEntity>, attachmentsById:Map<string,TemplateEntityAttachments>, ignoredEntities:string[]):void {
    const patchData = templateVersion.patch;
    for(let i=1;i<patchData.length;i++) {
        const operations = patchData[i] as TemplatePatchOperations;
        const entityName = operations[0];
        if ( ignoredEntities.indexOf(entityName) !== -1 )
            continue;

        for(let j=1;j<operations.length;j++) {
            const operation = operations[j] as TemplatePatchOperation;
            if(!operation._metadata) {
                // create
                let entity = entitiesById.get(operation.templateId);
                if(!entity) {
                    entity = {
                        template: {},
                        entityName: entityName
                    };
                    entitiesById.set(operation.templateId, entity);
                }
                for(const key in operation) {
                    if(key === '_metadata')
                        continue;
                    entity.template[key] = operation[key];
                }
            } else if(operation._metadata.op === 'update') {
                // update
                const entity = entitiesById.get(operation.templateId);
                if(!entity)
                    continue;   // ignore updates on non existing entities
                for(const key in operation) {
                    if(key === '_metadata')
                        continue;
                    entity.template[key] = operation[key];
                }
            } else if(operation._metadata.op === 'delete') {
                // delete
                entitiesById.delete(operation.templateId);
                attachmentsById.delete(operation.templateId);
            }

        }
    }

    // attachments
    const attachmentManifest = templateVersion.manifest;
    if(!attachmentManifest)
        return;

    for(const entityId in attachmentManifest) {
        const attachments = attachmentManifest[entityId];
        let entityAttachments = attachmentsById.get(entityId);

        for(const attachmentKey in attachments) {
            if(attachments[attachmentKey].deleted) {
                delete entityAttachments[attachmentKey];
            } else {
                if(!entityAttachments)
                    entityAttachments = {};
                attachmentsById.set(entityId, entityAttachments);

                entityAttachments[attachmentKey] = attachments[attachmentKey].url;
            }
        }

        let hasKey = false;
        for(const _attachmentKey in attachments) {
            hasKey = true;
            break;
        }

        if(entityAttachments && !hasKey)
            attachmentsById.delete(entityId);
    }
}

/**
 * Flatten the state of all the entities in a list of templates
 *
 * @param templates the input templates
 * @param entitiesById output entity state by id
 * @param attachmentsById output attachment state by id
 */
function flattenTemplates(templates:Template[], entitiesById:Map<string,TemplateEntity>, attachmentsById:Map<string,TemplateEntityAttachments>, ignoredEntities:string[]):void {
    const templateVersions:TemplateVersion[] = [];

    // collect all template version
    for(const template of templates) {
        // first element is template header, not relevant here
        for(let i = 1; i < template.length; i++ ) {
            const templateVersion = template[i] as TemplateVersion;
            templateVersions.push(templateVersion);
        }
    }

    // sort them by increasing timestamp
    templateVersions.sort(function(a, b) {
        if(a.version < b.version)
            return -1;
        else if(a.version > b.version)
            return 1;
        return 0;
    });

    // apply them in sequence
    for(const templateVersion of templateVersions)
        flattenTemplateVersion(templateVersion, entitiesById, attachmentsById, ignoredEntities);
}

/**
 * Make a new project template version
 *
 * @param patchId the template version identifier
 * @param ormContext orm context to fetch entities from
 * @param rootEntityId project id
 * @param baseTemplates base templates for the new template
 * @return a new project template version data
 */
export function makeTemplateVersion(templateId:string, patchId:string, ormContext:Orm.EntityContext, rootEntityId:string, baseTemplates:Template[]):{ version:TemplateVersion, attachmentsToCopy:{source:FileDescriptor, dest:string}[] } {
    const ignoredEntities = ['ProjectTemplateVersion', 'ProjectUser'];

    const entitiesById = new Map<string,any>();
    const attachmentsById = new Map<string,any>();

    const attachmentsToCopy:{source:FileDescriptor, dest:string}[] = [];

    // flatten the base templates in order to have a reference image of the entities
    flattenTemplates(baseTemplates, entitiesById, attachmentsById, ignoredEntities);

    const manifestData = {};
    let hasManifest:boolean = false;
    const relationData = {
        "ProjectTemplateVersion": {
            "project": "Project"
        }
    };

    // make patch header
    const patchData:TemplatePatch = [
        {
            diff: true,
            minVersion: 503,
            version: 503,
            relations: relationData
        }
    ];

    // for each entiy type
    const templateIdById = new Map<string,string>();
    const blockIndexByEntityName = new Map<string,number>();

    for(const entityType of ormContext.entityTypes.byIndex) {
        if(!entityType.export){ continue; }

        const entityName = entityType.entityName;

        if ( ignoredEntities.indexOf(entityName) !== -1 ){ continue; }

        const entities = ormContext.entitiesByType[entityName];
        const entitiesData:TemplatePatchOperations = [ entityName ];

        // for each entity instance
        for(const entityId in entities) {
            const entity = entities[entityId];

            let templateEntityId: string;
            if(entityId === rootEntityId) {
                templateEntityId = '_ROOT_';
            } else {
                templateEntityId = entity['templateId'];
                if(!templateEntityId){
                    templateEntityId = IdGenerator.makeUUID();    // assign template id if needed
                }
            }

            templateIdById.set(entityId, templateEntityId);

            const baseEntityData = entitiesById.get(templateEntityId);
            const baseEntityAttachments = attachmentsById.get(templateEntityId);

            // write entity data
            let entityData: {[key: string]: unknown} = null;
            for(const name in entityType.metadata) {
                const property = entityType.metadata[name];
                if(name === 'id' || name === 'templateId' || !property || !property.exportable){
                    continue;   // ignore non exportable properties
                }
                if(templateEntityId === '_ROOT_' && name === 'name'){
                    continue;   // ignore root entity name
                }

                let value: unknown;
                if(!property.relation) {
                    // simple properties
                    value = entity[name];
                } else if(property['single']) {
                    // maintain relations in headers                    
                    relationData[entityType.entityName] = {
                        ...relationData[entityType.entityName],
                        [name]: property['target'].entityName
                    };

                    // single relationships
                    const target = entity[name];
                    if(target){
                        value = templateIdById.get(target.id);
                    } else {
                        value = null;
                    } 
                } else {
                    // other
                    continue;
                }

                if ( baseEntityData && baseEntityData.template[name] !== undefined ) {
                    if ( baseEntityData.template[name] instanceof Date && value instanceof Date ) {
                        if ( baseEntityData.template[name].getTime() == value.getTime() ){
                            continue;
                        }
                    } else {
                        if(baseEntityData && baseEntityData.template[name] === value){
                            continue;
                        }
                    }
                }

                if(!entityData) {
                    entityData = {
                        templateId:templateEntityId
                    };

                    if ( baseEntityData ) {
                        // if the entity was previously deleted in the template and recreated for some reason, don't set the update flag since it's a recreation
                        if ( ! (baseEntityData.template._metadata && baseEntityData.template._metadata.op == 'delete') ) {
                            entityData._metadata = { op: 'update' };
                        }
                    }
                }
                entityData[name] = value;
            }

            // write attachments
            let attachmentManifest = null;
            const entityAttachments = entity.getAttachments();
            for(const attachmentKey in entityAttachments) {
                const sourceFile = entityAttachments[attachmentKey];

                // only attachments that are not from a template (attachments that were added to this project 
                // since the last instanciation). 
                //
                // HACK: We determine which attachment are from a project template by using the templateEntityId encoded in the path:
                //      `815195706193209364/$$$_ROOT_$$$photo.jpg` is from a project template
                //      `815195706193209364/photo.jg` is not
                //
                if(sourceFile instanceof PatchAttachmentDescriptor && sourceFile.attachmentPath.match(/\/(\$\$\$([a-zA-Z0-9]{32}|_ROOT_)\$\$\$.+)$/))
                    continue;   

                if(!attachmentManifest){
                    attachmentManifest = {};
                }

                const taggedFilename = `$$$${templateEntityId}$$$${attachmentKey}`;
                const destinationPath = `${patchId}/${templateEntityId}/${taggedFilename}`;

                attachmentsToCopy.push({source: sourceFile, dest: destinationPath});
                attachmentManifest[attachmentKey] = { url:`${templateEntityId}/${taggedFilename}` };
            }
            if(baseEntityAttachments) {
                for(const attachmentKey in baseEntityAttachments) {
                    // deleted attachments
                    if(attachmentKey in entityAttachments){
                        continue;
                    }

                    if(!attachmentManifest){
                        attachmentManifest = {};
                    }

                    attachmentManifest[attachmentKey] = { deleted:true };
                }
            }

            if(attachmentManifest && !entityData) {
                // when updating an attachment, mark the entity as updated
                entityData = {
                    templateId:templateEntityId,
                    _metadata: {
                        op: 'update'
                    }
                };
            }

            if(entityData){
                entitiesData.push(entityData);
            }

            if(attachmentManifest) {
                hasManifest = true;
                manifestData[templateEntityId] = attachmentManifest;
            }
        }

        if(entitiesData.length !== 1) {
            blockIndexByEntityName.set(entityName, patchData.length);
            patchData.push(entitiesData);
        }
    }

    // we iterated over all entities in context. Now find entities that were in template and not in context anymore
    const usedTemplateIds = new Set(templateIdById.values());
    entitiesById.forEach(function(entity, templateId) {
        if ( ! usedTemplateIds.has(templateId) ) {
            const deletedEntity:TemplatePatchOperation = {
                templateId: templateId,
                _metadata: {
                    op: 'delete'
                }
            };

            if ( ! blockIndexByEntityName.has(entity.entityName) ) {
                const index = patchData.length;
                blockIndexByEntityName.set(entity.entityName, index);
                patchData[index] = [entity.entityName];
            }

            const index = blockIndexByEntityName.get(entity.entityName);
            const operations = patchData[index] as TemplatePatchOperations;
            operations.push(deletedEntity);
        }
    });

    // Add template version entity
    patchData.push(["ProjectTemplateVersion", {
        //templateId: IdGenerator.generateDeterministicId(patchId, templateId, "uuid"),   // DOESNT WORK ! generates a id, not a uuid
        projectTemplateId: templateId,
        version: patchId,
        project: "_ROOT_"
    }]);

    return {
        version: {
            "version": patchId,
            "patch": patchData,
            "manifest": hasManifest ? manifestData : null
        },
        attachmentsToCopy: attachmentsToCopy
    };
}



function mergePatchHeader(patchData:NetPatchData, header:TemplatePatchHeader) {
    patchData[0].minVersion = Math.max(patchData[0].minVersion, header.minVersion);
    patchData[0].version = Math.max(patchData[0].version, header.version);

    if ( patchData[0].minVersion == 0 )
        throw new ProjectTemplateError("no min version in patch header");

    if ( patchData[0].version == 0 )
        throw new ProjectTemplateError("no version in patch header");
}

/**
 * Make a patch data from template data
 *
 * @param templateData content of a template.json file
 * @param projectId   the project id
 * @param projectName the project name
 * @param versionsToSkip array of versions to ignore
 * @return patch object with patch data, attachmentsManifest and originTemplateIds array
 */
export function templateToPatch(templateData:Template, patchHeaderEnv:{ client:string, user: UserInfo, templateIdStack:string }, projectId:string, projectName:string, versionsToSkip:string[], originTemplateIds, mapping?): {
    dependencies: string|undefined|null;
    patch: {
        patch: NetPatchData;
        attachmentManifest: {};
    };
} {
    let foundDependencies : string|undefined|null = undefined;

    const patchData:NetPatchData = [{ diff:true, minVersion: 0, version: 0 }];
    const manifest = {};

    for(const id in patchHeaderEnv) {
        patchData[0][id] = patchHeaderEnv[id];
    }

    mapping = mapping || [];

    let hasContent = false;

    const entityIndexes:{ [entityName:string]: number } = {};
    const entitiesById = {};

    const createdEntitiesById = {};

    const orderedEntitiesList = [];
    orderedEntitiesList.push('Project'); // Hack because template relations do not have Project

    const DELETE_EXISTING_ENTITIES_ON_UPDATE = false;

    const generateDeterministicId = (projectId, templateId) => IdGenerator.generateDeterministicId(projectId, templateId, "uuid");


    // a template can contains several versions
    // the first block is an header, so start at 1
    for ( let i = 1; i < templateData.length; i++ ) {
        const templateVersion = templateData[i] as TemplateVersion;

        const version = templateVersion.version;

        const patchBlock = templateVersion.patch || [];
        const manifestBlock = templateVersion.manifest || {};

        if ( versionsToSkip.indexOf(version) !== -1 ) {

            // feed mapping in case we refer an entity of this template in child templates
            patchBlock.filter((entityBlock, blockIndex) => blockIndex != 0 && entityBlock[0] != 'Project').forEach((entityBlock:TemplatePatchOperations) => {
                entityBlock.filter((source:TemplatePatchOperation, entityIndex) => entityIndex != 0 && source.templateId).forEach((source:TemplatePatchOperation) => {
                    mapping[source.templateId] = generateDeterministicId(projectId, source.templateId);
                });
            });

            continue;
        }

        hasContent = true;

        let relations:TemplatePatchHeaderRelations = {};
        let previousEntityIndex = 0;

        patchBlock.forEach(function(block, blockIndex) {
            if ( blockIndex == 0 ) {
                const headerBlock = block as TemplatePatchHeader;
                mergePatchHeader(patchData, headerBlock);
                relations = headerBlock.relations;

                Object.keys(relations).forEach(entityName => {
                    const entityIndex = orderedEntitiesList.indexOf(entityName);
                    if (entityIndex == -1) {
                        orderedEntitiesList.splice(++previousEntityIndex, 0, entityName);
                    } else {
                        previousEntityIndex = entityIndex;
                    }
                });

                return true;
            }

            const entityBlock = block as TemplatePatchOperations;
            const entityName = entityBlock[0];

            // different versions might use the same entity. Reuse entity block if already defined
            if ( ! entityIndexes[entityName] ) {
                let maxIndex = 0;
                for ( const k in entityIndexes )
                    if ( entityIndexes[k] > maxIndex )
                        maxIndex = entityIndexes[k];

                const newEntityIndex = maxIndex +1;
                entityIndexes[entityName] = newEntityIndex;
                patchData[newEntityIndex] = [entityName];
            }

            const currentBlockIndex = entityIndexes[entityName];


            // $entity is a visit or bug or ...
            for ( let i = 1; i < entityBlock.length; i++ ) {
                const source = entityBlock[i] as TemplatePatchOperation;
                let id;

                const templateId = source.templateId || null;
                if ( entityName == 'Project' ) {
                    id = projectId;
                } else if ( templateId ) {
                    id = generateDeterministicId(projectId, templateId);
                    mapping[templateId] = id;
                } else {
                    // for some entities, templateId is null, generate a random id for these entities
                    id = IdGenerator.generateId();
                }

                const op = source._metadata ? source._metadata.op : 'create'; // if no metadata given, it's a creation

                const patchOperations = patchData[currentBlockIndex] as NetPatchOperations;
                let entity = entitiesById[id];
                if ( ! entity ) {
                    entity = {
                        id: id
                    };
                    entitiesById[id] = entity;
                    patchOperations.push(entity);


                    if ( op == 'create' ) {
                        createdEntitiesById[id] = true;
                    }

                    if ( op == 'delete' && DELETE_EXISTING_ENTITIES_ON_UPDATE ) {   // delete existing entity on template upgrade (=> versionsToSkip is not empty so it's an upgrade)
                        // if it's a deletion, add the metadata and don't even try to copy data
                        entity._metadata = source._metadata;
                        continue;
                    }
                } else {
                    // entity defined in previous version of the same template, clean it up
                    if ( op == 'delete' ) {
                        delete entitiesById[id];

                        // iterate over data in patch
                        for ( let i = 1; i < patchOperations.length; i++ ) {
                            const patchOperation = patchOperations[i] as NetPatchOperation;
                            if ( patchOperation.id == id ) {
                                patchOperations.splice(i, 1);
                                break;
                            }
                        }

                        // nothing to be done more, entity removed from patch
                        continue;
                    }
                }

                for ( const fieldName in source ) {
                    if ( fieldName == 'id' ) {
                        // nothing to do
                        continue;
                    } else if( fieldName == '_metadata' ) {
                        // if there is the update flag and we created the entity in this template, ignore it since we flatten all data in the block
                        if ( source._metadata.op == 'update' && createdEntitiesById[id] ) {
                            continue;
                        } else {
                            entity[fieldName] = source[fieldName];
                        }
                    } else if ( relations[entityName] && relations[entityName][fieldName] && source[fieldName] ) {
                        // convert templateId in relation field by the real id
                        const targetEntity = relations[entityName][fieldName];
                        const sourceKey = source[fieldName] as string;
                        const target = (targetEntity == 'Project') ? projectId : mapping[sourceKey];
                        if ( target === undefined ) {
                            console.warn("Mapping to an undefined target "+ targetEntity + " ("+entityName+"."+fieldName+")");
                        } else {
                            entity[fieldName] = target;
                        }
                    } else {
                        // regular field
                        if ( source[fieldName] !== undefined )
                            entity[fieldName] = source[fieldName];
                    }
                }

                if ( entityName == 'Project' ) {
                    // special case for project
                    entity.name = projectName;
                    if ( originTemplateIds )
                        entity.originTemplateIds = originTemplateIds.join(",");
                    else
                        delete entity.originTemplateIds;

                    if ( entity.dependencies !== undefined )
                        foundDependencies = entity.dependencies;

                    delete entity.templateId;
                }
            }
        });

        for ( const entityTemplateId in manifestBlock ) {
            const entityManifest = manifestBlock[entityTemplateId];
            const entityId = entityTemplateId == '_ROOT_' ? projectId : IdGenerator.generateDeterministicId(projectId, entityTemplateId, "uuid");

            if ( ! manifest[entityId] )
                manifest[entityId] = {};

            for ( const attachmentKey in entityManifest ) {
                const entityAttachment = entityManifest[attachmentKey];
                if ( entityAttachment['deleted'] ) {
                    if ( ! versionsToSkip.length || (versionsToSkip.length && DELETE_EXISTING_ENTITIES_ON_UPDATE) ) {
                        manifest[entityId][attachmentKey] = {
                            deleted: true
                        };
                    }
                } else {
                    manifest[entityId][attachmentKey] = {
                        url: `${version}/${entityAttachment.url}`
                    };
                }
            }

        }

    }

    const header = patchData.shift();
    patchData.sort((a, b) => orderedEntitiesList.indexOf(a[0]) - orderedEntitiesList.indexOf(b[0]));
    patchData.unshift(header);

    return {
        dependencies: foundDependencies,
        patch: hasContent ? {
            patch: patchData,
            attachmentManifest: manifest
        } : null
    };
}








/*----------------------------------------------------------------------------*/


function insertTemplateVersionEntity(patch, project, templateId, templateVersion) {
    if ( hasTemplateVersion(project, templateId, templateVersion) )
        throw new ProjectTemplateError("Trying to insert an existing templateVersion entity");

    insertEntityInUpgradePatch(patch, "ProjectTemplateVersion", IdGenerator.generateId(), { projectTemplateId: templateId, version: templateVersion, project: project.id}, false);
}

function insertEntityInUpgradePatch(patch, entityName, entityId, value, isUpdate, isDelete?) {
    let entityBlock = null;
    for ( let i = 1; i < patch.length; i++ ) {
        if ( patch[i][0] == entityName ) {
            entityBlock = patch[i];
            break;
        }
    }
    if ( ! entityBlock ) {
        entityBlock = [entityName];
        patch.push(entityBlock);
    }

    let entity = null;
    for ( let i = 1; i < entityBlock.length; i++ ) {
        if ( entityBlock[i].id == entityId ) {
            entity = entityBlock[i];
        }
    }

    if ( ! entity ) {
        entity = {
            id: entityId
        };
        entityBlock.push(entity);
    }

    for ( const k in value ) {
        entity[k] = value[k];
    }

    if ( isDelete ) {
        entity['_metadata'] = { op:'delete'};
    }
    else if ( isUpdate ) {
        entity['_metadata'] = { op:'update'};
    }
}

function addTemplateIdsToExistingEntities(patch, project, equalityTests) {
    const context = project.getContext();

    for ( const equalityTest of equalityTests ) {
        const query = queryService.query(context, equalityTest.entityName);
        query.predicateWithFormat(equalityTest.predicate);
        const resultSet = query.execute({});
        const count = resultSet.count();

        if ( count == 0 ) {
            console.log('Can\'t find entity for ', equalityTest);
            if ( equalityTest.errorIfNotFound )
            throw new ProjectTemplateError("Upgrade error : no entity found for predicate. Please contact support");
        } else {
            if ( count > 1 && ! equalityTest.multipleAllowed ) {
                throw new ProjectTemplateError("Upgrade error : multiple entities found for predicate. Please contact support");
            } else {
                const entity = resultSet.firstEntity();
                if ( ! entity['templateId'] ) {
                    //entity.templateId = equalityTest.templateId
                    insertEntityInUpgradePatch(patch, equalityTest.entityName, entity.id, { templateId: equalityTest.templateId}, true);
                } else {
                    console.log("entity already has a template ID, ignoring")
                }
            }
        }
    }
}


function hasTemplateVersion(project, templateId, templateVersion) {
    for ( const templateVersionEntity of project.templateVersions ) {
        if ( templateVersionEntity.projectTemplateId == templateId && templateVersionEntity.version == templateVersion )
            return true;
    }

    return false;
}



// UPGRADE PART

function getProjectLanguage(project: ProjectEntity) {
    let language: string = project.language;
    if ( ! language ) {
        language = getLocalizationService().locale.language;
        log.warn("project language not found. using user default | language:", language);
    }
    if ( ! language ) {
        language = 'en';
        log.warn("project language not found. using system default | language:", language);
    }

    if ( ! BASE_TEMPLATE_UPGRADE[language] ) {
        // fallback to a known language
        language = 'en';

        log.warn("project language unknown. using system default | language:", language);
    }

    return language;
}


const UPGRADE_GENERALITIES_MAP = {
    // generalities map
    templateId: 'DF7428A16F40C873C438D24A3B80D929',
    entityName: 'Map',
    predicate: 'type == 1 && fileType == 0',
    errorIfNotFound: true,
    multipleAllowed: false
};
const UPGRADE_OWNER = {
    // Maitre d'ouvrage
    templateId: '1B31415D05B486456BB7FEDAF80EA5F5',
    entityName: 'ProjectMember',
    predicate: 'systemType == false && ('
    + 'type == "Maitre d\'ouvrage"' // lang:fr
    + ' || type == "Owner"' // lang:en
    + ' || type == "Bauherr"' // lang:de
    + ' || type == "Propietario"' // lang:es
    + ')',
    errorIfNotFound: false,
    multipleAllowed: true
};
const UPGRADE_OWNERS_ASSISTANT = {
    // Maitre d'oeuvre
    templateId: 'E621D081F502C8BFD006E0B377EA651D',
    entityName: 'ProjectMember',
    predicate: 'systemType == false && ('
    + 'type == "Maitre d\'oeuvre"' // lang:fr
    + ' || type == "Owner\'s assistant"' // lang:en
    + ' || type == "Bauleiter"' // lang:de
    + ' || type == "Supervisor"' // lang:es
    + ')',
    errorIfNotFound: false,
    multipleAllowed: true
};
const UPGRADE_SITE_MEETING = {
    // Visite de chantier
    templateId: 'FEF465603E19F163C9A1103E117AF6FC',
    entityName: 'VisitType',
    predicate: 'isDefault == true',  // TODO Do we need to check name ?
    errorIfNotFound: true,
    multipleAllowed: false
};
const UPGRADE_REMARKSTYLE_NORMAL = {
    // RemarkStyle Normal
    templateId: '43A47DAC002FB63276E717C568DC2F75',
    entityName: 'RemarkStyle',
    // we don't check isDefault because of a cloud bug early 2017
    predicate: 'name == "Normal" && color == 0 && style == 0',
    errorIfNotFound: false,
    multipleAllowed: true
};
const UPGRADE_REMARKSTYLE_URGENT = {
    // RemarkStyle Urgent
    templateId: '7A13E1408F2C4A373D37EE9211026D72',
    entityName: 'RemarkStyle',
    predicate: 'name == "Urgent" && color == -65536 && style == 1',
    errorIfNotFound: false,
    multipleAllowed: true
};
const UPGRADE_REMARKSTYLE_IMPORTANT = {
    // RemarkStyle Important
    templateId: 'C32AE167E71A20FB015E4D95FE810897',
    entityName: 'RemarkStyle',
    predicate: 'name == "Important" && color == 0 && style == 1',
    errorIfNotFound: false,
    multipleAllowed: true
};


function makeBaseTemplateUpgrade(metaTemplateData) {
    return {
        templateId: metaTemplateData.templateId,
        upgradeData : [
            {
                // initial data
                version: metaTemplateData.upgradeData[0].version,
                upgradeTest: function(project) { return true; },
                entitiesToUpdate: [
                    UPGRADE_GENERALITIES_MAP,
                    UPGRADE_OWNER,
                    UPGRADE_OWNERS_ASSISTANT,
                    UPGRADE_SITE_MEETING,
                    UPGRADE_REMARKSTYLE_NORMAL,
                    UPGRADE_REMARKSTYLE_URGENT,
                    UPGRADE_REMARKSTYLE_IMPORTANT
                ]
            },
    
            // WF V1
            {
                version:  metaTemplateData.upgradeData[1].version,
                upgradeTest: function(project) { return isBugWorkflowEnabled(project); },
                entitiesToUpdate: []    // nothing to actually update, just add the appropriate TemplateVersion entity
            },
    
            // WF V2
            {
                version:  metaTemplateData.upgradeData[2].version,
                upgradeTest: function(project) { return WorkflowManager.hasWorkflowRights(project); },
                entitiesToUpdate: []    // nothing to actually update, just add the appropriate TemplateVersion entity
            }
        ]
    };    
}


const BASE_TEMPLATE_UPGRADE_FR = makeBaseTemplateUpgrade({
    templateId: "9FB933D99C4D4AE2AB79E60D6FBEF371",
    upgradeData : [
        {
            // initial data
            version: "0000015d8ab1a038.5947a731",
        },

        // WF V1
        {
            version: "0000015d8abf38b0.0534ae7c",
        },

        // WF V2
        {
            version: "0000015d8af72130.13db248e",
        }
    ]
});


const BASE_TEMPLATE_UPGRADE_EN = makeBaseTemplateUpgrade({
    templateId: "3C9A5A472A59526BABB64C84365304E5",
    upgradeData : [
        {
            // initial data
            version: "0000015d9f703408.9e4d9d87",
        },

        // WF V1
        {
            version: "0000015d9f742f90.8442cd38",
        },

        // WF V2
        {
            version: "0000015d9f77f080.352fc24c",
        }
    ]
});


const BASE_TEMPLATE_UPGRADE_DE = makeBaseTemplateUpgrade({
    templateId: "70282E3CA73A4B01884201F504FCA194",
    upgradeData : [
        {
            // initial data
            version: "000001620b42cf3f.be483f2c",
        },

        // WF V1
        {
            version: "000001620b42f653.e95e8a54",
        },

        // WF V2
        {
            version: "000001620b4330f6.dab13b7f",
        }
    ]
});


const BASE_TEMPLATE_UPGRADE_ES = makeBaseTemplateUpgrade({
    templateId: "BD9C8DFE3F2E41EDBFA84627AAEDBC87",
    upgradeData : [
        {
            // initial data
            version: "000001620b42949f.75deb474",
        },

        // WF V1
        {
            version: "000001620b42e2c8.5cd2b04c",
        },

        // WF V2
        {
            version: "000001620b4309df.b6fce2c4",
        }
    ]
});

const BASE_TEMPLATE_UPGRADE_IT = makeBaseTemplateUpgrade({
    templateId: "E7FE37D7D4ECA8E15E8EF1E460022169",
    upgradeData : [
        {
            // initial data
            version: "000001620b42cf3f.be483f2d",
        },

        // WF V1
        {
            version: "000001620b42f653.e95e8a55",
        },

        // WF V2
        {
            version: "000001620b4330f6.dab13b80",
        }
    ]
});


const BASE_TEMPLATE_UPGRADE = {
    fr: BASE_TEMPLATE_UPGRADE_FR,
    en: BASE_TEMPLATE_UPGRADE_EN,
    de: BASE_TEMPLATE_UPGRADE_DE,
    es: BASE_TEMPLATE_UPGRADE_ES,
    it: BASE_TEMPLATE_UPGRADE_IT,
}

/**
 * Get an "initial template upgrade" patch info.
 * 
 * The so called "initial template upgrade" is the one that introduced the
 * template ids on certain system entities and the project template versions of
 * workflow v1 and v2.
 * 
 * It DOES NOT upgrades the project with workflow v1 etc. it just adds template
 * ids on the needed entities and add {@link ProjectTemplateVersion} entities in
 * the project
 * 
 * @param project 
 */
export function getInitialTemplateUpgrade(project: ProjectEntity) {
    const language = getProjectLanguage(project);
    const upgradeInstructions = BASE_TEMPLATE_UPGRADE[language];

    let didPerformUpgrade = false;

    const patchOperations = [];

    const instanciatedVersions = {};
    instanciatedVersions[upgradeInstructions.templateId] = [];

    // workaround a language bug
    if ( project.originTemplateIds == "3C9A5A472A59526BABB64C84365304E5" && language != "en" ) {
        // wrong origin, fix it
        didPerformUpgrade = true;

        insertEntityInUpgradePatch(patchOperations, "Project", project.id, { originTemplateIds: upgradeInstructions.templateId}, true);
        project.templateVersions.forEach(templateVersion => {
            if (templateVersion.projectTemplateId == "3C9A5A472A59526BABB64C84365304E5") {
                insertEntityInUpgradePatch(patchOperations, "ProjectTemplateVersion", templateVersion.id, {}, false, true);
            }
        });
    }

    if ( ! project.originTemplateIds ) {
        // inject base templateId if not inserted (old Archipad client)
        insertEntityInUpgradePatch(patchOperations, "Project", project.id, { originTemplateIds: upgradeInstructions.templateId}, true);
        didPerformUpgrade = true;
    }

    for ( const templateUpgrade of upgradeInstructions.upgradeData ) {
        if ( templateUpgrade.upgradeTest(project) && ! hasTemplateVersion(project, upgradeInstructions.templateId, templateUpgrade.version)) {
            addTemplateIdsToExistingEntities(patchOperations, project, templateUpgrade.entitiesToUpdate);
            insertTemplateVersionEntity(patchOperations, project, upgradeInstructions.templateId, templateUpgrade.version);
            instanciatedVersions[upgradeInstructions.templateId].push(templateUpgrade.version);
            didPerformUpgrade = true;
        }
    }

    const patch: NetPatchData = [
        {
            diff: true,
            version: 503,
            minVersion: 503,
        },
        ...patchOperations,
    ];

    return {
        didPerformUpgrade: didPerformUpgrade,
        patch: {
            patch: patch,
            attachmentManifest: {},
        },
        templateId: upgradeInstructions.templateId,
        instanciatedVersions: instanciatedVersions,
    };
}

/**
 * Check if the project need an "initial template" upgrade.
 * 
 * The so called "initial template upgrade" is the one that introduced the
 * template ids on certain system entities and the project template versions of
 * workflow v1 and v2.
 * 
 * @param project - The project to check
 */
export function isInitialTemplateUpgradeAvailable(project: ProjectEntity): boolean {
    // make sure the project has at least the base template
    const language = getProjectLanguage(project);
    const upgradeInstructions = BASE_TEMPLATE_UPGRADE[language];

    for ( const templateUpgrade of upgradeInstructions.upgradeData ) {
        // we should also check templateUpgrade.upgradeTest for coherency but templateUpdateAvailable would return true anyway
        if ( ! hasTemplateVersion(project, upgradeInstructions.templateId, templateUpgrade.version)) {
            return true;
        }
    }

    return false;
}
