import { Driver, Navigate, AppReloadEvent } from "../navigation";
import { IllegalArgumentError } from "@core/errors/errors-core";

class CloudNavigationDriver implements Driver {
    navigate(params:Navigate) {
        const mainController = document.querySelector('main-controller');
        
        let detail;
        switch(params.controller) {
            case 'Form': {
                detail = { url: `/${params.projectId}/Forms/list/f=${params.formId};t=SCREEN;i=${params.fieldId};`};
                break;
            } 
            case 'Bug': {
                detail = { url: `/${params.projectId}/Issues/list/m=${params.mapId};|${params.bugId}`};
                break;
            } 
            case 'Report': {
                if(params.insideProject === true){
                    detail = {
                        replace: true,
                        url: `/${params.projectId}/Reports/reports|auto#/Report/${params.reportId}/info`
                    };
                } else if(params.insideProject === false){
                    // Is a success, but on project list, report is not adopted until opening ! Switch to report list
                    detail = {
                        replace: true,
                        url: `/${params.projectId}/Reports/reports`
                    };
                } else {
                    detail = { 
                        url: `/${params.projectId}/Reports/reports#/Report/${params.reportId}/info`
                    };
                }
                break;
            }
            case 'Upgrade': {
                detail = { url: '/upgrade', popup: true };
                break;
            }
            case 'Renew': {
                detail = { url: '/renew', popup: true };
                break;
            }
            case 'ReportConfigurator': {
                detail = { url: `/${params.projectId}/Reports/configure/` + (params.templateId ? `t=${params.templateId};` : ``)};
                break;
            }
            case 'FormGallery': {
                detail = { url: `/FormGallery` };
                break;
            }
            default:
                throw new IllegalArgumentError(`No such navigation controller: ${params['controller']}`);
        }

        const evnt = new CustomEvent('navigate', { 
            bubbles: true, 
            cancelable: true, 
            composed: true,
            detail
        });
        mainController.dispatchEvent(evnt);
    }

    reload(force?: boolean): void {
        const mainController = document.querySelector('main-controller');
        if (!mainController) {
            document.location.reload();
        }

        const event: AppReloadEvent = new CustomEvent('app-reload', {
            bubbles: true,
            cancelable: false,
            composed: true,
            detail: {
                force: Boolean(force),
            },
        });

        mainController.dispatchEvent(event);
    }
}

export const driver = new CloudNavigationDriver();