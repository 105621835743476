import { Observable, defer } from 'rxjs';
import { map } from 'rxjs/operators';

import * as S3 from "aws-sdk/clients/s3";

import {
    S3FileDriver,
    S3Context,
    S3ProjectPathDescriptor,
    S3ReportTemplatePathDescriptor,
    S3ReportPathDescriptor,
    makeEndpoint,
    S3ReportTemplateFileTypes,
    NEW_REPORT_TEMPLATE_ID,
    S3DumpPatchPathDescriptor,
    S3Credentials,
    S3DumpPatchFileType,
} from '@core/services/file/s3file';

import { RemoteProjectInfo, getProjectInfo, getStorageInfo, StorageInfo, S3Creds } from '@archipad/backend/archiweb/synchroBackend';
import { listReportTemplates, ReportTemplate } from "@archipad/backend/archiweb/resourceTemplate";
import { convertIdToHex16, convertIdToHex32 } from '@core/helpers/entityId';
import { IllegalArgumentError, IllegalStateError } from '@core/errors/errors-core';
import { DEFAULT_REPORT_TEMPLATE_ID } from '@archipad/services/report/models/ArchipadReportTemplateConfig';
import _LegacyServiceManager from "@core/services/legacy-service-manager";

import '@archipad/backend/archiweb/patchFileResolver';
import { getAccountService } from '@archipad/services/account.service';

/*---------------------------------------------------------------------------*/
interface ProjectInfoCacheEntry {
    projectInfo: RemoteProjectInfo
    
    expiration: number;
    endPointByRegionAndSessionToken: Map<string, Map<string, S3>>;
    projectInfoRequest: Promise<ProjectInfoCacheEntry>;
}

// 
const _projectInfoCache = new Map<string, ProjectInfoCacheEntry>()

function getProjectInfoCacheEntry(projectId:string): Promise<ProjectInfoCacheEntry> {
    let cacheEntry = _projectInfoCache.get(projectId);
    if(cacheEntry && cacheEntry.expiration > new Date().getTime()) {
        return cacheEntry.projectInfoRequest;
    }
    
    // create the cache entry
    cacheEntry = {
        projectInfo: null,
        expiration: new Date().getTime()+60*1000,   // set a temporary expiration date so that API has enough time to respond
        endPointByRegionAndSessionToken: new Map<string, Map<string, S3>>(),
        projectInfoRequest: null
    };

    const projectInfoRequest = getProjectInfo(null, {id: projectId})
        .then(function(projectInfo) {
            cacheEntry.projectInfo = projectInfo;
            // adjust expiration time (minus one hour margin)
            cacheEntry.expiration = new Date(projectInfo.credentials.Expiration).getTime() - 60-60*1000;

            return cacheEntry;
        })
        .catch(function(err) {
            // expire the cache entry in case of error
            cacheEntry.expiration = 0;
            throw err;
        });
    cacheEntry.projectInfoRequest = projectInfoRequest;

    // add to cache
    _projectInfoCache.set(projectId, cacheEntry);

    return cacheEntry.projectInfoRequest;
}


function getOrCreateEndpoint(cacheEntry, context: S3Context, creds: { AccessKeyId: string; SecretAccessKey: string; SessionToken: string; Expiration: number } | S3Credentials){
    let endpoint = cacheEntry.endPointByRegionAndSessionToken.get(context.region)?.get(creds.SessionToken);
    if (endpoint){
        return endpoint;
    }
    if(!endpoint) {
        const endpoint = makeEndpoint({creds, region: context.region});
        if (!cacheEntry.endPointByRegionAndSessionToken.has(context.region)) {
            cacheEntry.endPointByRegionAndSessionToken.set(context.region, new Map());
        }
        cacheEntry.endPointByRegionAndSessionToken.get(context.region).set(creds.SessionToken, endpoint);
        return endpoint;
    }
}
/*---------------------------------------------------------------------------*/

interface ReportTemplateInfoCacheEntry {
    reportStorageInfo: StorageInfo | null;
    reportTemplatesInfo: {
        [reportTemplateId: string]: {
            token: S3Creds;
            imagesPrefix: string;
            bucket: string;
            region: string;
        };
    };
    expiration: number;
    endPointByRegionAndSessionToken: Map<string, Map<string, S3>>;
    reportTemplateInfoRequest: Promise<ReportTemplateInfoCacheEntry> | null;
}

let _reportStorageInfoCache:ReportTemplateInfoCacheEntry|null = null;

function _updateReportTemplateInfo(reportTemplates: ReadonlyArray<ReportTemplate>) {
    _reportStorageInfoCache.reportTemplatesInfo = reportTemplates.reduce((reportTemplatesInfo, reportTemplate) => {
        reportTemplatesInfo[reportTemplate.id] = {
            token: reportTemplate.token,
            bucket: reportTemplate.bucket,
            region: reportTemplate.region,
            imagesPrefix: reportTemplate.imagesPrefix
        };
        return reportTemplatesInfo;
    }, {} as ReportTemplateInfoCacheEntry['reportTemplatesInfo']);
}

async function getReportTemplateInfoCacheEntry(reportTemplateId: string | null, projectId: string | null): Promise<ReportTemplateInfoCacheEntry> {
    if(_reportStorageInfoCache && _reportStorageInfoCache.expiration > Date.now() && _reportStorageInfoCache.reportTemplateInfoRequest) {
        if (reportTemplateId && !(reportTemplateId in _reportStorageInfoCache.reportTemplatesInfo)) {
            _updateReportTemplateInfo(await listReportTemplates(null, projectId));
        }
        return _reportStorageInfoCache.reportTemplateInfoRequest;
    }
   
    // create the cache entry
    _reportStorageInfoCache = {
        reportStorageInfo: null,
        reportTemplatesInfo: {},
        expiration: new Date().getTime()+60*1000,   // set a temporary expiration date so that API has enough time to respond
        endPointByRegionAndSessionToken: new Map(),
        reportTemplateInfoRequest: null
    };

    const reportTemplateInfoRequest = Promise.all([getStorageInfo(null, null), projectId ? listReportTemplates(null, projectId) : []])
        .then(([storageInfo, reportTemplates]) => {
            _reportStorageInfoCache.reportStorageInfo = storageInfo;

            _updateReportTemplateInfo(reportTemplates);

            // adjust expiration time (minus one hour margin)
            _reportStorageInfoCache.expiration = new Date(storageInfo.credentials.Expiration).getTime() - 60 - 60 * 1000;

            return _reportStorageInfoCache;
        })
        .catch(function (err) {
            // expire the cache entry in case of error
            _reportStorageInfoCache.expiration = 0;
            throw err;
        });
    
    _reportStorageInfoCache.reportTemplateInfoRequest = reportTemplateInfoRequest;

    return _reportStorageInfoCache.reportTemplateInfoRequest;
}

/*---------------------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

function resolveS3ProjectLocation(location:S3ProjectPathDescriptor):Observable<S3Context> {
    return defer(function() { return getProjectInfoCacheEntry(location.projectId); }).pipe(
        map(function(cacheEntry) {
            const context:S3Context = {
                endpoint: null,
                region: null,
                bucket: null,
                keyPrefix: null,
                acl: 'private'
            };

            const projectInfo = cacheEntry.projectInfo;
            switch(location.s3Type) {
                case 'patch': {
                    context.region = projectInfo.patches.region;
                    context.bucket = projectInfo.patches.bucket;
                    context.keyPrefix = projectInfo.patches.path;
                    if(location.acl)
                        context.acl = location.acl;
                } break;
                case 'temp': {
                    context.region = projectInfo.temporary.region;
                    context.bucket = projectInfo.temporary.bucket;
                    context.keyPrefix = projectInfo.temporary.path;
                    if(location.acl)
                        context.acl = location.acl;
                } break;
                case 'upload': {
                    /**
					 * BIG HACK to fix the upload path missing hexadecimal projectId subfolder.
					 *
					 * @todo remove that uglyness and fix the upload path on API side?
					 */
					const hexProjectId = convertIdToHex16(location.projectId);
                    const keyPrefix = `${projectInfo.upload.path}/${hexProjectId}`;
                    
                    context.region = projectInfo.upload.region;
                    context.bucket = projectInfo.upload.bucket;
                    context.keyPrefix = keyPrefix;
                    context.policy = projectInfo.upload.policy;
                    context.signature = projectInfo.upload.signature;
                    if(location.acl)
                        context.acl = location.acl;
                } break;
                default:
                    throw new IllegalArgumentError(`No such s3Type: ${location.s3Type}`);
            }
            context.endpoint = getOrCreateEndpoint(cacheEntry,context,projectInfo.credentials)
            return context;
        })
    );
}
function resolveS3DumpPatchLocation(location:S3DumpPatchPathDescriptor):Observable<S3Context> {
    return defer(function() { return getProjectInfoCacheEntry(location.projectId); }).pipe(
        map(function(cacheEntry) {
            const context:S3Context = {
                endpoint: null,
                region: null,
                bucket: null,
                keyPrefix: null,
                acl: 'private',
            };

            const projectInfo = cacheEntry.projectInfo;
            const credentials = projectInfo.projectCaches.credentials;
            switch(location.s3Type) {
                case S3DumpPatchFileType.VALUE: {
                    context.region = projectInfo.projectCaches.region;
                    context.bucket = projectInfo.projectCaches.bucket;
                    context.keyPrefix = projectInfo.projectCaches.path;
                    if(location.acl){
                        context.acl = location.acl;
                    }
                } break;
                default:
                    throw new IllegalArgumentError(`No such s3Type: ${location.s3Type}`);
            }

            /**
             * We need that the reference on the end point be the same, because higher level of cache depends on the reference of the endpoint.
             * This is why we use the sessionToken for indexing ours endpoints, because if we use create instead of get or create strategy, the reference will changed.
             */
            context.endpoint = getOrCreateEndpoint(cacheEntry, context, credentials)
            return context;
        })
    );
}

function resolveS3ReportLocation(location:S3ReportPathDescriptor):Observable<S3Context> {
    return defer(function() { return getProjectInfoCacheEntry(location.projectId); }).pipe(
        map(function(cacheEntry) {
            const context:S3Context = {
                endpoint: null,
                region: null,
                bucket: null,
                keyPrefix: null,
                acl: 'private',
            };

            const projectInfo = cacheEntry.projectInfo;
            const hexReportID = convertIdToHex32(location.reportId);
            context.region = projectInfo.reportGeneration.region;
            context.bucket = projectInfo.reportGeneration.bucket;
            if(location.acl){
                context.acl = location.acl;
            }

            switch(location.s3Type) {
                case 'reportOutput':{
                    context.keyPrefix = `${projectInfo.reportGeneration.path}/${hexReportID}/output`;
                    break;
                }
                case 'reportExport': {
                    context.keyPrefix = `${projectInfo.reportGeneration.path}/${hexReportID}/export`;
                    break;
                }
                case 'reportCustomResource': {
                    // TODO XXX question : template (since it is copied in the `template` folder of the report generator) OR customResource (since report + [name])
                    context.keyPrefix = `${projectInfo.reportGeneration.path}/${hexReportID}/template`;
                    break;
                }
                default:
                    throw new IllegalArgumentError(`No such s3Type: ${location.s3Type}`);
            }

            context.endpoint = getOrCreateEndpoint(cacheEntry, context, projectInfo.credentials)
            return context;
        })
    );
}

function resolveS3ReportTemplateLocation(location:S3ReportTemplatePathDescriptor):Observable<S3Context> {
    const isNewReportTemplateId = location.reportTemplateId === NEW_REPORT_TEMPLATE_ID || location.reportTemplateId === DEFAULT_REPORT_TEMPLATE_ID;
    const reportTemplateIdOrNull = location.reportTemplateId === NEW_REPORT_TEMPLATE_ID ? null : location.reportTemplateId;
    return defer(function() { return getReportTemplateInfoCacheEntry(reportTemplateIdOrNull, location.projectId); }).pipe(
        map(function(cacheEntry) {
            const reportStorageInfo = cacheEntry.reportStorageInfo;
            const reportTemplateInfo = !isNewReportTemplateId ? cacheEntry.reportTemplatesInfo[reportTemplateIdOrNull] : null;

            const context:S3Context = {
                endpoint: null,
                region: null,
                bucket: null,
                keyPrefix: null,
                acl: 'private',
            };

            let creds:S3Creds;

            switch(location.s3Type) {
                case S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX: {
                    if (isNewReportTemplateId) {
                        context.region = reportStorageInfo.region;
                        context.bucket = reportStorageInfo.bucket;
                        context.keyPrefix = reportStorageInfo.reportTemplatesImagesPrefix;
                        creds = reportStorageInfo.creds;
                    } else {
                        if (!reportTemplateInfo) {
                            console.error(`Unable to find report template info for report template ${reportTemplateIdOrNull}`);
                            throw new IllegalStateError(`Unable to find report template info`)
                        }
                        context.region = reportTemplateInfo.region;
                        context.bucket = reportTemplateInfo.bucket;
                        context.keyPrefix = reportTemplateInfo.imagesPrefix;
                        creds = reportTemplateInfo.token;
                    }
                    if (location.acl) {
                        context.acl = location.acl;
                    }
                } break;
                case S3ReportTemplateFileTypes.REPORT_TEMPLATE_UPLOAD: {
                    context.region = reportStorageInfo.upload.region;
                    context.bucket = reportStorageInfo.upload.bucket;
                    context.keyPrefix = reportStorageInfo.upload.path;
                    context.policy = reportStorageInfo.upload.policy;
                    context.signature = reportStorageInfo.upload.signature;
                    creds = reportStorageInfo.creds;
                    if(location.acl)
                        context.acl = location.acl;
                } break;
                case S3ReportTemplateFileTypes.REPORT_TEMPLATE_TRANSIENT: {
                    context.region = reportStorageInfo.region;
                    context.bucket = reportStorageInfo.bucket;
                    context.keyPrefix = reportStorageInfo.reportTemplatesImagesPrefix.concat('/transient');
                    creds = reportStorageInfo.creds;
                    if(location.acl)
                        context.acl = location.acl;
                } break;
                default:
                    throw new IllegalArgumentError(`No such s3Type: ${location.s3Type}`);
            }

            console.log(`${location} => ${JSON.stringify(context, null, 2)}`);

            /**
             * We need that the reference on the end point be the same, because higher level of cache depends on the reference of the endpoint.
             * This is why we use the sessionToken for indexing ours endpoints, because if we use create instead of get or create strategy, the reference will changed.
             */
            context.endpoint = getOrCreateEndpoint(cacheEntry, context, creds);
            return context;
        })
    );
}

/*---------------------------------------------------------------------------*/

// Set the location resolvers for the S3FileDriver

S3FileDriver.setLocationResolver('patch', resolveS3ProjectLocation);
S3FileDriver.setLocationResolver('temp', resolveS3ProjectLocation);
S3FileDriver.setLocationResolver('upload', resolveS3ProjectLocation);
S3FileDriver.setLocationResolver(S3ReportTemplateFileTypes.REPORT_TEMPLATE_IMAGES_PREFIX, resolveS3ReportTemplateLocation);
S3FileDriver.setLocationResolver(S3ReportTemplateFileTypes.REPORT_TEMPLATE_UPLOAD, resolveS3ReportTemplateLocation);
S3FileDriver.setLocationResolver(S3ReportTemplateFileTypes.REPORT_TEMPLATE_TRANSIENT, resolveS3ReportTemplateLocation);
S3FileDriver.setLocationResolver('reportExport', resolveS3ReportLocation);
S3FileDriver.setLocationResolver('reportCustomResource', resolveS3ReportLocation);
S3FileDriver.setLocationResolver('reportOutput', resolveS3ReportLocation);
S3FileDriver.setLocationResolver(S3DumpPatchFileType.VALUE, resolveS3DumpPatchLocation);

// clear the cache if the user info changes
_LegacyServiceManager.waitForReady().then(() => {
    getAccountService().currentUserInformation$.subscribe(() => {
        _projectInfoCache.clear(); 
        _reportStorageInfoCache = null;
    });
}).catch(() => {});
