/**
 * Returns a Set containing all elements of given sets.
 *
 * @example
 * getSetUnion(new Set([1, 2]), new Set([2, 3])); // output: Set(3) {1, 2, 3}
 */
export function getSetUnion<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    const _union = new Set(setA);
    for (const elem of setB) {
        _union.add(elem);
    }
    return _union;
}

/**
 * Returns a Set containing elements that are only in both given sets.
 *
 * @example
 * const a = new Set([1, 2]);
 * const b = new Set([2, 3]);
 * getSetIntersection(a, b); // output: Set(1, 3) {2}
 */
export function getSetIntersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    const _intersection = new Set<T>();
    for (const elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
}

/**
 * Returns a Set containing elements of given setA that are not in given setB.
 *
 * @example
 * const a = new Set([1, 2]);
 * const b = new Set([2, 3]);
 * getSetDifference(a, b); // output: Set(1) {1}
 */
export function getSetDifference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    if (setA === setB) {
        return new Set();
    }

    const _difference = new Set(setA);
    for (const elem of setB) {
        _difference.delete(elem);
    }
    return _difference;
}

/**
 * Returns a Set containing elements that are not strictly in both given Set.
 *
 * @example
 * const a = new Set([1, 2]);
 * const b = new Set([2, 3]);
 * getSetDifference(a, b); // output: Set(2) {1, 3}
 */
export function getSetSymmetricDifference<T>(setA: Set<T>, setB: Set<T>): Set<T> {
    if (setA === setB) {
        return new Set();
    }

    const _difference = new Set(setA);
    for (const elem of setB) {
        if (_difference.has(elem)) {
            _difference.delete(elem);
        } else {
            _difference.add(elem);
        }
    }
    return _difference;
}

/**
 * Returns whether or not the given Sets content are strictly equal.
 */
export function isSetEqual<T>(setA: Set<T>, setB: Set<T>): boolean {
    if (setA === setB) {
        return true;
    }

    if (setA.size !== setB.size) {
        return false;
    }

    for (const elem of setA) {
        if (!setB.has(elem)) {
            return false;
        }
    }

    return true;
}

/**
 * Returns whether or not all elements of given subset are in the given superset.
 *
 * @example
 * isSetSuperset(new Set([1, 2]), new Set([1]));    // output: true
 * isSetSuperset(new Set([1, 2]), new Set([1, 3])); // output: false
 */
export function isSetSuperset<T>(superset: Set<T>, subset: Set<T>): boolean {
    if (superset === subset) {
        return true;
    }

    for (const elem of subset) {
        if (!superset.has(elem)) {
            return false;
        }
    }
    return true;
}
