import { IllegalArgumentError } from '@core/errors/errors-core';
import { BugAnnotationEntity } from '../BugAnnotationEntity';
import { BugEntity } from '../BugEntity';

Object.defineProperty(BugAnnotationEntity.prototype, 'parentAnnotatedEntity', {
    get: function(this: BugAnnotationEntity) : BugEntity {
        return this.bug;
    },
    set: function(this: BugAnnotationEntity, parentAnnotatedEntity: BugEntity): void {
        if(!parentAnnotatedEntity){
            throw new IllegalArgumentError('cannot be null nor undefined', 'parentAnnotatedEntity');
        }
        this.bug = parentAnnotatedEntity;
    },
    enumerable: true,
});