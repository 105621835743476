import { MAP_FILE_TYPES, MAP_TYPES } from '@archipad-models/models/extensions/MapEntity-ext';

export function getMapFileExt(filename:string) : string {
    return filename.substr(filename.lastIndexOf('.')+1).toLowerCase();
}

export function getMapFileType(filename:string) : MAP_TYPES {
    const ext = getMapFileExt(filename).toUpperCase();
    return (ext in MAP_FILE_TYPES) ? MAP_FILE_TYPES[ext] : MAP_FILE_TYPES.UNKNOWN;
}
