import { BaseEntity } from "@core/services/orm/orm";

export function flattenRelationNN<T = BaseEntity, R = BaseEntity>(entityObject:T, relation1Name:string, relation2Name:string): ReadonlyArray<R> {
    const relations: ReadonlyArray<R> = entityObject[relation1Name];
    let result: R[];
    if (relations.length == 0) {
        result = [];
    } else {
        result = [];
        relations.forEach(function(relation) {
            const object = relation[relation2Name];
            result.push(object);
        });
    }
    return result;
}

export function flattenFilteredRelationNN<T = BaseEntity, R = BaseEntity>(entityObject:T, relation1Name:string, relation2Name:string, filterKey:string, filterValue:unknown): ReadonlyArray<R> {
    const relations: ReadonlyArray<R> = entityObject[relation1Name];
    let result: R[];
    if (relations.length == 0) {
        result = [];
    } else {
        result = [];
        relations.forEach(function(relation) {
            const object = relation[relation2Name];

            const value = relation[filterKey];
            if (value !== filterValue) {
                return;
            }

            result.push(object);
        });
    }
    return result;
}
