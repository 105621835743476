import { Observable, forkJoin } from 'rxjs';
import { take, map, first, tap } from 'rxjs/operators';
import JSZip from 'jszip';
import { getConfigService, env as ConfigEnv } from '@core/services/config';
import { createLogger } from '@core/services/logger.service';
import * as Cache from '@core/services/cache';
import * as IdGenerator from '@core/services/orm/idGenerator';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { GeneratorType } from '@core/tasks/generator';
import { callAsGenerator, callAsAsync, callAsObservable, voidAbortSignal, voidProgress } from '@core/tasks/asyncCaller';
import { makeEndpoint, S3ContextPathDescriptor, S3FileDescriptor } from '@core/services/file/s3file';
import { FileService, Data, FileDescriptor } from '@core/services/file/file';
import _LegacyServiceManager from "@core/services/legacy-service-manager";

import { ProjectEntity } from '@archipad-models/models/ProjectEntity';

import * as SynchroDriver from '@archipad/backend/archiweb/synchroDriver';
import * as RpcService from '@archipad/backend/rpc/abortable/authenticated';

import * as Patch from '@archipad/backend/project/patch';
import _PatchHandlerService from '@archipad/backend/project/patch';
import * as ProjectTemplate from '@archipad/backend/project/projectTemplate';

import { getArchipadSchemaManager } from "@archipad/services/archipad-schema-manager";

import { UnsupportedOperationError, IllegalArgumentError } from '@core/errors/errors-core';
import { EntityContext } from "@core/services/orm/orm";

import { applyPatch, exportPatch, importPatch, TempPatchAttachmentSerializer } from "@archipad/backend/project/patch";
import { ProjectContext } from '@archipad/backend/project/projectContext';
import { getProjectTemplateStorageInfo, ProjectTemplateRelation, ProjectTemplateStorageInfo, resolveDeeplyProjectTemplateRelation, UpdateRemoteProjectInfo } from '@archipad/backend/archiweb/synchroBackend';

import { MinimalProjectInfo } from './projectInfo';
import { JSONDecode } from '@archipad-js/archipad/utils';
import { ConfigBundle } from '@archipad-js/core/config';
import { getLocalizationService } from '@core/services/translate';
import { getAccountService } from '@archipad/services/account.service';
import { Trusted_Time_Service_DIID } from '@archipad-js/archipad/archiweb-services-client';

const log = createLogger('ProjectTemplateManager');

export const KnownProjectTemplate = {
    System: {
        Workflow: {
            DE: '70282E3CA73A4B01884201F504FCA194',
            EN: '3C9A5A472A59526BABB64C84365304E5',
            ES: 'BD9C8DFE3F2E41EDBFA84627AAEDBC87',
            FR: '9FB933D99C4D4AE2AB79E60D6FBEF371',
            IT: 'E7FE37D7D4ECA8E15E8EF1E460022169',
        },
        WorkflowComplexe: {
            DE: 'D182F9CBBEBF4CF8AE62CBD1AAEDB743',
            EN: 'FD200747F4B1E99C39CB742C0B8E4C84',
            ES: '8F131903B1974CBEA10950C6EB1A5841',
            FR: '3432862658B9C497930B8C1E9B499AC9',
            IT: '246915ED4E61A92A429B0823E23FFAF5',
        },
    },
    Cogedim: 'D531FD014071D34AD205727529BE5700',
    Promoimmo: '831DA8A6A0F9AA28E3F9C0CAD530ABAD',
} as const;
/**
 * @link https://regex101.com/r/jkyCrO/1
 */
// eslint-disable-next-line no-useless-escape
const REGEX_BUNDLED_TEMPLATES_DEPENDENCIES = new RegExp(`cloud\/bundled-templates\..*\.json`);

export interface IsTemplateUpgradeAvailableResponse {
    updateAvailable: boolean;
    showBanner: boolean;
    minIOSVersion: string;
    msg: string;
    skipUI: boolean;
}

const env = ConfigEnv();
if ( ! env.clientName)
    throw new IllegalArgumentError("No clientName defined");

/*-------------------------------------------------------------------------------------*/
export interface ProjectTemplateInfo {
    id: string;
    name: string;
    creationDate: number;
    lastModificationDate: number;
    published: boolean
    interactions: string[];
    system: boolean
    originTemplates: string[];
    versions: string[];
}

export interface BundledTemplate {
    system: boolean;
    instanciable: boolean;
    name: string;
    lang?: string;
    template: ProjectTemplate.Template;
}

export type OnSavePatch = (signal: AbortSignal, progress:ProgressObserver, userInfo:Patch.UserInfo, projectInfo : UpdateRemoteProjectInfo) => Promise<void>;


/*---------------------------------------------------------------------------*/
class ProjectTemplateAttachmentSerializer implements Patch.AttachmentSerializer {

    serializeAttachment(file:FileDescriptor, entityId:string, attachmentKey:string): Patch.SerializedJSONAttachment {
        throw new UnsupportedOperationError("Method not implemented.");
    }
    deserializeAttachment(): FileDescriptor {
        throw new UnsupportedOperationError("Method not implemented.");
    }
}

/*---------------------------------------------------------------------------*/

async function _getUserInfo(signal: AbortSignal, progress: ProgressObserver): Promise<{ id:number, email:string, company_id:number }> {
    const p = makeProgressHandler(progress);
    p.total(100);
    
    const accountInfo = getAccountService().currentAccount;
    const userInfo = getAccountService().currentUser;

    return {
        id: accountInfo.id,
        email: userInfo.login,
        company_id: accountInfo.company.id,
    };
}

function getPatchHeaderEnv(userInfo:Patch.UserInfo, templateIdStack:string[]): { client: string; user: Patch.UserInfo; templateIdStack: string; } {
    return {
        client: env.clientName,
        user: userInfo,
        templateIdStack: templateIdStack.join('/'),
    };
}

function getOriginTemplateIds(project:ProjectEntity): string[] {
    if(!project.originTemplateIds)
    {
        return [];
    }

    return project.originTemplateIds.split(",");
}

async function _savePatch(
    signal: AbortSignal,
    progress: ProgressObserver,
    attachmentSerializer: Patch.AttachmentSerializer,
    patch: Patch.NetPatch,
    projectId: string,
    projectName: string,
    userInfo: Patch.UserInfo,
    deps: string[],
    onSavePatch: OnSavePatch,
): Promise<void> {
    const p = makeProgressHandler(progress);
    p.total(100);

    await callAsAsync(signal, p, 95, (po) =>
        SynchroDriver.saveProjectPatch(
            po,
            {
                id: projectId,
                name: projectName,
                dependencies: deps,
            },
            attachmentSerializer,
            patch,
        ),
    );

    await callAsAsync(signal, p, 5, (po) =>
        onSavePatch(signal, po, userInfo, {
            id: projectId,
            name: projectName,
            dependencies: deps ?? [],
        }),
    );
}

function _versionsToSkip(instantiatedVersions:  { [templateId:string]:string[] }, currentTemplateId: string, entityContext: EntityContext, templateData: ProjectTemplate.Template): string[]{
    const versionsToSkip = [];
    if (instantiatedVersions && instantiatedVersions[currentTemplateId]){
        versionsToSkip.push(...instantiatedVersions[currentTemplateId]);
    }
    const [,...templateVersions] = templateData;
    for (const templateVersion of templateVersions){
        const [templateVersionPatchHeader] = templateVersion.patch;
        if (templateVersionPatchHeader.version > entityContext.modelVersion){
            versionsToSkip.push(templateVersion.version);
        }
    }
    return versionsToSkip;

}

/**
 * Make a project patch from a template and publish it. Recursively instanciate origin template ids
 *
 * @param entityContext an {@link EntityContext} aim to produce the patch
 * @param templateId template id to make a patch from
 * @return a promise that resolves when the patch has been created and published, attachments copied
 */
async function _instantiateTemplate(signal: AbortSignal, progress:ProgressObserver, entityContext: EntityContext, projectId:string, projectName:string, templateId:string,
    instanciatedVersions: { [templateId:string]:string[] }, originTemplateIds:string[], onSavePatch:OnSavePatch): Promise<boolean>
{
    const p = makeProgressHandler(progress);
    p.task(100);

    const mapping = [];
    let anyInstanciated = false;
    let allDependencies : Array<string> = undefined;
    const userInfo = await callAsAsync(signal, p, 20, (po) => _getUserInfo(signal, po));

    async function _doInstanciateTemplate(templatesStack, projectOriginTemplateIds): Promise<string> {
        const currentTemplateId = templatesStack[templatesStack.length-1];
        const templatesData = await callAsAsync(signal, p, 0, (po) => _gatherTemplates(signal, po, [currentTemplateId]));

        const templateData = templatesData[0].template;
        const templateOriginTemplateIds = templateData[0].originTemplateIds || [];
        
        const versionsToSkip = _versionsToSkip(instanciatedVersions, currentTemplateId, entityContext, templateData);

        // if we have origin templates, instanciate them first recursively
        let parentDependencies:string = undefined;
        for(const originTemplateId of templateOriginTemplateIds) {
            // give instanciated version (to not reinstanciate), don't give originTemplateIds because we don't want them to be in project.originTemplateIds when recursing, give stackData for parent entities mapping
            parentDependencies = await callAsAsync(signal, p, 0, (po) => _doInstanciateTemplate(templatesStack.concat(originTemplateId), null));
        }

        // we instanciated all parent templates, we have the latest mapping
        const patchHeaderEnv = getPatchHeaderEnv(userInfo, templatesStack);
        const result = ProjectTemplate.templateToPatch(templateData, patchHeaderEnv, projectId, projectName, versionsToSkip, projectOriginTemplateIds, mapping);
        const dependencies = result.dependencies !== undefined ? result.dependencies : parentDependencies;

        if (dependencies !== undefined) {
            if (!allDependencies){
                allDependencies = [];
            }
            const dependencies = result.dependencies ? result.dependencies.split(',') : [];
            for (const dependency of dependencies){
                if (!allDependencies.includes(dependency)){
                    allDependencies.push(dependency);
                }
            }
        }

        if ( ! result.patch ) {
            // when upgrading a project, we apply default templates.
            // If default templates was already applied, there is no resulting patch
            return dependencies;
        }

        const attachmentResolver = new ProjectTemplateAttachmentSerializer();

        const patch = importPatch(
            entityContext,
            attachmentResolver,
            result.patch.patch,
            result.patch.attachmentManifest,
        );
        applyPatch(entityContext,patch);


        anyInstanciated = true;
        return dependencies;
    }
    await callAsAsync(signal, p, 0, (po) => _doInstanciateTemplate([templateId], originTemplateIds));
    p.units(40);

    const header: Patch.PatchHeader = {
        modelMinVersion: entityContext.modelMinVersion,
        modelVersion: entityContext.modelVersion,
    };
    await _PatchHandlerService.executePatchHandlers("willCreatePatch", entityContext, projectId, Patch.PatchHandlerContext.UPGRADE);
    const patch = Patch.extractPatch(entityContext, header);
    if (patch.isEmpty(entityContext)) {
        return false;
    }
    const serializer = new TempPatchAttachmentSerializer(projectId);
    await _PatchHandlerService.executePatchHandlers("willExportPatch", entityContext, projectId, Patch.PatchHandlerContext.UPGRADE, patch, SynchroDriver.CURRENT_PATCH);
    const netPatch = exportPatch(entityContext, serializer, patch);

    if (netPatch) {
        netPatch.patch[0] = {
            ...netPatch.patch[0],
            ...getPatchHeaderEnv(userInfo, originTemplateIds),
        };
        await callAsAsync(signal, p, 0, (po) => _savePatch(signal, po, serializer, netPatch, projectId, projectName, userInfo, allDependencies, onSavePatch));
        entityContext.commit();
    }
    p.units(40);

    return anyInstanciated;
}

async function _gatherTemplates(
    signal: AbortSignal,
    progress: ProgressObserver,
    requiredTemplates: string[],
): Promise<{ template: ProjectTemplate.Template }[]> {
    const processingProgress = makeProgressHandler(progress);
    processingProgress.total(requiredTemplates.length * 2);

    const templatesData: { template: ProjectTemplate.Template }[] = [];
    const notHandledTemplates: Set<string> = new Set(requiredTemplates);

    // Retrieves the desired project templates from the configuration
    const bundledTemplates = await callAsAsync(signal, processingProgress, 0, () => _getBundledTemplates());
    for (const bundledTemplate of bundledTemplates) {
        const templateId = bundledTemplate.template[0].templateId;
        if (requiredTemplates.includes(templateId)) {
            templatesData.push(bundledTemplate);
            notHandledTemplates.delete(templateId);
            processingProgress.units(templatesData.length * 2);
        }
    }

    // Now processing user/dependencies projectTemplates on S3
    const distantTemplateInfos = await callAsAsync(signal, processingProgress, notHandledTemplates.size, (po) =>
        _batchGetTemplateStorageInfo(signal, po, [...notHandledTemplates]),
    );

    // Retrieves the user projectTemplate
    for (const userTemplateInfo of distantTemplateInfos.filter(
        (storageInfo) => storageInfo.projectTemplateStorageInfo.type === "USER",
    )) {
        const userProjectTemplateRawData = await callAsAsync(signal, processingProgress, 1, (po) =>
            userTemplateInfo.templateData.asJSON(po),
        );
        notHandledTemplates.delete(userTemplateInfo.templateId);
        templatesData.push({ template: userProjectTemplateRawData as ProjectTemplate.Template });
    }

    // Retrieves the dependencies projectTemplate
    /**
     * AP-6563
     * This is the case when users trigger the update of projects and do not have the right dependencies locally.
     * For example, the guests on a cogedim project
     */
    const isNeedToHandleProjectTemplateStoredInDistantDependencyBundle = distantTemplateInfos.find(
        (storageInfo) => storageInfo.projectTemplateStorageInfo.type === "DEPENDENCY",
    );
    if (isNeedToHandleProjectTemplateStoredInDistantDependencyBundle) {
        const configService = getConfigService();
        /**
         * Create a "fake" configuration to use the aspect interpretation process
         */
        const temporaryProjectTemplateBundle = new ConfigBundle("temporary-bundled-templates", 999); // High priority to avoid overwriting other configurations
        configService.enableBundle(temporaryProjectTemplateBundle);
        try {
            for (const dependenciesTemplateInfo of distantTemplateInfos.filter(
                (storageInfo) => storageInfo.projectTemplateStorageInfo.type === "DEPENDENCY",
            )) {
                const dependencyProjectTemplateRawData = await callAsAsync(signal, processingProgress, 1, (po) =>
                    extractTemplateDataFromRawData(signal, po, dependenciesTemplateInfo.templateData),
                );
                temporaryProjectTemplateBundle.addAspect(
                    "bundled-templates",
                    dependenciesTemplateInfo.templateId,
                    dependencyProjectTemplateRawData as Array<Record<string, unknown>>,
                );
                temporaryProjectTemplateBundle.setEnabledAspects([
                    ...temporaryProjectTemplateBundle.getEnabledAspects(),
                    dependenciesTemplateInfo.templateId,
                ]);
                const bundledTemplates = await callAsAsync(signal, processingProgress, 0, () =>
                    _getBundledTemplates(),
                );
                const targetBundledTemplate = bundledTemplates.find(
                    (bundle) => bundle.template[0].templateId === dependenciesTemplateInfo.templateId,
                );
                notHandledTemplates.delete(dependenciesTemplateInfo.templateId);
                templatesData.push(targetBundledTemplate);
            }
        } finally {
            configService.disableBundle(temporaryProjectTemplateBundle);
        }        
    }
    if (notHandledTemplates.size){
        throw new Error(`ProjectTemplate not found "${[...notHandledTemplates]}"`)
    }
    return templatesData;
}

function _batchGetTemplateStorageInfo(
    signal: AbortSignal,
    progress: ProgressObserver,
    projectTemplateIds: string[],
): Promise<{
    templateId: string;
    projectTemplateStorageInfo: ProjectTemplateStorageInfo;
    templateData: Data;
}[]> {
    const apiCallProgress = makeProgressHandler(progress);
    apiCallProgress.total(projectTemplateIds.length);
    const storageInfoCalls: Promise<{
        templateId: string;
        projectTemplateStorageInfo: ProjectTemplateStorageInfo;
        templateData: Data;
    }>[] = projectTemplateIds.map(async (templateId) => {
        const storageInfo = await callAsAsync(signal, apiCallProgress, 1, (po) => getProjectTemplateStorageInfo(signal, po, { id: templateId }));
        const templateDir = new S3ContextPathDescriptor({
            endpoint: makeEndpoint({
                creds: storageInfo.credentials,
                region: storageInfo.region,
            }),
            region: storageInfo.region,
            bucket: storageInfo.bucket,
            keyPrefix: storageInfo.baseURI,
            acl: null,
        });
        const templatePath = new S3FileDescriptor(templateDir, storageInfo.path);
        const templateData = FileService.download(templatePath);
        return {
            templateId: templateId,
            projectTemplateStorageInfo: storageInfo,
            templateData: templateData,
        };
    });
    return Promise.all(storageInfoCalls);
}


/**
 * Extract aspect data about projectTemplate from dependency bundle zip
 * @Return Aspect data that must be ingested by the configuration
 */
 async function extractTemplateDataFromRawData(signal: AbortSignal, progress:ProgressObserver, data: Data): Promise<unknown[]> {
    const downloadProgress = makeProgressHandler(progress);
    downloadProgress.total(1);
    const zipBlob = await callAsAsync(signal,downloadProgress,1,(po) => data.asBlob(po));
    const zip = await JSZip.loadAsync(zipBlob);

    const bundledTemplate = Object.values(zip.files).find(file => file.name.match(REGEX_BUNDLED_TEMPLATES_DEPENDENCIES));

    if ( ! bundledTemplate ) {
        throw new Error('Unable to find template.json in dependency');
    }
    const templateContent = await bundledTemplate.async("string");
    const jsonTemplateContent = JSONDecode(templateContent) as unknown[];
    return jsonTemplateContent;
}

function getInstanciatedTemplates(project:ProjectEntity) {

    const versionsByTemplate = {};
    for ( const version of project.templateVersions ) {
        if ( ! versionsByTemplate[version.projectTemplateId] ) {
            versionsByTemplate[version.projectTemplateId] = [];
        }

        versionsByTemplate[version.projectTemplateId].push(version.version);
    }

    return versionsByTemplate;
}

/*----------------------------------------------------------------------------*/
/**
 * Suffix the project template name from user input and maybe suffix it with a digit.
 *
 * @param {string} name
 * @returns {string}
 */
function suffixProjectTemplateName(projectTemplates: ProjectTemplateInfo[], name: string): string {
    let n = -1;

    for (const projectTemplate of projectTemplates) {
        // Get the suffix of the projectTemplate (number at the end of project template name)
        const regMatcher = new RegExp(/[0-9]+$/);
        const regResult = regMatcher.exec(projectTemplate.name);

        // Get the projectTemplate name without the suffix and the suffix index in project template name
        const suffixIndex = regResult ? regResult.index : null;
        const projectTemplateWithoutSuffix = suffixIndex ? projectTemplate.name.substring(0, suffixIndex).trim() : projectTemplate.name.trim();

        // If project template name is the same as the name given by the user
        // Then, we must increment the suffix counter
        if(projectTemplateWithoutSuffix === name) {
            const m = parseInt(projectTemplate.name.substring(name.length + 1), 10) || 0;
            if (m > n) {
                n = m;
            }
        }
    }

    return name + (n == -1 ? "" : " " + ++n);
}

export function saveProjectAsTemplate(progress:ProgressObserver, name:string, projectContext:ProjectContext, projectId:string): GeneratorType<string> {
    const p = makeProgressHandler(progress);
    p.task(1);
    return callAsGenerator(p, 1, (si, po) => _saveProjectAsTemplate(si, po, name, projectContext, projectId));
}
async function _saveProjectAsTemplate(signal: AbortSignal, progress:ProgressObserver, name:string, projectContext:ProjectContext, projectId:string): Promise<string> {
    const p = makeProgressHandler(progress);
    p.task(100);

    const templateId = IdGenerator.makeUUID();

    const trustedTimeService = _LegacyServiceManager.container.get(Trusted_Time_Service_DIID.symbol);
    const synchronizedTime = trustedTimeService.trustedTime;
    const synchronizedMicroTime = trustedTimeService.trustedMicroTime;

    const ormContext = projectContext.getEntityContext();
    const patchId = Patch.newPatchId(synchronizedMicroTime)

    // contains the direct parents only
    const originTemplateIds = getOriginTemplateIds(projectContext.getProjectEntity());

    // contains all ancestors
    const instanciatedTemplates = Object.keys(getInstanciatedTemplates(projectContext.getProjectEntity()));

    const baseTemplates = await callAsAsync(signal, p, 24, (po) => _gatherTemplates(signal, po, instanciatedTemplates));
    const templateVersion = ProjectTemplate.makeTemplateVersion(templateId, patchId, ormContext, projectId, baseTemplates.map(template => template.template));

    // make the new template
    const template = [
        {
            templateId: templateId,
            originTemplateIds: originTemplateIds,
            diff: true,
            minVersion: 503,
            version: 503,
            creationDate: synchronizedTime
        },
        templateVersion.version
    ];

    const projectTemplates = await callAsAsync(signal, p, 5,(po) => listProjectTemplates(signal, po));

    // start the creation on backend
    const templateStorageInfo = await callAsAsync(signal, p, 20, (po) => RpcService.makeAuthenticatedRequest<any>(signal, po, 'projectTemplate', 'create', {
         id:templateId, name:suffixProjectTemplateName(projectTemplates, name), fromProjectId:projectId, originTemplateIds: originTemplateIds }));
    const templatePath = new S3ContextPathDescriptor({
        endpoint: makeEndpoint({creds: templateStorageInfo.credentials, region: templateStorageInfo.region}),
        region: templateStorageInfo.region,
        bucket: templateStorageInfo.bucket,
        keyPrefix: templateStorageInfo.path,
        acl: null,
    });

    // copy attachments
    if(templateVersion.attachmentsToCopy.length) {        
        // upload attachments
        const uploadProgress = makeProgressHandler(p.task(30));
        uploadProgress.total(templateVersion.attachmentsToCopy.length);
        const uploadTasks:Observable<unknown>[] = [];

        for(const copyInfo of templateVersion.attachmentsToCopy) {
            uploadTasks.push(FileService.copy(uploadProgress.task(1), copyInfo.source, new S3FileDescriptor(templatePath, copyInfo.dest)));
        }
        
        await callAsAsync(signal, p, 0, () => FileService.execute(uploadTasks));
    }

    // copy template data to template.json
    const data = Data.fromJSON(template);
    await callAsAsync(signal, p, 15, (po) => FileService.upload(po, new S3FileDescriptor(templatePath, "template.json"), data));

    // TODO: not yet implemented in api
    // // update the template to complete creation
    // yield* callAsGenerator(p, 0, (si,po) => updateProjectTemplate(si,po,templateId,suffixProjectTemplateName(projectTemplates, name))); // TODO XXX give version ?


    clearProjectTemplatesListCache();
    await callAsAsync(signal, p, 5, (po) => listProjectTemplates(signal, po));

    return templateId;
}

let projectTemplatesCache: Cache.Request<ProjectTemplateInfo[]>;

_LegacyServiceManager.waitForReady().then(() => {
    projectTemplatesCache = new Cache.Request(
        getAccountService().currentUserInformation$,
        function (currentAuthInfo) {
            if (!currentAuthInfo) {
                return null;
            }
    
            const signal = voidAbortSignal();
            const progress = voidProgress();
            const p = makeProgressHandler(progress);
    
            return callAsAsync(signal, p, 0, (po) =>
                RpcService.makeAuthenticatedRequest(signal, po, "projectTemplate", "list", {
                    lang: getLocalizationService().locale,
                }),
            );
        },
        5 * 60 * 1000,
    );

    getAccountService().accountUpdated$.pipe(
        tap(() => {
            clearProjectTemplatesListCache();
        })
    ).subscribe();

}).catch(() => {})

export async function listProjectTemplates(signal: AbortSignal, progress:ProgressObserver): Promise<ProjectTemplateInfo[]> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const forkJoin$: Observable<[{templates: BundledTemplate[]}, ProjectTemplateInfo[]]> = forkJoin([
        getConfigService().getObservable('bundled-templates').pipe(first()) as Observable<any>, 
        projectTemplatesCache.fetch(),
    ]);
    const [bundledTemplates, server] = await callAsAsync(signal, p, 0, () => forkJoin$);

    console.log("new templates config", bundledTemplates);
    const templates:ProjectTemplateInfo[] = [];
    for ( const bundledTemplate of bundledTemplates.templates ) {
        if ( ! bundledTemplate.instanciable || (bundledTemplate.lang && bundledTemplate.lang != getLocalizationService().locale.language) )
            continue;

        const versions = [];
        for ( let i = 1; i < bundledTemplate.template.length; i++ )
            versions.push(bundledTemplate.template[i].version);

        templates.push({
            id: bundledTemplate.template[0].templateId,
            name: bundledTemplate.name,
            creationDate: bundledTemplate.template[0].creationDate,
            lastModificationDate: bundledTemplate.template[0].creationDate,
            published: true,
            interactions: [],
            system: true,
            originTemplates: bundledTemplate.template[0].originTemplateIds,
            versions: versions
        });
    }

    p.units(1);

    return [].concat(templates, server);
}

function clearProjectTemplatesListCache(): void {
    projectTemplatesCache.clear();
}

/* export async function updateProjectTemplate(signal: AbortSignal, progress:ProgressObserver, templateId:string, name:string): Promise<ProjectTemplateInfo[]> {
    const p = makeProgressHandler(progress);
    p.total(2);

    await callAsAsync(signal, p, 1, (po) =>
        RpcService.makeAuthenticatedRequest(signal, po, "projectTemplate", "update", { id: templateId, name: name }),
    );
    clearProjectTemplatesListCache();
    return callAsAsync(signal, p, 1, (po) => listProjectTemplates(signal, po));
} */

async function _deleteProjectTemplate(signal: AbortSignal, progress:ProgressObserver, templateId:string): Promise<ProjectTemplateInfo[]> {
    const p = makeProgressHandler(progress);
    p.total(100);
    
    const isSystem = await callAsAsync(signal, p, 0, () => isSystemTemplate(templateId));
    if (isSystem) {
        throw new UnsupportedOperationError(`Cannot delete the System Project Template "${templateId}".`)
    }

    await callAsAsync(signal, p,80, (po) => RpcService.makeAuthenticatedRequest(signal, po, 'projectTemplate', 'delete', { id:templateId }));
    clearProjectTemplatesListCache();

    const projectTemplates = await callAsAsync(signal, p, 20, (po) => listProjectTemplates(signal, po));

    return projectTemplates;
}
export function deleteProjectTemplate(progress:ProgressObserver, templateId:string): Observable<ProjectTemplateInfo[]> {
    const p = makeProgressHandler(progress);
    p.total(1);
    return callAsObservable(p,1,(si, po) => _deleteProjectTemplate(si,po, templateId));
}

async function _createFromTemplate(signal: AbortSignal, progress:ProgressObserver, projectName:string, templateIds:string[], serverSideInstanciation:boolean, onSavePatch:OnSavePatch): Promise<MinimalProjectInfo> {
    const p = makeProgressHandler(progress);
    if ( serverSideInstanciation ) {
        p.total(100);

        const projectInfo = await callAsAsync(signal, p, 100, (po) => RpcService.makeAuthenticatedRequest<MinimalProjectInfo>(signal, po, 'project', 'create', { id:IdGenerator.generateId(), name:projectName, templates:templateIds }));
        return projectInfo;
    } else {
        p.total(2);

        const projectInfo = {
            id: IdGenerator.generateId(),
            name: projectName
        };

        await callAsAsync(signal, p, 0, () => p.oldTask(1, null, (task) => SynchroDriver.registerProject(task, projectInfo)));

        const entityContext = await callAsAsync(signal, p, 0, () => getArchipadSchemaManager().entityContextFromConfiguration());

        await entityContext.executeWithoutValidators(async () => {
            for (const templateId of templateIds) {
                await callAsAsync(signal, p, 1, (po) => _instantiateTemplate(signal, po, entityContext, projectInfo.id, projectInfo.name, templateId, {}, templateIds, onSavePatch));
            }
        });

        return projectInfo;
    }
}
export function createFromTemplate(progress:ProgressObserver, projectName:string, templateIds:string[], serverSideInstanciation:boolean, onSavePatch:OnSavePatch): Observable<MinimalProjectInfo> {
    const p = makeProgressHandler(progress);
    p.total(1);
    return callAsObservable(p, 1, (si, po) =>
        _createFromTemplate(si, po, projectName, templateIds, serverSideInstanciation, onSavePatch),
    );
}

// export async function debugGetTemplateContent(progress:ProgressObserver, templateId:string) {
//     const templateDir = new S3ProjectTemplatePathDescriptor(templateId, "template");
//     const templatePath = new S3FileDescriptor(templateDir, "template.json");

//     const data = FileService.download(templatePath);
//     return data.asJSON(progress);
// }

function _getBundledTemplates(): Observable<BundledTemplate[]> {
    return getConfigService().getObservable('bundled-templates').pipe(
        map((bundledTemplates) => (bundledTemplates as any).templates),
        take(1),
    );
}

// TODO : get all instanciable system templates

async function _isTemplateUpgradeAvailable(signal: AbortSignal, progress:ProgressObserver, project:ProjectEntity): Promise<IsTemplateUpgradeAvailableResponse> {
    const p = makeProgressHandler(progress);
    p.total(100);

    const response:IsTemplateUpgradeAvailableResponse = {
        updateAvailable: false,
        showBanner: false,
        minIOSVersion: "9",
        msg: "",
        skipUI: true
    };

    if ( ProjectTemplate.isInitialTemplateUpgradeAvailable(project) ){
        response.updateAvailable = true
        response.showBanner = false;
        return response;
    }

    const versionsByTemplate = getInstanciatedTemplates(project);
    const templates = await callAsAsync(signal, p, 0, () => _getBundledTemplates());

    // check system templates
    for(const template of templates) {
        if(!template.system)
            continue;   // not a system template
        const templateId = template.template[0].templateId;
        const versions = versionsByTemplate[templateId] || [];
        if(!versions.length && getOriginTemplateIds(project).map(templateId => templateId.trim()).indexOf(templateId) === -1)
            continue;   // project is not using this template

        const [, ...projectTemplateVersions] = template.template;
        // for each version in the template
        for(const item of projectTemplateVersions) {
            if(versions.indexOf(item.version) === -1) {
                // this project is missing a system template version
                // requires an upgrade
                response.updateAvailable = true;
                response.showBanner = false;
                return response;
            }
        }
    }

    p.units(50);

    // continue checking with api
    const projectTemplateRelations = project.templateVersions;
    const originTemplateIds = getOriginTemplateIds(project);
    const allProjectTemplateRelationsFromAPI: ProjectTemplateRelation[] = [];
    for (const originTemplateId of originTemplateIds) {
        if (allProjectTemplateRelationsFromAPI.some(r => r.id === originTemplateId)){
            continue;
        }
        const projectTemplateRelationsFromAPI = await callAsAsync(signal, p, 50, (po) => resolveDeeplyProjectTemplateRelation(signal, po, {id: originTemplateId}));
        allProjectTemplateRelationsFromAPI.push(...projectTemplateRelationsFromAPI);
    }
    
    for (const relation of allProjectTemplateRelationsFromAPI) {
        const gotMissingLastProjectTemplateVersion = !projectTemplateRelations.some(
            (r) => r.projectTemplateId === relation.id && r.version === relation.lastVersionId,
        );
        if (gotMissingLastProjectTemplateVersion) {
            response.updateAvailable = true;
            return response;
        }
    }
    return response;
}
export function isTemplateUpgradeAvailable(progress:ProgressObserver, project:ProjectEntity): Observable<IsTemplateUpgradeAvailableResponse> {
    const p = makeProgressHandler(progress);
    p.total(1);
    return callAsObservable(p, 1, (si, po) => _isTemplateUpgradeAvailable(si, po, project));
}

export function isSystemTemplate(templateId: string): Observable<boolean> {
    return _getBundledTemplates().pipe(
        map((templates) => {
            // find the first template that match the templateId
            const currentTemplate = templates.find((template) => {
                return template.template[0].templateId === templateId;
            });

            return (currentTemplate && currentTemplate.system);
        })
    );
}

async function _applyTemplateUpgrade(signal: AbortSignal, progress:ProgressObserver, project: ProjectEntity, onSavePatch:OnSavePatch): Promise<boolean> {
    const p = makeProgressHandler(progress);
    p.total(100);
    
    const response = await callAsAsync(signal, p, 10, (po) => isTemplateUpgradeAvailable(po, project));
    if ( ! response.updateAvailable ) {
        p.units(90);
        return false;
    }

    let anyUpgradeApplied = false;

    // may fix project
    const upgradePatch = ProjectTemplate.getInitialTemplateUpgrade(project);

    // gather all applied version, per templateId to skip them when applying templates
    let instanciatedVersions = getInstanciatedTemplates(project);
    // direct ancestors
    let templateIds = getOriginTemplateIds(project);

    if ( upgradePatch.didPerformUpgrade ) {
        templateIds = [upgradePatch.templateId];
        instanciatedVersions = upgradePatch.instanciatedVersions;

        // create and push initial update patch
        const userInfo = await callAsAsync(signal, p, 0, (po) => _getUserInfo(signal, po));

        // add header
        upgradePatch.patch.patch[0] = {
            ...getPatchHeaderEnv(userInfo, [upgradePatch.templateId]),
            ...upgradePatch.patch.patch[0],
        };

        const attachmentResolver = new ProjectTemplateAttachmentSerializer();
        const deps = project.dependencies?.split(',');
        await callAsAsync(signal, p, 10, (po) => _savePatch(signal, po, attachmentResolver, upgradePatch.patch as Patch.NetPatch, project.id, project.name, userInfo, deps, onSavePatch));
        const entityContext = project.getContext();
        entityContext.commit();
        anyUpgradeApplied = true;
    } else {
        p.units(10);
    }

    const entityContext = project.getContext();

    await callAsAsync(signal, p, 90, async (po) => {
        const p = makeProgressHandler(po);
        p.total(templateIds.length);

        await entityContext.executeWithoutValidators(async () => {
            for (const templateId of templateIds) {
                await callAsAsync(signal, p, 1, async (po) => {
                    const p = makeProgressHandler(po);
                    p.total(1);
            
                    const upgraded = await _instantiateTemplate(signal, p.task(1), project.getContext(), project.id, project.name, templateId, instanciatedVersions, templateIds, onSavePatch);
    
                    if (upgraded) {
                        anyUpgradeApplied = true;
                    }
                });
            }
        });
    });

    return anyUpgradeApplied;
}

export function applyTemplateUpgrade(progress:ProgressObserver, project: ProjectEntity, onSavePatch:OnSavePatch): Observable<boolean> {
    const p = makeProgressHandler(progress);
    p.total(1);
    return callAsObservable(p, 1, (si, po) => _applyTemplateUpgrade(si, po, project, onSavePatch));
}

/**
 * Retrieve the bundled project templates which can be applied on a given project,
 * useful when upgrading a project to a given project template.
 *
 * The bundled project template data source comes from Config service only
 * because upgrading a project to a user project template is not allowed.
 *
 * Also it filters not instanciable project template to avoid confusion when you
 * could upgrade a project to Promoimmo and CBO because CBO is based on Promoimmo.
 */
export async function getUpgradeCompatibleProjectTemplates(project: ProjectEntity): Promise<BundledTemplate[]> {
    const signal = voidAbortSignal();
    const progress = voidProgress();
    const p = makeProgressHandler(progress);
    p.total(1);

    const projectOriginTemplateIds = project.originTemplateIds?.split(',') ?? [];

    // cannot upgrade a project template with multiple ancestors
    const hasManyAncestors = projectOriginTemplateIds.length > 1;
    if (hasManyAncestors) {
        return [];
    }

    const builtinTemplateIds = [
        ...Object.values(KnownProjectTemplate.System.Workflow),
        ...Object.values(KnownProjectTemplate.System.WorkflowComplexe),
    ] as string[];

    const knownBundledTemplates: BundledTemplate[] = await callAsAsync(signal, p, 1, () => _getBundledTemplates());
    const compatibleUpgradeTemplates = knownBundledTemplates.filter((bundledTemplate) => {
        // cannot upgrade a project to a user project template
        const isInstanciableSystemProjectTemplate = bundledTemplate.system && bundledTemplate.instanciable;
        if (!isInstanciableSystemProjectTemplate) {
            return false;
        }

        const [projectTemplateHeader] = bundledTemplate.template;

        // cannot upgrade a project to a builtin project template
        const isBuiltinProjectTemplate = builtinTemplateIds.includes(projectTemplateHeader.templateId);
        if (isBuiltinProjectTemplate) {
            return false;
        }

        // cannot upgrade a project to a project template that is already an ancestor of the given project
        const isAlreadyUpgraded = projectOriginTemplateIds.includes(projectTemplateHeader.templateId);
        if (isAlreadyUpgraded) {
            return false;
        }

        const missingAncestors = getMissingProjectTemplateAncestor(project, knownBundledTemplates, bundledTemplate);

        const isProjectTemplateHaveMissingAncestor = missingAncestors.length > 0;

        /**
         * Cannot upgrade a project to a project template if the project template ancestors are not present in the config.
         * 
         * @example Cannot upgrade a project to CBO if Promoimmo is not in the Config.
         */
        if (isProjectTemplateHaveMissingAncestor) {
            log.error(`The following ancestors of the project template "${bundledTemplate.name}" are missing:`, missingAncestors);
            return false;
        }

        return true;
    });
    return compatibleUpgradeTemplates;
}

/**
 * Check if the given project template got some unknown ancestors.
 * @param knownBundledTemplates List of known BundledTemplate, used to go back in the ancestor tree
 */
export function getMissingProjectTemplateAncestor(project: ProjectEntity, knownBundledTemplates: BundledTemplate[], bundledTemplate: BundledTemplate): string[] {
    const [projectTemplateHeader] = bundledTemplate.template;
    const projectTemplateOriginTemplateIds = projectTemplateHeader.originTemplateIds;
    
    const currentOriginTemplateIds = project.originTemplateIds?.split(',') ?? [];
    const ancestorsTemplateIds = [];
    for (const currentOriginTemplateId of currentOriginTemplateIds) {
        ancestorsTemplateIds.push(currentOriginTemplateId);
        ancestorsTemplateIds.push(..._getRecursivelyChainedAncestorTemplateIds(currentOriginTemplateId, knownBundledTemplates));
    }

    const missingAncestors = projectTemplateOriginTemplateIds.filter((projectTemplateId) => {
        return !knownBundledTemplates.some((bundledTemplate) => bundledTemplate.template[0].templateId === projectTemplateId);
    });
    return missingAncestors;
}

function _getRecursivelyChainedAncestorTemplateIds(templateId: string, knownBundledTemplates: BundledTemplate[]): string[] {
    const bundledTemplate = knownBundledTemplates.find((bundledTemplate) => bundledTemplate.template[0].templateId === templateId);
    if (!bundledTemplate) {
        return [];
    }
    const ancestorTemplateIds = [];
    const [projectTemplateHeader] = bundledTemplate.template;
    const originTemplateIds = projectTemplateHeader.originTemplateIds;
    for (const originTemplateId of originTemplateIds) {
        ancestorTemplateIds.push(originTemplateId);
        ancestorTemplateIds.push(..._getRecursivelyChainedAncestorTemplateIds(originTemplateId, knownBundledTemplates));
    }
    return ancestorTemplateIds;
}
