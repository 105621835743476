import { FileDescriptor, PathDescriptor } from "@core/services/file/file";
import { IllegalArgumentError } from "@core/errors/errors-core";

/*---------------------------------------------------------------------------*/
declare global {
    interface FileTypeMap {
        'patch': PatchFileDescriptor,
        'temp': PatchFileDescriptor,
        'dumpPatch': PatchFileDescriptor,
    }
}

/*---------------------------------------------------------------------------*/

/**
 * Represents a group of patches of a specific type in a project (the regular patches or the temp patches)
 */
export class PatchesDescriptor extends PathDescriptor {
    /** type of patch */
    readonly type: "patch" | "temp" | "dumpPatch";
    /** id of the project to which this patch belongs to */
    readonly projectId: string;

    constructor(projectId: string, type: "patch" | "temp" | "dumpPatch") {
        super();
        this.type = type
        this.projectId = projectId;
    }
}

/**
 * Represents a patch directory 
 */
export class PatchDescriptor extends PathDescriptor {
    get type() { return this.patches.type; }
    
    readonly patches:PatchesDescriptor;
    /** id of the patch: eg "000001488d37bd9c.6a5e1ad3 */
    readonly patchId: string;

    constructor(patches:PatchesDescriptor, patchId:string) {
        super();
        this.patches = patches;
        this.patchId = patchId;
    }
}

/**
 * Represents a manifest file in a patch 
 */
export class PatchManifestDescriptor extends FileDescriptor {
    get type() { return this.patch.patches.type };

    readonly patch:PatchDescriptor;
    /** attachment path */
    readonly path: string;

    constructor(patch:PatchDescriptor, path: string) {
        super();
        this.patch = patch;
        this.path = path;
    }
}

/**
 * Represents an attachment file in a patch 
 */
export class PatchAttachmentDescriptor extends FileDescriptor {
    get type() { return this.patch.patches.type };

    readonly patch:PatchDescriptor;
    /** attachment entity id */
    readonly entityId: string;
    /** attachment path */
    readonly attachmentPath: string;

    constructor(patch:PatchDescriptor, entityId: string, attachmentPath: string) {
        super();

        if(typeof entityId !== 'string' || typeof attachmentPath !== 'string')
            throw new IllegalArgumentError('Invalid PatchAttachmentDescriptor argument');

        this.patch = patch;
        this.entityId = entityId;
        this.attachmentPath = attachmentPath;
    }
}

/*---------------------------------------------------------------------------*/

export type PatchFileDescriptor = PatchManifestDescriptor | PatchAttachmentDescriptor;
