import { isDate, isBefore } from 'date-fns';
import { OperatorFunction } from 'rxjs';
import { getTimeFromDate } from '@core/helpers/dateHelper';
import { getUniqueTextInList } from '@core/helpers/textHelper';
import { Notification, ifEntityConcerned } from '@core/services/orm/orm';
import { query } from '@core/services/orm/query';
import { generateDeterministicId } from '@core/services/orm/idGenerator';
import _TrackingService from '@core/drivers/tracking';
import { TrackingService } from '@core/drivers/tracking/tracking.types';

import _WorkflowUserManager, { WorkflowUserManager } from '@archipad/backend/project/workflowUserManager';

import { 
    PlanningForbiddenEventCreationError, 
    PlanningForbiddenEventUpdateError, 
    PlanningEventDeletionForbiddenError,
} from '@archipad/errors/errors-archipad';
import { PlanningEventCategoryEntity } from '@archipad-models/models/PlanningEventCategoryEntity';
import { PlanningEventEntity } from '@archipad-models/models/PlanningEventEntity';
import { ProjectEntity } from '@archipad-models/models/ProjectEntity';
import { ProjectUserEntity } from '@archipad-models/models/ProjectUserEntity';
import { PlanningEventLogEntity } from '@archipad-models/models/PlanningEventLogEntity';
import { ProjectUserRightEntity } from '@archipad-models/models/ProjectUserRightEntity';
import { comparatorForType } from '@archipad-js/core/query';
export interface PlanningUpdatePayload {
    text: PlanningEventEntity['text'] | null;
    startDate: PlanningEventEntity['startDate'] | null;
    endDate: PlanningEventEntity['endDate'] | null;
    categoryText: PlanningEventCategoryEntity['text'] | null;
    workPackage: PlanningEventEntity['workPackage'] | null;
    progress: PlanningEventEntity['progress'] | null;
}

export enum PlanningCapabilities {
    /**
     * Not allowed to write/edit planning events !
     */
    None = 0,                   // 000
    Read = 1 << 0,              // 001
    UpdateProgress = 1 << 1,    // 010
    Write = 1 << 2,             // 100
}


const PlanningUpdatePayloadKeys:Array<keyof PlanningUpdatePayload> = [ 
    "text",
    "startDate",
    "endDate",
    "categoryText",
    "workPackage",
    "progress",
];

/**
 * Responsible of all CRUD operations on Planning related entities.
 */
export class PlanningService {

    constructor(
        private readonly workflowUserManager: WorkflowUserManager,
        private readonly trackingService: TrackingService,
    ) { }

    /**
     * Filters an observable to filter only the notifications
     * that concerns Planning related entities.
     *
     * Might be used to refresh the list of planning events.
     */
    public static filterNotifications<T extends Notification>(): OperatorFunction<T,Notification<PlanningEventEntity>>{
        return (source) => {
            return source.pipe(
                ifEntityConcerned('PlanningEvent', 'PlanningEventCategory'),
            );
        };
    }

    //#region PlanningEvent

    /**
     * Creates a {@link PlanningEventEntity} with default values according to
     * the current project state.
     * 
     * @throws {PlanningEventCreationForbiddenError} current user must be the planning master
     */
    public createEvent(project: ProjectEntity): Readonly<PlanningEventEntity> {
        const canWriteEvent = this.currentUserCanWriteEvent(project);
        if (!canWriteEvent) {
            throw new PlanningForbiddenEventCreationError('', project.planningMaster?.email);
        }

        const text = this.getDefaultEventText(project);
        const startDate = this.getDefaultEventStartDate(project);
        const endDate = this.getDefaultEventEndDate();

        const entityContext = project.getContext();
        const planningEvent = entityContext.createEntity('PlanningEvent');

        planningEvent.project = project;
        planningEvent.text = text;
        planningEvent.startDate = startDate;
        planningEvent.endDate = endDate;
        planningEvent.progress = 0;

        const planningEventLog = this.createPlanningEventLog(planningEvent);
        planningEvent.creationDate = planningEventLog.creationDate;

        this.trackingService.trackAction({
            name: 'Add',
            category: 'Model',
            entity: 'PlanningEvent',
        });

        return planningEvent;
    }

    /**
     * Returns whether the current user can create, update or delete planning events.
     */
    public currentUserCanWriteEvent(project: ProjectEntity): boolean {
        return this.isCurrentUserPlanningMaster(project);
    }

    public isCurrentUserPlanningMaster(project: ProjectEntity): boolean {
        const planningRight = this.getCurrentUserPlanningCapabilities(project);
        if ((planningRight & PlanningCapabilities.Write) === PlanningCapabilities.Write) {
            return true;
        }
        return false;
    }

    /**
     * Returns whether the current user can update progress of planning events.
     */
    public currentUserCanUpdateEventProgress(project: ProjectEntity): boolean {
        const planningRight = this.getCurrentUserPlanningCapabilities(project);
        if ((planningRight & PlanningCapabilities.UpdateProgress) === PlanningCapabilities.UpdateProgress) {
            return true;
        }
        return false;
    }

    /**
     * Returns all ordered {@link PlanningEventEntity} of the current project.
     */
    public getAllEvents(project: ProjectEntity): readonly Readonly<PlanningEventEntity>[] {
        const entityContext = project.getContext();
        const q = query(entityContext, 'PlanningEvent')
            .orderBy('startDate asc, creationDate asc, id asc');

        const resultSet = q.execute();
        return resultSet.sortedEntities();
    }

    /**
     * Updates the given {@link PlanningEventEntity}.
     * 
     * @param payload The values to update the event with.
     * @throws {PlanningEventCreationForbiddenError} current user must be the planning master
     */
    public updateEvent(
        event: Readonly<PlanningEventEntity>,
        payload: PlanningUpdatePayload,
    ): void {
        const updatedAttributes:Array<string> = this.detectUpdatesInPayload(event, payload);
        if( !updatedAttributes.length ) {
            return;
        }

        let canWriteEvent = false;
        if( updatedAttributes.length == 1 && updatedAttributes.includes('progress') ) {
            canWriteEvent = this.currentUserCanUpdateEventProgress(event.project);
        }
        else {
            canWriteEvent = this.currentUserCanWriteEvent(event.project);
        }

        if (!canWriteEvent) {
            throw new PlanningForbiddenEventUpdateError('', event.project.planningMaster?.email);
        }

        const entityContext = event.project.getContext();
        const eventToUpdate = entityContext.getEntity('PlanningEvent', event.id);

        const newText = payload.text ?? eventToUpdate.text ?? this.getDefaultEventText(event.project);
        const newStartDate = isDate(payload.startDate) ? new Date(payload.startDate) : this.getDefaultEventStartDate(event.project);
        const newEndDate = isDate(payload.endDate) ? new Date(payload.endDate) : this.getDefaultEventEndDate();
        const category = payload.categoryText ? this.getOrCreatePlanningEventCategory(event.project, payload.categoryText) : null;
        const workPackage = payload.workPackage ?? null;
        const progress = payload.progress ?? 0;

        const prevProgress = eventToUpdate.progress;

        eventToUpdate.text = newText;
        eventToUpdate.startDate = newStartDate;
        eventToUpdate.endDate = newEndDate;
        eventToUpdate.category = category;
        eventToUpdate.workPackage = workPackage;
        eventToUpdate.progress = progress;

        this.ensureDatesIntegrity(eventToUpdate);

        const createEventLog = updatedAttributes.includes('progress') && eventToUpdate.progress != prevProgress;
        if( createEventLog ) {
            this.createPlanningEventLog(eventToUpdate);
        }
    }

    /**
     * Delete the given {@link PlanningEventEntity}.
     */
    public deleteEvent(event: PlanningEventEntity): void {
        const project = event.project;
        if(!this.currentUserCanWriteEvent(event.project)) {
            throw new PlanningEventDeletionForbiddenError('', project.planningMaster?.email);
        }

        const entityContext = project.getContext();
        entityContext.deleteEntity(event);

        this.trackingService.trackAction({
            name: 'Delete',
            category: 'Model',
            entity: 'PlanningEvent',
        });
    }

    /**
     * Returns the start date time hint of an event base on the existing events of
     * the given project.
     * 
     * @returns The start date time hint (format: HH:mm)
     */
    public getStartDateTimeHint(project: ProjectEntity): string {
        const entityContext = project.getContext();
        const q = query(entityContext, 'PlanningEvent')
            .predicateWithFormat('startDate != null')
            .orderBy('startDate desc, creationDate desc, id desc');
        const resultSet = q.execute();

        const furtherestEventWithStartDate = resultSet.firstEntity();
        const date = furtherestEventWithStartDate?.startDate ?? null;
        return getTimeFromDate(date) ?? '08:00';
    }

    /**
     * Returns the end date time hint of an event base on the existing events of
     * the given project.
     * 
     * @returns The end date time hint (format: HH:mm)
     */
    public getEndDateTimeHint(project: ProjectEntity): string {
        const entityContext = project.getContext();
        const q = query(entityContext, 'PlanningEvent')
            .predicateWithFormat('startDate != null AND endDate != null')
            .orderBy('startDate desc, creationDate desc, id desc');
        const resultSet = q.execute();

        const furtherestEventWithEndDate = resultSet.firstEntity();
        const date = furtherestEventWithEndDate?.endDate ?? null;
        return getTimeFromDate(date) ?? '17:00';
    }

    /**
     * Integrity Problems solve : 
     * - endDate begin before startDate
     */
    public fixPlanningEventEntitiesIntegrity(project: ProjectEntity): void {
        for (const planningEvent of project.planningEvents){
            this.ensureDatesIntegrity(planningEvent);
        }
    }

    private getPlanningCapabilities(projectUserRight: ProjectUserRightEntity): PlanningCapabilities {
        switch (projectUserRight.templateName) {
            case 'administrator':
            case 'collaborator': {
                return PlanningCapabilities.Read | PlanningCapabilities.UpdateProgress;
            }
            case 'guest': {
                return PlanningCapabilities.Read;
            }
            default: {
                return PlanningCapabilities.None;
            }
        }
    }

    /**
     * Returns current user planning capabilities.
     */
    private getCurrentUserPlanningCapabilities(project: ProjectEntity): PlanningCapabilities {
        const currentUser = this.workflowUserManager.getCurrentUser(project);
        const allPlanningCapabilitiesBundle = PlanningCapabilities.Read | PlanningCapabilities.UpdateProgress | PlanningCapabilities.Write;
        if (!currentUser) { // Means that we're on a local project without workflow (we have all rights)
            return allPlanningCapabilitiesBundle;
        }

        if (!project.planningMaster) {
            // Owner user is planning master if planningMaster was not initialize
            if (project.owner === currentUser) { 
                return allPlanningCapabilitiesBundle;
            }
        }

        if (project.planningMaster === currentUser) { // Current user is the planning master
            return allPlanningCapabilitiesBundle;
        }

        const currentUserRight = this.workflowUserManager.findRight(project, currentUser.right?.templateName);
        if (!currentUserRight) {
            return PlanningCapabilities.None;
        }

        return this.getPlanningCapabilities(currentUserRight);
    }

    /**
     * Returns the default text value of a {@link PlanningEventEntity}.
     * 
     * @example Untitled
     * @example Untitled 2
     */
    private getDefaultEventText(project: ProjectEntity): string {
        const existingPlanningEventTextList = project
            .planningEvents.map((planningEvent) => planningEvent.text)
            .filter((text) => Boolean(text));
        return getUniqueTextInList(l('Untitled event'), existingPlanningEventTextList);
    }

    /**
     * Returns the default start date for a {@link PlanningEventEntity} which is
     * the current date at 8 AM.
     */
    private getDefaultEventStartDate(project: ProjectEntity): Date {
        const entityContext = project.getContext();
        const q = query(entityContext, 'PlanningEvent')
            .predicateWithFormat('startDate != null')
            .orderBy('startDate desc, creationDate desc, id desc');
        const resultSet = q.execute();

        const furtherestEvent = resultSet.firstEntity();
        if (furtherestEvent) {
            return new Date(furtherestEvent.startDate);
        }

        const currentDate = new Date();
        return new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            8,
        );
    }

    /**
     * Returns the default end date for a {@link PlanningEventEntity} which is
     * null.
     */
    private getDefaultEventEndDate(): null {
        return null;
    }

    private isKeyOfPlanningUpdatePayload(key):key is keyof PlanningUpdatePayload {
        return PlanningUpdatePayloadKeys.includes(key);
    }

    /**
     * Returns a list of updated {keyof PlanningUpdatePayload}
     * 
     * @param planningEvent 
     * @param payload 
     */
    private detectUpdatesInPayload(
        planningEvent:Readonly<PlanningEventEntity>,
        payload: PlanningUpdatePayload,
    ): Array<keyof PlanningUpdatePayload> {
        const payloadKeys = Object.keys(payload);
        const updatedAttributes = payloadKeys.filter((key) => {
            if (key === 'categoryText') {
                const previousCategoryText = planningEvent.category?.text ?? null;
                return payload.categoryText !== previousCategoryText;
            }
            return payload[key] !== planningEvent[key];
        }).filter(this.isKeyOfPlanningUpdatePayload);

        return updatedAttributes;
    }

    private shouldInvertDates({ startDate, endDate }: Readonly<PlanningEventEntity>): boolean {
        return Boolean(endDate && isBefore(endDate, startDate));
    }

    /**
     * Invert startDate and endDate if endDate begin before startDate
     */
    private ensureDatesIntegrity(planningEvent: PlanningEventEntity): void {
        if (this.shouldInvertDates(planningEvent)) {
            const tempStartDate = planningEvent.startDate;
            planningEvent.startDate = planningEvent.endDate;
            planningEvent.endDate = tempStartDate;
        }
    }

    //#endregion

    //#region PlanningEventCategory

    /**
     * Returns an existing {@link PlanningEventCategoryEntity} for a given 
     * category text or create it otherwise if the given category text is valid.
     * 
     * @param text The desired category text.
     */
    private getOrCreatePlanningEventCategory(project: ProjectEntity, categoryText: PlanningEventCategoryEntity['text']): PlanningEventCategoryEntity | null {
        const sanitizedCategoryText = categoryText.trim();
        if (sanitizedCategoryText.length === 0) {
            return null;
        }

        const planningEventCategoryId = generateDeterministicId(project.id, sanitizedCategoryText, 'string');
        const entityContext = project.getContext();

        const existing = entityContext.getEntity('PlanningEventCategory', planningEventCategoryId);
        if (existing !== null) {
            return existing;
        }

        const planningEventCategory = entityContext.createEntity('PlanningEventCategory', planningEventCategoryId);
        planningEventCategory.project = project;
        planningEventCategory.text = sanitizedCategoryText;

        return planningEventCategory;
    }

    //#endregion

    //#region PlanningEventLog

    private createPlanningEventLog(planningEvent:PlanningEventEntity):PlanningEventLogEntity {
        const entityContext = planningEvent.project.getContext();
        const planningEventLog = entityContext.createEntity('PlanningEventLog');

        const user:ProjectUserEntity = this.workflowUserManager.getCurrentUser(planningEvent.project);
        const progress = planningEvent.progress;

        planningEventLog.progress = progress;
        planningEventLog.creationDate = new Date();
        planningEventLog.planningEvent = planningEvent;
        planningEventLog.user = user;
        return planningEventLog;
    }

    //#endregion

    //#region PlanningMaster

    /**
     * Get all users who are eligible to be designated as planning master.
     */
    public getAllUsersEligibleToBePlanningMaster(project: Readonly<ProjectEntity>): ProjectUserEntity[] {
        const authorizedRoles: string[] = ['administrator', 'collaborator'];
        const naturalComparator = comparatorForType('string');
        
        const usersEligibleToBePlanningMaster: ProjectUserEntity[] = project.users
            .filter((user) => {
                if (!this.isValidPlanningMasterCandidate(user)) {
                    return false;
                }
                if (user.ownerOfProject) {
                    return true;
                }
                if (authorizedRoles.includes(user.right?.templateName)) {
                    return true;
                }
                return false;
            })
            .sort((a, b) => {
                if (a.ownerOfProject) {
                    return -1;
                }
                if (b.ownerOfProject) {
                    return 1;
                }
                return naturalComparator(a.email, b.email);
            });
        return usersEligibleToBePlanningMaster;
    }

    private isValidPlanningMasterCandidate(projectUser: ProjectUserEntity): boolean {
        if (projectUser.right && projectUser.right.templateName && projectUser.email && projectUser.email.length > 0) {
            return true;
        }
        return false;
    }

    //#endregion
}

export default new PlanningService(_WorkflowUserManager, _TrackingService);
