import { PhaseEntity } from '../PhaseEntity';
import { BugGroupEntity } from '../BugGroupEntity';
import { flattenRelationNN } from '@archipad/helpers/objectModelHelper';
import { getLocalizationService } from '@core/services/translate';

declare module "../PhaseEntity" {
    interface PhaseEntity  {
        bugGroups: ReadonlyArray<BugGroupEntity>;
    }
}

Object.defineProperty(PhaseEntity.prototype, 'nameComputed', {
    get: function(this:PhaseEntity):string {
        const localizer = getLocalizationService();
        
        if(this.nameSystem && localizer.isLocalized(this.nameSystem)) {
            return String(localizer.getFormatted(this.nameSystem));
        }

        return this.name?.length > 0 ? this.name : this.nameSystem ?? '';
    },
});

Object.defineProperty(PhaseEntity.prototype, 'bugGroups', {
    get: function(this:PhaseEntity): ReadonlyArray<BugGroupEntity> {
        return flattenRelationNN<PhaseEntity, BugGroupEntity>(this, 'bugGroupsRelations', 'bugGroup');
    },
});
