export enum MAP_FILE_TYPES {
    UNKNOWN = -1,
    PDF = 0, DWF = 1, DWFX = 5,
    PNG = 2, GIF = 3, JPG = 4, JPEG = 4,
};

export enum MAP_TYPES {
    UNKNOWN = -1,
    DEFAULT = 0,
    GENERALITIES = 1,
    FORMS = 2,
};