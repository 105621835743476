import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { map, reduce } from "rxjs/operators";

import { callAsAsync } from "@core/tasks/asyncCaller";
import { S3DumpPatchFileType, DumpPatchSet } from "@core/services/file/s3file";
import { FileService, FileServiceImpl, PathDescriptor, FileDescriptor } from "@core/services/file/file";
import { comparePatchId } from "@archipad/backend/project/patchHelper";
import { PatchData } from "@archipad/backend/archiweb/synchroDriver";
import * as PatchIO from '@archipad/backend/project/patch';
import { PatchManifestDescriptor, PatchesDescriptor } from '@archipad/services/file/patchFile';
import { makeProgressHandler, ProgressObserver } from "@core/tasks/progress";

/*---------------------------------------------------------------------------*/

export interface DumpPatchInfo {
    patchId: string;
    patch: FileDescriptor;
    attachmentManifest: FileDescriptor;
    patchSet: FileDescriptor;
}

export interface DumpPatchInfoByPatch {
    [patchId: string]: DumpPatchInfo;
}

/*---------------------------------------------------------------------------*/

export interface DumpPatchContext{
    readonly dumpPatchInfo: DumpPatchInfo,
    readonly dumpPatchSet: DumpPatchSet,
}


export class DumpPatchService {
    
    constructor(
            private readonly _fileService: FileServiceImpl,
    ){
    }

    /**
     * Gives data related to an Archipad project, necessary for the operation of other methods of the service
     */
    async getDumpPatchContext(signal: AbortSignal, progress: ProgressObserver, projectId: string ): Promise<DumpPatchContext | null> {
        const p = makeProgressHandler( progress );
        p.task(100);
        const projectCachesDescriptor = new PatchesDescriptor(projectId, S3DumpPatchFileType.VALUE);

        const dumpPatchList = await callAsAsync( signal, p, 50, ( progress ) => this._listDumpPatchesAtPath( signal, progress, projectCachesDescriptor ) );

        if ( !dumpPatchList || !Object.keys( dumpPatchList ).length ){
            return null;
        }

        const lastDumPatchId = [ ...Object.keys( dumpPatchList ).sort( comparePatchId ) ].pop();
        const dumpPatchInfo = dumpPatchList[ lastDumPatchId ];
        const patchSet = await callAsAsync( signal, p, 50, ( progress ) => this.loadPatchSetFromDumpPatch( signal, progress, dumpPatchInfo.patchSet ) );
        
        return { 
            dumpPatchInfo : dumpPatchInfo, 
            dumpPatchSet: patchSet, 
        };
    }

    /**
     * Is the flattened patch compatible for these patches or not ? 
     * @param projectPatchesId List of patches id of the project
     */
    isADumpPatchValid(context: DumpPatchContext, projectPatchesId: string[]): boolean {
        if (!context?.dumpPatchSet?.patchSet?.length){
            return false;
        }
        const patchesNotIncludeInDumpPatch = projectPatchesId.filter(value => !context.dumpPatchSet.patchSet.includes(value));
        const lastPatchInPatchSet = [...context.dumpPatchSet.patchSet].pop();

        // If there is a patch located chronologically before the upper bound of the dumpPatch in patchesNotIncludeInDumpPatch
        // ( This can happen when you add a patch manually on S3, between several existing patches )
        const checkThereIsInterlacedPatch = patchesNotIncludeInDumpPatch.filter(value => comparePatchId(value,lastPatchInPatchSet) <= -1).length;
        if (checkThereIsInterlacedPatch){
            return false;
        }

        // Check if there are excess patches in the patchSet
        // ( This can happen when you manually delete a patch on S3 )
        const checkThereIsExcessPatch = context.dumpPatchSet.patchSet.filter(value => !projectPatchesId.includes(value)).length;
        if (checkThereIsExcessPatch){
            return false;
        }

        return true;
    }

    async loadPatchesDataFromDumpPatch(signal: AbortSignal, progress: ProgressObserver, context: DumpPatchContext): Promise<PatchData[]> {
        const dumpPatchListElement: DumpPatchInfo = context.dumpPatchInfo;
        const patchSetFromLastDumpPatch: DumpPatchSet = context.dumpPatchSet;
        const p = makeProgressHandler(progress);
        p.total(100);
        const dumpPatchData : PatchData = {attachmentManifest: undefined, patch: undefined, patchId: patchSetFromLastDumpPatch.patchSet[0]};
        const downloadTasks: Observable<unknown>[] = [];
        // download _data.json
        downloadTasks.push(
            this._fileService.download(dumpPatchListElement.patch).asJSON(p.task(40)).pipe(
                map(function (json) {
                    dumpPatchData.patch = json;
                    const [dumpPatchHeader] = dumpPatchData.patch;
                    dumpPatchHeader.nature = PatchIO.PatchNature.DumpPatch;
                }),
            ),
        );
        // download _manifest.json
        if (dumpPatchListElement.attachmentManifest) {
            downloadTasks.push(
                this._fileService.download(dumpPatchListElement.attachmentManifest).asJSON(p.task(40)).pipe(
                    map(function (attachments: PatchIO.NetPatchAttachmentManifest) {
                        dumpPatchData.attachmentManifest = attachments;
                    }),
                ),
            );
        }else {
            dumpPatchData.attachmentManifest = null;
        }
    

        await callAsAsync( signal, p, 20, (po) => this._fileService.execute( downloadTasks ) );
    
        const [dumpPatchHeader] = dumpPatchData.patch;

        const linkedEmptyPatchIds = Array.from(patchSetFromLastDumpPatch.patchSet).splice(1);
        const linkedEmptyPatches: PatchData[] = linkedEmptyPatchIds.map( ( patchId ) => {    
            const linkedEmptyPatchHeader = cloneDeep(dumpPatchHeader);
            linkedEmptyPatchHeader.nature = PatchIO.PatchNature.LinkedEmptyPatch;
            const emptyPatchData: PatchData = { 
                attachmentManifest: null, 
                patch: [linkedEmptyPatchHeader], 
                patchId: patchId,
             }; 
            return emptyPatchData;
        });
        
        const patchesData: PatchData[] = [
            dumpPatchData,
            ...linkedEmptyPatches,
        ];

        return patchesData;
    }

    async loadPatchSetFromDumpPatch(signal: AbortSignal, progress: ProgressObserver, dumpPatchSetToLoad: FileDescriptor): Promise<DumpPatchSet> {
        const p = makeProgressHandler(progress);
        p.total(1);
        const json = await callAsAsync(signal, p, 1, (po) => this._fileService.download(dumpPatchSetToLoad).asJSON(po) as Observable<DumpPatchSet>);
        return json;
    }

    /**
     * Gives the list of flattened patch ids
     */
    async listProjectDumpPatches(signal: AbortSignal, progress: ProgressObserver, patchesDir: PathDescriptor): Promise<string[]> {
        const p = makeProgressHandler(progress);
        p.total(1);
        const dumpPatches = await callAsAsync( signal, p, 1, (po) => this._listDumpPatchesAtPath(signal, progress, patchesDir) );
        const dumpPatchIds = Object.keys(dumpPatches);
        return dumpPatchIds;
    }

    private _listDumpPatchesAtPath(signal: AbortSignal, progress: ProgressObserver, patchesPath: PathDescriptor, patchesToIgnore?: ReadonlySet<string>): Promise<DumpPatchInfoByPatch> {
        const p = makeProgressHandler(progress);
        p.total(1);

        const patchesToLoad: DumpPatchInfoByPatch = {};
        return callAsAsync( signal, p, 1, ( po ) => this._fileService.list( progress, patchesPath ).pipe(
            reduce((patchesToLoad, file: FileDescriptor) => {
                if (!(file instanceof PatchManifestDescriptor)){
                    return patchesToLoad;
                }

                const patchId = file.patch.patchId;

                if (patchesToIgnore && patchesToIgnore.has(patchId)){
                    return patchesToLoad;
                }

                let patch = patchesToLoad[patchId];
                if (!patch){
                    patchesToLoad[patchId] = patch = { patchId: null, patch: null, attachmentManifest: null, patchSet: null};
                }
                if (!patch.patchId){
                    patch.patchId = patchId;
                }

                if (file.path.includes('_data.json')){
                    patch.patch = file;
                } else if (file.path.includes('_manifest.json')){
                    patch.attachmentManifest = file;
                } else if (file.path.includes('_patchSet.json')){
                    patch.patchSet = file;
                }

                return patchesToLoad;
            }, patchesToLoad),
            map((patchesToLoad: DumpPatchInfoByPatch) => {
                for (const patchId in patchesToLoad) {
                    if (!patchesToLoad[patchId].patch){
                        delete patchesToLoad[patchId];
                    }
                }

                return patchesToLoad;
            }),
        )); 
    }

}

const instance =  new DumpPatchService(FileService);
export default instance;