import { query } from "@core/services/orm/query";

import { PhaseEntity } from "@archipad-models/models/PhaseEntity";
import { BugGroupEntity } from "@archipad-models/models/BugGroupEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";


/**
 * Data access service for phases.
 */
export class PhaseService {
    //#region Phase
    /**
     * Return the all phases of Project
     */
    public listPhases(project: ProjectEntity): readonly PhaseEntity[] {
        const entityContext = project.getContext();
        const queryPhaseBugGroups = query(entityContext, "Phase_BugGroups");
        queryPhaseBugGroups.orderBy('bugGroup.id, phase.index, phase.name, phase.id');
        const possibleBugGroupPhases = queryPhaseBugGroups.execute({}).sortedEntities();

        if (possibleBugGroupPhases.length) {
            return possibleBugGroupPhases.map(relation => relation.phase);
        }
        // No filter
        // Fallback, no relation in Phase_BugGroups (or impossible business case: No phase for said BugGroup in relation table)
        const queryPhasesProject = query(entityContext, "Phase");
        queryPhasesProject.orderBy('index, name, id');
        const phases = queryPhasesProject.execute({}).sortedEntities();
        return phases;
    }

    /**
    * Return the phases based on the bugGroup
    * Phase_BugGroups relation table is empty or non-existent, return all phases of project.
    */
    public listPhasesForBugGroup(project: ProjectEntity, bugGroup: BugGroupEntity | null): readonly PhaseEntity[] {
        const entityContext = project.getContext();
        const queryBugGroupPhase = query(entityContext, "Phase_BugGroups");
        queryBugGroupPhase.predicateWithFormat("bugGroup == {bugGroup}");
        queryBugGroupPhase.orderBy('phase.index, phase.name, phase.id');
        const predicateParams = { 'bugGroup': bugGroup };

        const resultSet = queryBugGroupPhase.execute(predicateParams);
        const possibleBugGroupPhases = resultSet.sortedEntities();

        if (possibleBugGroupPhases.length) {
            return possibleBugGroupPhases.map(relation => relation.phase);
        }

        // No filter
        // Fallback, no relation in Phase_BugGroups (or impossible business case: No phase for said BugGroup in relation table)
        return this.listPhases(project);
    }

    /**
     * Return the default phase based on the bugGroup
     * If the bugGroup is null, then return the default phase based on the project
     */
    public getDefaultPhaseForBugGroup(project: ProjectEntity, bugGroup: BugGroupEntity | null): PhaseEntity | null {
        const phases: readonly PhaseEntity[] = this.listPhasesForBugGroup(project, bugGroup);
        const defaultPhase = phases[0] ?? null;
        return defaultPhase;
    }

    //#endregion
}