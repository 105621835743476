/**
 * Base definitions of sections and inspector descriptors
 */
import * as t from 'io-ts';

// TODO XXX CLEANUP : move to Archipad-report in deliverable 3
export enum SectionType {
    ReportSection = 'report-section',
    ProjectInfoSection = 'project-info-section',
    ParticipantsSection = 'participants-section',
    SignaturesSection = 'signatures-section',
    ObservationsSection = 'observations-section',
    RemarksSection = 'remarks-section',
    NotesSection = 'notes-section',
    AnnexesSection = 'annexes-section',
    PlanningSection = 'planning-section',
    // Promoimmo sections
    LotsInfoSection = 'lots-info-section',
    ElementsRemisSection = 'elements-remis-section',
    CompteursSection = 'compteurs-section',
    DeclarationSection = 'declaration-section',
    SoldeSection = 'solde-section',
    MentionsLegalesSection = 'mentions-legales-section',
}
export const SectionTypeV = t.keyof({
    [SectionType.ReportSection]: SectionType.ReportSection,
    [SectionType.ProjectInfoSection]: SectionType.ProjectInfoSection,
    [SectionType.ParticipantsSection]: SectionType.ParticipantsSection,
    [SectionType.SignaturesSection]: SectionType.SignaturesSection,
    [SectionType.ObservationsSection]: SectionType.ObservationsSection,
    [SectionType.RemarksSection]: SectionType.RemarksSection,
    [SectionType.NotesSection]: SectionType.NotesSection,
    [SectionType.AnnexesSection]: SectionType.AnnexesSection,
    [SectionType.PlanningSection]: SectionType.PlanningSection,
    // PromoImmo sections
    [SectionType.LotsInfoSection]: SectionType.LotsInfoSection,
    [SectionType.ElementsRemisSection]: SectionType.ElementsRemisSection,
    [SectionType.CompteursSection]: SectionType.CompteursSection,
    [SectionType.DeclarationSection]: SectionType.DeclarationSection,
    [SectionType.SoldeSection]: SectionType.SoldeSection,
    [SectionType.MentionsLegalesSection]: SectionType.MentionsLegalesSection,
});

export const enum ReportTemplateDestination {
    User = 'user',
    Project = 'project',
}

/**
 * Config classes declaration
 */

/**
 * IO-TS Model -
 * This is the base interface for ReportTemplate section configurations
 * Contains the minimum vital information for a section to display : it's section id and section type
 */
export const ReportTemplateSectionConfigModel = t.interface({
    /**
     * SectionId of the configured section
     */
    sectionId: t.string,

    /**
     * Type of the configured section (report-section, participant-sections, ...)
     * Section must be defined section (Definition.availableSections)
     */
    sectionType: t.string,
});
export type ReportTemplateSectionConfigModel = t.TypeOf<typeof ReportTemplateSectionConfigModel>;

/**
 * IO-TS Model -
 * A specialisation of a base SectionConfig able to handle sub-sections
 */
export const ReportTemplateSectionContainerConfigModel = t.intersection([
    t.partial({
        name: t.string,
        /**
         * The child sections configuration objects, referenced by sectionId
         * Contains all sections, even invisible/removed ones
         */
        sections: t.record(t.string, ReportTemplateSectionConfigModel),

        /**
         * The order the sections should be. Contains the visible sections and their order
         */
        sectionsOrder: t.array(t.string),
    }),
    ReportTemplateSectionConfigModel,
]);
export type ReportTemplateSectionContainerConfigModel = t.TypeOf<typeof ReportTemplateSectionContainerConfigModel>;
