import { RemarkCategoryEntity } from "../RemarkCategoryEntity";
import { PersonEntity } from "../PersonEntity";

export const REMARK_CATEGORY_ENTITY_TEMPLATE_ID = 'C52EBADDF91944CD8DAD7143C63D8E26';


Object.defineProperty(RemarkCategoryEntity.prototype, 'contact', {
    get: function(this:RemarkCategoryEntity): PersonEntity | null {
        const self : RemarkCategoryEntity = this;
        if (self.projectMember)
            return self.projectMember.person;
        if (self.workPackage)
            return self.workPackage.contact;
        if (self.visitor)
            return self.visitor;
        return null;
    },
    enumerable: true,
});
