// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as longFormatters from "date-fns/_lib/format/longFormatters";
import { format, parse, isValid, isDate, formatDistanceStrict, fromUnixTime } from "date-fns";
import * as Locales from "date-fns/locale";
import { IllegalArgumentError, UnsupportedOperationError } from "@archipad-js/core/error";
import { LocalizationService } from "./localization.service";
import { Localization_Service_DIID } from "./translate.types";
import { inject, injectable } from "inversify";
import { LongDateFormat } from "./longDateFormat";

type DateStyleFormatType = "default" | "short" | "medium" | "short-day" | "long" | "iso" | "datetime-iso" | string;
type DatetimeStyleFormatType = "default" | "hide_midnight" | "long" | string;

@injectable()
export class InternationalizationService {
    private _localizationService: LocalizationService;

    constructor(
        @inject(Localization_Service_DIID.symbol)
        localizationService: LocalizationService,
    ) {
        this._localizationService = localizationService;
    }

    /**
     * Convert a bytes amount to a human readable string with unit
     * @param decimals Number of digits after the decimal (default to 1)
     * @example
     * formatBytes(11) => "11o"
     * formatBytes(1500, 2) => "1.5 Ko"
     * formatBytes(1500000, 0) => "2 Mo"
     */
    public formatBytes(bytes: number, decimals = 1) {
        const locale = this._localizationService.locale.baseName;
        const options: Intl.NumberFormatOptions = {
            notation: "compact",
            style: "unit",
            unit: "byte",
            unitDisplay: "narrow",
            maximumFractionDigits: decimals,
        };

        return Intl.NumberFormat(locale, options).format(bytes);
    }

    public dateFnsLocale(): Locale {
        switch (this._localizationService.locale.baseName) {
            case "en": {
                return Locales["enUS"];
            }
            /**
             * @see [AP-9434](https://bigsool-archipad.atlassian.net/browse/AP-9434)
             */
            case "fr-FR": {
                return Locales["fr"];
            }
            /**
             * Some cases like "en-FR" exists but are non-existant in DateFns this can lead to this default case being 'undefined'
             * Espcially on Ipad since Apple are correct but Date-FNS isn't.
             * When this happen a defaulting by Date-FNS is done that can lead to wrong date formatting.
             */
            default: {
                const dateFnsLocale = [
                    this._localizationService.locale.language,
                    this._localizationService.locale.region,
                ].join("") as keyof typeof Locales;
                return Locales[dateFnsLocale];
            }
        }
    }

    //#region DateInternationalization

    /**
     * Return the date format for the current locale.
     * Ex : [FR] dd/MM/yyyy
     *      [EN] M/d/yy
     */
    getDateFormat(style: DateStyleFormatType = "default"): string {
        let format: string;
        switch (style) {
            case "default":
            case "short": {
                const symbolicFormat = this._dateStyleToFormat(style);
                const formatter = longFormatters[symbolicFormat];
                const localeData = this.dateFnsLocale();
                if (!localeData || !localeData.formatLong) {
                    throw new UnsupportedOperationError(
                        `date format style \`${style}\` not supported for locale \`${this._localizationService.locale}\`.`,
                    );
                }
                format = formatter(symbolicFormat, localeData.formatLong);
                break;
            }
            default:
                throw new UnsupportedOperationError(`date format style \`${style}\`.`);
        }
        return format;
    }

    /**
     * From date-fns docs:
     * Return the distance between the given dates in words, using strict units.
     * This is like formatDistance, but does not use helpers like 'almost', 'over', 'less than' and the like.
     * Ex.: '6 months', '2 days', '15 seconds'
     *
     */
    formatDistance(date: Date | number, from: Date | number = new Date()): string {
        return formatDistanceStrict(from, date, {
            locale: this.dateFnsLocale(),
        });
    }

    /**
     * @see : https://date-fns.org/docs/I18n
     * by providing a default string of 'PP' or any of its variants for `formatStr`
     * it will format dates in whichever way is appropriate to the locale
     */
    simpleFormatDate(date: Date | number, formatStr = "PP"): string {
        return format(date, formatStr, {
            locale: this.dateFnsLocale(),
        });
    }

    /**
     * Format a date according to the current locale.
     * @param d The date value to format. If not a Date but a Timestamp, it will be converted to one.
     * @param style Ex: 'short', 'medium', 'long', 'iso', 'datetime-iso'
     */
    formatDate(d: Date | number, style: DateStyleFormatType = "default"): string {
        if (d === null || d === undefined) {
            return this._localizationService.get("Invalid date").toString();
        }
        if (typeof d === "number") {
            d = new Date(d);
        }

        const format = this._dateStyleToFormat(style);
        return this.simpleFormatDate(d, format);
    }

    /**
     * @param formatStr Ex: 'dd/MM/yyyy' or 'MM/dd/yyyy'
     */
    simpleParseDate(str: string, formatStr: string): Date {
        return parse(str, formatStr, new Date(), {
            locale: this.dateFnsLocale(),
        });
    }

    parseDate(str: string, style: DateStyleFormatType = "default"): Date | null {
        const format = this._dateStyleToFormat(style);
        const m = this.simpleParseDate(str, format);
        return isValid(m) ? m : null;
    }

    /**
     * Return true if date is a Date instance
     * Otherwise return false
     */
    isDate(date: unknown): date is Date {
        return isDate(date);
    }

    private _dateStyleToFormat(style: DateStyleFormatType): string {
        let format: string;
        switch (style) {
            case "default":
            case "short":
                format = LongDateFormat.date_short(this._localizationService.locale);
                break;
            case "medium":
                format = LongDateFormat.date_medium(this._localizationService.locale);
                break;
            case "short-day":
                format = LongDateFormat.date_short_day(this._localizationService.locale);
                break;
            case "long-day":
                format = LongDateFormat.long_day(this._localizationService.locale);
                break;
            case "long":
                format = LongDateFormat.date_long(this._localizationService.locale);
                break;
            case "iso":
                format = LongDateFormat.date_iso();
                break;
            case "datetime-iso":
                format = LongDateFormat.datetime_iso();
                break;
            default:
                throw new IllegalArgumentError(`Unknown date format style \`${style}\`.`);
        }
        return format;
    }

    //#endregion
    //#region TimeInternationalization

    /**
     * Return the time format for the current locale.
     * Ex: [FR] HH:mm
     */
    getTimeFormat(style = "default"): string {
        let format: string;
        switch (style) {
            case "short":
            case "default": {
                const symbolicFormat = this._timeStyleToFormat(style);
                const formatter = longFormatters[symbolicFormat];
                const localeData = this.dateFnsLocale();
                format = formatter(symbolicFormat, localeData.formatLong);
                break;
            }
            default:
                throw new UnsupportedOperationError(`time format style \`${style}\`.`);
        }
        return format;
    }

    /**
     * Format a time according to the current locale.
     * @param style Ex: 'default', 'hide_midnight', 'long'
     * @returns
     */
    formatDatetime(d: Date | number, style: DatetimeStyleFormatType = "default"): string {
        if (typeof d === "number") {
            d = fromUnixTime(d);
        }

        const format = this._dateTimeStyleToFormat(style, d);
        return this.simpleFormatDate(d, format);
    }

    parseDatetime(str: string, style: DatetimeStyleFormatType = "default"): Date {
        const format = this._dateTimeStyleToFormat(style);
        return this.simpleParseDate(str, format);
    }

    private _dateTimeStyleToFormat(style: DatetimeStyleFormatType, date?: Date): string {
        let format: string;
        switch (style) {
            case "short":
            case "default":
                format = LongDateFormat.datetime_short(this._localizationService.locale);
                break;
            case "hide_midnight":
                if (date === undefined) {
                    throw new IllegalArgumentError("IllegalArgument");
                }
                format = LongDateFormat.datetime_hide_midnight(this._localizationService.locale, date);
                break;
            case "long":
                format = LongDateFormat.datetime_long(this._localizationService.locale);
                break;
            default:
                throw new IllegalArgumentError(`Unknown date format style \`${style}\`.`);
        }
        return format;
    }

    private _timeStyleToFormat(style: "default" | "short"): string {
        let format: string;
        switch (style) {
            case "default":
            case "short":
                format = LongDateFormat.time_short(this._localizationService.locale);
                break;
        }
        return format;
    }

    //#endregion
}
