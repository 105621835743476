import * as Authenticated from '@archipad/backend/rpc/abortable/authenticated';
import * as Task from '@core/services/task';
import { callAsTask } from '@core/tasks/asyncCaller';


export async function makeAuthenticatedRequest<T=any>(task: Task.Task, serviceName:string, methodName:string, methodParams?:Record<string,any>) : Promise<T> {
    const result : T = await callAsTask(task, async (signal, po) =>
        Authenticated.makeAuthenticatedRequest(signal, po, serviceName, methodName, methodParams) );
    return result;
}

export async function makeUnauthenticatedRequest<T=any>(task: Task.Task, serviceName:string, methodName:string, methodParams?:Record<string,any>) : Promise<T> {
    const result : T = await callAsTask(task, async (signal, po) =>
        Authenticated.makeUnauthenticatedRequest(signal, po, serviceName, methodName, methodParams) );
    return result;
}
