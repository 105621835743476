import { z } from "zod";

import { PropertyDescription, PropertyDescriptionMap, ValuePropertyType } from "./types";

export function makeValidationSchema(
    properties: PropertyDescriptionMap,
): z.ZodObject<Record<string, z.ZodOptional<z.ZodTypeAny>>> {
    const shape: z.ZodRawShape = Object.fromEntries(
        Object.entries(properties).map(([propertyName, propertyDescription]) => [
            propertyName,
            propertyDescriptionToSchema(propertyDescription),
        ]),
    );

    const schema = z.object(shape).strict().partial();
    return schema;
}

function propertyDescriptionToSchema(propertyDescription: PropertyDescription): z.ZodTypeAny {
    if (propertyDescription.relation === true) {
        if (propertyDescription.single) {
            return z.unknown();
        }

        return z.never();
    }

    if (propertyDescription.calculated) {
        return z.never();
    }

    const type = valuePropertyTypeToSchema(propertyDescription.type);

    if (propertyDescription.optional) {
        return type.nullable();
    }

    return type;
}

function valuePropertyTypeToSchema(type: ValuePropertyType): z.ZodTypeAny {
    switch (type) {
        case "string": {
            return z.string();
        }
        case "number": {
            return z.number();
        }
        case "boolean": {
            return z.boolean();
        }
        case "date": {
            return z.date();
        }
        case "object": {
            return z.unknown();
        }
    }
}
