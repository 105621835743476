import { createLogger } from '@core/services/logger.service';
import { BaseEntity, Validator } from '@core/services/orm/orm';

const classModules = require.context('@archipad-models/models/', false, /Entity\.ts$/);
const extensionModules = require.context('@archipad-models/models/extensions/', false, /Entity-ext\.ts$/);
const validators = require.context('@archipad-models/models/validators/', false, /.ts$/);

const logger = createLogger('orm-entity-classes');

export function getEntityClasses(): ReadonlyMap<string, typeof BaseEntity> {
    const entityModuleRegExp = /(\w+)Entity\.ts$/;

    const moduleNames = classModules.keys();

    const entityClasses: Map<string, typeof BaseEntity> = new Map();
    const extendedEntityClassNames: Set<string> = new Set();

    for (const moduleName of moduleNames) {
        const match = moduleName.match(entityModuleRegExp);
        if (match === null) {
            continue;
        }

        const [, entityName] = match;

        const module = classModules(moduleName);
        const extensionModuleName = `./${entityName}Entity-ext.ts`;
        const hasExtension = extensionModules.keys().includes(extensionModuleName);
        if (hasExtension) {
            extendedEntityClassNames.add(entityName);
            extensionModules(extensionModuleName);
        }

        const cls: typeof BaseEntity = module[`${entityName}Entity`];
        entityClasses.set(entityName, cls);
    }

    logger.debug('orm entity class extended found:', Array.from(extendedEntityClassNames));

    return entityClasses;
}

export function getValidators(): ReadonlyArray<Validator> {
    const ret = [];
    for(const key of validators.keys()) {
        const module = validators(key);
        ret.push(module.default);
    }
    return ret;
}
