import { Observable, Observer, combineLatest, defer, of, throwError } from 'rxjs';
import { map, switchMap, take, catchError, share } from 'rxjs/operators';
import { IllegalArgumentError } from '@core/errors/errors-core';
export type BuildTaskFn<T> = (...args:any[])=>(Observable<T> | Promise<T> | T );
export type Validity<T> = ((v:T, lastUpdate:number)=>boolean) | number;

/**
 * Cache for cold observables
 *
 * - Keeps the result of a cold observable in memory for given validity period.
 * - Multiple subscriptions to the same Request are shared, there is at most
 *  one subscription to a request at a time.
 *
 * @param ...dependencies an array of observables or promises on which the request depends
 * @param fn a function that builds the request
 * @param validity either a function to test the curent value validity or the value time to live. Default is 0 which means illimited ttl.
 * @return a cached cold observable
 * 
 * @todo Use factories instead of constructor with variable arguments.
 * @todo Redesign this API to handle AbortSignal and Progress.
 * @todo Redesign the public API to be Promise only (but still use Observable under the hood for the share() behavior).
 * @todo Add a way to handle multiple cache requests entrypoints (like an individual cache policy for different users) maybe add another class for it.
 * @todo {@link https://developer.mozilla.org/en-US/docs/Web/API/Cache CACHE API} Check to replace the storage of values by the CACHE API
 */
export class Request<T> {
    private _value:T;
    private _lastUpdate:number = 0;
    private _isValid:(v:T, lastUpdate:number)=>boolean;

    private _request: Observable<T>;
    private _activeRequest: Observable<T>;

    constructor(...args:(Observable<any> | Promise<any> |  BuildTaskFn<T> | Validity<T>)[]) {
        const dependencies:(Observable<any> | Promise<any>)[] = [];
        let fn:BuildTaskFn<T>;
        let validity:Validity<T> = 0;

        let i;
        for(i=0;i<args.length;i++) {
            const arg = args[i];
            if(arg && (arg instanceof Observable || arg instanceof Promise)) {
                dependencies.push(arg as Observable<any> | Promise<any>);
            } else {
                break;
            }
        }
        if(i >= args.length || typeof args[i] !== 'function')
            throw new IllegalArgumentError('Wrong type or wrong number of arguments');
        fn = args[i++] as BuildTaskFn<T>;
        if(i < args.length)
            validity = args[i++] as Validity<T>;

        this._value = null;
        this._lastUpdate = 0;

        if(typeof validity === 'function') {
            this._isValid = validity;
        } else if(typeof validity === 'number') {
            if(validity === 0)
                this._isValid = function(v:T, lastUpdate:number) { return lastUpdate !== 0; }
            else
                this._isValid = function(v:T, lastUpdate:number) { return lastUpdate+(validity as number) > (new Date()).getTime(); }
        } else {
            throw new IllegalArgumentError('Wrong type of validity argument');
        }

        if(dependencies.length){
            this._request = combineLatest(dependencies).pipe(
                switchMap((dependencyValues) => {
                    const request = fn.apply(null, dependencyValues);
                    if(!request || (!(request instanceof Promise) && !(request instanceof Observable))){
                        return of<T>(request);
                    } else {
                        return request;
                    }
                })
            );
        } else {
            this._request = defer(function() {
                const request = fn();
                if(!request || (!(request instanceof Promise) && !(request instanceof Observable)))
                    return of<T>(request as T);
                else
                    return request as Observable<T> | Promise<T>;
            });
        }

        this._activeRequest = null;
    }

    /**
     * Clear the cache
     *
     * Invalidate the cache value
     */
    clear() {
        this._lastUpdate = 0;
        this._activeRequest = null;
    }

    /**
     * Fetch the request
     *
     * @return an observable that resolves immediately to the cached value if it is still valid
     */
    fetch(): Observable<T> {
        return new Observable((observer: Observer<T>) => {
            if(this._isValid(this._value, this._lastUpdate)) {
                observer.next(this._value);
                observer.complete();
                return ()=>{};
            }

            if(!this._activeRequest) {
                const activeRequest = this._request.pipe(
                    take(1),
                    map((v) => {
                        // check if the current request is still active (cache
                        // might have been cleared)
                        if(this._activeRequest === activeRequest) {
                            this._value = v;
                            this._lastUpdate = (new Date()).getTime();
                            this._activeRequest = null;
                        }
                        return v;
                      }),
                      catchError(err => {
                        // clear the active request on error
                        this._activeRequest = null;
                        return throwError(err);
                      }),
                      share() // share subscription

                ); 

                this._activeRequest = activeRequest;
            }

            return this._activeRequest
                .subscribe(observer);
        });
    }
}


