import { BugEntity } from "@archipad-models/models/BugEntity";
import { BugNoteEntity } from "@archipad-models/models/BugNoteEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";
import _WorkflowUserManager, { WorkflowUserManager } from "@archipad/backend/project/workflowUserManager";
import { generateDeterministicId } from "@core/services/orm/idGenerator";
import { query } from "@core/services/orm/query";
import { BugService } from "./bug.service";
import { EquivBugState, EquivBugTransition } from "@archipad/backend/project/workflowManagerConstant";


export class BugNoteFacade {
    constructor(
        private readonly workflowUserManager: WorkflowUserManager,
        private readonly bugService: BugService,
    ) { }

    createBugNote(bug: BugEntity, visit: VisitEntity): BugNoteEntity {
        const bugNote = this.createBugNoteModel(bug);
        try {
            bugNote.creationDate = new Date();
            bugNote.text = null;
            bugNote.visit = visit;
            bugNote.user = this.workflowUserManager.getCurrentUser(bug.visit.project);
            bugNote.bugState = null;
            return bugNote;
        }
        catch (ex) {
            bugNote.getContext().deleteEntity(bugNote);
            throw ex;
        }
    }

    upgradeBugNotes(project: ProjectEntity): void {
        const entityContext = project.getContext();
        const queryBug = query(entityContext, "Bug");
        const resultSet = queryBug.execute({});
        resultSet.forEach((bug: BugEntity) => {
            this._convertToBugNotes(bug);
        }, this);
    }

    createBugNoteModelPrimitive(bug: BugEntity, deterministicId?): BugNoteEntity {
        const entityContext = bug.getContext();
        const bugNote = entityContext.createEntity("BugNote", deterministicId);
        bugNote.bug = bug;
        return bugNote;
    }

    createBugNoteModel(bug: BugEntity): BugNoteEntity {
        if (bug.notes.length == 0) {
            this._convertToBugNotes(bug);
        }
        return this.createBugNoteModelPrimitive(bug);
    }

    private _convertToBugNotes(bug: BugEntity) {
        if (bug.notes.length > 1) {
            return;
        }

        const project = bug.visit.project;

        // optional entity, mapped for : bug created
        if (bug.notes.length == 0) {
            const bugNoteId = generateDeterministicId(bug.bugId, "1AB0DC21BB2D4EC28B5DFDC82898AC89", "uuid");
            const bugNote = this.createBugNoteModelPrimitive(bug, bugNoteId);
            bugNote.creationDate = bug.visit.date;
            bugNote.text = null;
            bugNote.visit = bug.visit;
            bugNote.user = null;
            bugNote.bugState = this.bugService.findEquivBugState(EquivBugState.EquivStateOpen, project);
            bugNote.bugTransition = this.bugService.findEquivTransition(EquivBugTransition.EquivTransitionEnter, bugNote.bugState, project);
        }

        // optional entity, mapped for : bug solved
        if (bug.notes.length == 1 && bug.closeVisit != null) {
            const bugNoteId = generateDeterministicId(bug.bugId, "887329BD114949CCB2D0CBCC31B3F344", "uuid");
            const bugNote = this.createBugNoteModelPrimitive(bug, bugNoteId);
            const newDate = new Date(bug.closeVisit.date.getTime() + 1000 * 60);
            bugNote.creationDate = newDate;
            bugNote.text = null;
            bugNote.visit = bug.closeVisit;
            bugNote.user = null;
            bugNote.bugState = this.bugService.findEquivBugState(EquivBugState.EquivStateClosed, project);
            bugNote.bugTransition = this.bugService.findEquivTransition(EquivBugTransition.EquivTransitionAccept, bugNote.bugState, project);
        }
    }
}

export default new BugNoteFacade(
    _WorkflowUserManager,
    new BugService(),
);