import "reflect-metadata";

import { inject, injectable } from "inversify";
import { type UserManager } from "oidc-client-ts";

import { OAuth_Client_UserManager_DIID } from "./oauth-client.type";

@injectable()
export class OAuthClientService {
    constructor(
        @inject(OAuth_Client_UserManager_DIID.symbol)
        protected readonly userManager: UserManager,
    ) {}

    /**
     * If is required, redirects to the OAuth provider login page
     */
    public async startLoginFlow(urlDestination: string): Promise<void | never> {
        if (await this.canBeSilentlyAuthenticated()) {
            try {
                await this.userManager.signinSilent();
                return;
            } catch (e) {
                await this.userManager.signoutSilent();
                throw e;
            }
        }
        await this.userManager.signinRedirect({
            url_state: urlDestination,
        });
        return;
    }

    /**
     * @returns The url to redirect to, or null if the user is already authenticated
     */
    public async completeLoginFlow(): Promise<string | undefined> {
        const user = await this.userManager.getUser();
        if (await this.canBeSilentlyAuthenticated()) {
            await this.userManager.signinSilent();
            if (user?.url_state) {
                return user.url_state;
            }
            return;
        }
        const userFromRedirect = await this.userManager.signinRedirectCallback();
        if (userFromRedirect?.url_state) {
            return decodeURIComponent(userFromRedirect.url_state);
        }
        return;
    }

    public async getAccessToken(): Promise<string | null> {
        const user = await this.userManager.getUser();
        if (!user) {
            return null;
        }
        return user.access_token;
    }

    public async logout(): Promise<void> {
        await this.userManager.removeUser();
        // todo redirect to the logout page
    }

    /**
     * Returns true if the user can be / is silently authenticated
     */
    public async canBeSilentlyAuthenticated(): Promise<boolean> {
        const user = await this.userManager.getUser();
        if (!user) {
            return false;
        }
        if (user.expired) {
            return false;
        }
        if (!user.access_token) {
            return false;
        }
        if (!user.refresh_token) {
            return false;
        }
        return true;
    }

    /**
     * Adapt the error description for the UI
     */
    public formatOAuthErrorDescription(errorDescription: string): string {
        // Replace All + by space
        return errorDescription.replace(/\+/g, " ");
    }
}
