import { interfaces } from "inversify";

/**
 * An identifier that can be used with `inversify` or that can be extended
 * to return an Angular `InjectionToken`.
 */
export interface DependencyInjectionIdentifier<T> {
    readonly symbol: interfaces.ServiceIdentifier<T>;
    readonly name: string;
}

/**
 * An identifier that can be used with `inversify` or that can be extended
 * to return an Angular `InjectionToken`.
 */
export class DependencyInjectionIdentifierProxy<T> /* implements DependencyInjectionIdentifier<T> */ {
    public readonly symbol: interfaces.ServiceIdentifier<T>;

    /**
     * @param name should be equal to name_of_type(T)
     */
    constructor(public readonly name: string) {
        this.symbol = Symbol(name);
    }
}

/**
 * Short alias for creating a {@link DependencyInjectionIdentifier}
 */
export function DIID<T>(name: string): DependencyInjectionIdentifier<T> {
    return new DependencyInjectionIdentifierProxy<T>(name) as DependencyInjectionIdentifier<T>;
}
