import { z } from "zod";

export interface ORMModelConf {
    entities: EntityConf[];
}

export interface EntityConf {
    model?: boolean | number;
    name: string;
    import?: boolean | number;
    export?: boolean | number;
    attributes?: AttributeConf[];
    relations?: RelationConf[];
}

export interface AttributeConf {
    model?: boolean | number;
    name: string;
    attributeType: AttributeTypeConf;
    defaultValue: unknown;
    indexed?: boolean | number;
    import?: boolean | number;
    export?: ExportTypeConf;
    importable?: boolean | number;
    transient?: boolean | number;
    optional?: boolean | number;
    calculated?: boolean | number;
}

export interface RelationConf extends RelationEndpointConf {
    model?: boolean | number;
    destinationEntity: string;
    owning?: boolean | number;
    inverse?: RelationEndpointConf;
}

export interface RelationEndpointConf {
    name: string;
    min?: number;
    max?: number;
    import?: boolean | number;
    export?: ExportTypeConf;
    optional?: boolean | number;
    deleteRule?: DeleteRuleConf;
    calculated?: boolean | number;
}

export type AttributeTypeConf =
    | "int16"
    | "int32"
    | "int64"
    | "decimal"
    | "double"
    | "float"
    | "string"
    | "bool"
    | "date"
    | "binary"
    | "transformable"
    | "undefined"
    | "uuid";

export type ExportTypeConf = boolean | number | "no" | "yes" | "clearOnExport" | "cache";

export type DeleteRuleConf = "nullify" | "cascade";

export interface BasePropertyDescription {
    name: string;
    /** export reason */
    export: ExportKind;
    /** is the property exportable */
    exportable: boolean;
    /** is the property importable */
    importable: boolean;
    optional: boolean;
    /** is the property calculated : a getter property defined in an entity extension `XxxEntity-ext.ts` */
    calculated?: boolean;
}

export type ValuePropertyType = "string" | "number" | "boolean" | "date" | "object";

export interface ValuePropertyDescription extends BasePropertyDescription {
    relation: false;
    type: ValuePropertyType;
    originType: AttributeTypeConf;
    primaryKey?: boolean;
    /** is the property transient (cached value, only stored in RAM). Note: reset to default value after a Commit on cloud */
    transient: boolean;
    defaultValue: unknown;
}

export interface RelationDescription extends BasePropertyDescription {
    relation: true;
    single: boolean;
    target: EntityType;
    inverseField?: string;
    inverseEntity: EntityType;
    isInverse: boolean;
    deleteRule?: DeleteRuleConf;
    owning: boolean;
    originType: AttributeTypeConf;
}

export interface EntityType {
    /** entity name */
    entityName: string;
    /** entity property descriptions */
    metadata: PropertyDescriptionMap;
    /** is the entity importable */
    import: boolean;
    /** is the entity exportable */
    export: boolean;

    /**
     * Validation schema for the entity.
     */
    readonly validationSchema: z.ZodObject<Record<string, z.ZodOptional<z.ZodTypeAny>>>;

    /**
     * Get the property description for a given keypath
     *
     * a.b.c -> propertyC
     */
    propertyForKeyPath(keypath: string): PropertyDescription | null;

    /**
     * Get the property description for all elements in a given keypath
     *
     * a.b.c -> [propertyA, propertyB, propertyC]
     */
    keyPathDescription(keypath: string): PropertyDescription[] | null;
}

export interface EntityTypes {
    readonly byIndex: ReadonlyArray<EntityType>;
    readonly byName: Readonly<Record<string, EntityType>>;
}

export type PropertyDescription = ValuePropertyDescription | RelationDescription;
export type PropertyDescriptionMap = Record<string, PropertyDescription>;

export enum ExportKind {
    // Implies exportable=false
    // No reason specified
    no = 0,
    // Implies exportable=true
    // No reason specified
    yes = 1,
    // Implies exportable=true
    // The property is cleared after a successful export.
    clearOnExport = 2,
    // Implies exportable=false
    // The property is a cache. It can be rebuilt using other data from the ORM.
    cache = 3,
}
