import { isPlainObject as isPlainObjectFunction } from "is-plain-object";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RecordKey = keyof any;

/**
 * Checks whether the value is a plain object
 *
 * A plain object is an object with properties that is not an instance of a class.
 */
export function isPlainObject(value: unknown): value is Record<RecordKey, unknown> {
    return isPlainObjectFunction(value);
}

/**
 * Checks whether the value is a record (an object with properties).
 */
export function isRecord(value: unknown): value is Record<RecordKey, unknown> {
    return (
        value !== undefined &&
        value !== null &&
        (typeof value === "object" || typeof value === "function") &&
        !Array.isArray(value)
    );
}
