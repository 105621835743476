import { IllegalStateError } from "@core/errors/errors-core";

/** elements in which the shadow dom should be searched for predicates */
const lookInsidePredicates: ElementPredicate[] = [];

/*---------------------------------------------------------------------------*/
/**
 * Return a list of elements that matches the predicate on parent node
 */
export type ElementPredicate = (parentNode:ParentNode) => Element[];

/**
 * An element iterator
 */
export type ElementIterator = (element:Element) => boolean;

/*---------------------------------------------------------------------------*/
/**
 * Make an element predicate from a CSS selector
 * 
 * @param selector a CSS selector
 * @param element predicate
 */
export function makeQuerySelectorPredicate(selector:string) {
    return (parentNode) => {
        if(!parentNode)
            return [];
        return Array.prototype.slice.call(parentNode.querySelectorAll(selector));
    }
}


/**
 * Add a selector to the lookInsideElement list
 * 
 * @param selector the css selector to add
 */
export function addLookInsideElement(selector:string) {
    lookInsidePredicates.push(makeQuerySelectorPredicate(selector));
}


/**
 * Research elements in the shadow dom of a parent element
 *
 * @param parentElement the parent element
 * @param selector the css selector
 * @param elementsFound array which contains the elements matching the css selector
 */
function researchElementsInShadowRoot(parentElement:ParentNode, selector:string, elementsFound:Node[]) {

    // walk into all the visible dom
    const elements = Array.prototype.slice.call(parentElement.children);
    elements.forEach(function(elem){
        researchElementsInShadowRoot(elem, selector, elementsFound);
    });

    if (parentElement['shadowRoot']) {
        // pierce one shadowRoot and start the research of elements on this new root
        researchElementsFromRoot(parentElement['shadowRoot'], selector, elementsFound);
    }

}

/**
 * Research elements that can be present anywhere in a root element
 *
 * @param rootElement the root element
 * @param selector the css selector
 * @param elementsFound array which contains the elements matching the css selector
 */
function researchElementsFromRoot(rootElement:ParentNode, selector, elementsFound:Node[]) {

    // 1 : research elements in the visible dom
    const elements = rootElement.querySelectorAll(selector);
    if (elements) {
        elements.forEach(function(element){
            elementsFound.push(element);
        });
    }

    // 2 : research elements in the shadow dom
    researchElementsInShadowRoot(rootElement, selector, elementsFound);

}

/**
 * Walk an element list
 * /
function _walkElements(parentElement:ParentNode, predicate:ElementPredicate, iterator:ElementIterator):boolean {
    if(!parentElement)
        return false;
    
    // walk light dom
    const elements = predicate(parentElement);
    for(const element of elements) {
        if(iterator(element))
            return true;
    }

    // collect the shadow root of the lookInsideElements
    let shadowRoots:ShadowRoot[] = [];

    // add the parent element own shadowRoot if there is one
    if(parentElement['shadowRoot'])
        shadowRoots.push(parentElement['shadowRoot']);

    for(const lookInsidePredicate of lookInsidePredicates) {
        const elements = lookInsidePredicate(parentElement);
        for(const element of elements) {
            shadowRoots.push(element.shadowRoot);
        }
    }

    while(shadowRoots.length) {

        const subShadowRoots:ShadowRoot[] = [];

        // for each shadow root to search
        for(const shadowRoot of shadowRoots) {
            // try finding element directy in the shadow root
            const elements = predicate(shadowRoot);
            for(const element of elements) {
                if(iterator(element))
                    return true;
            }

            // collect the shadow root of the lookInsideElements in current shadow root
            for(const lookInsidePredicate of lookInsidePredicates) {
                const elements = lookInsidePredicate(shadowRoot);
                for(const element of elements) {
                    subShadowRoots.push(element.shadowRoot);
                }
            }
        }

        // explore next level of shadow roots
        shadowRoots = subShadowRoots;
    }

    return false;
}*/

/**
 * Find the first element that matches a predicate.
 * 
 * ```
 * Automation.addLookInsideElement('ui-page');
 * 
 * // find the first ui-string-default element in the page, including the ones in ui-page elements
 * let element = Automation.findElement('ui-string-default');
 * ```
 * 
 * @param selector the selector of the elements to find
 * @param intoParent element to search into (document by default)
 */
export function findElement(selector:string, intoParent:ParentNode = document): Element {

    /*let element = null;
    _walkElements(
        intoParent,
        makeQuerySelectorPredicate(selector),
        (el) => {
            // return the first element that matches
            element = el;
            return true;
        }
    );

    return element;*/

    const elements = [];

    researchElementsFromRoot(intoParent,selector,elements);

    if (elements.length > 1) {
        console.warn('findElement() on selector : ' + selector + ' has found many elements ',elements);
    }

    return elements.length ?  elements[0] : null;

}

/**
 * Find all elements that matches a predicate.
 * 
 * ```
 * Automation.addLookInsideElement('ui-page');
 * 
 * // find all the element that have a name attribute equal to "addItem" in the page, including the ones in ui-page elements
 * let elements = Automation.findElements('[name="addItem"]');
 * ```
 * 
 * @param selector the selector of the elements to find
 * @param intoParent element to search into (document by default)
 */
export function findElements(selector:string, intoParent:ParentNode = document): Element[] {

   /* const elements = [];
    _walkElements(
        intoParent,
        makeQuerySelectorPredicate(selector),
        (el) => {
            // collect all matching elements
            elements.push(el);
            return false;
        }
    );

    return elements;*/

    const elements = [];

    researchElementsFromRoot(intoParent,selector,elements);

    return elements.length ?  elements : null;

}

/**
 * Wait for a condition
 * 
 * @param condition condition to test
 * @param paramsArgument wait parameters
 */
export function waitFor<T>(condition:()=>T, paramsArgument?:{ timeout?:number, interval?:number }): Promise<T> {
    const params = Object.assign({ timeout:25000, interval:200 }, paramsArgument);

    return new Promise<T>(function (resolve, reject) {
        const v = condition();
        if(v) {
            resolve(v);
            return;
        }
        
        const t = new Date().getTime();
        const interval = setInterval(function() {
            const v = condition();
            if(v) {
                clearInterval(interval);
                resolve(v);
            } else if(new Date().getTime()-t > params.timeout) {
                clearInterval(interval);
                const error = new IllegalStateError(`Unable to get element: timeout`);
                reject(error);
            }
        }, params.interval);
    });
}

/**
 * Wait for an element to appear on the page
 * 
 * @param selector the css selector of the element to find
 * @param paramsArgument wait parameters
 */
export function waitForElement(selector:string, paramsArgument?:{ timeout?:number, interval?:number, intoParent?:ParentNode }): Promise<Element> {
    return waitFor<Element>(function() {
        const intoParent = (paramsArgument && paramsArgument.intoParent) ? paramsArgument.intoParent : document;
        return findElement(selector, intoParent);
    }, paramsArgument);
}

// /*---------------------------------------------------------------------------*/
// export interface UISetAction {
//     type: "set"
//     target: ElementPathPredicate;
//     value: any;
// }

// export interface UITapAction {
//     type: "tap"
//     target: ElementPathPredicate;
// }

// export interface UIWaitAction {
//     type: "wait"
//     target: ElementPathPredicate;
//     fn: (element:Element)=>boolean;
// }

// export type UIAction = UISetAction | UITapAction | UIWaitAction;

// /*---------------------------------------------------------------------------*/
// export interface RecorderEventHandler {
//     /**
//      * Start recording events
//      * 
//      * @param document 
//      * @param recorder 
//      */
//     start(document:HTMLDocument, recorder:Recorder):void;
//     /**
//      * Stop recording events
//      */
//     stop():void;
//     /**
//      * Execute a recorded action
//      */
//     execute(target:Element, action:UIAction):Promise<void>;
// };

// export type RecorderActionHandler = (actions:UIAction[])=>void;

// /**
//  * A UI action recorder
//  */
// export class Recorder {
//     private _eventHandlers:RecorderEventHandler[];
//     private _actionHandlers:RecorderActionHandler[];
//     private _actions:UIAction[];

//     constructor() {
//         this._eventHandlers = [];
//         this._actionHandlers = [];
//         this._actions = [];
//     }

//     /**
//      * Add an event handler
//      * 
//      * @param handler 
//      */
//     eventHandler(handler:RecorderEventHandler) {
//         this._eventHandlers.push(handler);
//     }
//     /**
//      * Add an action handler
//      * 
//      * @param handler 
//      */
//     actionHandler(handler:RecorderActionHandler) {
//         this._actionHandlers.push(handler);
//     }

//     /**
//      * Start recording ui actions
//      * 
//      * @param document 
//      */
//     start(document:HTMLDocument) {
//         for(let eventHandler of this._eventHandlers)
//             eventHandler.start(document, this);
//     }
//     /**
//      * Stop recording ui actions
//      * 
//      * @param document 
//      */
//     stop() {
//         for(let eventHandler of this._eventHandlers)
//             eventHandler.stop();
//     } 

//     /**
//      * Add an ui action to the recorder
//      * @param action 
//      */
//     addAction(action:UIAction) {
//         this._actions.push(action);
//         this.actionsUpdated();
//     }
//     /**
//      * Notify a change on the ui action list
//      * 
//      * @param action 
//      */
//     actionsUpdated() {
//         for(let actionHandler of this._actionHandlers)
//             actionHandler(this._actions);
//     }
//     /**
//      * Get the ui action list
//      */
//     getActions() {
//         return this._actions;
//     }

//     /**
//      * Execute a UI action on the page
//      * 
//      * @param action ui action to execute
//      * @return a promise that resolves once the action is complete
//      */
//     executeAction(action:UIAction):Promise<void> {
//         let target = waitForElement(action.target);
//         return target.then((target) => {
//             // find the first handler able to execute the action
//             for(let eventHandler of this._eventHandlers) {
//                 let promise = eventHandler.execute(target, action);
//                 if(promise)
//                     return promise;
//             }
//             return null;
//         });
//     }

//     private processNextAction(actionsToDo:UIAction[]):Promise<void> {
//         let action = actionsToDo.shift();
//         if(!action)
//             return null;
//         return this.executeAction(action).then(() => {
//             return this.processNextAction(actionsToDo);
//         })
//     }

//     /**
//      * Execute a list of UI actions
//      * 
//      * @param actions ui actions to execute
//      * @return a promise that resolves once all actions are executed
//      */
//     executeActions(actions:UIAction[]):Promise<void> {
//         let actionsToDo = actions.slice();
//         return this.processNextAction(actionsToDo);
//     }    
// }

// /*---------------------------------------------------------------------------*/
// /**
//  * A recorder handler that watches change events on data-ui-fields and tap events on data-ui-actions
//  */
// export class DataUIRecorderHandler implements RecorderEventHandler {
//     private _document:HTMLDocument;
//     private _recorder:Recorder;
//     private _boundOnEvent: (evnt:Event)=>void; 

//     constructor() {
//         this._document = null;
//         this._recorder = null;
//         this._boundOnEvent = null;
//     }

//     private _onEvent(event:Event) {
//         let targets = event.composedPath();
//         let actions = this._recorder.getActions();

//         switch(event.type) {
//             case 'click': {
//                 for(let target of targets) {
//                     if(target instanceof Element) {
//                         let name = target.getAttribute('data-ui-action');
//                         if(name) {
//                             this._recorder.addAction({ type:"tap", target:`[data-ui-action='${name}']` });
//                             return;
//                         }
//                     }
//                 }
//             } break;

//             case 'field-state-changed': {
//                 if(event['detail'].state === 'active')
//                     return;

//                 for(let target of targets) {
//                     if(target instanceof Element) {
//                         let name = target.getAttribute('data-ui-field');
//                         if(name) {
//                             let targetSelector = `[data-ui-field='${name}']`;
//                             let action = actions.length ? actions[actions.length-1] : null;
//                             if(action && action.type === 'set' && action.target === targetSelector) {
//                                 // update last set action
//                                 action.value = target['value'];
//                                 this._recorder.actionsUpdated();
//                             }else {
//                                 // create a new set action
//                                 action = { type:"set", target:targetSelector, value:target['value'] };
//                                 this._recorder.addAction(action);
//                             }
//                             return;
//                         }
//                     }
//                 }
//             } break;
//         }
//     }

//     start(document:HTMLDocument, recorder:Recorder) {
//         this._document = document;
//         this._recorder = recorder;

//         this._boundOnEvent = (evnt) => {
//             this._onEvent(evnt);
//         };
//         document.addEventListener('click', this._boundOnEvent, true);
//         document.addEventListener('field-state-changed', this._boundOnEvent, true);
//     }
//     stop() {
//         this._document.removeEventListener('click', this._boundOnEvent, true);
//         this._document.removeEventListener('field-state-changed', this._boundOnEvent, true);
//         this._document = null;
//         this._recorder = null;
//     }
//     execute(target:Element, action:UIAction):Promise<void> {
//         switch(action.type) {
//             case 'set': {
//                 console.log(`[automation] - SET ${action.value} ON ${action.target}`);
//                 target['value'] = action.value;
//                 return Promise.resolve();
//             } break;
//             case 'tap': {
//                 console.log(`[automation] - TAP ON ${action.target}`);
//                 target['click']();
//                 return Promise.resolve();
//             } break;
//             case 'wait': {
//                 console.log(`[automation] - WAIT ON ${action.target}`);
//                 return waitFor(() => action.fn(target)).then(() => {});
//             } break;
//         }
//     }

// }



// let recorder = new Automation.Recorder();
// recorder.eventHandler(new Automation.DataUIRecorderHandler());
// recorder.actionHandler(function(actions) {
//     console.log('*** action', JSON.stringify(actions, null, 4));
// })

// recorder.start(document);

// recorder.executeActions([
//     {
//         "type": "set",
//         "target": "[data-ui-field='login']",
//         "value": "saslawsky@hotmail.com"
//     },
//     {
//         "type": "set",
//         "target": "[data-ui-field='password']",
//         "value": "password"
//     },
//     {
//         "type": "tap",
//         "target": "[data-ui-action='login']"
//     },
//     {
//         "type": "wait",
//         "target": "[data-ui-action='addItem']",
//         fn: (element) => !element.hasAttribute('disabled')
//     },
//     {
//         "type": "tap",
//         "target": "[data-ui-action='addItem']"
//     },
//     {
//         "type": "set",
//         "target": "[data-ui-field='name']",
//         "value": "Blaaaa"
//     },
//     {
//         "type": "set",
//         "target": "[data-ui-field='address']",
//         "value": "Address"
//     },
//     {
//         "type": "set",
//         "target": "[data-ui-field='tokheim_site']",
//         "value": "Site"
//     },
//     {
//         "type": "set",
//         "target": "[data-ui-field='tokheim_code_irisweb']",
//         "value": "112-35-813"
//     }
// ]).then(function() {
//     console.log('[automation] *** ALL DONE');
// }).catch(function(err) {
//     console.log('[automation] *** ERROR', err);
// });

