import { Observable, isObservable, Observer, Unsubscribable, Subscription, throwError, from } from "rxjs";

/*---------------------------------------------------------------------------*/

/**
 * Checks if given object is a Promise-like (thenable) object.
 * @param obj the object to test
 */
function isPromiseLike<T>(arg: any): arg is PromiseLike<T> {
    return arg != null && typeof arg === "object" && typeof arg.then === "function";
}

/**
 * Append the current stack trace err 
 */
function fixStackTrace(err:Error) {
    // update the stack
    try {
        // make a new Error object to get the current stack
        let stackStr = `${new Error().stack}`;

        // Fix Chrome/Node stack traces:
        //
        //    Error
        //        at fixStackTrace (webpack-internal:///./modules/core/src/tasks/generator.ts:13:15)
        //        at call (webpack-internal:///./modules/core/src/tasks/generator.ts:52:15)
        //        at call.next (<anonymous>)
        //        at ProjectContextImpl.openProjectContext (webpack-internal:///./modules/archipad/src/backend/project/projectContext.ts:177:108)
        //        at openProjectContext.next (<anonymous>)
        //
        stackStr = stackStr.replace(/^(Error|    at fixStackTrace \([^\)]+\)|    at call \([^\)]+\)|    at [^.]+\.next \([^\)]+\))$/gm, "");

        // Fix Firefox stack traces:
        //
        //    fixStackTrace@webpack-internal:///./modules/core/src/tasks/generator.ts:13:15
        //    call@webpack-internal:///./modules/core/src/tasks/generator.ts:52:15
        //    openProjectContext@webpack-internal:///./modules/archipad/src/backend/project/projectContext.ts:177:42
        //    getProjectContext@webpack-internal:///./modules/archipad/src/backend/project/projectContext.ts:307:12
        //    _getContext@webpack-internal:///./ui-components/ui-archipad/src/backend/project/project.ts:502:39
        //
        stackStr = stackStr.replace(/^(fixStackTrace@.*:[0-9]+:[0-9]+|call@.*:[0-9]+:[0-9]+)$/gm, "");

        // Fix Safari stack traces:
        //
        //    fixStackTrace
        //    call
        //    generatorResume
        //    openProjectContext
        //    generatorResume
        //
        stackStr = stackStr.replace(/^(fixStackTrace|call|generatorResume@\[native code\])$/gm, "");

        // remove empty lines
        stackStr = stackStr.replace(/^\s*[\r\n]/gm, "");

        err.stack = `${err.stack}\n    --------------\n${stackStr}`;
    } catch(err) {
        // don't touch in case of error
    }

    return err;
}

/*---------------------------------------------------------------------------*/
class YieldOperation<T> {
    observable: Observable<T>;
    result: T;
    error: Error;
    
    constructor(observable: Observable<T>) {
        this.observable = observable;
        this.result = null;
        this.error = null;
    }
}

type IteratorType<T> = Iterator<YieldOperation<unknown>, T, unknown>;

/**
 * A GeneratorType is an iterator that yields a list of YieldOperations then returns T
 */
export interface GeneratorType<T> extends IteratorType<T> {
    [Symbol.iterator](): GeneratorType<T>;
}

/**
 * Call transforms an observable into an iterator that yields one YieldOperation and returns its result
 * 
 * Example:
 * ```
 * const observable$ = of(1234);
 * const value = yield* call(observable$);
 * // value == 1234
 * ```
 */
export function* call<T>(callable: Observable<T> | PromiseLike<T>): GeneratorType<T> {
    let observable : Observable<T>;
    if (isObservable(callable)) {
        observable = callable;
    }
    else if (isPromiseLike(callable)) {
        observable = from(callable);
    }
    else {
        observable = throwError(new Error(`call() requires an observable or a promise like argument, got: ${callable}`));
    }

    const operation = new YieldOperation(observable);   // create the YieldOperation
    yield operation;                                    // evaluate it
    if(operation.error)
        throw fixStackTrace(operation.error);           // handle errors
    return operation.result;                            // return the result
}

/*---------------------------------------------------------------------------*/
class _TaskProcessor<T> implements Unsubscribable {
    private _iterator: IteratorType<T>;
    private _observer: Observer<T>;

    private _subscription: Subscription;


    constructor(iterator: IteratorType<T>, observer: Observer<T>) {
        this._iterator = iterator;
        this._observer = observer;
        this._subscription = null;
    
        // start iteration
        this._processNext();
    }

    private _processNext() {
        try {
            // get the next yield operation
            const next = this._iterator.next();

            if(next.done) {
                // iteration complete
                if(this._observer) 
                    this._observer.next(next.value);    // next may unsubscribe and clear this._observer in next() ...
                if(this._observer)                      // ... so we have to test again to see if this._observer is still set.
                    this._observer.complete();
                return;
            }

            // run the yield operation
            let operation = next.value as YieldOperation<unknown>;
            let subscription:Subscription = null;
            
            if(!operation || !(operation instanceof YieldOperation))
                this._iterator.throw(new Error(`Unexpected yield argument "${operation}", you should use yield* instead of yield in tasks`))

            subscription = operation.observable.subscribe({
                next: (v) => {
                    if(!operation || !this._observer)
                        return;

                    // operation  complete
                    operation.result = v;
                    operation = null;
                    
                    if(subscription)
                        this._done(subscription);
                },
                error: (err) => {
                    if(!operation || !this._observer)
                        return;

                    // operation error
                    operation.error = err;
                    operation = null;

                    if(subscription)
                        this._done(subscription);
                },
                complete: () => {
                    if(!operation || !this._observer)
                        return;

                    operation.error = new Error(`Observable did not return a value to yield !`);
                    operation = null;

                    if(subscription)
                        this._done(subscription);
                }                    
            });

            if(!operation) {
                // the observable has completed before returning from subscribe()
                this._done(subscription);
            } else {
                // the observable is still running
                this._subscription = subscription;
            }
        } catch(err) {
            this._observer.error(err);
        }
    }

    private _done(subscription:Subscription) {
        subscription.unsubscribe();
        // process next item
        this._processNext();    
    }

    unsubscribe() {
        this._iterator = null;
        this._observer = null;
        if(this._subscription)
            this._subscription.unsubscribe();
        this._subscription = null
    }
}

/**
 * Transform a generator into an Observable.
 * 
 * Example:
 * ```
 * task(() => function*() {
 *     const value = yield* call(observable$);
 *     ...
 * })
 * ```
 * 
 * Subscribes to each observable yield by the generator 
 */
export function task<T,This = never>(generator: (this:This) => GeneratorType<T>, thisArg?:This): Observable<T> {
    return new Observable<T>(function(observer) {
        const iterator = generator.call(thisArg);   // important: we need a new iterator for each subscription
        return new _TaskProcessor(iterator, observer);
    });
}


