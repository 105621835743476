import { Observable, race, defer, BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { switchMap, tap, ignoreElements, finalize, first, share } from 'rxjs/operators';

import { ProgressObserver, ProgressSubject } from '@core/tasks/progress';
import { CancelledError } from '@core/errors/errors-core';

export interface DefaultTaskContext extends Record<string, unknown> {
    blocking?: boolean;
}

/*---------------------------------------------------------------------------*/

/**
 * A task in the task queue
 */
export class QueuedTask {
    readonly key: string;
    readonly context: DefaultTaskContext;
    
    private _taskFn: (progress:ProgressObserver) => Observable<unknown>;

    private _queue: TaskQueue;
    private _isRunning: boolean;
    private _progress: ProgressSubject;
    private _runSubject: BehaviorSubject<boolean>;
    private _task: Observable<unknown>;

    constructor(key:string, taskFn:(progress:ProgressObserver) => Observable<unknown>, context?: any) {
        this.key = key;
        this.context = context;
        this._taskFn = taskFn;
        
        this._queue = null;
        this._isRunning = false;

        this._progress = null;
        this._runSubject = null;
        this._task = null;
    }

    setTaskQueue(queue:TaskQueue) {
        if(this._queue === queue){
            return; // already in queue
        }

        this._queue = queue;

        const progress = new ProgressSubject();
        const runSubject = new BehaviorSubject<boolean>(false);
        const task = runSubject.pipe(
            switchMap((started) => {
                if(!started){
                    return EMPTY;
                }

                // log.debug(`Task '${this.key}' started `, this);
                return this._taskFn(progress.observer)
            }),
            finalize(() => {
                // log.debug(`Task '${this.key}' finished `, this);
                this._queue.onTaskFinished(this);
            }),
            first(),
            share()
        );

        this._isRunning = false;

        this._progress = progress;
        this._runSubject = runSubject;
        this._task = task;
    }
    removeFromQueue() {
        this._queue = null;
        this._isRunning = false;

        this._progress = null;
        this._runSubject = null;
        this._task = null;
    }

    isRunning() { return this._isRunning; }

    /**
     * Start the task
     */
    start() {
        this._isRunning = true;
        this._runSubject.next(true);
    }
    /**
     * Cancel the task
     * 
     * All task subscribers receive a CancelledError
     */
    cancel() {
        // Task was never started.
        if(!this._runSubject) return;

        this._runSubject.error(new CancelledError());
    }

    /**
     * Attach to this task
     * 
     * @param progress a progress observer
     * @return an observable that resolves once the task is complete
     */
    bind<T>(progress:ProgressObserver): Observable<T> {
        if(!progress)   
            return this._task as Observable<T>;
        
        const progressUpdates$ = this._progress.observable.pipe(
            tap((message) => {
                switch (message.type) {
                    case "start":
                    case "showTime":
                        break;
                    default:
                        progress.next(message);
                }
            }),
        ).pipe(
            ignoreElements(),
        );

        return race(
            // observe the task progress for as long as we're subscribed
            progressUpdates$,
            // wait for the task
            this._task,
        ) as Observable<T>
    }
}

export function defaultScheduleTaskPolicy(queue:QueuedTask[]) {
    let isRunning = false;
    for(const task of queue) {
        if(task.isRunning()) {
            isRunning = true;
            break;
        }
    }
    if(queue.length === 0 || isRunning)
        return;
    
    // start the first task in the queue
    queue[0].start();
}

export function defaultAddTaskPolicy(queue:QueuedTask[], args:{ key:string, context?:unknown, taskFn:(progress:ProgressObserver) => Observable<unknown> }): QueuedTask {
    let task = queue.find((task) => (task.key === args.key));
    if(task)
        return task;    // task already exist, share it
    
    // otherwise append at end
    task = new QueuedTask(args.key, args.taskFn, args.context);
    queue.push(task);
    return task;
}

/**
 * Handle a list of tasks
 */
export class TaskQueue {
    private _tasks: QueuedTask[];

    public scheduleTaskPolicy: (queue:QueuedTask[]) => void;
    public addTaskPolicy: (queue:QueuedTask[], args:{ key:string, context?:unknown, taskFn:(progress:ProgressObserver) => Observable<unknown> }) => QueuedTask;

    private _readySub: Subject<boolean> = new Subject();

    public constructor() {
        this._tasks = [];

        this.scheduleTaskPolicy = defaultScheduleTaskPolicy;
        this.addTaskPolicy = defaultAddTaskPolicy;
    }

    public onTaskFinished(task: QueuedTask) {
        const wasRunning = task.isRunning();
        // remove the task
        task.removeFromQueue();
        const idx = this._tasks.indexOf(task);
        this._tasks.splice(idx, 1);
    
        // schedule the next task
        if(wasRunning){
            this.scheduleTaskPolicy(this._tasks);
        }

        if(this._tasks.length === 0){
            this._readySub.next(true);
        } else {
            this._readySub.next(false);
        } 
    }

    /**
     * Add a new task to the queue
     * 
     * The returned observable waits for the task to start then to complete, the progress observer is notified as the task progresses
     */
    public addTask<T,C>(progress: ProgressObserver, args:{ key:string, context?:C, taskFn:(progress:ProgressObserver) => Observable<T> }): Observable<T> {
        // using defer here in order to avoid side effects if no one subscribes to the 
        // returned observable
        return defer(() => {
            const task = this.addTaskPolicy(this._tasks, args);
            task.setTaskQueue(this);
            this.scheduleTaskPolicy(this._tasks)    // schedule the next task no task is running
            this._readySub.next(false);

            return task.bind<T>(progress);
        });
    }

    public isRunning() {
        return this._tasks.length !== 0;
    }

    public get ready$(){
        return this._readySub.asObservable();
    }
}
