/**
* SuggestionEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";



declare global {
    interface EntityTypeMap {
        "Suggestion": SuggestionEntity
    }
}

export abstract class SuggestionEntity extends BaseEntity {

	 abstract suggestionId: string;
	 abstract name: string;
	 abstract type: string;
	 abstract templateId?: string;
	
}
