import { Observable } from "rxjs";
import { switchMap, tap, ignoreElements, debounceTime, share, map } from "rxjs/operators";

import { ProjectEntity } from '@archipad-models/models/ProjectEntity';

import { ensureCogedimCodeOperationIntegrity, ensurePlanningMasterIntegrity, ensureProjectNameIntegrity } from "./project";
import { enforceFormBugsIntegrityOn, updateFormsContainsConformityWithObservation, updateFormsObsMap, updateFormObsMapFilename, addFormObsMap } from "./formObsMap";
import { updateMapTypeOrderHint, updateProjectMapsTypeOrderHint, canMoveMap } from "@archipad/services/entities/map";
import { AnnotatedEntity, AnnotationService } from "@archipad/services/annotation.service";
import { RegionEntity } from "@archipad-models/models/RegionEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";
import { MAP_TYPES } from "@archipad-models/models/extensions/MapEntity-ext";
import { MapEntity } from "@archipad-models/models/MapEntity";
import { BaseEntity } from "@core/services/orm/orm";
import { isValidDate } from "@core/helpers/dateHelper";

/**
 * Set of modelMinVersion by feature.
 */
export const enum FeatureModelMinVersion {
    FORMOBS = 620,
}

/**
 * Will ensure project integrity when the modelMinVersion changes and on each
 * orm changes that requires an update.
 * 
 * @example Will add all formObs map when the modelMinVersion changes
 * @example Will add the correspondind formObs Map when a Region is created
 * 
 * @param project - The project to ensure the integrity.
 */
export function ensureProjectIntegrity(project: ProjectEntity): Observable<void> {
    const annotationService = new AnnotationService();
    const entityContext = project.getContext();

    return entityContext.modelMinVersion$.pipe(
        switchMap((modelMinVersion) => {
            ensureProjectNameIntegrity(project);

            if (modelMinVersion >= FeatureModelMinVersion.FORMOBS) {
                for (const visit of project.visits) {
                    enforceFormBugsIntegrityOn(visit);
                }
                
                addFormObsMap(project);

                updateFormsContainsConformityWithObservation(project);
            }

            updateProjectMapsTypeOrderHint(project);

            const entityContext = project.getContext();

            /**
             * Accumulator that will hold Create/Update/Delete/ToDelete
             * emitted in a JavaScript loop.
             */
            const accumulator = {
                'Create': new Set<BaseEntity>(),
                'Update': new Map<BaseEntity, Record<string, {
                    oldValue: unknown,
                    newValue: unknown,
                }>>(),
                'ToDelete': new Set<BaseEntity>(),
                'Delete': new Set<BaseEntity>(),
                'ToRemoveAttachment': new Set<AnnotatedEntity>(),
            };

            return entityContext.notifications.pipe(
                // Accumulate all notifications and returns it
                map(notification => {
                    const type = notification[0];
                    const entity = notification[1];

                    if (type === 'entityCreated') {
                        accumulator.Create.add(entity);
                    }

                    if (type === 'willDeleteEntity') {
                        const entityName = entity.entityType.entityName;
                        if (entityName === 'Region') {
                            const region = entity as RegionEntity;
                            
                            for (const map of region.maps) {
                                if (!canMoveMap(map)) {
                                    accumulator.ToDelete.add(map);
                                }
                            }
                        }

                        // Remove the AnnotatedEntity attachment only if the AnnotationEntity is the legacy Annotation
                        if(annotationService.isAnnotationEntity(entity) && annotationService.isLegacyAnnotationEntity(entity)) {
                            accumulator.ToRemoveAttachment.add(entity.parentAnnotatedEntity);
                        }

                    }

                    if (type === 'entityUpdated') {
                        const property = notification[2];
                        
                        if (accumulator.Create.has(entity)) {
                            accumulator.Create.add(entity);
                        } else {
                            let properties = {};
                            if (accumulator.Update.has(entity)) {
                                properties = accumulator.Update.get(entity);
                                
                            }
                            properties[property] = {
                                oldValue: notification[3],
                                newValue: notification[4],
                            };
                            accumulator.Update.set(entity, properties);
                        }
                    }

                    if (type === 'entityDeleted') {
                        accumulator.Create.delete(entity);
                        accumulator.Update.delete(entity);
                        accumulator.Delete.add(entity);
                    }

                    return accumulator;
                }),

                // Discard notifications until a JavaScript loop has passed
                debounceTime(0),

                // Treat the accumulator as a transaction
                tap((transaction) => {
                    transaction.Create.forEach((entity) => {
                        const entityName = entity.entityType.entityName;
                        if (entityName === 'Map') {
                            updateMapTypeOrderHint(entity as MapEntity);
                        }

                        if (modelMinVersion >= FeatureModelMinVersion.FORMOBS) {
                            if (entityName === 'Region') {
                                updateFormsObsMap(project, entity as RegionEntity);
                            }
                        }
                    });

                    transaction.Update.forEach((properties, entity) => {
                        const entityName = entity.entityType.entityName;

                        if (entityName === 'Project') {
                            const project = entity as ProjectEntity;
                            if ('name' in properties) {
                                ensureProjectNameIntegrity(project);
                            }
                            if ('planningMaster' in properties) {
                                ensurePlanningMasterIntegrity(project);
                            }
                            if ('cogedim_code_operation' in properties) {
                                ensureCogedimCodeOperationIntegrity(project);
                            }
                        }
                        if (entityName === 'ProjectUser' ){
                            if ('right' in properties) {
                                ensurePlanningMasterIntegrity(project);
                            }
                        }
                        if (entityName === 'Visit') {
                            if ('date' in properties) {
                                const { oldValue, newValue } = properties['date']; 
                                const visit = entity as VisitEntity;
                                /**
                                 * @see [AP-9354](https://bigsool-archipad.atlassian.net/browse/AP-9354)
                                 */
                                // If the value we want to update to is not a valid Date, restore to previous value
                                if(!isValidDate(newValue as Date)) {
                                    // If previous Value is also not a valid Date, set date to new Date()
                                    if(!isValidDate(oldValue as Date)) {  
                                        visit.date = new Date();
                                    } else {
                                        visit.date = oldValue as Date;
                                    }
                                }
                            }
                        }
                        if (modelMinVersion >= FeatureModelMinVersion.FORMOBS) {
                            if (entityName === 'Visit') {
                                const visit = entity as VisitEntity;
                                if ('region' in properties && visit.region !== null) {
                                    enforceFormBugsIntegrityOn(visit);
                                }
                            }
    
                            if (entityName === 'Region') {
                                if ('name' in properties) {
                                    const region = entity as RegionEntity;
                                    if (region.maps && region.maps.length > 0) {
                                        const formObsMap = region.maps.find(map => map.type === MAP_TYPES.FORMS);
                                        updateFormObsMapFilename(formObsMap);
                                    }
                                }
                            }
                        }
                    });

                    // Remove attachment only if the entity is not already deleted
                    transaction.ToRemoveAttachment.forEach((entity) => entity?.setAttachmentForKey("annotations/0.jpg", null))

                    transaction.ToDelete.forEach((entity) => entityContext.deleteEntity(entity));

                }),

                // Clear the accumulator.
                tap(() => {
                    accumulator.Create.clear();
                    accumulator.Update.clear();
                    accumulator.Delete.clear();
                    accumulator.ToDelete.clear();
                    accumulator.ToRemoveAttachment.clear();
                })
            )
        }),

        ignoreElements(),

        share()
    );
}
