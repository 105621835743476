import env from '@env';

import { DBSchema, IDBPDatabase, openDB, StoreNames, StoreKey, StoreValue } from 'idb';

import { NetPatch, PatchId, PatchNature } from '@archipad/backend/project/patch';
import { TempPatch, CURRENT_PATCH } from '@archipad/backend/archiweb/synchroDriver';
import { UpgradeBlockedIndexedDBError, BlockingUpgradeIndexedDBError } from '@archipad/errors/errors-archipad';
import { ErrorHandlerService } from '@core/services/errorHandler';

import * as Navigation from "@ui-archipad/drivers/navigation";

const config: { dbName: string } = { ...env["@archipad/backend/indexedDB"], dbName: 'app.db' };
const DB_NAME: string = config.dbName;
const DB_VERSION: number = 4;

export interface LocalProjectInfo {
    approximateChangesCount?: number | null;
    aspects?: string[];
    created: number;
    dependencies: string[];
    hasUnfetchedChanges?: boolean;
    hasWorkflow: boolean;
    id: string;
    isOwner: boolean;
    lastModified?: number;
    lastPatchId?: string;
    name: string;
    photoUrl?: string | null;
    registered?: boolean;
    reports?: unknown | null;
}

export type LocalPatchData<T = PatchId> = T extends typeof CURRENT_PATCH ? TempPatch : NetPatch;

interface AppDBSchema_V1 extends DBSchema {
    'Projects': {
        key: string;
        value: {
            projectId: string;
            info: LocalProjectInfo;
        };
    };

    'ProjectPatches': {
        key: string;
        value: {
            patchId: string;
            data: LocalPatchData;
        };
    };
}

interface AppDBSchema_V2 extends AppDBSchema_V1 {
    'Interactions': {
        key: string;
        value: {
            interactionId: string;
            data: {
                id: string;
                interactions: unknown;
            }
        };
    };
}

interface AppDBSchema_V3 extends AppDBSchema_V2 {
    'UserReportTemplateConfigurations': {
        key: string;
        value: {
            reportTemplateId: string;
            data: /* SerializableTempReportTemplateConfiguration */ unknown;
        };
    }
}



interface AppDBSchema_V4 extends AppDBSchema_V3 {
    'ProjectPatches': {
        key: string;
        value: {
            patchId: string;
            data: LocalPatchData;
            nature: PatchNature;
        };
        indexes: {
            key: string;
        }
    };

}

// the current db schema version
type AppDBSchema = AppDBSchema_V4;

export type AppDB = IDBPDatabase<AppDBSchema>;
export type AppDBNames = StoreNames<AppDBSchema>;
export type AppDBKey<Name extends AppDBNames> = StoreKey<AppDBSchema, Name>;
export type AppDBValue<Name extends AppDBNames> = StoreValue<AppDBSchema, Name>;

let openingAppDB: Promise<AppDB> | undefined;

export async function getDB(): Promise<AppDB> {
    if (openingAppDB === undefined) {
        openingAppDB = _openDB();
    }

    return openingAppDB;
}

async function _openDB(): Promise<AppDB> {
    return openDB<AppDBSchema>(DB_NAME, DB_VERSION, {
        async upgrade<AppTDBSchema>(db, oldVersion,version,transaction) {
            if (oldVersion < 1) {
                const v1Db = (db as unknown) as IDBPDatabase<AppDBSchema_V1>;

                v1Db.createObjectStore('Projects', { keyPath: 'projectId' });
                v1Db.createObjectStore('ProjectPatches', { keyPath: 'patchId' });
            }

            if (oldVersion < 2) {
                const v2Db = (db as unknown) as IDBPDatabase<AppDBSchema_V2>;

                v2Db.createObjectStore('Interactions', { keyPath: 'interactionId' });
            }

            if (oldVersion < 3) {
                const v3Db = (db as unknown) as IDBPDatabase<AppDBSchema_V3>;

                v3Db.createObjectStore('UserReportTemplateConfigurations', { keyPath: 'reportTemplateId' });
            }
            if (oldVersion < 4) {
                const projectPatchesObjectStore = transaction.objectStore( 'ProjectPatches' );
                projectPatchesObjectStore.createIndex( 'nature', 'nature', { unique: false } );
                const patches = await projectPatchesObjectStore.getAll();
                for ( const patch of patches ) {
                    if ( patch.nature ) {
                        continue;
                    }
                    const realPatchId = [ ...patch.patchId.split( '|' ) ].pop();
                    switch ( realPatchId ) {
                        case 'current':
                            patch.nature = PatchNature.TempPatch;
                            break;
                        default:
                            patch.nature = PatchNature.PatchData;
                            break;
                    }
                    projectPatchesObjectStore.put( patch );
                }
            }

        },

        blocked() {
            const err = new UpgradeBlockedIndexedDBError();
            ErrorHandlerService.handleError(err);
        },

        blocking() {
            const err = new BlockingUpgradeIndexedDBError();
            ErrorHandlerService.handleError(err);
        },

        terminated() {
            Navigation.driver.reload(true);
        },
    });
}

/**
 * Returns a new IDBKeyRange for a given prefix to perform a "startsWith" operation
 * on IndexedDB.
 * 
 * We use a range from a lower bound with the searchString and an upper bound with
 * the searchString + the highest DOMString character to exclude all keys that
 * don't exactly start with the searchString.
 * 
 * @see https://hacks.mozilla.org/2014/06/breaking-the-borders-of-indexeddb/#:~:text=startsWith(str)
 */
export function startsWithIDBKeyRange(searchString: string): IDBKeyRange {
    const MAX_CHARACTER = '\uffff';
    return IDBKeyRange.bound(searchString, searchString + MAX_CHARACTER);
}
