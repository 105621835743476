import { IllegalArgumentError } from '@core/errors/errors-core';
import { RemarkAnnotationEntity } from '../RemarkAnnotationEntity';
import { RemarkEntity } from '../RemarkEntity';

Object.defineProperty(RemarkAnnotationEntity.prototype, 'parentAnnotatedEntity', {
    get: function(this: RemarkAnnotationEntity) : RemarkEntity {
        return this.remark;
    },
    set: function(this: RemarkAnnotationEntity, parentAnnotatedEntity: RemarkEntity): void {
        if(!parentAnnotatedEntity){
            throw new IllegalArgumentError('cannot be null nor undefined', 'parentAnnotatedEntity');
        }
        this.remark = parentAnnotatedEntity;
    },
    enumerable: true,
});