import { CoreError, TechnicalErrorIdentifier, TechnicalErrorModuleCore } from "@archipad-js/core/error";

import { APIResponseError } from "./archipad-rpc.service.types";

export abstract class APIError extends CoreError {}
export abstract class NetworkError extends CoreError {}

export enum API_ERROR_CODE {
    ERROR_INTERNAL_ERROR = 1,
    ERROR_REQUEST_INVALID = 2,
    ERROR_SERVICE_NOT_FOUND = 3,
    ERROR_METHOD_NOT_FOUND = 4,
    ERROR_CLIENT_IS_INVALID = 5,
    ERROR_PROTOCOL_IS_INVALID = 6,
    ACCESS_DENIED = 7,
}

/**
 * {@link InternalAPIError} is thrown when API returns an `http_code` above `400`.
 * Common cases are unhandled API exceptions
 */
export class InternalAPIError extends APIError {
    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "INTERNAL_ERROR");
}

/**
 * {@link MalFormedResponseAPIError} is thrown when API returns a malformed response.
 *
 * Common cases are invalid `JSON format`, or invalid `JSON content`.
 * Response is expected to pass `JSON.decode` and `rpcService.isGenericAPIResponse` type-check.
 */
export class MalFormedResponseAPIError extends APIError {
    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "MALFORMED_RESPONSE");
}

/**
 * {@link ResponseAPIError} is thrown when API returns a valid `error` response.
 *
 * Common cases are handled API errors.
 */
export class ResponseAPIError extends APIError {
    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "RETURNED_ERROR");

    constructor(
        /**
         * @todo In the future if we use more fine-grained API error handling we
         * should consider to make this property private and expose methods that
         * iterates in the response to find a code or something.
         */
        public readonly response: APIResponseError,
    ) {
        super(response.message);
        this.localizedMessage = response.localizedMessage;
    }

    public static is(o: unknown): o is ResponseAPIError {
        return o instanceof ResponseAPIError;
    }
}

/**
 * {@link ConnectionFailedNetworkError} is thrown when an error happens at the network level.
 *
 * Common cases are `timeouts` due to network latency/loss, or un-reachable server.
 */
export class ConnectionFailedNetworkError extends NetworkError {
    public override readonly identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModuleCore.NET,
        "CONNECTION_FAILED",
    );
}
