import { IllegalArgumentError, IllegalStateError } from "@archipad-js/core/error";
import { isRecord } from "@archipad-js/core/utils";

import * as OrderByParser from "./parsers/orderBy";
import * as PredicateParser from "./parsers/predicate";

//------------------------------------------------------------------------------
export type ValueType = "string" | "boolean" | "date" | "number" | "object";

type ComparisonOperators = "==" | "!=" | ">" | ">=" | "<" | "<=";
type ArithmeticOperators = "+" | "-" | "*" | "/";

type ValueTypeToType<T> = T extends "string"
    ? string
    : T extends "boolean"
    ? boolean
    : T extends "date"
    ? Date
    : T extends "number"
    ? number
    : T extends "object"
    ? unknown
    : unknown;

type TypeToValueType<T> = T extends Date
    ? "date"
    : T extends string
    ? "string"
    : T extends boolean
    ? "boolean"
    : T extends number
    ? "number"
    : T extends object
    ? "object"
    : string;

interface _ParamExpressionNode {
    op: "param" | "field";
    type: ValueType;
    name: string;
}
interface _ConstExpressionNode {
    op: "const";
    type: ValueType;
    v: unknown;
}
interface _UnaryExpressionNode {
    op: "NOT" | "NEG" | "not" | "neg";
    type: ValueType;
    a: _ExpressionNode;
}
interface _BinaryExpressionNode {
    op: ComparisonOperators | ArithmeticOperators | "IN" | "in" | "AND" | "OR";
    type: ValueType;
    a: _ExpressionNode;
    b: _ExpressionNode;
}
interface _FunctionExpressionNode {
    op: "func";
    type: ValueType;
    name: string;
    args: _ExpressionNode[];
}
interface _ArrayExpressionNode {
    op: "array";
    elems: _ExpressionNode[];
}

type _ExpressionNode =
    | _ParamExpressionNode
    | _ConstExpressionNode
    | _UnaryExpressionNode
    | _BinaryExpressionNode
    | _FunctionExpressionNode
    | _ArrayExpressionNode;

export type Comparator<T = unknown> = (a: T, b: T) => number;
export type EqualityComparator<T> = (a: T, b: T) => boolean;

export type UnaryOperator<T = unknown, R = unknown> = (o: T) => R;
export type BinaryOperator<A = unknown, B = unknown, R = unknown> = (a: A, b: B) => R;

interface BaseNode<T> {
    evaluate(o: unknown): T | null;
}

export class ConstNode<T> implements BaseNode<T> {
    constructor(public readonly type: TypeToValueType<T> | null, private readonly v: T | null) {}

    evaluate(): T | null {
        return this.v;
    }
}

export class DynNode<T> implements BaseNode<T> {
    constructor(
        public readonly type: TypeToValueType<T> | null,
        private readonly fn: UnaryOperator<unknown, T | null>,
    ) {}

    evaluate(o: unknown): T | null {
        return this.fn(o);
    }
}

export type Node<T = unknown> = ConstNode<T> | DynNode<T>;

function isNodeOfType<VT extends ValueType>(node: Node, type: VT): node is Node<ValueTypeToType<VT>> {
    return node.type === type;
}

interface FunctionDef<T> {
    type: TypeToValueType<T> | null;
    fn: (...args: unknown[]) => T | null;
}

export interface Delegate<T = unknown> {
    getProperty(name: string): DynNode<T>;
    getFunction?(name: string): FunctionDef<T>;
}

export interface BuiltinFunction<T> {
    name: string;
    build(args: Node[]): Node<T>;
}

//------------------------------------------------------------------------------
const builtinFunctions: Map<string, BuiltinFunction<unknown>> = new Map();

export function registerBuiltinFunction<T>(fn: BuiltinFunction<T>): void {
    builtinFunctions.set(fn.name, fn);
}

registerBuiltinFunction<string>({
    name: "typeof",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("Wrong number of arguments for typeof()");
        }
        const node = new ConstNode("string", args[0].type);
        return node;
    },
});

registerBuiltinFunction<string>({
    name: "toString",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("Wrong number of arguments for toString()");
        }

        const a = convertToString(args[0]);
        if (a instanceof ConstNode) {
            return new ConstNode("string", a.evaluate() ?? "");
        }

        return new DynNode("string", (o) => {
            const nodeValue = a.evaluate(o);
            return nodeValue ?? "";
        });
    },
});

registerBuiltinFunction<number>({
    name: "count",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("wrong number of arguments for count()");
        }

        const node = args[0];
        return new DynNode("number", (o) => {
            const nodeValue = node.evaluate(o);

            if (!nodeValue) {
                return 0;
            }

            if (!Array.isArray(nodeValue)) {
                throw new IllegalArgumentError("count() expects an array");
            }

            return nodeValue.length;
        });
    },
});

registerBuiltinFunction<string>({
    name: "toLowerCase",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("Wrong number of arguments for toLowerCase()");
        }

        const a = args[0];

        if (!isNodeOfType(a, "string")) {
            throw new IllegalArgumentError("Wrong type of argument passed to toLowerCase()");
        }

        return new DynNode("string", (o) => {
            const nodeValue = a.evaluate(o);
            return nodeValue?.toLowerCase() ?? null;
        });
    },
});

registerBuiltinFunction<Date>({
    name: "endOfToday",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("Wrong number of arguments for endOfToday()");
        }

        const node = args[0];
        if (!isNodeOfType(node, "date")) {
            throw new IllegalArgumentError("Wrong type of argument passed to endOfToday()");
        }

        return new DynNode("date", (o) => {
            const nodeValue = node.evaluate(o);
            if (nodeValue === null) {
                return null;
            }

            const endDate = new Date(nodeValue);
            endDate.setHours(23, 59, 59, 999);

            return endDate;
        });
    },
});

registerBuiltinFunction<Date>({
    name: "startOfToday",
    build(args) {
        if (args.length !== 1) {
            throw new IllegalArgumentError("Wrong number of arguments for startOfToday()");
        }

        const node = args[0];

        if (!isNodeOfType(node, "date")) {
            throw new IllegalArgumentError("Wrong type of argument passed to startOfToday()");
        }

        return new DynNode("date", (o) => {
            const nodeValue = node.evaluate(o);
            if (nodeValue === null) {
                return null;
            }

            const startDate = new Date(nodeValue);
            startDate.setHours(0, 0, 0, 0);

            return startDate;
        });
    },
});

//------------------------------------------------------------------------------

function equalityComparatorForType<VT extends ValueType>(
    type: VT | string | null,
): EqualityComparator<ValueTypeToType<VT> | null> {
    switch (type) {
        case "date": {
            return function dateComparator(a, b) {
                if (a === null) {
                    return b !== null ? false : true;
                }

                if (b === null) {
                    return a !== null ? false : true;
                }

                if (!(a instanceof Date) || !(b instanceof Date)) {
                    return false;
                }

                return a.getTime() === b.getTime();
            };
        }
        default:
            return function defaultComparator(a, b) {
                if (a === null) return b !== null ? false : true;
                if (b === null) return a !== null ? false : true;
                return a === b;
            };
    }
}

const baseSensitiveCollator = new Intl.Collator(undefined, {
    usage: "sort",
    sensitivity: "base",
});

const accentSensitiveCollator = new Intl.Collator(undefined, {
    usage: "sort",
    sensitivity: "accent",
});

/**
 * Compare two strings using `Intl.Collator.compare`.
 */
function stringComparator(a: string, b: string): number {
    return baseSensitiveCollator.compare(a, b);
}

/**
 * @todo remove when TypeScript will support Intl.Segmenter
 */
interface Segmenter {
    segment(str: string): Iterable<Segment>;
}
interface Segment {
    index: number;
    input: string;
    segment: string;
}

let GraphemeSegmenter: Segmenter | null;
/**
 * @todo remove me when `Intl.Segmenter` will be supported on Firefox and when
 * we will support Safari >14.1 only.
 *
 * @see [`Intl.Segmenter` Browser Compatibility](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Segmenter#browser_compatibility)
 */
if ("Segmenter" in Intl) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GraphemeSegmenter = new (Intl as any).Segmenter(undefined, {
        granularity: "grapheme",
    });
} else {
    GraphemeSegmenter = null;
}

/**
 * Returns a list of graphemes segments that is backward compatible with older
 * browsers.
 *
 * DO NOT USE THIS FUNCTION FOR NON-SORTING USAGE
 */
function getGraphemes(input: string): Iterable<Segment> {
    if (GraphemeSegmenter === null) {
        const fallback = input
            .replace(/\p{Diacritic}/gu, "")
            .split("")
            .map((segment, index) => ({
                index,
                input,
                segment,
            }));
        return fallback;
    }
    const segmenter: Segmenter = GraphemeSegmenter;
    const graphemes = segmenter.segment(input);
    return graphemes;
}

function isDigitGrapheme(grapheme: string): boolean {
    return /[0-9]/.test(grapheme);
}

/**
 * Compare two strings with a natural sort algorithm.
 *
 * Adapted from the package `nwoltman/string-natural-compare` but use the
 * `Intl.Collator` to compare strings instead of charcodes so we can safely
 * ignore accents.
 *
 * @see [`nwoltman/string-natural-compare`](https://github.com/nwoltman/string-natural-compare)
 */
function stringNaturalComparator(a: string, b: string): number {
    /**
     * We use graphemes instead of chars because some writing units are encoded
     * with multiple unicode chars and depending on the unicode encoding
     * (NFC, NFD, NFKC, NFKD) we can have different results.
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
     */
    const graphemesA = getGraphemes(a)[Symbol.iterator]();
    const graphemesB = getGraphemes(b)[Symbol.iterator]();

    let firstDifferenceInGrapheme = 0;

    let nextA = graphemesA.next();
    let nextB = graphemesB.next();
    while (!nextA.done && !nextB.done) {
        const charA = nextA.value.segment;
        const charB = nextB.value.segment;

        if (isDigitGrapheme(charA)) {
            if (!isDigitGrapheme(charB)) {
                // if `a` is a number but `b` is not, then use classic string comparison
                return baseSensitiveCollator.compare(charA, charB);
            }

            let numASize = 0;
            let numA = 0;
            let digitA = charA;
            for (;;) {
                numASize++;
                numA *= 10;
                numA += Number.parseInt(digitA, 10);

                nextA = graphemesA.next();
                if (nextA.done) {
                    break;
                }

                if (!isDigitGrapheme(nextA.value.segment)) {
                    break;
                }

                digitA = nextA.value.segment;
            }

            let numBSize = 0;
            let numB = 0;
            let digitB = charB;
            for (;;) {
                numBSize++;
                numB *= 10;
                numB += Number.parseInt(digitB, 10);

                nextB = graphemesB.next();
                if (nextB.done) {
                    break;
                }

                if (!isDigitGrapheme(nextB.value.segment)) {
                    break;
                }

                digitB = nextB.value.segment;
            }

            const difference = numA - numB;
            if (difference !== 0) {
                return difference;
            }

            if (firstDifferenceInGrapheme === 0) {
                /**
                 * By default the Intl.Collator will sort them as `001` < `01` < `1`.
                 * We want to sort them as `1` < `01` < `001` just like macOS's Finder.
                 */
                firstDifferenceInGrapheme = numASize - numBSize; // compare number sizes
            }

            continue;
        }

        const comparison = baseSensitiveCollator.compare(charA, charB);
        if (comparison !== 0) {
            return comparison;
        } else if (firstDifferenceInGrapheme === 0) {
            firstDifferenceInGrapheme = accentSensitiveCollator.compare(charA, charB);
        }

        nextA = graphemesA.next();
        nextB = graphemesB.next();
    }

    if (nextA.done && nextB.done) {
        return firstDifferenceInGrapheme;
    } else if (nextA.done) {
        // `a` is a substring of `b`
        return -1;
    } else {
        // `b` is a substring of `a`
        return 1;
    }
}

export function comparatorForType<T>(
    type: TypeToValueType<T> | null,
    style?: string,
): Comparator<T | undefined | null> {
    switch (type) {
        case "string": {
            return function comparatorForString(a, b) {
                if (typeof a !== "string" || typeof b !== "string") {
                    if (typeof a !== "string" && typeof b === "string") {
                        return -1;
                    }

                    if (typeof a === "string" && typeof b !== "string") {
                        return 1;
                    }

                    return 0;
                }
                if (style === "natural") {
                    return stringNaturalComparator(a, b);
                }
                return stringComparator(a, b);
            };
        }
        case "number": {
            return function numberComparator(a, b) {
                if (typeof a !== "number" || typeof b !== "number") {
                    if (typeof a !== "number" && typeof b === "number") {
                        return -1;
                    }

                    if (typeof a === "number" && typeof b !== "number") {
                        return 1;
                    }

                    return 0;
                }
                return a - b;
            };
        }
        case "boolean": {
            return function booleanComparator(a, b) {
                if (typeof a !== "boolean" || typeof b !== "boolean") {
                    if (typeof a !== "boolean" && typeof b === "boolean") {
                        return -1;
                    }

                    if (typeof a === "boolean" && typeof b !== "boolean") {
                        return 1;
                    }

                    return 0;
                }
                if (a === b) {
                    return 0;
                }
                return a === true ? 1 : -1;
            };
        }
        case "date": {
            return function dateComparator(a, b) {
                if (!(a instanceof Date) || !(b instanceof Date)) {
                    if (!(a instanceof Date) && b instanceof Date) {
                        return -1;
                    }

                    if (a instanceof Date && !(b instanceof Date)) {
                        return 1;
                    }

                    return 0;
                }

                if (Number.isNaN(a.getTime()) || Number.isNaN(b.getTime())) {
                    if (Number.isNaN(a.getTime()) && Number.isNaN(b.getTime())) {
                        return 0;
                    }

                    if (Number.isNaN(a.getTime())) {
                        return -1;
                    }

                    return 1;
                }

                return a.getTime() - b.getTime();
            };
        }
    }

    throw new IllegalArgumentError("Cannot compare type: " + type);
}

// handle a.b.c expressions
export function makePathExpression<T = Record<string, unknown>>(str: string): UnaryOperator<T, unknown> {
    const paths = str.split(".");

    return function (o) {
        let result: unknown = o;
        for (const propertyName of paths) {
            if (!isRecord(result)) {
                return null;
            }

            const v = result[propertyName];
            if (v === undefined) {
                return null;
            }

            if (v === null) {
                return null;
            }

            result = v;
        }

        return result ?? null;
    };
}

export function convertToString(a: Node): Node<string> {
    if (isNodeOfType(a, "string")) {
        return a;
    }

    if (isNodeOfType(a, "boolean")) {
        if (a instanceof ConstNode) {
            const nodeValue = a.evaluate();
            const value = nodeValue === null ? null : nodeValue ? "true" : "false";
            return new ConstNode("string", value);
        }

        return new DynNode("string", function (o) {
            const nodeValue = a.evaluate(o);
            const value = nodeValue === null ? null : nodeValue ? "true" : "false";
            return value;
        });
    }

    if (isNodeOfType(a, "date")) {
        if (a instanceof ConstNode) {
            const nodeValue = a.evaluate();
            const value = nodeValue?.toLocaleDateString() ?? null;
            return new ConstNode("string", value);
        }

        return new DynNode("string", function (o) {
            const nodeValue = a.evaluate(o);
            const value = nodeValue?.toLocaleDateString() ?? null;
            return value;
        });
    }

    if (a instanceof ConstNode) {
        const nodeValue = a.evaluate();
        return new ConstNode("string", nodeValue ? String(nodeValue) : null);
    }

    return new DynNode("string", function (o) {
        const nodeValue = a.evaluate(o);
        return nodeValue ? String(nodeValue) : null;
    });
}

function buildBinaryOperator<VT extends ValueType>(type: VT, fn: BinaryOperator, a: Node, b: Node): Node {
    if (a instanceof ConstNode && b instanceof ConstNode) {
        // both arguments are constant, evaluate now
        return new ConstNode(type, fn(a.evaluate(), b.evaluate()));
    }

    return new DynNode(type, function (o) {
        return fn(a.evaluate(o), b.evaluate(o));
    });
}

function buildComparatorOperator(
    op: ComparisonOperators,
    comparator: BinaryOperator<
        ValueTypeToType<ValueType> | null,
        ValueTypeToType<ValueType> | null,
        number | boolean | null
    >,
    a: Node,
    b: Node,
): Node {
    if (a instanceof ConstNode && b instanceof ConstNode) {
        const compareResult = comparator(a.evaluate(), b.evaluate());
        // both arguments are constant, evaluate now
        switch (op) {
            case "==": {
                return new ConstNode("boolean", Boolean(compareResult));
            }
            case "!=": {
                return new ConstNode("boolean", !compareResult);
            }
            case "<": {
                return new ConstNode("boolean", Number(compareResult) < 0);
            }
            case ">": {
                return new ConstNode("boolean", Number(compareResult) > 0);
            }
            case "<=": {
                return new ConstNode("boolean", Number(compareResult) <= 0);
            }
            case ">=": {
                return new ConstNode("boolean", Number(compareResult) >= 0);
            }
        }
    }

    // both a and/or b dynamic
    switch (op) {
        case "==":
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return Boolean(compareResult);
            });
        case "!=": {
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return !compareResult;
            });
        }
        case "<": {
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return Number(compareResult) < 0;
            });
        }
        case ">": {
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return Number(compareResult) > 0;
            });
        }
        case "<=": {
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return Number(compareResult) <= 0;
            });
        }
        case ">=": {
            return new DynNode("boolean", function (o) {
                const compareResult = comparator(a.evaluate(o), b.evaluate(o));
                return Number(compareResult) >= 0;
            });
        }
    }
}

function buildNotOperator(a: Node): Node {
    if (a instanceof ConstNode) {
        return new ConstNode("boolean", !a.evaluate());
    }

    return new DynNode("boolean", function (o) {
        return !a.evaluate(o);
    });
}

function buildNegOperator(a: Node<number>): Node<number> {
    if (a instanceof ConstNode) {
        return new ConstNode("number", -(a.evaluate() ?? 0));
    }

    return new DynNode("number", function (o) {
        return -(a.evaluate(o) ?? 0);
    });
}

function buildAndOperator(a: Node, b: Node): Node {
    if (a instanceof ConstNode && b instanceof ConstNode) {
        return new ConstNode("boolean", a.evaluate() && b.evaluate());
    }

    if (a instanceof ConstNode) {
        if (!a.evaluate()) {
            return new ConstNode("boolean", false);
        }

        return b;
    }

    if (b instanceof ConstNode) {
        if (!b.evaluate()) {
            return new ConstNode("boolean", false);
        }

        return a;
    }

    return new DynNode("boolean", function (o) {
        return Boolean(a.evaluate(o) && b.evaluate(o));
    });
}

function buildOrOperator(a: Node, b: Node): Node {
    if (a instanceof ConstNode && b instanceof ConstNode) {
        return new ConstNode("boolean", a.evaluate() || b.evaluate());
    }

    if (a instanceof ConstNode) {
        if (a.evaluate()) {
            return new ConstNode("boolean", true);
        }

        return b;
    }

    if (b instanceof ConstNode) {
        if (b.evaluate()) {
            return new ConstNode("boolean", true);
        }

        return a;
    }

    return new DynNode("boolean", function (o) {
        return Boolean(a.evaluate(o) || b.evaluate(o));
    });
}

function buildExpressionOperator(op: ArithmeticOperators, a: Node, b: Node): Node<string> | Node<number> {
    if (op === "+") {
        if (isNodeOfType(a, "string") || isNodeOfType(b, "string")) {
            const aString = convertToString(a);
            const bString = convertToString(b);

            // string concatenation
            if (aString instanceof ConstNode && bString instanceof ConstNode) {
                // both arguments are constant, evaluate now
                const result = (aString.evaluate() ?? "") + (bString.evaluate() ?? "");
                return new ConstNode("string", result);
            }

            return new DynNode("string", function (o) {
                const result = (aString.evaluate(o) ?? "") + (bString.evaluate(o) ?? "");
                return result;
            });
        }
    }

    if (!isNodeOfType(a, "number") || !isNodeOfType(b, "number")) {
        throw new IllegalArgumentError(op + " requires number arguments");
    }

    if (a instanceof ConstNode && b instanceof ConstNode) {
        // both arguments are constant, evaluate now
        switch (op) {
            case "+": {
                return new ConstNode("number", (a.evaluate() ?? 0) + (b.evaluate() ?? 0));
            }
            case "-": {
                return new ConstNode("number", (a.evaluate() ?? 0) - (b.evaluate() ?? 0));
            }
            case "*": {
                return new ConstNode("number", (a.evaluate() ?? 0) * (b.evaluate() ?? 0));
            }
            case "/": {
                return new ConstNode("number", (a.evaluate() ?? 0) / (b.evaluate() ?? 0));
            }
        }
    }

    // both a and/or b dynamic
    switch (op) {
        case "+": {
            return new DynNode("number", function (o) {
                return (a.evaluate(o) ?? 0) + (b.evaluate(o) ?? 0);
            });
        }
        case "-": {
            return new DynNode("number", function (o) {
                return (a.evaluate(o) ?? 0) - (b.evaluate(o) ?? 0);
            });
        }
        case "*": {
            return new DynNode("number", function (o) {
                return (a.evaluate(o) ?? 0) * (b.evaluate(o) ?? 0);
            });
        }
        case "/": {
            return new DynNode("number", function (o) {
                return (a.evaluate(o) ?? 0) / (b.evaluate(o) ?? 0);
            });
        }
    }
}

function buildArray(elems: Node[]): Node {
    if (elems.every((e): e is ConstNode<unknown> => e instanceof ConstNode)) {
        const values = elems.map((e) => e.evaluate());
        return new ConstNode("object", values);
    }

    return new DynNode("object", function (o) {
        return elems.map((e) => e.evaluate(o));
    });
}

function requireSameTypes(op: ComparisonOperators, typeA: string | null, typeB: string | null): string | null {
    if (typeA && typeB && typeA !== typeB) {
        throw new IllegalArgumentError(`${op} requires arguments of the same type`);
    }
    const type = typeA ? typeA : typeB;
    return type;
}

function requireBoolean(op: _BinaryExpressionNode["op"], typeA: string | null, typeB: string | null): "boolean" {
    if ((typeA && typeA !== "boolean") || (typeB && typeB !== "boolean")) {
        throw new IllegalArgumentError(`${op} requires boolean arguments`);
    }

    return "boolean";
}

function functionCall(fn: FunctionDef<unknown>, args: Node[]): DynNode<unknown> {
    return new DynNode("object", (o) => {
        const values = args.map((arg) => {
            return arg.evaluate(o);
        });
        return fn.fn(...values);
    });
}

function buildPredicateExpression(
    expr: _ExpressionNode,
    context: Record<string, unknown>,
    delegate: Delegate<unknown>,
): Node {
    switch (expr.op) {
        case "const": {
            return new ConstNode(expr.type, expr.v);
        }

        case "param": {
            const fn = makePathExpression(expr.name);
            const value = fn(context);

            if (value === undefined) {
                throw new IllegalArgumentError(`Missing predicate parameter: ${JSON.stringify(expr)}`);
            }

            if (typeof value === "boolean") {
                return new ConstNode("boolean", value);
            }

            if (value === null) {
                return new ConstNode(null, null);
            }

            if (value instanceof Date) {
                return new ConstNode("date", value);
            }

            if (typeof value === "string" || value instanceof String) {
                return new ConstNode("string", value.toString());
            }

            if (typeof value === "number") {
                return new ConstNode("number", value);
            }

            return new ConstNode("object", value);
        }

        case "field": {
            const property = delegate.getProperty(expr.name);
            if (!property) {
                throw new IllegalStateError(`No such field '${expr.name}'`);
            }
            return new DynNode(property.type, (o) => property.evaluate(o));
        }

        case "func": {
            const args: Node[] = [];
            for (const arg of expr.args) args.push(buildPredicateExpression(arg, context, delegate));

            const builtinFunction = builtinFunctions.get(expr.name);
            if (builtinFunction) {
                return builtinFunction.build(args);
            } else {
                const fn = !delegate.getFunction ? null : delegate.getFunction(expr.name);
                if (!fn) {
                    throw new IllegalStateError('No such function "' + expr.name + '"');
                }

                return functionCall(fn, args);
            }
        }

        case "array": {
            const elems: Node[] = [];
            for (const elem of expr.elems) elems.push(buildPredicateExpression(elem, context, delegate));
            return buildArray(elems);
        }

        case "==": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = equalityComparatorForType(requireSameTypes("==", a.type, b.type));
            return buildComparatorOperator("==", comparator, a, b);
        }
        case "!=": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = equalityComparatorForType(requireSameTypes("!=", a.type, b.type));
            return buildComparatorOperator("!=", comparator, a, b);
        }
        case "<": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = comparatorForType(requireSameTypes("<", a.type, b.type));
            return buildComparatorOperator("<", comparator, a, b);
        }
        case ">": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = comparatorForType(requireSameTypes(">", a.type, b.type));
            return buildComparatorOperator(">", comparator, a, b);
        }
        case "<=": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = comparatorForType(requireSameTypes("<=", a.type, b.type));
            return buildComparatorOperator("<=", comparator, a, b);
        }
        case ">=": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            const comparator = comparatorForType(requireSameTypes(">=", a.type, b.type));
            return buildComparatorOperator(">=", comparator, a, b);
        }
        case "NOT":
        case "not": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            //if(a.type && a.type != 'boolean')
            //    throw new Error('! requires a boolean argument');
            return buildNotOperator(a);
        }
        case "IN":
        case "in": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            if (b.type && b.type !== "object") throw new IllegalArgumentError("in requires an array argument");
            return buildBinaryOperator(
                "boolean",
                function (a, b) {
                    if (!b) return false;
                    if (!Array.isArray(b)) throw new IllegalArgumentError("in requires an array argument");
                    return b.indexOf(a) !== -1;
                },
                a,
                b,
            );
        }
        case "AND": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            requireBoolean("AND", a.type, b.type);
            return buildAndOperator(a, b);
        }
        case "OR": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            requireBoolean("OR", a.type, b.type);
            return buildOrOperator(a, b);
        }
        case "NEG":
        case "neg": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            if (!isNodeOfType(a, "number")) {
                throw new IllegalArgumentError("- requires a number argument");
            }
            return buildNegOperator(a);
        }
        case "+":
        case "-":
        case "*":
        case "/": {
            const a = buildPredicateExpression(expr.a, context, delegate);
            const b = buildPredicateExpression(expr.b, context, delegate);
            return buildExpressionOperator(expr.op, a, b);
        }

        default:
            throw new IllegalArgumentError("No such operator: " + JSON.stringify(expr));
    }
}

//------------------------------------------------------------------------------
export class Expression {
    /** root expression node */
    private exprNode: _ExpressionNode;
    /** expression parameter contexts (for expressions like 'id = {projectId}' ) */
    private context: Record<string, unknown>;

    public static parse(expressionFormat: string): _ExpressionNode {
        return PredicateParser.parse(expressionFormat);
    }

    private static collectRequiredFields(expr: _ExpressionNode, fields: Set<string>): void {
        switch (expr.op) {
            case "field":
                {
                    fields.add(expr.name);
                }
                break;

            case "func":
                {
                    for (const arg of expr.args) {
                        Expression.collectRequiredFields(arg, fields);
                    }
                }
                break;

            case "NOT":
            case "NEG":
            case "not":
            case "neg":
                {
                    Expression.collectRequiredFields(expr.a, fields);
                }
                break;

            case "==":
            case "!=":
            case "<":
            case ">":
            case "<=":
            case ">=":
            case "IN":
            case "in":
            case "AND":
            case "OR":
            case "+":
            case "-":
            case "*":
            case "/": {
                Expression.collectRequiredFields(expr.a, fields);
                Expression.collectRequiredFields(expr.b, fields);
            }
        }
    }

    constructor();
    constructor(expressionFormat: string);
    constructor(expressionFormat: string, context: Record<string, unknown>);
    constructor(expressionNode: _ExpressionNode, context: Record<string, unknown>);
    constructor(...args: [] | [string] | [string | _ExpressionNode, Record<string, unknown>]) {
        if (args.length === 1) {
            const [str] = args;
            this.exprNode = Expression.parse(str);
            this.context = {};
            return;
        }

        if (args.length === 2) {
            const [expressionFormatOrNode, context] = args;
            const expressionNode =
                typeof expressionFormatOrNode === "string"
                    ? Expression.parse(expressionFormatOrNode)
                    : expressionFormatOrNode;
            this.exprNode = expressionNode;
            this.context = { ...context };
            return;
        }

        this.exprNode = { op: "const", type: "boolean", v: true };
        this.context = {};
    }

    and(expression: Expression): Expression {
        return new Expression(
            { op: "AND", type: "boolean", a: this.exprNode, b: expression.exprNode },
            { ...this.context, ...expression.context },
        );
    }

    or(expression: Expression): Expression {
        return new Expression(
            { op: "OR", type: "boolean", a: this.exprNode, b: expression.exprNode },
            { ...this.context, ...expression.context },
        );
    }

    getRequiredFields(): Set<string> {
        const fields = new Set<string>();
        Expression.collectRequiredFields(this.exprNode, fields);
        return fields;
    }

    /**
     * Return the field name if the expression is a field.
     * Used for two way binding on template expressions. I.e {{foo.bar}}
     *
     * @return field name
     */
    getFieldName(): string | null {
        const expr = this.exprNode;
        if (expr.op !== "field") return null;
        return expr.name;
    }

    build(context?: Record<string, unknown>, _delegate?: Delegate<unknown>): UnaryOperator {
        const delegate = _delegate || {
            getProperty: function (path) {
                return new DynNode(null, makePathExpression(path));
            },
        };

        const ret = buildPredicateExpression(this.exprNode, { ...this.context, ...context }, delegate);
        if (ret instanceof ConstNode) {
            // constant expression
            const retv = ret.evaluate();
            return () => retv;
        }

        return (o) => ret.evaluate(o);
    }
}

//------------------------------------------------------------------------------
export class OrderByPredicate {
    private _str: string;
    private _clauses: Array<{ asc: boolean; path: string; style: string | null }>;

    constructor(orderBy: string) {
        this._str = orderBy;
        this._clauses = [...OrderByParser.parse(orderBy)];
    }

    private static _makeComparator(comparator: Comparator, pathFn: UnaryOperator, asc: boolean): Comparator {
        if (asc) {
            return function ascComparator(a, b) {
                return comparator(pathFn(a), pathFn(b));
            };
        } else {
            return function descComparator(a, b) {
                return -comparator(pathFn(a), pathFn(b));
            };
        }
    }

    add(orderBy: string) {
        this._clauses.push(...OrderByParser.parse(orderBy));
        if (this._str) this._str += "AND " + orderBy;
        else this._str = orderBy;
    }

    getRequiredFields(): Set<string> {
        const fields = new Set<string>();
        for (const clause of this._clauses) fields.add(clause.path);
        return fields;
    }

    build(delegate: Delegate<unknown>): Comparator {
        const comparators: Comparator[] = [];
        const str = this._str;

        const clauses = this._clauses;
        for (const clause of clauses) {
            const property = delegate.getProperty(clause.path);
            const comparator = comparatorForType(property.type, clause.style ?? undefined);
            comparators.push(OrderByPredicate._makeComparator(comparator, (o) => property.evaluate(o), clause.asc));
        }

        return function (a, b) {
            try {
                let ret;
                for (let i = 0; i < comparators.length; i++) {
                    ret = comparators[i](a, b);
                    if (ret) return ret;
                }
                return 0;
            } catch (err) {
                const error = new IllegalStateError("Error in predicate '" + str + "' comparing " + a + " and " + b);
                if (err instanceof Error) {
                    error.underlyingError = err;
                }
                throw error;
            }
        };
    }
}
