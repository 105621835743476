/**
 * LongDateFormat : a date formatter
 */
export class LongDateFormat {
    /**
     * Return a date iso format (ISO 8601).
     *
     * @example 1992-10-30
     */
    static date_iso(): string {
        return "yyyy-MM-dd";
    }

    /**
     * Return a datetime iso format (ISO 8601)
     *
     * @example 1992-10-30T05:30:58+01:00
     */
    static datetime_iso(): string {
        return "yyyy-MM-dd'T'HH:mm:ssxxx";
    }

    /**
     * Return a short date format.
     *
     * @example [EN] 10/30/1992
     * @example [FR] 30/10/1992
     */
    static date_short(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "P";
        }
    }

    /**
     * Return a shortened month date format.
     *
     * @example [EN] Oct. 30, 1992
     * @example [FR] 30 oct. 1992
     */
    static date_medium(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "PP";
        }
    }

    /**
     * Return a long date format.
     *
     * @example [EN] October 30, 1992
     * @example [FR] 30 octobre 1992
     */
    static date_long(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "PPP";
        }
    }

    /**
     * Return a long date format with full day name.
     *
     * @example [EN] Wed 08/04/2021
     * @example [FR] mer. 04/08/2021
     */
    static date_short_day(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "iii P";
        }
    }

    /**
     * Return a long date format with full day name.
     *
     * @example [EN] Friday, October 30, 1992
     * @example [FR] vendredi 30 octobre 1992
     */
    static long_day(locale: Intl.Locale): string {
        switch (locale.language) {
            case "en":
                return "iiii, PP";
            default:
                return "iiii PP";
        }
    }

    /**
     * Return a short time format.
     *
     * @example [EN] 6:10 AM
     * @example [FR] 06:10
     */
    static time_short(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "p";
        }
    }

    /**
     * Return a short datetime format.
     *
     * @example [EN] 10/30/1992 6:10 AM
     * @example [FR] 30/10/1992 06:10
     */
    static datetime_short(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "P p";
        }
    }

    /**
     * Return a long datetime format.
     *
     * @example [EN] October 30, 1992 6:10 AM
     * @example [FR] 30 octobre 1992 06:10
     */
    static datetime_long(locale: Intl.Locale): string {
        switch (locale.language) {
            default:
                return "PP p";
        }
    }

    /**
     * Return a long datetime format.
     *
     * @example [EN] 10/30/1992 or 10/30/1992 6:10 AM
     * @example [FR] 30/10/1992 or 30/10/1992 06:10
     */
    static datetime_hide_midnight(locale: Intl.Locale, d: Date): string {
        const isMidnight = !d.getHours() && !d.getMinutes();
        switch (locale.language) {
            default:
                if (isMidnight) {
                    return "P";
                }
                return "P p";
        }
    }
}
