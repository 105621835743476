import Env from "@env";

import _LegacyServiceManager from '@core/services/legacy-service-manager';

import {
    Config_Service_DIID,
    ConfigService,
    ConfigBundle,
    Config_Bundle_DIID,
} from '@archipad-js/core/config';

/**
 * Retrieve a ConfigService singleton.
 * 
 * Must be used carefully and not called anywhere, you must call it when the
 * app has already booted and not directly on module top level because not all
 * bundle might have been registered yet. See {@link registerConfigBundle()}.
 */
export function getConfigService(): ConfigService {
    return _LegacyServiceManager.container.get(Config_Service_DIID.symbol);
}

export function findConfigBundleByName(name: ConfigBundle['name']): ConfigBundle | undefined {
    return _LegacyServiceManager.container.getAll(Config_Bundle_DIID.symbol).find((bundle) => bundle.name === name);
}

export function env(): { clientName: string, appVersion: string, locale?: string, [key: string]: unknown, hasReportGenerationService?: boolean } {
    return Object.assign({}, Env["@core/services/config"]);
}
