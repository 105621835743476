import { Observable, defer, BehaviorSubject, EMPTY, merge, race, timer } from 'rxjs';
import { map, share, switchMap, concatMap, debounceTime, filter, tap } from 'rxjs/operators';

import { GeneratorType, task, call } from '@core/tasks/generator';
import { makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { createLogger } from '@core/services/logger.service';
import { env as ConfigEnv } from '@core/services/config';
import { EntityContext } from '@core/services/orm/orm';
import { SchemaManager } from '@core/services/orm/schema-manager.interface';

import * as Patch from '@archipad/backend/project/patch';
import _PatchHandlerService from '@archipad/backend/project/patch';
import { ensureProjectIntegrity } from '@archipad/services/entities/integrityCheck';
import { getArchipadSchemaManager } from '@archipad/services/archipad-schema-manager';

import { ProjectEntity } from '@archipad-models/models/ProjectEntity';
import { ProjectContextError } from '@archipad/errors/errors-archipad';
import { ConfigError } from '@core/errors/errors-core';
import { callAsGenerator, voidProgress, callAsAsync } from '@core/tasks/asyncCaller';
import { ProjectContext } from '@archipad/backend/project/projectContext';


const _currentProject$ = new BehaviorSubject<ProjectEntity | null>(null);

/**
 * The current opened project.
 * 
 * Must be used to watch the current opened project without opening it.
 * 
 * A good usage example of this observable is to display the project name in the
 * Archipad Cloud's header. In this case, we just want to watch the current 
 * opened project, if there is not, we don't want to open a context.
 * 
 * NEVER use it in a controller!
 * 
 * It is forbidden because controllers MUST open a context if there is not. 
 * This observable will just emit `null` if there is no context opened.
 */
export const currentProject$ = _currentProject$.asObservable();

/**
 * Retrieve the current opened project or null otherwise.
 */
export function getCurrentProject(): ProjectEntity | null {
    return _currentProject$.getValue();
}

// NOTE: This the delay between changes on the orm and the call to contextChangesMonitor(). 
//
// The problem is that hasUnflushedChanges is set to true once contextChangesMonitor() completes, 
// so there is a critical section between a change on the orm and the time hasUnflushedChanges is set.
//
// If this delay is set to high, the user has the time to reload the page after changing the orm and will loosing data. If it is set too low
// dragging form fields is going to be slow (continuously build a temp patch and write to indexed db).
//
// TODO: set hasUnflushedChanges to true as soon as an orm notification is received.
const FLUSH_CHANGES_LOCALLY_TIMER = 200;

const CONST_MAX_DELAY_FOR_CLOSING_CONTEXT = 4000;

const log = createLogger('projectContext');

const env = ConfigEnv();
if ( ! env.clientName)
    throw new ConfigError("No clientName defined");



export interface ProjectContextDataManager {
    getId() : string;
    getPatches(): Observable<string[]>;
    makePatchHeader(entityContext:EntityContext, clientName:string, temp:boolean) : Patch.MutablePatchHeader;
    getPatchData(id:string) : Observable<{patch: Patch.NetPatch, nature: Patch.PatchNature}>;
    contextModified(projectName:string, patch:Patch.NetPatch, onUserInput:boolean) : Observable<void>;
    getAttachmentSerializer():Patch.AttachmentSerializer;
    upgradeProjectUsingContext(progress: ProgressObserver, context: ProjectContext): Observable<unknown>;
    applyPatchesDidCommit(referenceContext:EntityContext): GeneratorType<void>;
}


 export interface ProjectContextLifecycleManager {
    /**
     * An observable that must be kept subscribed for as long as clients are using this context
     */
    readonly processes$: Observable<unknown>;
    
    /**
     * Flag the project context as "ready" so the `ProjectContext` is set on the
     * {@link currentProject$} {@link Observable}.
     */
     notifyContextReady(): void;

     /**
      * Force a synchronous temp patch update 
      */
     flushORMChanges(): Observable<unknown>;
 
     /**
      * Reload the context from indexedb
      */
     reload(): Observable<unknown>;

     /**
      * Open the context
      */
     openProjectContext(progress: ProgressObserver): GeneratorType<void>;

     /**
      * Close the context
      * 
      * Note: will not complete until the context is flushed to indexeddb and the last subscripton on processes$ has been removed
      */
     close(): Observable<unknown>;
  
}


//------------------------------------------------------------------------------
// A base class to implement a project context
//------------------------------------------------------------------------------

export abstract class BaseProjectContext implements ProjectContext, ProjectContextLifecycleManager {

    //------------------------------------------------------------------------------
    // this abstract class api; to be implemented by child classes

    protected abstract get _dataManager(): ProjectContextDataManager;


    //------------------------------------------------------------------------------
    // interfaces not implemented by this class; to be implemented by child classes

    public abstract addBlockingTask<T = unknown>(progress: ProgressObserver, key: string, taskFn: (progress: ProgressObserver) => Observable<T>): Observable<T>;


    //------------------------------------------------------------------------------
    // class starts here

    public readonly processes$: Observable<unknown>;

    protected _entityContext: EntityContext | null;
 
    private _enableOrmMonitoring$: BehaviorSubject<boolean>;


    protected constructor(
        private readonly schemaManager: SchemaManager = getArchipadSchemaManager(),
    ) {
        this._entityContext = null; 
        this._enableOrmMonitoring$ = new BehaviorSubject<boolean>(true);
        this.processes$ = this._makeProcesses(this._enableOrmMonitoring$);   
    }

    private _clean() {
        if(this._entityContext)
            this._entityContext.dispose();
        this._entityContext = null;
        this._enableOrmMonitoring$ = null;
    }

    private _makeProcesses(enableOrmMonitoring$ : Observable<boolean>): Observable<unknown> {
        return defer(() => {
            // process changes on orm entity context
            const processsUserChanges$ = enableOrmMonitoring$.pipe(
                switchMap((enabled) => {
                    if(!enabled)
                        return EMPTY;   // stop watching entity context

                    return this._entityContext.notifications.pipe(
                        debounceTime(FLUSH_CHANGES_LOCALLY_TIMER),
                        concatMap(() => {
                            if(!this._entityContext.isDirty())
                                return EMPTY;   // no changes to process

                            return task(() => this._flushORMChanges())
                        })
                    );
                })
            );

            const projectEntity = this.getProjectEntity();

            // ensure project integrity
            const ensureProjectIntegrity$ = ensureProjectIntegrity(projectEntity);

            // sync project attachments with report entities
            const syncProjectAttachments$ = this._entityContext.notifications.pipe(
                map((notification) => {
                    if(notification[0] !== 'willDeleteEntity')
                        return;
                        
                    const entity = notification[1];
                    if ( entity.entityType.entityName == 'Report' ) {
                        // if a report is deleted, delete all related attachments in project
                        for ( const key in entity.getAttachments() ) {
                            projectEntity.setAttachmentForKey(key, null);
                        }
                    }
                })
            );

            return merge(
                ensureProjectIntegrity$, 
                processsUserChanges$, 
                syncProjectAttachments$
            );
        }).pipe(
            // only one execution allowed at a time
            share()
        );        
    }

    public getAttachmentSerializer():Patch.AttachmentSerializer {
        return this._dataManager.getAttachmentSerializer();
    }

    private *_flushORMChanges(): GeneratorType<void> {
        const changes = yield* this._makeTempPatch();

        const project = this.getProjectEntity();
        const name = project.name;
        const patch : Patch.NetPatch = Patch.exportPatch(this._entityContext, this.getAttachmentSerializer(), changes);
        yield* call(this._dataManager.contextModified(name, patch, true));
    }

    private _getPatch(patchId:string) : Observable<{patch: Patch.NetPatch, nature: Patch.PatchNature}> {
        return this._dataManager.getPatchData(patchId);
    }

    private *_applyPatches(entityContext:EntityContext, patches:string[]): GeneratorType<void> {
        const projectId = this._dataManager.getId();

        const p = makeProgressHandler(voidProgress());
        p.total(100);

        yield* callAsGenerator(p, 5, async (signal, po) => {
            await _PatchHandlerService.executePatchHandlers('willImportPatches', entityContext, projectId, Patch.PatchHandlerContext.UNKNOWN);
        });

        yield* callAsGenerator(p, 90, async (signal, po) => {
            const p = makeProgressHandler(po);
            p.total(patches.length);

            await entityContext.executeWithoutValidators(async () => {
                for(const patchId of patches) {
                    const patchInfo = await callAsAsync(signal, p, 1, () => this._getPatch(patchId));
                    if (patchInfo?.nature === Patch.PatchNature.LinkedEmptyPatch){
                        continue;
                    }
                    let patchData = patchInfo ? patchInfo.patch : null;
                    const patch = Patch.importPatch(entityContext, this.getAttachmentSerializer(), patchData.patch, patchData.attachmentManifest);
                    patchData = null;
                    if(!patch) {
                        throw new ProjectContextError(`Missing patch ${patchId} from ${projectId}`);
                    }
    
                    await _PatchHandlerService.executePatchHandlers('willImportPatch', entityContext, projectId, Patch.PatchHandlerContext.UNKNOWN, patch, patchId);
    
                    Patch.applyPatch(entityContext, patch);
    
                    await _PatchHandlerService.executePatchHandlers('didImportPatch', entityContext, projectId, Patch.PatchHandlerContext.UNKNOWN, patch, patchId);
                }
            });
        });

        // start recording changes from there
        entityContext.commit();  

        // notify started recording changes
        yield* this._dataManager.applyPatchesDidCommit(entityContext);

        // finish loading on context
        yield* callAsGenerator(p, 5, async (signal, po) => {
            await _PatchHandlerService.executePatchHandlers('endImportPatches', entityContext, projectId, Patch.PatchHandlerContext.UNKNOWN);
        });
    }

    // Creates a new patch from context's changes
    private *_makeTempPatch(): GeneratorType<Patch.MutablePatch> {
        const p = makeProgressHandler(voidProgress());
        p.total(2);
        
        const header = this._dataManager.makePatchHeader(this._entityContext, this.getClientName(), true);
        if (header == null)
            throw new ProjectContextError("header is missing");
        
        yield* callAsGenerator(p,1,() => _PatchHandlerService.executePatchHandlers('willCreatePatch', this._entityContext, this._dataManager.getId(), Patch.PatchHandlerContext.TEMP));  
        const patch : Patch.MutablePatch = Patch.extractPatch(this._entityContext, header);

        // short path: return null if no modifications
        if(patch.isEmpty(this._entityContext))
            return null;

        // generate a fake patch as it were being published
        const fakePatchHeader = this._dataManager.makePatchHeader(this._entityContext, this.getClientName(), false); // note temp=false here, extractPath() depends on this 
        const fakePublishPatch : Patch.MutablePatch = Patch.extractPatch(this._entityContext, fakePatchHeader);

        // execute the patch handlers
        yield* callAsGenerator(p,1,() => _PatchHandlerService.executePatchHandlers('willExportPatch', this._entityContext, this._dataManager.getId(), Patch.PatchHandlerContext.TEMP, fakePublishPatch));
        if (fakePublishPatch.isEmpty(this._entityContext)) {
            // fake patch is empty: this means that we have changes in the temp patch but that
            // they wouldn't change anything to the project if we published them (changes to non exportable properties ...)
            header.emptyLike = true;
        }

        return patch;
    }

    /**
     * /!\ This function is used everytime a context is opened (on Cloud, contractor, even ipad-app through nativeOrmContext)
     *
     * @param {Observer} progress
     * @memberof ProjectContextImpl
     */
    public *openProjectContext(progress: ProgressObserver): GeneratorType<void> {
        try {
            const p = makeProgressHandler(progress);
            p.total(1);

            // load the schema
            this._entityContext = yield* call(this.schemaManager.entityContextFromConfiguration());

            yield* this._reloadContext();

            yield* call(this._dataManager.upgradeProjectUsingContext(p.task(1), this));

        } catch(err) {
            this._clean();

            throw err;
        }
    }

    private *_closeProjectContext(): GeneratorType<void> {
        try {
            // wait for the last subscriber to release the orm context
            const waitForLastSubscriber$ = this._entityContext.subscriberCount.pipe(
                filter((count) => count === 0)
            );
            const timer$ = timer(CONST_MAX_DELAY_FOR_CLOSING_CONTEXT).pipe(
                tap(() => {
                    log.error('Orm context is still in use by the following subscribers:', this._entityContext.subscribers);
                    throw new ProjectContextError('Timeout while waiting for orm context shutdown');

                    })
            );
            yield* call(race(waitForLastSubscriber$, timer$));
        } catch(err) {
            log.error('Error while closing orm context', err);
            throw err;
        } finally {
            // clean instance
            this._clean();
        }
    }

    private _listPatches(): Observable<string[]> {
        return this._dataManager.getPatches();
    }
    
    private *_reloadContext(): GeneratorType<void> {
        this._enableOrmMonitoring$.next(false);
        try {
            // load the patches into a reference context
            const referenceContext = yield* call(this.schemaManager.entityContextFromConfiguration());
            const patches = yield* call(this._listPatches());
            yield* this._applyPatches(referenceContext, patches);

            // at this point the reference context contains the state that we should have on the orm
            // apply the state on the current orm
            this._entityContext.rebaseFrom(referenceContext);
            referenceContext.dispose();

            // trigger temp patch flush if one makes changes on ORM
            if (this._entityContext.isDirty()) {
                yield* this._flushORMChanges();
            }

        } finally {
            this._enableOrmMonitoring$.next(true);
        }
    }

    private *_reload(): GeneratorType<void> {
        // something changed, reload context
        yield* this._reloadContext();

        const changes = yield* this._makeTempPatch();
        
        const project = this._entityContext.getEntity('Project', this._dataManager.getId());
        if(!project)
            return;
        
        const name = project.name;
        const patch = Patch.exportPatch(this._entityContext, this.getAttachmentSerializer(), changes);
        yield* call(this._dataManager.contextModified(name, patch, false));
    }


    public getClientName(): string {
        return env.clientName;
    }
    
    public getProjectEntity(): ProjectEntity {
        const projectId = this._dataManager.getId();
        const entity = this._entityContext.getEntity('Project', projectId);
        if(!entity)
            throw new ProjectContextError('Can\'t find project entity, was created ?');

        return entity;
    }
    
    public getEntityContext(): EntityContext {
        return this._entityContext;
    }

    public reload(): Observable<unknown> {
        return task(() => this._reload());
    }

    public notifyContextReady(): void {
        _currentProject$.next(this.getProjectEntity());
    }

    public close(): Observable<unknown> {
        _currentProject$.next(null);
        return task(() => this._closeProjectContext());
    }

    public flushORMChanges(): Observable<unknown> {
        return task(() => this._flushORMChanges());
    }
}
