import { Observable } from 'rxjs';

import { ProgressObserver } from '@core/tasks/progress';
import { FileService, Data, FileDescriptor, FileDriver, PathDescriptor, ResolverFileDriver, FileMetadata } from '@core/services/file/file';
import { NotImplementedError } from '@core/errors/errors-core';

export type FileMapperFunction<T extends FileDescriptor> = (file:T) => FileDescriptor;

export type FileListFunction<T extends FileDescriptor> = (progress:ProgressObserver, location:PathDescriptor) => Observable<FileDescriptor>;

/**
 * File resolver driver
 * 
 * A file resolver is a virtual file driver that maps some file descriptors to another driver. 
 * 
 * The following example maps all requests to a PatchFileDescriptor to a S3FileDescriptor:
 * ```
 * const patchDriver = new FileResolverDriver<PatchFileDescriptor>({
 *     mapper: function (file: PatchFileDescriptor) {
 *         const s3Path = new S3ProjectPathDescriptor(file.patch.patches.projectId, "patch");
 *          if (file instanceof PatchManifestDescriptor)
 *              return new S3FileDescriptor(s3Path, `${file.patch.patchId}/${file.path}`);
 *          else
 *              return new S3FileDescriptor(s3Path, `${file.patch.patchId}/${file.entityId}/${file.attachmentPath}`);
 *     } 
 * }); 
 * fileService.registerDriver('patch', patchDriver);
 * ```
 * 
 * Notes: 
 * - mappers can return different file descriptor classes depending on the input file. 
 * - for complex mappings (asynchronous mapping, download and keep in cache if not available locally, ...) one can extend the FileMapperDriver class.
 */
export class FileResolverDriver<T extends FileDescriptor> implements FileDriver<T>, ResolverFileDriver<T> {
    protected _mapperFn: FileMapperFunction<T>;
    protected _listFn: FileListFunction<T>

    constructor({ mapper, list }: { mapper: FileMapperFunction<T>; list?: FileListFunction<T>; }) {
        this._mapperFn = mapper;
        this._listFn = list;
    }

    resolveFile(file:T): FileDescriptor {
        return this._mapperFn(file);
    }

    download(file:T): Data {
        const mappedFile = this._mapperFn(file);
        return FileService.download(mappedFile);
    }
    upload(progress: ProgressObserver, file: T, data:Data): Observable<unknown> {
        const mappedFile = this._mapperFn(file);
        return FileService.upload(progress, mappedFile, data);
    }

    delete(progress: ProgressObserver, source: T): Observable<unknown> {
        const mappedFile = this._mapperFn(source);
        return FileService.delete(progress, mappedFile);
    }

    list(progress:ProgressObserver, location:PathDescriptor): Observable<FileDescriptor> {
        if(!this._listFn)
            throw new NotImplementedError("list", location.type);
        return this._listFn(progress, location);
    }

    url(file:T|null): Observable<string|null> {
        const mappedFile = this._mapperFn(file);
        return FileService.url(mappedFile);
    }

    metadata(file:T): Observable<FileMetadata> {
        const mappedFile = this._mapperFn(file);
        return FileService.metadata(mappedFile);
    }
}
