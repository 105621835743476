import { Logger, LoggerConfig } from "./logger.interface";
import { LoggerService } from "./logger.service";

/**
 * The most common usage of this class is to obtain a named Logger.
 *
 * The LoggerManager also acts as a registry of all created Loggers and can
 * update the config of all Loggers when the global config as been updated.
 *
 * Therefore it is possible to get a Logger instance anywhere in the app and
 * update the config later (for example after application boot).
 */
export class LoggerManager {
    private static readonly registeredLoggersByName: Map<string, LoggerService> = new Map();

    private static currentConfig: Readonly<LoggerConfig> = {
        DEBUG: false,
        INFO: false,
        WARN: false,
        ERROR: false,
        classes: {},
    };

    public static getLogger(name: string): Logger {
        let logger = this.registeredLoggersByName.get(name);

        if (logger === undefined) {
            logger = new LoggerService(name, this.currentConfig);
            this.registeredLoggersByName.set(name, logger);
        }

        return logger;
    }

    /**
     * Update the config of all existing and future loggers.
     */
    public static updateConfig(config: Readonly<LoggerConfig>): void {
        this.currentConfig = config;
        for (const [, logger] of this.registeredLoggersByName) {
            logger.config = config;
        }
    }

    /**
     * Reset the config of all existing and future loggers to default.
     */
    public static debugReset(): void {
        const config = {
            DEBUG: false,
            INFO: false,
            WARN: false,
            ERROR: false,
            classes: {},
        };
        this.updateConfig(config);
    }
}
