import { inject, injectable, multiInject, optional } from "inversify";
import { get, isEqual } from "lodash";
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    firstValueFrom,
    merge,
    Observable,
    of,
    scheduled,
    SchedulerLike,
    Subject,
} from "rxjs";
import { distinctUntilChanged, map, startWith, switchMap } from "rxjs/operators";

import { IllegalArgumentError, IllegalStateError } from "@archipad-js/core/error";
import { getSetSymmetricDifference, isRecord, isSetEqual } from "@archipad-js/core/utils";

import { BundleUpdate, ConfigBundle } from "./config-bundle";
import { ConfigConstant } from "./config-contant";
import { ConfigOperator } from "./config-operator";
import { applyOperators, isBuiltInOperator, isIdentifierAspectPath, parsePath } from "./config.helpers";
import {
    ConfigError,
    Config_Bundle_DIID,
    Config_Constant_DIID,
    Config_Operator_DIID,
    Config_Service_Scheduler_DIID,
} from "./config.types";

/**
 * A stateful service that expose an aggregate of JSON configurations.
 *
 * Gather {@link ConfigBundle bundles}, {@link ConfigOperator operators}
 * and {@link ConfigConstant constants} to compute the current JSON configuration.
 *
 * The JSON configuration exposed by this service is constantly re-computed to
 * avoid mutation collisions between multiple listeners that could affect each
 * others.
 */
@injectable()
export class ConfigService {
    private readonly activeBundles: BehaviorSubject<readonly ConfigBundle[]>;
    private readonly operatorChanges: Subject<void> = new Subject();
    private readonly operators: readonly ConfigOperator[];

    constructor(
        @multiInject(Config_Bundle_DIID.symbol)
        @optional()
        defaultBundles: readonly ConfigBundle[] = [],

        @multiInject(Config_Operator_DIID.symbol)
        @optional()
        operators: readonly ConfigOperator[] = [],

        @multiInject(Config_Constant_DIID.symbol)
        @optional()
        private readonly constants: readonly ConfigConstant[] = [],

        @inject(Config_Service_Scheduler_DIID.symbol)
        @optional()
        private readonly scheduler?: SchedulerLike,
    ) {
        const constantOperator = new ConfigOperator("const", (args) => {
            if (!isBuiltInOperator(args)) {
                throw new IllegalArgumentError(`Missing "const" parameter`);
            }

            return this.getConstant(args.str);
        });

        this.operators = [...operators, constantOperator];

        const bundles: readonly ConfigBundle[] = defaultBundles.slice().sort(ConfigBundle.comparator);
        this.activeBundles = new BehaviorSubject(bundles);
    }

    /**
     * Get the current configuration data.
     *
     * The configuration objects returned are mutation safe and are always
     * re-computed.
     */
    public async get(path?: string): Promise<unknown> {
        const configData = await firstValueFrom(this.getObservable(path));
        return configData;
    }

    /**
     * Get an {@link Observable} of the configuration data.
     *
     * The observable will emit a new value every time the configuration is
     * updated unless the value is deep equal to the previous one.
     *
     * The configuration objects returned are mutation safe.
     *
     * @param path the configuration path to observe.
     */
    public getObservable(path?: string): Observable<unknown> {
        const pathParts = path ? parsePath(path) : [];
        if (!pathParts.every(isIdentifierAspectPath)) {
            throw new ConfigError(`Only identifiers are supported in access paths:${path}`);
        }

        const currentConfig$ = this.getCurrentConfig();
        const currentConfigScheduled$ = this.scheduler ? scheduled(currentConfig$, this.scheduler) : currentConfig$;

        return currentConfigScheduled$.pipe(
            map((o) => {
                let result: unknown = o;
                for (const { value: propertyName } of pathParts) {
                    if (!isRecord(result)) {
                        return null;
                    }

                    const v = result[propertyName];
                    if (v === undefined) {
                        return null;
                    }

                    if (v === null) {
                        return null;
                    }

                    result = v;
                }

                return result;
            }),
            distinctUntilChanged((a, b) => isEqual(a, b)),
        );
    }

    /**
     * Returns given constant value.
     *
     * @example
     * const bar = configService.getConstant("Constant.foo.bar");
     */
    public getConstant(constantPath: string): unknown {
        const [namespace, ...path] = constantPath.split(".");
        const configConstant = this.constants.find((configConstant) => configConstant.namespace === namespace);
        if (configConstant === undefined) {
            throw new IllegalArgumentError(`Unknown constant namespace "${namespace}`);
        }

        const value = get(configConstant.constant, path);
        return value;
    }

    /**
     * Enable the given bundle on the current configuration.
     *
     * All the data in the bundle are added to the configuration, the aspects
     * enabled by the bundle are enabled in the whole configuration.
     *
     * Will throw an error if a bundle with the same priority of the given one
     * has already been enabled.
     */
    public enableBundle(bundle: ConfigBundle): void {
        const bundles = [...this.activeBundles.getValue(), bundle].sort(ConfigBundle.comparator);
        this.activeBundles.next(bundles);
    }

    /**
     * Disable the given bundle on the current configuration.
     *
     * All the data in the bundle are removed from the configuration, the
     * aspects enabled by the bundle are disabled in the configuration unless
     * another bundle is still enabling them.
     */
    public disableBundle(bundle: ConfigBundle): void {
        const bundles = [...this.activeBundles.getValue()].filter((activeBundle) => activeBundle !== bundle);
        this.activeBundles.next(bundles);
        bundle.currentConfig = null;
    }

    /**
     * Returns whether the given bundle is enabled or not.
     */
    public hasEnabledBundle(bundleName: string): boolean {
        const bundles = this.activeBundles.getValue();
        return bundles.some((bundle) => bundle.name === bundleName);
    }

    /**
     * Force re-evaluation of all operators with given names.
     *
     * @param operators Operators' names to refresh.
     *
     * @example A localization operator that depends on the current language, if
     * the language has changed the configuration must be re-evaluated.
     */
    public refreshOperators(operators: string[]): void {
        for (const name of operators) {
            if (!this.operators.some((o) => o.name === name)) {
                throw new IllegalStateError(`Cannot refresh, no such operator or a function named "${name}"`);
            }
        }
        this.operatorChanges.next();
    }

    /**
     * Get all active aspects on given {@link ConfigBundle ConfigBundles}.
     */
    private getActiveAspects(bundles: readonly ConfigBundle[]): readonly string[] {
        const activeAspects: string[] = [];
        for (const bundle of bundles) {
            for (const aspect of bundle.getEnabledAspects()) {
                activeAspects.push(aspect);
            }
        }
        return activeAspects;
    }

    /**
     * Returns the list of added and removed aspects between old and new given
     * aspect names.
     */
    private getUpdatedAspects(newActiveAspects: Set<string>, oldActiveAspects: Set<string>): Set<string> {
        const updatedAspects = getSetSymmetricDifference(newActiveAspects, oldActiveAspects);
        return updatedAspects;
    }

    /**
     * Returns an {@link Observable} of the current configuration data.
     */
    private getCurrentConfig(): Observable<unknown> {
        let currentBundles: readonly ConfigBundle[] = [];
        let activeAspects: readonly string[] = [];

        const activeBundlesUpdates$ = this.activeBundles.pipe(
            switchMap((activeBundles) => {
                const bundleUpdates = activeBundles.map((bundle) => bundle.updates$);
                return merge(...bundleUpdates).pipe(startWith({ type: "bundle_list" } as BundleUpdate));
            }),
        );

        const operatorChanges$ = this.operatorChanges.pipe(startWith(undefined));

        return combineLatest([activeBundlesUpdates$, operatorChanges$]).pipe(
            switchMap(([update]) => {
                const bundles = this.activeBundles.getValue();
                switch (update.type) {
                    case "bundle_list": {
                        const newBundles = bundles;

                        // bundle list updated, update the active aspects
                        const newActiveAspects = this.getActiveAspects(newBundles);
                        const oldActiveAspects = activeAspects;
                        activeAspects = newActiveAspects;

                        // get updated aspects
                        const updatedAspects = this.getUpdatedAspects(
                            new Set(newActiveAspects),
                            new Set(oldActiveAspects),
                        );

                        // find the position of the first added, removed or changed bundle
                        const oldBundles = currentBundles;
                        currentBundles = newBundles;
                        const length = Math.min(oldBundles.length, newBundles.length);
                        for (let i = 0; i < length; i++) {
                            if (newBundles[i] !== oldBundles[i] || newBundles[i].hasAnAspectIn(updatedAspects)) {
                                // bundle added, removed or changed at this idx, rebuild the config starting from there
                                return of(i);
                            }
                        }
                        // bundle removed, rebuild the config from the end
                        // (basically just recompute operators)
                        return of(length);
                    }
                    case "object": {
                        // bundle updated, rebuild config
                        const idx = currentBundles.indexOf(update.bundle);
                        return of(idx);
                    }
                    case "aspect": {
                        // aspect added, no impact on config
                        return EMPTY;
                    }
                    case "enabled_aspects": {
                        // enabled aspect set:  changed
                        const newActiveAspects = this.getActiveAspects(bundles);
                        const oldActiveAspects = activeAspects;

                        if (isSetEqual(new Set(newActiveAspects), new Set(oldActiveAspects))) {
                            return EMPTY;
                        }

                        activeAspects = newActiveAspects;

                        // get updated aspects
                        const updatedAspects = this.getUpdatedAspects(
                            new Set(newActiveAspects),
                            new Set(oldActiveAspects),
                        );

                        // find the position of the first bundle affected by the
                        // change of aspect
                        for (let i = 0; i < bundles.length; i++) {
                            if (bundles[i].hasAnAspectIn(updatedAspects)) {
                                return of(i);
                            }
                        }

                        return EMPTY;
                    }
                }
            }),
            map((bundleIndex) => {
                const bundles = this.activeBundles.getValue();
                let configData = bundleIndex > 0 ? bundles[bundleIndex - 1].currentConfig : {};

                for (let i = bundleIndex; i < bundles.length; i++) {
                    const bundle = bundles[i];

                    configData = bundle.currentConfig = bundle.applyOnConfig(configData, activeAspects);
                }

                // apply the configuration operators
                configData = applyOperators(configData, (name, args) => {
                    const operator = this.operators.find((o) => o.name === name);
                    if (!operator) return args;
                    return operator.fn(args);
                });

                return configData;
            }),
        );
    }
}
