/**
* ProjectUserRoleEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { ProjectUserRole_TransitionsEntity } from "./ProjectUserRole_TransitionsEntity";
import { ProjectUserRole_BugGroupsEntity } from "./ProjectUserRole_BugGroupsEntity";
import { ProjectUserRightEntity } from "./ProjectUserRightEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";


declare global {
    interface EntityTypeMap {
        "ProjectUserRole": ProjectUserRoleEntity
    }
}

export abstract class ProjectUserRoleEntity extends BaseEntity {

	 abstract projectUserRoleId: string;
	 abstract restrictTransitions: boolean;
	 abstract restrictDelete: boolean;
	 abstract restrictRead: boolean;
	 abstract restrictCreateVisit: boolean;
	 abstract readonlyBug: boolean;
	 abstract project: ProjectEntity;
	 abstract transitionsRelations: ReadonlyArray<ProjectUserRole_TransitionsEntity>;
	 abstract bugGroupsRelations: ReadonlyArray<ProjectUserRole_BugGroupsEntity>;
	 abstract templateName?: string;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract attachedRights?: ReadonlyArray<ProjectUserRightEntity>;
	 abstract users?: ReadonlyArray<ProjectUserEntity>;
	
}
