import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { EntityType, ORMError } from "@archipad-js/core/orm";
import {
    comparatorForType,
    DynNode,
    Expression,
    makePathExpression,
    OrderByParser,
    UnaryOperator,
} from "@archipad-js/core/query";
import { binarySearch } from "@archipad-js/core/utils";

import { IllegalArgumentError } from "@core/errors/errors-core";
import * as Orm from "@core/services/orm/orm";

// handle a.b.c expressions
class OrmPathExpression {
    type:string;

    relation:boolean;
    single:boolean;
    target:EntityType;
    inverse: { target:EntityType, field:string };

    fn: UnaryOperator<Orm.BaseEntity, unknown>;

    constructor(entityType:EntityType, str:string) {
        const description = entityType.keyPathDescription(str);
        if(!description || description.length < 1) {
            throw new ORMError('No such property "' + str + '" in ' + entityType.entityName);
        }
        const property = description[description.length-1];


        // this._entityType = entityType;
        // this._description = description;
        this.fn = makePathExpression(str);

        this.relation = (property.relation);
        if(property.relation===true) {
            const relation = property;
            this.type = null;
            this.single = (relation.single);
            this.target = relation.target;
            this.inverse = {
                target: relation.inverseEntity,
                field: relation.inverseField
            };
        }
        else {
            this.type = property.type;
        }
    }
}

// order/group by clauses
export class OrderByClause {
    comparators: ((a:any,b:any)=>0 | 1 | -1)[];

    constructor(entityType:EntityType, clauses:{ path:string, asc:boolean, style:string }[]) {
        this.comparators = [];
        clauses.forEach((clause) => {
            const path = new OrmPathExpression(entityType, clause.path);
            if(path.relation)
                throw new ORMError("Cannot sort by relation '" + clause.path + "' in '" + entityType + "'");

            const comparator = comparatorForType(path.type, clause.style);
            let fn;

            if(clause.asc) {
                fn = function ascComparator(a,b) {
                    return comparator(path.fn(a),path.fn(b));
                };
            } else {
                fn = function descComparator(a,b) {
                    return -comparator(path.fn(a),path.fn(b));
                };
            }
            this.comparators.push(fn);
        });
    }

    apply(a,b): 0 | 1 | -1 {
        let ret;
        for(let i=0;i<this.comparators.length;i++) {
            ret = this.comparators[i](a,b);
            if(ret)
                return ret;
        }
        return 0;
    };
}

//--------------------------------------------------------------------------
interface EntitySet<T extends Orm.BaseEntity> {
    contains(o:T):boolean;
    every(fn:(o:T)=>boolean,self:any):boolean;
    isEmpty():boolean;
}

class ArrayEntitySet<T extends Orm.BaseEntity> implements EntitySet<T> {
    array:ReadonlyArray<T>;

    constructor(array:ReadonlyArray<T>) {
        this.array = array;
    }

    contains(o) {
        return this.array.indexOf(o) != -1;
    }
    every(fn:(o)=>boolean,self) {
        for(let i=0;i<this.array.length;i++) {
            if(!fn.call(self, this.array[i]))
                return false;
        }
        return true;
    }
    isEmpty() {
        return this.array.length === 0;
    }
}

class DictionaryEntitySet<T extends Orm.BaseEntity> implements EntitySet<T> {
    dict:{ [name:string]:T };

    constructor(dict:{ [name:string]:T }) {
        this.dict = dict;
    }
    contains(o) {
        return !!this.dict[o.id];
    }
    every(fn:(o)=>boolean,self) {
        for(const k in this.dict) {
            if(!fn.call(self, this.dict[k]))
                return false;
        }
        return true;
    }
    isEmpty() {
        for(const _k in this.dict)
            return false;
        return true;
    }
}

//--------------------------------------------------------------------------
export class ResultSet<T extends Orm.BaseEntity> {
    private _entitySet: EntitySet<T>;
    private _entityContext: Orm.EntityContext;
    private _entityType: EntityType;
    private _predicateFunction: UnaryOperator<T, unknown>;
    private _orderBy: OrderByClause;

    constructor(entityContext:Orm.EntityContext, entityType:EntityType, predicate:Expression, predicateParams, entitySet: EntitySet<T>, orderBy:{ path:string, asc:boolean, style:string }[]) {
        this._entitySet = entitySet;
        this._entityContext = entityContext;
        this._entityType = entityType;

        // make predicate
        this._predicateFunction = predicate.build(predicateParams, {
            getProperty: function(path) {
                const expression = new OrmPathExpression(entityType, path);
                return new DynNode(expression.type, expression.fn);
            },
        });

        // make order/ group clauses
        if(orderBy.length)
            this._orderBy = new OrderByClause(entityType, orderBy);
    }

    contains(entity:T):boolean {
        return this._entitySet.contains(entity);
    }

    get predicateFunction() {
        return this._predicateFunction;
    }
    get entityContext() {
        return this._entityContext;
    }

    get entityType() {
        return this._entityType;
    }
    get orderByClause() {
        return this._orderBy;
    }

    /**
     * Observes changes on the resultset
     */
    updates(): Observable<any> {
        return this._entityContext.notifications.pipe(
            filter((notification) => {
                switch(notification[0]) {
                    case 'entityCreated': {
                        const entity = notification[1] as T;
                        if(entity.entityType === this._entityType && this._predicateFunction(entity))
                            return true;    // new entity matches predicate
                    } break;

                    case 'entityUpdated': {
                        const entity = notification[1] as T;
                        if(entity.entityType === this._entityType) {
                            if(this._predicateFunction(entity))
                                return true;    // entity matches now or order changed
                            else if(this._entitySet.contains(entity))
                                return true;    // entity no longer matches
                        }
                    } break;

                    case 'willDeleteEntity': {
                        const entity = notification[1] as T;
                        if(entity.entityType === this._entityType && this._predicateFunction(entity)) {
                            return true;    // remove entity matches predicate
                        }
                    } break;
                }
                return false;
            })
        );
    }

    count() {
        let count = 0;
        this._entitySet.every(function(entity) {
            if(!this._predicateFunction(entity))
                return true;
            count++;
            return true;
        },this);
        return count;
    }

    firstEntity():T|null {
        let first: T|null = null;
        this._entitySet.every(function(entity) {
            if(!this._predicateFunction(entity))
                return true;
            if(!first) {
                first = entity;
                if(!this._orderBy)
                    return false;
            } else if( (this._groupBy && this._groupBy.apply(first,entity) > 0) || this._orderBy.apply(first,entity) > 0 ) {
                first = entity;
            }
            return true;
        },this);

        return first;
    }

    forEach(fn:(o:T)=>void, self) {
        this._entitySet.every(function(entity) {
            if(!this._predicateFunction(entity))
                return true;
            fn.call(self, entity);
            return true;
        },this);
    }

    sortedEntities():T[] {
        if(!this._orderBy){
            throw new IllegalArgumentError('No order by clause found.');
        }
        
        const entities = [];
        this._entitySet.every(function(entity) {
            if(!this._predicateFunction(entity))
                return true;
            const pos = binarySearch(entity, entities, (a,b) => this._orderBy.apply(a,b)).pos;
            entities.splice(pos, 0, entity);
            return true;
        },this);
        return entities;
    }
}

//--------------------------------------------------------------------------
/**
 * Query interface
 * 
 * Usage:
 * ```
 * const resultSet = Query.query<VisitEntity>(entityContext, 'Visit')
 *       .orderBy('date desc,object(natural)');
 *       .predicateWithFormat('date >= {start} AND date < {end}')
 *       .predicateWithFormat('(visitType = null  OR (visitType.bugGroup = null AND visitType.eventPriority == 0))')
 *       .execute({
 *           start: ...,
 *           end: ...
 *       });
 * 
 * const firstVisit = result.firstEntity()
 * ```
 */
export interface Query<T extends Orm.BaseEntity> {
    predicate(expression: Expression): Query<T>;
    predicateWithFormat(expressionFormat:string, predicateParams?:{ [name:string]:any }): Query<T>;
    orderBy(orderBy:string): Query<T>;
    execute(variables?:{ [name:string]:any }):ResultSet<T>;
}

class _Query<T extends Orm.BaseEntity> implements Query<T> {
    readonly entityType:EntityType;
    private _entityContext:Orm.EntityContext;
    private _entitySet:EntitySet<T>;
    private _predicate:Expression;
    private _orderByClauses:{ path:string, asc:boolean, style:string }[];
    
    constructor(entityContext, entityType, entitySet) {
        this.entityType = entityType;
        this._entityContext = entityContext;
        this._entitySet = entitySet;
        this._predicate = new Expression();
        this._orderByClauses = [];
    }

    predicate(expression: Expression): this {
        this._predicate = this._predicate.and(expression);
        return this;
    }

    predicateWithFormat(expressionFormat: string, predicateParams?: Record<string, unknown>): this {
        const expression = new Expression(expressionFormat, predicateParams);
        this._predicate = this._predicate.and(expression);
        return this;
    }

    orderBy(orderBy:string) {
        Array.prototype.push.apply(this._orderByClauses, OrderByParser.parse(orderBy));
        return this;
    }

    execute(predicateParams?:{ [name:string]:any }):ResultSet<T> {
        return new ResultSet(this._entityContext, this.entityType, this._predicate, predicateParams, this._entitySet, this._orderByClauses);
    }
}

//--------------------------------------------------------------------------

export function query<K extends keyof EntityTypeMap>(entityContext:Orm.EntityContext, entityName:K):Query<EntityTypeMap[K]>;
export function query<T extends Orm.BaseEntity>(entityContext:Orm.EntityContext, entityName:string):Query<T>;
export function query(entityContext:Orm.EntityContext, entityName:string):Query<Orm.BaseEntity> {
    const entityType = entityContext.entityTypes.byName[entityName];
    if(!entityType){
        throw new ORMError('No such entity type "' + entityName + '"');
    }

    const entitiesById = entityContext.entitiesByType[entityName];
    const entitySet = new DictionaryEntitySet(entitiesById);
    return new _Query(entityContext, entityType, entitySet);
}

export function queryRelation<T extends Orm.BaseEntity>(entityContext:Orm.EntityContext, parentEntity:Orm.BaseEntity, relationKeyPath:string):Query<T> {
    if(!parentEntity || typeof parentEntity != 'object') {
        throw new IllegalArgumentError('parentEntity must be defined');
    }

    const relationPath = new OrmPathExpression(parentEntity.entityType, relationKeyPath);
    if(!relationPath.relation || relationPath.single) {
        throw new ORMError('"' + relationKeyPath + '" must be a multiple relationship');
    }

    const entities = relationPath.fn(parentEntity);
    if (!Array.isArray(entities) || !entities.every(isEntity)) {
        throw new ORMError('"' + relationKeyPath + '" does not return an array of entities');
    }

    const entityType = relationPath.target;
    const entitySet = new ArrayEntitySet(entities);
    const query = new _Query<T>(entityContext, entityType, entitySet);

    const inverseParentPathElements = relationKeyPath.split('.');
    inverseParentPathElements.pop();
    const inverseParentEntity = inverseParentPathElements.length === 0 ? parentEntity : (new OrmPathExpression(parentEntity.entityType, inverseParentPathElements.join('.'))).fn(parentEntity);

    if (!isEntity(inverseParentEntity)) {
        throw new ORMError('"' + relationKeyPath + '" does not return an entity');
    }

    const predicate = relationPath.inverse.field + '.id = "'+ inverseParentEntity.id + '"';
    query.predicateWithFormat(predicate);

    return query;
}

function isEntity(o: unknown): o is Orm.BaseEntity {
    return o instanceof Orm.BaseEntity;
}