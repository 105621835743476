import { Observable, from } from 'rxjs';
import * as t from 'io-ts';
import { map } from 'rxjs/operators';

import * as Cache from '@core/services/cache';
import * as rpc from '@archipad/backend/rpc/authenticated';
import { Task } from '@core/services/task';
import { JSONDecode } from '@archipad-js/archipad/utils';
import { isLeft } from 'fp-ts/lib/Either';
import { NumericStringCodec } from '@core/iots/codecs';
import { formattedReporter } from '@core/iots/reporter';
import { IOParsingError, ResponseAPIError, findInAllChainedErrors } from '@core/errors/errors-core';
import { S3FileDescriptor, S3FileDriver, S3ReportTemplatePathDescriptor } from '@core/services/file/s3file';
import { S3Creds } from "@archipad/backend/archiweb/synchroBackend";
import { getAccountService } from '@archipad/services/account.service';

// TODO Fix this import (i'm not supposed to import @ui-archipad here)
// import {ReportTemplateSectionContainerConfigModel} from "../../../../../ui-components/ui-archipad/src/controllers/report/configuration/model/Definition";

// Old report
interface ReportTemplateApiModel {
    id:number;
    name:string;
    date:number;
    user_id:number;
    data:string;
    project_id: string | null;
    creator_firstname: string;
    creator_lastname: string;
    creator_login: string;
}

// ReportTemplateSectionElement
const ReportTemplateSectionElement = t.intersection([
    t.type({
        x: t.number,
        y: t.number,
    }),
    t.partial({ 
        display: t.boolean,
    }),
]);
export type ReportTemplateSectionElement = t.TypeOf<typeof ReportTemplateSectionElement>;

// ReportTemplateBackground
const ReportTemplateBackground = t.intersection([
    t.type({
        orientation: t.number
    }),
    t.partial({
        file: t.string
    })
]);
export type ReportTemplateBackground = t.TypeOf<typeof ReportTemplateBackground>;

// ReportTemplateSection
const ReportTemplateSection = t.intersection([
    t.type({
        height: t.number
    }),
    t.partial({
        elements: t.type({
            pageNumbers: ReportTemplateSectionElement,
            reportDate: ReportTemplateSectionElement,
        }),
    }),
]);
export type ReportTemplateSection = t.TypeOf<typeof ReportTemplateSection>;

// ReportTemplateLayout
export const ReportTemplateLayout = t.interface({
    header: ReportTemplateSection,
    footer: ReportTemplateSection
});
export type ReportTemplateLayout = t.TypeOf<typeof ReportTemplateLayout>;

// ReportTemplateData
export const ReportTemplateData = t.intersection([
    t.type({
        background: ReportTemplateBackground,
        layout: ReportTemplateLayout,
        modelVersion: t.string,
        template: t.string
    }),
    t.partial({
        tintColor: t.string.pipe( NumericStringCodec ),
        paperSize : t.string,
        content: t.unknown, // ReportTemplateSectionContainerConfigModel
    })
]);
export type ReportTemplateData = t.TypeOf<typeof ReportTemplateData>;

// ReportTemplate
const ReportTemplate = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        date: t.number,
        data: ReportTemplateData,
        user_id: t.string,
        creator_firstname: t.union([t.null, t.string]),
        creator_lastname: t.union([t.null, t.string]),
        creator_login: t.string,
        project_id: t.union([t.null, t.string]),
    }),
    t.partial({
        token: S3Creds,
        bucket: t.string,
        region: t.string,
        imagesPrefix: t.string,
        path: t.string,
    })
]);

export type ReportTemplate = t.TypeOf<typeof ReportTemplate>;

/*---------------------------------------------------------------------------*/
/**
 * Check if API have sent valid ReportTemplateModel
 * @param model 
 */
function _checkReportTemplateIntegrity(model: ReportTemplate): void {
    const decodedReportTemplate = ReportTemplate.decode(model);

    if(isLeft(decodedReportTemplate)) {
        throw new IOParsingError(formattedReporter(decodedReportTemplate).toString());
    }
}

function _updateModelVersion(modelVersion: string): string {
    if(modelVersion !== '1.0.1') {
        return modelVersion;
    }
    return '1.0.2';
}

function _fixReportTemplateModel(model:ReportTemplateApiModel):ReportTemplate {

    const data = JSONDecode(model.data) as any;

    const reportTemplate : ReportTemplate = {
        ...model,
        id: "" + model.id,
        user_id: "" + model.user_id,
        project_id: model.project_id,
        creator_firstname: model.creator_firstname,
        creator_lastname: model.creator_lastname,
        creator_login: model.creator_login,
        data: {
            background: {orientation: parseInt(data?.background?.orientation, 10), file: data?.background?.file},
            layout: {
                header: {height: parseInt(data?.layout?.header?.height, 10)},
                footer: {
                    height: parseInt(data?.layout?.footer?.height, 10),
                    elements: {
                        reportDate: {
                            x: parseInt(data?.layout?.footer?.elements?.reportDate?.x, 10),
                            y: parseInt(data?.layout?.footer?.elements?.reportDate?.y, 10),
                            display: data?.layout?.footer?.elements?.reportDate?.display ?? true,
                        },
                        pageNumbers: {
                            x: parseInt(data?.layout?.footer?.elements?.pageNumbers?.x, 10),
                            y: parseInt(data?.layout?.footer?.elements?.pageNumbers?.y, 10),
                            display: data?.layout?.footer?.elements?.pageNumbers?.display ?? true,
                        },

                    }
                }
            },
            // Update ModelVersion
            modelVersion: _updateModelVersion(data?.modelVersion ?? ''),
            template: data?.template,
            content: data?.content,
        }
    };

    if (data?.tintColor != null) {
        // force cast to string
        reportTemplate.data.tintColor = "" + data.tintColor;
    }

    _checkReportTemplateIntegrity(reportTemplate);

    return reportTemplate

}

const reportTemplateListCache = new Map<string, Cache.Request<readonly ReportTemplate[]>>();

function getOrCreateReportTemplateListRequest(task: Task, projectId: string): Cache.Request<readonly ReportTemplate[]> {
    const userId = getAccountService().currentUser?.id;

    const cacheKey = `${userId}|${projectId}`;
    if (!reportTemplateListCache.has(cacheKey)) {
        const request = new Cache.Request<ReportTemplate[]>(async () => {
            try {
                const ret: { data: readonly ReportTemplateApiModel[] } = await rpc.makeAuthenticatedRequest(task, 'report-template', 'list', {
                    'project_id': projectId,
                });
                return ret.data.map((model) => _fixReportTemplateModel(model));
            } catch (err) {
                reportTemplateListCache.delete(cacheKey);
                throw err;
            }
        });

        reportTemplateListCache.set(cacheKey, request);
    }

    const request = reportTemplateListCache.get(cacheKey);
    return request;
}

function clearCacheReportTemplateListRequest(): void {
    for (const [_cacheKey, request] of reportTemplateListCache) {
        request.clear();
    }

    reportTemplateListCache.clear();
}

/**
 * @param id : if null then create else update
 */
export async function updateReport(
    {
        task,
        id,
        name,
        data,
        project_id,
    }: {
        task: Task;
        id?: string;
        name: string;
        data: ReportTemplateData;
        project_id?: string;
    }
): Promise<ReportTemplate> {
    const params = { id, name: name, data: data, project_id };

    clearCacheReportTemplateListRequest();

    const ret = await rpc.makeAuthenticatedRequest(task, 'report-template', 'update', params);
    return _fixReportTemplateModel(ret);
}

/**
 * @param ids : array containing report templates ids to delete
 */
export async function deleteReports(task: Task, ids:ReadonlyArray<string>): Promise<{ success: true }> {
    const params = {reportsTemplates:[]};

    for(const id of ids){
        params.reportsTemplates.push({ "id": id });
    }

    clearCacheReportTemplateListRequest();

    let result = null;
    try {
        result = await rpc.makeAuthenticatedRequest(task, 'report-template', 'delete', params);
    } catch (error) {
        const apiError = findInAllChainedErrors(error, ResponseAPIError.is);
        // Report wasn't saved yet, delete it directly
        if (apiError?.response.code === 1001) {
            result = { success: true };
        } else {
            throw error;
        }
    }

    return result;
}

export async function listReportTemplates(task: Task, projectId: string): Promise<readonly ReportTemplate[]> {
    const request = getOrCreateReportTemplateListRequest(task, projectId);
    const reportTemplateList = await request.fetch().toPromise();
    return reportTemplateList;
}

export function getReportTemplate(task: Task, projectId: string, templateId: string) : Observable<ReportTemplate> {
    return from(listReportTemplates(task, projectId)).pipe(
        map((templateList: ReportTemplate[]) => {
            return templateList.find(tpl => {
                return tpl.id === templateId;
            })
        })
    );
}


export async function serializeAsV1ReportTemplateBackgroundFile(file : S3FileDescriptor) : Promise<string> {
    const context = await S3FileDriver.resolveLocation(file.location).toPromise();
    // TODO WORKAROUND FIXME : background files path include the keyPrefix, we have to do the same here
    // in order to maintain the compatibility.
    const result = `${context.keyPrefix}/${file.path}`;
    return result;
}

export async function deserializeAsV1ReportTemplateBackgroundFile(fileUri : string, reportTemplateId: string, projectId: string) : Promise<S3FileDescriptor> {
    const reportTemplateDir = S3ReportTemplatePathDescriptor.MakeForReportTemplate(reportTemplateId, projectId);
    const context = await S3FileDriver.resolveLocation(reportTemplateDir).toPromise();
    // TODO WORKAROUND FIXME : background files path include the keyPrefix, we have to remove it
    // in order to create the file.
    const file = fileUri.substring(context.keyPrefix.length+1);
    const result = new S3FileDescriptor(reportTemplateDir, file);
    return result;
}
