import {
    CoreError,
    findInAllChainedErrors,
    getSourceError,
    TechnicalErrorIdentifier,
    TechnicalErrorModuleCore,
} from "@archipad-js/core/error";

export { IllegalArgumentError, IllegalStateError, UnsupportedOperationError } from "@archipad-js/core/error";

import { APIError } from "@archipad-js/archipad/archiweb-services-client";

export {
    InternalAPIError,
    ResponseAPIError,
    MalFormedResponseAPIError,
    ConnectionFailedNetworkError,
    NetworkError,
    TimeSkewAPIError,
} from "@archipad-js/archipad/archiweb-services-client";

import { FileDescriptor } from "@core/services/file/file";

export { CoreError, getSourceError, findInAllChainedErrors, TechnicalErrorIdentifier, APIError };

/**
 * Set underlyingError on an error
 * @param underlyingError
 * @param error
 */
export function chainError<T extends CoreError>(underlyingError: Error, error: T): T | Error {
    if(underlyingError instanceof CancelledError){
        return underlyingError;
    }

    error.underlyingError = underlyingError;

    return error;
}

export enum AlertErrorAction {
    CANCEL = 'cancel',
    RELOAD = 'reload',
    BACK_TO_PROJECT_LIST = 'backToProjectList',
    BACK_TO_PLAN_LIST = 'backToPlanList',
}

export class AlertError extends CoreError {
    public underlyingError?: Error;

    constructor(
        public title: string,
        public content: string | string[],
        public action?: AlertErrorAction,
    ) {
        super();
        this.name = this.constructor.name;
    }
}

/**
 * Must not be extended
 * Must never be an underlyingError
 * Must only be catch or throw when a task is cancelled
 */
export class CancelledError extends Error {
    public reasonCode: string;

    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
    }
}

export class ParseError extends CoreError { }

export class UnexpectedError extends CoreError { }

export class ConfigError extends CoreError { }

// File
export class NotImplementedError extends CoreError {
    constructor(method:string, context:string) {
        super(`NotImplementedError: method ${method} in ${context}`);

        this.addContext("Archipad", { method: method });
        this.addContext("Archipad", { context: context });
    }
}

export class FileError extends CoreError {
    private _recoverable = false;

    public get recoverable(): boolean {
        return Boolean(this._recoverable);
    }

    public set recoverable(value: boolean) {
        this._recoverable = value;
        this.addContext("Archipad", { recoverable: value });
    }

    static makeError(
        method: string,
        sourceDescriptor?: FileDescriptor,
        destinationDescriptor?: FileDescriptor,
    ): FileError {
        const err = new FileError(
            `FileError method:"${method}" ${sourceDescriptor ? `source:"${sourceDescriptor}"` : ""} ${
                destinationDescriptor ? `destination:"${destinationDescriptor}"` : ""
            }`,
        );

        err.addContext("Archipad", { method: method });

        if (sourceDescriptor) {
            err.addContext("Archipad", { sourceDescriptor: sourceDescriptor });
        }

        if (destinationDescriptor) {
            err.addContext("Archipad", { destinationDescriptor: destinationDescriptor });
        }

        return err;
    }
}

export class FileNotFoundError extends FileError {
    static makeError(
        method: string,
        sourceDescriptor?: FileDescriptor,
        destinationDescriptor?: FileDescriptor,
    ): FileNotFoundError {
        const err = new FileNotFoundError(
            `FileNotFound method:"${method}" ${sourceDescriptor ? `source:"${sourceDescriptor}"` : ""} ${
                destinationDescriptor ? `destination:"${destinationDescriptor}"` : ""
            }`,
        );

        err.addContext("Archipad", { recoverable: err.recoverable });
        err.addContext("Archipad", { method: method });

        if (sourceDescriptor) {
            err.addContext("Archipad", { sourceDescriptor: sourceDescriptor });
        }

        if (destinationDescriptor) {
            err.addContext("Archipad", { destinationDescriptor: destinationDescriptor });
        }

        return err;
    }
}

export class AuthenticationAPIError extends APIError {
    public identifier = new TechnicalErrorIdentifier(
        TechnicalErrorModuleCore.API, 
        "AUTHENTICATION_ERROR",
    );
}

// Localized error
export class LocalizedError extends CoreError {
    constructor(
        public readonly localizedMessage: string,
    ) {
        super();
    }
}

// ResultSet
export class ResultSetError extends CoreError { }

// IOParsingError
export class IOParsingError extends CoreError { }

export class IndexedDBVersionError extends CoreError { }
