import { format, isSameHour, isSameMinute, isValid, isMatch, parse, set } from "date-fns";

export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = 60 * SECOND_IN_MS;
export const HOUR_IN_MS = 60 * MINUTE_IN_MS;
export const DAY_IN_MS = 24 * HOUR_IN_MS;

// NB: (today_midnight - (today_midnight - 1s)) = 1d
export function dateDaysBetween(date1: Date, date2: Date): number {
    // copy date parts of the timestamps, discarding the time parts
    const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    // calculate the difference in milliseconds
    const difference_ms = d2.getTime() - d1.getTime();
    // convert back to days (round, not floor, because of daylight saving time)
    const days = Math.round(difference_ms / DAY_IN_MS);
    return days;
}

export function addDays(date: Date, amount: number): Date {
    const res = new Date(date.getTime() + (amount * DAY_IN_MS));
    return res;
}

export function differenceInCalendarDays(dateLeft: Date, dateRight: Date): number {
    const startOfDayLeft = startOfDayTimestamp(dateLeft);
    const startOfDayRight = startOfDayTimestamp(dateRight);

    // Round the number of days to the nearest integer
    // because the number of milliseconds in a day is not constant
    // (e.g. it's different in the day of the daylight saving time clock shift)
    return Math.round((startOfDayLeft - startOfDayRight) / DAY_IN_MS);
}

export function startOfDay(date: Date): Date {
    const originTime = date.getTime();
    const timeSinceMidnight = (originTime - (date.getTimezoneOffset() * MINUTE_IN_MS)) % DAY_IN_MS;
    const timestamp = originTime - timeSinceMidnight;
    const resDate = new Date(timestamp);
    return resDate;
}

export function startOfDayTimestamp(date: Date): number {
    const originTime = date.getTime();
    const timeSinceMidnight = (originTime - (date.getTimezoneOffset() * MINUTE_IN_MS)) % DAY_IN_MS;
    const timestamp = originTime - timeSinceMidnight;
    return timestamp;
}

export function dateCompare(x,y) {
    if(x==null)
        return y != null ? -1 : 0;
    if(y==null)
        return x != null ? 1 : 0;
    return x.getTime()-y.getTime();
}

/**
 * Determines if time of date is midnight (00:00)
 * Midnight value is used as "empty time" in visit
 * 
 * @param d 
 */
export function isMidnight( d:Date ):boolean {
    return (!d.getHours() && !d.getMinutes());
}

/**
 * Set year, month and day from "from" date to "to" date
 * "to" date is modified and returned
 * 
 * @param from 
 * @param to 
 */
export function setSameDay( from:Date | null, to:Date | null ):Date | null {
    if( !from ) {
        return to;
    }
    if( !to ) {
        return null;
    }

    const newDate = set( to, {
        year: from.getFullYear(),
        month: from.getMonth(), date:
        from.getDate()
    });
    return newDate;
}

/**
 * Set hour and minutes from "from" date to "to" date
 * "to" date is modified and returned
 * 
 * @param from 
 * @param to 
 */
export function setSameTime( from:Date | null, to:Date | null ):Date | null {
    if( !from ) {
        return to;
    }
    if( !to ) {
        return null;
    }

    const newDate = set( to, {
        hours: from.getHours(),
        minutes: from.getMinutes(),
        seconds: from.getSeconds(),
        milliseconds: 0
    });
    return newDate;
}

/**
 * Returns true is hour and minute are equal on both dates
 * 
 * @param date1 
 * @param date2 
 */
export function isSameTime( date1:Date, date2:Date ):boolean {
    return isSameMinute( date1, date2 ) && isSameHour( date1, date2 );
}

/**
 * Returns an HH:mm formatted string for date
 * 
 * @param date 
 */
export function getTimeFromDate( date:Date | null, withSeconds:boolean = false ):string | null {
    if( !date ) {
        return null;
    }

    const timeFormat = withSeconds ? "HH:mm:ss" : "HH:mm";
    return format( date, timeFormat );
}

/**
 * Parse input value as HH:mm or HH:mm:ss, uses rest of date data from "date" and returns a new date
 * 
 * @param value 
 * @param date 
 */
export function parseTimeToDate( value:string, date:Date ):Date | null {
    let newDate = parse( value, "HH:mm:ss", date );
    if( !isValidDate(newDate) ) {
        newDate = parse( value, "HH:mm", date );

        if( !isValidDate(newDate) ) {
            return null;
        }
    }

    return newDate;
}

/**
 * Returns true if date is valid
 */

export function isValidDate(date:Date):boolean {
    return isValid( date );
}

export function isValidTime(time:string | null):boolean {
    if( !time ) {
        return false;
    }
    return isMatch(time, "HH:mm:ss") || isMatch(time, "HH:mm");
}