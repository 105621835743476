// import { createLogger } from '@core/services/logger.service';
import { Comparator, comparatorForType } from "@archipad-js/core/query";
import {PatchData} from "@archipad/backend/archiweb/synchroDriver";
// const log = createLogger('patch');


const DATE_RE = /^(?:\/Date\(([0-9]+)\)\/|([0-9]+))$/

// Parse a date in patch format
export function parseDate(d) {
    if(!d)
        return null;
    if(d instanceof Date)
        return d;

    let date = null;
    try {
        if(typeof d === 'object') {
            // log.warn('Autofixing date', d);
            // workaround for failed JSONDecode that lead to {"":1325132654000} instead of dates
            if('' in d && typeof d[''] === 'number')
                date = new Date(d['']);
        } else if(typeof d === 'number') {
            date = new Date(d);
        } else if(typeof d === 'string') {
            const m = d.match(DATE_RE);
            if(m[1]) {
                // log.warn('Autofixing date', d);
                // workaround for old patches where dates are encoded as "/Date(1325132654000)/" instead of "\/Date(1325132654000)\/"
                date = new Date(parseInt(m[1], 10));
            } else if(m[2]) {
                // allow dates as timestamps "1325132654000"
                date = new Date(parseInt(m[2], 10));
            }
        }
    } catch(err) {
        console.log('Unable to fix date', err);
    }

    if(date && isNaN(date.getTime()))
        date = null;
    return date;
}

export const comparePatchId: Comparator<string> = comparatorForType('string', null);

export const comparePatchData: Comparator<PatchData> =
    function (patchA, patchB) {
        return comparePatchId(patchA.patchId,patchB.patchId);
    }