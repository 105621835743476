import { ORMError } from "./errors";
import {
    EntityConf,
    EntityType,
    EntityTypes,
    ExportKind,
    ORMModelConf,
    PropertyDescription,
    RelationDescription,
    RelationEndpointConf,
    ValuePropertyDescription,
} from "./types";
import { convertBoolean, convertExportKind, convertType, kExportKindExportableByValue, logger } from "./utils";
import { makeValidationSchema } from "./validation";

export function buildSchema(metadata: unknown): EntityTypes {
    // get model entities
    // TODO implement `model.json` configuration types using io-ts
    const ormModel: ORMModelConf = metadata as ORMModelConf;
    const metaDataEntities: EntityConf[] = ormModel.entities.filter((entity) => convertBoolean(entity.model, true));

    const duplicates = metaDataEntities.filter(
        (entity, index) => metaDataEntities.findIndex((e) => e.name === entity.name) !== index,
    );
    if (duplicates.length) {
        const error = new ORMError("Illegal attempt to init ORM with duplicate entities");
        error.addContext("Archipad", { duplicates: duplicates });
        throw error;
    }

    // first pass: create the EntityType with their plain attributes
    const entityTypeBuilders = metaDataEntities.map((entityConf) => new EntityTypeBuilder(entityConf));

    const byIndex = entityTypeBuilders.map((entityTypeBuilder) => entityTypeBuilder.entityType);
    const byName = Object.fromEntries<EntityType>(byIndex.map((entityType) => [entityType.entityName, entityType]));
    const entityTypes = { byIndex, byName };

    // second pass: define relations
    entityTypeBuilders.forEach((entityTypeBuilder) => entityTypeBuilder.addRelations(entityTypes));

    return entityTypes;
}

class EntityTypeBuilder {
    public readonly entityType: EntityType;

    constructor(private entityConf: EntityConf) {
        this.entityType = new EntityTypeImpl(this.entityConf.name);

        this.entityType.export = convertBoolean(this.entityConf.export, true);
        this.entityType.import = convertBoolean(this.entityConf.import, true);

        if (!this.entityConf.attributes) {
            return;
        }

        // define entity attributes
        let hasPrimaryKey = false;
        this.entityConf.attributes.forEach((attribute) => {
            if (!convertBoolean(attribute.model, true)) {
                return;
            }

            const type = convertType(attribute.attributeType);
            if (!type) {
                throw new ORMError("Unexpected attributeType in attribute: " + JSON.stringify(attribute));
            }

            // make int64, indexed attributes primary key
            const primaryKey =
                !hasPrimaryKey && attribute.attributeType == "int64" && convertBoolean(attribute.indexed, false);

            const exportKind = convertExportKind(attribute.export, ExportKind.yes);
            const exportable = kExportKindExportableByValue[exportKind];
            const importable = convertBoolean(attribute.import, true);
            const transient = convertBoolean(attribute.transient, false);
            const optional = convertBoolean(attribute.optional, false);
            const calculated = convertBoolean(attribute.calculated, false);

            const metadata: ValuePropertyDescription = {
                relation: false,
                name: attribute.name,
                type: type,
                defaultValue: attribute.defaultValue,
                primaryKey: primaryKey,
                export: exportKind,
                exportable: exportable,
                importable: importable,
                transient: transient,
                optional: optional,
                originType: attribute.attributeType,
                calculated: calculated,
            };

            const name = attribute.name;

            EntityTypeBuilder.defineValuePropertyDescription(this.entityType, metadata);

            if (primaryKey) {
                hasPrimaryKey = true;
                // alias the primary key to "id"
                const primaryKeyProperty = this.entityType.metadata[name];
                EntityTypeBuilder.defineValuePropertyDescription(this.entityType, {
                    ...primaryKeyProperty,
                    name: "id",
                });

                // only export "id"
                this.entityType.metadata["id"].exportable = this.entityType.metadata[name].exportable;
                this.entityType.metadata[name].exportable = false;
            }
        });
    }

    public addRelations(entityTypes: EntityTypes): void {
        if (!this.entityConf.relations) {
            return;
        }

        this.entityConf.relations.forEach((relation) => {
            if (!convertBoolean(relation.model, true)) {
                return; // ignore non model relations
            }

            const owning = convertBoolean(relation.owning, false);
            const optional = convertBoolean(relation.optional, false);

            // integrity checks
            if (owning) {
                if (optional) {
                    logger.error(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is owning and optional`,
                    );
                }
                if (relation.deleteRule !== "nullify") {
                    logger.error(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is owning but has no 'nullify' delete rule`,
                    );
                }
                if (relation.max !== 1) {
                    logger.error(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is owning and not single`,
                    );
                }
                if (!relation.inverse) {
                    logger.error(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is owning and has no inverse`,
                    );
                } else if (relation.inverse.deleteRule !== "cascade") {
                    logger.error(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is owning and its inverse has no 'cascade' delete rule`,
                    );
                }
            } else {
                if (
                    !optional &&
                    relation.min === 1 &&
                    relation.max === 1 &&
                    relation.inverse &&
                    relation.inverse.deleteRule === "cascade"
                ) {
                    logger.warn(
                        `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is single, mandatory, it's inverse has a cascade delete rule but its not owning`,
                    );
                }
            }

            if (relation.min !== 0 && optional) {
                logger.warn(
                    `Relation from ${this.entityConf.name} to ${relation.destinationEntity} is optional but its min value is ${relation.min}`,
                );
            }
            if (relation.inverse && relation.calculated) {
                /**
                 * Calculated relations with inverse relation are not supported for now.
                 * This is because we don't find any use case for this.
                 * @link [AP-8150](https://bigsool-archipad.atlassian.net/browse/AP-8150)
                 */
                throw new ORMError(`Relation ${relation.name} is calculated and has an inverse`);
            }
            let inverseField: string | undefined;
            if (relation.inverse) {
                inverseField = relation.inverse.name;
            }
            EntityTypeBuilder.defineRelationDescription(
                this.entityType,
                entityTypes,
                relation,
                relation.destinationEntity,
                inverseField,
                false,
                owning,
            );

            // create the relation inverse
            if (relation.inverse) {
                const targetEntityName: string = relation.destinationEntity;
                const targetEntityType = entityTypes.byName[targetEntityName];

                EntityTypeBuilder.defineRelationDescription(
                    targetEntityType,
                    entityTypes,
                    relation.inverse,
                    this.entityType.entityName,
                    relation.name,
                    true,
                    false,
                );
            }
        });
    }

    private static defineValuePropertyDescription(entityType: EntityType, metadata: PropertyDescription): void {
        entityType.metadata[metadata.name] = metadata;
    }

    private static defineRelationDescription(
        entityType: EntityType,
        entityTypes: EntityTypes,
        relation: RelationEndpointConf,
        target: string,
        inverseField: string | undefined,
        isInverse: boolean,
        owning: boolean,
    ): void {
        const isSingle = relation.max == 1;

        const targetEntityType: EntityType = entityTypes.byName[target];
        if (!targetEntityType) {
            throw new ORMError(
                `missing target type "${target}" in relation "${relation.name}" in type "${entityType.entityName}"`,
            );
        }

        const exportKind = convertExportKind(relation.export, ExportKind.yes);
        const exportable = kExportKindExportableByValue[exportKind] && !isInverse;
        const importable = convertBoolean(relation.import, true) && !isInverse;
        const calculated = convertBoolean(relation.calculated, false);

        // create the relation property
        const metadata: RelationDescription = {
            relation: true,
            name: relation.name,
            optional: convertBoolean(relation.optional, false),
            export: exportKind,
            exportable: exportable,
            importable: importable,
            single: isSingle,
            target: targetEntityType,
            inverseField: inverseField,
            inverseEntity: entityType,
            isInverse: isInverse,
            deleteRule: relation.deleteRule,
            owning: owning,
            originType: "int64",
            calculated: calculated,
        };

        EntityTypeBuilder.defineValuePropertyDescription(entityType, metadata);
    }
}

class EntityTypeImpl implements EntityType {
    public metadata: EntityType["metadata"] = {};
    public import = false;
    public export = false;

    public get validationSchema(): EntityType["validationSchema"] {
        return makeValidationSchema(this.metadata);
    }

    constructor(public entityName: EntityType["entityName"]) {}

    public propertyForKeyPath(str: string): ReturnType<EntityType["propertyForKeyPath"]> {
        const description = this.keyPathDescription(str);
        if (!description) {
            return null;
        }
        for (let i = 0; i < description.length - 1; i++) {
            const property = description[i];
            if (property.relation && !property.single) {
                throw new ORMError(
                    `Dereferencing a multiple relationship '${property.name}' to '${property.target.entityName}'`,
                );
            }

            if (!property.relation) {
                throw new ORMError(`Dereferencing a non relationship '${property.name}'`);
            }
        }

        return description[description.length - 1];
    }

    public keyPathDescription(str: string): ReturnType<EntityType["keyPathDescription"]> {
        const path = str.split(".");
        const description = [];

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let targetType: EntityType = this;
        for (let i = 0; i < path.length; i++) {
            const property = targetType.metadata[path[i]];
            if (!property) {
                return null;
            }

            const isLastIndex = i === path.length - 1;

            if (!property.relation && !isLastIndex) {
                /**
                 * iterating on a non relational property while not being last index
                 * means that the given path attempt to find a property that does not exists.
                 *
                 * @example [relationA, id, text]
                 */
                return null;
            }

            if (property.relation) {
                targetType = property.target;
            }

            description.push(property);
        }

        return description;
    }
}
