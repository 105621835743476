import { getConfigService } from '@core/services/config';
import { SchemaManagerImpl } from '@core/services/orm/schema-manager';
import { SchemaManager } from '@core/services/orm/schema-manager';

import { getEntityClasses, getValidators } from '@archipad-models/orm-entity-classes';

/**
 * This should be reworked using DI in order to remove the dependency to orm-entity-classes
 * getEntityClasses should be replaced with @archipad-js/archipad-model/EntityClasses.ts
 * getValidators should be replaced with a static list of validators
 * Once this is done, `orm-entity-classes` (and thus webpack's `require.context`) could be removed
 */
export function getArchipadSchemaManager(): SchemaManager {
    return new SchemaManagerImpl(
        getEntityClasses(),
        getValidators(),
        getConfigService(),
    );
}
