import { fromEvent, isObservable, Observable } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { IllegalArgumentError } from "@archipad-js/core/error";
import { makeProgressHandler, ProgressHandler as Handler, ProgressObserver } from "@archipad-js/core/progress";

export function createAbortError() {
    const error = new Error("Aborted");
    error.name = "AbortError";
    return error;
}

export function isAbortError(error: unknown): error is Error {
    if (error == null) {
        return false;
    }

    // `instanceof Error` won't work if the error is thrown from a realm or a vm
    return (error as Error).name === "AbortError";
}

/**
 * Returns a `ProgressObserver` aimed to call reusable progressive async code
 * in methods that can't get a `ProgressObserver` from its caller.
 *
 * Should be used as a last resort.
 *
 * @example
 * export async function asyncMethod(): Promise<unknown> {
 *     const qwe = await otherAsyncMethod(voidProgress());
 *     return qwe;
 * }
 */
export function voidProgress(): ProgressObserver {
    const po: ProgressObserver = {
        /* eslint-disable @typescript-eslint/no-empty-function */
        next: () => {},
        error: () => {},
        complete: () => {},
        /* eslint-enable @typescript-eslint/no-empty-function */
    };
    return po;
}

/**
 * Returns an `AbortSignal` aimed to call reusable cancelable async code in
 * methods that can't get an `AbortSignal` from its caller.
 *
 * Should be used as a last resort.
 *
 * @example
 * export async function asyncMethod(): Promise<unknown> {
 *     const signal = voidAbortSignal();
 *     const qwe = await callAsAsync(signal, voidProgress(), (po) => otherAsyncMethod(signal, po));
 *     return qwe;
 * }
 */
export function voidAbortSignal(): AbortSignal {
    const abortController = new AbortController();
    const { signal } = abortController;
    return signal;
}

/**
 * Shortcut to retrieve a {@link Handler} based on an void {@link ProgressObserver}
 *
 * Useful in methods that can't get a {@link ProgressObserver} from their caller.
 */
export function voidProgressHandler(totalUnits = 1): Handler {
    const progress = voidProgress();
    const p = makeProgressHandler(progress);
    p.total(totalUnits);
    return p;
}

export function promiseToObservable<T>(func: (signal: AbortSignal) => Promise<T>): Observable<T> {
    return new Observable<T>((subscriber) => {
        const abortController = new AbortController();
        const { signal } = abortController;
        // This flag exists to make sure we don't `abort()` the promiseToObservable upon tearing down
        // this observable after emitting a Response. Aborting in such circumstances
        // would also abort subsequent methods - like `json()` - that could be called
        // on the Response. Consider: `promiseToObservable(...).pipe(take(1), mergeMap(res => res.json()))`
        let abortable = true;

        func(signal)
            .then((response) => {
                abortable = false;
                subscriber.next(response);
                subscriber.complete();
            })
            .catch((err) => {
                abortable = false;
                subscriber.error(err);
            });

        return () => {
            if (abortable) {
                abortController.abort();
            }
        };
    });
}

/**
 * Adapter to call a task like function
 *
 * @returns (outer function) abortable `Promise`
 * @param func (inner function) abortable `Promise` or `Observable`
 */
export async function callAsAsync<T>(
    signal: AbortSignal,
    p: Handler,
    units: number,
    func: (po: ProgressObserver | null) => Observable<T> | Promise<T>,
): Promise<T> {
    // before enter check
    if (signal.aborted) {
        throw createAbortError();
    }

    return await p.executeTask(units, async (po) => {
        const result = func(po);
        let resultAsPromise: Promise<T>;
        if (isObservable(result)) {
            resultAsPromise = observableToPromise(result, signal);
        } else {
            resultAsPromise = result;
        }
        const resultValue = await resultAsPromise;
        // before exit check
        if (signal.aborted) {
            throw createAbortError();
        }
        return resultValue;
    });
}

/**
 * Returns a Promise that resolves with the last emission of the given Observable,
 * rejects if the Observable errors or rejects with an `AbortError` when the AbortSignal is aborted.
 */
export function observableToPromise<T>(observable: Observable<T>, signal: AbortSignal): Promise<T> {
    if (!signal) {
        throw new IllegalArgumentError("Must be abortable. AbortSignal required");
    }

    return new Promise((resolve, reject) => {
        if (signal.aborted) {
            reject(createAbortError());
            return;
        }

        let value: T;

        const signalAbort$ = fromEvent(signal, "abort").pipe(
            tap(() => {
                reject(createAbortError());
            }),
        );

        observable.pipe(takeUntil(signalAbort$)).subscribe({
            next: (val) => {
                value = val;
            },
            error: (err) => {
                reject(err);
            },
            complete: () => {
                resolve(value);
            },
        });
    });
}
