import { Observable, of, throwError, defer, Observer } from "rxjs";
import { switchMap, map, finalize, catchError } from "rxjs/operators";

import * as S3 from "aws-sdk/clients/s3";

import { Data, StringData } from "@core/services/file/file";
import { S3Context, wrapS3Error } from "@core/services/file/s3file";

import { S3FetchRequest } from "../fileData";
import { UnexpectedError, NotImplementedError, IllegalStateError, chainError } from "@core/errors/errors-core";
import { URLFileDescriptor } from "@core/drivers/urlFile/browser/urlFileDriver";
import { makeProgressHandler, ProgressObserver } from "@core/tasks/progress";

/*---------------------------------------------------------------------------*/
function blobToString(blob:Blob, encoding:string): Observable<string> {
    return new Observable(function(observer) {
        let fileReader = new FileReader();
        fileReader.addEventListener('load', function() {
            observer.next(fileReader.result as string);
            observer.complete();
            fileReader = null;
        });
        fileReader.addEventListener('error', function() {
            observer.error(new UnexpectedError("BlobData.asString()"));
            if(fileReader) {
                fileReader.abort();
                fileReader = null;
            }
        });
        fileReader.readAsText(blob,encoding);
        return function() {
            if(fileReader) {
                fileReader.abort();
                fileReader = null;
            }
        };
    });
}

function downloadS3Body(progress: ProgressObserver | null, fetchRequest:S3FetchRequest): Observable<S3.Body> {
    const p = makeProgressHandler(progress);

    return fetchRequest().pipe(
        switchMap(function(downloadRequest) {
            let total = -1;
            downloadRequest.on('httpDownloadProgress', (progress) => {
                if(downloadRequest)
                    return;
                if(total === -1 && progress.total) {
                    total = progress.total;
                    p.total(progress.total);
                }
                if(total !== -1)
                    p.units(progress.loaded);
            });

            const upload$ = defer(async function() {
                const response = await downloadRequest.promise();
                return response.Body;
            })
            
            return upload$.pipe(
                catchError((err) => throwError(wrapS3Error(err, 'download'))),
                finalize(function() {
                    if(downloadRequest) {
                        downloadRequest.abort();
                        downloadRequest = null;
                    }
                })
            );
        })
    );
}

function uploadS3Body(progress: ProgressObserver | null, context:S3Context, key:string, body:S3.Body, mimeType:string): Observable<unknown> {
    const p = makeProgressHandler(progress);

    console.log(`UPLOAD ${context.bucket}/${key}`);
    let uploadRequest = context.endpoint.upload({ 
        Bucket:context.bucket, 
        Key: key, 
        Body:body, 
        ContentType:mimeType,
        ACL: context.acl
    });

    let total = -1;
    uploadRequest.on('httpUploadProgress', (progress) => {
        if(!uploadRequest)
            return;
        if(total === -1 && progress.total) {
            total = progress.total;
            p.total(total);
        }
        if(total !== -1)
            p.units(progress.loaded);
    });

    const upload$ = defer(function() {
        return uploadRequest.promise()
    })
    
    return upload$.pipe(
        catchError((err) => throwError(wrapS3Error(err, 'upload'))),
        finalize(function() {
            if(uploadRequest) {
                uploadRequest.abort();
                uploadRequest = null;
            }
        })
    );
}

/*---------------------------------------------------------------------------*/
Data.fromS3FetchRequest = function(request: S3FetchRequest, mimeType:string): Data {
    return new S3Data(request, mimeType);
}
Data.fromBlob = function(blob: Blob): Data {
    return new BlobData(blob);
}
Data.fromURL = function(descriptor: URLFileDescriptor): Data {
    return new URLData(descriptor.url);
}
Data.prototype.pipeToS3Upload = function(progress: ProgressObserver | null, context:S3Context, key:string): Observable<unknown> {
    return throwError(new NotImplementedError('pipeToS3Upload', `${this}`));
}
Data.prototype.asBlob = function(progress: ProgressObserver): Observable<Blob> {
    return throwError(new NotImplementedError('asBlob', `${this}`));
}

StringData.prototype.pipeToS3Upload = function(progress: ProgressObserver | null, context:S3Context, key:string): Observable<unknown> {
    return this.asString(null).pipe( // just convert to string
        switchMap((str) => {
            return uploadS3Body(progress, context, key, str, this.mimeType);    // upload to S3
        })
    );
}
StringData.prototype.asBlob = function(progress: ProgressObserver): Observable<Blob> {
    return this.asString(progress).pipe(
        map((str:string) => {
            return new Blob([ str ], { type:this.encoding ? `${this.mimeType};charset={this.encoding}` : this.mimeType });
        })
    );
}

/*---------------------------------------------------------------------------*/
class S3Data extends Data {
    private _request: S3FetchRequest;

    constructor(request: S3FetchRequest, mimeType:string) {
        super(mimeType);
        this._request = request;
    }

    asString(progress:ProgressObserver, encoding:string = "utf-8"): Observable<string> { 
        return this.asBlob(progress).pipe(
            switchMap((body) => blobToString(body, encoding))
        );
    }
    pipeToS3Upload(progress: ProgressObserver | null, context:S3Context, key:string): Observable<unknown> {
        const p  = makeProgressHandler(progress);
        p.total(2);
        return downloadS3Body(p.task(1), this._request).pipe(   // download body
            switchMap((body) => {
                return uploadS3Body(p.task(1), context, key, body, this.mimeType);  // upload body
            })
        );
    }
    asBlob(progress: ProgressObserver): Observable<Blob> {
        return downloadS3Body(progress, this._request).pipe(
            map((body) => {
                if(typeof body === 'string')
                    return new Blob([ body ], { type: this.mimeType });
                else if(ArrayBuffer.isView(body))
                    return new Blob([ body ], { type: this.mimeType });
                else if(body instanceof Blob)
                    return body;
                else
                    throw new Error('Unexpected S3 body format');
            })
        );
    }
}

/*---------------------------------------------------------------------------*/
class BlobData extends Data {
    private _blob: Blob;

    constructor(blob: Blob) {
        super(blob.type);
        this._blob = blob;
    }

    asString(progress:ProgressObserver, encoding:string = "utf-8"): Observable<string> { 
        return blobToString(this._blob, encoding);
    }
    pipeToS3Upload(progress: ProgressObserver | null, context:S3Context, key:string): Observable<unknown> {
        return uploadS3Body(progress, context, key, this._blob, this.mimeType);  // upload body
    }
    asBlob(progress: ProgressObserver): Observable<Blob> {
        return of(this._blob);
    }
}

class URLData extends Data {
    private _url: string;

    constructor(url: string, mimeType:string = 'binary') {
        super(mimeType);
        this._url = url;
    }

    private _makeError(underlyingError?: Error, status?: number, statusText?: string){
        const err = new IllegalStateError(`Unable to download url`);
        err.addContext('Archipad', { 'url': this._url });

        if(status){
            err.addContext('Archipad', { 'status': status });
        }
        if(statusText){
            err.addContext('Archipad', { 'statusText': statusText });
        }
        
        if(underlyingError){
            return chainError(underlyingError, err);
        }

        return err;
    }

    private _downloadXHR(progress: ProgressObserver, responseType: XMLHttpRequestResponseType): Observable<XMLHttpRequest> {
        const p = makeProgressHandler(progress);

        return new Observable<XMLHttpRequest>((observer:Observer<unknown>) => {
            let xhr: XMLHttpRequest = null;

            try {
                xhr = new XMLHttpRequest();
                xhr.open('GET', this._url, true);
    
                xhr.responseType = responseType;
    
                if (xhr.overrideMimeType) {
                    xhr.overrideMimeType("text/plain; charset=x-user-defined");
                }
    
                let lengthComputable = false;
                xhr.onprogress = function(evnt:ProgressEvent) {
                    if(!lengthComputable) {
                        if(evnt.lengthComputable) {
                            lengthComputable = true;
                            p.total(evnt.total);
                        }
                    }
                    if(lengthComputable)
                        p.units(evnt.loaded);
                };
    
                xhr.onerror = () => {
                    const error = this._makeError(null, xhr.status, xhr.statusText);

                    observer.error(error);
                    xhr = null;
                };
    
                xhr.onreadystatechange = () => {
                    if(!xhr)
                        return;
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 0) {
                            observer.next(xhr);
                            observer.complete();
                        } else {
                            observer.error(this._makeError(null, xhr.status, null));
                        }
                        xhr = null;
                    }
                };
    
                xhr.send();
            } catch (e) {
                let err: Error;
                if (e) {
                    err = this._makeError(e, null, null);
                } else {
                    err = this._makeError(null, null, null)
                }

                observer.error(err);
                xhr = null;
            }
    
            return function() {
                if(xhr)
                    xhr.abort();
            };
        });
    }

    asString(progress: ProgressObserver, encoding?: string): Observable<string> {
        return this._downloadXHR(progress, 'text').pipe(
            map(xhr => xhr.responseText),
        );
    }

    asBlob(progress: ProgressObserver): Observable<Blob> {
        return this._downloadXHR(progress, 'blob').pipe(
            map((xhr) => {
                return xhr.response as Blob;
            })
        );
    }
}

