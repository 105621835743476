import { ForbiddenError } from "@casl/ability";
import { AsyncContainerModule, interfaces } from "inversify";

import { ConfigOperator, Config_Operator_DIID, isBuiltInOperator, isCustomOperator } from "@archipad-js/core/config";
import { IllegalArgumentError, IllegalStateError } from "@archipad-js/core/error";
import { convertToString, DynNode, registerBuiltinFunction } from "@archipad-js/core/query";
import {
    InternationalizationService,
    Internationalization_Service_DIID,
    JsonTranslationFile,
    Locale_DIID,
    LocalizationService,
    Localization_Service_DIID,
    TranslationMap_DIID,
} from "@archipad-js/core/translate";
import { isRecord } from "@archipad-js/core/utils";

import _LegacyServiceManager from "@core/services/legacy-service-manager";

export function getLocalizationService(): LocalizationService {
    return _LegacyServiceManager.container.get(Localization_Service_DIID.symbol);
}

export function getInternationalizationService(): InternationalizationService {
    return _LegacyServiceManager.container.get(Internationalization_Service_DIID.symbol);
}

export function makeLegacyTranslateModule(locale: Intl.Locale): interfaces.AsyncContainerModule {
    return new AsyncContainerModule(
        async (bind, unbind, isBound, rebind, unbindAsync, onActivation, onDeactivation) => {
            // use require instead of import here to avoid issues when compiling to CommonJS
            const importedTradFile: JsonTranslationFile = require(`@localize/${locale.language}.json`);
            if (!importedTradFile?.data) {
                throw new IllegalStateError(`Missing translation file for locale ${locale}`);
            }

            const translations = importedTradFile.data;

            bind(Locale_DIID.symbol).toConstantValue(locale);
            bind(TranslationMap_DIID.symbol).toConstantValue(translations);

            onActivation(Localization_Service_DIID.symbol, (context, localizationService) => {
                globalThis.l = (...params) => {
                    return localizationService.getFormatted(...params).toString();
                };

                ForbiddenError.setDefaultMessage((error) =>
                    l('Cannot execute "{{action}}" on "{{subject}}".', {
                        action: error.action,
                        subject: error.subjectType,
                    }),
                );

                return localizationService;
            });

            onDeactivation(Localization_Service_DIID.symbol, () => {
                delete globalThis.l;
            });

            bind(Config_Operator_DIID.symbol).toDynamicValue((context) => {
                const localizationService = context.container.get(Localization_Service_DIID.symbol);
                const langOperator = new ConfigOperator("lang", (args) => {
                    if (!isCustomOperator(args) || !isRecord(args.variants)) {
                        throw new IllegalArgumentError(`Missing "variants" object parameter`);
                    }
                    return args.variants[localizationService.locale.language];
                });
                return langOperator;
            });

            bind(Config_Operator_DIID.symbol).toDynamicValue((context) => {
                const localizationService = context.container.get(Localization_Service_DIID.symbol);
                const locOperator = new ConfigOperator("loc", (args) => {
                    if (!isBuiltInOperator(args)) {
                        throw new IllegalArgumentError("Missing string to localize");
                    }
                    return localizationService.get(args.str);
                });
                return locOperator;
            });

            registerBuiltinFunction({
                name: "dateFormat",
                build: function (args) {
                    if (args.length !== 2) {
                        throw new IllegalArgumentError("Wrong number of arguments for dateFormat()");
                    }
                    if (args[0].type && args[0].type !== "date" && args[0].type !== "object") {
                        throw new IllegalArgumentError("Bad date argument for dateFormat()");
                    }
                    if (args[1].type && args[1].type !== "string" && args[0].type !== "object") {
                        throw new IllegalArgumentError("Bad format argument for dateFormat()");
                    }
                    const dateArg = args[0];
                    const formatArg = convertToString(args[1]);
                    return new DynNode("string", function (o) {
                        const internationalizationService = getInternationalizationService();

                        const date = dateArg.evaluate(o);
                        const format = formatArg.evaluate(o);
                        return internationalizationService.isDate(date)
                            ? internationalizationService.formatDate(date, format)
                            : "";
                    });
                },
            });
        },
    );
}
