import { interfaces } from "inversify";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { filter, map, shareReplay } from "rxjs/operators";

import { IllegalStateError } from "@archipad-js/core/error";

/**
 * A service manager handling a {@link interfaces.Container Container} that can be used to get services.
 * 
 * It is not the recommended way to use DI in `archipad-js/legacy`. It is only a temporary solution to mitigate the lack
 * of DI of the legacy code.
 * 
 * The recommended way to get services is to get those services injected in the service that needs them.
 */
class LegacyServiceManager {
    /**
     * Whether or not the {@link LegacyServiceManager} is ready to be used.
     */
    public isReady$: Observable<boolean>;

    private containerSub = new BehaviorSubject<interfaces.Container | undefined>(undefined);

    constructor() {
        this.isReady$ = this.containerSub.pipe(
            map((container) => Boolean(container)),
            shareReplay(),
        );
    }

    public get container(): interfaces.Container {
        const container = this.containerSub.getValue();
        if (container === undefined) {
            throw new IllegalStateError(`Illegal attempt to get the container before application load`);
        }

        return container;
    }

    public set container(container: interfaces.Container) {
        this.containerSub.next(container);
    }

    public async waitForReady(): Promise<void> {
        await firstValueFrom(
            this.isReady$.pipe(
                filter((isReady) => isReady),
            ),
        );
    }
}

export default new LegacyServiceManager();
