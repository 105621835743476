import { defer, Observable } from 'rxjs';
import { map, reduce, tap } from 'rxjs/operators';

import * as CryptoJS from 'crypto-js';
import 'crypto-js/sha1';

import { adaptOldTask, makeProgressHandler, ProgressObserver } from '@core/tasks/progress';
import { GeneratorType, task, call } from '@core/tasks/generator';
import { callAsAsync, callAsGenerator, callAsObservable } from '@core/tasks/asyncCaller';
import * as Cache from '@core/services/cache';
import * as Task from '@core/services/task';
import { FileService, FileDescriptor, Data, listAsArray } from '@core/services/file/file';

import {
    PatchAttachmentDescriptor,
    PatchDescriptor,
    PatchesDescriptor,
    PatchManifestDescriptor,
} from '@archipad/services/file/patchFile';

import { UpdateRemoteProjectInfo, RemoteProjectInfo, RemoteListProjectInfo, StorageInfo } from '@archipad/backend/archiweb/synchroBackend';
import * as synchroBackend from '@archipad/backend/archiweb/synchroBackend';
import { comparePatchId } from "@archipad/backend/project/patchHelper";

import * as PatchIO from '@archipad/backend/project/patch';

import { convertIdToHex16 } from '@core/helpers/entityId';

import "@archipad/services/file/file";
import { IllegalArgumentError } from '@core/errors/errors-core';
import _LegacyServiceManager from "@core/services/legacy-service-manager";

import { SynchroDriverError } from '@archipad/errors/errors-archipad';

import _DumpPatchService from '@archipad/backend/archiweb/dumpPatch.service';
import { createLogger } from '@core/services/logger.service';
import { getAccountService } from '@archipad/services/account.service';
import { Trusted_Time_Service_DIID } from '@archipad-js/archipad/archiweb-services-client';

const log = createLogger('synchroDriver');

/*---------------------------------------------------------------------------*/

/**
 * Archipad patch attachment serializer
 *
 * Same as TempAttachmentSerializer but reads and writes url in the current archipad patch format. 
 * 
 * Note: does not handle temp file descriptors
 */
export class ArchipadPatchAttachmentSerializer implements PatchIO.AttachmentSerializer {
    readonly patchDir: PatchDescriptor;

    constructor(projectId: string, patchId: string) {
        this.patchDir = new PatchDescriptor(new PatchesDescriptor(projectId, "patch"), patchId);
    }

    serializeAttachment(file: FileDescriptor, entityId: string, attachmentKey: string): PatchIO.SerializedJSONAttachment {
        if (!(file instanceof PatchAttachmentDescriptor)){
            throw new IllegalArgumentError(`Invalid attachment file`);
        }

        if (file.type !== "patch"){
            throw new IllegalArgumentError('Invalid file type');
        }

        const json: PatchIO.SerializedJSONAttachment = { url: `${file.entityId}/${file.attachmentPath}` };

        if (file.mimeType){
            json.mimeType = file.mimeType;
        }
        if (file.fileSize){
            json.size = file.fileSize;
        }
        if (file.hash){
            json.hash = file.hash;
        }

        return json;
    }

    deserializeAttachment(json: PatchIO.SerializedJSONAttachment, entityId: string, attachmentKey: string): PatchAttachmentDescriptor {
        if (!json || typeof json.url !== 'string'){
            throw new IllegalArgumentError(`Invalid attachment in manifest`);
        }

        const match = json.url.match(/^([0-9]+)\/(.+)$/);
        if (!match){
            throw new IllegalArgumentError(`Invalid patch url "${json.url}"`);
        }

        // patch url
        const file = new PatchAttachmentDescriptor(this.patchDir, match[1], match[2]);

        if (json.mimeType){
            file.mimeType = json.mimeType;
        }
        if (json.size){
            file.fileSize = json.size;
        }
        if (json.hash){
            file.hash = json.hash;
        }

        return file;
    }
}

/*---------------------------------------------------------------------------*/

export interface PatchInfo {
    patch: FileDescriptor;
    attachmentManifest: FileDescriptor;
}

interface PatchInfoByPatch {
    [patchId: string]: PatchInfo;
}

export interface PatchData extends PatchIO.NetPatch {
    patchId: string;
    patch: any,
    attachmentManifest: PatchIO.NetPatchAttachmentManifest
}

/*---------------------------------------------------------------------------*/

export interface TempPatch {
    patchSet: string[],
    patch: PatchData,
    synchroTime: number,
    hasUnflushedChanges: boolean,
    version: number
}

export enum TempPatchState {
    "IN_SYNC" = "TEMP_PATCH_IN_SYNC", 
    "PUSH" = "TEMP_PATCH_PUSH", 
    "DELETE" = "TEMP_PATCH_DELETE", 
    "FETCH" = "TEMP_PATCH_FETCH", 
    "CONFLICT" = "TEMP_PATCH_CONFLICT"
}

export interface TempPatchStatus {
    state: TempPatchState,
    rebase: boolean,
    tempPatch: TempPatch
}

interface InternalTempPatchStatus extends TempPatchStatus {
    reference: string,
    synchroTime,
    patchId: string,
    patchDescriptor,
    otherPatches: PatchInfo[]
}

/*---------------------------------------------------------------------------*/
const INITIAL_REFERENCE = "0000000000000001.abcdef00";

// const SIGNED_URL_EXPIRATION_TIME = 15*60;

export async function newPatchId(signal: AbortSignal, progress: ProgressObserver): Promise<string> {
    const p = makeProgressHandler(progress);
    p.total(1);

    const trustedTimeService =_LegacyServiceManager.container.get(Trusted_Time_Service_DIID.symbol);
    const str = PatchIO.newPatchId(trustedTimeService.trustedMicroTime);
    return str;
}

function* _persistTempPatch(progress: ProgressObserver, tempPatch: PatchDescriptor, changes: PatchIO.NetPatch): GeneratorType<PatchIO.NetPatch> {
    const p = makeProgressHandler(progress);
    p.total(100);

    const manifest = { tempManifest: true };

    // saving temp patch: write the attachment manifest as is - with attachment
    // urls that can reference either current patch files or files from previous
    // patches (renaming a plan, activating a new map version, any situation where
    // an existing resource is renamed ...)

    for (const entityId in changes.attachmentManifest){
        manifest[entityId] = changes.attachmentManifest[entityId];
    }

    const manifestFile = new PatchManifestDescriptor(tempPatch, "_manifest.json");
    const dataFile = new PatchManifestDescriptor(tempPatch, "data.json");

    // copy attachment manifest
    yield* call(FileService.upload(p.task(50), manifestFile, Data.fromJSON(manifest)));

    // send data
    yield* call(FileService.upload(p.task(50), dataFile, Data.fromJSON(changes.patch)));

    return changes;
}
function persistTempPatch(progress: ProgressObserver, tempPath: PatchDescriptor, changes): Observable<unknown> {
    return task(() => _persistTempPatch(progress, tempPath, changes));
}

function* _persistPatch(progress: ProgressObserver, patchPath: PatchDescriptor, inputSerializer: PatchIO.AttachmentSerializer, outputSerializer: PatchIO.AttachmentSerializer, changes: PatchIO.NetPatch): GeneratorType<PatchIO.NetPatch> {
    const p = makeProgressHandler(progress);

    const manifest = {};
    let manifestHasContent = false;

    const copyTasks: Observable<unknown>[] = [];

    // committing the patch to its final location.
    // convert the manifest to archipad's format
    for (const entityId in changes.attachmentManifest) {
        manifestHasContent = true;
        manifest[entityId] = {};

        const entityAttachments = changes.attachmentManifest[entityId];
        for (const attachmentKey in entityAttachments) {
            const attachment = entityAttachments[attachmentKey];
            manifest[entityId][attachmentKey] = {};
            if (attachment.deleted) {
                // delete
                manifest[entityId][attachmentKey][attachmentKey] = '_removed';
            } else {
                // create or update, copy the attachment in place
                const sourceFile = inputSerializer.deserializeAttachment(attachment, entityId, attachmentKey);

                // HACK: extract the templateEntityId from the attachment url and put it in the destination 
                // attachment key, this is used a a marker to determine whether the attachment is from a project template
                // or not.
                const match = attachment.url.match(/^[^\/]+\/([a-zA-Z0-9]{32}|_ROOT_)\/(\$\$\$([a-zA-Z0-9]{32}|_ROOT_)\$\$\$.+)$/);
                let destinationFile: FileDescriptor;
                if (match && match[3]){
                    destinationFile = new PatchAttachmentDescriptor(patchPath, entityId, `$$$${match[3]}$$$${attachmentKey}`);
                } else {
                    destinationFile = new PatchAttachmentDescriptor(patchPath, entityId, attachmentKey);
                }

                // update the manifest and copy the attachment
                manifest[entityId][attachmentKey][attachmentKey] = outputSerializer.serializeAttachment(destinationFile, entityId, attachmentKey).url;
                copyTasks.push(FileService.copy(p.task(1), sourceFile, destinationFile));
            }
        }
    }

    let total = copyTasks.length + 1;
    if (manifestHasContent){
        total++;
    }
    p.total(total);

    // copy attachments first
    yield* call(FileService.execute(copyTasks));

    const manifestPath = new PatchManifestDescriptor(patchPath, '_manifest.json');
    const dataPath = new PatchManifestDescriptor(patchPath, 'data.json');

    if (manifestHasContent) {
        // copy attachment manifest
        yield* call(FileService.upload(p.task(1), manifestPath, Data.fromJSON(manifest)));
    }

    // send patch data
    yield* call(FileService.upload(p.task(1), dataPath, Data.fromJSON(changes.patch)));

    return changes;
}
export function persistPatch(progress: ProgressObserver, patchPath: PatchDescriptor, inputSerializer: PatchIO.AttachmentSerializer, outputSerializer: PatchIO.AttachmentSerializer, changes: PatchIO.NetPatch): Observable<PatchIO.NetPatch> {
    return task(() => _persistPatch(progress, patchPath, inputSerializer, outputSerializer, changes));
}

function* _loadPatches(progress: ProgressObserver, projectId: string, patchesToLoad: PatchInfoByPatch): GeneratorType<PatchData[] | null> {
    const p = makeProgressHandler(progress);

    let filesToLoad = 0;

    // compute size to download
    for (const patchId in patchesToLoad) {
        const patch = patchesToLoad[patchId];
        filesToLoad++;
        if (patch.attachmentManifest){
            filesToLoad++;
        }
    }

    if (filesToLoad === 0) {
        // nothing to load
        return null;
    }

    const loadedPatches: { [patchId: string]: PatchData } = {};

    p.total(filesToLoad);

    // download the manifests
    const downloadTasks: Observable<unknown>[] = [];
    for (const patchId in patchesToLoad) {
        const patchToLoad = patchesToLoad[patchId];
        const patchData = loadedPatches[patchId] = { patchId: patchId, patch: null, attachmentManifest: null };

        // download data.json
        downloadTasks.push(
            FileService.download(patchToLoad.patch).asJSON(p.task(1)).pipe(
                map(function (json) { patchData.patch = json; }),
            ),
        );

        // download _manifest.json
        if (patchToLoad.attachmentManifest) {
            downloadTasks.push(
                FileService.download(patchToLoad.attachmentManifest).asJSON(p.task(1)).pipe(
                    map(function (attachments: any) {
                        const attachmentManifest = {}
                        const inputSerializer = new ArchipadPatchAttachmentSerializer(projectId, patchId);
                        const ouputSerializer = new PatchIO.TempPatchAttachmentSerializer(projectId);

                        // convert from archipad to internal manifest formmat
                        for (const entityId in attachments) {
                            const entityAttachments = attachments[entityId];
                            attachmentManifest[entityId] = {};
                            for (const attachmentKey in entityAttachments) {
                                const entityAttachment = entityAttachments[attachmentKey];
                                if (entityAttachment[attachmentKey] && entityAttachment[attachmentKey] == '_removed') {
                                    attachmentManifest[entityId][attachmentKey] = { deleted: true };
                                } else if (entityAttachments[attachmentKey][attachmentKey]) {
                                    const attachment = { url: entityAttachments[attachmentKey][attachmentKey] };

                                    // deserialize patch file from archipad format
                                    const file = inputSerializer.deserializeAttachment(attachment, entityId, attachmentKey);
                                    // serialize patch file in temp patch form
                                    attachmentManifest[entityId][attachmentKey] = ouputSerializer.serializeAttachment(file, entityId, attachmentKey);
                                }
                            }
                        }

                        patchData.attachmentManifest = attachmentManifest;
                    }),
                ),
            );
        }
    }

    yield* call(FileService.execute(downloadTasks));

    // return the patch data in chronological order
    const patchesData: PatchData[] = [];
    for (const patchId in loadedPatches){
        patchesData.push(loadedPatches[patchId]);
    }
    patchesData.sort((p1, p2) => {
        return comparePatchId(p1.patchId,p2.patchId)
    });

    return patchesData;
}

function* _loadTempPatch(progress: ProgressObserver, tempPatchId: string, tempPatchInfo: PatchInfo): GeneratorType<PatchData> {
    const p = makeProgressHandler(progress);

    let filesToLoad = 1;
    if (tempPatchInfo.attachmentManifest){
        filesToLoad++;
    }

    p.total(filesToLoad);

    const tempPatchData: PatchData = { patchId: tempPatchId, patch: null, attachmentManifest: null };

    // download the manifests
    const downloadTasks: Observable<unknown>[] = [];

    // download data.json
    downloadTasks.push(
        FileService.download(tempPatchInfo.patch).asJSON(p.task(1)).pipe(
            map(function (json: PatchIO.NetPatchData) { tempPatchData.patch = json; }),
        ),
    );

    // download _manifest.json
    if (tempPatchInfo.attachmentManifest) {
        downloadTasks.push(
            FileService.download(tempPatchInfo.attachmentManifest).asJSON(p.task(1)).pipe(
                map(function (json: PatchIO.NetPatchAttachmentManifest) { tempPatchData.attachmentManifest = json; }),
            ),
        );
    }

    yield* call(FileService.execute(downloadTasks));

    return tempPatchData;
}

async function _listPatchesAtPath(signal: AbortSignal, progress: ProgressObserver, patchesPath: PatchesDescriptor, patchesToIgnore?: ReadonlySet<string>): Promise<PatchInfoByPatch> {
        const p = makeProgressHandler(progress);
        p.total(1);
        const files: FileDescriptor[] = await callAsAsync( signal, p, 1, (po) => listAsArray(FileService.list(po, patchesPath)));

        // list the project manifest files
        const patchesToLoad: PatchInfoByPatch = {};
        for (const file of files){
            if (!(file instanceof PatchManifestDescriptor)){
                continue;
            }
            const patchId = file.patch.patchId;

            if (patchesToIgnore && patchesToIgnore.has(patchId)){
                continue;
            }

            let patch = patchesToLoad[patchId];
            if (!patch){
                patchesToLoad[patchId] = patch = { patch: null, attachmentManifest: null };
            }

            if (file.path === 'data.json'){
                patch.patch = file;
            } else if (file.path === '_manifest.json'){
                patch.attachmentManifest = file;
            }
        }

        //filtre file without patch fileDescriptor
        for (const patchId in patchesToLoad) {
            if (!patchesToLoad[patchId].patch){
                delete patchesToLoad[patchId];
            }
        }
        
        return patchesToLoad;
}

function organizeTempPatches(patchesToLoad: PatchInfoByPatch) {
    const patches = Object.keys(patchesToLoad);
    const patchesByReference = {};

    patches.forEach(function (patchId) {
        const p = patchId.split('-');
        const reference = p[0];
        const hash = p[1];
        const time = p[2];
        if (!patchesByReference[reference]){
            patchesByReference[reference] = { hashes: {}, times: {} };
        }

        patchesByReference[reference].hashes[hash] = true;
        patchesByReference[reference].times[time] = patchId;
    });

    return patchesByReference;
}

function getPatchSetHash(patchSet) {
    const p = [].concat(patchSet);
    p.sort();
    const hash = CryptoJS.SHA1(p.join()).toString(CryptoJS.enc.Hex);
    return hash;
}

function getTempPatchReference(tempPatch: TempPatch): string {
    let reference = null;
    // TODO more tests, was part of changes : commit 003fe4fe, refactored synchro to handle interupted project import and patches in past
    //if ( tempPatch && tempPatch.patch ) {
    if (tempPatch && (tempPatch.patch || tempPatch.hasUnflushedChanges)) {
        reference = [].concat(tempPatch.patchSet).pop() || INITIAL_REFERENCE;
    }
    return reference;
}

function _getLatestSynchroTimeForReference(ref: string, patchesByReference: unknown) {
    if (!ref){
        return undefined;
    }

    const times = Object.keys(patchesByReference[ref].times);
    times.sort();
    const latestTime = times.pop();
    return latestTime;
}

function* _getTempPatchStatus(progress: ProgressObserver, tempDir: PatchesDescriptor, tempPatch): GeneratorType<InternalTempPatchStatus> {
    const reference = getTempPatchReference(tempPatch);
    const p = makeProgressHandler(progress);
    p.total(1);
    const patchesToLoad = yield* callAsGenerator(p,1,(si, po) => _listPatchesAtPath(si, po, tempDir));
    const patchesByReference = organizeTempPatches(patchesToLoad);

    log.debug("getting temp patch status for temp patch", tempPatch, patchesByReference);

    const references = Object.keys(patchesByReference);
    references.sort();

    const status: InternalTempPatchStatus = {
        reference: null,
        synchroTime: null,
        state: TempPatchState.IN_SYNC,
        rebase: false,
        patchId: null,
        patchDescriptor: null,
        otherPatches: [],
        tempPatch: null,
    };

    const latestReference = references.pop();

    if (reference) {
        const tempPatchHash = getPatchSetHash(tempPatch.patchSet);

        // if we asked for a reference, make sure the patch exists or mark as rebase needed
        if (!patchesByReference[reference] || latestReference != reference) {
            if (tempPatch.synchroTime) {
                // we had a synchro time so patch was supposed to be there
                log.warn("Unable to find temp patch for reference" + reference);
                _markAsConflict(status, latestReference, patchesByReference);
            } else {
                // we didn't have a synchroTime so it's a push
                status.reference = reference;
                if (tempPatch.patch) {
                    status.state = TempPatchState.PUSH;
                } else {
                    status.state = TempPatchState.DELETE;
                }
            }
        } else {
            // we have the good reference, check if we have the right patchSetHash
            const hashes = Object.keys(patchesByReference[reference].hashes);
            if (hashes.indexOf(tempPatchHash) === -1) {
                // we didn't found our patches set
                log.warn("Unable to find temp patch for patch set" + tempPatchHash + ' with ref ' + reference);
                _markAsConflict(status, latestReference, patchesByReference);
            } else if (hashes.length > 1) {
                // multiple patch sets found
                log.warn("Multiple patch sets found  with ref " + reference);
                _markAsConflict(status, latestReference, patchesByReference);
            } else {
                // we have the good reference, check we have the right time/version
                status.reference = reference;
                status.synchroTime = _getLatestSynchroTimeForReference(reference, patchesByReference);

                const patchSetHash = patchesByReference[status.reference].times[status.synchroTime].split('-')[1];
                if (patchSetHash != tempPatchHash){
                    throw new SynchroDriverError("Programming error, remote patch hash is not current hash after asserts");
                }

                const times = Object.keys(patchesByReference[reference].times);

                if (tempPatch.synchroTime) {
                    if (times.indexOf(tempPatch.synchroTime + "") == -1) {
                        // fetched patch time not found in existing patches.
                        // was cleared by someone else
                        if (tempPatch.synchroTime > status.synchroTime) {
                            log.warn("Conflict : Reference " + reference + " exists but synchroTime " + tempPatch.synchroTime + " not found.");
                            status.state = TempPatchState.CONFLICT;
                        } else {
                            if (tempPatch.patch && tempPatch.hasUnflushedChanges) {
                                // we have unflushed changes, this is a conflict
                                log.warn("Can't find patch for time, found a newer but having unflushed changes. Conflict");
                                status.state = TempPatchState.CONFLICT;
                            } else {
                                log.info("Can't find patch for time but found a newer : fetch it");
                                status.state = TempPatchState.FETCH;
                            }
                        }
                    } else if (times.length > 1) {
                        // multiple patch time founds in existing patches
                        // concurrent temp patch save
                        log.warn("Conflict : Reference " + reference + " exists but multiple patch times. Concurrent temp changes detected.");
                        status.state = TempPatchState.CONFLICT;
                    } else if (status.synchroTime == tempPatch.synchroTime) {
                        // we found our temp patch
                        if (tempPatch.hasUnflushedChanges) {
                            if (tempPatch.patch) {
                                status.state = TempPatchState.PUSH;
                            } 
                            else {
                                status.state = TempPatchState.DELETE;
                            }
                        }
                    }
                // make sure there are no patches remotely
                } else if (tempPatch.hasUnflushedChanges && times.length) {
                    log.warn("Patches found remotely, local unflushed changes but no local synchro time. Conflict");
                    status.state = TempPatchState.CONFLICT;
                }
            }
        }
    // if we didn't ask for a specific reference, take the latest one
    } else if (latestReference) {
        status.reference = latestReference;
        status.synchroTime = _getLatestSynchroTimeForReference(latestReference, patchesByReference);
        status.state = TempPatchState.FETCH;
    }

    if (status.reference && status.synchroTime) {
        status.patchId = patchesByReference[status.reference].times[status.synchroTime];
        status.patchDescriptor = patchesToLoad[status.patchId];
        status.otherPatches = [];

        for(const time of Object.keys(patchesByReference[status.reference].times)){
            const patchId = patchesByReference[status.reference].times[time];
            if (status.patchId == patchId){ continue; }

            status.otherPatches.push(patchesToLoad[patchId]);
        }
    }

    if (status.reference == INITIAL_REFERENCE){
        status.reference = null;
    }

    return status;
}

function _markAsConflict(status: InternalTempPatchStatus, latestReference: string, patchesByReference: unknown) {
    status.reference = latestReference;
    status.synchroTime = _getLatestSynchroTimeForReference(latestReference, patchesByReference);
    status.state = TempPatchState.CONFLICT;
    status.rebase = true;
}

function sanitizeStatus(status: InternalTempPatchStatus): TempPatchStatus {
    const s = {
        state: status.state,
        rebase: status.rebase,
        tempPatch: null,
    };

    if (status.tempPatch !== undefined){
        s.tempPatch = status.tempPatch;
    }

    return s;
}

function* _removeTempPatches(progress: ProgressObserver, tempDirectory: PatchesDescriptor, patches: PatchInfo[]): GeneratorType<void> {
    const filesToDelete: FileDescriptor[] = [];
    patches.forEach(function (patchDescriptor) {
        filesToDelete.push(patchDescriptor.patch);
        if (patchDescriptor.attachmentManifest){
            filesToDelete.push(patchDescriptor.attachmentManifest);
        }
    });

    const p = makeProgressHandler(progress);
    p.total(filesToDelete.length);

    const deleteTasks: Observable<unknown>[] = [];
    for (const fileToDelete of filesToDelete){
        deleteTasks.push(FileService.delete(p.task(1), fileToDelete));
    }

    yield * call(FileService.execute(deleteTasks));
}

const projectStorageInfoCache = new Map<string, Cache.Request<RemoteProjectInfo>>();

export function getProjectInfo(progress: ProgressObserver, projectId: string): Observable<RemoteProjectInfo> {
    let entry = projectStorageInfoCache.get(projectId);
    if (!entry) {
        entry = new Cache.Request<RemoteProjectInfo>( getAccountService().currentAccount$, function (currentAccountInfo) {
            if (!currentAccountInfo){
                return null;
            }

            /*return synchroBackend.isProjectSyncAllowed(task, projectId).then(function(ret) {
				return {
					credentials: ret.data.credentials,
					patches: {
						bucket: ret.data.url,
						region: ret.data.region,
						path: ret.data.path
					},
					temp: {
						bucket: ret.data.url,
						region: ret.data.region,
						path: ret.data.tempPath
					},
					upload: ret.data.upload

				}
            });*/
            const p = makeProgressHandler(null);
            return p.oldTask(0, null, async (task) => {
                const ret = await synchroBackend.getProjectInfo(task, {id: projectId})
                /**
                 * BIG HACK to fix the upload path missing hexadecimal projectId subfolder.
                 *
                 * @todo remove that uglyness and fix the upload path on API side?
                 */
                const hexProjectId = convertIdToHex16(projectId);
                ret.upload.path = `${ret.upload.path}/${hexProjectId}/`;

                return ret;
            });
        });
        projectStorageInfoCache.set(projectId, entry);
    }

    return defer(() => {
        const p = makeProgressHandler(progress);
        p.total(1);
        return entry.fetch().pipe(
            tap(() => { p.units(1); }),
        );
    });
}

// clear the cache if the user info changes
_LegacyServiceManager.waitForReady().then(() => {
    getAccountService().currentUserInformation$.subscribe(() => {
        projectStorageInfoCache.clear();
    });
    getAccountService().currentAccount$.subscribe(() => {});
}).catch(() => {});

export const CURRENT_PATCH = "current";

export function getProjects(task: Task.Task): Promise<readonly RemoteListProjectInfo[]> {
    return synchroBackend.listProjects(task);
}

export function getTemplateStorageInfo(task: Task.Task, projectId: string, templateId?: string): Promise<RemoteProjectInfo> {
    return synchroBackend.getProjectInfo(task, {id: projectId, templateId});
}

export function deleteProjects(task: Task.Task, ids: string[]): Promise<unknown> {
    // todo : clean local paths ?
    return synchroBackend.deleteProjects(task, ids);
}

export function registerProject(task: Task.Task, projectInfo: { id?: string, name?: string }): Promise<unknown> {
    return synchroBackend.addProject(task, projectInfo.id, projectInfo.name);
}

export async function getProjectPatchesStorageInfo(signal: AbortSignal, progress: ProgressObserver, projectId: string): Promise<StorageInfo> {
    const p = makeProgressHandler(progress);
    p.total(2);

    const trustedTimeService =_LegacyServiceManager.container.get(Trusted_Time_Service_DIID.symbol);
    const projectInfo = await callAsAsync(signal, p, 1, (po) => getProjectInfo(po, projectId));

    const result: StorageInfo = {
        id: projectId,
        getSynchronizedTime: () => { return trustedTimeService.trustedTime },
        credentials: projectInfo.credentials,
        creds: projectInfo.credentials,
        bucket: projectInfo.patches.bucket,
        region: projectInfo.patches.region,
        path: projectInfo.patches.path,
        upload: projectInfo.upload,
        projectCaches: projectInfo.projectCaches,
    };
    return result;
}

export async function getProjectTempStorageInfo(task: Task.Task, projectId: string): Promise<StorageInfo> {
    const trustedTimeService =_LegacyServiceManager.container.get(Trusted_Time_Service_DIID.symbol);
    const projectInfo = await adaptOldTask(task, (progress) => getProjectInfo(progress, projectId));

    const result = {
        id: projectId,
        getSynchronizedTime: () => { return trustedTimeService.trustedTime },
        credentials: projectInfo.credentials,
        creds: projectInfo.credentials,
        bucket: projectInfo.temporary.bucket,
        region: projectInfo.temporary.region,
        path: projectInfo.temporary.path,
        upload: projectInfo.upload,
        creatorId: projectInfo.creatorId,
        projectCaches: projectInfo.projectCaches,
    };
    return result;
}

export function listProjectPatches(progress: ProgressObserver, patchesDir: PatchesDescriptor): Observable<string[]> {
    const p = makeProgressHandler(progress);
    p.total(1);
    return callAsObservable( p, 1, async ( signal, progress ) => {
        const patchesToLoad = await _listPatchesAtPath( signal, progress, patchesDir );
        const patches = Object.keys( patchesToLoad );
        return patches;
       },
   );
}

function* _loadPatchesDataFromDumpPatch(progress: ProgressObserver, projectId: string, patchesToLoad: PatchInfoByPatch): GeneratorType<PatchData[] | null>{
    const p = makeProgressHandler(progress);
    p.total(100);

    const dumpPatchContext = yield* callAsGenerator( p, 25, ( si, po ) => _DumpPatchService.getDumpPatchContext(si, po, projectId));

    const patchesId = Object.keys(patchesToLoad);
    if ( !_DumpPatchService.isADumpPatchValid( dumpPatchContext, patchesId ) ){
        return null;
    }

    const patchesData = yield* callAsGenerator( p, 50, ( si, po ) => _DumpPatchService.loadPatchesDataFromDumpPatch(si, po, dumpPatchContext));

    const patchesInfoNotIncludeInDumpPatch: PatchInfoByPatch = {};
    for (const patchId of patchesId.filter(value => !dumpPatchContext.dumpPatchSet.patchSet.includes(value))){
        patchesInfoNotIncludeInDumpPatch[patchId] = patchesToLoad[patchId];
    }

    //Merge with patch not include in patchData
    const unflattenedPatchData = yield* _loadPatches(p.task(75), projectId, patchesInfoNotIncludeInDumpPatch);
    if (unflattenedPatchData){
        patchesData.push(...unflattenedPatchData);
    }
    return patchesData;
}

function* _loadProjectPatches(
    progress: ProgressObserver,
    projectId: string,
    patchesDir: PatchesDescriptor,
    patchesToIgnore: ReadonlySet<string>,
    requestedPatches: string[],
    onPatchLoaded:  (progress: ProgressObserver,patchesData: PatchData[]) => Observable<unknown>,
    useDumpPatchMechanism: boolean,
): GeneratorType<string[]> {
    const p = makeProgressHandler(progress);
    p.total(100);

    const patchesToLoad = yield* callAsGenerator(p,20,(si, po) => _listPatchesAtPath(si, po, patchesDir, patchesToIgnore));

    const patches = Object.keys(patchesToLoad);
    patches.sort(comparePatchId);

    const ignoredPatches = [];
    for (let patchIndex = 0; patchIndex < patches.length; patchIndex++) {
        // we did not request that patch
        if (requestedPatches && requestedPatches.indexOf(patches[patchIndex]) == -1) {
            ignoredPatches.push(patches[patchIndex]);
            delete patchesToLoad[patches[patchIndex]];
        }
    }
    let patchesData = undefined;
    if (useDumpPatchMechanism && !patchesToIgnore.size){
        patchesData = yield* _loadPatchesDataFromDumpPatch(p.task(10), projectId, patchesToLoad);
    }
    if (!patchesData){
        patchesData = yield* _loadPatches(p.task(70), projectId, patchesToLoad);
    }
    if (patchesData) {
        yield* call(onPatchLoaded(p.task(70),patchesData));
    }
    return ignoredPatches
}

export function loadProjectPatches(
    progress: ProgressObserver,
    projectId: string,
    patchesDir: PatchesDescriptor,
    patchesToIgnore: ReadonlySet<string>,
    requestedPatches: string[],
    afterPatchesDownload: (progress: ProgressObserver,patchesData: PatchData[]) => Observable<unknown>,
    useDumpPatchMechanism: boolean,
): Observable<string[]> {
    return task(() => _loadProjectPatches(progress, projectId, patchesDir, patchesToIgnore, requestedPatches, afterPatchesDownload,useDumpPatchMechanism));
}

function* _saveProjectPatch(progress: ProgressObserver, projectInfo: UpdateRemoteProjectInfo, inputSerializer: PatchIO.AttachmentSerializer, patchchanges: PatchIO.NetPatch): GeneratorType<{ id: string, patch: PatchIO.NetPatch }> {
    const p = makeProgressHandler(progress);
    p.total(100);

    if(!projectInfo.id){
        throw new IllegalArgumentError(`Invalid project info`);
    }

    // generate a new patch id
    const patchId = yield* callAsGenerator(p, 1, (signal, po) => newPatchId(signal, po));

    // persist the patch
    const outputSerializer = new ArchipadPatchAttachmentSerializer(projectInfo.id, patchId);
    yield* call(persistPatch(p.task(94), outputSerializer.patchDir, inputSerializer, outputSerializer, patchchanges));

    // call backend to update project info
    try {
        yield* callAsGenerator(p, 5, (signal, po) => synchroBackend.updateProject(signal, po, projectInfo, patchId));
    }
    catch(err) {
        // NOTE: ignoring errors
        log.warn("ignoring error", err);
    }

    return {
        id: patchId,
        patch: patchchanges,
    };
}
export function saveProjectPatch(progress: ProgressObserver, projectInfo: UpdateRemoteProjectInfo, inputSerializer: PatchIO.AttachmentSerializer, patchChanges: PatchIO.NetPatch): Observable<{
    id: string;
    patch: PatchIO.NetPatch;
}> {
    return task(() => _saveProjectPatch(progress, projectInfo, inputSerializer, patchChanges));
}

export function getTempPatchStatus(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch): Observable<TempPatchStatus> {
    return task(() => _getTempPatchStatus(progress, tempDirectory, tempPatch)).pipe(
        map(function (status) {
            return sanitizeStatus(status);
        }),
    );
}

function* _getLatestTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch): GeneratorType<TempPatchStatus> {
    const p = makeProgressHandler(progress);
    p.total(100);

    if (tempPatch) {
        const reference = [].concat(tempPatch.patchSet).pop() || null;
        log.debug("loading user patch for ref " + reference + " and synchroTime " + tempPatch.synchroTime);
    } else {
        log.debug("loading user patch");
    }

    const status = yield* _getTempPatchStatus(p.task(5), tempDirectory, tempPatch);

    if (status.state == TempPatchState.IN_SYNC || status.state == TempPatchState.PUSH || status.state == TempPatchState.DELETE) {
        if (tempPatch) {
            log.debug("Using local temp patch");
            status.tempPatch = tempPatch;
            status.tempPatch.synchroTime = status.synchroTime;
        } else {
            log.debug("Using local null temp patch");
            status.tempPatch = null;
        }
        return status;
    }

    if (status.state == TempPatchState.CONFLICT && status.rebase) {
        status.tempPatch = null;
        log.debug("rebase needed, not using any temp patch");
        return status;
    }

    if (!status.patchId || !status.patchDescriptor) {
        throw new IllegalArgumentError("Cant fetch temp patch without patchId/patchDescriptor");
    }

    const tempPatchToLoad = {};
    tempPatchToLoad[status.patchId] = status.patchDescriptor;

    const patchData = yield* _loadTempPatch(p.task(95), status.patchId, status.patchDescriptor);
    if (patchData) {
        status.tempPatch = {
            patchSet: patchData.patch.patchSet,
            patch: { patchId: patchData.patchId, patch: patchData.patch.patch, attachmentManifest: patchData.attachmentManifest },
            synchroTime: status.synchroTime,
            hasUnflushedChanges: false,
            version: patchData.patch.version,
        };
        delete patchData.patch.version;
        delete patchData.patch.patchSet;

        log.debug("loaded user patch for ref " + status.reference + " with time " + status.synchroTime);
    }

    return sanitizeStatus(status);
}

export function getLatestTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch): Observable<TempPatchStatus> {
    return task(() => _getLatestTempPatch(progress, tempDirectory, tempPatch));
}


function* _clearTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch): GeneratorType<TempPatchStatus> {
    const p = makeProgressHandler(progress);
    p.total(100);

    if (!(tempPatch && tempPatch.patch == null && tempPatch.patchSet && tempPatch.version)) {
        throw new IllegalArgumentError("Invalid temp patch to clear");
    }

    const status = yield* _getTempPatchStatus(p.task(50), tempDirectory, tempPatch);

    if (status.rebase) {
        const error = new SynchroDriverError("Can't resolve conflict since rebase is needed");
        error['status'] = sanitizeStatus(status);
        throw error;
    }

    if (status.state != TempPatchState.DELETE) {
        // can't do it, sorry
        const error = new SynchroDriverError("Can't clear temp patches because of state is not PUSH but " + status.state);
        error['status'] = sanitizeStatus(status);
        throw error;
    }

    if (status && status.patchId) {
        // add previous patch to list of otherPatches
        const patchesToDelete = status.otherPatches.slice(0);
        patchesToDelete.push(status.patchDescriptor);

        yield* _removeTempPatches(p.task(50), tempDirectory, patchesToDelete);
    }

    log.debug("cleared user patch");
    tempPatch.synchroTime = null;
    tempPatch.hasUnflushedChanges = false;

    return {
        state: TempPatchState.IN_SYNC,
        rebase: false,
        tempPatch: tempPatch,
    } as TempPatchStatus;
}

export function clearTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch): Observable<TempPatchStatus> {
    return task(() => _clearTempPatch(progress, tempDirectory, tempPatch));
}

function _getSynchronizedMicroTime(): Promise<number> {
    const trustedTimeService =_LegacyServiceManager.container.get(Trusted_Time_Service_DIID.symbol);
    return new Promise<number>((resolve) => {  
        setTimeout(() => resolve(trustedTimeService.trustedMicroTime), 1);  
    });
}

function* _saveTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch, resolvingConflict: boolean): GeneratorType<TempPatchStatus> {
    const p = makeProgressHandler(progress);
    p.total(100);

    if (!(tempPatch && tempPatch.patch && tempPatch.patchSet && tempPatch.version)) {
        throw new IllegalArgumentError("Invalid temp patch to save");
    }

    const reference = getTempPatchReference(tempPatch);

    log.debug("saving user patch with ref " + reference + ', time ' + tempPatch.synchroTime);

    const status = yield* _getTempPatchStatus(p.task(20), tempDirectory, tempPatch);

    if (status.rebase) {
        const error = new SynchroDriverError("Can't resolve conflict since rebase is needed");
        error['status'] = sanitizeStatus(status);
        throw error;
    }

    if (resolvingConflict && status.state != TempPatchState.CONFLICT) {
        const error = new SynchroDriverError("Can't resolve conflict when no conflict");
        error['status'] = sanitizeStatus(status);
        throw error;
    }

    if (status.state != TempPatchState.PUSH && !(status.state == TempPatchState.CONFLICT && resolvingConflict)) {
        // can't do it, sorry
        const error = new SynchroDriverError("Can't save temp patches because of state is not PUSH but " + status.state);
        error['status'] = sanitizeStatus(status);
        throw error;
    }

    const newSynchroMicroTime = yield* callAsGenerator(p, 5, () => _getSynchronizedMicroTime());
    const tempPrefix = `${reference}-${getPatchSetHash(tempPatch.patchSet)}-${newSynchroMicroTime}`;

    const patch = {
        attachmentManifest: tempPatch.patch.attachmentManifest,
        patch: {
            patchSet: tempPatch.patchSet,
            version: tempPatch.version,
            patch: tempPatch.patch.patch,
        },
    };

    yield* call(persistTempPatch(p.task(65), new PatchDescriptor(tempDirectory, tempPrefix), patch));

    if (status && status.patchId) {
        // add previous patch to list of otherPatches
        const patchesToDelete = status.otherPatches.slice(0);
        patchesToDelete.push(status.patchDescriptor);

        yield* _removeTempPatches(p.task(10), tempDirectory, patchesToDelete);
    }

    log.debug("saved user patch with ref " + reference + " and time " + tempPatch.synchroTime);
    tempPatch.synchroTime = newSynchroMicroTime;
    tempPatch.hasUnflushedChanges = false;

    return {
        state: TempPatchState.IN_SYNC,
        rebase: false,
        tempPatch: tempPatch,
    } as TempPatchStatus;
}
export function saveTempPatch(progress: ProgressObserver, tempDirectory: PatchesDescriptor, tempPatch: TempPatch, resolvingConflict: boolean): Observable<TempPatchStatus> {
    return task(() => _saveTempPatch(progress, tempDirectory, tempPatch, resolvingConflict));
}


function* _clearAllTempPatches(progress: ProgressObserver, tempDirectory: PatchesDescriptor, exceptLastTempPatch?: boolean): GeneratorType<void> {
    const p = makeProgressHandler(progress);

    if (exceptLastTempPatch) {
        p.total(100);
        const status = yield* _getTempPatchStatus(p.task(20), tempDirectory, null);

        const patchesToDelete = status.otherPatches.slice(0);
        yield* _removeTempPatches(p.task(80), tempDirectory, patchesToDelete);
    } else {
        p.total(100);

        // list the files to delete 
        const deleteProgress = makeProgressHandler(p.task(80));
        const deleteTasks: Observable<unknown>[] = yield* call(FileService.list(p.task(20), tempDirectory).pipe(
            reduce(function (deleteTasks, file) {
                deleteTasks.push(FileService.delete(deleteProgress.task(1), file));
                return deleteTasks;
            }, []),
        ));

        if (!deleteTasks.length) {
            deleteProgress.total(1);
            deleteProgress.units(1);
            return;
        }

        deleteProgress.total(deleteTasks.length);
        yield* call(FileService.execute(deleteTasks));
    }
}

export function clearAllTempPatches(progress: ProgressObserver, tempDirectory: PatchesDescriptor, exceptLastTempPatch?: boolean): Observable<unknown> {
    return task(() => _clearAllTempPatches(progress, tempDirectory, exceptLastTempPatch));
}

/**
 * Copy an attachment to a temp location 
 * 
 * @param progress 
 * @param tempPatch temp patch
 * @param source attachment data or attachment file
 * @param entityId target entity id
 * @param key target attachment key
 * 
 * @return a temp file descriptor
 */
export function prepareAttachment(progress: ProgressObserver, tempPatch: PatchDescriptor, source: FileDescriptor | Data, entityId: string, key: string): Observable<FileDescriptor> {
    const attachmentFile = new PatchAttachmentDescriptor(tempPatch, entityId, `${new Date().getTime()}-${key}`);

    let task: Observable<unknown> = null;
    if (source instanceof Data) {
        task = FileService.upload(progress, attachmentFile, source);

    } else {
        task = FileService.copy(progress, source, attachmentFile);
    }

    return task.pipe(
        map(function () {
            return attachmentFile;
        }),
    );
}