import { Observable, distinctUntilChanged, map, switchMap, takeWhile, timer } from "rxjs";

/**
 * Creates an observable that can act as a clock for a poller.
 * @param frequency$ An observable who gives the frequency in milliseconds.
 *                   If the observable emit 0 or a negative value, the poller will stop.
 * @returns An observable who emit when the frequency is reached
 *
 * @example With simple task job
 * ```typescript
 * const myPoller$ = pollingNotifier(of(PollFrequency.HIGH));
 * const job$ = myPoller$.pipe(
 *      tap(() => myJobTask()),
 * );
 * ```
 *
 * @example With a couple of observable and retry on error
 * ```typescript
 * const myPoller$ = pollingNotifier(of(PollFrequency.HIGH));
 * const job$ = combineLatest([myPoller$, myOtherObservable$]).pipe(
 *      tap(([_, myOtherValue]) => myJobTask(myOtherValue)),
 *      retry({
 *          delay: (error, retryCount) => {
 *              const isError500API = error.type === 'backend_API' && error.status === 500;
 *              if (isError500API && retryCount < 3) {
 *                  return of(true);
 *              }
 *          return throw error;
 *          },
 *    }),
 *);
 * ```
 */
export function pollingNotifier(interval$: Observable<number>): Observable<void> {
    return interval$.pipe(
        distinctUntilChanged(),
        switchMap((interval) => {
            return timer(0, interval).pipe(map(() => interval));
        }),
        takeWhile((interval) => interval > 0),
        map(() => undefined),
    );
}
