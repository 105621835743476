export const VisitTypeTemplateName = {
    AfterSalesService: 'afterSalesService',
    Delivery: 'delivery',
    HandoverToRealization: 'handoverToRealization',
    RealizationToHandover: 'realizationToHandover',
    WorkPackage: 'workpackage',
    Automation: 'automation',
} as const;
export type VisitTypeTemplateName = EnumLikeValueType<typeof VisitTypeTemplateName>;

export const EquivBugTransition: Record<string, number> = {
    EquivTransitionNone: 0,
    EquivTransitionAccept: 1,
    EquivTransitionReject: 2,
    EquivTransitionEnter: 3,
} as const;
export type EquivBugTransition = EnumLikeValueType<typeof EquivBugTransition>;

export const BugTransitionActivation: Record<string, number> = {
    TransitionActivationNone: 0,
    TransitionActivationIfEveryLinkClosed: 1,
    TransitionActivationIfNotEveryLinkClosed: 2,
    TransitionActivationIfAutoAcceptanceOfBug: 3,
} as const;
export type BugTransitionActivation = EnumLikeValueType<typeof BugTransitionActivation>;

export const EquivBugState: Record<string, number> = {
    EquivStateNone: 0,
    EquivStateOpen: 1,
    EquivStateClosed: 2,
    EquivStateOpenAlt: 3,
    EquivStateClosedAlt: 4,
    EquivStateClosedRejected: 5,
    EquivStateClosing: 6,
} as const;
export type EquivBugState = EnumLikeValueType<typeof EquivBugState>;

export const BugStateAction: Record<string, number> = {
    StateActionNone: 0,
    StateActionCopyBug: 1,
    StateActionCleanupBugLinks: 2,
} as const;
export type BugStateAction = EnumLikeValueType<typeof BugStateAction>;

export const BugGroupRelationType: Record<string, number> = {
    BugGroupRelationNone: 0,
    BugGroupAllowingDelete: 1,
    BugGroupAllowingRead: 2,
    BugGroupAllowingCreateVisit: 3,
} as const;
export type BugGroupRelationType = EnumLikeValueType<typeof BugGroupRelationType>;
