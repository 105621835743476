/**
* DocumentEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { RegionEntity } from "./RegionEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { DocumentVersionEntity } from "./DocumentVersionEntity";


declare global {
    interface EntityTypeMap {
        "Document": DocumentEntity
    }
}

export abstract class DocumentEntity extends BaseEntity {

	 abstract documentId: string;
	 abstract downloadPolicy: string;
	 abstract owningProject: ProjectEntity;
	 abstract documents: ReadonlyArray<DocumentVersionEntity>;
	 abstract activeVersion: DocumentVersionEntity;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract project?: ProjectEntity;
	 abstract region?: RegionEntity;
	 abstract workPackage?: WorkPackageEntity;
	
}
