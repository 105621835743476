/**
* ProjectMemberEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectEntity } from "./ProjectEntity";
import { PersonEntity } from "./PersonEntity";
import { RemarkCategoryEntity } from "./RemarkCategoryEntity";


declare global {
    interface EntityTypeMap {
        "ProjectMember": ProjectMemberEntity
    }
}

export abstract class ProjectMemberEntity extends BaseEntity {

	 abstract projectMemberId: string;
	 abstract type: string;
	 abstract systemType: boolean;
	 abstract project: ProjectEntity;
	 abstract templateId?: string;
	 abstract order?: number;
	 abstract person?: PersonEntity;
	 abstract remarkCategory?: RemarkCategoryEntity;
	
}
