import { Size } from "@archipad-js/core/utils";

/**
 * Carful: This paperSize is specific to the report.html template
 */
export const reportTemplatePixelSize = {
    A4: { height: 1448, width: 1024 },
    US_LETTER: { height: 1426, width: 1104 },
};

export type ReportTemplatePixelPaperSize = keyof typeof reportTemplatePixelSize;

/**
 * Carful: This paperSize is specific to the report.html template
 */
export function getMaxReportTemplateCoordinates(paperSize: ReportTemplatePixelPaperSize, orientation: string): { x: number; y: number } {
    const { height, width } = reportTemplatePixelSize[paperSize];

    if (orientation == "landscape") {
        return { x: height, y: width };
    }
    //Default to portrait
    return { x: width, y: height };
}


export const knownPaperSizeInMM = {
    "A4": { height: 297, width: 210 },
    "A3": { height: 420, width: 297 },
    "Letter": { height: 279.4, width: 215.9 },
}
export type PaperFormat = keyof typeof knownPaperSizeInMM;
export type PaperOrientation = 'landscape' | 'portrait';
export class PaperFormatter {
    
    protected readonly sizeInMM: Size;

    constructor(paperFormat: PaperFormat, orientation: PaperOrientation){
        const { height, width } = knownPaperSizeInMM[paperFormat];
        if (orientation == "landscape") {
            this.sizeInMM = { height: width, width: height };
        } else {
            this.sizeInMM = { height: height, width: width };
        }
    }

    public toMM(): Size {
        return this.sizeInMM;
    }

    public toPx(): Size {
        return { height: this.sizeInMM.height * WWW_PX_PER_MM, width: this.sizeInMM.width * WWW_PX_PER_MM };
    }

     /**
     * Some usecase (report page cutting...) require even value for correct division.
     */
    public toEvenPx(): Size {
        return { 
            height: Math.round(this.sizeInMM.height * WWW_PX_PER_MM / 2) * 2, 
            width: Math.round(this.sizeInMM.width * WWW_PX_PER_MM / 2) * 2, 
        };
    }

    public toInch(): Size {
        return { height: this.sizeInMM.height / MM_PER_INCH, width: this.sizeInMM.width / MM_PER_INCH };
    }

    public toPDFPoints(): Size {
        return { height: this.sizeInMM.height * PDF_POINTS_PER_MM, width: this.sizeInMM.width * PDF_POINTS_PER_MM };
    }
}

export const PDF_POINTS_PER_INCH = 72.0 /* points per inch */;
export const MM_PER_INCH = 25.4 /* mm per inch */;
export const PDF_POINTS_PER_MM = 72.0 /* points per inch */ / MM_PER_INCH;
export const WWW_PX_PER_MM = 96.0 /* pixels per inch */ / MM_PER_INCH;
export const WWW_PX_PER_INCH = 96.0 /* pixels per inch */;
