import { emptyFn, logFnFor } from "./logger.helper";
import { LoggerConfig, Logger, LogLevel, SingleLoggerConfig } from "./logger.interface";

export class LoggerService implements Logger {
    public constructor(private readonly name: string, public config: Readonly<LoggerConfig>) {}

    public get debug(): (...args: unknown[]) => void {
        return this.isEnabled(LogLevel.DEBUG) ? logFnFor(LogLevel.DEBUG)(this.name) : emptyFn;
    }

    public get info(): (...args: unknown[]) => void {
        return this.isEnabled(LogLevel.INFO) ? logFnFor(LogLevel.INFO)(this.name) : emptyFn;
    }

    public get warn(): (...args: unknown[]) => void {
        return this.isEnabled(LogLevel.WARN) ? logFnFor(LogLevel.WARN)(this.name) : emptyFn;
    }

    public get error(): (...args: unknown[]) => void {
        return this.isEnabled(LogLevel.ERROR) ? logFnFor(LogLevel.ERROR)(this.name) : emptyFn;
    }

    public isEnabled(level: LogLevel): boolean {
        const levelName = LogLevel[level];
        const config: SingleLoggerConfig = {
            ...this.config,
            ...this.config.classes[this.name],
        };
        return !!config[levelName];
    }

    public setEnabled(level: LogLevel, enabled: boolean): void {
        const levelName = LogLevel[level];
        if (!this.config.classes[this.name]) {
            this.config.classes[this.name] = {
                DEBUG: this.config.DEBUG,
                INFO: this.config.INFO,
                WARN: this.config.WARN,
                ERROR: this.config.ERROR,
            };
        }
        this.config.classes[this.name][levelName] = enabled;
    }
}
