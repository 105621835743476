import { IllegalArgumentError } from '@core/errors/errors-core';
import { BugNoteAnnotationEntity } from '../BugNoteAnnotationEntity';
import { BugNoteEntity } from '../BugNoteEntity';

Object.defineProperty(BugNoteAnnotationEntity.prototype, 'parentAnnotatedEntity', {
    get: function(this: BugNoteAnnotationEntity) : BugNoteEntity {
        return this.bugNote;
    },
    set: function(this: BugNoteAnnotationEntity, parentAnnotatedEntity: BugNoteEntity): void {
        if(!parentAnnotatedEntity){
            throw new IllegalArgumentError('cannot be null nor undefined', 'parentAnnotatedEntity');
        }
        this.bugNote = parentAnnotatedEntity;
    },
    enumerable: true,
});