export enum TechnicalErrorModuleCore {
    UNKNOWN = "unknown",

    NET = "network",
    API = `network.api`,
}

export class TechnicalErrorIdentifier {
    constructor(private readonly module: string, private readonly code: string) {}

    toString(): string {
        return `${this.module}:${this.code}`;
    }
}

/**
 * Mother-class for Core related Errors
 */
export abstract class CoreError extends Error {
    /**
     * An optional error code identifer that might help to distinct errors
     * regardless of the callstack.
     */
    public identifier?: TechnicalErrorIdentifier;

    /**
     * An optional localized message.
     */
    public localizedMessage?: string;

    /**
     * The underlying error is the source error.
     *
     * Useful when you want to rethrow a global error but you don't want to lose
     * the original error.
     */
    public underlyingError?: Error;

    private readonly _tags: Map<string, string> = new Map();
    private readonly _context: Map<string, Record<string, unknown>> = new Map();

    constructor(message?: string) {
        super(message);
        this.name = this.constructor.name;
    }

    /**
     * Indexed and searchable informations attached on an error.
     *
     * The intent is to store values that might help to find an error.
     */
    public get tags(): ReadonlyMap<string, string> {
        return this._tags;
    }

    /**
     * Additional diagnostic informations attached to an error.
     *
     * The intent is to store values that might help to solve the error.
     *
     * ⚠️ It MUST never be read to do business logic stuff.
     *
     * @example
     * const error = new ReportTemplateProcessError();
     * error.config = reportTemplate.config;
     * error.addContext("Archipad", { config: reportTemplate.config });
     *
     * // ❌ WRONG
     * if (error.context.get("config") === stuff) {}
     *
     * // ✅ GOOD
     * const isReportTemplateProcessError = error instanceof ReportTemplateProcessError;
     * if (isReportTemplateProcessError && error.config === stuff) {}
     */
    public get context(): ReadonlyMap<string, Record<string, unknown>> {
        return this._context;
    }

    /**
     * Add indexable and searchable informations on error.
     *
     * @see {@link tags}
     *
     * @example
     * const error = new ORMError("Entity already exists");
     * error.addTag("entityName", entity.name);
     */
    public addTag(tagName: string, tagValue: string): void {
        this._tags.set(tagName, tagValue);
    }

    /**
     * Add additional diagnostic informations attached to an error.
     *
     * @see {@link context}
     *
     * @example
     * const error = new ORMError("Entity already exists");
     * error.addContext("Archipad", { entityName: entity.name });
     */
    public addContext(sectionName: string, sectionContent: Record<string, unknown>): void {
        const oldSectionContent = this._context.get(sectionName);
        this._context.set(sectionName, {
            ...oldSectionContent,
            ...sectionContent,
        });
    }

    /**
     * Returns an iterable of all chained errors including the source error.
     *
     * @example
     * class CustomError extends CoreError {}
     * const error = new CustomError('high level error');
     * error.underlyingError = new CustomError('low level error');
     *
     * const errors = Array.from(error.allChainedErrors());
     * console.log(errors.length); // Output: 2
     */
    allChainedErrors(): IterableIterator<Error> {
        return underlyingErrors(this);
    }

    /**
     * Returns an iterable of underlying chained errors excluding the source error.
     *
     * @example
     * class CustomError extends CoreError {}
     * const error = new CustomError('high level error');
     * error.underlyingError = new CustomError('low level error');
     *
     * const errors = Array.from(error.underlyingChainedErrors());
     * console.log(errors.length); // Output: 1
     */
    underlyingChainedErrors(): IterableIterator<Error> {
        return underlyingErrors(this.underlyingError);
    }
}

function* underlyingErrors(error?: Error): Generator<Error, void, unknown> {
    let currentValue: Error | undefined = error;

    for (;;) {
        if (!currentValue) {
            break;
        }

        yield currentValue;
        if (currentValue instanceof CoreError) {
            currentValue = currentValue.underlyingError;
        } else {
            break;
        }
    }
}

/**
 * Returns the source error in the error chain or the error itself if it's not
 * a CoreError.
 *
 * @example
 * const error = new Error();
 * console.log(getSourceError(error) === error); // true
 *
 * class CustomError extends CoreError {}
 *
 * const error2 = new CustomError('high level error');
 * const error2.underlyingError = error;
 * console.log(getSourceError(error2) === error); // true
 */
export function getSourceError(error: Error): Error {
    if (!(error instanceof CoreError)) {
        return error;
    }

    if (!error.underlyingError) {
        return error;
    }

    const underlyingErrors = Array.from(error.underlyingChainedErrors());
    return underlyingErrors[underlyingErrors.length - 1];
}

/**
 * Returns the error of the first element in the error chain where predicate is
 * true including the source error, and undefined otherwise.
 */
export function findInAllChainedErrors<T extends Error>(
    error: Error,
    predicate: (err: unknown) => err is T,
): T | undefined {
    if (error instanceof CoreError) {
        const chainedErrors = Array.from(error.allChainedErrors());
        for (const chainedError of chainedErrors) {
            if (predicate(chainedError)) {
                return chainedError;
            }
        }
    } else if (predicate(error)) {
        return error;
    }

    return undefined;
}
