import "reflect-metadata";

import { DIID } from "@archipad-js/dependency-injection";

import type { Response as NodeFetchResponse, RequestInit as NodeFetchRequestInit } from "node-fetch";

export const Http_Client_DIID = DIID<HttpClientInterface>("HttpClientInterface");

export const ALLOWED_HTTP_METHODS = ["DELETE", "GET", "HEAD", "OPTIONS", "PATCH", "POST", "PUT"] as const;

type HttpMethod = typeof ALLOWED_HTTP_METHODS[number];

export type FetchResponse = Response | NodeFetchResponse;

export type FetchRequestInit = RequestInit | NodeFetchRequestInit;

export interface HttpClientInterface {
    /**
     * Shortcut to request("GET")
     */
    get(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("HEAD")
     */
    head(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("DELETE")
     */
    delete(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("DELETE")
     */
    options(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("PATCH")
     */
    patch(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("POST")
     */
    post(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Shortcut to request("PUT")
     */
    put(url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;

    /**
     * Returns a fetch Promise<Response>
     */
    request(method: HttpMethod, url: string | URL, options?: FetchRequestInit): Promise<FetchResponse>;
}
