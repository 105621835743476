/**
* BugNoteEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugEntity } from "./BugEntity";
import { VisitEntity } from "./VisitEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";
import { PhaseEntity } from "./PhaseEntity";
import { BugTransitionEntity } from "./BugTransitionEntity";
import { BugStateEntity } from "./BugStateEntity";
import { BugNoteAnnotationEntity } from "./BugNoteAnnotationEntity";


declare global {
    interface EntityTypeMap {
        "BugNote": BugNoteEntity
    }
}

export abstract class BugNoteEntity extends BaseEntity {

	 abstract bugNoteId: string;
	 abstract creationDate: Date;
	 abstract temp: boolean;
	 abstract bug: BugEntity;
	 abstract visit: VisitEntity;
	 abstract templateId?: string;
	 abstract text?: string;
	 abstract user?: ProjectUserEntity;
	 abstract phase?: PhaseEntity;
	 abstract bugTransition?: BugTransitionEntity;
	 abstract bugState?: BugStateEntity;
	 abstract annotations?: ReadonlyArray<BugNoteAnnotationEntity>;
	
}
