import { CoreError } from "./core-error";

/**
 *  Error representing error about the state of a class / function
 *  - Inconsistent state / can't proceed
 */
export class IllegalStateError extends CoreError {}

/**
 *  Error reprensenting error about argument passed to a function / method
 *  - Missing argument
 *  - Invalid argument
 */
export class IllegalArgumentError extends CoreError {
    constructor(message?: string, argName?: string, expected?: string, got?: string) {
        let computedMessage = argName ? `argName : ${argName} ` : "";
        computedMessage += expected ? `expected : ${expected} ` : "";
        computedMessage += got ? `got : ${got} ` : "";
        computedMessage += message;

        super(computedMessage);
    }
}

/**
 *  Error representing error about methods or functions
 *  - Not implemented function / method
 *  - Not supported action
 */
export class UnsupportedOperationError extends CoreError {}
