import "reflect-metadata";

import { plainToInstance } from "class-transformer";
import { inject, injectable } from "inversify";

import { isAbortError } from "@archipad-js/core/async";
import { FetchResponse, HttpClientInterface, Http_Client_DIID } from "@archipad-js/core/http";
import { LoggerManager } from "@archipad-js/core/logger";
import { LocalizationService, Localization_Service_DIID } from "@archipad-js/core/translate";
import { validateSync } from "@archipad-js/core/validation";
import { DIID } from "@archipad-js/dependency-injection";

import {
    ConnectionFailedNetworkError,
    InternalAPIError,
    MalFormedResponseAPIError,
    ResponseAPIError,
} from "./archipad-rpc.service.error";
import { APIResponseFail, APIResponseSuccess, ArchipadHttpClientConfiguration } from "./archipad-rpc.service.types";

export const Archipad_RPC_Service_DIID = DIID<ArchipadRPCService>("ArchipadRPCService");
export const Archipad_Http_Client_Configuration_DIID = DIID<ArchipadHttpClientConfiguration>(
    "ArchipadHttpClientConfiguration",
);

@injectable()
export class ArchipadRPCService {
    private readonly logger = LoggerManager.getLogger("ArchipadRPCService");

    constructor(
        @inject(Http_Client_DIID.symbol) private readonly httpClientService: HttpClientInterface,
        @inject(Archipad_Http_Client_Configuration_DIID.symbol)
        public readonly configuration: ArchipadHttpClientConfiguration,
        @inject(Localization_Service_DIID.symbol) private readonly localizationService: LocalizationService,
    ) {}

    public async makeRequest(
        signal: AbortSignal,
        serviceName: string,
        methodName: string,
        methodParams?: Record<string, unknown>,
        httpHeaders?: HeadersInit,
    ): Promise<unknown> {
        const endpointUrl = this.formatEndpointURL(serviceName).toString();

        this.logger.debug(`Calling ${endpointUrl}::${methodName}`);

        let headers: HeadersInit = {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...httpHeaders,
        };

        if (this.configuration.preflightOptimization) {
            headers = {
                "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
            };
        }

        const requestBody = this.encodeBody(methodName, methodParams);

        const options: RequestInit = {
            body: requestBody,
            headers: headers,
            signal: signal,
        };

        let response: FetchResponse;
        try {
            response = await this.httpClientService.post(endpointUrl, options);
        } catch (e) {
            if (isAbortError(e)) {
                throw e;
            } else if (e instanceof Error) {
                const connectionFailedNetworkError = new ConnectionFailedNetworkError(e.message);
                connectionFailedNetworkError.underlyingError = e;
                throw connectionFailedNetworkError;
            }
            throw new ConnectionFailedNetworkError();
        }

        /**
         * Handle un-handled API exceptions
         */
        if (response.status && response.status >= 400) {
            const responseText = await response.text();
            const error = new InternalAPIError(responseText);
            error.addTag("archipad.api.http_code", response.status.toString());
            error.addTag("archipad.api.service", serviceName);
            error.addTag("archipad.api.method", methodName);
            error.addContext("Archipad", {
                method: methodName,
                service: serviceName,
                params: methodParams,
                response: responseText,
            });
            throw error;
        }

        const responseStatus = response.status.toString();
        let responseText;
        let parsedResponse;

        try {
            responseText = await response.text();
            parsedResponse = JSON.parse(responseText);
        } catch (e) {
            const malFormedResponseAPIError = this.createMalformedResponseAPIError(
                serviceName,
                methodName,
                methodParams,
                responseStatus,
                responseText ?? "",
            );
            throw malFormedResponseAPIError;
        }

        const apiResponseSuccessInstance = plainToInstance(APIResponseSuccess, parsedResponse);

        let errors = validateSync(apiResponseSuccessInstance);
        if (errors.length === 0) {
            return apiResponseSuccessInstance.result;
        }

        const apiResponseFailInstance = plainToInstance(APIResponseFail, parsedResponse);
        errors = validateSync(apiResponseFailInstance);
        if (errors.length === 0) {
            const error = new ResponseAPIError(parsedResponse.error);
            error.addContext("Archipad", {
                service: serviceName,
                method: methodName,
                params: methodParams,
                error: parsedResponse.error,
            });
            throw error;
        }

        const malFormedResponseAPIError = this.createMalformedResponseAPIError(
            serviceName,
            methodName,
            methodParams,
            responseStatus,
            responseText,
        );
        throw malFormedResponseAPIError;
    }

    private formatEndpointURL(serviceName: string): URL {
        const language = this.localizationService.locale.language;
        const client: string = [this.configuration.clientName, this.configuration.clientVersion, language].join("+");
        const url: string = [this.configuration.clientProtocol, client, serviceName].join("/");
        return new URL(url, this.configuration.webServiceBaseUrl);
    }

    private encodeBody(methodName: string, methodParams?: Record<string, unknown>): string {
        const params: Record<string, unknown> = { method: methodName };
        if (methodParams) {
            params["params"] = methodParams;
        }
        const str = JSON.stringify(params);
        return str;
    }

    private createMalformedResponseAPIError(
        serviceName: string,
        methodName: string,
        methodParams: Record<string, unknown> | undefined,
        responseStatus: string,
        responseText: string,
    ): MalFormedResponseAPIError {
        const error = new MalFormedResponseAPIError();
        error.addTag("archipad.api.http_code", responseStatus);
        error.addTag("archipad.api.service", serviceName);
        error.addTag("archipad.api.method", methodName);
        error.addContext("Archipad", {
            service: serviceName,
            method: methodName,
            params: methodParams,
            response: responseText,
        });
        return error;
    }
}
