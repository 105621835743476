import { BugEntity } from "../BugEntity";
import { BugNoteEntity } from "../BugNoteEntity";
import { PhaseEntity } from "../PhaseEntity";

Object.defineProperty(BugEntity.prototype, 'currentPhase', {
    get: function(this:BugEntity) : PhaseEntity {
        if (this.phase) {
            return this.phase;
        }
        return this.visit?.phase ?? null;
    },
    enumerable: true,
});

// return the last bugNote with a state change
Object.defineProperty(BugEntity.prototype, 'stateNoteCache', {
    get: function(this:BugEntity) : BugNoteEntity {
        return this.notes.slice()
            .sort(((a, b) => b.creationDate.getTime() - a.creationDate.getTime()))
            .find((note) => !!note.bugState);
    },
    enumerable: true,
});
