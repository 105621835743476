import { ValidationError as BaseValidationError } from "class-validator";

import { CoreError, TechnicalErrorIdentifier, TechnicalErrorModuleCore } from "@archipad-js/core/error";

export class ValidationError extends CoreError {
    constructor(public readonly details: Array<BaseValidationError>, message?: string) {
        super(message);
    }

    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "VALIDATION_ERROR");
}
