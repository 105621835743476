import { query } from "@core/services/orm/query";
import { FunctionKeys, OmitByValue, ReadonlyKeys } from "utility-types";

import { BaseEntity, EntityContext } from "@core/services/orm/orm";

/**
 * Turns the given entity into a plain object that can be used to create an entity.
 */
export type CreateEntityPayload<T extends BaseEntity> = Partial<
    OmitByValue<
        Omit<T, FunctionKeys<T> | ReadonlyKeys<T> | "allowUndoRedo" | "_values" | "_updating">,
        ReadonlyArray<unknown>
    >
>;

/**
 * Turns the given entity into a plain object that can be used to update an entity.
 */
export type UpdateEntityPayload<T extends BaseEntity> = Omit<CreateEntityPayload<T>, "id">;

/**
 * Creates an entity with the given properties if they are known by the model.
 *
 * @see {@link updateEntityWithProperties()}
 */
export function createEntityWithProperties<
    EntityName extends keyof EntityTypeMap,
    Entity extends EntityTypeMap[EntityName],
>(entityContext: EntityContext, entityName: EntityName, data: CreateEntityPayload<Entity>): Entity;
export function createEntityWithProperties<T extends BaseEntity>(
    entityContext: EntityContext,
    entityName: string,
    data: CreateEntityPayload<T>,
): T;
export function createEntityWithProperties(
    entityContext: EntityContext,
    entityName: string,
    data: CreateEntityPayload<BaseEntity>,
): BaseEntity {
    entityContext.entityTypes.byName[entityName].validationSchema.parse(data);
    const { id, ...properties } = data;
    const createdEntity = entityContext.createEntity(entityName, id);
    assignPropertiesToEntity(createdEntity, properties);
    return createdEntity;
}

/**
 * Updates the given entity with the given properties if they are known by the model.
 */
export function updateEntityWithProperties<T extends BaseEntity>(entity: T, data: UpdateEntityPayload<T>): void {
    entity.entityType.validationSchema.parse(data);
    assignPropertiesToEntity(entity, data);
}

function assignPropertiesToEntity(entity: BaseEntity, properties: Record<string, unknown>): void {
    for (const [propertyName, propertyValue] of Object.entries(properties)) {
        const propertyDescription = entity.entityType.propertyForKeyPath(propertyName);
        if (!propertyDescription) {
            continue;
        }

        if (propertyValue === undefined) {
            continue;
        }

        entity[propertyName] = propertyValue ?? null;
    }
}

export function fetchEntityByTemplateName<EntityName extends keyof EntityTypeMap>(
    entityContext: EntityContext,
    entityName: EntityName,
    templateName: string,
): EntityTypeMap[EntityName];
export function fetchEntityByTemplateName<T extends BaseEntity>(
    entityContext: EntityContext,
    entityName: string,
    templateName: string,
): T;
export function fetchEntityByTemplateName(
    entityContext: EntityContext,
    entityName: string,
    templateName: string,
): BaseEntity {
    const predicate = "templateName = {templateName}";
    const predicateParams = { templateName: templateName };
    const ormQuery = query<BaseEntity>(entityContext, entityName);
    ormQuery.predicateWithFormat(predicate);
    const resultSet = ormQuery.execute(predicateParams);
    if (resultSet.count() > 1) {
        throw new Error(`More than one entity found for template name: ${templateName}`);
    }
    const entity = resultSet.firstEntity();
    return entity;
}

export function fetchEntityByTemplateId<EntityName extends keyof EntityTypeMap>(
    entityContext: EntityContext,
    entityName: EntityName,
    templateId: string,
): EntityTypeMap[EntityName];
export function fetchEntityByTemplateId<T extends BaseEntity = BaseEntity>(
    entityContext: EntityContext,
    entityName: string,
    templateId: string,
): T;
export function fetchEntityByTemplateId(
    entityContext: EntityContext,
    entityName: string,
    templateId: string,
): BaseEntity {
    const predicate = "templateId = {templateId}";
    const predicateParams = { templateId: templateId };
    const ormQuery = query<BaseEntity>(entityContext, entityName);
    ormQuery.predicateWithFormat(predicate);
    const resultSet = ormQuery.execute(predicateParams);
    if (resultSet.count() > 1) {
        throw new Error(`More than one entity found for template id: ${templateId}`);
    }
    const entity = resultSet.firstEntity();
    return entity;
}
