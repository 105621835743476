import { ConfigService } from '@archipad-js/core/config';
import { EntityTypes, buildSchema } from '@archipad-js/core/orm';
import { 
    BaseEntity,
    DynamicEntities,
    DynamicEntitiesImpl,
    EntityContext,
    Validator,
} from '@core/services/orm/orm';


import { SchemaManager } from '@core/services/orm/schema-manager.interface';

export class SchemaManagerImpl implements SchemaManager {
    constructor(
        private readonly entityClasses: ReadonlyMap<string, typeof BaseEntity>,
        private readonly validators: ReadonlyArray<Validator>,
        private readonly configService: ConfigService,
    ) { }

    public async entityContextFromConfiguration(): Promise<EntityContext> {
        const metadata = await this.getModelSchema();

        const entityTypes : EntityTypes = buildSchema(metadata);

        const dynamicEntities : DynamicEntities =  new DynamicEntitiesImpl(
            entityTypes,
            this.entityClasses,
        );

        const entityContext = new EntityContext(
            entityTypes,
            dynamicEntities,
            this.validators,
            metadata,
        );
        return entityContext;
    }

    private async getModelSchema(): Promise<unknown> {
        const modelSchema = await this.configService.get('model');
        return modelSchema;
    }
}

export type { SchemaManager };
