/**
* BugGroupEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { BugWorkflowEntity } from "./BugWorkflowEntity";
import { BugStateEntity } from "./BugStateEntity";
import { VisitTypeEntity } from "./VisitTypeEntity";
import { ProjectUserRole_BugGroupsEntity } from "./ProjectUserRole_BugGroupsEntity";
import { Phase_BugGroupsEntity } from "./Phase_BugGroupsEntity";
import { BugEntity } from "./BugEntity";


declare global {
    interface EntityTypeMap {
        "BugGroup": BugGroupEntity
    }
}

export abstract class BugGroupEntity extends BaseEntity {

	 abstract bugGroupId: string;
	 abstract bugWorkflow: BugWorkflowEntity;
	 abstract rolesRelations: ReadonlyArray<ProjectUserRole_BugGroupsEntity>;
	 abstract phasesRelations: ReadonlyArray<Phase_BugGroupsEntity>;
	 abstract templateName?: string;
	 abstract templateId?: string;
	 abstract name?: string;
	 abstract bugStates?: ReadonlyArray<BugStateEntity>;
	 abstract visitTypes?: ReadonlyArray<VisitTypeEntity>;
	 abstract bugs?: ReadonlyArray<BugEntity>;
	
}
