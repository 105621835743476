import { getLocalizationService } from "@core/services/translate";
import { RegionTypeEntity } from "../RegionTypeEntity";

Object.defineProperty(RegionTypeEntity.prototype, 'nameComputed', {
    get: function(this:RegionTypeEntity):string {
        const localizer = getLocalizationService();
        
        if(this.nameSystem && localizer.isLocalized(this.nameSystem)) {
            return String(localizer.getFormatted(this.nameSystem));
        }

        return this.name?.length > 0 ? this.name : this.nameSystem ?? '';
    },
});