import { createLogger } from '@core/services/logger.service';
import * as Orm from '@core/services/orm/orm';
import * as IdGenerator from '@core/services/orm/idGenerator';
import { FormEntity } from '@archipad-models/models/FormEntity';
import { FormTypeViewEntity } from '@archipad-models/models/FormTypeViewEntity';
import { FormTypeVersionEntity } from '@archipad-models/models/FormTypeVersionEntity';
import { FormTypeEntity } from '@archipad-models/models/FormTypeEntity';
import { VisitEntity } from '@archipad-models/models/VisitEntity';
import { IllegalStateError, IllegalArgumentError } from '@core/errors/errors-core';

const log = createLogger('form.model');

// rect coordinates precision: 1/100mm 
export const PRECISION = 100.0;

/*----------------------------------------------------------------------------*/
export namespace FormView {
    export type ValueType = "string" | "number" | "date" | "boolean";

    export interface ReadonlyRect {
        readonly left:number;
        readonly top:number;
        readonly right:number;
        readonly bottom:number;
    }
    export interface Rect extends ReadonlyRect {
        left:number;
        top:number;
        right:number;
        bottom:number;
    }

    export interface Font {
        family?: string;
        style?: string;
        size?: number;
        color?: string;
        textIndent?: number;
        lineHeight?: number;
    }

    export interface BaseElement {
        /** field identifier, must be unique across form types  */
        id: string;
        /** identifier of the field in the form model, multiple fields may share the same model id */
        modelId:string;
        /** item name */
        name?: string;
        /** item label */
        label: string;
        /** whether the element is deletable */
        isDeletable?: boolean;

        /** an expression which hides the element if it evaluates to true */
        hiddenExpr?: string;

        rect?: Rect;
        font?: Font;

        children?: Item[];
    }

    export interface Constant extends BaseElement {
        type:"constant";
        value: unknown;
        valueType:ValueType;
    }

    export interface Text extends BaseElement {
        type:"text";
        characters?: number;
    }

    export interface Input extends BaseElement {
        type:"input";
        placeholder?: string;
        format?:"raw" | "uppercase" | "email" | "time";
        dateFormat?: string;
        timeFormat?: string;
        rows?: number;
        characters?: number;
        valueType:ValueType;
        isNumberOfEntries?: boolean;
    }

    export interface CheckBox extends BaseElement {
        type:"checkbox";
        valueType:ValueType;    // value type
        checkedValue:any;       // model value when this checkbox is checked
        showAsLabel:string;     // additional label when this checkbox belongs to a Selector
    }

    export interface Field extends BaseElement {
        type:"field";
        /** visit.date, visit.name, project.name */
        fieldName:string;
        valueType:ValueType;
    }

    export interface Image extends BaseElement {
        type:"photo" | "signature";
    }

    export type SelectorShowAs = "value" | "checkboxes"

    export interface Selector extends BaseElement {
        type:"selector";
        /** show selector as text or as checkboxes in report */
        showAs:SelectorShowAs;
        /** is the set of values locked ? */
        lockedValues:boolean;
        valueType:ValueType;

        // children contains one checkbox per option
    }

    export interface Conformity extends BaseElement {
        type:"conformity";
        // children contains one status selector and a comment string input field
    }
    export interface ConformityWithObservation extends BaseElement {
        type:"conformity_observation";

        /** do not allow KO is an observation is not created */
        observationRequired: boolean;
        /** modelId of the bugId when the conformity is KO */
        bugModelId: string;

        // children contains one status selector
    }

    export interface Section extends BaseElement {
        type:'section';
        // group children, children rect coordinates are relative to section rect
    }

    export interface RepeatLine {
        /* template model id to line model id translation */
        modelId: { [templateModelId: string]: string };
    }

    export const enum RepeatCountKind {
        button = 'button',
        input = 'input'
    };

    export const enum RepeatDirection {
        vertical = 'vertical',
        horizontal = 'horizontal'
    };

    export interface RepeatSection extends BaseElement {
        type: 'repeat_section'
        lines: RepeatLine[];
        rowCountKind: RepeatCountKind;
        maxRowCount: number;
        templateRect: Rect;
        lineCountModelId: string;
        repeatDirection: RepeatDirection;
        notRepeatedChildren?: Item[];
        entriesTitle?: string;
        // children are repeated for each line
    }

    export interface Formula extends BaseElement {
        type: 'formula';
        formulaExpr: string;
        valueType: ValueType;
        dateFormat?: string;
    }

    // children positioned in list or using absolute coordinates relative to
    // their parent
    export type SectionType = "list" | "absolute";

    export interface PageSection extends BaseElement  {
        type: 'page_section';
        sectionType: SectionType;
        paperFormat: PaperFormat;
        background?:string;
    }

    export interface Summary extends BaseElement {
        type: "summary";
        /** modelId du champ conformité avec observations */
        targetFieldModelId?: string;
        /** separateur du résumé: ',' '/' '\n' ' ' ... */
        separator: string;
    }

    // Item which cannot have children
    export type Leaf = Input | Text | Field | CheckBox | Image | Selector | Conformity | Constant | Formula | Summary;
    
    // All Items
    export type Item = RepeatSection | Section | Input | Text | Field | CheckBox | Image | Selector | Conformity | ConformityWithObservation | Constant | Formula | Summary;

    export type Element = PageSection | Item;
}

enum FeatureEngineVersion {
    BASE = 110,
    FORMULA_NUMBER = 111
}

export enum FormTypeViewVersion {
    CURRENT = 111
}

if (FORM_ENGINE_VERSION !== FormTypeViewVersion.CURRENT) {
    throw new IllegalStateError(`Mismatch between FORM_ENGINE_VERSION and FormTypeViewVersion.CURRENT`);
}

export enum FormViewType {
    SCREEN = "SCREEN",
    PRINT = "PRINT",
}

export const enum PaperFormat {
    A4_PORTRAIT = 'A4-portrait',
    A4_LANDSCAPE = 'A4-landscape',
}

export interface FormView {
    /** form layout version */
    version: number;
    /** form type code */
    code: string;
    /** filename expression */
    fileNameExpr: string;
    /** labelname expression */
    labelNameExpr: string;
    /** page elements in this form view */
    children: FormView.PageSection[];
}

export interface FormDescription { [modelId:string]:{ name:string, type:string } };

export interface Form {
    /* form fields description */
    description: FormDescription;
    /* form fields values */
    fields: { [modelId:string]:any };
}

/*----------------------------------------------------------------------------*/
export function normalizeCoordinate(x:number):number {
    return (typeof x !== 'number') ? null : Math.round(x*PRECISION)/PRECISION;
}

export function normalizeRect(rect:FormView.ReadonlyRect):FormView.ReadonlyRect {
    if(!rect)
        return null;
    const ret = {
        left:normalizeCoordinate(rect.left), 
        top:normalizeCoordinate(rect.top), 
        right:normalizeCoordinate(rect.right), 
        bottom:normalizeCoordinate(rect.bottom)
    };

    return ret;

}

export function hasPosition(rect:FormView.ReadonlyRect):boolean {
    return rect && rect.left !== null;
}

export function hasSize(rect:FormView.ReadonlyRect):boolean {
    return rect && rect.right !== null;
}

export function getSize(rect: FormView.ReadonlyRect): { width: number, height: number } {
    if (!hasSize(rect))
        return null;
    if (!hasPosition(rect))
        return { width: rect.right, height: rect.bottom };

    return { width: normalizeCoordinate(rect.right-rect.left), height: normalizeCoordinate(rect.bottom-rect.top) };
}

/*----------------------------------------------------------------------------*/
export function createFormType(entityContext: Orm.EntityContext, properties: { project: Orm.BaseEntity, name?: string, temp?: boolean }): FormTypeEntity {
    // create initial version
    const formTypeVersion:any = entityContext.createEntity('FormTypeVersion');
    formTypeVersion.date = new Date();

    // create form type
    const formType:any = entityContext.createEntity('FormType');

    Object.assign(formType, properties);

    formTypeVersion.formType = formType;    // note: initial version is inactive

    // add form type views
    {
        const formTypeView = entityContext.createEntity('FormTypeView') as FormTypeViewEntity;
        formTypeView.type = FormViewType.SCREEN;
        formTypeView.name = l('Screen');
        formTypeView.version = formTypeVersion;
        ensureEngineVersion(formTypeView);
    }

    {
        const formTypeView = entityContext.createEntity('FormTypeView') as FormTypeViewEntity;
        formTypeView.type = FormViewType.PRINT;
        formTypeView.name = l('Report');
        formTypeView.version = formTypeVersion;
        ensureEngineVersion(formTypeView);
    }

    return formType;
}

export function getLatestFormTypeVersion(formType: FormTypeEntity): FormTypeVersionEntity | null {
    let latestTimestamp = Number.MIN_SAFE_INTEGER;
    let latestVersion = null;

    for (const version of formType.versions) {
        const timestamp = version.date.getTime();

        if (latestTimestamp < timestamp) {
            latestTimestamp = timestamp;
            latestVersion = version;
        }
    }

    return latestVersion;
}

export function copyFormType(entityContext:Orm.EntityContext, sourceFormType: FormTypeEntity): FormTypeEntity {
    // copy form type
    const newFormType = entityContext.createEntity('FormType');
    newFormType.name = sourceFormType.name;

    // create initial version
    const newFormTypeVersion = entityContext.createEntity('FormTypeVersion');
    newFormTypeVersion.date = new Date();

    // copy each view from the latest version
    const sourceFormTypeVersion = getLatestFormTypeVersion(sourceFormType);
    for(const view of sourceFormTypeVersion.views) {
        const newFormTypeView = entityContext.createEntity('FormTypeView') as FormTypeViewEntity;
        newFormTypeView.type = view.type;
        newFormTypeView.name = view.name;
        newFormTypeView.data = view.data;
        newFormTypeView.version = newFormTypeVersion;
        newFormTypeView.engineVersion = view.engineVersion;
        newFormTypeView.engineMinVersion = view.engineMinVersion;

        // copy attachments
        const sourceAttachments = view.getAttachments();
        for(const attachmentKey in sourceAttachments)
        newFormTypeView.setAttachmentForKey(attachmentKey, sourceAttachments[attachmentKey]);
    }

    newFormTypeVersion.formType = newFormType;
    newFormType.hidden = false;

    return newFormType;
}

export function createForm(entityContext:Orm.EntityContext, formType:FormTypeEntity, visit:VisitEntity): FormEntity {
    const activeVersion = formType['activeVersion'];
    if(!activeVersion)
        throw new IllegalArgumentError('No active version for FormType: ' + formType);

    const form:FormEntity = entityContext.createEntity('Form');
    form.formTypeVersion = formType['activeVersion'];
    form.visit = visit;

    return form;
}

export function getFormTypeView(formTypeVersion:Orm.BaseEntity, type:string): FormTypeViewEntity | null {
    if(!formTypeVersion)
        return null;
    for(const formTypeView of formTypeVersion['views']) {
        if(formTypeView.type === type)
            return formTypeView;
    }
    return null;
}

export function createFormTypeVersion(formType:Orm.BaseEntity): FormTypeVersionEntity {
    const entityContext: Orm.EntityContext = formType.getContext();

    const activeVersion = formType['activeVersion'];
    if(!activeVersion)
        throw new IllegalArgumentError('Cannot create new form type version, no existing version');

    // create a new version based on the active version
    const newFormTypeVersion:any = entityContext.createEntity('FormTypeVersion');
    newFormTypeVersion.date = new Date();
    newFormTypeVersion.formType = formType;

    for(const formTypeView of activeVersion.views) {
        // clone the form type view
        const newFormTypeView = entityContext.createEntity('FormTypeView') as FormTypeViewEntity;
        newFormTypeView.name = formTypeView.name;
        newFormTypeView.type = formTypeView.type;
        newFormTypeView.data = formTypeView.data;

        // copy view attachments
        const attachments = formTypeView.getAttachments();
        for(const attachmentKey in attachments)
            newFormTypeView.setAttachmentForKey(attachmentKey, attachments[attachmentKey]);

        // link the cloned view to the new version
        newFormTypeView.version = newFormTypeVersion;

        ensureEngineVersion(newFormTypeView);
    }

    return newFormTypeVersion;
}

/**
 * Will ensure that the given FormTypeView entity will have an accurate
 * engineVersion and engineMinVersion.
 * 
 * @param formTypeView  The form type view to ensure the engine version
 */
export function ensureEngineVersion(formTypeView: FormTypeViewEntity): void {
    formTypeView.engineVersion = FormTypeViewVersion.CURRENT;
    if(formTypeView.engineMinVersion < FeatureEngineVersion.BASE) {
        formTypeView.engineMinVersion = FeatureEngineVersion.BASE;
    }
}

/**
 * @DOC @VERSION this function is useful for conditionnaly update the engineMinVersion
 * @param formTypeView 
 * @param formView 
 */
export function ensureDynamicallyEngineVersion(formTypeView: FormTypeViewEntity, formView: FormView): void {
    if(hasFormulaNumber(formView)) {
        formTypeView.engineMinVersion = Math.max(FeatureEngineVersion.FORMULA_NUMBER, formTypeView.engineMinVersion);
    } 
    ensureEngineVersion(formTypeView);
} 

/*----------------------------------------------------------------------------*/
function _removeSections(element:FormView.Element | FormView): void {
    if(!element.children)
        return;

    for(let i=element.children.length-1;i>=0;i--) {
        const child = element.children[i];

        // recurse
        _removeSections(child);

        if(child.type === "section") {
            // adjust children offset
            if(child.rect) {
                const offsetX = child.rect.left;
                const offsetY = child.rect.top;

                if(child.children) {
                    for(const sectionChild of child.children) {
                        if(!sectionChild.rect)
                            continue;
                        sectionChild.rect.left += offsetX;
                        sectionChild.rect.top += offsetY;
                        sectionChild.rect.right += offsetX;
                        sectionChild.rect.bottom += offsetY;
                    }
                }
            }

            // remove section but keep children
            const children = element.children as FormView.Item[];
            children.splice(i, 1, ...child.children);
        }
    }
}

function _removeDuplicates(modeldIds:Set<string>, element:FormView.Element | FormView): void {
    let i=0;
    while(i<element.children.length) {
        const child = element.children[i];
        if(!child.modelId)
            continue;
        if(modeldIds.has(child.modelId)) {
            log.error('DUPLICATE FORM FIELD DETECTED IN INPUT, GENERATING A NEW MODEL ID', child);
            child.modelId = IdGenerator.generateId();
        } else {
            modeldIds.add(child.modelId);
        }
        if(child.children)
            _removeDuplicates(modeldIds, child);
        i++;
    }
}

/**
 * fix broken Show / As selector control. 
 * - Did put "Show" in option.value, "As" in option.label
 * - Should have been "Show" in option.label,  "As" in option.showAsLabel
 */
function _fix99SelectorOptions(formView:FormView): void {
    walkElementChildren(formView, (formElement) => {
        if(formElement.type === "selector") {
            if(!formElement.children)
                return WalkResult.SKIP_CHILDREN;

            for(const option of formElement.children) {
                if(option.type === 'checkbox') {
                    switch(option.checkedValue) {
                        case 'OK': case 'NOK': case 'N/A': 
                            // leave conformity selectors
                            break;
                        case 'N/A\n':
                            option.checkedValue = 'N/A';
                            // fix typo
                            break;  
                        default: {
                            if(option.checkedValue === option.label) {
                                // leave selectors where label and value are the same
                                break;
                            }

                            // fix other selectors
                            log.error('FIXING OPTION', option)
                            option['showAsLabel'] = option.label;
                            option.label = option.checkedValue;
                        }
                    }
                }
            }
            return WalkResult.SKIP_CHILDREN;
        }
        return WalkResult.CONTINUE;
    })
}

/**
 * in version 101 selector options had a checkedValue, a label and a showAsLabel
 * now: 
 * - in SCREEN layout: option = { checkedValue:oldOption.checkedValue, label:oldOption.label }
 * - in PRINT layout: option = { checkedValue:oldOption.checkedValue, label:oldOption.showAsLabel }
 */
function _upgrade101Selectors(formView:FormView, viewType:FormViewType): void {
    walkElementChildren(formView, (formElement, parentElement) => {
        if(formElement.type === "selector") {
            if(!formElement.children)
                return WalkResult.SKIP_CHILDREN;

            if(parentElement['type'] === "conformity")
                formElement.lockedValues = true;    // conformity status selectors now have a locked value

            for(const option of formElement.children) {
                if(option.type === 'checkbox') {
                    switch(viewType) {
                        case FormViewType.SCREEN:
                            delete option['showAsLabel'];
                            break;
                        case FormViewType.PRINT:
                            option.label = option['showAsLabel'] ? option['showAsLabel'] : option.label;
                            delete option['showAsLabel'];
                            break;
                    }
                }
            }

            return WalkResult.SKIP_CHILDREN;
        }
        return WalkResult.CONTINUE;
    })
}

/**
 * Generate the number of entries form element in repeat sections on the screen layout.
 */
function _upgrade102RepeatSectionNumberOfEntries(formView: FormView, viewType: FormViewType): void {
    if (viewType === FormViewType.PRINT) {
        return;
    }

    walkElementChildren(formView, (formElement) => {
        if (formElement.type !== 'repeat_section') {
            return WalkResult.CONTINUE;
        }

        if (formElement.notRepeatedChildren !== undefined) {
            return WalkResult.SKIP_CHILDREN;
        }

        const numberOfEntriesElement = {
            id: IdGenerator.generateId(),
            modelId: formElement.lineCountModelId,
            label: l('Number of entries'),
            isDeletable: false,
            type: 'input',
            valueType: 'number',
        } as FormView.Input;

        formElement.notRepeatedChildren = [numberOfEntriesElement];

        return WalkResult.SKIP_CHILDREN;
    });
}

/**
 * We actually need to restore the showAsLabel in selector options with different semantics:
 * - label is always synchronized between layouts
 * - showAsLabel is not, when showAsLabel is not null, it overrides the label in selectors 
 */
function _upgrade102SelectorsOptions(formView:FormView, viewType:FormViewType): void {
    walkElementChildren(formView, (formElement, parentElement) => {
        if(formElement.type === "selector") {
            if(!formElement.children)
                return WalkResult.SKIP_CHILDREN;

            for(const option of formElement.children) {
                if(option.type === 'checkbox') {
                    // if a label is set, put it in showAsLabel 
                    option.showAsLabel = (option.checkedValue !== option.label) ? option.label : null;
                }
            }

            return WalkResult.SKIP_CHILDREN;
        }
        return WalkResult.CONTINUE;
    });
}

/**
 * Set the `isNumberOfEntries` flag on all number of entries elements.
 */
function _upgrade104RepeatSectionNumberOfEntries(formView: FormView, viewType: FormViewType): void {
    walkElementChildren(formView, (formElement) => {
        if (formElement.type !== 'repeat_section') {
            return WalkResult.CONTINUE;
        }

        if (formElement.notRepeatedChildren && formElement.notRepeatedChildren.length > 0) {
            formElement.notRepeatedChildren.forEach((notRepeatedChild) => {
                if (notRepeatedChild.type === 'input' && notRepeatedChild.modelId === formElement.lineCountModelId) {
                    notRepeatedChild.isNumberOfEntries = true;
                }
            });
        }

        return WalkResult.SKIP_CHILDREN;
    });
}

/**
 * Add the description field to conformities with observations, assign a name to the status and description elements in conformities
 */
function _upgrade105_106Conformities(formView: FormView, viewType: FormViewType): void {
    walkElementChildren(formView, (formElement) => {
        if(formElement.type !== 'conformity_observation' && formElement.type !== 'conformity')
            return WalkResult.CONTINUE;

        const status = walkElementChildren(formElement, (child) => {
            if(child.type === 'selector')
                return WalkResult.FOUND;
            return WalkResult.CONTINUE;
        }) as FormView.Selector;
        let description = walkElementChildren(formElement, (child) => {
            if(child.type === 'input' && child.valueType === 'string')
                return WalkResult.FOUND;
            return WalkResult.CONTINUE;
        }) as FormView.Input;

        // add description fields to conformities with obs
        if(formElement.type === 'conformity_observation') {
            const children = [];

            if(status)
                children.push(status);
            
            // use a deterministic model id, otherwise the layout sync will create multiple instances of the same type
            const descriptionModelId = IdGenerator.generateDeterministicId(formElement.modelId, 'description');
            if(!description || description.modelId !== descriptionModelId)
                description = {id:IdGenerator.generateId(), modelId:descriptionModelId, name:'description', label: l('Description'), type: 'input', valueType: 'string', rect: {left: null, top: null, right: 70, bottom: 9}};
            children.push(description);

            formElement.children = children;
        }

        // assign the default conformity field names
        if(status)
            status.name = 'status';
        if(description)
            description.name = 'description';
        
        return WalkResult.SKIP_CHILDREN;
    });
}

/**
 * Duplicate item did incorrectly share the bugModelId of the conformities with observation between instances
 */
function _upgrade108BugModelId(formView: FormView, viewType: FormViewType): void {
    const bugModelIds = new Set<string>();
    walkElementChildren(formView, (formElement) => {
        if(formElement.type !== 'conformity_observation')
            return WalkResult.CONTINUE;
        if(bugModelIds.has(formElement.bugModelId)) {
            formElement.bugModelId = IdGenerator.generateDeterministicId(formElement.modelId, 'bugModelId');
        }
        bugModelIds.add(formElement.bugModelId);
        return WalkResult.CONTINUE;
    });
}

/**
 * Normalize the rect coordinates precision, reduces the layout size and makes comparaisons easier because we no longer need an epsilon range
 */
function _upgrade109Rects(formView: FormView, viewType: FormViewType): void {
    walkElementChildren(formView, (formElement) => {
        formElement.rect = normalizeRect(formElement.rect);
        if(formElement.type === 'repeat_section') {
            formElement.templateRect = normalizeRect(formElement.templateRect);
            if(formElement.notRepeatedChildren) {
                formElement.notRepeatedChildren.forEach((notRepeatedChild) => {
                    notRepeatedChild.rect = normalizeRect(notRepeatedChild.rect);
                });
            }
        }
        return WalkResult.CONTINUE;
    });
}

export function upgradeFormView(formView:FormView, viewType:FormViewType): boolean {
    const version: number = formView.version ? formView.version : 99
    if (version === FormTypeViewVersion.CURRENT)
        return false;

    if(version <= 99) {
        switch(viewType) {
            case FormViewType.SCREEN:
                // remove duplicate modelId from SCREEN layouts, assign new modelId if needed
                _removeDuplicates(new Set<string>(), formView);
                break;
            case FormViewType.PRINT:
                // remove sections from PRINT layouts
                _removeSections(formView);
                break;
        }
    }

    if(version <= 100)
        _fix99SelectorOptions(formView);
    if(version <= 101)
        _upgrade101Selectors(formView, viewType);
    if(version <= 102) {
        _upgrade102RepeatSectionNumberOfEntries(formView, viewType);
        _upgrade102SelectorsOptions(formView, viewType);
    }
    if(version <= 104)
        _upgrade104RepeatSectionNumberOfEntries(formView, viewType);
    if(version <= 106)
        _upgrade105_106Conformities(formView, viewType);
    if(version <= 108)
        _upgrade108BugModelId(formView, viewType);
    if(version <= 109)
        _upgrade109Rects(formView, viewType);

    formView.version = FormTypeViewVersion.CURRENT;
    return true;
}

function _cloneElementChildren(doCopy: boolean, element: FormView.Element, children: FormView.Element[], fn?: (element: FormView.Element, parent?: FormView.Element) => FormView.Element): FormView.Item[] {
    let output = null;
    for (const child of children) {
        const clonedChild = _clone(doCopy, child, element, fn);
        if (clonedChild) {
            if (!output) {
                output = [];
            }
            output.push(clonedChild);
        }
    }

    return output;
}

/*----------------------------------------------------------------------------*/
function _clone(doCopy: boolean, element: FormView.Element, parent: FormView.Element, fn?: (element: FormView.Element, parent?: FormView.Element) => FormView.Element): FormView.Element {
    let ret:FormView.Element = null;

    const itemId = IdGenerator.generateId();
    const modelId = doCopy ? element.modelId : IdGenerator.generateId();

    switch(element.type) {
        case 'text': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'text' };
        } break;

        case 'input': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, valueType:element.valueType, type:'input' };
            if('placeholder' in element)
                ret.placeholder = element.placeholder;
            if('format' in element)
                ret.format = element.format;
            if('dateFormat' in element)
                ret.dateFormat = element.dateFormat;
            if('rows' in element)
                ret.rows = element.rows;
            if('characters' in element)
                ret.characters = element.characters;
            if('valueType' in element)
                ret.valueType = element.valueType;
        } break;

        case 'constant': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, valueType:element.valueType, value: element.value, type:'constant' };
        } break;

        case 'summary': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'summary', targetFieldModelId: element.targetFieldModelId, separator: element.separator };
        } break;

        case 'checkbox': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, valueType:element.valueType, checkedValue:element.checkedValue, showAsLabel:element.showAsLabel, type:'checkbox' };
        } break;

        case 'field': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, fieldName:element.fieldName, valueType:element.valueType, type:'field' };
        } break;

        case 'selector': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, valueType:element.valueType, showAs:element.showAs, lockedValues:element.lockedValues, type:'selector' };
        } break;

        case 'conformity': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'conformity' };
        } break;

        case 'conformity_observation': {
            const bugModelId = doCopy ? element.bugModelId : IdGenerator.generateId();
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, bugModelId:bugModelId, observationRequired:element.observationRequired, type:'conformity_observation' };
        } break;

        case 'photo': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'photo' };
        } break;

        case 'signature': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'signature' };
        } break;

        case 'section': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, type:'section' };
        } break;

        case 'repeat_section': {
            const templateRect = element.templateRect ? { ...element.templateRect } : null;
            const lineCountModelId = doCopy ? element.lineCountModelId : IdGenerator.generateId();
            const lines = element.lines.map((line) => ({ modelId: Object.assign({}, line.modelId) }));

            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, rowCountKind: element.rowCountKind, maxRowCount:element.maxRowCount, lineCountModelId:lineCountModelId, templateRect:templateRect, lines:lines, repeatDirection: element.repeatDirection, type:'repeat_section', entriesTitle:element.entriesTitle };
            
            if (element.notRepeatedChildren) {
                ret.notRepeatedChildren = _cloneElementChildren(doCopy, element, element.notRepeatedChildren, fn);
            }
        } break;

        case 'page_section': {
            ret = { id:itemId, modelId:modelId, label:element.label, name: element.name, isDeletable: element.isDeletable, sectionType:element.sectionType, paperFormat: element.paperFormat, type:'page_section' };
            if('background' in element)
                ret.background = element.background;
        } break;

        case 'formula': {
            ret = { id: itemId, modelId: modelId, label: element.label, name: element.name, isDeletable: element.isDeletable, formulaExpr: element.formulaExpr, valueType: element.valueType, dateFormat: element.dateFormat, type:'formula' }
        } break;

        default:
            throw new IllegalStateError(`No such element type: ${element['type']}`);
    }

    if('hiddenExpr' in element)
        ret.hiddenExpr = element.hiddenExpr;
    if('rect' in element)
        ret.rect = Object.assign({}, element.rect);
    if('font' in element)
        ret.font = Object.assign({}, element.font);
    if (element.children) {
        ret.children = _cloneElementChildren(doCopy, element, element.children, fn);
    }

    if (ret.type === 'repeat_section') {
        const hasLines = ret.lines && ret.lines.length > 0;
        if (!doCopy && hasLines) {
            const templateFieldModelIds = ret.children.map((child) => child.modelId);
            ret.lines = ret.lines.map(line => {
                const modelId = {};

                for (const templateFieldModelId of templateFieldModelIds) {
                    modelId[templateFieldModelId] = IdGenerator.generateId();
                }

                return {
                    ...line,
                    modelId: modelId
                };
            });
        }
    }

    return fn ? fn(ret,parent) : ret;
}

/**
 * Recursively clone an element and its children.
 * 
 * @param doCopy    If true the new element will be binded to the cloned one.
 * @param element   The element to clone.
 * @param fn        The callback invoked on each element.
 */
export function clone(doCopy: boolean, element:FormView.Element, fn?:(element:FormView.Element,parent?:FormView.Element)=>FormView.Element):FormView.Element {
    return _clone(doCopy, element, null, fn);
}

/**
 * Find if the formView has a formula Item with a mumber valueType
 * @param formView 
 */
export function hasFormulaNumber(formView: FormView): boolean {
    let isFormulaNumber = false;
    walkElementChildren(formView, (element) => {
        if(element.type === 'formula' && element.valueType === 'number') {
            isFormulaNumber = true;
            return WalkResult.FOUND;
        }
        return WalkResult.CONTINUE;
    });
    return isFormulaNumber;
}

export function isEqual(a:FormView.Element, b:FormView.Element):boolean {
    for(const k in a) {
        if(k === 'children')
            continue;
        const va = a[k];
        const vb = b[k];
        if(va !== vb) {
            if(va === null || vb === null || typeof va !== 'object' || typeof vb !== 'object')
                return false;
            if(JSON.stringify(va) !== JSON.stringify(vb))
                return false;
        }
    }
    for(const k in b) {
        if(!(k in a))
            return false;
    }
    return true;
}

export const enum WalkResult {
    CONTINUE = 0,       // continue walking
    FOUND = 1,          // stop walking
    SKIP_CHILDREN = 2   // continue walking and skip descendants
};

/**
 * Walk form view elements in depth first order
 * 
 * @param element form view element to walk
 * @param fn walk function
 * @return the element found  
 */
export function walkElementChildren(element:FormView.Element | FormView, fn:(child:FormView.Element,parent?:FormView.Element | FormView)=>WalkResult):FormView.Element | FormView | null {
    if (element === null)
        return null;
    if(!element.children)
        return null;

    for(const child of element.children) {
        const ret = fn(child,element);
        if(ret === WalkResult.FOUND)
            return child;
        else if(ret === WalkResult.SKIP_CHILDREN)
            continue;
        
        const found = walkElementChildren(child, fn);
        if(found)
            return found;
    }

    return null;
}
