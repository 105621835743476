import { endOfDay, isBefore, startOfDay } from "date-fns";
import { z } from "zod";

import { makeRangeExpression } from "@archipad-js/core/query";
import { Range } from "@archipad-js/core/range";

import { formatTextList } from "@core/helpers/textHelper";
import {
    CreateEntityPayload,
    createEntityWithProperties,
    UpdateEntityPayload,
    updateEntityWithProperties,
} from "@core/services/orm/orm.helper";
import { Query, query } from "@core/services/orm/query";

import { WorkflowUserManager } from "@archipad/backend/project/workflowUserManager";

import { PersonEntity } from "@archipad-models/models/PersonEntity";
import { PhaseEntity } from "@archipad-models/models/PhaseEntity";
import { ProjectEntity } from "@archipad-models/models/ProjectEntity";
import { ProjectUserEntity } from "@archipad-models/models/ProjectUserEntity";
import { RegionEntity } from "@archipad-models/models/RegionEntity";
import { VisitEntity } from "@archipad-models/models/VisitEntity";
import { VisitMemberEntity } from "@archipad-models/models/VisitMemberEntity";
import { VisitTypeEntity } from "@archipad-models/models/VisitTypeEntity";
import { VisitTypeService } from "@archipad/services/visitType.service";
import { PhaseService } from "@archipad/services/phase.service";

export class VisitService {
    constructor(
        private readonly workflowUserManager: WorkflowUserManager,
        private readonly phaseService: PhaseService,
        private readonly visitTypeService: VisitTypeService,
    ) {}

    //#region Visit

    /**
     * Create a Visit entity with the given properties.
     * 
     * When no creator provided, the current user will be used.
     * When no visit type provided, the default visit type of the project will be used.
     * When no phase provided, the default phase of the bug group will be used.
     */
    public createVisit(plainPayload: CreateVisitPayload): VisitEntity {
        const project = plainPayload.project;
        const entityContext = project.getContext();

        plainPayload.visitType = plainPayload.visitType ?? this.visitTypeService.findVisitType({
            where: {
                project,
                isDefault: true,
            },
        });

        if (plainPayload.creator === undefined) {
            plainPayload.creator = this.workflowUserManager.getCurrentUser(project);
        }

        if (plainPayload.phase === undefined) {
            plainPayload.phase = plainPayload.visitType?.phase ?? this.phaseService.getDefaultPhaseForBugGroup(project, plainPayload.visitType?.bugGroup) ?? null;
        }

        const payload = VisitSchema.parse(plainPayload);

        const visit = createEntityWithProperties(entityContext, "Visit", payload);
        return visit;
    }

    /**
     * List the visits according to the provided options, default to all visits of project sorted by date then id
     * @param options - options passed to the query, with **where** as AND query clause and **orderBy** as a String
     */
    public listVisits(options: { where: VisitWhereInput; orderBy?: string }): readonly VisitEntity[] {
        const defaultOrderBy = "date";

        const q = this.makeVisitQuery({
            ...options,
            orderBy: options.orderBy ?? defaultOrderBy,
        });

        const resultSet = q.execute();
        const visits = resultSet.sortedEntities();

        return visits;
    }

    public findVisit(options: { where: VisitWhereInput; orderBy: string }): VisitEntity | undefined {
        const { where, orderBy } = options;
        const q = this.makeVisitQuery({ where, orderBy });

        const resultSet = q.execute();
        const lastVisit = resultSet.firstEntity();

        return lastVisit ?? undefined;
    }

    private makeVisitQuery(options: { where: VisitWhereInput; orderBy?: string }): Query<VisitEntity> {
        const { where, orderBy } = options;
        const { project, creators, dateRange, phases, regions, visitTypes } = where;
        const entityContext = project.getContext();

        const q = query(entityContext, "Visit")
            .predicateWithFormat("project == {project}", { project })
            .orderBy(formatTextList([orderBy, "id asc"], ", "));

        if (phases) {
            q.predicateWithFormat("phase IN {phases}", { phases: phases });
        }

        if (creators) {
            q.predicateWithFormat("creator IN {creators}", { creators: creators });
        }

        if (dateRange) {
            q.predicate(makeRangeExpression({ fieldName: "date", range: dateRange }));
        }

        if (regions) {
            q.predicateWithFormat("region IN {regions}", { regions: regions });
        }

        if (visitTypes) {
            q.predicateWithFormat("visitType IN {visitTypes}", { visitTypes: visitTypes });
        }

        return q;
    }

    public updateVisit(visit: VisitEntity, plainPayload: UpdateVisitPayload): void {
        const payload = VisitSchema.parse(plainPayload);
        updateEntityWithProperties(visit, payload);
    }

    public deleteVisit(visit: VisitEntity): void {
        visit.getContext().deleteEntity(visit);
    }

    public isVisitAnteriorTo(visit: VisitEntity, comparisonDate: Date): boolean {
        return isBefore(endOfDay(visit.date), startOfDay(comparisonDate));
    }

    //#endregion

    //#region VisitMembers

    public findMember(options: { where: { visit: VisitEntity; person: PersonEntity } }): VisitMemberEntity {
        const { visit, person } = options.where;
        return visit.members.find((member) => member.person.id === person.id);
    }

    /**
     * List VisitMembers according to where clause
     * @param where.visits VisitEntity list to fetch VisitMembers from
     */
    public listVisitMembers(options: {
        where: { project: ProjectEntity; visits?: VisitEntity[] };
    }): readonly VisitMemberEntity[] {
        const { project, visits } = options.where;

        const q = query(project.getContext(), "VisitMember")
            .predicateWithFormat("person != null")
            .predicateWithFormat("visit.project == {project}", { project })
            .orderBy("person.name(natural)");

        if (visits) {
            q.predicateWithFormat("visit IN {visits}", { visits: visits });
        }

        const resultSet = q.execute();
        const members = resultSet.sortedEntities();

        return members;
    }

    /**
     * List VisitMembers of said visit and VisitMembers convened to said visit
     * (Convened to a visit means a VisitMember.convokedOn and visit.date are on the same day)
     */
    public listVisitMembersConvenedToVisit(visit: VisitEntity): readonly VisitMemberEntity[] {
        const project = visit.project;

        const q = query(project.getContext(), "VisitMember")
            .predicateWithFormat("person != null")
            .predicateWithFormat("visit.project == {project}", { project })
            .orderBy("person.name(natural)");

        const visitStartDate = startOfDay(visit.date);
        const visitEndDate = endOfDay(visit.date);

        q.predicateWithFormat("visit == {visit} OR (convokedOn >= {startOfDay} AND convokedOn <= {endOfDay})");
        const resultSet = q.execute({
            visit: visit,
            startOfDay: visitStartDate,
            endOfDay: visitEndDate,
        });

        const members = resultSet.sortedEntities();

        return members;
    }

    /**
     * Create a VisitMember entity with the given properties.
     */
    public createVisitMember(payload: CreateEntityPayload<VisitMemberEntity>): VisitMemberEntity {
        const entityContext = payload.visit.project.getContext();
        const visitMember = createEntityWithProperties(entityContext, "VisitMember", payload);
        return visitMember;
    }

    /**
     * Update the given VisitMember entity with the given properties.
     */
    public updateVisitMember(visitMember: VisitMemberEntity, payload: UpdateEntityPayload<VisitMemberEntity>): void {
        updateEntityWithProperties(visitMember, payload);
    }

    /**
     * Delete a VisitMember.
     */
    public deleteVisitMember(visitMember: VisitMemberEntity): void {
        visitMember.visit.getContext().deleteEntity(visitMember);
    }

    //#endregion
}

const VisitSchema = z.object({
    visitType: z.instanceof(VisitTypeEntity).optional(),
    phase: z.instanceof(PhaseEntity).optional().nullable(),
    creator: z.instanceof(ProjectUserEntity).optional(),
}).passthrough();

type VisitSchema = z.infer<typeof VisitSchema>;

export type CreateVisitPayload = VisitSchema & CreateEntityPayload<VisitEntity>;
export type UpdateVisitPayload = VisitSchema & UpdateEntityPayload<VisitEntity>;


export interface VisitWhereInput {
    project: ProjectEntity;
    creators?: readonly ProjectUserEntity[];
    dateRange?: Range<Date>;
    phases?: readonly PhaseEntity[];
    regions?: readonly RegionEntity[];
    visitTypes?: readonly VisitTypeEntity[];
}
