import "reflect-metadata";

import { inject, injectable } from "inversify";

import { DIID } from "@archipad-js/dependency-injection";

import { TrustedDate, TrustedTime } from "./trusted-time.service.types";

export const Trusted_Time_DIID = DIID<TrustedTime>("TrustedTime");
export const Trusted_Time_Service_DIID = DIID<TrustedTimeService>("TrustedTimeService");

/**
 * TrustedTimeService uses the server time (retrieved with a bootstrap call)
 * to compute a server-synchronized Timestamp or Date.
 *
 * These timestamps and dates can then be used to hash login credentials
 * or safely generated patchIds.
 */
@injectable()
export class TrustedTimeService {
    public readonly serverDeltaTimeSeconds: number;

    constructor(@inject(Trusted_Time_DIID.symbol) private serverTimeSeconds: TrustedTime) {
        this.serverDeltaTimeSeconds = (Math.round(new Date().getTime() / 1000) - this.serverTimeSeconds) | 0;
    }

    /**
     * Returns a trusted timestamp **without milliseconds**
     */
    public get trustedTime(): TrustedTime {
        const time = new Date().getTime();
        return (Math.round(time / 1000) - this.serverDeltaTimeSeconds) | 0;
    }

    /**
     * Returns a trusted timestamp **with milliseconds**
     */
    public get trustedMicroTime(): TrustedTime {
        const time = new Date().getTime();
        return time - this.serverDeltaTimeSeconds * 1000;
    }

    /**
     * Returns a new trusted {@link TrustedDate}
     */
    public get trustedDate(): TrustedDate {
        return new Date(this.trustedMicroTime);
    }
}
