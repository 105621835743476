import { BehaviorSubject, Observable, Observer, OperatorFunction, pipe, Subject, throwError } from 'rxjs';
import { debounceTime, filter, finalize, startWith } from 'rxjs/operators';

import { IllegalArgumentError, IllegalStateError, UnsupportedOperationError } from '@archipad-js/core/error';
import { EntityType, EntityTypes, ExportKind, ORMError, PropertyDescription, PropertyDescriptionMap, RelationDescription } from '@archipad-js/core/orm';
import { Delegate, DynNode, makePathExpression } from '@archipad-js/core/query';

import { FileDescriptor } from '@core/services/file/file';
import { generateId } from '@core/services/orm/idGenerator';


declare global {
    // tslint:disable-next-line:no-empty-interface
    interface EntityTypeMap {
    }
}


// #region Rx operators //
/**
 * Filter some notifications based on their type from notifications pipe
 *
 * @param types
 * @param strict Filter or not the null values
 */
export function keepOnly<T extends Notification>(types: NotificationType[], strict: boolean = false): OperatorFunction<T, T> {
    const strictFilter = (notification: T) => notification ? types.indexOf(notification[0]) !== -1 : false;
    const looseFilter = (notification: T) => !notification || types.indexOf(notification[0]) !== -1;
    
    const filteringFn = strict ? strictFilter : looseFilter;

    return function (source: Observable<T>) {
        return source.pipe(
            filter(filteringFn),
        )
    }
}

/**
 * Filters notifications and keep only those that concerns the given entity names.
 * 
 * @example
 * entityContext.notifications.pipe(
 *   ifEntityConcerned('PlanningEvent', 'PlanningEventCategory'),
 *   ...
 * );
 */
export function ifEntityConcerned<K extends keyof EntityTypeMap, T = EntityTypeMap[K]>(...entityNames: K[]): OperatorFunction<Notification<BaseEntity>, Notification<T>>;
export function ifEntityConcerned<T extends BaseEntity>(...entityNames: string[]): OperatorFunction<Notification<BaseEntity>, Notification<T>> {
    return function(source: Observable<Notification<T>>) {
        return source.pipe(
            keepOnly([
                NotificationType.entityCreated,
                NotificationType.willDeleteEntity, 
                NotificationType.entityDeleted,
                NotificationType.entityUpdated,
                NotificationType.attachmentUpdated,
            ]),
            filter((notification) => {
                if (notification === null) {
                    return true;
                }

                const [, entity] = notification;

                const isConcerned = entityNames.includes(entity?.entityType.entityName);
                return isConcerned;
            }),
        );
    }
}

/**
 * Filters notifications and keep those that are related to the given entity IDs.
 * @example
 * ```typescript
 *   entityContext.notifications.pipe(
 *     ofEntity([{ id: selectedId, visit }]),
 *     ...
 *   );
 * ```

 */
 export function ofEntity<T extends BaseEntity>(allowedEntities: Array<T | Pick<T, "id">>): OperatorFunction<Notification, Notification<T>> {
     return pipe(
         keepOnly( [
             NotificationType.entityCreated,
             NotificationType.willDeleteEntity,
             NotificationType.entityDeleted,
             NotificationType.entityUpdated,
             NotificationType.attachmentUpdated,
         ] ),
         filter( ( notification ): notification is Notification<T> | null => {
             if ( notification === null ) {
                 return true;
             }

             const [ , entity ] = notification ?? [];

             if ( !entity ) {
                 return false;
             }
             const isAllowed = allowedEntities.find( ( entityId => entityId.id === entity.id ) ) !== undefined;
             return isAllowed;
         } ),
    );
}

/**
 * In the context of the given entity : emit only the requested notifications for a subset of entities
 * @param entityNames emit only notifications concerning those types of entity
 * @param notifications filter notifications types
 */
export function fromEntityNotifications<T extends BaseEntity>(
    contextEntity: BaseEntity,
    entityNames: string[],
    notifications: NotificationType[])
    : Observable<Notification<T>>
export function fromEntityNotifications<K extends keyof EntityTypeMap, T = EntityTypeMap[K]>(
    contextEntity: BaseEntity,
    entityNames: K[],
    notifications: NotificationType[])
    : Observable<Notification<T>> {
    return contextEntity
        .getContext()
        .notifications.pipe(
            startWith(null as Notification),
            keepOnly(notifications),
            ifEntityConcerned<K, T>(...entityNames),
            debounceTime(0));
}

/**
 * Emit when entities are created/updated/deleted in the given context
 * @see {@link fromEntityNotifications()}
 */
export function fromEntityChangeNotifications(contextEntity: BaseEntity, ...entityNames: Parameters<typeof ifEntityConcerned>[0][]) {
    return fromEntityNotifications(
        contextEntity,
        entityNames,
        [NotificationType.entityCreated, NotificationType.entityUpdated, NotificationType.entityDeleted]);
}

// #endregion
//------------------------------------------------------------------------------

function isCalculatedPropertyDefined(dynamicEntity:DynamicEntity, name:string) : boolean {
    // find a getter property defined in an entity extension `XxxEntity-ext.ts`
    const property = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(dynamicEntity), name);
    return Boolean(property);
}

function defineGetter(property:PropertyDescriptor, name:string) {
    property.get = function() {
        return this._values[name];
    };
}

// TODO implement optional
function defineSetter(property:PropertyDescriptor, name:string) {
    property.set = function(val) {
        if (val instanceof BaseEntity) {
            if (this.getContext() !== null && this.getContext() !== val.getContext()) {
                const error = new ORMError('Illegal attempt to make a relation between two entities from different EntityContexts');
                error.addContext('Archipad', { 'source entity': {
                    'entityName': this.entityType.entityName,
                    'entityId': this.id,
                    'propertyName': name,
                }});
                error.addContext('Archipad', { 'target entity': {
                    'entityName': val.entityType.entityName,
                    'entityId': val.id,
                }});
                throw error;
            }
        }

        const oldVal = this._values[name];
        if(val !== oldVal) {
            this._values[name] = val ?? null;
            if(!this._entityContext) {
                throw new ORMError('Illegal assignment to a deleted orm object', this.entityType.entityName, this.id);
            }
            if(this._entityContext) {
                this._entityContext.entityUpdated(this, name, oldVal, val);
            }
        }
    };
}

function defineDynamicProperty(entityType:EntityType, name:string, dynamicEntity: DynamicEntity) : void {
    const metadata = entityType.metadata[name];
    if (metadata.calculated) {
        return;
    }

    const property : PropertyDescriptor = { enumerable: true };
    defineGetter(property, name);
    defineSetter(property, name);

    dynamicEntity._properties[name] = property;
}

function defineDynamicPropertyValueFromDescriptor(entityType:EntityType, name:string, dynamicEntity: DynamicEntity, property: PropertyDescriptor) : void {
    dynamicEntity._properties[name] = property;
}

// aliases
const defineDynamicValueProperty = defineDynamicProperty;
const defineDynamicRelation = defineDynamicProperty;

//------------------------------------------------------------------------------



export enum NotificationType {
    'entityCreated' = 'entityCreated',
    'entityUpdated' = 'entityUpdated',
    'willDeleteEntity' = 'willDeleteEntity',
    'entityDeleted' = 'entityDeleted',
    'attachmentUpdated' = 'attachmentUpdated',
    'didCommit' = 'didCommit'
};

//-----------------------------------------------------------------------------
export type Notification<T = BaseEntity> = 
    [ NotificationType.entityCreated, T ] |
    [ NotificationType.willDeleteEntity, T ] |
    [ NotificationType.entityDeleted, T ] |
    [ NotificationType.entityUpdated, T, string, any, any ] |
    [ NotificationType.attachmentUpdated, T, string, any, any ] |
    [ NotificationType.didCommit ]

export interface Validator {
    /**
     * Validate an orm notification
     * 
     * @param notification the orm notification
     * @throw an error if the orm change notification is invalid 
     */
    validateNotification(notification: Notification): void;
}

export type FlushDataHandler = ()=> Observable<void>;

//------------------------------------------------------------------------------
export type Attachment = FileDescriptor;

export interface AttachmentList {
    [index: string]: Attachment;
}

//------------------------------------------------------------------------------




// entity type interface private to the orm engine
interface DynamicEntity {
    _properties:PropertyDescriptorMap;

    entityType:EntityType;
}
interface DynamicEntityConstructor {
    (entityContext:EntityContext, entityId:string): void;
    prototype : DynamicEntity
}


// entity type base class
export abstract class BaseEntity {
    private _entityContext: EntityContext;
    private _attachments: { [attachmentKey:string]:Attachment };

    /**
     * If False :
     *  - Avoid to changes entity's values
     *  - Allow entity creation / deletion
     */
    private _allowUndoRedo: boolean;
    _values: { [key:string]:unknown };
    _updating: boolean;

    abstract id:string;
    public readonly entityType:EntityType;

    constructor(entityContext:EntityContext,entityMetadata:PropertyDescriptionMap) {
        this.init(entityContext,entityMetadata);
    }

    public set allowUndoRedo(allow: boolean) {
        this._allowUndoRedo = allow;
    }

    public get allowUndoRedo(): boolean {
        return this._allowUndoRedo;
    }

    init(entityContext:EntityContext,entityMetadata:PropertyDescriptionMap) {
        this._entityContext = entityContext;
        this._attachments = {};
        this._values = {};
        this._updating = false;
        this._allowUndoRedo = true;

        // set defaults
        for(const key in entityMetadata) {
            const prop = entityMetadata[key];
            this._values[key] = this.getDefaultValueFor(prop);
        }
    }

    attachEntity(entityContext) {
        this._entityContext = entityContext;
    }
    detachEntity() {
        this._entityContext = null;
    }

    setAttachmentForKey(key:string, attachment:FileDescriptor) {
        if(typeof key !== 'string') {
            throw new IllegalArgumentError('Invalid key for setAttachmentForKey', 'key');
        }

        key = key.normalize("NFD");
        const oldAttachment = this._attachments[key];
        if(oldAttachment != attachment) {
            if(attachment) {
                if(!this._entityContext) {
                    throw new ORMError('Attempting to set an attachment on a removed entity', this.entityType.entityName, this.id);
                }
                this._attachments[key] = attachment;
            } else {
                delete this._attachments[key];
            }
            if(this._entityContext) {
                this._entityContext.entityAttachmentUpdated(this, key, oldAttachment, attachment);
            }
        }
    }
    getAttachmentForKey(key: string): Attachment|undefined {
        key = key.normalize("NFD");
        return this._attachments[key];
    }
    getAttachments(): AttachmentList {
        return this._attachments;
    }
    hasAttachments() {
        for(const _key in this._attachments) {
            return true;
        }
        return false;
    }

    getDefaultValueFor(prop:PropertyDescription) {
        if(prop.relation === true) {
            // initialize multiple relations
            return (prop.single) ? null : [];
        }

        if(prop.defaultValue == null) {
            // no default value provided
            if(prop.optional) {
                return null;
            } else {
                switch(prop.type) {
                    case 'number':
                        return 0;
                    case 'boolean':
                        return false;
                    case 'date':
                        return new Date();
                    case 'string':
                        return '';
                    default:
                        throw new IllegalArgumentError("Can't provide a defaultValue for: " + JSON.stringify(prop));
                }
            }
        }

        let defaultValue = prop.defaultValue;

        if(typeof defaultValue == 'object' && defaultValue['func:']) {
            throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
        }

        switch(prop.type) {
            case 'number':
                if(typeof defaultValue == 'number') {
                    break;
                }
                throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
            case 'boolean':
                if(typeof defaultValue == 'number') {
                    defaultValue = (defaultValue !== 0);
                    break;
                }
                if(typeof defaultValue == 'boolean') {
                    break;
                }
                throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
            case 'string':
                if(typeof defaultValue == 'string') {
                    break;
                }
                if (typeof defaultValue == 'number' && prop.originType == 'int64') {
                    if (defaultValue < -1 || defaultValue > 1) {
                        throw new IllegalArgumentError('Unexpected defaultValue on int64 (only string or -1, 0, 1 are allowed): ' + JSON.stringify(prop));
                    }
                    defaultValue = defaultValue.toString();
                    break;
                }
                throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
            case 'date':
                if(typeof defaultValue == 'number') {
                    defaultValue = new Date(defaultValue);
                    break;
                }
                throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
            default:
                throw new IllegalArgumentError('Unexpected defaultValue: ' + JSON.stringify(prop));
        }

        return defaultValue;
    }

    // allow accessing context
    getContext() {
         return this._entityContext;
    }

    /**
     * Check if the entity has been persisted (if the user have synchronized its changes).
     */
    isPersisted(): boolean {
        const context = this.getContext();

        if (!context) {
            throw new ORMError('Entity is deleted', this.entityType.entityName, this.id);
        }

        return !context.createdEntities[this.entityType.entityName][this.id];
    }

    /**
     * Check if the entity has been deleted.
     */
    isDeleted(): boolean {
        const context = this.getContext();

        return !context;
    }

    // toString for debugging
    toString() {
        let str = this.entityType.entityName + ' {\n';
        const metadata = this.entityType.metadata;
        for(const key in metadata) {
            let value = this[key];
            const propertyDescription = metadata[key];
            if(value && propertyDescription.relation) {
                if(propertyDescription.single) {
                    value = value.id;
                }
                else {
                    value = JSON.stringify(value.map(function(o) { return o.id; }));
                }
            }
            str += '    ' + key + ':' + value + '\n';
        }
        str += '}\n';
        return str;
    }
}


//------------------------------------------------------------------------------
export interface DynamicEntities {
    dynamicEntityByName: Readonly<Record<string, DynamicEntityConstructor>>;
}


export class DynamicEntitiesImpl implements DynamicEntities {
    private _dynamicEntityByName: Record<string, DynamicEntityConstructor>;


    constructor(
        private readonly entityTypes: EntityTypes,
        private readonly entityClasses: ReadonlyMap<string, typeof BaseEntity>,
    ) {
        this.buildDynamicEntities();
    }


    private get byIndex(): ReadonlyArray<EntityType> {
        return this.entityTypes.byIndex;
    }


    private buildDynamicEntities() : void {
        this._dynamicEntityByName = {};

        // pass : create the dynamic entity
        for(const entityType of this.byIndex) {
            // create the dynamic entity (which is the entity constructor function)
            const dynamicEntity : DynamicEntityConstructor = this.createDynamicEntity(entityType.entityName, entityType);

            // add entity to the collection
            this._dynamicEntityByName[entityType.entityName] = dynamicEntity;            
        }

        // pass : build calculated properties metadata
        for(const entityType of this.byIndex) {
            for(const propertyName in entityType.metadata) {
                const property = entityType.metadata[propertyName];

                const dynamicEntity:DynamicEntityConstructor = this.dynamicEntityByName[entityType.entityName];
                const defined = isCalculatedPropertyDefined(dynamicEntity.prototype, property.name);
                if (property.calculated) {
                    if (!defined) {
                        throw new IllegalStateError(`Calculated property '${property.name}' is not defined in '${entityType.entityName}' entity`);
                    }
                }

                // Check if the relation is a calculated property and if it has an inverse relation
                if (defined && property.relation === true && !property.isInverse && property.inverseField) {
                    /**
                    * Calculated relations with inverse relation are not supported for now.
                    * This is because we don't find any use case for this.
                    * @link [AP-8150](https://bigsool-archipad.atlassian.net/browse/AP-8150)
                    */
                    throw new IllegalStateError(`Calculated property '${property.name}' cannot have an inverse relation`);
                }
            }
        }

        // pass : define properties (attributes and relations)
        for(const entityType of this.byIndex) {
            const dynamicEntity:DynamicEntityConstructor = this.dynamicEntityByName[entityType.entityName];

            for(const propertyName in entityType.metadata) {
                const property = entityType.metadata[propertyName];

                // define attributes
                if (property.relation === false) {

                    if (property.primaryKey) {
                        const propertyDescriptor : PropertyDescriptor = {
                            enumerable: true,
                            get: function(this:BaseEntity): string {
                                return this._values['id'] as string;
                            },
                            set: function(this:BaseEntity, v:string): void {
                                throw new UnsupportedOperationError('The modification of an entity id is forbidden.');
                            },
                        };

                        defineDynamicPropertyValueFromDescriptor(entityType, property.name, dynamicEntity.prototype, propertyDescriptor);
                        // alias the primary key to "id"
                        defineDynamicPropertyValueFromDescriptor(entityType, 'id', dynamicEntity.prototype, propertyDescriptor);
                    }
                    else {
                        defineDynamicValueProperty(entityType, property.name, dynamicEntity.prototype);
                    }
                }
                // define relations
                else {
                    defineDynamicRelation(entityType, property.name, dynamicEntity.prototype);    
                }
            }

            // finish the dynamic entity
            Object.defineProperties(dynamicEntity.prototype, dynamicEntity.prototype._properties);
        }
    }


    private createDynamicEntity(entityName:string, entityType:EntityType) : DynamicEntityConstructor {
        const dynamicEntityConstructor = function(this:BaseEntity, entityContext:EntityContext, entityId:string) {
            BaseEntity.prototype.init.call(this, entityContext, this.entityType.metadata);
            this._values['id'] = entityId;
            Object.seal(this);
        }

        const dynamicEntity : DynamicEntityConstructor = dynamicEntityConstructor;

        // load entity type base class if there is one
        const entityClass = this.entityClasses.get(entityName) ?? BaseEntity;

        // inherits from entity class
        dynamicEntity.prototype = Object.create(entityClass.prototype);

        dynamicEntity.prototype._properties = {};

        // allow accessing the entity type from the entity
        dynamicEntity.prototype.entityType = entityType;

        return dynamicEntity;
    }


    public get dynamicEntityByName(): Readonly<Record<string, DynamicEntityConstructor>> {
        return this._dynamicEntityByName;
    }
}


//------------------------------------------------------------------------------
export class EntityContext {
    private _validatorsEnabled = false;

    private _isDirty:boolean;
    private _flushDataHandler:FlushDataHandler;

    // all orm modification since last commit()
    private _createdEntities:{[entityName:string]:{[entityId:string]:BaseEntity}};
    private _modifiedEntities:{[entityName:string]:{[entityId:string]:any}};
    private _deletedEntities:{[entityName:string]:{[entityId:string]:boolean}};

    private _createdAttachments:{[entityName:string]:{[entityId:string]:{[key:string]:any}}};
    private _updatedAttachments:{[entityName:string]:{[entityId:string]:{[key:string]:any}}};

    private _notifications: Subject<Notification>;
    private _notificationsObservable: Observable<Notification>;
    private _subscribers: Set<Observer<unknown>> = new Set();
    private _subscriberCount$: BehaviorSubject<number>;

    private _entitiesByType:{[name:string]:{[entityId:string]:BaseEntity}};     // all orm entities
    private _deletedEntitiesByType:{[name:string]:{[entityId:string]:boolean}}; // entities that once existed but were deleted
    
    private _modelVersion: number;

    private _modelMinVersionSub$: BehaviorSubject<number>;


    get modelVersion(): number {
        return this._modelVersion;
    }

    get modelMinVersion(): number {
        return this._modelMinVersionSub$.getValue();
    }

    get modelMinVersion$(): Observable<number> {
        return this._modelMinVersionSub$.asObservable();
    }

    patchHandlers:any[];

    constructor(
        public entityTypes: EntityTypes,
        private dynamicEntities: DynamicEntities,
        private validators: readonly Validator[],
        metadata: any,
    ) {
        this._subscribers.clear();
        const subscriberCount$ = new BehaviorSubject<number>(0);
        const notifications = new Subject<Notification>()
        const notificationsObservable = new Observable<Notification>((subscriber) => {
            // on unsubscribe
            this._subscribers.add(subscriber);
            subscriberCount$.next(this._subscribers.size);
            return notifications.pipe(
                // on unsubscribe
                finalize(() => {
                    this._subscribers.delete(subscriber);
                    subscriberCount$.next(this._subscribers.size);
                }),
            ).subscribe(subscriber);
        });
        
        this._isDirty = false;

        this._notifications = notifications;
        this._notificationsObservable = notificationsObservable;
        this._subscriberCount$ = subscriberCount$;

        this._modelVersion = parseInt(metadata.versionIdentifiers[0], 10);
        this._modelMinVersionSub$ = new BehaviorSubject(metadata.minVersion);

        this.patchHandlers = metadata.patchHandlers || [];

        this._entitiesByType = {};
        for(const entityName in this.entityTypes.byName) {
            this._entitiesByType[entityName] = {};
        }
        this._deletedEntitiesByType = {};
        for(const entityName in this.entityTypes.byName) {
            this._deletedEntitiesByType[entityName] = {};
        }

        this.commit();
    }

    dispose() {
        for(const entityType in this._entitiesByType) {
            const entities = this._entitiesByType[entityType];
            for(const entityId in entities) {
                const entity = entities[entityId];
                entity.detachEntity();
            }
        }

        this.validators = null;

        this._flushDataHandler = null;
    
        this._createdEntities = null;
        this._modifiedEntities = null;
        this._deletedEntities = null;
    
        this._createdAttachments = null;
        this._updatedAttachments = null;
    
        this._notifications = null;
    
        this.entityTypes = null;
        this._entitiesByType = null;
        this._deletedEntitiesByType = null;
    }

    private _addRelation(target:BaseEntity, name:string, property:RelationDescription, val:BaseEntity) {
        if(property.single) {
            target[name] = val;
        } else {
            target[name].push(val);
        }
    }
    private _removeRelation(target:BaseEntity, name:string, property:RelationDescription, val:BaseEntity) {
        if(property.single) {
            target[name] = null;
        } else {
            const rel = target[name];
            if(!rel) {
                return;
            }
            const idx = rel.indexOf(val);
            if(idx >= 0) {
                rel.splice(idx, 1);
            }
        }
    }
    private _updateRelationInverse(target:BaseEntity, property:RelationDescription, val:BaseEntity, oldVal:BaseEntity) {
        if(!property.inverseField) {
            return;
        }

        // maintain the other side of the relation
        const inverseProperty = property.target.metadata[property.inverseField] as RelationDescription;
        if(oldVal && !oldVal._updating) {
            this._removeRelation(oldVal, property.inverseField, inverseProperty, target);
        }
        if(val && !val._updating) {
            this._addRelation(val, property.inverseField, inverseProperty, target);
        }
    }

    get createdEntities(): {readonly [entityName:string]:{readonly [entityId:string]:BaseEntity}} { return this._createdEntities; }
    get modifiedEntities():{readonly [entityName:string]:{readonly [entityId:string]:any}} { return this._modifiedEntities; }
    get deletedEntities():{readonly [entityName:string]:{readonly [entityId:string]:boolean}} { return this._deletedEntities; }

    get createdAttachments():{readonly [entityName:string]:{readonly [entityId:string]:{readonly [key:string]:any}}} { return this._createdAttachments; }
    get updatedAttachments():{readonly [entityName:string]:{readonly [entityId:string]:{readonly [key:string]:any}}} { return this._updatedAttachments; }

    get notifications(): Observable<Notification> { return this._notificationsObservable; }
    get subscribers(): ReadonlySet<Observer<unknown>> { return this._subscribers; }
    get subscriberCount(): Observable<number> { return this._subscriberCount$; }

    get entitiesByType():{ readonly [name:string]:{ readonly [entityId:string]:BaseEntity} } { return this._entitiesByType; }

    commit() {
        this._createdEntities = {};
        this._modifiedEntities = {};
        this._deletedEntities = {};

        this._createdAttachments = {};
        this._updatedAttachments = {};

        this.setDirty(false);

        for(const entityName in this.entityTypes.byName) {
            this._createdEntities[entityName] = {};
            this._modifiedEntities[entityName] = {};
            this._deletedEntities[entityName] = {};
        }

        this._notifyListeners('didCommit');
    }
    setDirty(dirty:boolean) {
        this._isDirty = dirty;
    }
    isDirty() {
        return this._isDirty;
    }

    /**
     * Ensure the project modelMinVersion is superior or equal to the given one.
     * 
     * @param minVersion  The minVersion to ensure.
     */
    ensureModelMinVersion(minVersion: number): void {
        if (minVersion === null) {
            throw new IllegalArgumentError('Invalid minVersion value');
        }

        // emit new modelMinVersion value only if superior to the existing one
        if (minVersion > this.modelMinVersion) {
            this._modelMinVersionSub$.next(minVersion);
        }
    }

    /**
     * Apply the state of another orm into this one.
     * 
     * Attempt to minimize the changes on the current orm as much as possible
     * 
     * @param entityContext the other orm context
     */
    rebaseFrom(entityContext:EntityContext) {
        // TODO : remove hack
        // HACK : force the `modelMinVersion` to trigger `integrityCheck`
        this._modelMinVersionSub$.next(entityContext.modelMinVersion);
        // this.ensureModelMinVersion(entityContext.modelMinVersion);

        // create missing entities
        for(const entityType of this.entityTypes.byIndex) {
            const entityName = entityType.entityName;
            const myEntities = this._entitiesByType[entityName];
            const hisEntities = entityContext._entitiesByType[entityName];

            for(const entityId in hisEntities) {
                if(entityId in myEntities) {
                    continue;
                }
                this.createEntity(entityName, entityId);   
            }
        }

        // copy changes
        for(const entityType of this.entityTypes.byIndex) {
            const entityName = entityType.entityName;
            const metadata = entityType.metadata;
            const myEntities = this._entitiesByType[entityName];
            const hisEntities = entityContext._entitiesByType[entityName];

            for(const entityId in hisEntities) {
                const myEntity = myEntities[entityId];
                const hisEntity = hisEntities[entityId];

                // sync properties
                for(const name in metadata) {
                    const property = metadata[name];

                    if (property.calculated) {
                        continue;
                    }
                    
                    if(property.relation===true) {
                        if(!property.single) {
                            continue;   // only single relationships
                        }

                        // update relation, find the corresponding value in my entities
                        const value = hisEntity[name];
                        if(!value) {
                            myEntity[name] = null;
                        } else {
                            const target = this.getEntity(property.target.entityName, value.id);
                            if(!target) {
                                throw new ORMError('missing entity reference', entityType.entityName, entityId);
                            }
                            myEntity[name] = target;
                        }
                    } else {
                        if (property.primaryKey) {
                            continue;                            
                        }

                        if (property.export === ExportKind.cache) {
                            continue;
                        }

                        // update value
                        myEntity[name] = hisEntity[name];
                    }
                }

                // sync attachments
                const myAttachments = myEntity.getAttachments();
                const hisAttachments = hisEntity.getAttachments();
                for(const key in hisAttachments) {
                    const hisAttachment = hisAttachments[key];
                    if(!(key in myAttachments) || myAttachments[key] !== hisAttachment) {
                        myEntity.setAttachmentForKey(key, hisAttachment);
                    }
                }
                for(const key in myAttachments) {
                    if(!(key in hisAttachments)) {
                        myEntity.setAttachmentForKey(key, null);
                    }
                }
            }
        }

        // remove entities that are no longer there
        for(const entityType of this.entityTypes.byIndex) {
            const entityName = entityType.entityName;
            const myEntities = this._entitiesByType[entityName];
            const hisEntities = entityContext._entitiesByType[entityName];

            for(const entityId in myEntities) {
                if(entityId in hisEntities) {
                    continue;
                }
                this.deleteEntity(myEntities[entityId]);
            }
        }

        // at this point, both entity context have the same state but we still need to synchronize the updates
        this._createdEntities = {};
        for(const entityName in entityContext._createdEntities) {
            const myCreations = this._createdEntities[entityName] = {};
            const hisCreations = entityContext._createdEntities[entityName];
            for(const entityId in hisCreations) {
                myCreations[entityId] = this.getEntity(entityName, entityId);
            }
        }
        this._modifiedEntities = {};
        for(const entityName in entityContext._modifiedEntities) {
            const myUpdates = this._modifiedEntities[entityName] = {};
            const hisUpdates = entityContext._modifiedEntities[entityName];
            for(const entityId in hisUpdates) {
                myUpdates[entityId] = Object.assign(hisUpdates[entityId]);
            }
        }
        this._deletedEntities = {};
        for(const entityName in entityContext._deletedEntities) {
            const mySuppressions = this._deletedEntities[entityName] = {};
            const hisSuppressions = entityContext._deletedEntities[entityName];
            for(const entityId in hisSuppressions) {
                mySuppressions[entityId] = hisSuppressions[entityId];
            }
        }
        this._createdAttachments = {};
        for(const entityName in entityContext._createdAttachments) {
            const myUpdates = this._createdAttachments[entityName] = {};
            const hisUpdates = entityContext._createdAttachments[entityName];
            for(const entityId in hisUpdates) {
                myUpdates[entityId] = Object.assign(hisUpdates[entityId]);
            }
        }
        this._updatedAttachments = {};
        for(const entityName in entityContext._updatedAttachments) {
            const myUpdates = this._updatedAttachments[entityName] = {};
            const hisUpdates = entityContext._updatedAttachments[entityName];
            for(const entityId in hisUpdates) {
                myUpdates[entityId] = Object.assign(hisUpdates[entityId]);
            }
        }
    }

    /**
     * Clone the entity context.
     * Keep the same schema model (`entityTypes, dynamicEntities, validators, modelVersion, modelMinVersion`).
     */
    clone(): EntityContext {
        const clonedEntityContext = new EntityContext(
            this.entityTypes,
            this.dynamicEntities,
            this.validators,
            {
                versionIdentifiers: [ this._modelVersion ],
                minVersion: this._modelMinVersionSub$.getValue(),
                patchHandlers: this.patchHandlers,
            },
        );
        // Copy actual project entity context into the new one
        clonedEntityContext.rebaseFrom(this);
        return clonedEntityContext;
    }
    
    /**
     * Get an entity by entity type and entity id
     * 
     * @param entityName entity type name
     * @param entityId entity id
     * @return the entity or null
     */
    getEntity<K extends keyof EntityTypeMap>(entityName:K, entityId:string): EntityTypeMap[K] | null;
    getEntity<T extends BaseEntity>(entityName:string, entityId:string): T | null;
    getEntity(entityName:string, entityId:string): BaseEntity | null {
        const entity = this._entitiesByType[entityName][entityId];
        if(!entity) {
            return null;
        }
        return entity;
    }
    /**
     * Check if an entity once existed then was deleted
     * 
     * @param entityName entity type name
     * @param entityId entity id
     * @return true if the entity once existed then was deleted
     */
    isEntityDeleted(entityName:string, entityId:string) {
        return this._deletedEntitiesByType[entityName][entityId];
    }
    
    createEntity<K extends keyof EntityTypeMap>(entityName:K, entityId?:string): EntityTypeMap[K];
    createEntity<T extends BaseEntity>(entityName:string, entityId?:string): T;
    createEntity(entityName:string, entityId?:string):BaseEntity {
        const dynamicEntity: DynamicEntityConstructor = this.dynamicEntities.dynamicEntityByName[entityName];
        if(!dynamicEntity) {
            throw new ORMError('No such entity name', entityName);
        }

        if(!entityId) {
            entityId = generateId();
        }

        if(entityId in this._entitiesByType[entityName]) {
            throw new ORMError('A entity already exists', entityName, entityId);
        }

        const entity = new dynamicEntity(this, entityId);

        if(entity.id in this.deletedEntities[entityName]) {
            // entity recreation (may happen with deterministic id generation) -> mark the entity as modified
            delete this._deletedEntities[entityName][entity.id];
            delete this._deletedEntitiesByType[entityName][entity.id];
            this._modifiedEntities[entityName][entity.id] = {};
        } else {
            this._createdEntities[entityName][entityId] = entity;
        }

        this.setDirty(true);
        this._entitiesByType[entityName][entityId] = entity;

        entity.attachEntity(this);
        this._notifyListeners('entityCreated', entity);

        return entity;
    }
    deleteEntity(entity:BaseEntity) {
        if (entity.getContext() !== null && entity.getContext() !== this) {
            const error = new ORMError('Illegal attempt to delete an entity of another EntityContext');
            error.addContext('Archipad', { 'entity to delete': {
                'entityName': entity.entityType.entityName,
                'entityId': entity.id,
            }});
            throw error;
        }
        if(entity.isDeleted()) {
            return; // already deleted
        }
        if(entity._updating) {
            return; // already updating
        }

        // prevent update loops
        entity._updating = true;

        const entityType = entity.entityType;
        const entityName = entityType.entityName;

        // notify listeners that the entity is about to be deleted
        this._notifyListeners('willDeleteEntity',entity);

        // handle delete cascade and clear entity relationships
        for(const propertyName in entityType.metadata) {
            const property = entityType.metadata[propertyName];
            if(!property.relation || property.calculated) {
                continue;
            }

            const relation = property;
            if(relation.single) {
                if(entity[propertyName]) {
                    const target = entity[propertyName];
                    // clear relation
                    entity[propertyName] = null;
                    this._updateRelationInverse(entity, property, null, target);
                    // have to notify listeners manually here because this entity is already updating
                    this._notifyListeners('entityUpdated', entity, propertyName, target, null);
                    // cascade delete
                    if(relation.deleteRule === 'cascade') {
                        this.deleteEntity(target);
                    }
                }
            } else {
                const targets = entity[propertyName];
                if(targets.length) {
                    // clear relation
                    for(const target of targets) {
                        this._updateRelationInverse(entity, property, null, target);
                    }
                    // cascade delete
                    if(relation.deleteRule == 'cascade') {
                        for(const target of targets) {
                            this.deleteEntity(target);
                        }
                    }
                    targets.splice(0,targets.length);
                }
            }
        }

        // delete attachments
        const attachments = entity.getAttachments();
        for ( const key in attachments) {
            this.entityAttachmentUpdated(entity, key, attachments[key], null);
        }

        if(entity.id in this.createdEntities[entityName]) {
            // delete an entity that was created in the current patch -> no longer created
            delete this._createdEntities[entityName][entity.id];
        } else if(entity.id in this.modifiedEntities[entityName]) {
            // delete an entity that was modified in the current patch -> no longer modified and mark as deleted
            delete this._modifiedEntities[entityName][entity.id];
            this._deletedEntities[entityName][entity.id] = true;
        } else {
            // otherwise mark as deleted
            this._deletedEntities[entityName][entity.id] = true;
        }
        this._deletedEntitiesByType[entityName][entity.id] = true;

        delete this._entitiesByType[entityName][entity.id];
        entity.detachEntity();

        entity._updating = false;

        // entity deleted
        this.setDirty(true);
        this._notifyListeners('entityDeleted',entity);
    }

    setFlushDataHandler(handler: FlushDataHandler) {
        this._flushDataHandler = handler;
    }
    flushData(): Observable<void> {
        if(!this._flushDataHandler) {
            return throwError(new IllegalStateError('No flush data handler'));
        }
        return this._flushDataHandler();
    }

    async executeWithoutValidators(callback: () => Promise<void> | void): Promise<void> {
        this._validatorsEnabled = false;
        try {
            await callback();
        } finally {
            this._validatorsEnabled = true;
        }
    }

    private _notifyListeners(...args:any[]) {
        const notification = args as Notification;

        if (this._validatorsEnabled) {
            for (const validator of this.validators) {
                validator.validateNotification(notification);
            }
        }

        this._notifications.next(notification);
    }

    entityUpdated(entity:BaseEntity, name:string, oldVal:any, val:any) {
        if(entity._updating) {
            return; // already updating
        }
        entity._updating = true;

        const entityType = entity.entityType;
        const entityName = entityType.entityName;
        const property = entityType.metadata[name];

        if(property.relation) {
            if(!property.single) {
                throw new ORMError('Assignment of multiple relationship "' + name + '" is not supported');
            }
        }

        if(entity.id in this.createdEntities[entityName]) {
            // keep the changes on created entities
            this.createdEntities[entityName][entity.id][name] = val;
        } else {
            // mark entity changed and keep the initial value for modified property
            let modifiedEntity = this.modifiedEntities[entityName][entity.id];
            if(!modifiedEntity) {
                this._modifiedEntities[entityName][entity.id] = modifiedEntity = {};
            }
            if(modifiedEntity[name] === undefined) {
                modifiedEntity[name] = oldVal;
            }
        }

        // maintain relationship information
        if(property.relation) {
            this._updateRelationInverse(entity, property, val, oldVal);
        }

        entity._updating = false;

        this.setDirty(true);
        this._notifyListeners('entityUpdated', entity, name, oldVal, val);

        // cleared an owning property
        if(oldVal && !val && property.relation && property.owning) {
            let isOrphan = true;
            for(const name in entityType.metadata) {
                const property = entityType.metadata[name];
                if(property.relation && property.owning && this[name]) {
                    isOrphan = false;   // entity still has a non null owning property
                    break;
                }
            }

            // the entity is now orphan, delete it
            if(isOrphan) {
                this.deleteEntity(entity);
            }
        }
    }
    entityAttachmentUpdated(entity:BaseEntity, key:string, oldAttachment:any, attachment:any) {
        const entityName = entity.entityType.entityName;

        if(!oldAttachment && attachment) {
            // adding a new attachment
            if (this.updatedAttachments?.[entityName]?.[entity.id] && key in this.updatedAttachments[entityName][entity.id] ) {
                this._updatedAttachments[entityName][entity.id][key] = attachment;
            } else {
                if ( ! this.createdAttachments[entityName] ) {
                    this._createdAttachments[entityName] = {};
                }

                if ( ! this.createdAttachments[entityName][entity.id] ) {
                    this._createdAttachments[entityName][entity.id] = {};
                }

                this._createdAttachments[entityName][entity.id][key] = attachment;
            }
        } else if(!attachment) {
            // removing an attachment
            if(this.createdAttachments[entityName] && this.createdAttachments[entityName][entity.id] && this.createdAttachments[entityName][entity.id][key]) {
                delete this._createdAttachments[entityName][entity.id][key];
            } else {
                if ( ! this.updatedAttachments[entityName] ) {
                    this._updatedAttachments[entityName] = {};
                }

                if ( ! this.updatedAttachments[entityName][entity.id] ) {
                    this._updatedAttachments[entityName][entity.id] = {};
                }

                this._updatedAttachments[entityName][entity.id][key] = attachment;
            }
        } else {
            // updating an attachment
            if(this.createdAttachments[entityName] && this.createdAttachments[entityName][entity.id] && this.createdAttachments[entityName][entity.id][key]) {
                this._createdAttachments[entityName][entity.id][key] = attachment;
            } else {
                if ( ! this.updatedAttachments[entityName] ) {
                    this._updatedAttachments[entityName] = {};
                }

                if ( ! this.updatedAttachments[entityName][entity.id] ) {
                    this._updatedAttachments[entityName][entity.id] = {};
                }

                this._updatedAttachments[entityName][entity.id][key] = attachment;
            }
        }

        // also mark the associated entity as modified if this is not already the case
        if(!this.createdEntities[entityName][entity.id] && !this.modifiedEntities[entityName][entity.id]) {
            this._modifiedEntities[entityName][entity.id] = {};
        }

        this.setDirty(true);

        this._notifyListeners('attachmentUpdated', entity, key, oldAttachment, attachment);
    }
}

/**
 * Make a predicate delegate for a given entity type
 * 
 * Usage:
 * ```
 * let predicate = new Predicate.OrderByPredicate('visit.date asc, time asc');
 * let compareFn = predicate.build(Orm.predicateDelegateFor(entityContext, 'Remark'));
 * // compareFn is a comparator function for two RemarkEntity
 * ```
 * 
 * @param entityContext orm entity context
 * @param entityName entity name
 */
export function predicateDelegateFor(entityContext:EntityContext, entityName:string): Delegate<unknown> {
    const entityType = entityContext.entityTypes.byName[entityName];
    if (!entityType) {
        throw new ORMError(`No such entity name "${entityName}"`);
    }
    
    return {
        getProperty(name:string) {
            const keyPathDescription = entityType.keyPathDescription(name);
            for(let i=0;i<keyPathDescription.length-1;i++) {
                const propertyDescription = keyPathDescription[i] as RelationDescription;
                if(!propertyDescription.single) {
                    throw new ORMError(`"${name}" is not a single relationship in ${entityName}`);
                }
            }

            const property = keyPathDescription[keyPathDescription.length-1];
            if (property.relation === true) {
                throw new ORMError("property must be a value property. found a relation");
            }

            return new DynNode(property.type, makePathExpression(name));
        },
    }
}
