import { ReportEntity } from "../ReportEntity";
import { IllegalStateError } from '@core/errors/errors-core';

export const enum ReportGenerationStatus {
    generationStarted = 0,
    dumpPatchCreated = 1,
    tempAttachmentsCopied = 2,
    attachmentsManifestUploaded = 3,
    dumpPatchUploaded = 4,
    reportConfigUploaded = 5,
    appConfigUploaded = 6,
    generationConfigUploaded = 7,
    generationPending = 8,
    generationProcessing = 9,
    // = 30%

    generationProcessed = 10,
    // = 60%

    adoptionStarted = 11,
    outputAttachmentsManifestDownloaded = 12,
    outputAttachmentDownloaded = 13,
    // = 90%

    generationDone = 14,
    // = 100%

    adoptingDone = 15
}

declare module "../ReportEntity" {
    interface ReportEntity  {
        generationProgress : number;
        isGenerationRunning: boolean;
    }
}

/**
 * Get the generation progress of a given report entity.
 * 
 * @param reportEntity - The report entity
 */
Object.defineProperty(ReportEntity.prototype, 'generationProgress', {
    get: function(this:ReportEntity):number {
        const self : ReportEntity = this;
        const generationStatus = self.generationStatus as ReportGenerationStatus;

        switch (generationStatus) {
            case null:
                return 0;
            case ReportGenerationStatus.generationStarted:
            case ReportGenerationStatus.dumpPatchCreated:
            case ReportGenerationStatus.tempAttachmentsCopied:
            case ReportGenerationStatus.attachmentsManifestUploaded:
            case ReportGenerationStatus.dumpPatchUploaded:
            case ReportGenerationStatus.reportConfigUploaded:
            case ReportGenerationStatus.appConfigUploaded:
            case ReportGenerationStatus.generationConfigUploaded:
            case ReportGenerationStatus.generationPending:
            case ReportGenerationStatus.generationProcessing:
                return 30;
            case ReportGenerationStatus.generationProcessed:
            case ReportGenerationStatus.adoptionStarted:
                return 60;
            case ReportGenerationStatus.outputAttachmentsManifestDownloaded:
            case ReportGenerationStatus.outputAttachmentDownloaded:
                return 90;
            case ReportGenerationStatus.generationDone:
                return 100;
            case ReportGenerationStatus.adoptingDone:
                return 0;
            default:
                throw new IllegalStateError(`Unsupported generationStatus "${generationStatus}"`);
        }
    },
    enumerable: true,
});

/**
 * Whether the report generation is running on server side.
 * 
 * @param reportEntity - The report entity
 */
Object.defineProperty(ReportEntity.prototype, 'isGenerationRunning', {
    get: function(this:ReportEntity):boolean {
        const self : ReportEntity = this;
        const generationStatus = self.generationStatus as ReportGenerationStatus;

        switch (generationStatus) {
            case ReportGenerationStatus.generationPending:
            case ReportGenerationStatus.generationProcessing:
            case ReportGenerationStatus.generationProcessed:
            case ReportGenerationStatus.adoptionStarted:
            case ReportGenerationStatus.outputAttachmentsManifestDownloaded:
            case ReportGenerationStatus.outputAttachmentDownloaded:
            case ReportGenerationStatus.generationDone:
                return true;
            case ReportGenerationStatus.adoptingDone:
                return false;
        }

        return false;
    },
    enumerable: true,
});
