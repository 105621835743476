/**
* ProjectEntity Interface
*
* Generated file, don't touch manually
*
* ModelVersion: 870
*/

import {BaseEntity} from "@core/services/orm/orm";

import { ProjectTemplateVersionEntity } from "./ProjectTemplateVersionEntity";
import { PhaseEntity } from "./PhaseEntity";
import { BugWorkflowEntity } from "./BugWorkflowEntity";
import { RegionTypeEntity } from "./RegionTypeEntity";
import { RegionEntity } from "./RegionEntity";
import { MapEntity } from "./MapEntity";
import { PersonEntity } from "./PersonEntity";
import { ProjectMemberEntity } from "./ProjectMemberEntity";
import { WorkPackageEntity } from "./WorkPackageEntity";
import { VisitTypeEntity } from "./VisitTypeEntity";
import { ProjectUserRoleEntity } from "./ProjectUserRoleEntity";
import { ProjectUserRightEntity } from "./ProjectUserRightEntity";
import { ProjectUserEntity } from "./ProjectUserEntity";
import { VisitEntity } from "./VisitEntity";
import { PlanningEventCategoryEntity } from "./PlanningEventCategoryEntity";
import { PlanningEventEntity } from "./PlanningEventEntity";
import { FormTypeEntity } from "./FormTypeEntity";
import { RemarkStyleEntity } from "./RemarkStyleEntity";
import { RemarkCategoryEntity } from "./RemarkCategoryEntity";
import { RemarkCategoryHierarchyEntity } from "./RemarkCategoryHierarchyEntity";
import { DocumentEntity } from "./DocumentEntity";
import { ReportEntity } from "./ReportEntity";


declare global {
    interface EntityTypeMap {
        "Project": ProjectEntity
    }
}

export abstract class ProjectEntity extends BaseEntity {

	 abstract projectId: string;
	 abstract settingDotColorFunction: string;
	 abstract settingEventColorFunction: string;
	 abstract settingAutoAcceptanceOfBug: boolean;
	 abstract templateVersions: ReadonlyArray<ProjectTemplateVersionEntity>;
	 abstract phases: ReadonlyArray<PhaseEntity>;
	 abstract maps: ReadonlyArray<MapEntity>;
	 abstract workPackages: ReadonlyArray<WorkPackageEntity>;
	 abstract visitTypes: ReadonlyArray<VisitTypeEntity>;
	 abstract visits: ReadonlyArray<VisitEntity>;
	 abstract formTypes: ReadonlyArray<FormTypeEntity>;
	 abstract originTemplateIds?: string;
	 abstract language?: string;
	 abstract name?: string;
	 abstract address?: string;
	 abstract demolitionPermit?: string;
	 abstract buildingPermit?: string;
	 abstract typeOfWork?: string;
	 abstract townHallAddress?: string;
	 abstract unitOfMeasure?: string;
	 abstract demolishedBuildingsSurface?: number;
	 abstract landArea?: number;
	 abstract floorSurface?: number;
	 abstract buildingHeight?: number;
	 abstract currency?: string;
	 abstract estimatedDemolitionBudget?: number;
	 abstract estimatedConstructionBudget?: number;
	 abstract realDemolitionBudget?: number;
	 abstract realConstructionBudget?: number;
	 abstract estimatedStartDate?: Date;
	 abstract estimatedEndDate?: Date;
	 abstract realStartDate?: Date;
	 abstract realEndDate?: Date;
	 abstract type?: string;
	 abstract country?: string;
	 abstract buildingsQuantity?: number;
	 abstract closeDate?: Date;
	 abstract settingDotSize?: number;
	 abstract settingHotBugDuration?: number;
	 abstract settingLateBugForVisitDuration?: number;
	 abstract formAspects?: string;
	 abstract dependencies?: string;
	 abstract estimatedOverallBudget?: number;
	 abstract realOverallBudget?: number;
	 abstract estimatedCostSurface?: number;
	 abstract realCostSurface?: number;
	 abstract promoimmo_ref_projet?: string;
	 abstract promoimmo_moa?: string;
	 abstract promoimmo_moa_addr?: string;
	 abstract promoimmo_date_reception?: Date;
	 abstract bugWorkflow?: BugWorkflowEntity;
	 abstract regionTypes?: ReadonlyArray<RegionTypeEntity>;
	 abstract regions?: ReadonlyArray<RegionEntity>;
	 abstract people?: ReadonlyArray<PersonEntity>;
	 abstract members?: ReadonlyArray<ProjectMemberEntity>;
	 abstract userRoles?: ReadonlyArray<ProjectUserRoleEntity>;
	 abstract userRights?: ReadonlyArray<ProjectUserRightEntity>;
	 abstract users?: ReadonlyArray<ProjectUserEntity>;
	 abstract owner?: ProjectUserEntity;
	 abstract planningMaster?: ProjectUserEntity;
	 abstract planningEventCategories?: ReadonlyArray<PlanningEventCategoryEntity>;
	 abstract planningEvents?: ReadonlyArray<PlanningEventEntity>;
	 abstract remarkStyles?: ReadonlyArray<RemarkStyleEntity>;
	 abstract remarkCategories?: ReadonlyArray<RemarkCategoryEntity>;
	 abstract remarkCategoryHierarchies?: ReadonlyArray<RemarkCategoryHierarchyEntity>;
	 abstract ownedDocuments?: ReadonlyArray<DocumentEntity>;
	 abstract documents?: ReadonlyArray<DocumentEntity>;
	 abstract reports?: ReadonlyArray<ReportEntity>;
	
}
