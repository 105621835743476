type UnknownMap = Map<unknown, unknown>;
type MapKeyType<T extends UnknownMap> = T extends Map<infer K, unknown> ? K : never;
type MapValueType<T extends UnknownMap> = T extends Map<unknown, infer V> ? V : never;

/**
 * Returns the element at the specified key of the Map object if it exists or
 * insert a given default value otherwise.
 *
 * @param key - The key of the element to return from the Map object.
 * @param defaultValue - The value to insert in the Map object if the key does not already exists.
 */
export function getOrCreateFromMap<T extends UnknownMap, K = MapKeyType<T>, V = MapValueType<T>>(
    map: Map<K, V>,
    key: K,
    defaultValue: V,
): V {
    if (!map.has(key)) {
        map.set(key, defaultValue);
    }

    const value = map.get(key);
    return value;
}
