import * as JSBN from 'jsbn';

/**
 * Convert an entity id represented as a decimal string to an hexadecimal string padded to 16 chars
 * 
 * "40812564021...." -> "0FAD12..."
 */
export function convertIdToHex16(entityId:string):string {
    let hexProjectId = (new JSBN.BigInteger(entityId, 10)).toString(16);
    hexProjectId = ('0000000000000000' + hexProjectId).slice(-16);

    return hexProjectId;
}

/**
 * Convert an entity id represented as a decimal string to an hexadecimal string padded to 32 chars
 * 
 * "40812564021...." -> "0FAD12..."
 */
export function convertIdToHex32(entityId:string):string {
    let hexProjectId = (new JSBN.BigInteger(entityId, 10)).toString(16);
    hexProjectId = ('00000000000000000000000000000000' + hexProjectId).slice(-32);

    return hexProjectId;
}

/**
 * Convert an hexadecimal entity id into a decimal entity id
 * @param entityId 
 */
export function convertHexIdToDecimal(entityId:string):string {
    return (new JSBN.BigInteger(entityId, 16)).toString(10);
}
