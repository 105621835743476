/**
 * Checks if the given object is a plain object or not.
 * 
 * @example
 * console.log(isPlainObject({}));
 * // expected output: "true"
 * 
 * console.log(isPlainObject(new Date()));
 * // expected output: "false"
 */
export function isPlainObject(obj: unknown): obj is Record<string, unknown> {
    if (typeof obj !== 'object') {
        return false;
    }

    if (obj === null) {
        return false;
    }

    const proto = Object.getPrototypeOf(obj);

    if (proto === null) {
        return true;
    }

    if (proto === Object.prototype) {
        return true;
    }

    return false;
}

/**
 * Copy the values of all of the enumerable own properties from one or more source objects to a
 * target object only if the source value is not undefined. Returns the target object.
 * 
 * @param target The target object to copy to.
 * @param source The source object from which to copy properties.
 */
export function assignIfDefined<T>(target: T, source: Partial<T>): T {
    for (const [propertyName, propertyValue] of Object.entries(source)) {
        if (propertyValue !== undefined) {
            target[propertyName] = propertyValue;
        }
    }

    return target;
}
