export interface BinarySearchResult {
    found: boolean;
    pos: number;
}

export function binarySearch<T, E>(element: T, array: E[], orderFn: (a: T, b: E) => number): BinarySearchResult {
    let min = 0;
    let max = array.length - 1;
    let idx = 0;
    let ret;

    while (min <= max) {
        idx = Math.floor((min + max) / 2);
        ret = orderFn(element, array[idx]);

        if (ret > 0) {
            min = idx + 1;
        } else if (ret < 0) {
            max = idx - 1;
        } else {
            return { found: true, pos: idx };
        }
    }

    if (max < min) idx = min;
    return { found: false, pos: idx };
}

export function insert<T>(array: T[], id: T): boolean {
    let min = 0;
    let max = array.length - 1;
    let idx = 0;

    while (min <= max) {
        idx = Math.floor((min + max) / 2);
        if (id === array[idx]) return false;

        if (array[idx] < id) {
            min = idx + 1;
        } else {
            max = idx - 1;
        }
    }

    if (max < min) idx = min;
    array.splice(idx, 0, id);

    return true;
}

export function find<T>(array: T[], id: T): boolean {
    let min = 0;
    let max = array.length - 1;
    let idx;

    while (min <= max) {
        idx = Math.floor((min + max) / 2);
        if (id === array[idx]) return true;

        if (array[idx] < id) {
            min = idx + 1;
        } else {
            max = idx - 1;
        }
    }

    return false;
}
