import { TechnicalErrorIdentifier, TechnicalErrorModuleCore } from "@archipad-js/core/error";

import { APIError } from "../rpc/archipad-rpc.service.error";

export class AuthenticationAPIError extends APIError {
    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "AUTHENTICATION_ERROR");
}

export class TimeSkewAPIError extends APIError {
    public override identifier = new TechnicalErrorIdentifier(TechnicalErrorModuleCore.API, "CLIENT_TIME_SKEW");
}
