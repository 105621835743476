/* istanbul ignore file */

/*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */

"use strict";

function peg$subclass(child, parent) {
    function ctor() {
        this.constructor = child;
    }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
}

function peg$SyntaxError(message, expected, found, location) {
    this.message = message;
    this.expected = expected;
    this.found = found;
    this.location = location;
    this.name = "SyntaxError";

    if (typeof Error.captureStackTrace === "function") {
        Error.captureStackTrace(this, peg$SyntaxError);
    }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function (expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
        literal: function (expectation) {
            return '"' + literalEscape(expectation.text) + '"';
        },

        class: function (expectation) {
            var escapedParts = "",
                i;

            for (i = 0; i < expectation.parts.length; i++) {
                escapedParts +=
                    expectation.parts[i] instanceof Array
                        ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
                        : classEscape(expectation.parts[i]);
            }

            return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        },

        any: function (expectation) {
            return "any character";
        },

        end: function (expectation) {
            return "end of input";
        },

        other: function (expectation) {
            return expectation.description;
        },
    };

    function hex(ch) {
        return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s) {
        return s
            .replace(/\\/g, "\\\\")
            .replace(/"/g, '\\"')
            .replace(/\0/g, "\\0")
            .replace(/\t/g, "\\t")
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/[\x00-\x0F]/g, function (ch) {
                return "\\x0" + hex(ch);
            })
            .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                return "\\x" + hex(ch);
            });
    }

    function classEscape(s) {
        return s
            .replace(/\\/g, "\\\\")
            .replace(/\]/g, "\\]")
            .replace(/\^/g, "\\^")
            .replace(/-/g, "\\-")
            .replace(/\0/g, "\\0")
            .replace(/\t/g, "\\t")
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/[\x00-\x0F]/g, function (ch) {
                return "\\x0" + hex(ch);
            })
            .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
                return "\\x" + hex(ch);
            });
    }

    function describeExpectation(expectation) {
        return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }

    function describeExpected(expected) {
        var descriptions = new Array(expected.length),
            i,
            j;

        for (i = 0; i < expected.length; i++) {
            descriptions[i] = describeExpectation(expected[i]);
        }

        descriptions.sort();

        if (descriptions.length > 0) {
            for (i = 1, j = 1; i < descriptions.length; i++) {
                if (descriptions[i - 1] !== descriptions[i]) {
                    descriptions[j] = descriptions[i];
                    j++;
                }
            }
            descriptions.length = j;
        }

        switch (descriptions.length) {
            case 1:
                return descriptions[0];

            case 2:
                return descriptions[0] + " or " + descriptions[1];

            default:
                return descriptions.slice(0, -1).join(", ") + ", or " + descriptions[descriptions.length - 1];
        }
    }

    function describeFound(found) {
        return found ? '"' + literalEscape(found) + '"' : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
    options = options !== void 0 ? options : {};

    var peg$FAILED = {},
        peg$startRuleFunctions = { start: peg$parsestart },
        peg$startRuleFunction = peg$parsestart,
        peg$c0 = function (a) {
            return a;
        },
        peg$c1 = "or",
        peg$c2 = peg$literalExpectation("OR", true),
        peg$c3 = "||",
        peg$c4 = peg$literalExpectation("||", false),
        peg$c5 = function (a, b) {
            return b;
        },
        peg$c6 = function (a, tail) {
            return tail.reduce(function (result, element) {
                return { op: "OR", a: result, b: element };
            }, a);
        },
        peg$c7 = "and",
        peg$c8 = peg$literalExpectation("AND", true),
        peg$c9 = "&&",
        peg$c10 = peg$literalExpectation("&&", false),
        peg$c11 = function (a, tail) {
            return tail.reduce(function (result, element) {
                return { op: "AND", a: result, b: element };
            }, a);
        },
        peg$c12 = function (a, op, b) {
            return { op: op, b: b };
        },
        peg$c13 = function (a, tail) {
            return tail.reduce(function (result, element) {
                return { op: element.op, a: result, b: element.b };
            }, a);
        },
        peg$c14 = "<>",
        peg$c15 = peg$literalExpectation("<>", false),
        peg$c16 = function () {
            return "!=";
        },
        peg$c17 = ">=",
        peg$c18 = peg$literalExpectation(">=", false),
        peg$c19 = "!=",
        peg$c20 = peg$literalExpectation("!=", false),
        peg$c21 = "<=",
        peg$c22 = peg$literalExpectation("<=", false),
        peg$c23 = "<",
        peg$c24 = peg$literalExpectation("<", false),
        peg$c25 = ">",
        peg$c26 = peg$literalExpectation(">", false),
        peg$c27 = "==",
        peg$c28 = peg$literalExpectation("==", false),
        peg$c29 = "=",
        peg$c30 = peg$literalExpectation("=", false),
        peg$c31 = function () {
            return "==";
        },
        peg$c32 = "in",
        peg$c33 = peg$literalExpectation("in", true),
        peg$c34 = "+",
        peg$c35 = peg$literalExpectation("+", false),
        peg$c36 = "-",
        peg$c37 = peg$literalExpectation("-", false),
        peg$c38 = function (a, tail) {
            return tail.reduce(function (result, element) {
                return { op: element.op, a: result, b: element.b };
            }, a);
        },
        peg$c39 = "*",
        peg$c40 = peg$literalExpectation("*", false),
        peg$c41 = "/",
        peg$c42 = peg$literalExpectation("/", false),
        peg$c43 = function (number) {
            return { op: "const", v: number, type: "number" };
        },
        peg$c44 = "null",
        peg$c45 = peg$literalExpectation("null", true),
        peg$c46 = function () {
            return { op: "const", v: null };
        },
        peg$c47 = "true",
        peg$c48 = peg$literalExpectation("true", true),
        peg$c49 = function () {
            return { op: "const", v: true, type: "boolean" };
        },
        peg$c50 = "false",
        peg$c51 = peg$literalExpectation("false", true),
        peg$c52 = function () {
            return { op: "const", v: false, type: "boolean" };
        },
        peg$c53 = function (string) {
            return { op: "const", v: string, type: "string" };
        },
        peg$c54 = "(",
        peg$c55 = peg$literalExpectation("(", false),
        peg$c56 = ")",
        peg$c57 = peg$literalExpectation(")", false),
        peg$c58 = "!",
        peg$c59 = peg$literalExpectation("!", false),
        peg$c60 = function (arg) {
            return { op: "not", a: arg };
        },
        peg$c61 = "{",
        peg$c62 = peg$literalExpectation("{", false),
        peg$c63 = "}",
        peg$c64 = peg$literalExpectation("}", false),
        peg$c65 = function (name) {
            return { op: "param", name: name };
        },
        peg$c66 = "[",
        peg$c67 = peg$literalExpectation("[", false),
        peg$c68 = ",",
        peg$c69 = peg$literalExpectation(",", false),
        peg$c70 = function (elem, n) {
            return n;
        },
        peg$c71 = function (elem, otherElem) {
            return [elem].concat(otherElem);
        },
        peg$c72 = "]",
        peg$c73 = peg$literalExpectation("]", false),
        peg$c74 = function (elems) {
            return { op: "array", elems: elems ? elems : [] };
        },
        peg$c75 = function (name, args) {
            return args;
        },
        peg$c76 = function (name, fnArgs) {
            if (fnArgs) return { op: "func", name: name, args: fnArgs };
            else return { op: "field", name: name };
        },
        peg$c77 = function (arg, n) {
            return n;
        },
        peg$c78 = function (arg, otherArgs) {
            return [arg].concat(otherArgs);
        },
        peg$c79 = ".",
        peg$c80 = peg$literalExpectation(".", false),
        peg$c81 = function (name, n) {
            return n;
        },
        peg$c82 = function (name, otherNames) {
            return [name].concat(otherNames).join(".");
        },
        peg$c83 = /^[_a-zA-Z]/,
        peg$c84 = peg$classExpectation(["_", ["a", "z"], ["A", "Z"]], false, false),
        peg$c85 = /^[_a-zA-Z0-9]/,
        peg$c86 = peg$classExpectation(["_", ["a", "z"], ["A", "Z"], ["0", "9"]], false, false),
        peg$c87 = /^[0-9]/,
        peg$c88 = peg$classExpectation([["0", "9"]], false, false),
        peg$c89 = function (digits, fraq) {
            return fraq ? parseFloat(digits + fraq) : parseInt(digits, 10);
        },
        peg$c90 = peg$otherExpectation("string"),
        peg$c91 = '"',
        peg$c92 = peg$literalExpectation('"', false),
        peg$c93 = function (chars) {
            return chars.join("");
        },
        peg$c94 = "'",
        peg$c95 = peg$literalExpectation("'", false),
        peg$c96 = "\\",
        peg$c97 = peg$literalExpectation("\\", false),
        peg$c98 = function (sequence) {
            return sequence;
        },
        peg$c99 = "b",
        peg$c100 = peg$literalExpectation("b", false),
        peg$c101 = function () {
            return "\b";
        },
        peg$c102 = "f",
        peg$c103 = peg$literalExpectation("f", false),
        peg$c104 = function () {
            return "\f";
        },
        peg$c105 = "n",
        peg$c106 = peg$literalExpectation("n", false),
        peg$c107 = function () {
            return "\n";
        },
        peg$c108 = "r",
        peg$c109 = peg$literalExpectation("r", false),
        peg$c110 = function () {
            return "\r";
        },
        peg$c111 = "t",
        peg$c112 = peg$literalExpectation("t", false),
        peg$c113 = function () {
            return "\t";
        },
        peg$c114 = "u",
        peg$c115 = peg$literalExpectation("u", false),
        peg$c116 = function (digits) {
            return String.fromCharCode(parseInt(digits, 16));
        },
        peg$c117 = /^[ -!#-&(-[\]-\u10FFFF]/,
        peg$c118 = peg$classExpectation([[" ", "!"], ["#", "&"], ["(", "["], ["]", "\u10FF"], "F", "F"], false, false),
        peg$c119 = /^[0-9a-f]/i,
        peg$c120 = peg$classExpectation(
            [
                ["0", "9"],
                ["a", "f"],
            ],
            false,
            true,
        ),
        peg$c121 = peg$otherExpectation("whitespace"),
        peg$c122 = /^[ \n]/,
        peg$c123 = peg$classExpectation([" ", "\n"], false, false),
        peg$currPos = 0,
        peg$savedPos = 0,
        peg$posDetailsCache = [{ line: 1, column: 1 }],
        peg$maxFailPos = 0,
        peg$maxFailExpected = [],
        peg$silentFails = 0,
        peg$result;

    if ("startRule" in options) {
        if (!(options.startRule in peg$startRuleFunctions)) {
            throw new Error("Can't start parsing from rule \"" + options.startRule + '".');
        }

        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function text() {
        return input.substring(peg$savedPos, peg$currPos);
    }

    function location() {
        return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description, location) {
        location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos);

        throw peg$buildStructuredError(
            [peg$otherExpectation(description)],
            input.substring(peg$savedPos, peg$currPos),
            location,
        );
    }

    function error(message, location) {
        location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos);

        throw peg$buildSimpleError(message, location);
    }

    function peg$literalExpectation(text, ignoreCase) {
        return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts, inverted, ignoreCase) {
        return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }

    function peg$anyExpectation() {
        return { type: "any" };
    }

    function peg$endExpectation() {
        return { type: "end" };
    }

    function peg$otherExpectation(description) {
        return { type: "other", description: description };
    }

    function peg$computePosDetails(pos) {
        var details = peg$posDetailsCache[pos],
            p;

        if (details) {
            return details;
        } else {
            p = pos - 1;
            while (!peg$posDetailsCache[p]) {
                p--;
            }

            details = peg$posDetailsCache[p];
            details = {
                line: details.line,
                column: details.column,
            };

            while (p < pos) {
                if (input.charCodeAt(p) === 10) {
                    details.line++;
                    details.column = 1;
                } else {
                    details.column++;
                }

                p++;
            }

            peg$posDetailsCache[pos] = details;
            return details;
        }
    }

    function peg$computeLocation(startPos, endPos) {
        var startPosDetails = peg$computePosDetails(startPos),
            endPosDetails = peg$computePosDetails(endPos);

        return {
            start: {
                offset: startPos,
                line: startPosDetails.line,
                column: startPosDetails.column,
            },
            end: {
                offset: endPos,
                line: endPosDetails.line,
                column: endPosDetails.column,
            },
        };
    }

    function peg$fail(expected) {
        if (peg$currPos < peg$maxFailPos) {
            return;
        }

        if (peg$currPos > peg$maxFailPos) {
            peg$maxFailPos = peg$currPos;
            peg$maxFailExpected = [];
        }

        peg$maxFailExpected.push(expected);
    }

    function peg$buildSimpleError(message, location) {
        return new peg$SyntaxError(message, null, null, location);
    }

    function peg$buildStructuredError(expected, found, location) {
        return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location);
    }

    function peg$parsestart() {
        var s0, s1, s2, s3;

        s0 = peg$currPos;
        s1 = peg$parse_();
        if (s1 === peg$FAILED) {
            s1 = null;
        }
        if (s1 !== peg$FAILED) {
            s2 = peg$parseconditionalOr();
            if (s2 !== peg$FAILED) {
                s3 = peg$parse_();
                if (s3 === peg$FAILED) {
                    s3 = null;
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c0(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseconditionalOr() {
        var s0, s1, s2, s3, s4, s5, s6;

        s0 = peg$currPos;
        s1 = peg$parseconditionalAnd();
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$currPos;
            if (input.substr(peg$currPos, 2).toLowerCase() === peg$c1) {
                s4 = input.substr(peg$currPos, 2);
                peg$currPos += 2;
            } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c2);
                }
            }
            if (s4 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c3) {
                    s4 = peg$c3;
                    peg$currPos += 2;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c4);
                    }
                }
            }
            if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 === peg$FAILED) {
                    s5 = null;
                }
                if (s5 !== peg$FAILED) {
                    s6 = peg$parseconditionalAnd();
                    if (s6 !== peg$FAILED) {
                        peg$savedPos = s3;
                        s4 = peg$c5(s1, s6);
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
            }
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$currPos;
                if (input.substr(peg$currPos, 2).toLowerCase() === peg$c1) {
                    s4 = input.substr(peg$currPos, 2);
                    peg$currPos += 2;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c2);
                    }
                }
                if (s4 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c3) {
                        s4 = peg$c3;
                        peg$currPos += 2;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c4);
                        }
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 === peg$FAILED) {
                        s5 = null;
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parseconditionalAnd();
                        if (s6 !== peg$FAILED) {
                            peg$savedPos = s3;
                            s4 = peg$c5(s1, s6);
                            s3 = s4;
                        } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            }
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c6(s1, s2);
                s0 = s1;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseconditionalAnd() {
        var s0, s1, s2, s3, s4, s5, s6;

        s0 = peg$currPos;
        s1 = peg$parseconditionalExpression();
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$currPos;
            if (input.substr(peg$currPos, 3).toLowerCase() === peg$c7) {
                s4 = input.substr(peg$currPos, 3);
                peg$currPos += 3;
            } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c8);
                }
            }
            if (s4 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c9) {
                    s4 = peg$c9;
                    peg$currPos += 2;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c10);
                    }
                }
            }
            if (s4 !== peg$FAILED) {
                s5 = peg$parse_();
                if (s5 === peg$FAILED) {
                    s5 = null;
                }
                if (s5 !== peg$FAILED) {
                    s6 = peg$parseconditionalExpression();
                    if (s6 !== peg$FAILED) {
                        peg$savedPos = s3;
                        s4 = peg$c5(s1, s6);
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
            }
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$currPos;
                if (input.substr(peg$currPos, 3).toLowerCase() === peg$c7) {
                    s4 = input.substr(peg$currPos, 3);
                    peg$currPos += 3;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c8);
                    }
                }
                if (s4 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c9) {
                        s4 = peg$c9;
                        peg$currPos += 2;
                    } else {
                        s4 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c10);
                        }
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 === peg$FAILED) {
                        s5 = null;
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parseconditionalExpression();
                        if (s6 !== peg$FAILED) {
                            peg$savedPos = s3;
                            s4 = peg$c5(s1, s6);
                            s3 = s4;
                        } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            }
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c11(s1, s2);
                s0 = s1;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseconditionalExpression() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        s0 = peg$currPos;
        s1 = peg$parseexpression();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$currPos;
                s5 = peg$parseconditionalOperator();
                if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 === peg$FAILED) {
                        s6 = null;
                    }
                    if (s6 !== peg$FAILED) {
                        s7 = peg$parseexpression();
                        if (s7 !== peg$FAILED) {
                            peg$savedPos = s4;
                            s5 = peg$c12(s1, s5, s7);
                            s4 = s5;
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$currPos;
                    s5 = peg$parseconditionalOperator();
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 === peg$FAILED) {
                            s6 = null;
                        }
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseexpression();
                            if (s7 !== peg$FAILED) {
                                peg$savedPos = s4;
                                s5 = peg$c12(s1, s5, s7);
                                s4 = s5;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c13(s1, s3);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseconditionalOperator() {
        var s0, s1;

        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c14) {
            s1 = peg$c14;
            peg$currPos += 2;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c15);
            }
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c16();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c17) {
                s0 = peg$c17;
                peg$currPos += 2;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c18);
                }
            }
            if (s0 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c19) {
                    s0 = peg$c19;
                    peg$currPos += 2;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c20);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c21) {
                        s0 = peg$c21;
                        peg$currPos += 2;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c22);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 60) {
                            s0 = peg$c23;
                            peg$currPos++;
                        } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c24);
                            }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 62) {
                                s0 = peg$c25;
                                peg$currPos++;
                            } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c26);
                                }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c27) {
                                    s0 = peg$c27;
                                    peg$currPos += 2;
                                } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c28);
                                    }
                                }
                                if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.charCodeAt(peg$currPos) === 61) {
                                        s1 = peg$c29;
                                        peg$currPos++;
                                    } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$c30);
                                        }
                                    }
                                    if (s1 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c31();
                                    }
                                    s0 = s1;
                                    if (s0 === peg$FAILED) {
                                        if (input.substr(peg$currPos, 2).toLowerCase() === peg$c32) {
                                            s0 = input.substr(peg$currPos, 2);
                                            peg$currPos += 2;
                                        } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$c33);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return s0;
    }

    function peg$parseexpression() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        s0 = peg$currPos;
        s1 = peg$parseterm();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 43) {
                    s5 = peg$c34;
                    peg$currPos++;
                } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c35);
                    }
                }
                if (s5 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 45) {
                        s5 = peg$c36;
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c37);
                        }
                    }
                }
                if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 === peg$FAILED) {
                        s6 = null;
                    }
                    if (s6 !== peg$FAILED) {
                        s7 = peg$parseterm();
                        if (s7 !== peg$FAILED) {
                            peg$savedPos = s4;
                            s5 = peg$c12(s1, s5, s7);
                            s4 = s5;
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 43) {
                        s5 = peg$c34;
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c35);
                        }
                    }
                    if (s5 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 45) {
                            s5 = peg$c36;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c37);
                            }
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 === peg$FAILED) {
                            s6 = null;
                        }
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseterm();
                            if (s7 !== peg$FAILED) {
                                peg$savedPos = s4;
                                s5 = peg$c12(s1, s5, s7);
                                s4 = s5;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c38(s1, s3);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseterm() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8;

        s0 = peg$currPos;
        s1 = peg$parseprimary();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 42) {
                    s5 = peg$c39;
                    peg$currPos++;
                } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c40);
                    }
                }
                if (s5 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 47) {
                        s5 = peg$c41;
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c42);
                        }
                    }
                }
                if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 === peg$FAILED) {
                        s6 = null;
                    }
                    if (s6 !== peg$FAILED) {
                        s7 = peg$parseprimary();
                        if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 === peg$FAILED) {
                                s8 = null;
                            }
                            if (s8 !== peg$FAILED) {
                                peg$savedPos = s4;
                                s5 = peg$c12(s1, s5, s7);
                                s4 = s5;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 42) {
                        s5 = peg$c39;
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c40);
                        }
                    }
                    if (s5 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 47) {
                            s5 = peg$c41;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c42);
                            }
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 === peg$FAILED) {
                            s6 = null;
                        }
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseprimary();
                            if (s7 !== peg$FAILED) {
                                s8 = peg$parse_();
                                if (s8 === peg$FAILED) {
                                    s8 = null;
                                }
                                if (s8 !== peg$FAILED) {
                                    peg$savedPos = s4;
                                    s5 = peg$c12(s1, s5, s7);
                                    s4 = s5;
                                } else {
                                    peg$currPos = s4;
                                    s4 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c13(s1, s3);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parseprimary() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parsenumber();
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c43(s1);
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 4).toLowerCase() === peg$c44) {
                s1 = input.substr(peg$currPos, 4);
                peg$currPos += 4;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c45);
                }
            }
            if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c46();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 4).toLowerCase() === peg$c47) {
                    s1 = input.substr(peg$currPos, 4);
                    peg$currPos += 4;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c48);
                    }
                }
                if (s1 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c49();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c50) {
                        s1 = input.substr(peg$currPos, 5);
                        peg$currPos += 5;
                    } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c51);
                        }
                    }
                    if (s1 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c52();
                    }
                    s0 = s1;
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsestring();
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c53(s1);
                        }
                        s0 = s1;
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 40) {
                                s1 = peg$c54;
                                peg$currPos++;
                            } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c55);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                s2 = peg$parse_();
                                if (s2 === peg$FAILED) {
                                    s2 = null;
                                }
                                if (s2 !== peg$FAILED) {
                                    s3 = peg$parseconditionalOr();
                                    if (s3 !== peg$FAILED) {
                                        s4 = peg$parse_();
                                        if (s4 === peg$FAILED) {
                                            s4 = null;
                                        }
                                        if (s4 !== peg$FAILED) {
                                            if (input.charCodeAt(peg$currPos) === 41) {
                                                s5 = peg$c56;
                                                peg$currPos++;
                                            } else {
                                                s5 = peg$FAILED;
                                                if (peg$silentFails === 0) {
                                                    peg$fail(peg$c57);
                                                }
                                            }
                                            if (s5 !== peg$FAILED) {
                                                peg$savedPos = s0;
                                                s1 = peg$c0(s3);
                                                s0 = s1;
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                            }
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 33) {
                                    s1 = peg$c58;
                                    peg$currPos++;
                                } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c59);
                                    }
                                }
                                if (s1 !== peg$FAILED) {
                                    s2 = peg$parse_();
                                    if (s2 === peg$FAILED) {
                                        s2 = null;
                                    }
                                    if (s2 !== peg$FAILED) {
                                        s3 = peg$parseprimary();
                                        if (s3 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c60(s3);
                                            s0 = s1;
                                        } else {
                                            peg$currPos = s0;
                                            s0 = peg$FAILED;
                                        }
                                    } else {
                                        peg$currPos = s0;
                                        s0 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                }
                                if (s0 === peg$FAILED) {
                                    s0 = peg$parsearray();
                                    if (s0 === peg$FAILED) {
                                        s0 = peg$parsefieldOrFunction();
                                        if (s0 === peg$FAILED) {
                                            s0 = peg$parseparam();
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return s0;
    }

    function peg$parseparam() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 123) {
            s1 = peg$c61;
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c62);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = peg$parsefield();
                if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    if (s4 === peg$FAILED) {
                        s4 = null;
                    }
                    if (s4 !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 125) {
                            s5 = peg$c63;
                            peg$currPos++;
                        } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c64);
                            }
                        }
                        if (s5 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c65(s3);
                            s0 = s1;
                        } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsearray() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10;

        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 91) {
            s1 = peg$c66;
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c67);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$parseconditionalOr();
            if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                if (s4 === peg$FAILED) {
                    s4 = null;
                }
                if (s4 !== peg$FAILED) {
                    s5 = [];
                    s6 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s7 = peg$c68;
                        peg$currPos++;
                    } else {
                        s7 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c69);
                        }
                    }
                    if (s7 !== peg$FAILED) {
                        s8 = peg$parse_();
                        if (s8 === peg$FAILED) {
                            s8 = null;
                        }
                        if (s8 !== peg$FAILED) {
                            s9 = peg$parseconditionalOr();
                            if (s9 !== peg$FAILED) {
                                s10 = peg$parse_();
                                if (s10 === peg$FAILED) {
                                    s10 = null;
                                }
                                if (s10 !== peg$FAILED) {
                                    peg$savedPos = s6;
                                    s7 = peg$c70(s3, s9);
                                    s6 = s7;
                                } else {
                                    peg$currPos = s6;
                                    s6 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s6;
                                s6 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s6;
                            s6 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                    }
                    while (s6 !== peg$FAILED) {
                        s5.push(s6);
                        s6 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 44) {
                            s7 = peg$c68;
                            peg$currPos++;
                        } else {
                            s7 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c69);
                            }
                        }
                        if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 === peg$FAILED) {
                                s8 = null;
                            }
                            if (s8 !== peg$FAILED) {
                                s9 = peg$parseconditionalOr();
                                if (s9 !== peg$FAILED) {
                                    s10 = peg$parse_();
                                    if (s10 === peg$FAILED) {
                                        s10 = null;
                                    }
                                    if (s10 !== peg$FAILED) {
                                        peg$savedPos = s6;
                                        s7 = peg$c70(s3, s9);
                                        s6 = s7;
                                    } else {
                                        peg$currPos = s6;
                                        s6 = peg$FAILED;
                                    }
                                } else {
                                    peg$currPos = s6;
                                    s6 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s6;
                                s6 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s6;
                            s6 = peg$FAILED;
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        peg$savedPos = s2;
                        s3 = peg$c71(s3, s5);
                        s2 = s3;
                    } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                }
            } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
            }
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 93) {
                    s3 = peg$c72;
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c73);
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c74(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsefieldOrFunction() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        s0 = peg$currPos;
        s1 = peg$parsefield();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 40) {
                    s4 = peg$c54;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c55);
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parse_();
                    if (s5 === peg$FAILED) {
                        s5 = null;
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parsefunction_args();
                        if (s6 === peg$FAILED) {
                            s6 = null;
                        }
                        if (s6 !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 41) {
                                s7 = peg$c56;
                                peg$currPos++;
                            } else {
                                s7 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c57);
                                }
                            }
                            if (s7 !== peg$FAILED) {
                                peg$savedPos = s3;
                                s4 = peg$c75(s1, s6);
                                s3 = s4;
                            } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
                if (s3 === peg$FAILED) {
                    s3 = null;
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c76(s1, s3);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsefunction_args() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8;

        s0 = peg$currPos;
        s1 = peg$parseconditionalOr();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            if (s2 === peg$FAILED) {
                s2 = null;
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 44) {
                    s5 = peg$c68;
                    peg$currPos++;
                } else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c69);
                    }
                }
                if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    if (s6 === peg$FAILED) {
                        s6 = null;
                    }
                    if (s6 !== peg$FAILED) {
                        s7 = peg$parseconditionalOr();
                        if (s7 !== peg$FAILED) {
                            s8 = peg$parse_();
                            if (s8 === peg$FAILED) {
                                s8 = null;
                            }
                            if (s8 !== peg$FAILED) {
                                peg$savedPos = s4;
                                s5 = peg$c77(s1, s7);
                                s4 = s5;
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                }
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$currPos;
                    if (input.charCodeAt(peg$currPos) === 44) {
                        s5 = peg$c68;
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c69);
                        }
                    }
                    if (s5 !== peg$FAILED) {
                        s6 = peg$parse_();
                        if (s6 === peg$FAILED) {
                            s6 = null;
                        }
                        if (s6 !== peg$FAILED) {
                            s7 = peg$parseconditionalOr();
                            if (s7 !== peg$FAILED) {
                                s8 = peg$parse_();
                                if (s8 === peg$FAILED) {
                                    s8 = null;
                                }
                                if (s8 !== peg$FAILED) {
                                    peg$savedPos = s4;
                                    s5 = peg$c77(s1, s7);
                                    s4 = s5;
                                } else {
                                    peg$currPos = s4;
                                    s4 = peg$FAILED;
                                }
                            } else {
                                peg$currPos = s4;
                                s4 = peg$FAILED;
                            }
                        } else {
                            peg$currPos = s4;
                            s4 = peg$FAILED;
                        }
                    } else {
                        peg$currPos = s4;
                        s4 = peg$FAILED;
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c78(s1, s3);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsefield() {
        var s0, s1, s2, s3, s4, s5;

        s0 = peg$currPos;
        s1 = peg$parsename();
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 46) {
                s4 = peg$c79;
                peg$currPos++;
            } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c80);
                }
            }
            if (s4 !== peg$FAILED) {
                s5 = peg$parsename();
                if (s5 !== peg$FAILED) {
                    peg$savedPos = s3;
                    s4 = peg$c81(s1, s5);
                    s3 = s4;
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
            }
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 46) {
                    s4 = peg$c79;
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c80);
                    }
                }
                if (s4 !== peg$FAILED) {
                    s5 = peg$parsename();
                    if (s5 !== peg$FAILED) {
                        peg$savedPos = s3;
                        s4 = peg$c81(s1, s5);
                        s3 = s4;
                    } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            }
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c82(s1, s2);
                s0 = s1;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsename() {
        var s0, s1, s2, s3, s4;

        s0 = peg$currPos;
        s1 = peg$currPos;
        if (peg$c83.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
        } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c84);
            }
        }
        if (s2 !== peg$FAILED) {
            s3 = [];
            if (peg$c85.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c86);
                }
            }
            while (s4 !== peg$FAILED) {
                s3.push(s4);
                if (peg$c85.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c86);
                    }
                }
            }
            if (s3 !== peg$FAILED) {
                s2 = [s2, s3];
                s1 = s2;
            } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
            }
        } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            s0 = input.substring(s0, peg$currPos);
        } else {
            s0 = s1;
        }

        return s0;
    }

    function peg$parsenumber() {
        var s0, s1, s2, s3, s4, s5, s6;

        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 43) {
            s3 = peg$c34;
            peg$currPos++;
        } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c35);
            }
        }
        if (s3 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
                s3 = peg$c36;
                peg$currPos++;
            } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c37);
                }
            }
        }
        if (s3 === peg$FAILED) {
            s3 = null;
        }
        if (s3 !== peg$FAILED) {
            s4 = [];
            if (peg$c87.test(input.charAt(peg$currPos))) {
                s5 = input.charAt(peg$currPos);
                peg$currPos++;
            } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c88);
                }
            }
            if (s5 !== peg$FAILED) {
                while (s5 !== peg$FAILED) {
                    s4.push(s5);
                    if (peg$c87.test(input.charAt(peg$currPos))) {
                        s5 = input.charAt(peg$currPos);
                        peg$currPos++;
                    } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c88);
                        }
                    }
                }
            } else {
                s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
                s3 = [s3, s4];
                s2 = s3;
            } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
            }
        } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
            s1 = input.substring(s1, peg$currPos);
        } else {
            s1 = s2;
        }
        if (s1 !== peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 46) {
                s4 = peg$c79;
                peg$currPos++;
            } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c80);
                }
            }
            if (s4 !== peg$FAILED) {
                s5 = [];
                if (peg$c87.test(input.charAt(peg$currPos))) {
                    s6 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s6 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c88);
                    }
                }
                if (s6 !== peg$FAILED) {
                    while (s6 !== peg$FAILED) {
                        s5.push(s6);
                        if (peg$c87.test(input.charAt(peg$currPos))) {
                            s6 = input.charAt(peg$currPos);
                            peg$currPos++;
                        } else {
                            s6 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c88);
                            }
                        }
                    }
                } else {
                    s5 = peg$FAILED;
                }
                if (s5 !== peg$FAILED) {
                    s4 = [s4, s5];
                    s3 = s4;
                } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                }
            } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
            }
            if (s3 === peg$FAILED) {
                s3 = null;
            }
            if (s3 !== peg$FAILED) {
                s2 = input.substring(s2, peg$currPos);
            } else {
                s2 = s3;
            }
            if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c89(s1, s2);
                s0 = s1;
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }

        return s0;
    }

    function peg$parsestring() {
        var s0, s1, s2, s3;

        peg$silentFails++;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 34) {
            s1 = peg$c91;
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c92);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = [];
            s3 = peg$parsedbl_quot_char();
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                s3 = peg$parsedbl_quot_char();
            }
            if (s2 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 34) {
                    s3 = peg$c91;
                    peg$currPos++;
                } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c92);
                    }
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c93(s2);
                    s0 = s1;
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 39) {
                s1 = peg$c94;
                peg$currPos++;
            } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c95);
                }
            }
            if (s1 !== peg$FAILED) {
                s2 = [];
                s3 = peg$parsequot_char();
                while (s3 !== peg$FAILED) {
                    s2.push(s3);
                    s3 = peg$parsequot_char();
                }
                if (s2 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 39) {
                        s3 = peg$c94;
                        peg$currPos++;
                    } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c95);
                        }
                    }
                    if (s3 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c93(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c90);
            }
        }

        return s0;
    }

    function peg$parsequot_char() {
        var s0, s1, s2;

        s0 = peg$parseunescaped();
        if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 34) {
                s0 = peg$c91;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c92);
                }
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c96;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c97);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseescaped_char();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c98(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
        }

        return s0;
    }

    function peg$parsedbl_quot_char() {
        var s0, s1, s2;

        s0 = peg$parseunescaped();
        if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 39) {
                s0 = peg$c94;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c95);
                }
            }
            if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 92) {
                    s1 = peg$c96;
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c97);
                    }
                }
                if (s1 !== peg$FAILED) {
                    s2 = peg$parseescaped_char();
                    if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c98(s2);
                        s0 = s1;
                    } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
        }

        return s0;
    }

    function peg$parseescaped_char() {
        var s0, s1, s2, s3, s4, s5, s6, s7;

        if (input.charCodeAt(peg$currPos) === 92) {
            s0 = peg$c96;
            peg$currPos++;
        } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c97);
            }
        }
        if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
                s0 = peg$c41;
                peg$currPos++;
            } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$c42);
                }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 34) {
                    s0 = peg$c91;
                    peg$currPos++;
                } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c92);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 39) {
                        s0 = peg$c94;
                        peg$currPos++;
                    } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$c95);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        s0 = peg$currPos;
                        if (input.charCodeAt(peg$currPos) === 98) {
                            s1 = peg$c99;
                            peg$currPos++;
                        } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$c100);
                            }
                        }
                        if (s1 !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c101();
                        }
                        s0 = s1;
                        if (s0 === peg$FAILED) {
                            s0 = peg$currPos;
                            if (input.charCodeAt(peg$currPos) === 102) {
                                s1 = peg$c102;
                                peg$currPos++;
                            } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$c103);
                                }
                            }
                            if (s1 !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c104();
                            }
                            s0 = s1;
                            if (s0 === peg$FAILED) {
                                s0 = peg$currPos;
                                if (input.charCodeAt(peg$currPos) === 110) {
                                    s1 = peg$c105;
                                    peg$currPos++;
                                } else {
                                    s1 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$c106);
                                    }
                                }
                                if (s1 !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c107();
                                }
                                s0 = s1;
                                if (s0 === peg$FAILED) {
                                    s0 = peg$currPos;
                                    if (input.charCodeAt(peg$currPos) === 114) {
                                        s1 = peg$c108;
                                        peg$currPos++;
                                    } else {
                                        s1 = peg$FAILED;
                                        if (peg$silentFails === 0) {
                                            peg$fail(peg$c109);
                                        }
                                    }
                                    if (s1 !== peg$FAILED) {
                                        peg$savedPos = s0;
                                        s1 = peg$c110();
                                    }
                                    s0 = s1;
                                    if (s0 === peg$FAILED) {
                                        s0 = peg$currPos;
                                        if (input.charCodeAt(peg$currPos) === 116) {
                                            s1 = peg$c111;
                                            peg$currPos++;
                                        } else {
                                            s1 = peg$FAILED;
                                            if (peg$silentFails === 0) {
                                                peg$fail(peg$c112);
                                            }
                                        }
                                        if (s1 !== peg$FAILED) {
                                            peg$savedPos = s0;
                                            s1 = peg$c113();
                                        }
                                        s0 = s1;
                                        if (s0 === peg$FAILED) {
                                            s0 = peg$currPos;
                                            if (input.charCodeAt(peg$currPos) === 117) {
                                                s1 = peg$c114;
                                                peg$currPos++;
                                            } else {
                                                s1 = peg$FAILED;
                                                if (peg$silentFails === 0) {
                                                    peg$fail(peg$c115);
                                                }
                                            }
                                            if (s1 !== peg$FAILED) {
                                                s2 = peg$currPos;
                                                s3 = peg$currPos;
                                                s4 = peg$parseHEXDIG();
                                                if (s4 !== peg$FAILED) {
                                                    s5 = peg$parseHEXDIG();
                                                    if (s5 !== peg$FAILED) {
                                                        s6 = peg$parseHEXDIG();
                                                        if (s6 !== peg$FAILED) {
                                                            s7 = peg$parseHEXDIG();
                                                            if (s7 !== peg$FAILED) {
                                                                s4 = [s4, s5, s6, s7];
                                                                s3 = s4;
                                                            } else {
                                                                peg$currPos = s3;
                                                                s3 = peg$FAILED;
                                                            }
                                                        } else {
                                                            peg$currPos = s3;
                                                            s3 = peg$FAILED;
                                                        }
                                                    } else {
                                                        peg$currPos = s3;
                                                        s3 = peg$FAILED;
                                                    }
                                                } else {
                                                    peg$currPos = s3;
                                                    s3 = peg$FAILED;
                                                }
                                                if (s3 !== peg$FAILED) {
                                                    s2 = input.substring(s2, peg$currPos);
                                                } else {
                                                    s2 = s3;
                                                }
                                                if (s2 !== peg$FAILED) {
                                                    peg$savedPos = s0;
                                                    s1 = peg$c116(s2);
                                                    s0 = s1;
                                                } else {
                                                    peg$currPos = s0;
                                                    s0 = peg$FAILED;
                                                }
                                            } else {
                                                peg$currPos = s0;
                                                s0 = peg$FAILED;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return s0;
    }

    function peg$parseunescaped() {
        var s0, s1, s2;

        s0 = peg$currPos;
        s1 = [];
        if (peg$c117.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
        } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c118);
            }
        }
        if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                if (peg$c117.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c118);
                    }
                }
            }
        } else {
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            s0 = input.substring(s0, peg$currPos);
        } else {
            s0 = s1;
        }

        return s0;
    }

    function peg$parseHEXDIG() {
        var s0;

        if (peg$c119.test(input.charAt(peg$currPos))) {
            s0 = input.charAt(peg$currPos);
            peg$currPos++;
        } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c120);
            }
        }

        return s0;
    }

    function peg$parse_() {
        var s0, s1;

        peg$silentFails++;
        s0 = [];
        if (peg$c122.test(input.charAt(peg$currPos))) {
            s1 = input.charAt(peg$currPos);
            peg$currPos++;
        } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c123);
            }
        }
        if (s1 !== peg$FAILED) {
            while (s1 !== peg$FAILED) {
                s0.push(s1);
                if (peg$c122.test(input.charAt(peg$currPos))) {
                    s1 = input.charAt(peg$currPos);
                    peg$currPos++;
                } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$c123);
                    }
                }
            }
        } else {
            s0 = peg$FAILED;
        }
        peg$silentFails--;
        if (s0 === peg$FAILED) {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$c121);
            }
        }

        return s0;
    }

    peg$result = peg$startRuleFunction();

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
    } else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
            peg$fail(peg$endExpectation());
        }

        throw peg$buildStructuredError(
            peg$maxFailExpected,
            peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
            peg$maxFailPos < input.length
                ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
                : peg$computeLocation(peg$maxFailPos, peg$maxFailPos),
        );
    }
}

module.exports = {
    SyntaxError: peg$SyntaxError,
    parse: peg$parse,
};
