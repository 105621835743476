import * as t from 'io-ts';
import { cloneDeepWith } from 'lodash';

import {
    BaseSectionConfig,
    BaseSectionContainerConfig,
    ColumnsConfig,
    ReportTemplateSectionConfig,
} from "@archipad/services/report/models/ReportTemplateSectionConfig";
import { SectionType, ReportTemplateSectionConfigModel, ReportTemplateSectionContainerConfigModel } from "@archipad/services/report/models/Definition";
import { BackgroundOrientationV } from '@archipad/services/entities/report/reportConfig';
import { BackgroundAttachment } from '@archipad/services/report/BackgroundAttachment';
import { ReportTemplateLayout } from "@archipad/backend/archiweb/resourceTemplate";

import { IllegalArgumentError, NotImplementedError, IllegalStateError } from "@core/errors/errors-core";
import { NumericStringCodec, PhaseIdentifierCodec } from '@core/iots/codecs';
import { createEnumType } from '@core/iots/enumType';

import { BackgroundOrientation } from '@archipad-js/core/utils';


export const DEFAULT_REPORT_TEMPLATE_ID:string = 'default-archipad-report';
export const PROMOIMMO_REPORT_TEMPLATE_ID:string = 'promoimmo-archipad-report';

/**
 * All defined section configurations
 * There is a 1-1 mapping between SectionType and SectionConfig : each SectionType must have it SectionConfig
 */

// ReportOrientation
export enum ReportOrientation {
    Portrait = "portrait",
    Landscape = "landscape"
}
export const ReportOrientationV = t.keyof({
    [ReportOrientation.Portrait]: 'portrait',
    [ReportOrientation.Landscape]: 'landscape',
});

export enum PaperSize {
    A4 = "A4",
    US_LETTER = "US_LETTER"
}
export const PaperSizeV = t.keyof({
    [PaperSize.A4]: 'A4',
    [PaperSize.US_LETTER]: 'US_LETTER',
});
// ReportStyle
export enum ReportStyle {
    Modern = "modern",
    Simple = "simple",
    Pyjama = "pyjama",
    Custom = "custom"
}
export const ReportStyleV = t.keyof({
    [ReportStyle.Modern]: 'modern',
    [ReportStyle.Simple]: 'simple',
    [ReportStyle.Pyjama]: 'pyjama',
    [ReportStyle.Custom]: 'custom',
});

// PhotoSize
export enum PhotoSize {
    Small= "small",
    Medium= "medium",
    Large= "large"
}
export const PhotoSizeV = t.keyof({
    [PhotoSize.Small]: 'small',
    [PhotoSize.Medium]: 'medium',
    [PhotoSize.Large]: 'large',
});

// ReportFooterItemPosition
export const ReportFooterItemPosition = t.interface({
    x: t.number,
    y: t.number,
});
export type ReportFooterItemPosition = t.TypeOf<typeof ReportFooterItemPosition>;

// BackgroundAttachmentConfig 
export const BackgroundAttachmentConfig = t.partial({
    key: t.string,
    uri: t.string,
    filename: t.string,
});
export type BackgroundAttachmentConfig = t.TypeOf<typeof BackgroundAttachmentConfig>;

export enum TemplateColor {
    C0 = "0",
    C1 = "1",
    C2 = "2",
    C3 = "3",
    C4 = "4",
    C5 = "5",
    C6 = "6",
    C7 = "7",
    C8 = "8",
}
export const TemplateColorV = t.keyof({
    [TemplateColor.C0] : TemplateColor.C0, // Should not be possible to select this color, just historical bug
    [TemplateColor.C1] : TemplateColor.C1,
    [TemplateColor.C2] : TemplateColor.C2,
    [TemplateColor.C3] : TemplateColor.C3,
    [TemplateColor.C4] : TemplateColor.C4,
    [TemplateColor.C5] : TemplateColor.C5,
    [TemplateColor.C6] : TemplateColor.C6,
    [TemplateColor.C7] : TemplateColor.C7,
    [TemplateColor.C8] : TemplateColor.C8,
});

/**
 * Represents the config of the "main" report section config
 */
export class ArchipadReportTemplateConfig extends BaseSectionContainerConfig implements IdentifiedArchipadReportTemplateConfigModel {

    public readonly sectionType: SectionType = SectionType.ReportSection

    public name: string | undefined = undefined;

    public filename: string | undefined = undefined;

    public identifier: string | undefined = undefined;

    public style: ReportStyle | undefined = undefined;

    public color: TemplateColor | undefined = undefined;

    public photosSize: PhotoSize | undefined = undefined;

    public orientation: ReportOrientation | undefined = undefined;

    public paperSize: PaperSize | undefined = undefined;

    public background: BackgroundAttachmentConfig | undefined = undefined;

    public backgroundOrientation: BackgroundOrientation | undefined = undefined;

    public size: ReportTemplateLayout | undefined = undefined;

    public fixedLayout: boolean | undefined = undefined;

    constructor(sectionId:string, definition:ContainerSectionDefinition, sectionType: SectionType) {
        super(sectionId, definition, sectionType);
    }

    public ensureConsistency(): void {
        super.ensureConsistency();
    }

    protected _makeSectionConfig(sectionId: string, definition: SectionDefinition):ReportTemplateSectionConfig {
        const sectionType = definition.sectionType;
        switch(sectionType) {

            case SectionType.ParticipantsSection:
                return new ParticipantsSectionConfig(sectionId, definition, sectionType);

            case SectionType.ObservationsSection:
                return new ObservationsSectionConfig(sectionId, definition, sectionType);

            case SectionType.RemarksSection:
                return new RemarksSectionConfig(sectionId, definition, sectionType);
            case SectionType.PlanningSection:
                return new PlanningSectionConfig(sectionId, definition, sectionType);
            case SectionType.ProjectInfoSection:
            case SectionType.SignaturesSection:
            case SectionType.NotesSection:
            case SectionType.AnnexesSection:
            case SectionType.LotsInfoSection:
            case SectionType.ElementsRemisSection:
            case SectionType.CompteursSection:
            case SectionType.DeclarationSection:
            case SectionType.SoldeSection:
            case SectionType.MentionsLegalesSection:
                return new CommonSectionConfig(sectionId, definition, sectionType);

            default:
                throw new IllegalArgumentError('Unknown sectionType : ' + sectionType);
        }
    }
}

/**
 * Common SectionConfig that contains fields common to all Archipad SectionConfigs
 */
export class CommonSectionConfig extends BaseSectionConfig implements CommonSectionConfigModel {
    public breakBefore: boolean | undefined = undefined;
    public actions = ['delete', 'drag'];
}

/**
 * SectionConfigs of simple sections
 */
export class ParticipantsSectionConfig extends CommonSectionConfig implements ParticipantsSectionConfigModel {
    public readonly sectionType = SectionType.ParticipantsSection;
    public columns: ColumnsConfig | undefined = undefined;
}


/**
 * Enums for ObservationsSectionConfig
 */
// Group by
export enum ObservationsGroupBy {
    WorkPackages= "wps",
    Locations= "locs",
    Maps= "maps"
}
export const ObservationsGroupByV = t.keyof({
    [ObservationsGroupBy.WorkPackages]: ObservationsGroupBy.WorkPackages,
    [ObservationsGroupBy.Locations]: ObservationsGroupBy.Locations,
    [ObservationsGroupBy.Maps]: ObservationsGroupBy.Maps,
});

// Order by
export enum ObservationsOrderByForWPGroupBy {
    WorkpackageMapIndex= 'workPackage,map,index',
    WorkpackageMapLocationIndex= "workPackage,map,location,index",
}
const ObservationsOrderByForWPGroupByV = t.keyof({
    [ObservationsOrderByForWPGroupBy.WorkpackageMapIndex]: ObservationsOrderByForWPGroupBy.WorkpackageMapIndex,
    [ObservationsOrderByForWPGroupBy.WorkpackageMapLocationIndex]: ObservationsOrderByForWPGroupBy.WorkpackageMapLocationIndex,
});

export enum ObservationsOrderByForLocationGroupBy {
    Index= "location,index,map,workPackage",
    WorkPackage= "location,workPackage,index,map",
    Map= "location,map,index,workPackage",
}
const ObservationsOrderByForLocationGroupByV = t.keyof({
    [ObservationsOrderByForLocationGroupBy.Index]: ObservationsOrderByForLocationGroupBy.Index,
    [ObservationsOrderByForLocationGroupBy.WorkPackage]: ObservationsOrderByForLocationGroupBy.WorkPackage,
    [ObservationsOrderByForLocationGroupBy.Map]: ObservationsOrderByForLocationGroupBy.Map,
});

export enum ObservationsOrderByForMapsGroupBy {
    MapIndex='map,index',
    MapWorkpackageIndex= 'map,workPackage,index',
    MapLocationIndex= 'map,location,index'
}
const ObservationsOrderByForMapsGroupByV = t.keyof({
    [ObservationsOrderByForMapsGroupBy.MapIndex]: ObservationsOrderByForMapsGroupBy.MapIndex,
    [ObservationsOrderByForMapsGroupBy.MapWorkpackageIndex]: ObservationsOrderByForMapsGroupBy.MapWorkpackageIndex,
    [ObservationsOrderByForMapsGroupBy.MapLocationIndex]: ObservationsOrderByForMapsGroupBy.MapLocationIndex,
});

// Combined OrderBy
export const ObservationsOrderBy = t.union([
    ObservationsOrderByForWPGroupByV,
    ObservationsOrderByForLocationGroupByV,
    ObservationsOrderByForMapsGroupByV,
]);
export type ObservationsOrderBy = t.TypeOf<typeof ObservationsOrderBy>;

export function convertOrderByFromIdToValue(id:ObservationsOrderBy): Array<string> {
    // TODO XXX: Remove this ugly shit
    const value : Array<string> = id.split(',');
    return value;
}

// Observations enums
export enum ObservationsToInclude {
    All = "all",
    Open = "open",
    Visit = "visit",
    OpenAndVisit = "open_and_visit"
}
export const ObservationsToIncludeV = t.keyof({
    [ObservationsToInclude.All]: 'all',
    [ObservationsToInclude.Open]: 'open',
    [ObservationsToInclude.Visit]: 'visit',
    [ObservationsToInclude.OpenAndVisit]: 'open_and_visit',
});

export enum ObservationsIncludeHistoryAndComments {
    HistoryAndComments = "history_comments",
    Comments = "comments",
    None = "none"
}
export const ObservationsIncludeHistoryAndCommentsV = t.keyof({
    [ObservationsIncludeHistoryAndComments.HistoryAndComments]: 'history_comments',
    [ObservationsIncludeHistoryAndComments.Comments]: 'comments',
    [ObservationsIncludeHistoryAndComments.None]: 'none',
});

export class ObservationsSectionConfig extends CommonSectionConfig implements ObservationsSectionConfigModel{
    public readonly sectionType = SectionType.ObservationsSection;

    public title: string | undefined = undefined;

    public observations: ObservationsToInclude | undefined = undefined;

    public includeMapsWithoutBugs: boolean = undefined;

    public columns: ColumnsConfig | undefined = undefined;

    public groupBy: ObservationsGroupBy | undefined = undefined;

    public orderBy:ObservationsOrderBy | undefined = undefined;

    public breakAfterGroup: boolean | undefined = undefined;

    public historyAndComments: ObservationsIncludeHistoryAndComments | undefined = undefined;

    public phases: string[] | undefined = undefined;

    public useVisitPhase: boolean | undefined = false; // TODO XXX : Replace by undefined before adding the field

    //wps:string[];         // Not (yet) in template config. Is now in ReportRuntimeParameters along with projectId, visitId, etc
    //maps:string[];


    public ensureConsistency(): void {
        super.ensureConsistency();
        
        if ( this.groupBy == ObservationsGroupBy.WorkPackages && Object.values(ObservationsOrderByForWPGroupBy).indexOf(this.orderBy as ObservationsOrderByForWPGroupBy) === -1) {
            throw new IllegalArgumentError("Invalid order by "+ this.orderBy + " for group by "+ this.groupBy);
        }

        if ( this.groupBy == ObservationsGroupBy.Locations && Object.values(ObservationsOrderByForLocationGroupBy).indexOf(this.orderBy as ObservationsOrderByForLocationGroupBy) === -1) {
            throw new IllegalArgumentError("Invalid order by "+ this.orderBy + " for group by "+ this.groupBy);
        }

        if ( this.groupBy == ObservationsGroupBy.Maps && Object.values(ObservationsOrderByForMapsGroupBy).indexOf(this.orderBy as ObservationsOrderByForMapsGroupBy) === -1) {
            throw new IllegalArgumentError("Invalid order by "+ this.orderBy + " for group by "+ this.groupBy);
        }

        // TODO XXX ARCHIPAD REPORT CONFIG: check which columns are valid for each group by ?
    }
}


/**
 * Enums for RemarsSectionConfig
 */
// for now they are the same
export const RemarksToIncludeV = ObservationsToIncludeV;
export type RemarksToInclude = ObservationsToInclude;

export class RemarksSectionConfig extends CommonSectionConfig implements RemarksSectionConfigModel {
    public readonly sectionType = SectionType.RemarksSection;

    public title: string | undefined = undefined;

    public remarks: RemarksToInclude | undefined = undefined;

    public columns: ColumnsConfig | undefined = undefined;

    public categoryTitle: ColumnsConfig | undefined = undefined;
}

export class PlanningSectionConfig extends CommonSectionConfig implements PlanningSectionConfigModel {
    public readonly sectionType = SectionType.PlanningSection;
    public readonly actions = ['delete'];

}


/**
 * IO-TS Model -
 * Represents the config of the "main" report section config
 */
const MainSectionConfigModel = t.intersection([
    t.type({
        name: t.string,
        filename: t.string,
        style: ReportStyleV,
        color: TemplateColorV,
        photosSize: PhotoSizeV,
        orientation: ReportOrientationV,
        paperSize: t.union([PaperSizeV , t.undefined]),
        background: t.union([ BackgroundAttachmentConfig, t.null ]),
        backgroundOrientation: BackgroundOrientationV,
        size: ReportTemplateLayout,
    }),
    t.partial({
        fixedLayout: t.boolean,
    }),
    ReportTemplateSectionConfigModel,
]);
type MainSectionConfigModel = t.TypeOf<typeof MainSectionConfigModel>;

/**
 * IO-TS Model -
 * SectionConfig of participants section
 */
const ParticipantsSectionConfigModel = t.intersection([
    t.type({
        breakBefore: t.boolean,
        columns: ColumnsConfig
    }),
    ReportTemplateSectionConfigModel,

    // REDEFINED
    t.type({
        sectionType: t.literal(SectionType.ParticipantsSection),
    }),
]);
export type ParticipantsSectionConfigModel = t.TypeOf<typeof ParticipantsSectionConfigModel>;


const CommonSectionConfigModel = t.intersection([
    t.type({
        breakBefore: t.boolean,
    }),
    ReportTemplateSectionConfigModel,
])
export type CommonSectionConfigModel = t.TypeOf<typeof CommonSectionConfigModel>;

/**
 * IO-TS Model -
 * SectionConfig of observations section
 */
const ObservationsSectionConfigModel = t.intersection([
    t.type({
        breakBefore: t.boolean,
        title: t.string,
        observations: ObservationsToIncludeV,
        columns: ColumnsConfig,
        groupBy: ObservationsGroupByV,
        orderBy: ObservationsOrderBy,
        breakAfterGroup: t.boolean,
        historyAndComments: ObservationsIncludeHistoryAndCommentsV,
    }),
    t.partial({
        checklists: t.union([
            t.array(NumericStringCodec),
            t.null,
        ]),
        phases: t.union([
            t.array(PhaseIdentifierCodec),
            t.null,
        ]),
        useVisitPhase: t.boolean,
        // Must be optionnal for handling backward compat
        includeMapsWithoutBugs: t.boolean,
    }),
    ReportTemplateSectionConfigModel,

    // REDEFINED
    t.type({
        sectionType: t.literal(SectionType.ObservationsSection),
    }),
])
export type ObservationsSectionConfigModel = t.TypeOf<typeof ObservationsSectionConfigModel>;

/**
 * IO-TS Model -
 * SectionConfig of remarks section
 */
const RemarksSectionConfigModel = t.intersection([
    t.type({
        breakBefore: t.boolean,
        title: t.string,
        remarks: RemarksToIncludeV,
        columns: ColumnsConfig,
        categoryTitle: ColumnsConfig
    }),
    ReportTemplateSectionConfigModel,

    // REDEFINED
    t.type({
        sectionType: t.literal(SectionType.RemarksSection),
    }),
]);
export type RemarksSectionConfigModel = t.TypeOf<typeof RemarksSectionConfigModel>;


/**
 * IO-TS Model -
 * SectionConfig of planning section
 */
const PlanningSectionConfigModel = t.intersection([
    t.type({
        breakBefore: t.boolean,
    }),
    ReportTemplateSectionConfigModel,

    // REDEFINED
    t.type({
        sectionType: t.literal(SectionType.PlanningSection),
    }),
]);
export type PlanningSectionConfigModel = t.TypeOf<typeof PlanningSectionConfigModel>;

/**
 * Union of SectionConfig
 * Tagged union on 'sectionType'
 */
export const ArchipadReportSectionConfig = t.union([
    ParticipantsSectionConfigModel,
    ObservationsSectionConfigModel,
    RemarksSectionConfigModel,
    PlanningSectionConfigModel,

    // TODO XXX the following definitions should be a single catch all for any unregistered 'sectionType'
    t.intersection([CommonSectionConfigModel, t.type({
        sectionType: t.literal(SectionType.ProjectInfoSection),
    })]),
    t.intersection([CommonSectionConfigModel, t.type({
        sectionType: t.literal(SectionType.SignaturesSection),
    })]),
    t.intersection([CommonSectionConfigModel, t.type({
        sectionType: t.literal(SectionType.NotesSection),
    })]),
    t.intersection([CommonSectionConfigModel, t.type({
        sectionType: t.literal(SectionType.AnnexesSection),
    })]),
]);


/**
 * IO-TS Model -
 * Represents the config of the whole report section config
 */
export const ArchipadReportTemplateConfigModel = t.intersection([
    MainSectionConfigModel,
    ReportTemplateSectionContainerConfigModel,

    // REDEFINED
    t.partial({
        sections: t.record(t.string, ArchipadReportSectionConfig),
    }),
]);
export type ArchipadReportTemplateConfigModel = t.TypeOf<typeof ArchipadReportTemplateConfigModel>;

/**
 * IO-TS Model -
 * Represents the config of the whole report section config
 */
export const IdentifiedArchipadReportTemplateConfigModel = t.intersection([
    t.type({
        /** identifier of the configuration. This field is not part of the values */
        identifier: t.string
    }),

    MainSectionConfigModel,
    ReportTemplateSectionContainerConfigModel,
]);
export type IdentifiedArchipadReportTemplateConfigModel = t.TypeOf<typeof IdentifiedArchipadReportTemplateConfigModel>;


/**
 * Definition clases declaration
 */
export enum InspectorFieldType {
    Text = "text",
    ColumnsSelector = "columns-selector",
    Selector = "selector",
    Switch = "switch",
    BackgroundUpload = "background-upload",
    BackgroundOrientation = "background-orientation",
    StyleSelector = "style-selector",
    TitleSuggest = "title-suggest",
    ColorSelector = "color-selector",
    PhasesSelector = "phases-selector",
    FormTypeSelector = "form-type-selector",
    PagePreview = "page-preview",
    ReportSectionItemSize = "report-section-item.size",
    Number = "number",
    Button = "button",
}
export const InspectorFieldTypeV = createEnumType<InspectorFieldType>(InspectorFieldType, 'InspectorFieldType');

type IBaseInspectorFieldDescriptor = ExtractInterface<BaseInspectorFieldDescriptor>;

export abstract class Definition {

    [key: string]: unknown;

    public init(...args) {
        const [_definition] = args;
        const def = _definition instanceof Definition ? _definition.getValues() : _definition;
        for(const prop in def) {
            if(!def.hasOwnProperty(prop)) {
                continue;
            }
            this.setPropertyValue(prop, def[prop]);
        }
        Object.seal(this);
        return this;
    }

    /**
     * Get the value associated to the given `propertyName`
     * @param propertyName
     */
    public getPropertyValue(propertyName: string): unknown {
        return this[propertyName];
    }

    /**
     * Set a `newValue` to the given `propertyName`
     * @param propertyName
     * @param newValue
     */
    public setPropertyValue(propertyName: string, newValue: unknown): void {
        this[propertyName] = newValue;
    }

    /**
     * Get all values defined on the object
     */
    public getValues(): IBaseInspectorFieldDescriptor & {[propertyName: string]: unknown} {
        const values: { [key: string]: unknown } = {};
        for (const property in this) {
            values[property] = this[property];
        }
        return values as unknown as IBaseInspectorFieldDescriptor & { [propertyName: string]: unknown };
    }

    public clone(): Definition {
        // Create the new Definition entity
        const cloned = this.build();

        for (const property in this) {
            const value = cloneDeepWith(this[property], (value) => {
                if (value instanceof Definition) {
                    return value.clone();
                }
            });

            cloned[property] = value;
        }

        return cloned;
    }

    protected build(): Definition {
        throw new NotImplementedError('build', this.toString());
    }
}

/**
 * Base class which represent the definition of a section field
 */
const BaseInspectorFieldDescriptorModel = t.type({
    /** The default value of the field.
     * The default value of the field is used :
     *  - when instantiating from a config, filling the blanks, if any
     *  - when instantiating a new section from the (+) button
     */
    defaultValue: t.unknown,
     /** If the field should be disabled or not */
    disabled: t.boolean,
    /**
     * the fieldType to be used to display the field in the inspector. text, number, selector, columns-selector, ...
     * The fieldType are registered to the InspectorFieldsManager, defined in InspectorFieldsManager itself for common ones,
     * or in the sectionClass file if it's specific to that section (eg background rotation)
     */
    fieldType: InspectorFieldTypeV,
    /** the id of the field, used as id in the resultSet and key in config */
    id: t.string,
    /** Label of the field displayed to the user */
    label: t.union([t.string, t.null]),
    /** If the field should be readonly or not */
    readonly: t.boolean
});
type BaseInspectorFieldDescriptorModel = t.TypeOf<typeof BaseInspectorFieldDescriptorModel>;

export class BaseInspectorFieldDescriptor extends Definition implements BaseInspectorFieldDescriptorModel {
    public defaultValue: unknown;
    public disabled: boolean;
    public fieldType: InspectorFieldType;
    public id: string;
    public label: string | null;
    public readonly: boolean;

    build(): BaseInspectorFieldDescriptor {
        return new BaseInspectorFieldDescriptor();
    }
}


/**
 * Class which represents the definition for a Section
 */
const SectionDefinitionModel = t.type({
    /** section name to display to the user */
    localizedName: t.string,
    /** the row type used to display the preview (used by the sectionClass to create the previewResultSet) */
    previewRowType: t.string,
    /** the internal section "controller" class (used by factory to use) */
    sectionClass: t.string,
    /** type of the section */
    sectionType: t.string,
    /** the list of inspectable fields. (used by the sectionClass to create the inspectorResultSet) */
    fields: t.readonlyArray(BaseInspectorFieldDescriptorModel)
});
type SectionDefinitionModel = t.TypeOf<typeof SectionDefinitionModel>;

export class SectionDefinition extends Definition implements SectionDefinitionModel {
    public localizedName: string;
    public previewRowType: string;
    public sectionClass: string;
    public sectionType: SectionType;
    public fields: readonly BaseInspectorFieldDescriptor[];

    build(): SectionDefinition {
        return new SectionDefinition();
    }

    /**
     * Retrieve a field definition by its id
     * @param clazz class of the field to find
     * @param fieldId id of the field to find
     */
    public findFieldDefinitionById<T extends InspectorFieldDescriptor>(clazz: new (...args:any[]) => T, fieldId: string): T | undefined {
        const field = this.fields.find(field => field.id === fieldId);
        if (field === undefined) {
            return;
        }
        if (!(field instanceof clazz)) {
            throw new IllegalArgumentError(`Unexpected type of field ${fieldId}`);
        }
        return field;
    }

    /**
     * Getter on the orderBy field
     */
    public getOrderByField(): InspectorSelectorFieldDescriptor {
        const orderByField = this.findFieldDefinitionById(InspectorSelectorFieldDescriptor, 'orderBy');
        if (!orderByField) {
            throw new IllegalStateError(`Unable to find orderBy field`);
        }
        return orderByField;
    }

    /**
     * Getter of the columns fields
     */
    public getColumnsFields(): InspectorColumnsFieldDescriptor[] {
        return this.fields.filter((field: InspectorFieldDescriptor): field is InspectorColumnsFieldDescriptor => {
            return field instanceof InspectorColumnsFieldDescriptor;
        });
    }
}

export type InspectorFieldDescriptor =
    BaseInspectorFieldDescriptor
    | InspectorColumnsFieldDescriptor
    | InspectorButtonFieldDescriptor
    | InspectorSelectorFieldDescriptor
    | InspectorBackgroundOrientationFieldDescriptor
    | InspectorStyleSelectorFieldDescriptor
    | InspectorBackgroundUploadFieldDescriptor;


/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a button
 */
const InspectorButtonFieldDescriptorModel = t.intersection([
    t.type({
        operation: t.string
    }),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorButtonFieldDescriptorModel = t.TypeOf<typeof InspectorButtonFieldDescriptorModel>;

export class InspectorButtonFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorButtonFieldDescriptorModel {
    public operation: string

    build(): InspectorButtonFieldDescriptor {
        return new InspectorButtonFieldDescriptor();
    }
}

/**
 * Subclass of Definition which represent the definition of column
 */
const InspectorColumnFieldDescriptorModel = t.intersection([
    t.type({
        id: t.string,
        primaryText: t.string
    }),
    t.partial({
        mandatory: t.boolean,
        width: t.number
    })
]);
export type InspectorColumnFieldDescriptorModel = t.TypeOf<typeof InspectorColumnFieldDescriptorModel>;
export class InspectorColumnFieldDescriptor extends Definition implements InspectorColumnFieldDescriptorModel{
    public id!: string;
    public primaryText!: string;
    public mandatory?: boolean;
    public width?: number;

    build(): InspectorColumnFieldDescriptor {
        return new InspectorColumnFieldDescriptor();
    }
}

/**
 * Subclass of Definition which represent the definition of a selector value field
 */
const InspectorSelectorValueFieldDescriptorModel = t.intersection([
    t.type({
        id: t.string,
        primaryText: t.string
    }),
    t.partial({
        forGroupBy: t.readonlyArray(t.string)
    })
]);
export type InspectorSelectorValueFieldDescriptorModel = t.TypeOf<typeof InspectorSelectorValueFieldDescriptorModel>;
export class InspectorSelectorValueFieldDescriptor extends Definition implements InspectorSelectorValueFieldDescriptorModel{
    public id!: string;
    public primaryText!: string;
    public forGroupBy?: readonly string[];

    build(): InspectorSelectorValueFieldDescriptor {
        return new InspectorSelectorValueFieldDescriptor();
    }
}

/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a selector field
 */
const InspectorSelectorFieldDescriptorModel = t.intersection([
    t.type({
        values: t.readonlyArray(InspectorSelectorValueFieldDescriptorModel)
    }),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorSelectorFieldDescriptorModel = t.TypeOf<typeof InspectorSelectorFieldDescriptorModel>;
export class InspectorSelectorFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorSelectorFieldDescriptorModel {
    public values: readonly InspectorSelectorValueFieldDescriptor[];

    build(): InspectorSelectorFieldDescriptor {
        return new InspectorSelectorFieldDescriptor();
    }
}

/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a background orientation field
 */
const InspectorBackgroundOrientationFieldDescriptorModel = t.intersection([
    t.type({
        background: BackgroundAttachmentConfig,
        // cache : revolved background
        backgroundAttachment: BackgroundAttachment,
        columns: t.number
    }),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorBackgroundOrientationFieldDescriptorModel = t.TypeOf<typeof InspectorBackgroundOrientationFieldDescriptorModel>;

export class InspectorBackgroundOrientationFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorBackgroundOrientationFieldDescriptorModel {
    public background: BackgroundAttachmentConfig;
    public backgroundAttachment: BackgroundAttachment;
    public columns: number;

    build(): InspectorBackgroundOrientationFieldDescriptor {
        return new InspectorBackgroundOrientationFieldDescriptor();
    }
}

export type InspectorColumnsFieldValidator = (selectedColumns: ColumnsConfig | null) => { type: 'error', text: string } | null;

/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a columns selector field
 */
const InspectorColumnsFieldDescriptorModel = t.intersection([
    t.intersection([
        t.type({
            description: t.union([t.string, t.null]),
            columns: t.readonlyArray(InspectorColumnFieldDescriptorModel),
            shouldCheckWidth: t.boolean,
        }),
        /** Label of the dialog when selector is open  */
        t.partial({title: t.union([t.string, t.null])}),
        t.partial({validator: t.unknown})
    ]),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorColumnsFieldDescriptorModel = t.TypeOf<typeof InspectorColumnsFieldDescriptorModel>;
export class InspectorColumnsFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorColumnsFieldDescriptorModel {
    public description: string;
    public columns: readonly InspectorColumnFieldDescriptor[];
    public shouldCheckWidth: boolean;
    public title?: string | null;
    public validator?: InspectorColumnsFieldValidator;
    public defaultValue : Array<{ value : string, selected : boolean }>;

    build(): InspectorColumnsFieldDescriptor {
        return new InspectorColumnsFieldDescriptor();
    }

    public findColumnDefinitionById(columnDefinitionId: string):InspectorColumnFieldDescriptor|undefined {
        return this.columns.find((sectionColumnDefinition) => {
            return sectionColumnDefinition.id === columnDefinitionId;
        });
    }
}

/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a preview field
 */
const InspectorPagePreviewFieldDescriptorModel = t.intersection([
    t.intersection([
        t.type({
            background: BackgroundAttachmentConfig,
            // cache : revolved background
            backgroundAttachment: BackgroundAttachment
        }),
        t.partial({
            rotation: BackgroundOrientationV,
            orientation: ReportOrientationV,
            paperSize: PaperSizeV,

            // WORKAROUND: emulate some controller events : willLoadValues, didLoadValues
            valuesIdentifier: t.string,
        })
    ]),
    BaseInspectorFieldDescriptorModel
]);

export type InspectorPagePreviewFieldDescriptorModel = t.TypeOf<typeof InspectorPagePreviewFieldDescriptorModel>;

export class InspectorPagePreviewFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorPagePreviewFieldDescriptorModel {
    public background!: BackgroundAttachmentConfig;
    public backgroundAttachment!: BackgroundAttachment;
    public rotation?: BackgroundOrientation;
    public orientation?: ReportOrientation;
    public paperSize: PaperSize;
    public valuesIdentifier?:string;

    public init(arg:InspectorPagePreviewFieldDescriptorModel) {
        return super.init(arg);
    }

    build(): InspectorPagePreviewFieldDescriptor {
        return new InspectorPagePreviewFieldDescriptor();
    }
}


/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a background upload field
 */
const InspectorBackgroundUploadFieldDescriptorModel = t.intersection([
    t.type({
        templateId: t.union([t.string, t.null])
    }),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorBackgroundUploadFieldDescriptorModel = t.TypeOf<typeof InspectorBackgroundUploadFieldDescriptorModel>;

export class InspectorBackgroundUploadFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorBackgroundUploadFieldDescriptorModel {
    public templateId!: string | null;

    build(): InspectorBackgroundUploadFieldDescriptor {
        return new InspectorBackgroundUploadFieldDescriptor();
    }
}


/**
 * Subclass of BaseInspectorFieldDescriptor which represent the definition of a style selector field
 */
const InspectorStyleSelectorFieldDescriptorModel = t.intersection([
    t.type({
        items: t.readonlyArray(t.string)
    }),
    BaseInspectorFieldDescriptorModel
]);
export type InspectorStyleSelectorFieldDescriptorModel = t.TypeOf<typeof InspectorStyleSelectorFieldDescriptorModel>;

export class InspectorStyleSelectorFieldDescriptor extends BaseInspectorFieldDescriptor implements InspectorStyleSelectorFieldDescriptorModel {
    public items!: readonly string[];

    build(): InspectorStyleSelectorFieldDescriptor {
        return new InspectorStyleSelectorFieldDescriptor();
    }
}

/**
 * Subclass of Definition which represent the definition of a background field
 */
const InspectorBackgroundFieldDescriptorModel = t.type({
    key: t.union([t.string, t.null]),
    uri: t.union([t.string, t.null]),
    filename: t.union([t.string, t.null])
});
export type InspectorBackgroundFieldDescriptorModel = t.TypeOf<typeof InspectorBackgroundFieldDescriptorModel>;
export class InspectorBackgroundFieldDescriptor extends Definition implements InspectorBackgroundFieldDescriptorModel {
    public key!: string | null;
    public uri!: string | null;
    public filename!: string | null;

    build(): InspectorBackgroundFieldDescriptor {
        return new InspectorBackgroundFieldDescriptor();
    }
}

/**
 * Subclass of Definition which represent the definition of a background attachment (cache) field
 */
const InspectorBackgroundAttachmentFieldDescriptorModel = t.type({
    key: t.union([t.string, t.null]),
    url: t.union([t.string, t.null])
});
export type InspectorBackgroundAttachmentFieldDescriptorModel = t.TypeOf<typeof InspectorBackgroundAttachmentFieldDescriptorModel>;
export class InspectorBackgroundAttachmentFieldDescriptor extends Definition implements InspectorBackgroundAttachmentFieldDescriptorModel {
    public key!: string | null;
    public url!: string | null;

    build(): InspectorBackgroundAttachmentFieldDescriptor {
        return new InspectorBackgroundAttachmentFieldDescriptor();
    }
}


/**
 * Serializable definition of a section that can contain sections, like the ReportSectionItem
 * extends the base SectionDefinition interface
 */
export const ContainerSectionDefinitionModel = t.intersection([
    t.type({
        /**
         * List of sections that are available to be added
         * The controller (sectionClass:ContainerSectionItem) uses this field to compute the list of sections that can actually be added
         */
        availableChildSectionDefinitions: t.readonlyArray(SectionDefinitionModel)
    }),
    SectionDefinitionModel
]);
export type ContainerSectionDefinitionModel = t.TypeOf<typeof ContainerSectionDefinitionModel>;
export class ContainerSectionDefinition extends SectionDefinition implements ContainerSectionDefinitionModel {
    public availableChildSectionDefinitions: readonly SectionDefinition[];

    build(): ContainerSectionDefinition {
        return new ContainerSectionDefinition();
    }

    /**
     * Retrieve ever section of a given type
     * @param sectionType type of the sections to return
     */
    public findSectionDefinitionByType(sectionType: SectionType): SectionDefinition[] {
        return this.availableChildSectionDefinitions.filter(sectionDefinition => sectionDefinition.sectionType === sectionType);
    }

    /**
     * Getter for the background field
     */
    public getBackgroundField(): InspectorBackgroundUploadFieldDescriptor {
        const background = this.findFieldDefinitionById(InspectorBackgroundUploadFieldDescriptor, 'background');
        if (!background) {
            throw new IllegalStateError(`Unable to find background field`);
        }
        return background;
    }

    /**
     * Getter for the preview field
     */
    public getPreviewField(): InspectorPagePreviewFieldDescriptor {
        const preview = this.findFieldDefinitionById(InspectorPagePreviewFieldDescriptor, 'size');
        if (!preview) {
            throw new IllegalStateError(`Unable to find preview field`);
        }
        return preview;
    }

    /**
     * Getter for the background orientation field
     */
    public getBackgroundOrientationField(): InspectorBackgroundOrientationFieldDescriptor {
        const orientation = this.findFieldDefinitionById(InspectorBackgroundOrientationFieldDescriptor, 'backgroundOrientation');
        if (!orientation) {
            throw new IllegalStateError(`Unable to find background orientation field`);
        }
        return orientation;
    }

    /**
     * Getter for the document orientation field
     */
    public getDocumentOrientationField(): InspectorSelectorFieldDescriptor {
        const orientation = this.findFieldDefinitionById(InspectorSelectorFieldDescriptor, 'orientation');
        if (!orientation) {
            throw new IllegalStateError(`Unable to find document orientation field`);
        }
        return orientation;
    }

    /**
     * Getter for the document format field
     */
    public getDocumentPaperSizeField(): InspectorSelectorFieldDescriptor {
        const paperSize = this.findFieldDefinitionById(InspectorSelectorFieldDescriptor, 'paperSize');
        if (!paperSize) {
            throw new IllegalStateError(`Unable to find document paperSize field`);
        }
        return paperSize;
    }

    /**
     * Getter for the style field
     */
    public getStyleField(): InspectorStyleSelectorFieldDescriptor {
        const style = this.findFieldDefinitionById(InspectorStyleSelectorFieldDescriptor, 'style');
        if (!style) {
            throw new IllegalStateError(`Unable to find style field`);
        }
        return style;
    }
}