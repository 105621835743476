import "reflect-metadata";

import { inject, injectable, optional } from "inversify";

import { callAsAsync, voidProgressHandler } from "@archipad-js/core/async";
import { DIID } from "@archipad-js/dependency-injection";

import {
    LegacyArchipadAuthService,
    Legacy_Archipad_Auth_Service_DIID,
} from "./authentication/legacy-archipad-authentication.service";
import { ArchipadRPCService, Archipad_RPC_Service_DIID } from "./rpc/archipad-rpc.service";
import { Archipad_Auth_Service_DIID, ArchipadAuthService } from "./authentication/archipad-authentication.service";

export const Archiweb_Services_Client_DIID = DIID<ArchiwebServicesClient>("ArchiwebServicesClient");

// [PLUS-158] Delete this feature flag when the Plus Authentication Server is deployed and stable
export const Feature_Flag_PlusAuthenticationServer_DIID = DIID<boolean>("FeatureFlag_PlusAuthenticationServer");

@injectable()
export class ArchiwebServicesClient {
    constructor(
        @inject(Archipad_RPC_Service_DIID.symbol) private readonly archipadRpcService: ArchipadRPCService,
        @inject(Legacy_Archipad_Auth_Service_DIID.symbol)
        private readonly legacyArchipadAuthService: LegacyArchipadAuthService,
        @inject(Archipad_Auth_Service_DIID.symbol) private readonly archipadAuthService: ArchipadAuthService,
        @inject(Feature_Flag_PlusAuthenticationServer_DIID.symbol)
        @optional()
        private readonly featureFlagPlusAuthenticationServer: boolean = false,
    ) {}

    /**
     * Make an authenticated request to Archipad legacy API
     * Simple proxy to LegacyArchipadAuthService.makeRequest()
     */
    public async makeAuthenticatedRequest(
        signal: AbortSignal,
        serviceName: string,
        methodName: string,
        methodParams?: Record<string, unknown>,
    ): Promise<unknown> {
        const p = voidProgressHandler();
        const result = await callAsAsync(signal, p, 1, () => {
            if (this.featureFlagPlusAuthenticationServer) {
                return this.archipadAuthService.makeRequest(signal, serviceName, methodName, methodParams);
            } else {
                return this.legacyArchipadAuthService.makeRequest(signal, serviceName, methodName, methodParams);
            }
        });
        return result;
    }

    /**
     * Make an unauthenticated request to Archipad legacy API
     * Simple proxy to ArchipadRPCService.makeRequest()
     */
    public async makeUnauthenticatedRequest(
        signal: AbortSignal,
        serviceName: string,
        methodName: string,
        methodParams?: Record<string, unknown>,
    ): Promise<unknown> {
        const p = voidProgressHandler();
        const result = await callAsAsync(signal, p, 1, () =>
            this.archipadRpcService.makeRequest(signal, serviceName, methodName, methodParams),
        );
        return result;
    }
}
